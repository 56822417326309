import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Award, Shield, MessageSquare } from 'react-feather'
import ReactImageFallback from 'react-image-fallback'
import galleryPlaceholder from '../../assets/images/gallery-placeholder.svg'
import galleryPlaceholderDark from '../../assets/images/gallery-placeholder-dark.svg'
import { Typography } from '@mui/material'

class ShortlistPhoto extends Component {
  getFinalAward = (awards) => { 
    if(awards.length > 0) {
      let aoeCount = awards.filter(award => award.awardClass === 'Award of Excellence').length
      let hmCount = awards.filter(award => award.awardClass === 'Honorable Mention').length
      
      return (aoeCount > hmCount || aoeCount === hmCount) ? 'aoe' : (hmCount > 0) ? 'hm' : null
    }
    return null
  }
  render() {
    const { photo, onClick, photoOnClick, viewBadge, role, placeholderDark } = this.props
    const finalAward = this.getFinalAward(photo.awards)
    const isAwardedPage = window.location.pathname.includes('awarded-photos')
    const imgComponent = (
      <ReactImageFallback 
        fallbackImage='https://i.pinimg.com/originals/9b/96/92/9b9692c9f0db9f6276e6bd29a98c25e0.png'
        initialImage={placeholderDark ? galleryPlaceholderDark : galleryPlaceholder}
        src={photo.sizedUrl || photo.url}
        alt={photo.altText}
        className='photo photo_letterbox_type'
        key={photo.id}
      />
    )
    return (
      <Fragment>
        {typeof photo == 'undefined' ? null : onClick ||
          (this.props.photoOnClick &&
            (window.location.pathname.includes('class-detail') ||
              window.location.pathname.includes('class-review') || 
              window.location.pathname.includes('awarded-photos'))) ? (
          <div
            className='container__photo shortlist-photo--contained__bg'
            onClick={() => (onClick ? onClick(photo) : photoOnClick(photo))}
          >
            { imgComponent }
            
            {/* <img
              className='photo photo_letterbox_type'
              src={photo.sizedUrl || photo.url}
              alt={photo.altText}
              key={photo.id}
            /> */}
          </div>
        ) : (
          <Link to={`/photo-detail/${photo.id}`}>
            <div className='container__photo shortlist-photo--contained__bg' key={photo.id}>
              { imgComponent }
            </div>
          </Link>
        )} 
        {photo.awards ? <div className='photo-container__hover-content--top-right'>
          { isAwardedPage ? <div className='photo-container__hover-content--top-right---content'>
          <Typography variant="h4" className='semi-strong'>
            <span className='photo-container__hover-content--top-right---overlay'>
              { finalAward === 'aoe' ? <Award size={16}/> : finalAward === 'hm' ? <Shield size={16}/> : null }
            </span>
          </Typography>
        </div>
        : <>
            {photo.awards.map(award => (
              <div className='photo-container__hover-content--top-right---content' key={award.id}>
                <Typography variant="h4" className='semi-strong'>
                  <span className='photo-container__hover-content--top-right---overlay'>
                  { viewBadge ? null : `${award.nominatorInitials} `}{award.awardClass === 'Award of Excellence' ? <Award size={16}/> : award.awardClass === 'Honorable Mention' ? <Shield size={16}/> : null }
                  </span>
                </Typography>
              </div>
            ))}
          </>
        }</div> : null}
        
        {photo.photoComments.length > 0 && ['appadmin', 'curator'].includes(role) ? (
          <div className='message-notifier__photo-overlay'>
            <MessageSquare />
          </div>
        ) : null}
      </Fragment>
    )
  }
}

export default ShortlistPhoto
