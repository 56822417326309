import { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import { useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Box, Typography } from '@mui/material'
import _ from 'lodash'
import { CLASS_DETAILS_QUERY } from '../queries/classDetailsQueries'
import Loading from '../ui/elements/Loading.js'
import NoResultListMessage from '../ui/elements/NoResultListMessage.js'
import ToastNotification from '../ui/elements/ToastNotification.js'
import ClassReviewSchoolDetailsModal from '../ui/molecules/ClassReviewSchoolDetailsModal.js'
import AssignmentDetails from '../ui/organisms/AssignmentDetails.js'
import AwardProcessing from '../ui/organisms/AwardProcessing.js'
import ClassTrackerStudentList from '../ui/organisms/ClassTrackerStudentList.js'
import Hero from '../ui/organisms/Hero.js'
import TeacherAwardResults from '../ui/organisms/TeacherAwardResults.js'
import Layout from '../ui/template/Layout.js'
import { withRouter } from '../utils/customHoc.js'
import ErrorPage from './ErrorPage.jsx'
import DateUtils from '../utils/DateUtils'

const ClassDetails = props => {
  const { userStore, params, breadcrumbStore, location, basePath } = props

  const classDetailTabs = [
    { eventKey: 'class-details', title: 'Class Details', path: `/class-detail/${params.classId}` },
    {
      eventKey: 'student-list',
      title: 'Student List',
      path: `/class-detail/${params.classId}/student-list`
    },
    {
      eventKey: 'award-results',
      title: 'Award Results',
      path: `/class-detail/${params.classId}/award-results`
    }
  ]

  const [role] = useState(userStore.role)
  const [currentTab, setCurrentTab] = useState()
  const [showToast, setShowToast] = useState(false)
  const [toastType, setToastType] = useState()
  const [toastMessage, setToastMessage] = useState()
  const [educatorSummarySent, setEducatorSummarySent] = useState(false)
  const [studentSummarySent, setStudentSummarySent] = useState(false)
  const [modalSchoolDetailsShow, setModalSchoolDetailsShow] = useState(false)
  const [cohort, setCohort] = useState()

  useEffect(() => {
    setCurrentTab(props.params?.currentTab || 'class-details')
  }, [props.params.currentTab])

  useEffect(() => {
    if (cohort) {
      breadcrumbStore.addBreadcrumb({
        pageName: `Detail: ${cohort.name}`,
        link: location.pathname,
        basePath: basePath,
        isParent: false,
        isActive: true
      })
    }
  }, [cohort, location, basePath, breadcrumbStore])

  const showToastMsg = (toastType, message = '') => {
    setShowToast(true)
    setToastMessage(message)
    setToastType(toastType)
  }

  const handleTabChange = newTab => {
    setCurrentTab(newTab)
  }

  const containsProperty = (cohort, property) => {
    return (
      cohort.submittedProjects
        .map(proj => proj.photos.filter(photo => photo[property]))
        .filter(photosArr => photosArr.length > 0).length > 0
    )
  }

  const updateSummarySentStatus = type => {
    if (type === 'student') setStudentSummarySent(true)

    if (type === 'educator') setEducatorSummarySent(true)
  }

  const awardedProjects = projects => {
    return projects.filter(proj => proj.photos.filter(photo => photo.awards.length > 0).length > 0)
  }

  const getClassReviewHeaderSubTitle = cohort => {
    let schoolName = cohort.school ? cohort.school.name : ''
    let city = cohort.school.mailingAddress.city ? `, ${cohort.school.mailingAddress.city}` : ''
    let state = cohort.school.mailingAddress.state ? `, ${cohort.school.mailingAddress.state}` : ''
    let schoolNameTitle =
      schoolName.length > 40
        ? `${schoolName.substring(0, 40)}...`
        : schoolName.length > 0
        ? `${schoolName}`
        : schoolName
    return (
      <Box
        component={'p'}
        sx={{ display: { xs: 'grid', sm: 'flex' }, gap: { xs: 0.5, sm: 'auto' } }}
      >
        {schoolNameTitle}
        {_.includes(['appadmin', 'facilitator'], role.toLowerCase()) ? `${city}${state}` : ' '}
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>• </Box>
        <Typography variant='link' onClick={() => setModalSchoolDetailsShow(true)}>
          School Details
        </Typography>
      </Box>
    )
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowToast(false)
  }

  const getTabsWithoutAwardTab = () => {
    return classDetailTabs.filter(tab => tab.eventKey !== 'award-results')
  }

  const modalClose = () => {
    setModalSchoolDetailsShow(false)
  }

  const { loading, error, data } = useQuery(CLASS_DETAILS_QUERY, {
    variables: { id: params.classId },
    onCompleted(data) {
      setCohort(data.cohort)
    }
  })

  if (loading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout>
        <ErrorPage errorCode='403' withInPage={true} />
      </Layout>
    )
  }

  if (data) {
    const cohort = data.cohort
    const projects =
      cohort &&
      cohort.submittedProjects.filter(project => project.submitted && project.photos.length > 0)
    const curatorReviewSend = cohort.reviewed
    const summaryBeenSent = cohort.summarySent
    const classTabs =
      role === 'appadmin' && curatorReviewSend
        ? classDetailTabs
        : ['teacher', 'facilitator'].includes(role) && summaryBeenSent
        ? classDetailTabs
        : getTabsWithoutAwardTab()

    if (role === 'teacher' && !cohort.approved) {
      return (
        <Layout>
          <NoResultListMessage message='You are not authorized to view class details because this class is not approved.' />
        </Layout>
      )
    } else {
      return (
        <Layout>
          {showToast === true ? (
            <ToastNotification
              notificationType={toastType}
              showToast={showToast}
              onClose={handleClose}
              toastMessage={toastMessage}
            />
          ) : null}
          <>
            <Hero
              heroTitle={data.cohort.name}
              heroSubTitle={getClassReviewHeaderSubTitle(cohort)}
              heroTimeline={props.heroTimeline}
              currentTab={currentTab}
              tabChangeHandler={handleTabChange}
              tabs={classTabs}
              goBackPage='/classes'
            />
            <ClassReviewSchoolDetailsModal
              show={modalSchoolDetailsShow}
              onHide={modalClose}
              cohortId={data.cohort.id}
              schoolId={data.cohort.school.id}
            />
            {currentTab === 'class-details' ? (
                <AssignmentDetails
                  cohort={data.cohort}
                  role={role}
                  showToast={showToastMsg}
                  hasClassEnded={DateUtils.isDatePassed(cohort.endDate)}
                />
            ) : currentTab === 'student-list' ? (
              <ClassTrackerStudentList
                cohort={cohort}
                role={role}
                hasClassEnded={DateUtils.isDatePassed(cohort.endDate)}
                classUuid={cohort.uuid}
                showToast={showToastMsg}
              />
            ) : currentTab === 'award-processing' ? (
              <AwardProcessing
                cohort={data.cohort}
                showOnly={true}
                role={role}
                isPublished={containsProperty(data.cohort, 'published')}
                showToast={showToastMsg}
                summaryBeenSent={
                  educatorSummarySent || containsProperty(data.cohort, 'summarySent')
                }
                studentSummaryBeenSent={
                  studentSummarySent || containsProperty(data.cohort, 'sentStudentSummary')
                }
                reviewSent={curatorReviewSend}
                updateParentSummarySentStatus={updateSummarySentStatus}
                tabChangeHandler={handleTabChange}
              />
            ) : currentTab === 'award-results' ? (
              <TeacherAwardResults
                cohort={data.cohort}
                showToast={showToastMsg}
                awardedProjects={awardedProjects(projects)}
                role={role}
              />
            ) : null}
          </>
        </Layout>
      )
    }
  } else {
    return (
      <Layout>
        <ErrorPage errorCode='403' withInPage={true} />
      </Layout>
    )
  }
}

export default inject('userStore', 'breadcrumbStore')(
  observer(withApollo(withRouter(ClassDetails)))
)
