import React from 'react'
import { Box } from '@mui/material'

export const BaseModalFooter = props => {
  return (
    <Box sx={{ 
      borderTop:'0.0625rem solid #d5d5d7',
      width: {xs: 'calc(100% + 48px)', sm: 'calc(100% + 64px)'},
      position: 'relative',
      left: {xs:'-24px', sm: '-32px'},
      padding:{xs: '0 24px', sm: '0 32px'},
      marginTop:{xs: '32px !important'}
    }}>
      {props.children}
    </Box>
    
  );
}