import React, { Component } from 'react'
import Button from '../elements/Button.js'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { withApollo } from '@apollo/client/react/hoc'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import { gql } from '@apollo/client'
import Loading from '../elements/Loading.js'
import PhotoTagListRow from '../molecules/PhotoTagListRow.js'
import PhotoTagModal from '../molecules/PhotoTagModal.js'
import { Typography } from '@mui/material'

class PhotoTagsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      editPhotoTagShow:false,
      photoTagsCount: 0,
      tagId:0,
      tagName:''
    }
  }


  toggleEditEvent = (tagId, tagName) => {
    this.setState({
      editPhotoTagShow:true,
      tagId:tagId,
      tagName:tagName
    })
  }

  render() {
    const { photoTags } = this.props
    let modalClose = () => this.setState({ modalShow: false, tagId:0, tagName:'' })
    let editModalClose = () => this.setState({ editPhotoTagShow:false, tagId:0, tagName:'' })
    return (
      <div className='container__body'>
        <div className='classes__student-list--header'>
          <div className='school-list__header--text'>
            {
              photoTags.length > 0 || this.props.photoTagsCount > 0 ?
                <h1>Photo Tags ({ this.props.photoTagsCount || photoTags.length})</h1>
              : <h1>No Photo Tags</h1>
            }
          </div>
          <div className='school-list__header--button'>
            <div className='classes__header--button'>
              <Button
                variant='outlined'
                title='Add a Tag'
                disabled={false}
                onClick={() => this.setState({ modalShow: true })}
                />
            </div>
          </div>
        </div>
        { photoTags.length > 0 ?
        <div className='school-list__labels'>
          <Row>
            <Col sm={10}>
              <Typography variant="h4">Tag Name</Typography>
            </Col>
          </Row>
        </div> : null }
        {photoTags.map((tag, index) => (
          <PhotoTagListRow
            photoTag={tag.node}
            tagIndex={index}
            showToast={this.props.showToast}
            toggleEditEvent={this.toggleEditEvent}
          />
        ))}
        <PhotoTagModal
          show={this.state.editPhotoTagShow}
          onHide={editModalClose}
          tagId={parseInt(this.state.tagId)}
          tagName={this.state.tagName}
          showToast={this.props.showToast}
        />
        <PhotoTagModal
          show={this.state.modalShow}
          onHide={modalClose}
          tagId={parseInt(this.state.tagId)}
          tagName={this.state.tagName}
          showToast={this.props.showToast}
        />
      </div>
    )
  }
}

export default inject('schoolFilterStore')(observer(withApollo(PhotoTagsList)))
