import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Box, Typography } from '@mui/material'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import pluralize from 'pluralize'
import { PROJECT_DETAILS_QUERY } from '../queries/projectDetailsQueries.js'
import Loading from '../ui/elements/Loading.js'
import SubHeaderLinks from '../ui/elements/SubHeaderLinks.js'
import ToastNotification from '../ui/elements/ToastNotification.js'
import Hero from '../ui/organisms/Hero.js'
import ProjectDetail from '../ui/organisms/ProjectDetail.js'
import Layout from '../ui/template/Layout.js'
import { withRouter } from '../utils/customHoc.js'
import ErrorPage from './ErrorPage.jsx'
import { useParams } from 'react-router-dom'

const ProjectDetailPage = props => {
  const { projectId, currentTab: initialTab } = useParams()
  const { userStore, breadcrumbStore, location, basePath } = props
  const { role } = userStore
  const [projectDetails, setProjectDetails] = useState(null)
  const [showToastFlag, setShowToastFlag] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastType, setToastType] = useState('')
  const [currentTab, setCurrentTab] = useState()

  useEffect(() => {
    setCurrentTab(initialTab || ['curator'].includes(role) ? 'photographs' : 'project-status')
  }, [initialTab, role])

  const { loading, data, error, refetch } = useQuery(PROJECT_DETAILS_QUERY, {
    cacheTime: 0,
    variables: {
      id: projectId
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setProjectDetails(data.project)
      breadcrumbStore.addBreadcrumb({
        pageName:
          role === 'student'
            ? data.project && data.project.cohort.name
            : data.project && data.project.student && data.project.student.name,
        link: location.pathname,
        basePath: basePath,
        isParent: false,
        isActive: true
      })
    }
  })

  const showToast = (toastType, message) => {
    setShowToastFlag(true)
    setToastMessage(message)
    setToastType(toastType)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowToastFlag(false)
  }

  const handleTabChange = newTab => {
    setCurrentTab(newTab)
  }

  const checkStudentSummary = photos => {
    if (photos.length > 0) {
      const studentSummaryLength = photos.filter(photo => photo.sentStudentSummary).length
      if (studentSummaryLength > 0) {
        return true
      }
      return false
    }
    return false
  }

  if (projectDetails) {
    const tabs = [
      {
        eventKey: 'project-status',
        title: projectDetails.submitted ? 'Project Status' : 'Project Progress',
        path: `/project-detail/${projectId}`
      },
      {
        eventKey: 'photographs',
        title: 'Photographs',
        path: `/project-detail/${projectId}/photographs`
      }
    ]
    const schoolName =
      projectDetails.cohort &&
      projectDetails.cohort.school &&
      ['facilitator', 'teacher', 'student', 'curator', 'appadmin'].includes(role)
        ? `${projectDetails.cohort.school.name}`
        : ''

    const schoolCity =
      projectDetails.cohort && projectDetails.cohort.school && ['curator'].includes(role)
        ? projectDetails.cohort.school.mailingAddress
          ? `, ${projectDetails.cohort.school.mailingAddress.city}`
          : null
        : ''

    const schoolState =
      projectDetails.cohort && projectDetails.cohort.school && ['curator'].includes(role)
        ? projectDetails.cohort.school.mailingAddress
          ? `, ${projectDetails.cohort.school.mailingAddress.state}`
          : null
        : ''
    const cohortName = projectDetails.cohort && projectDetails.cohort.name
    const photoCount = projectDetails.photos.length
    const submittedPhotoCount = projectDetails.photos.filter(photo => photo.isShortListed).length
    const isStudentSummarySend = checkStudentSummary(projectDetails.photos)
    const shouldTabShow =
      (isStudentSummarySend && ['student', 'teacher', 'facilitator', 'appadmin'].includes(role)) ||
      (projectDetails.submitted && !isStudentSummarySend && !['curator'].includes(role)) ||
      (!projectDetails.submitted &&
        moment(projectDetails.cohort.endDate, 'YYYY-MM-DD').hours(23).minutes(59).seconds(59).isBefore(moment().format('YYYY-MM-DD')))

    const tabList = tabs

    return (
      <Layout>
        {showToastFlag ? (
          <ToastNotification
            notificationType={toastType}
            showToast={showToastFlag}
            onClose={handleClose}
            toastMessage={toastMessage}
          />
        ) : null}
        <Box>
          <Hero
            heroTitle={
              <Box sx={{ display: 'flex' }} alignItems='center'>
                <Typography variant='h2' className='strong'>
                  {projectDetails && projectDetails.student.name}
                </Typography>
                <Typography
                  variant='h4'
                  className=''
                  sx={{
                    ml: 1,
                    display: {
                      xs: 'none',
                      sm: 'block'
                    }
                  }}
                >
                  (
                  {pluralize(
                    'photographs',
                    role === 'curator' ? submittedPhotoCount : photoCount,
                    true
                  )}
                  )
                </Typography>
              </Box>
            }
            heroSubTitle={
              <Box sx={{ fontSize: { xs: '1.25rem' }, mt: { xs: '24px' } }}>
                {schoolName}
                {schoolCity}
                {schoolState}
                {` • ${cohortName}`}
                <SubHeaderLinks role={role} projectDetails={projectDetails} />
              </Box>
            }
            cohort={projectDetails.cohort}
            currentTab={shouldTabShow ? currentTab : ''}
            tabChangeHandler={shouldTabShow ? handleTabChange : ''}
            tabs={shouldTabShow ? tabList : ''}
          />
        </Box>
        {currentTab === 'project-status' || currentTab === 'photographs' ? (
          <ProjectDetail
            project={projectDetails}
            showToast={showToast}
            role={role}
            currentTab={currentTab}
            refetchProjectData={refetch}
          />
        ) : null}
      </Layout>
    )
  } else if (error) {
    return <ErrorPage errorCode='403' />
  } else if (!projectDetails && !loading)
    return (
      <Typography variant='paragraph' component='p'>
        Error :(
      </Typography>
    )
  else return <Loading />
}

export default inject('userStore', 'breadcrumbStore')(
  observer(withRouter(withApollo(ProjectDetailPage)))
)
