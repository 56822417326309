import React from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import Modal from 'react-bootstrap/Modal'
import { Typography } from '@mui/material'
import Cancel from '../elements/Cancel'

const ChangeUserModal = props => {
  return (
    <Modal
      {...props}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={props.show}
      onClick={props.onClick}
      value={props.value}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <Typography variant='h2'>Change User Information</Typography>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='delete-user__description'>
          <Typography variant='paragraph' sx={{ mb: '1rem' }} component='p'>
            Before you change this user’s account information, please make sure you have asked their
            permission. The user will be notified of any changes made.
          </Typography>
        </div>
        <div className='delete-user__button'>
          <Button
            type='copy'
            title='Save Changes'
            disabled={false}
            onClick={e => {
              props.handelSubmit()
              props.onHide()
            }}
          />
          <Cancel sx={{ ml: 2 }} onClick={props.onHide} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ChangeUserModal
