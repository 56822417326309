import React, { Component } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import UniqueLink from '../elements/UniqueLink.js'
import { Link } from 'react-router-dom'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Check } from 'react-feather'
import { Typography } from '@mui/material'
import { Grid, Box } from '@mui/material'
import BaseModal from '../template/BaseModal';

class InviteStudentModal extends Component {
  constructor(props) {
    super(props)
    this.state = { copied: false }
    this.getInviteURL = this.getInviteURL.bind(this)
  }

  handleCopyClick() {
    this.setState({ copied: true })
  }

  handleAnimationEnd() {
    this.setState({ copied: false })
  }

  getInviteURL() {
    var baseurl = window.location.origin
    let url = baseurl + '/sign-up/' + this.props.classUuid
    return url
  }

  render() {
    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);

    };
    return (
      <BaseModal
        {...this.props}
        show={this.props.show}
        onClick={this.props.onClick}
        value={this.props.value}
        open={this.props.show}
        onClose={handleClose}
        onHide={this.props.onHide}
      >          
        <BaseModal.BaseModalHeader>
          Invite Students to Class
        </BaseModal.BaseModalHeader>
        <BaseModal.BaseModalBody>
          <Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
            <Grid item className='invite-student__description__custom'>
              <Typography variant="paragraph"
                sx={{
                  mb:"1rem",
                }}
                  component="p" >Invite students to join this class by sharing the link below.</Typography>
              <Typography variant="paragraph"
                  sx={{
                  mb:"1rem",
                }}
                component="p" >
                  Students who are new to the <i>wrkxfmly</i> program will be asked to create an account.
              </Typography>
              <Typography variant="paragraph"
            sx={{
              mb:"1rem",
            }}
            component="p" >
              Students who already have a <i>wrkxfmly</i> account can sign in with their existing details, and a new project will be created for them in this class.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9} className='invite-student__copy-link'>
              <UniqueLink randomString={this.getInviteURL()} />
            </Grid>
            <Grid item xs={12} sm={3} className='invite-student__copy-link--button'>
              <Box>
                <Grid container spacing={2} direction={'row'}>
                  <Grid item xs={10} sm={9}>
                  <CopyToClipboard text={this.getInviteURL()} onCopy={this.handleCopyClick.bind(this)}>
                    <Button
                      variant='contained'
                      type='copy'
                      title='Copy Link'
                      disabled={false}
                      sx={{width: '100% !important'}}
                    />
                  </CopyToClipboard>
                  </Grid>
                  <Grid item xs={2} sm={3}>
                    {this.state.copied ? (
                      <span
                        onAnimationEnd={this.handleAnimationEnd.bind(this)}
                        className='copy-link__button--success'
                      >
                        <Check />
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

            <Box sx={{padding:'24px 0 8px', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
              <Typography component={'p'} variant={'p'}>
                To read more about the information we collect from students when they create an account, please see our <Link to='/privacy-policy' target='_blank' rel='noopener noreferrer'>Application Privacy Policy</Link>.
              </Typography>
            </Box>
          {/* <div className='invite-student__description__custom'>
            <Typography variant="paragraph"
            sx={{
              mb:"1rem",
            }}
            component="p" >Invite students to join this class by sharing the link below.</p>
            <Typography variant="paragraph"
            sx={{
              mb:"1rem",
            }}
            component="p" >
            Students who are new to the <i>wrkxfmly</i> program will be asked to create an account.
            </Typography>
            <Typography
            variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
              >
            Students who already have a <i>wrkxfmly</i> account can sign in with their existing details, and a new project will be created for them in this class.
            </p>
          </div> */}
          {/* <div className='invite-student__copy-link'>
            <UniqueLink randomString={this.getInviteURL()} />
          </div> */}
          {/* <div className='invite-student__copy-link--button'>
            <CopyToClipboard text={this.getInviteURL()} onCopy={this.handleCopyClick.bind(this)}>
              <Button variant='contained' type='copy' title='Copy Link' disabled={false} />
            </CopyToClipboard>
            {this.state.copied ? (
              <span
                onAnimationEnd={this.handleAnimationEnd.bind(this)}
                className='copy-link__button--success'
              >
                <Check />
              </span>
            ) : null}
          </div> */}
          {/* <Typography variant="paragraph"
            sx={{
              mb:"1rem",
            }}
            component="p" >
            To read more about the information we collect from students when they create an account, please see our <Link to='/privacy-policy' target='_blank' rel='noopener noreferrer'>Application Privacy Policy</Link>.
          </p> */}
        </BaseModal.BaseModalBody>
        <br/>
      </BaseModal>
    )
  }
}

export default InviteStudentModal
