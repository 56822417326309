import { gql } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import Masonry from '@mui/lab/Masonry'
import {
  Box,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  Typography
} from '@mui/material'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import { Component, Fragment } from 'react'
import { PHOTO_COLLECTION_COUNT_QUERY, PHOTO_COLLECTION_QUERY } from '../queries/collectionQueries'
import FormSelect from '../ui/elements/FormSelect'
import Loading from '../ui/elements/Loading.js'
import Photo from '../ui/elements/Photo'
import PhotoOverlay from '../ui/molecules/PhotoOverlay'
import Hero from '../ui/organisms/Hero'
import NewFullScreenPhotoModal from '../ui/organisms/PhotoDetailsModal/NewFullScreenPhotoModal'
import Layout from '../ui/template/Layout'
import { getCollectionPageYearFilter, getDefaultCollectionYear, getYearPhotoCount } from '../utils/commonFunctions'
import { withRouter } from '../utils/customHoc.js'

const GET_YEARLY_COUNT_QUERY = gql`
query GetYearlyCount($minYear: String!, $type:String!) {
    getYearlyCount(minYear: $minYear, type: $type)
  }
`
class Collection extends Component {
  constructor(props) {
    super(props)
    const { breadcrumbStore, location, basePath } = props
    this.state = {
      display: null,
      gqlRes: null,
      totalPhotos: 0,
      yearPhotoCount: 0,
      batchSize: 9,
      step: 0,
      collectionPhotos: [],
      showPhotoModal: false,
      selectedPhotoId: -1
    }
    breadcrumbStore.resetBreadcrumb()
    breadcrumbStore.addBreadcrumb({
      pageName: 'National Collection',
      link: location.pathname,
      basePath: basePath,
      isParent: true,
      isActive: true
    })
    this.handleScroll = this.handleScroll.bind(this)
  }

  async componentDidMount() {
    const yearly_data = await this.props.client.query({
      query: GET_YEARLY_COUNT_QUERY,
      variables: {
        minYear: "2013",
        type: this.props.type
      },
      fetchPolicy: 'network-only'
    })
    const filterYear = this.props.type === 'national' ? getDefaultCollectionYear(yearly_data.data.getYearlyCount, true) : 'all'

    this.setState({
      ...this.state,
      collectionFiter: getCollectionPageYearFilter(yearly_data.data.getYearlyCount),
      filter: getDefaultCollectionYear(yearly_data.data.getYearlyCount),
      filterYear: filterYear,
      yearPhotoCount: getYearPhotoCount(yearly_data.data.getYearlyCount, filterYear),
      yearsData: yearly_data.data.getYearlyCount
    }, () => {
      this.fetchPhotosDetails()
    })
    window.addEventListener('scroll', this.handleScroll)
  }
  fetchPhotosDetails = async () => {
    await this.fetchMorePhotos(this.state.step)
    const data = await this.props.client.query({
      query: PHOTO_COLLECTION_COUNT_QUERY,
      variables: {
        filter: this.state.filterYear,
        type: this.props.type
      },
      fetchPolicy: 'network-only'
    })
    this.setState({
      ...this.state,
      totalPhotos: data.data.gallerySize,
    })
  }

  componentWillUnmount() {

    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = async e => {
    const elem = e.target.scrollingElement
    const vh = elem.scrollHeight
    const currVh = elem.scrollTop
    const threshold = vh / 4 + 200
    if (currVh > threshold && this.state.loading === false) {
      const batchSize = this.state.batchSize
      const pageNum = this.state.step
      const data = this.state.collectionPhotos
      const photoCount = this.state.yearPhotoCount
      if (batchSize * (pageNum + 1) > photoCount) return
      if (!(data.length >= photoCount)) await this.fetchMorePhotos(pageNum + 1)
      this.setState({
        ...this.state,
        step: pageNum + 1,
        loading: false
      })
    }
  }

  toggleState = payload => {
    if (this.state.display === null) {
      this.setState({
        ...this.state,
        display: payload
      })
    } else {
      this.setState({
        ...this.state,
        display: null
      })
    }
  }

  makePhotosArray = arr => {
    return arr.filter(elem => elem.node).map(elem => ({ ...elem.node }))
  }

  fetchMorePhotos = async (step, loadSize = null) => {
    const { photosStore } = this.props
    const { batchSize, totalPhotos, filterYear, yearPhotoCount } = this.state
    if (step * batchSize > yearPhotoCount) {
      return []
    }
    this.setState({
      ...this.state,
      step: step,
      loading: true
    })

    const data = await this.props.client.query({
      query: PHOTO_COLLECTION_QUERY,
      variables: {
        batchSize: loadSize || batchSize,
        baseBatchSize: batchSize,
        step,
        filter: filterYear,
        type: this.props.type
      },
      fetchPolicy: 'network-only'
    })
    const getIds = data.data.galleryConnectionPhotoIds.map(photo => photo.id)
    photosStore.setPhotoStore('collection', [...getIds])
    return await this.mergeIncomingData(data.data.galleryConnection)
  }

  mergeIncomingData = async (data) => {
    if (!data) {
      this.setState({
        ...this.state,
        loading: false
      })
      return
    }
    const { collectionPhotos } = this.state
    if (
      this.state.step > 0 &&
      data[data.length - 1].id === collectionPhotos[collectionPhotos.length - 1].id
    )
      return

    const ids = collectionPhotos.map(photo => photo.id)
    for (let obj of data) {
      if (!ids.includes(obj.id)) {
        collectionPhotos.push(obj)
        ids.push(obj.id)
      }
    }

    this.setState({
      ...this.state,
      collectionPhotos: collectionPhotos,
      loading: false
    })
  }

  updateDisplay = display => {
    this.setState({
      ...this.state,
      display
    })
  }

  getAdjacentImage = async (direction, photoId) => {
    if (direction && !['prev', 'next'].includes(direction)) return

    const photos = [...this.state.collectionPhotos]
    const currIdx = photos.findIndex(elem => elem.id === photoId)
    if (direction === 'prev') {
      this.setState({
        ...this.state,
        selectedPhotoId: currIdx === 0 ? photos[photos.length - 1].id : photos[currIdx - 1].id
      })
    } else {
      if (this.state.yearPhotoCount - 1 === currIdx) {
        this.setState({ ...this.state, selectedPhotoId: photos[0].id })
        return
      } else if (this.state.yearPhotoCount - 1 >= currIdx && !photos[currIdx + 1]) {
        await this.fetchMorePhotos(this.state.step + 1)
      } else {
        this.setState({
          ...this.state,
          selectedPhotoId: photos[currIdx + 1] && photos[currIdx + 1].id
        })
      }
    }
  }

  thumbnailOnClick = (photo, studentDetails) => {
    this.setState({
      ...this.state,
      selectedPhotoId: photo.id,
      showPhotoModal: true,
      student: studentDetails
    })
  }

  getPhotoList = collection => {
    const endpoint = window.location.pathname.includes('class-review')
    const isCollection = window.location.pathname.includes('collection') || window.location.pathname.includes('covid-collection')
    collection =
      endpoint && collection ? collection.filter(photo => photo.project.submitted) : collection // might want to add submittedUpdatedAt too later

    return collection ?
      (
        <ImageList cols={1} sx={{ overflowY: 'hidden', marginTop: { xs: 5, sm: 6 }, mb: 0, pb: 0}}>
          <Masonry columns={{ xs: 1, sm: 2, lg: 3 }} sx={{m: 0}}>
            {collection.map((photo) => (
              <>
                <ImageListItem key={photo.key}>
                  <PhotoOverlay
                    key={'PhotoOverlay' + photo.id}
                    photo={photo}
                    photoOnClick={() => this.thumbnailOnClick(photo, photo.project.student)}
                    hideStudentSection={
                      _.includes(['student'], this.props.userStore.role) ? true : null
                    }
                  />
                  <Photo
                    key={'Photo' + photo.id}
                    photo={photo}
                    placeholderDark={false}
                    onClick={() => this.thumbnailOnClick(photo, photo.project.student)}
                    isSinglePhotoView={false}
                    viewBadges={true}
                    showAwards={isCollection ? false : true}
                    role={this.props.userStore.role}
                  />
                </ImageListItem>
              </>
            ))}
          </Masonry>
        </ImageList>)
      : null
  }

  getHeaderComponent = () => {
    const { type, userStore } = this.props
    const { isAuthenticated, role } = userStore
    const publicContainerBgColor = type === 'covid' ? '#F15B4E' : '#ffe8d4'
    const publicTitleColor = type === 'covid' ? '#ffffff' : '#00746b'
    const publicHeading = type === 'covid' ? 'COVID-19 Collection' : 'National Collection'
    const publicIntoText = type === 'covid' ?
      <>In the spring of 2020, lives across the globe took a sudden and hard turn due to COVID-19. This
        collection of honest and intimate images by <i>wrkxfmly</i> students records unprecedented times
        from inside the American family.</>
      : <>
        Students from schools across the country have taken part in the <i>wrkxfmly</i>{' '}
        assignment. The outstanding photographs featured here received awards for a combination of technical quality, creative seeing, and insightful personal narrative.
      </>

    return isAuthenticated ?
      <>
        <Box sx={{ backgroundColor: '#f7f7f7' }}>
          <Box padding={{ xs: '3rem 2rem', md: '3rem'}}>
            <Typography variant="h1" sx={{ fontWeight: 'bold !important', fontFamily: 'Calibre Bold,Helvetica,Verdana,sans-serif !important', fontSize: '2rem !important' }}>
              Photograph Collection
            </Typography>
            <Typography variant="h6" width={{ xs: '100%', md: '60%' }} sx={{ fontFamily: 'Calibre,Helvetica,Verdana,sans-serif !important', fontSize: '1.125rem !important', letterSpacing: '0.2 !important', lineHeight: '1.2 !important' }}>
              {['appadmin', 'student', 'curator'].includes(role) ? (
                <>
                  Students across the country have taken part in the <i>wrkxfmly</i> assignment. The outstanding photographs featured here received awards for a combination of technical quality, creative seeing, and insightful personal narrative.
                </>
              ) : ['teacher', 'facilitator'].includes(role) ? (
                <>
                  Students across the country have taken part in the <i>wrkxfmly</i> assignment. The photographs featured here received awards due to their outstanding technical qualities and exceptional narratives.
                  <br />
                  <br />
                  You can also see all photographs submitted by students in your classes, whether or not they received awards.
                </>
              ) : null}
            </Typography>
          </Box>
        </Box></>
      : <>
        <Box
          sx={{
            backgroundColor: publicContainerBgColor,
            clipPath: 'polygon(100% 0, 100% 89%, 76% 100%, 0 90%, 0 0)',
            p: { xs: '80px 32px 120px', md: '120px 32px 160px', lg: '144px 32px 144px'},
          }}
        >
          <Grid container gap={{ xs: 4, md: 0 }} columnGap={{lg: 4}} sx={{ml: {sm:3, lg: 3 }}}>
            <Grid item xs={12} sm={10} md={6} lg={4}>
              <Typography
                variant="h1"
                className='extra-size'
                sx={{
                  color: publicTitleColor,
                  lineHeight: 0.9
                }}
              >
                {publicHeading}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={10} md={6} lg={5}>
              <Typography
                variant="h2"
                sx={{
                  fontFamily: '"Calibre Medium" !important',
                  fontSize: { xs: '1.25rem !important', sm: '1.5rem !important', md: '1.25rem !important', lg: '1.5rem !important' },
                  color: '#2d2d2d !important',
                  ml: {md: 2, lg: 0}
                }}
              >
                {publicIntoText}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
  }

  onFilterChange = (event) => {
    const { yearsData } = this.state

    const filterObj = this.state.collectionFiter.filter(item => {
      return (item.value === event.target.value) ? item : null
    })
    this.setState({
      filter: filterObj ? filterObj[0] : null,
      filterYear: event.target.value,
      collectionPhotos: [],
      loading: false,
      yearPhotoCount: getYearPhotoCount(yearsData, event.target.value)
    }, () => {
      this.fetchMorePhotos(0)
    })
  }

  getSelectOptions = () => {
    let options = []
    this.state.collectionFiter && this.state.collectionFiter.map((option, index) => (
      options.push({ value: option.year, label: option.label })
    ))
    return options
  }

  render() {
    const { type, userStore } = this.props
    const { isAuthenticated } = userStore
    if (this.state.collectionPhotos) {
      let { collectionPhotos } = this.state
      const photoList = this.getPhotoList(collectionPhotos)
      return (
        <Layout>
          {isAuthenticated ? <Hero heroTitle='National Collection' /> : null}
          <Box className={isAuthenticated ? '' : ''}>
            {this.getHeaderComponent()}
            {
              isAuthenticated && !window.location.pathname.includes('collection') &&
                !window.location.pathname.includes('covid-collection') &&
                !window.location.pathname.includes('class-detail') ? <Box sx={{ backgroundColor: '#f7f7f7' }} padding={{ xs: '0 32px 32px', md: '0 48px 48px' }}>
                <Typography variant='h1' sx={{ fontWeight: '600 !important', fontFamily: 'Calibre Semibold,Helvetica,Verdana,sans-serif !important', fontSize: '2rem !important' }}>
                  Photographs ({this.state.totalPhotos})
                </Typography>
              </Box> : null
            }

            <Box onScroll={this.handleScroll} sx={{ backgroundColor: '#fff', p: { xs: '40px 32px 63px', sm: '40px 32px 65px', md: '80px 48px 65px' } }}>
              <Box>
                <Grid container>
                  <Grid item xs={2} sm={7}></Grid>
                  <Grid item xs={12} sm={5}>
                    {type === 'national' && (
                      <FormSelect
                        name="filter"
                        variant="filled"
                        value={this.state.filter ? this.state.filter.year : null}
                        defaultValue={this.state.filter ? this.state.filter.year : null}
                        onChange={e => this.onFilterChange(e)}
                        inputProps={{ 'aria-label': 'Without label', sx: { py: 2 } }}
                        options={this.getSelectOptions()}
                        fullWidth={true}
                      />
                    )}
                  </Grid>
                </Grid>
                {photoList}
              </Box>

              {this.state.loading && this.state.yearPhotoCount > 0 ? (
                <Box className='loader--wrapper'>
                  <Loading />
                </Box>
              ) : null}
            </Box>
            {/* </Box> */}
          </Box>

          {this.state.selectedPhotoId !== -1 ? (
            <NewFullScreenPhotoModal
              show={this.state.showPhotoModal}
              photoId={this.state.selectedPhotoId}
              totalCount={this.state.yearPhotoCount}
              closeModal={() => {
                this.setState(
                  { ...this.state, fadeOut: true },
                  () => {
                    setTimeout(
                      () =>
                        this.setState({
                          showPhotoModal: false,
                          selectedPhotoId: -1,
                          fadeOut: false
                        }),
                      500
                    )
                  }
                )
              }}
              isModal={true}
              placeholderDark={false}
              componentCallFrom='collection'
              toggleParentModalState={(pushState = true, shouldRefetch = false) => {
                if (pushState) {
                  window.history.pushState(
                    null,
                    '',
                    this.props.backToPageStore.activePage
                  )
                }
                this.setState({ ...this.state, fadeOut: true }, () => {
                  setTimeout(
                    () =>
                      this.setState({
                        showPhotoModal: false,
                        selectedPhotoId: -1,
                        fadeOut: false
                      }),
                    500
                  )
                })
              }}
              photoCurrentIndex={collectionPhotos.findIndex(
                elem => elem.id === this.state.selectedPhotoId
              )}
              animation={false}
              className={`animated ${this.state.showPhotoModal && !this.state.fadeOut
                ? 'fadeInUpBig'
                : 'fadeOutDownBig'
                }`}
              student={this.state.student}
            />
          ) : null}
        </Layout>
      )
    } else {
      return (
        <Layout>
          <Box className='spinner-container'>
            <CircularProgress
              variant='indeterminate'
              disableShrink
              className='collections-spinner'
              size={50}
              thickness={8}
            />
          </Box>
        </Layout>
      )
    }
  }
}

export default inject('photosStore', 'userStore', 'photoCollectionStore', 'backToPageStore', 'breadcrumbStore')(observer(withApollo(withRouter(Collection))))