import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Switches from './Switches'
import variables from '../settings/_variables.scss'
import { Box } from '@mui/material'
import DateUtils from '../../utils/DateUtils'
import moment from 'moment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0076BB'
    },
    error: {
      main: `${variables.redError}`,
      color: `${variables.redError}`
    }
  },
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  },
  components: {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '&.MuiPickersPopper-root': {
            zIndex: 1350
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: '1.125rem',
          color: `${variables.navigationGrey}`,
          fontFamily: `${variables.sansSerif}`
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px !important',
          color: `${variables.redError}`
        }
      }
    }
  }
})

const DatePicker = props => {
  const [value, setValue] = useState(props.value
    ? moment.isMoment(props.value)
      ? props.value
      : DateUtils.createMoment(props.value)
    : undefined)

  useEffect(() => {
    setValue(
      props.value
        ? moment.isMoment(props.value)
          ? props.value
          : DateUtils.createMoment(props.value)
        : undefined
    )
  }, [props.value])

  const handleChange = newValue => {
    setValue(newValue)
    props.onChange(moment(newValue).format('YYYY-MM-DD'))
  }

  return (
    <>
      {props.labelRadioButton ? (
        <Box sx={{ mb: { xs: 2 } }}>
          <Switches
            placement='end'
            height={props.SwitchHeight}
            alignitems={props.switchAlignItems}
            label={props.switchLabel}
            checked={props.secondMidVisitRequired}
            onChange={props.switchChange}
            disabled={false}
          />
        </Box>
      ) : (
        ''
      )}
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDatePicker
            timezone={'America/Los_Angeles'}
            labelId={props.labelId}
            name={props.name}
            label={props.label}
            value={value ? DateUtils.createMoment(value).toDate() : undefined}
            onChange={handleChange}
            disabled={props.disabled}
            placeholder='mm/dd/yyyy'
            format='MM/dd/yyyy'
            error={props.error}
            minDate={
              props.minDate
                ? DateUtils.createMoment(props.minDate).toDate()
                : DateUtils.createMoment('1900-01-01').toDate()
            }
            maxDate={
              props.maxDate
                ? DateUtils.createMoment(props.maxDate).toDate()
                : DateUtils.createMoment('2100-01-01').toDate()
            }
            inputProps={{ 'aria-label': props.label }}
            slotProps={{ textField: { variant: 'filled', helperText: props.helperText } }}
            disablePast={props.disablePast || false}
            sx={{
              svg: { color: props.error ? `${variables.redError} !important` : 'inherit' },
              label: { color: props.error ? `${variables.redError} !important` : 'inherit' },
              width: '100%',
              mt: 0,
              ...props.sx
            }}
            className={props.className}
            views={['year', 'month', 'day']}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </>
  )
}

DatePicker.propTypes = {
  classes: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  // minDate: PropTypes.string,
  // maxDate: PropTypes.string,
  labelRadioButton: PropTypes.bool,
  SwitchHeight: PropTypes.number,
  switchAlignItems: PropTypes.string,
  switchLabel: PropTypes.string,
  secondMidVisitRequired: PropTypes.bool,
  switchChange: PropTypes.func,
  sx: PropTypes.object,
  className: PropTypes.string,
  disablePast: PropTypes.bool
}

export default DatePicker
