class DownloadUtils {

  static async toDataURL(url) {
    const res = await fetch(url, {
      'cache-control': 'no-store',
    }).then(r => r.blob());
    if (!res) return

    const blob = res
    if (!blob) return

    return URL.createObjectURL(blob)
  }

  static async downloadFileAndSaveAs(url, name) {
    const objectURL = await this.toDataURL(url)
    if (!objectURL) return
    const a = document.createElement('a')
    a.href = objectURL
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export default DownloadUtils
