import moment from 'moment'
import React, { useState } from 'react'
import UseAgreementDropDown from '../../../elements/UseAgreementDropDown'
import { Typography, Box, Grid } from '@mui/material'
import ApproveOrRejectFormModal from '../../../molecules/ApproveOrRejectFormModal'
import DeleteUseAgreementModal from '../../../molecules/DeleteUseAgreementModal'
import ResendUseAgreementModal from '../../../molecules/ResendUseAgreementModal'
import variable from './../../../settings/_variables.scss'
import { getFormattedStatus } from './../../../../utils/commonFunctions'

const UseAgreementListRowSection = props => {
  const { photoId, useAgreement, isStudentMinor, role, student, refetchUaData } = props
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [showResendModal, setShowResendModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const getSignedStatusMessage = (useAgreement, student) => {
    const isOfflineOrPdf = ['offline', 'pdf'].includes(useAgreement.sentVia)

    let statusMessage = ''

    if (!isOfflineOrPdf) {
      statusMessage = `Granted by ${
        !['self'].includes(useAgreement.sentVia)
          ? useAgreement.signeeFirstName + ' ' + useAgreement.signeeLastName
          : student && student.name
      }${
        useAgreement.signedon
          ? ` on ${moment(useAgreement.signedon, 'YYYY-MM-DD').format('MMMM D, YYYY')}`
          : ''
      }`
    } else {
      statusMessage = `PDF Uploaded${
        useAgreement.signedon
          ? ` on ${moment(useAgreement.signedon, 'YYYY-MM-DD').format('MMMM D, YYYY')}`
          : ''
      }`
    }

    return `${statusMessage}`
  }

  return (
    <Box component={'div'} className='model-release__list-body-section'>
      {
        <>
          {useAgreement && useAgreement.uuid ? (
            <Grid
              container
              className='model-release__list-history-section'
              sx={{
                mt: { xs: 1 },
                py: { xs: 2, sm: 3 },
                px: { xs: 1, sm: 2 },
                border: `${variable.borderThinGrey}`,
                backgroundColor: `${variable.backgroundGrey}`
              }}
            >
              <Grid item xs={10}>
                <Box
                  component={'div'}
                  className={`model-release__list-history-mr-status ${
                    useAgreement.uaStatus === 'Inactive'
                      ? 'model-release__list-history-mr-status--inactive'
                      : ''
                  }`}
                >
                  {isStudentMinor ||
                  (useAgreement?.signedon &&
                    moment(useAgreement.signedon, 'YYYY-MM-DD').diff(student.dob, 'years') < 18) ||
                  (!useAgreement?.signedon &&
                    moment(useAgreement.createdAt, 'YYYY-MM-DD').diff(student.dob, 'years') <
                      18) ? (
                    !['offline', 'pdf'].includes(useAgreement.sentVia) ? (
                      ['appadmin', 'student'].includes(role) ? (
                        <Typography variant='paragraph' component='p'>
                          Use Agreement sent to{' '}
                          <Box component={'strong'} sx={{ wordBreak: { xs: 'break-word' } }}>
                            {useAgreement.sentTo}
                          </Box>{' '}
                          on {moment(useAgreement.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                        </Typography>
                      ) : (
                        <Typography variant='paragraph' component='p'>
                          Use Agreement sent by{' '}
                          {useAgreement.sentVia === 'email' ? 'email' : 'text'} on{' '}
                          {moment(useAgreement.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}.
                        </Typography>
                      )
                    ) : (
                      <Typography variant='paragraph' component='p'>
                        Use Agreement PDF uploaded on{' '}
                        {moment(useAgreement.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                      </Typography>
                    )
                  ) : 
                    !['offline', 'pdf'].includes(useAgreement.sentVia) ? (
                    ['appadmin', 'student', 'teacher', 'facilitator'].includes(role) ? (
                    <Typography variant='paragraph' component='p'>
                      Use Agreement signed by student on{' '}
                      {moment(useAgreement.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}.
                    </Typography>
                  ) : null) : (
                    <Typography variant='paragraph' component='p'>
                      Use Agreement PDF uploaded on{' '}
                      {moment(useAgreement.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                    </Typography>
                  )
                }
                </Box>

                <Box component={'div'} sx={{ mt: { xs: 3, sm: 2 } }}>
                  <Typography variant='status' component={'div'}>
                    {useAgreement.signed
                      ? getFormattedStatus(
                          'CheckCircle',
                          getSignedStatusMessage(useAgreement, student)
                        )
                      : useAgreement.signed === false
                      ? getFormattedStatus(
                          'Slash',
                          `Declined by ${
                            !['self'].includes(useAgreement.sentVia)
                              ? useAgreement.signeeFirstName + ' ' + useAgreement.signeeLastName
                              : student.name
                          } ${
                            useAgreement.signedon
                              ? ` on ${moment(useAgreement.signedon, 'YYYY-MM-DD').format(
                                  'MMMM D, YYYY'
                                )}`
                              : null
                          }`
                        )
                      : getFormattedStatus(
                          'Clock',
                          `Signer Has Not Responded ${
                            useAgreement.uaStatus === 'Inactive' ? ' (Expired)' : ''
                          }`
                        )}
                    {useAgreement.signed ? (
                      <Box component={'div'} className='model-release__list--admin-decision-status'>
                        <Typography variant='h4' component='div' className='semi-strong'>
                          {useAgreement.adminDecision === 'Pending'
                            ? ['appadmin'].includes(role)
                              ? getFormattedStatus(
                                  'AlertTriangle',
                                  `Approve or Reject Use Agreement`
                                )
                              : getFormattedStatus(
                                  'Clock',
                                  `Working Assumptions Is Reviewing Use Agreement`
                                )
                            : useAgreement.adminDecision === 'Approve'
                            ? getFormattedStatus(
                                'CheckCircle',
                                `Approved by Working Assumptions
                                ${
                                  useAgreement.adminDecisionOn
                                    ? ` on ${moment(
                                        useAgreement.adminDecisionOn,
                                        'YYYY-MM-DD'
                                      ).format('MMMM D, YYYY')}`
                                    : null
                                }`
                              )
                            : useAgreement.adminDecision == 'Reject'
                            ? getFormattedStatus(
                                'Slash',
                                `Rejected by Working Assumptions
                                ${
                                  useAgreement.adminDecisionOn
                                    ? ` on ${moment(
                                        useAgreement.adminDecisionOn,
                                        'YYYY-MM-DD'
                                      ).format('MMMM D, YYYY')}`
                                    : null
                                }`
                              )
                            : null}
                        </Typography>
                        {useAgreement.adminDecision === 'Reject' ? (
                          <Typography variant='note' component='p' sx={{ mt: { xs: 2, sm: 1 } }}>
                            {useAgreement.adminRejectionNote ? useAgreement.adminRejectionNote : ''}
                          </Typography>
                        ) : null}
                      </Box>
                    ) : null}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                {(['student', 'teacher', 'facilitator'].includes(role) &&
                  useAgreement.signed === null &&
                  useAgreement.uaStatus === 'Inactive') ||
                (['teacher', 'facilitator'].includes(role) &&
                  useAgreement.signed === null &&
                  ['Inactive', 'Active'].includes(useAgreement.uaStatus)) ? null : (
                  <UseAgreementDropDown
                    useAgreement={useAgreement}
                    setShowApprovalModal={setShowApprovalModal}
                    setShowDeleteModal={setShowDeleteModal}
                    setShowResendModal={setShowResendModal}
                    role={role}
                  />
                )}
              </Grid>
            </Grid>
          ) : null}
        </>
      }
      <ApproveOrRejectFormModal
        uuid={useAgreement && useAgreement.uuid ? useAgreement.uuid : null}
        show={showApprovalModal}
        onHide={() => setShowApprovalModal(false)}
        entityType='ua'
        refetchUaData={refetchUaData}
      />
      <DeleteUseAgreementModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        photoId={photoId}
        assetId={useAgreement?.assetId}
        uaUuid={useAgreement && useAgreement.uuid ? useAgreement.uuid : null}
        refetchUaData={refetchUaData}
      />
      <ResendUseAgreementModal
        show={showResendModal}
        onHide={() => setShowResendModal(false)}
        photoId={photoId}
        useAgreement={useAgreement}
        uaUuid={useAgreement && useAgreement.uuid ? useAgreement.uuid : null}
        refetchUaData={refetchUaData}
      />
    </Box>
  )
}

export default UseAgreementListRowSection
