import React, { Component } from 'react'
import FacilitatorDashboardCard from '../molecules/FacilitatorDashboardCard'
import { getTextContentByPage } from '../../content/textContent'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ChevronRight, ChevronLeft } from 'react-feather'
import { Box, Typography } from '@mui/material';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const CustomRightArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <div onClick={() => onClick()} className='upcoming__visit--right-arrow'> <ChevronRight /></div>
};

const CustomLeftArrow = ({ onClick, ...rest }) => {
  const {
    onMove,
    carouselState: { currentSlide, deviceType }
  } = rest;
  // onMove means if dragging or swiping in progress.
  return <div onClick={() => onClick()} className='upcoming__visit--left-arrow'> <ChevronLeft /></div>
};


class UpcomingVisits extends Component {
  constructor(props) {
    super(props)
    this.state={
      cohorts:props.cohorts
    }
  }
  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (prevState.cohorts.length !== nextProps.cohorts.length) {
      return {
        cohorts:nextProps.cohorts
      }
    } else return null
  }
  render() {
    const { role } = this.props
    const upcomingVisitInro = this.state.cohorts.length > 0 ? 'UPCOMINGVISITS' : 'NOUPCOMINGVISITS'
    return (
      <Box>
        <Box sx={{ mb: { xs: 4, sm: 5, md: 4 } }}>
          <Typography variant='h2'>Upcoming Class Visits</Typography>
          <Typography variant='paragraph' component='p' sx={{ mt: 2 }}>
            {getTextContentByPage('CLASSLIST', role.toUpperCase(), upcomingVisitInro)}
          </Typography>
        </Box>
        {this.state.cohorts.length > 0 ? (
          <Box sx={{ mb: { xs: 6, sm: 6, md: 6 }}}>
            <Carousel
              responsive={responsive}
              arrows={false}
              renderDotsOutside={true}
              // customRightArrow={<CustomRightArrow />}
              // customLeftArrow={<CustomLeftArrow />}
              showDots={true}
              autoPlay={false}
              shouldResetAutoplay={false}
              dotListClass='wa-upcoming-custom-dot-container'
            >
              {this.state.cohorts &&
                this.state.cohorts.map(c => (
                  <FacilitatorDashboardCard cohort={c} role={role} key={`${c.id}-${parseInt(Math.floor(Math.random() * Math.random()))}`}/>
                ))}
            </Carousel>
          </Box>
        ) : null}
      </Box>
    )
  }
}

export default UpcomingVisits
