import React, { Component } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import Modal from 'react-bootstrap/Modal'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Typography } from '@mui/material'


const MUTATION = gql`
  mutation DeleteUserMutation($id: ID!, $opType:String!) {
    deleteUser(id: $id, opType: $opType) {
      id
    }
  }
`
class DeleteUserModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accountDisabledAt: props.accountDisabledAt
    }
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.accountDisabledAt !== this.state.accountDisabledAt) {
      this.setState({
        accountDisabledAt: nextProps.accountDisabledAt
      })
    }
  }
  render() {
    const { accountDisabledAt } = this.state
    return (
    <Mutation
        mutation={MUTATION}
        onCompleted={() => {
          this.props.refetchData()
          this.props.onHide()
          let message = accountDisabledAt ? 'User Enabled' : 'User Disabled'
          this.props.showToast('success', message)
        }}
      >
        {(onMutate, { data }) => {
          return (
            <Modal
              {...this.props}
              size='lg'
              aria-labelledby='contained-modal-title-vcenter'
              centered
              show={this.props.show}
              onClick={this.props.onClick}
              value={this.props.value}
            >
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  <Typography variant="h2">{accountDisabledAt ? 'Enable User' : 'Disable User'}</Typography>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='delete-user__description'>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                    >
                    This will {accountDisabledAt ? 'enable' : 'disable'} this user’s account.
                  </Typography>
                </div>
                <div className='delete-user__button'>
                  <Button
                    type='copy'
                    title={accountDisabledAt ? 'Enable User' : 'Disable User'}
                    disabled={false}
                    onClick={() =>
                      onMutate({
                        variables: {
                          id: this.props.userId,
                          opType: accountDisabledAt ? 'enable' : 'disable'
                        },
                        refetchQueries: [`getAdmins`]
                      })
                    }
                  />
                  {'  '}
                  <Typography variant="h4"className='semi-strong link' ml={2} onClick={() => this.props.onHide()}>
                    Cancel
                  </Typography>
                </div>
              </Modal.Body>
            </Modal>
          )
        }}
      </Mutation>
    )
  }
}

export default DeleteUserModal
