import { withApollo } from '@apollo/client/react/hoc'
import { Box, Grid, Typography } from '@mui/material'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { getTextContentByPage } from '../../content/textContent.js'
import { GET_STUDENTS_QUERY, PRESENTATION_QUERY } from '../../queries/classDetailsQueries'
import Loading from '../../ui/elements/Loading.js'
import GATag from '../../utils/GATag.js'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import ClassSubTabs from '../molecules/ClassSubTabs.js'
import ClassTrackerStudentListRow from '../molecules/ClassTrackerStudentListRow.js'
import EmptyListDesign from '../molecules/EmptyListDesign.js'
import InviteStudentModal from '../molecules/InviteStudentModal.js'
import SendMessageModal from '../molecules/SendMessageModal.js'
import './classTrackerStudentList.scss'
import { useQuery } from '@apollo/client'
import { withRouter } from '../../utils/customHoc.js'
import NewFullScreenPhotoModal from './PhotoDetailsModal/NewFullScreenPhotoModal.js'
import DateUtils from '../../utils/DateUtils.js'

const ClassTrackerStudentList = props => {
  const { role, params, photosStore } = props

  const [cohort, setCohort] = useState(null)
  const [allPhotoCount, setAllPhotoCount] = useState(0)
  const [finalsPhotoCount, setFinalsPhotoCount] = useState(0)
  const [classSharePhotoCount, setClassSharePhotoCount] = useState(0)
  const [awardedPhotoCount, setAwardedPhotoCount] = useState(0)
  const [modalShow, setModalShow] = useState(false)
  const [photoModalShow, setPhotoModalShow] = useState(false)
  const [modalFadeOut, setModalFadeOut] = useState(false)
  const [modalSendMessage, setModalSendMessage] = useState(null)
  const [currentFilter, setCurrentFilter] = useState('all')
  const [filtersArr, setFiltersArr] = useState([true, false, false, false])
  const [currentFilterPhotoCount, setCurrentFilterPhotoCount] = useState(0)
  const [selectedPhotoId, setSelectedPhotoId] = useState(-1)
  const [cohortSummary] = useState(props.cohort.summarySent)
  const [checkedBoxes, setCheckedBoxes] = useState({})
  const [checkedReminder, setCheckedReminder] = useState(false)
  const [filterPhotoIds, setFilterPhotoIds] = useState([])
  const [expandAll, setExpandAll] = useState(false)

  const { loading, errors, data, refetch: refetchCohortData } = useQuery(GET_STUDENTS_QUERY, {
    variables: {
      id: params.classId
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setCohort(data.cohort)
      setAllPhotoCount(data.allPhotoCount)
      setFinalsPhotoCount(data.finalPhotoCount)
      setClassSharePhotoCount(data.classSharePhotoCount)
      if ((['teacher', 'facilitator'].includes(role) && cohortSummary) || role === 'appadmin') {
        setAwardedPhotoCount(data.awardedPhotoCounts)
      }
    }
  })

  const {
    loading: filterLoading,
    errors: filterError,
    data: filterData,
    refetch: filterRefetch
  } = useQuery(PRESENTATION_QUERY, {
    variables: {
      id: params.classId,
      filter: currentFilter
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setFilterPhotoIds(data.filteredPhotosByCohortId)
      setCurrentFilterPhotoCount(data.filteredPhotosByCohortId.length)
      const getIds = data.filteredPhotosByCohortId.map(photo => photo.id)
      photosStore.setPhotoStore('studentTracker', [...getIds])
    }
  })

  useEffect(() => {
    filterRefetch()
  }, [currentFilter])

  const resetCheckedBoxes = () => {
    const { students } = cohort
    let checkedBoxes = {}
    students.forEach(student => (checkedBoxes[student.id] = false))
    setCheckedBoxes({})
    setCheckedReminder(false)
  }

  const updatedCheckedBoxes = studentId => {
    let newState = Object.assign({}, checkedBoxes)
    newState[studentId] = !newState[studentId]
    const { students } = cohort
    let newCheckedBoxes = Object.assign({}, newState)
    students.forEach(student => {
      if (newCheckedBoxes[student.id] === undefined) {
        newCheckedBoxes[student.id] = false
      }
    })
    setCheckedBoxes(newCheckedBoxes)
    setCheckedReminder(!Object.values(newCheckedBoxes).includes(false))
  }

  const handelTabChange = event => {
    if (event !== currentFilter) {
      const filters = ['all', 'finals', 'classShare', 'awarded']
      const targetIdx = filters.indexOf(event)
      const newState = [false, false, false, false]
      newState[targetIdx] = true
      setCurrentFilter(filters[targetIdx])
      setFiltersArr(newState)
      resetCheckedBoxes()
    }
  }

  const showPhotoModal = () => {
    if (filterPhotoIds.length > 0) {
      setSelectedPhotoId(filterPhotoIds[0].id)
    }

    setPhotoModalShow(true)

    let presentTypeContext = 'all-photos'
    if (currentFilter === 'finals') {
      presentTypeContext = 'final-photos'
    } else if (currentFilter === 'classShare') {
      presentTypeContext = 'shared-photos'
    } else if (currentFilter === 'awarded') {
      presentTypeContext = 'awarded-photos'
    }
    GATag.setEvent({
      category: 'class',
      action: 'class-presentation',
      label: presentTypeContext,
      value: 1
    })
  }

  const tabs = [
    {
      eventKey: 'all',
      title: 'All (' + allPhotoCount + ')',
      count: allPhotoCount
    },
    {
      eventKey: 'finals',
      title: 'In Shortlists (' + finalsPhotoCount + ')',
      count: finalsPhotoCount
    },
    {
      eventKey: 'classShare',
      title: 'Shared with Class (' + classSharePhotoCount + ')',
      count: classSharePhotoCount
    },
    {
      eventKey: 'awarded',
      title: 'Awarded (' + awardedPhotoCount + ')',
      count: awardedPhotoCount
    }
  ]

  const checkAllCheckedBoxes = e => {
    const { students } = cohort
    let checkedBoxes = {}
    students.forEach(student => (checkedBoxes[student.id] = e.target.checked))
    setCheckedReminder(e.target.checked)
    setCheckedBoxes(checkedBoxes)
  }

  const containsProperty = (cohort, property) => {
    return (
      cohort.submittedProjects
        .map(proj => proj.photos.filter(photo => photo[property]))
        .filter(photosArr => photosArr.length > 0).length > 0
    )
  }

  const isDateSameOrAfterCurrentDate = (date) => {
    return moment(DateUtils.createMoment(date), 'day').isSameOrAfter(moment(DateUtils.createMoment().toString()), 'day')
  }

  if (loading) return <Loading />
  if (errors) return <>Error in loading</>
  if (data) {
    const { students, endDate, approved } = data.cohort
    const selectedStudents = students
      .filter(student => checkedBoxes[student.id])
      .map(student => student.userId)
    let checkedStudents = Object.keys(checkedBoxes).filter(key => checkedBoxes[key])

    return (
      <Box sx={{ pb: { xs: 10, md: 9 } }}>
        <Box sx={{ px: { xs: 4, md: 6 } }}>
          <Grid container sx={{ mt: { xs: 5, md: 6, lg: 10 } }}>
            <Grid item xs={12} sm={10} md={isDateSameOrAfterCurrentDate(endDate) && approved ? 7 : 8}>
              <Typography variant='h1' className='bold'>
                Students in This Class ({students.length})
              </Typography>
              <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 } }}>
                {!isDateSameOrAfterCurrentDate(endDate)
                  ? getTextContentByPage('CLASSDETAILS', 'STUDENTLIST', 'PAST_CLASS')
                  : getTextContentByPage('CLASSDETAILS', 'STUDENTLIST', 'CURRENT_CLASS')}
              </Typography>
            </Grid>

            {students.length > 0 ? (
              <Grid
                item
                md={isDateSameOrAfterCurrentDate(endDate) && approved ? 3 : 4}
                sx={{ display: { xs: 'none', md: 'block' }, textAlign: { md: 'right' } }}
              >
                <Button
                  title='Email Selected Students'
                  onClick={() => setModalSendMessage(true)}
                  className={`class-tracker__subheading__button-container__send-message-button ${
                    role === 'facilitator' ? 'facilitator-class-tracker' : ''
                  }`}
                  disabled={checkedStudents.length < 1}
                  sx={{
                    width: { xs: '100%', md: 'auto' },
                    mr: {
                      md: isDateSameOrAfterCurrentDate(endDate) && approved ? 4 : 0
                    }
                  }}
                />
                <SendMessageModal
                  show={modalSendMessage}
                  onHide={() => setModalSendMessage(false)}
                  userIds={selectedStudents}
                  fromClassList={true}
                />
              </Grid>
            ) : null}

            {isDateSameOrAfterCurrentDate(endDate) && approved && (
              <Grid item xs={12} sm={6} md={2} display={{ xs: 'none', md: 'block' }}>
                <Button
                  variant='contained'
                  title='Get Invite Link'
                  onClick={() => setModalShow(true)}
                  sx={{ width: { xs: '100%' } }}
                />
                <InviteStudentModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  classUuid={props.classUuid}
                />
              </Grid>
            )}
          </Grid>
          {students.length > 0 ? (
            <Box sx={{ mt: { xs: 5 } }}>
              <Typography variant='h3' className='semi-strong' sx={{ pb: 0, mb: 2 }}>
                Filter and Present Photographs
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12} display={{ md: 'flex' }}>
                  <ClassSubTabs
                    tabs={tabs}
                    handelTabChange={tabKey => handelTabChange(tabKey)}
                    currentTab={currentFilter}
                  />
                  <Box display={{ xs: 'none', md: 'block' }} ml={4.1}>
                    <Button
                      variant='contained'
                      title='Present Photographs'
                      onClick={showPhotoModal}
                      disabled={currentFilterPhotoCount < 1}
                      color='secondary'
                      sx={{ width: { xs: '100%', md: 'auto' } }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} display={{ xs: 'block', md: 'none' }}>
                  <Button
                    variant='contained'
                    title='Present Photographs'
                    onClick={showPhotoModal}
                    disabled={currentFilterPhotoCount < 1}
                    color='secondary'
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : null}

          <Grid container spacing={2} sx={{ mt: { xs: 3 } }}>
            {students.length > 0 ? (
              <Grid item xs={12} sm={6} display={{ xs: 'block', md: 'none' }}>
                <Button
                  title='Email Selected Students'
                  onClick={() => setModalSendMessage(true)}
                  className={`class-tracker__subheading__button-container__send-message-button ${
                    role === 'facilitator' ? 'facilitator-class-tracker' : ''
                  }`}
                  disabled={checkedStudents.length < 1}
                  sx={{ width: { xs: '100%' } }}
                />
                <SendMessageModal
                  show={modalSendMessage}
                  onHide={() => setModalSendMessage(false)}
                  userIds={selectedStudents}
                  fromClassList={true}
                />
              </Grid>
            ) : null}
            {isDateSameOrAfterCurrentDate(endDate) && approved && (
              <Grid item xs={12} sm={6} display={{ md: 'none' }}>
                <Button
                  variant='contained'
                  title='Get Invite Link'
                  onClick={() => setModalShow(true)}
                  sx={{ width: { xs: '100%' } }}
                />
                <InviteStudentModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  classUuid={props.classUuid}
                />
              </Grid>
            )}
          </Grid>
          {selectedPhotoId !== -1 ? (
            <NewFullScreenPhotoModal
              show={photoModalShow}
              closeModal={() => {
                setModalFadeOut(true)
                setTimeout(() => {
                  setPhotoModalShow(false)
                  setModalFadeOut(false)
                  setSelectedPhotoId(-1)
                }, 500)
              }}
              photoId={selectedPhotoId}
              isModal={true}
              placeholderDark={true}
              showToast={props.showToast}
              totalCount={currentFilterPhotoCount}
              componentCallFrom='studentTracker'
              toggleParentModalState={(pushState = true, shouldRefetch = false) => {
                setModalFadeOut(true)
                setTimeout(() => {
                  setPhotoModalShow(false)
                  setSelectedPhotoId(-1)
                  setModalFadeOut(false)
                }, 500)
              }}
              photoCurrentIndex={filterPhotoIds.findIndex(elem => elem.id === selectedPhotoId)}
              animation={false}
              className={`animated ${
                photoModalShow && !modalFadeOut ? 'fadeInUpBig' : 'fadeOutDownBig'
              }`}
              student={props.student}
            />
          ) : null}
        </Box>
        {students.length > 0 ? (
          <Box sx={{ mt: { xs: 5 }, px: { md: 6 } }}>
            <Box sx={{ px: { xs: 3, lg: 2 }, pr: { md: 2 } }}>
              <Grid container spacing={{ md: 2 }}>
                <Grid
                  item
                  xs={1}
                  sx={{ alignSelf: 'center', textAlign: { md: 'end', lg: 'center' } }}
                >
                  <FormCheckbox
                    checked={checkedReminder}
                    onChange={checkAllCheckedBoxes}
                    value='checkedReminder'
                    sxcheckbox={{
                      '& .MuiSvgIcon-root': {
                        fontSize: { xs: 24 }
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={11}
                  md={8}
                  sx={{
                    display: { xs: 'block', lg: 'none', alignSelf: 'center' },
                    pl: '0 !important'
                  }}
                >
                  <Typography variant='h4' className='table_header'>
                    Select All Rows
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    display: { xs: 'none', lg: 'block', alignSelf: 'center' }
                  }}
                >
                  <Typography variant='h4' className='table_header'>
                    Student
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={3}
                  sx={{ display: { xs: 'none', lg: 'block', alignSelf: 'center' } }}
                >
                  <Typography variant='h4' className='table_header' pl={2}>
                    Shortlist
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={3}
                  sx={{ display: { xs: 'none', lg: 'block', alignSelf: 'center' } }}
                >
                  <Typography variant='h4' className='table_header' pl={2}>
                    Action and Notices
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  lg={2}
                  onClick={() => setExpandAll(!expandAll)}
                  sx={{
                    display: { xs: 'none', md: 'block', alignSelf: 'center' },
                    textAlign: 'right'
                  }}
                >
                  <Typography
                    variant='h4'
                    className='table_header'
                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    <b>
                      {expandAll ? (
                        <>
                          <ChevronUp size={16} /> Collapse All Rows
                        </>
                      ) : (
                        <>
                          <ChevronDown size={16} /> Expand All Rows
                        </>
                      )}
                    </b>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {students.map(student => (
              <Box
                mb={{ md: 1 }}
                key={`${Math.floor(Math.random() * Math.random())}-list-${student.id}`}
              >
                <ClassTrackerStudentListRow
                  key={student.id}
                  student={student}
                  checked={checkedBoxes[student.id] || false}
                  onChecked={() => updatedCheckedBoxes(student.id)}
                  userId={student.userId}
                  cohort={cohort}
                  filtersArr={filtersArr}
                  currentFilter={currentFilter}
                  expandAll={expandAll}
                  collapseAllFunc={() => setExpandAll(false)}
                  role={role}
                  showToast={props.showToast}
                  isClassPublished={containsProperty(cohort, 'published')}
                  fetchCohortData={refetchCohortData}
                />
              </Box>
            ))}
          </Box>
        ) : null}
        {students.length <= 0 ? (
          <>
            <div className='empty-div__m-bottom-34' />
            <EmptyListDesign
              heading={getTextContentByPage('CLASSDETAILS', 'EMPTYSTUDENTLIST', 'HEADING')}
              subheading={getTextContentByPage('CLASSDETAILS', 'EMPTYSTUDENTLIST', 'SUBHEADING')}
            />
          </>
        ) : null}
      </Box>
    )
  }
}
export default inject('backToPageStore', 'photosStore')(
  observer(withApollo(withRouter(ClassTrackerStudentList)))
)
