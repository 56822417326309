import React from 'react'
import { checkStudentAccessToPhoto } from '../../utils/commonFunctions'
import RawFileUploader from '../organisms/RawFileUploader'
import ReplacePhotoUploader from '../organisms/ReplacePhotoUploader'
import { Typography } from '@mui/material'

const PhotoDetailsFileOperations = (props) => {
  const { role, photo, refetchPhoto, updateImage, updateRAW, replacePhotoToast, userStore } = props
  return (role === 'appadmin' ||
    (['student'].includes(role) && !photo.project.submitted)) ? (
    <div className='photo-detail__file-replace'>
      <Typography variant="h3" className='strong'>Files</Typography>
      <div className='photo-detail__photo-files'>
        {role === 'appadmin' ||
        (['student'].includes(role) && checkStudentAccessToPhoto(photo.project, userStore)) ? (
          <>
            <ReplacePhotoUploader
              photo={photo}
              updateImage={updateImage}
              refetchPhoto={refetchPhoto}
              replacePhotoToast={replacePhotoToast}
            />
          </>
        ) : null}
        {/* https://trello.com/c/8akR8u8a/2993-photograph-detail-page-hide-or-remove-upload-raw-functionality-a-s*/}
        {/* {['appadmin'].includes(role) || (photo && checkStudentAccessToPhoto(photo.project, userStore)) ? (
          <RawFileUploader
            photo={photo}
            updateRAW={updateRAW}
            refetchPhoto={refetchPhoto}
          />
        ) : null} */}
      </div>
    </div>
  ) : null
}

export default PhotoDetailsFileOperations