import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import MainLogo from '../../assets/svgs/mainLogo.jsx'
import { Grid, Box, Typography } from '@mui/material'
import variables from '../settings/_variables.scss'
import { withRouter } from '../../utils/customHoc.js'

const Footer = ({ location }) => {
  return (
    <Box sx={{ p: 6, backgroundColor: `${variables.navigationGrey}`, color: `${variables.white}` }}>
      <Grid container columnSpacing={{ xs: 3, md: 3 }} rowSpacing={{ xs: 6, md: 6 }}>
        <Grid item xs={12} sm={12} md={12} lg={3}>
          {
            // location && location.pathname === '/' ?
            // <Box onClick={() => {
            //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            // }}>
            //   <MainLogo fill='#fff'/>
            // </Box> :
            <Link to='https://www.workingassumptions.org' target='_blank'>
              <MainLogo fill='#fff' />
            </Link>
          }
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          sx={{ pt: { md: '32px !important', lg: '48px !important' } }}
        >
          <Box>
            <Typography variant='h4' className='semi-strong'>
              Working Assumptions
            </Typography>
          </Box>
          <Box sx={{ mt: { xs: 2, md: 4 } }}>
            <Box
              component='a'
              href='https://www.workingassumptions.org/about'
              target='_blank'
              rel='noreferrer'
            >
              <Typography variant='h5' className='footer-links'>
                About Us
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box
              component='a'
              href='https://www.instagram.com/workingassumptions/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Typography variant='h5' className='footer-links'>
                Instagram
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box
              component='a'
              href='https://twitter.com/WorkXFamily/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Typography variant='h5' className='footer-links'>
                Twitter
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          sx={{ pt: { xs: '32px !important', md: '32px !important', lg: '48px !important' } }}
        >
          <Box>
            <Typography variant='h4' className='semi-strong'>
              Contact Us
            </Typography>
          </Box>
          <Box sx={{ mt: { xs: 2, md: 4 } }}>
            <Box
              component='a'
              href='mailto:education@workingassumptions.org'
              target='_blank'
              rel='noreferrer'
            >
              <Typography
                variant='h5'
                className='footer-links'
                sx={{ wordBreak: { xs: 'break-all' } }}
              >
                education@workingassumptions.org
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box component='a' href='tel:5107049734' target='_blank' rel='noopener noreferrer'>
              <Typography variant='h5' className='footer-links'>
                (510) 704-9734
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Box
              component='a'
              href='https://www.google.com/maps/place/1700+Shattuck+Ave+%2325,+Berkeley,+CA+94709/@37.8764009,-122.2714295,17z/data=!3m1!4b1!4m5!3m4!1s0x80857ea1c6d6caa5:0x481c692c93e32791!8m2!3d37.8763967!4d-122.2692408'
              target='_blank'
              rel='noopener noreferrer'
            >
              <Typography variant='h5' className='footer-links'>
                1700 Shattuck Avenue, #25
                <br />
                Berkeley, CA 94709
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          sx={{ pt: { xs: '32px !important', md: '32px !important', lg: '48px !important' } }}
        >
          <Box>
            <Typography variant='h4' className='semi-strong'>
              Terms & Policies
            </Typography>
          </Box>
          <Box sx={{ mt: { xs: 2, md: 4 } }}>
            <Link to='/privacy-policy'>
              <Typography variant='h5' className='footer-links'>
                Privacy Policy
              </Typography>
            </Link>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Link to='/terms-of-use'>
              <Typography variant='h5' className='footer-links'>
                Terms of Use
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Box pt={{ xs: 4, md: 6, lg: 6 }}>
        <Typography variant='h5'>
          © {moment().format('YYYY')} Working Assumptions, a registered 501(c)(3) nonprofit
          organization.
        </Typography>
      </Box>
    </Box>
  )
}

export default withRouter(Footer)
