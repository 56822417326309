import React, { useEffect, useState } from 'react'
import Loading from '../elements/Loading.js'
import { ChevronLeft, ChevronRight } from 'react-feather'
import ImageZoom from '../organisms/ImageZoom.js'
import moment from 'moment'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import galleryPlaceholder from '../../assets/images/gallery-placeholder.svg'
import galleryPlaceholderDark from '../../assets/images/gallery-placeholder-dark.svg'
import ReactImageFallback from 'react-image-fallback'

const PhotoFeature = (props) => {
  const defaultWidth = '53vw'
  const { isModal, getAdjacentImage, publicView } = props
  const [ loading ] = useState()
  const [ photo ] = useState(props.photo)
  const [ zoomUrl, setZoomUrl ] = useState(props.photo ? props.photo.originalUrl : null,)
  const [ displayUrl, setDisplayUrl] = useState(props.photo
    ? props.photo.cdnOriginalUrl ||
      props.photo.url ||
      props.photo.originalUrl ||
      props.photo.sizedUrl
    : null)
  const [ shouldAllowUpdate, setShouldAllowUpdate ] = useState(props.shouldAllowUpdate)
  const [ zoomEnabled, setZoomEnabled ] = useState(false)
  const [ imagWidth, setImageWidth ] = useState(defaultWidth)
  const [ enablePrev ] = useState(props.enablePrev)
  const [ enableNext ] = useState(props.enableNext)

  const handleResize = () => {
    setImageWidth(document.getElementById('picture') ? document.getElementById('picture').width : defaultWidth)
  }

  const handleImageNavigation = (navType) => {
    setDisplayUrl(null)
    if (!shouldAllowUpdate) {
      setShouldAllowUpdate(true)
      getAdjacentImage(navType, photo.id)
      return
    }
    getAdjacentImage(navType, photo.id)
  }

  const getImageZoomComponent = () => {
    return <ImageZoom
      srcSmall={zoomUrl}
      src={zoomUrl}
      onClose={() => setZoomEnabled(false) }
      width={photo.exifData && photo.exifData.width}
      height={photo.exifData && photo.exifData.height}
      display={zoomEnabled}
    />
  }

  const getImageComponent = () => {
    return <figure className='item'>
      {displayUrl ? (
        // <LazyLoadImage
        //   id='picture'
        //   alt={photo.altText ? photo.altText : ''}
        //   effect="blur"
        //   src={displayUrl}
        //   className={!isModal ? 'photo' : ''}
        //   placeholderSrc={props.placeholderDark ? galleryPlaceholderDark : galleryPlaceholder}
        //   onClick={() => setZoomEnabled(true)}
        //   afterLoad={() => handleResize()}
        // />
        <ReactImageFallback
          src={displayUrl}
          fallbackImage='https://i.pinimg.com/originals/9b/96/92/9b9692c9f0db9f6276e6bd29a98c25e0.png'
          initialImage={props.placeholderDark ? galleryPlaceholderDark : galleryPlaceholder}
          alt={photo.altText ? photo.altText : ''}
          // className={props.letterBoxType ? 'photo photo_letterbox_type': 'photo'}
          onClick={() => setZoomEnabled(true)}
          className={!isModal ? 'photo' : ''}
        />
      ) : null}
      {publicView ? (
        <figcaption className='caption' style={{ width: imagWidth }}>
          <i>{photo.title}</i>, {photo.attribution ? photo.attribution : 'Anonymous'},{' '}
          {moment(photo.submittedDate).format('YYYY')}
        </figcaption>
      ) : null}
    </figure>
  }
  useEffect(() => {
    setDisplayUrl(props.photo
      ? props.photo.cdnOriginalUrl ||
        props.photo.url ||
        props.photo.originalUrl ||
        props.photo.sizedUrl
      : null);
    setZoomUrl(props.photo
      ? props.photo.originalUrl ||
        props.photo.cdnOriginalUrl ||
        props.photo.url ||
        props.photo.sizedUrl
      : null)
  }, [props])

  return (
    isModal ?
      <section
        className={publicView ? 'collection-modal-photo-container' : 'modal-photo-container'}
      >
        <button
          className={
            !enablePrev
              ? 'get-previous-photo-btn__disabled'
              : 'get-previous-photo-btn'
          }
          onClick={() =>
            enablePrev
              ? handleImageNavigation('prev')
              : null
          }
        >
          <ChevronLeft />
        </button>
        <span className='helper' />
        {getImageZoomComponent()}
        {loading ? (
          <div className='modal-photo-container-pulse-loader-wrapper'>
            <Loading />
          </div>
        ) : null}
        {getImageComponent()}
        <button
          className={
            !enableNext ? 'get-next-photo-btn__disabled' : 'get-next-photo-btn'
          }
          onClick={() =>
            enableNext
              ? handleImageNavigation('next')
              : null
          }
        >
          <ChevronRight />
        </button>
      </section>
    : <div className='body__featured-photo'>
        <div className='container__photo' key={photo.id}>
          {getImageZoomComponent()}
          {getImageComponent()}
        </div>
      </div>
  )
}

export default PhotoFeature