import React, { Component, Fragment } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import './agreements.scss'
import { gql } from '@apollo/client'
import FormCheckbox from '../elements/FormCheckbox.js'
import Photo from '../elements/Photo.js'
import FormField from '../elements/FormField.js'
import { observer, inject } from 'mobx-react'
import { Navigate } from 'react-router-dom'
import Validations from '../../utils/Validations'
import Button from '../elements/Button.js'
import './useAgreementContent.scss'
import variables from '../settings/_variables.scss'
import moment from 'moment'
import { Spinner } from 'react-bootstrap'
import { Box, Grid, Typography } from '@mui/material'
import { CheckCircle } from 'react-feather'
import UaAgreementModal from '../molecules/UaAgreementModal'

const SIGN_USE_AGREEMENT_MUTATION = gql`
  mutation SignUseAgreement(
    $agreementUuid: String!
    $signeeFirstName: String
    $signeeLastName: String
    $useAgreementValue: Boolean!
    $wantsUpdates: Boolean
    $emailO: String
    $phoneNumber: String
  ) {
    signUseAgreement(
      agreementUuid: $agreementUuid
      useAgreementValue: $useAgreementValue
      wantsUpdates: $wantsUpdates
      signeeFirstName: $signeeFirstName
      signeeLastName: $signeeLastName
      emailO: $emailO
      phoneNumber: $phoneNumber
    ) {
      id
      signed
    }
  }
`

const CreateUseAgreementMutation = gql`
  mutation CreateUseAgreement(
    $photoIds: [ID!]!
    $useAgreementType: String!
    $guardianFirstName: String
    $guardianLastName: String
    $email: String
    $phoneNumber: String
    $sendTo: String
    $wantsUpdate: Boolean
    $signed: Boolean
  ) {
    createUseAgreement(
      photoIds: $photoIds
      useAgreementType: $useAgreementType
      guardianFirstName: $guardianFirstName
      guardianLastName: $guardianLastName
      email: $email
      phoneNumber: $phoneNumber
      sendTo: $sendTo
      wantsUpdate: $wantsUpdate
      signed: $signed
    ) {
      id
    }
  }
`

class UseAgreementContent extends Component {
  constructor(props) {
    super(props)
    const { student, agreement } = props
    const birthDay =
      !!moment().diff(student.dob, 'years') === false ? 0 : moment().diff(student.dob, 'years')
    const isStudentMinor = birthDay < 18
      this.state = {
      useAgreementTermsGuardianModal: false,
      publishing: this.props.useAgreementValue,
      isStudentMinor: isStudentMinor,
      errors: {},
      useAgreement: {
        emailO: '',
        phoneNumber: '',
        termsAccepted: false,
        wantsUpdates: false,
        signeeFirstName:
          props.userStore.role === 'appadmin' && props.agreement && props.agreement.signeeFirstName
            ? props.agreement.signeeFirstName
            : props.userStore.role === 'student' && !isStudentMinor
            ? props.userStore.firstName
            : '',
        signeeLastName:
          props.userStore.role === 'appadmin' && props.agreement && props.agreement.signeeLastName
            ? props.agreement.signeeLastName
            : props.userStore.role === 'student' && !isStudentMinor
            ? props.userStore.lastName
            : ''
      },
      emailO: '',
      isSubmitting: false,
      redirect: null,
      isDisabled:
        this.props.type !== 'newUa' && agreement && agreement.signed !== null ? true : false
    }
  }

  canGuardianSubmitUseAgreement = () => {
    const { phoneNumber, termsAccepted, signeeFirstName, signeeLastName } = this.state.useAgreement
    const { student } = this.props
    const { emailO } = this.state
    let validFirstName = true
    let validLastName = true
    let validEmail = true
    let validPhoneNumber = true
    if (this.state.publishing) {
      validEmail = Validations.isValidEmail(emailO) && emailO.toLowerCase() !== student.email
      if (phoneNumber !== '') {
        validPhoneNumber = Validations.isValidPhoneNumber(phoneNumber)
      }
      validFirstName = Validations.isValidCity(signeeFirstName)
      validLastName = Validations.isValidCity(signeeLastName)
    }

    if (this.state.publishing === null) {
      return false
    }

    return (
      validEmail &&
      validPhoneNumber &&
      validFirstName &&
      validLastName &&
      (this.state.publishing !== null ? termsAccepted : true)
    )
  }

  handleChange = name => event => {
    if (!['termsAccepted', 'wantsUpdates'].includes(name))
      this.setState({ [name]: event.target.checked })
    else {
      const useAgreement = { ...this.state.useAgreement }
      useAgreement[name] = event.target.checked
      this.setState(
        {
          ...this.state,
          useAgreement
        },
        () => {
          this.setState({
            ...this.state
          })
        }
      )
    }
  }

  handleFormChangeCheck = e => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleFormChange = e => {
    if (!['email', 'phoneNumber', 'signeeFirstName', 'signeeLastName'].includes(e.target.name))
      this.setState({ [e.target.name]: e.target.value })
    else {
      const useAgreement = { ...this.state.useAgreement }
      useAgreement[e.target.name] = e.target.value
      this.setState({
        ...this.state,
        useAgreement
      })
    }

    if (e.target.name === 'state') {
      if (e.target.value.toLowerCase() === 'state') {
        e.target.style.borderColor = 'red'
      } else {
        e.target.style.borderColor = 'black'
      }
    }
  }

  togglePublishing = value => {
    this.setState({
      publishing: value
    })
  }

  checkForErrors = event => {
    const elem = event.target
    const { name, value } = elem
    const { student } = this.props
    let isValid, errors
    if (name === 'emailO') {
      isValid = this.state.publishing
        ? Validations.isValidEmail(value) && value.toLowerCase() !== student.email
        : true
      errors = { ...this.state.errors }
      errors.adultEmail = isValid
        ? null
        : Validations.isValidEmail(value) && value.toLowerCase() === student.email
        ? 'Email address cannot match student’s registered email address.'
        : 'Please enter a valid email address.'
      this.setState({
        ...this.state,
        errors
      })
    } else if (name === 'phoneNumber') {
      isValid = value !== '' ? Validations.isValidPhoneNumber(value) : true
      errors = { ...this.state.errors }
      errors.adultCell =
        isValid && value !== '0000000000' ? null : 'Please enter a valid mobile number.'
      this.setState({
        ...this.state,
        errors
      })
    } else if (['signeeFirstName', 'signeeLastName'].includes(name)) {
      isValid = Validations.isValidCity(value)
      errors = { ...this.state.errors }
      errors[`${name}`] = isValid
        ? null
        : `Please enter your ${name === 'signeeFirstName' ? 'first' : 'last'} name.`
      this.setState({
        ...this.state,
        errors
      })
    }
  }

  createUseAgreement = () => {
    if (this.canGuardianSubmitUseAgreement()) {
      const { phoneNumber, wantsUpdates, signeeFirstName, signeeLastName } = this.state.useAgreement
      this.setState({
        ...this.state,
        isSubmitting: true
      })
      if (this.props.type === 'newUa') {
        this.props.client
          .mutate({
            mutation: CreateUseAgreementMutation,
            variables: {
              photoIds: this.props.photoIds,
              useAgreementType: 'self',
              signed: this.state.publishing || false,
              email: this.state.emailO,
              phoneNumber,
              wantsUpdate: wantsUpdates || false,
              guardianFirstName: signeeFirstName,
              guardianLastName: signeeLastName
            },
            refetchQueries: [`Photo`]
          })
          .then(response =>
            this.setState({
              redirect: true
            })
          )
      } else {
        this.props.client
          .mutate({
            mutation: SIGN_USE_AGREEMENT_MUTATION,
            variables: {
              agreementUuid: this.props.agreementUuid,
              photoId: this.props.photoId,
              useAgreementValue: this.state.publishing || false,
              emailO: this.state.emailO,
              phoneNumber,
              wantsUpdates: wantsUpdates || false,
              signeeFirstName: signeeFirstName,
              signeeLastName: signeeLastName
            },
            refetchQueries: [`UseAgreement`]
          })
          .then(response =>
            this.setState({
              isDisabled: true
            })
          )
      }

      this.setState({
        ...this.state,
        newUseAgreementSent: true,
        isSubmitting: false
      })
    }
  }

  documentSectionForUseAgreementHeader = () => {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant='h4' className='semi-strong link'>
          <Box component='a' target='_blank' href='http://waed.org' rel='noopener noreferrer'>
            <Typography variant='link' className='large'>
              Read about the Photography Assignment
            </Typography>
          </Box>
        </Typography>
        <Typography variant='h4' className='semi-strong link' sx={{ mt: 2 }}>
          <Box
            component='a'
            href='#'
            onClick={e => {
              e.preventDefault()
              this.setState({
                ...this.state,
                useAgreementTermsGuardianModal: true
              })
            }}
          >
            <Typography variant='link' className='large'>
              Use Agreement Details
            </Typography>
          </Box>
        </Typography>
      </Box>
    )
  }

  render() {
    const { handleFormChange, togglePublishing } = this
    const { userStore, agreement } = this.props
    const { student } = this.props
    const { isStudentMinor } = this.state

    if (this.state.redirect) {
      return <Navigate to={`/project-detail/${this.props.projectId}`} />
    }
    return (
      <Fragment>
        <Box>
          <Box
            sx={{
              backgroundColor: `${variables.backgroundGrey}`,
              p: {
                xs: '56px 40px 40px',
                sm: '64px 40px 40px',
                md: '96px 40px 80px'
              }
            }}
          >
            <Grid container spacing={4}>
              <Grid item md={5} sm={12} xs={12} sx={{ pt: { md: '80px !important' } }}>
                <Typography
                  variant='h1'
                  className='oversize_bold'
                  sx={{
                    color: `${variables.publicBlue}`,
                    mt: { xs: 5, md: 0 }
                  }}
                >
                  Photograph Use Agreement
                </Typography>
              </Grid>
              <Grid item md={7} sm={12} xs={12} sx={{ pt: { md: '80px !important' } }}>
                {this.props.agreement && this.props.agreement.signed === true ? (
                  <Box>
                    <Typography variant='paragraph' component='p' sx={{ mb: 2 }}>
                      Thank you for granting permission to Working Assumptions to preserve and use
                      this work. You will receive confirmation shortly.
                    </Typography>
                    <Typography variant='paragraph' sx={{ mb: 2 }} component='p'>
                      If you have any questions, please contact{' '}
                      <a
                        href='mailto:education@workingassumptions.org'
                        style={{ wordBreak: 'break-word' }}
                      >
                        <Typography variant='link' className='large'>
                          education@workingassumptions.org
                        </Typography>
                      </a>
                      .
                    </Typography>
                    {this.documentSectionForUseAgreementHeader()}
                  </Box>
                ) : this.props.agreement && this.props.agreement.signed === false ? (
                  <Box>
                    <Typography variant='paragraph' sx={{ mb: 2 }} component='p'>
                      Thank you for your decision. This work will not be shared or made available
                      for study, reproduction or exhibition, but it will remain in the{' '}
                      <i>wrkxfmly</i> web application and digital research archive visible only to
                      individuals connected to Working Assumptions. You will receive confirmation
                      shortly.
                    </Typography>
                    <Typography variant='paragraph' sx={{ mb: 2 }} component='p'>
                      If you have any questions or wish to grant permission in the future, please
                      contact{' '}
                      <a
                        href='mailto:education@workingassumptions.org'
                        style={{ wordBreak: 'break-word' }}
                      >
                        <Typography variant='link' className='large'>
                          education@workingassumptions.org
                        </Typography>
                      </a>
                      .
                    </Typography>
                    {this.documentSectionForUseAgreementHeader()}
                  </Box>
                ) : isStudentMinor ? (
                  <Box>
                    <Typography variant='paragraph' component='p' sx={{ mb: 2 }}>
                      {student.name} has been recognized for their photography, created during the{' '}
                      <i>wrkxfmly</i> assignment organized by Working Assumptions. We would like to
                      include their excellent work in a digital research archive we are building to
                      preserve and honor their unique perspective. The photographs and captions held
                      in this archive advance our mission of seeing cultures of care through the
                      eyes of young adults.
                    </Typography>
                    <Typography variant='paragraph' component='p' sx={{ mb: 2 }}>
                      If you grant permission below, Working Assumptions may use, reproduce,
                      distribute, display and publish the student’s work for non-profit
                      mission-aligned purposes. The student’s work may be stored in Working
                      Assumption’s archive, made available for research collaborations, and included
                      in print publications, in digital formats, online, and in other media.
                    </Typography>
                    <Typography variant='paragraph' component='p' sx={{ mb: 2 }}>
                      Please read the details in the link below and decide if you want to allow
                      Working Assumptions to preserve and use this work as described. If you have
                      any questions, please contact{' '}
                      <a
                        href='mailto:education@workingassumptions.org'
                        style={{ wordBreak: 'break-word' }}
                      >
                        <Typography variant='link' className='large'>
                          education@workingassumptions.org
                        </Typography>
                      </a>
                      .
                    </Typography>
                    {this.documentSectionForUseAgreementHeader()}
                  </Box>
                ) : (
                  <Box>
                    <Typography variant='paragraph' component='p' sx={{ mb: 2 }}>
                      You have been recognized for your <i>wrkxfmly</i> photography, and we would
                      like to include your excellent work in a digital research archive we are
                      building to preserve and honor your unique perspective. The photographs and
                      captions held in this archive advance our mission of seeing cultures of care
                      through the eyes of young adults.
                    </Typography>

                    <Typography variant='paragraph' sx={{ mb: 2 }} component='p'>
                      If you grant permission below, Working Assumptions may use, reproduce,
                      distribute, display and publish your work for non-profit, mission-aligned
                      purposes. Your work may be stored in Working Assumption’s archive, made
                      available for research collaborations, and included in print publications, in
                      digital formats, online, and in other media.
                    </Typography>

                    <Typography variant='paragraph' component='p' sx={{ mb: 2 }}>
                      Please read the details in the link below and decide if you want to allow
                      Working Assumptions to preserve and use this work as described. If you have
                      any questions, please contact{' '}
                      <a
                        href='mailto:education@workingassumptions.org'
                        style={{ wordBreak: 'break-word' }}
                      >
                        <Typography variant='link' className='large'>
                          education@workingassumptions.org
                        </Typography>
                      </a>
                      .
                    </Typography>
                    {this.documentSectionForUseAgreementHeader()}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box
              sx={{
                background: variables.white,
                p: {
                  xs: '40px 20px',
                  sm: '40px 40px 48px',
                  md: '40px 40px',
                  lg: '80px 40px'
                }
              }}
            >
              <Grid container spacing={4}>
                {this.props.type === 'newUa' ? (
                  this.props.photos &&
                  this.props.photos.map(photo => {
                    return (
                      <Grid
                        item
                        lg={4}
                        sm={6}
                        xs={12}
                        sx={{ pt: { md: '40px !important' }, mt: { md: '-8px !important' } }}
                      >
                        <Photo letterBoxType={true} notClickable={true} photo={photo} />
                      </Grid>
                    )
                  })
                ) : (
                  <Grid
                    item
                    lg={4}
                    sm={6}
                    xs={12}
                    sx={{ pt: { md: '40px !important' }, mt: { md: '-8px !important' } }}
                  >
                    <Photo letterBoxType={true} notClickable={true} photo={this.props.photo} />
                  </Grid>
                )}
              </Grid>
            </Box>
            {// this.props.useAgreementType === 'self' || this.props.type === 'newUa' || this.props.agreement.signed !== true || this.props.userStore.role === "appadmin"?
            this.props.userStore.role === 'appadmin' ||
            this.props.type === 'newUa' ||
            (['existingUa'].includes(this.props.type) &&
              (this.props.agreement && this.props.agreement.signed === null)) ? (
              <Box
                sx={{
                  background: variables.white,
                  width: '100%',
                  margin: 'auto',
                  p: {
                    md: '2.5rem 16% 2.5rem',
                    sm: '0.5rem 1.9rem 2.5rem',
                    xs: '0.5rem 1.9rem 2.5rem'
                  }
                }}
              >
                <Box alignItems='center'>
                  <Box>
                    <Typography variant='paragraph' component='p'>
                      May Working Assumptions preserve and use the work as described above?
                    </Typography>
                  </Box>
                  <Grid
                    container
                    alignItems='center'
                    spacing={1}
                    sx={{ mt: { xs: '0px !important' }, pt: { xs: 3, md: 4 } }}
                  >
                    <Grid item md={6} sm={6} xs={12} sx={{ pt: { xs: '0px !important' } }}>
                      <Button
                        variant={
                          this.state.publishing === true || (agreement && agreement.signed === true)
                            ? 'selected-segmented'
                            : 'segmented'
                        }
                        onClick={() => togglePublishing(true)}
                        disabled={userStore.role === 'appadmin' || this.state.isDisabled}
                        boldTitle={
                          <Grid container alignItems='center' spacing={2}>
                            <Grid
                              item
                              md={10}
                              xs={10}
                              sm={10}
                              sx={{ textAlign: 'left', wordBreak: 'break-word !important' }}
                            >
                              <Typography variant='h4' className='table_header'>
                                Yes, allow Working Assumptions to preserve and use the work.
                              </Typography>
                            </Grid>
                            {this.state.publishing === true ||
                            (agreement && agreement.signed === true) ? (
                              <Grid item md={2} xs={2} sm={2}>
                                <CheckCircle color={`${variables.greenAccent}`} size={24} />
                              </Grid>
                            ) : null}
                          </Grid>
                        }
                        sx={{
                          width: { xs: '97%' },
                          minHeight: { xs: 120 }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      sx={{ pt: { xs: '16px !important', sm: '0px !important' } }}
                    >
                      <Button
                        variant={
                          this.state.publishing === false ||
                          (agreement && agreement.signed === false)
                            ? 'selected-segmented'
                            : 'segmented'
                        }
                        onClick={() => togglePublishing(false)}
                        disabled={userStore.role === 'appadmin' || this.state.isDisabled}
                        boldTitle={
                          <Grid container alignItems='center' spacing={1}>
                            <Grid
                              item
                              md={10}
                              xs={10}
                              sm={10}
                              sx={{ textAlign: 'left', wordBreak: 'break-word !important' }}
                            >
                              <Typography variant='h4' className='table_header'>
                                No, do not allow Working Assumptions to preserve and use the work.
                              </Typography>
                            </Grid>
                            {this.state.publishing === false ||
                            (agreement && agreement.signed === false) ? (
                              <Grid item md={2} xs={2} sm={2}>
                                <CheckCircle color={`${variables.greenAccent}`} size={24} />
                              </Grid>
                            ) : null}
                          </Grid>
                        }
                        sx={{
                          width: { xs: '97%' },
                          minHeight: { xs: 120 }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {this.state.publishing ? (
                  <Box>
                    <Box sx={{ mt: { xs: 6, sm: 6, md: 5, lg: 10 } }}>
                      <Typography variant='h2' component='h2'>
                        Your Details
                      </Typography>
                    </Box>
                    <Box sx={{ pt: 4 }}>
                      <Box sx={{ mb: 2 }}>
                        <Typography variant='paragraph' component='p'>
                          What is your name?
                        </Typography>
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12} lg={6} sx={{ mb: '0px !important' }}>
                          <FormField
                            sx={{ mt: '0px !important', mb: '0px !important' }}
                            formFieldId='outlined-email'
                            formFieldFullWidth='false'
                            formFieldVariant='filled'
                            formFieldLabel='Your First Name'
                            formFieldClasses={{
                              root: 'use-agreement-form-v2__fname'
                            }}
                            formFieldType='text'
                            formFieldName='signeeFirstName'
                            formFieldAutoComplete='signeeFirstName'
                            onChange={handleFormChange}
                            onBlur={e => this.checkForErrors(e)}
                            formFieldError={this.state.errors.signeeFirstName || null}
                            disabled={userStore.role === 'appadmin' || this.state.isDisabled}
                            formFieldDefaultValue={
                              agreement &&
                              agreement.signeeFirstName &&
                              userStore.role === 'appadmin'
                                ? agreement.signeeFirstName
                                : userStore.role === 'student' && !this.state.isStudentMinor
                                ? this.props.userStore.firstName
                                : this.state.useAgreement.signeeFirstName
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          lg={6}
                          sx={{
                            mt: { xs: this.state.errors.signeeFirstName ? '11px' : 5, lg: 0 },
                            pt: { xs: '0px !important', lg: '24px !important' }
                          }}
                        >
                          <FormField
                            sx={{ mt: '0px !important' }}
                            formFieldId='outlined-email'
                            formFieldFullWidth='false'
                            formFieldVariant='filled'
                            formFieldLabel='Your Last Name'
                            formFieldClasses={{
                              root: 'use-agreement-form-v2__lname'
                            }}
                            formFieldType='text'
                            formFieldName='signeeLastName'
                            formFieldAutoComplete='signeeLastName'
                            onChange={handleFormChange}
                            onBlur={e => this.checkForErrors(e)}
                            formFieldError={this.state.errors.signeeLastName || null}
                            disabled={userStore.role === 'appadmin' || this.state.isDisabled}
                            formFieldDefaultValue={
                              agreement && agreement.signeeLastName && userStore.role === 'appadmin'
                                ? agreement.signeeLastName
                                : userStore.role === 'student' && !this.state.isStudentMinor
                                ? this.props.userStore.lastName
                                : this.state.useAgreement.signeeLastName
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box
                      sx={{
                        mt:
                          this.state.errors.signeeLastName || this.state.errors.signeeFirstName
                            ? '11px'
                            : 5,
                        pt: 0.125
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography variant='paragraph' component='p'>
                            What is your email address?
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2, pt: '0px !important' }}>
                          <FormField
                            sx={{ mt: '0px !important', pt: '0px !important' }}
                            formFieldId='outlined-email'
                            formFieldFullWidth='false'
                            formFieldVariant='filled'
                            formFieldLabel='Your Email Address'
                            formFieldType='emailO'
                            formFieldName='emailO'
                            formFieldAutoComplete='emailO'
                            onChange={handleFormChange}
                            onBlur={e => this.checkForErrors(e)}
                            formFieldError={this.state.errors.adultEmail || null}
                            disabled={userStore.role === 'appadmin' || this.state.isDisabled}
                            formFieldValue={
                              agreement && agreement.email && userStore.role === 'appadmin'
                                ? agreement.email
                                : this.state.emailO
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ mt: this.state.errors.adultEmail ? '11px' : 5, pt: 0.125 }}>
                      <Grid container spacing={1}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography variant='paragraph' component='p'>
                            What is your mobile number? (optional)
                          </Typography>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} sx={{ mt: 2, pt: '0px !important' }}>
                          <FormField
                            formFieldId='outlined-email'
                            formFieldFullWidth='false'
                            formFieldVariant='filled'
                            formFieldLabel='Your Mobile Number'
                            formFieldType='number'
                            formFieldAutoComplete='tel'
                            formFieldName='phoneNumber'
                            onChange={handleFormChange}
                            onBlur={e => this.checkForErrors(e)}
                            formFieldError={this.state.errors.adultCell || null}
                            disabled={userStore.role === 'appadmin' || this.state.isDisabled}
                            formFieldValue={
                              agreement && agreement.phoneNumber && userStore.role === 'appadmin'
                                ? agreement.phoneNumber
                                : this.state.phoneNumber
                            }
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ mt: this.state.errors.adultCell ? '11px' : 5 }}>
                      <Typography
                        variant='paragraph'
                        component='p'
                        sx={{
                          mb: 5
                        }}
                      >
                        We ask for your contact information so that, should the case ever arise that
                        we would like to contact you regarding the photograph(s), we are able to do
                        so. Working Assumptions protects your privacy. To learn more about how we do
                        that, visit{' '}
                        <a
                          href='http://waed.org/privacy-policy'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <Typography variant='link' className='large'>
                            waed.org/privacy-policy
                          </Typography>
                        </a>
                        .
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {this.state.publishing === false && (
                  <Typography variant='paragraph' component='p' my={{ xs: 5 }}>
                    {this.state.isStudentMinor ? (
                      <>
                        If you choose not to give permission, the student will still receive the
                        award. Without permission, their work will not be shared or made available
                        for study, reproduction or exhibition, but it will remain in the{' '}
                        <i>wrkxfmly</i> web application and digital research archive visible only to
                        individuals connected to Working Assumptions. You can find out more about
                        the assignment by visiting{' '}
                        <a href='http://waed.org/' target='_blank' rel='noreferrer'>
                          <Typography variant='link' className='large'>
                            Working Assumptions Education
                          </Typography>
                        </a>
                        .
                      </>
                    ) : (
                      <>
                        If you choose not to give permission, you will still receive the award.
                        Without permission, your work will not be shared or made available for
                        study, reproduction or exhibition, but it will remain in the <i>wrkxfmly</i>{' '}
                        web application and digital research archive visible only to individuals
                        connected to Working Assumptions.
                      </>
                    )}
                  </Typography>
                )}
                <Box>
                  {this.state.publishing ? (
                    <Box>
                      <FormCheckbox
                        checked={
                          this.state.useAgreement.wantsUpdates ||
                          (agreement && agreement.wantsUpdates && userStore.role === 'appadmin')
                        }
                        onChange={this.handleChange('wantsUpdates')}
                        value='wantsUpdates'
                        label={
                          <Typography variant='paragraph'>
                            Please send me updates about the <i>wrkxfmly</i> assignment and Working
                            Assumptions programs.
                          </Typography>
                        }
                        color='primary'
                        name='wantsUpdates'
                        disabled={userStore.role === 'appadmin' || this.state.isDisabled}
                      />
                    </Box>
                  ) : null}
                  <Box
                    sx={{
                      mt: {
                        xs: this.state.publishing ? 2 : 3.2,
                        md: this.state.publishing ? 2 : 5,
                        lg: this.state.publishing ? 2 : 5
                      },
                      mb: 2
                    }}
                  >
                    <FormCheckbox
                      checked={
                        (this.state.useAgreement.termsAccepted ||
                          (agreement && agreement.signed === true)) || (!this.state.useAgreement.termsAccepted && (agreement && agreement.signed === false))
                      }
                      onChange={this.handleChange('termsAccepted')}
                      value='termsAccepted'
                      name='termsAccepted'
                      label={
                        <Fragment>
                          {this.state.publishing || this.state.publishing === null ? (
                            <Typography variant='paragraph'>I accept the terms of the </Typography>
                          ) : (
                            <Typography variant='paragraph'>
                              I have read and acknowledge the terms of the{' '}
                            </Typography>
                          )}{' '}
                          <Typography
                            variant='paragraph'
                            className='model-release__link'
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                useAgreementTermsGuardianModal: true
                              })
                            }
                          >
                            {' '}
                            <Typography variant='link' className='large'>
                              Use Agreement
                            </Typography>
                          </Typography>
                          .
                        </Fragment>
                      }
                      color='primary'
                      required={true}
                      disabled={
                        userStore.role === 'appadmin' ||
                        this.state.publishing === null ||
                        this.state.isDisabled
                      }
                    />
                  </Box>
                </Box>
                {userStore.role !== 'appadmin' &&
                (this.props.type === 'newUa' || (agreement && agreement.signed === null)) ? (
                  <Box sx={{ mt: 5 }}>
                    <Button
                      variant='contained'
                      type='submit'
                      disabled={
                        this.state.isSubmitting ||
                        !this.canGuardianSubmitUseAgreement() ||
                        this.state.newUseAgreementSent
                      }
                      title={
                        this.state.isSubmitting ? (
                          <span className='icon-text-span'>
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />{' '}
                            Submitting...{' '}
                          </span>
                        ) : (
                          'Submit Form'
                        )
                      }
                      onClick={() => this.createUseAgreement()}
                      sx={{
                        width: { xs: '100%', sm: '50%', md: 'auto' }
                      }}
                    />
                  </Box>
                ) : null}
              </Box>
            ) : null}
          </Box>
          <UaAgreementModal
            open={this.state.useAgreementTermsGuardianModal}
            handleClose={() =>
              this.setState({ ...this.state, useAgreementTermsGuardianModal: false })
            }
            isMinor={this.state.isStudentMinor}
          />
        </Box>
      </Fragment>
    )
  }
}
export default inject('uiStore', 'userStore')(observer(withApollo(UseAgreementContent)))
