import React, { useState, useEffect } from 'react'
import './herotabs.scss'
import { Typography, Tabs, Tab } from '@mui/material'
import { Link } from 'react-router-dom'

const HeroTabs = props => {

  const tabs = props.tabs
  const [value, setValue] = useState(tabs[0]['eventKey'])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    props.tabChangeHandler(newValue)
  }

  useEffect(() => {
    setValue(props.currentTab)
  }, [props.currentTab])

  return (
    <div className='hero__tabs'>
      <Tabs
        value={value}
        onChange={handleChange}
        variant='scrollable'
        scrollButtons
        allowScrollButtonsMobile
        aria-label='scrollable hero tab'
        indicatorColor='secondary'
        TabIndicatorProps={{ style: { background: '#0076bb', height: '4px' } }}
        TabScrollButtonProps={{ sx: { width: '20px' } }}
        sx={{ pl: { xs: 2, lg: 4 } }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={
              <Typography
                variant='h4'
                className='small_size_semi_bold'
                sx={{ textTransform: 'none' }}
              >
                {tab.title}
              </Typography>
            }
            value={tab.eventKey}
            className={tab.eventKey}
            to={tab?.path}
            component={Link}
          />
        ))}
      </Tabs>
    </div>
  )
}

export default HeroTabs
