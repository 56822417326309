import { action, makeAutoObservable } from 'mobx'

class MetadataStore {
	metadata = []
	constructor() {
		// Call it here
		makeAutoObservable(this)
	}
	setMetadata = action(metadata => {
    this.metadata = [...this.metadata, ...metadata]
  })
}

export default MetadataStore
