import React from 'react'
import GlobalLoaderImage from '../../assets/images/GlobalLoader.png'

const GlobalLoader = props => {
  return (
    <div className='global-progress-indicator'>
      <img className='global-progress-indicator-image' src={GlobalLoaderImage} alt='Loading...' />
    </div>
  )
}

export default GlobalLoader
