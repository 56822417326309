import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Button from '../elements/Button.js'
import { Box, Grid, Typography } from '@mui/material'
import { Spinner } from 'react-bootstrap'
import BaseModal from '../template/BaseModal'
import usePicsio from '../../utils/picsioUtility.js'

const ManageUseAgreementMutation = gql`
  mutation ManageUseAgreement($photoId: ID!, $uaUuid: String!, $manageType: String!) {
    manageUseAgreement(photoId: $photoId, uaUuid: $uaUuid, manageType: $manageType) {
      id
    }
  }
`

// const styles = theme => ({
//   root: {
//     display: 'flex',
//     marginBottom: '16px'
//   },
//   formControl: {
//     margin: 0
//   },
//   group: {
//     margin: 0
//   }
// })

const DeleteUseAgreementModal = props => {
  const { removeImage } = usePicsio()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [manageOnMutate] = useMutation(ManageUseAgreementMutation)

  const handleFormSubmit = () => {
    setIsSubmitting(true)
    manageOnMutate({
      variables: {
        photoId: props.photoId,
        uaUuid: props.uaUuid,
        manageType: 'delete'
      },
      refetchQueries: ['PhotoDetails']
    }).then(handleSubmitSuccess)
  }

  const handleSubmitSuccess = () => {
    setIsSubmitting(false)
    if (props?.assetId) {
      removeImage([props.assetId])
    }
    props?.refetchPhoto?.()
    props?.refetchUaData?.()
    props.onHide()
  }

  return (
    <BaseModal
      {...props}
      show={props.show}
      onClick={props.onClick}
      value={props.value}
      open={props.show}
      onClose={() => props.onHide(false)}
      onHide={props.onHide}
    >
      <BaseModal.BaseModalHeader>Delete Use Agreement</BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Box>
          <Grid container>
            <Grid item className='invite-student__description'>
              <Typography variant='paragraph' component='p'>
                Be sure you want to delete this Use Agreement.
              </Typography>
            </Grid>
            <Grid item sx={{ pb: '0px !important' }} className='invite-student__description'>
              <Typography variant='paragraph' component='p'>
                Reminder: if you delete this Use Agreement, you will no longer be able to access the
                information about this form through the web app.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <BaseModal.BaseModalFooter>
          <Box
            sx={{ padding: '24px 0 0', marginTop: '0px', alignContent: 'center' }}
            className='model-release__buttons'
          >
            <Grid
              container
              direction='row'
              sx={{
                justifyContent: { xs: 'center', sm: 'end' },
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={isSubmitting}
                  title={
                    isSubmitting ? (
                      <span className='icon-text-span'>
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />{' '}
                        Deleting...
                      </span>
                    ) : (
                      'Delete Use Agreement'
                    )
                  }
                  onClick={handleFormSubmit}
                  sx={{ width: { xs: '100%', sm: '100%' } }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ padding: { xs: '24px 0 0', sm: '16px 0 0', md: '12px 0 0' } }}
              >
                <Typography
                  sx={{
                    mr: { sm: 2 },
                    display: { xs: 'flex' },
                    justifyContent: { xs: 'center', sm: 'right' }
                  }}
                  variant='h4'
                  className='small_size_semi_bold link'
                  onClick={() => props.onHide()}
                >
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </BaseModal.BaseModalFooter>
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

// class DeleteUseAgreementModal extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       resendMessage: false,
//       uaUuid:props.uaUuid,
//       isSubmitting: false
//     }

//     this.handleFormSubmit = this.handleFormSubmit.bind(this)
//     this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this)
//   }

//   handleFormSubmit = (onMutate) => {
//     this.setState({isSubmitting: true})
//     onMutate({
//       variables: {
//         photoId: this.props.photoId,
//         uaUuid: this.props.uaUuid,
//         manageType:'delete',
//       },
//       refetchQueries: [`PhotoDetails`]
//     })
//   }

//   handleSubmitSuccess = () => {
//     this.setState({isSubmitting: false})
//     this.props.refetchPhoto && this.props.refetchPhoto()
//     this.props.refetchUaData && this.props.refetchUaData()
//     this.props.onHide()
//   }

//   render() {
//     const {
//       handleSubmitSuccess
//     } = this
//     const handleClose = () => {
//       this.setState({show: false});
//       this.props.onHide(false);

//     };
//     return <Mutation
//       mutation={ManageUseAgreementMutation}
//       onCompleted={()=>{
//         handleSubmitSuccess()
//       }}
//     >
//       {(manageOnMutate, { data }) => (
//         <BaseModal
//         {...this.props}
//         show={this.props.show}
//         onClick={this.props.onClick}
//         value={this.props.value}
//         open={this.props.show}
//         onClose={handleClose}
//         onHide={this.props.onHide}
//       >
//         <BaseModal.BaseModalHeader>
//           Delete Use Agreement
//         </BaseModal.BaseModalHeader>
//         <BaseModal.BaseModalBody>
//           <Box>
//             <Grid container>
//               <Grid item className='invite-student__description'>
//                 <Typography variant="paragraph" component="p">
//                   Be sure you want to delete this Use Agreement.
//                 </Typography>
//               </Grid>
//               <Grid item sx={{pb:'0px !important'}} className='invite-student__description'>
//                 <Typography variant="paragraph" component="p">
//                   Reminder: if you delete this Use Agreement, you will no longer be able to access the information about this form through the web app.
//                 </Typography>
//               </Grid>
//             </Grid>
//           </Box>
//           <BaseModal.BaseModalFooter>
//             <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='model-release__buttons'>
//               <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
//                 <Grid item xs={12} sm={6} md={4}>
//                   <Button
//                     variant='contained'
//                     type='submit'
//                     disabled={this.state.isSubmitting}
//                     title={
//                       this.state.isSubmitting ? <span className='icon-text-span'>
//                       <Spinner
//                         as="span"
//                         animation="border"
//                         size="sm"
//                         role="status"
//                         aria-hidden="true"
//                       /> Deleting... </span> : 'Delete Use Agreement'
//                     }
//                     onClick={() => this.handleFormSubmit(manageOnMutate)}
//                     sx={{width:{xs:'100%', sm:'100%'}}}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
//                   <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={() => this.props.onHide()}>
//                     Cancel
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Box>
//           </BaseModal.BaseModalFooter>
//         </BaseModal.BaseModalBody>
//       </BaseModal>
//       ) }
//     </Mutation>
//   }
// }

// DeleteUseAgreementModal.propTypes = {
//   classes: PropTypes.object.isRequired
// }

// export default withStyles(styles)(DeleteUseAgreementModal)

export default DeleteUseAgreementModal
