import { gql } from '@apollo/client'

export const COHORT_AWARD_COUNT_QUERY = gql`
  query sizeOfLibrary($cohortId: ID!) {
    allCount: cohortAwardTypeCount(cohortId: $cohortId, awardClass: "All")
    aoeCount: cohortAwardTypeCount(cohortId: $cohortId, awardClass: "Award of Excellence")
    hmCount: cohortAwardTypeCount(cohortId: $cohortId, awardClass: "Honorable Mention")
    noneCount: cohortAwardTypeCount(cohortId: $cohortId, awardClass: "None")
  }
`

export const COHORT_PAGINATED_PHOTOS_BY_AWARD_QUERY = gql`
  query PaginatedAwardedCohortPhotos($cohortId: ID!, $awardClass: String!, $cursor: String) {
    cohortAwardTypeCount(cohortId: $cohortId, awardClass: $awardClass)
    getPaginatedReviewPhotosIds(cohortId: $cohortId, awardClass: $awardClass) {
      id
    }
    getPaginatedReviewPhotos(
      first: 24
      cohortId: $cohortId
      awardClass: $awardClass
      after: $cursor
    ) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          updatedAt
          caption
          title
          originalTitle
          originalCaption
          isShortListed
          submittedDate
          adminApproved
          attribution
          url
          cdnOriginalUrl
          originalUrl
          downloadUrl
          sizedUrl(screenWidth: 500)
          summarySent
          educatorSummaryAt
          sentStudentSummary
          studentSummaryAt
          published
          complete
          hasPeople
          submitted
          classShare
          originalFileSize
          mrAdminNotes
          totalMrRequired
          readyToPublish
          createdAt
          exifData{
            fileType
          }
          modelReleases {
            id
            uuid
            createdAt
            signed
            isMinor
            isDeceased
            signeeFirstName
            signeeLastName
            mrStatus
            adminDecision
            signedOn
            adminDecisionOn
            adminRejectionNote
          }
          useAgreements {
            id
            signed
            signeeFirstName
            signeeLastName
            sentVia
            uaStatus
            adminDecision
            adminRejectionNote
            adminDecisionOn
            signedon
            createdAt
            uuid
          }
          awards {
            id
            awardClass
            nominatorInitials
            nominatorUserId
            nominatorName
            nominatorType
            isEdited
            editedOn
          }
          photoComments {
            body
            id
            commenterInitials
            commenterUserId
            commenterName
            createdAt
          }
          project {
            id
            submitted
            reviewed
            cohort {
              id
              endDate
              isReviewSend
              reviewed
              curatorReviews {
                id
                reviewerId
              }
              school {
                id
                name
              }
            }
            student {
              id
              name
              mobileNumber
              email
              dob
            }
          }
        }
      }
    }
  }
`
export const REVIREW_BY_COHORT_ID = gql`
  query ClassReview($id: ID!) {
    cohort(id: $id) {
      id
      uuid
      name
      gradeLevel
      startDate
      endDate
      estimatedNumberOfStudents
      isReviewSend
      sendForReviewOn
      curatorsDueDate
      reviewed
      summarySent
      educatorSummaryAt
      isReviewSend
      totalFileCount
      lastDownloadReq
      getLatestDownloadRequestStatus
      collectionId
      firstSync
      lastSync
      createdAt
      picsioCollection {
        id
        captionsCollectionId
        photoCollectionId
        uaCollectionId
        mrCollectionId
        classCollectionId
        firstSync
        lastSync
      }
      curators {
        name
        userId
        bio
        instagramHandle
        website
        email
        mobileNumber
        id
      }
      school {
        mailingAddress {
          state
          city
          id
        }
        id
        name
      }
      assignment {
        name
        id
      }
      teacher {
        name
        id
        email
        mobileNumber
      }
      facilitator {
        name
        id
        bio
        instagramHandle
        website
        email
        mobileNumber
      }
      curatorReviews {
        id
        hasReviewed
        reviewerId
        createdAt
      }
      projects {
        id
        submitted
        reviewed
        photos {
          id
          publishable
          project {
            id
          }
        }
        student {
          id
          dob
          name
        }
      }
      photos {
        id
        adminApproved
        covidRelated
        educatorSummaryAt
        published
        publishUpdatedAt
        sentStudentSummary
        studentSummaryAt
        summarySent
        hasPeople
        totalMrRequired
        mrAdminNotes
        readyToPublish
        isShortListed
        submittedDate
        awards {
          id
        }
        assetId
        formsPendingPicsioSync
      }
      submittedProjects {
        id
        submitted
        reviewed
        name
        submittedUpdatedAt
        student {
          id
          name
          mobileNumber
          email
        }
        cohort {
          id
          endDate
        }
        photos {
          id
          published
          summarySent
          sentStudentSummary
          originalFileSize
          rawFileUrl
          rawFileName
          rawFileSize
          project {
            id
            submitted
            submittedUpdatedAt
            reviewed
            cohort {
              id
              endDate
            }
          }
          exifData {
            size
            width
            height
            exposureProgram
            meteringMode
            iso
            lensModel
            focalLength
            cameraModel
            captureDate
            aperture
            shutterSpeedValue
            fileType
          }
          awards {
            id
            awardClass
            nominatorInitials
            nominatorUserId
            nominatorName
            nominatorType
            isEdited
            editedOn
            updatedAt
          }
          modelReleases {
            id
            assetId
          }
          useAgreements {
            id
            assetId
          }
          assetId
          formsPendingPicsioSync
        }
      }
    }
  }
`

export const ADD_COLLECTION = gql`
  mutation AddCollection(
    $cohortId: ID!
    $schoolCollectionId: String!
    $classCollectionId: String!
    $photoCollectionId: String!
    $captionsCollectionId: String!
    $formsCollectionId: String!
    $mrCollectionId: String!
    $uaCollectionId: String!
  ) {
    addCollection(
      cohortId: $cohortId
      schoolCollectionId: $schoolCollectionId
      classCollectionId: $classCollectionId
      photoCollectionId: $photoCollectionId
      captionsCollectionId: $captionsCollectionId
      formsCollectionId: $formsCollectionId
      mrCollectionId: $mrCollectionId
      uaCollectionId: $uaCollectionId
    ) {
      id
    }
  }
`
