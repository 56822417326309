import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Box, Paper, Typography } from '@mui/material'
import { Calendar } from 'react-feather'

const FacilitatorDashboardCard = ({ cohort, role }) => {
  const nextVisitType = (cohort) => {
    const { nextFacilitatorVisit, introVisit, midVisit, secondMidVisit, finalVisit } = cohort

    if (nextFacilitatorVisit === introVisit?.date) {
      return 'Introduction Visit On'
    } else if (nextFacilitatorVisit === midVisit?.date) {
      return 'Mid-Point Visit On'
    } else if (nextFacilitatorVisit === secondMidVisit?.date) {
      return 'Extra Visit On'
    } else if (nextFacilitatorVisit === finalVisit?.date) {
      return 'Final Visit On'
    } else {
      return 'Extra Visit On'
    }
  }

  return (
    <>
      <Box sx={{ m: '2px 2px 2px 2px'}}>
        <Paper elevation={2} sx={{ p: 4 }}>
          <Box>
            {cohort.school && ['facilitator', 'appadmin'].includes(role) && (
              <Typography variant='h4' component='h4' className='small_size'>
                {`${cohort.school.name}${cohort.school.mailingAddress?.state ? `, ${cohort.school.mailingAddress.state}` : ''}`}
              </Typography>
            )}
            <Typography variant='h4' className='semi-strong link' sx={{ mt: { xs: 1, md: 0.5 } }}>
              <Link to={`/class-detail/${cohort.id}`}>
                <Typography variant='h4' className='semi-strong' sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                  {cohort.name}
                </Typography>
              </Link>
            </Typography>
          </Box>
          <Box sx={{ mt: 6 }}>
            <Typography variant='h4' component='h4' className='small_size'>
              {nextVisitType(cohort)}
            </Typography>
            <Box component='p' sx={{ display: 'flex', mt: { xs: 1, md: 0.5 } }}>
              <Calendar size={19} />
              <Typography variant='h4' component='h4' sx={{ ml: 1 }}>
                {moment(cohort.nextFacilitatorVisit, 'YYYY-MM-DD').format('MMM DD, YYYY')}
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  )
}

export default FacilitatorDashboardCard
