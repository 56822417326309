import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import './curatorsUpcomingReviews.scss'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { UPCOMING_COHORT_REVIEW } from '../../queries/reviewQueries'
import Carousel from 'react-multi-carousel'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

class CuratorsUpcomingReviews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      gqlRes: null
    }
  }

  componentWillMount() {
    const mockObj = {
      hasNextPage: true,
      endCursor: ''
    }
    this.fetchMoreCohorts(mockObj)
  }

  fetchMoreCohorts = async ({ endCursor, hasNextPage }) => {
    if (!hasNextPage) return []
    this.setState({
      ...this.state,
      loading: true
    })
    await this.props.client
      .watchQuery({
        query: UPCOMING_COHORT_REVIEW,
        variables: {
          cursor: endCursor
        },
        fetchPolicy: 'network-only'
      })
      .subscribe(({ data }) => {
        if (!data) return
        this.mergeIncomingData(data)
      })
  }

  mergeIncomingData = data => {
    if (
      (this.state.gqlRes &&
        this.state.gqlRes.upcomingReviewsConnection.pageInfo.endCursor ===
          data.upcomingReviewsConnection.pageInfo.endCursor) ||
      Object.keys(data).length < 1
    )
      this.setState({
        ...this.state,
        loading: false
      })
    const current = (this.state.gqlRes && this.state.gqlRes.upcomingReviewsConnection) || {
      edges: []
    }
    const { edges } = current
    const newEdges = [...edges, ...data.upcomingReviewsConnection.edges]

    this.setState({
      ...this.state,
      gqlRes: {
        upcomingReviewsConnection: {
          edges: newEdges,
          pageInfo: data.upcomingReviewsConnection.pageInfo
        }
      },
      loading: false
    })
  }

  render() {
    const { gqlRes } = this.state
    const currentCohortUpcoming =
      gqlRes &&
      gqlRes.upcomingReviewsConnection &&
      gqlRes.upcomingReviewsConnection.edges &&
      gqlRes.upcomingReviewsConnection.edges.length > 0
    return (
      <Box>
        <Typography variant='h2' className='strong'>
          Classes Submitting Soon
        </Typography>

        <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 }, mb: { xs: 4 } }}>
          {currentCohortUpcoming ? (
            <>
              These classes are scheduled to submit soon. After they submit and are processed by the{' '}
              <i>wrkxfmly</i> administrator, you will receive an email asking you to begin
              curatorial review.
            </>
          ) : (
            'There are no classes due to submit in the next week.'
          )}
        </Typography>
        {currentCohortUpcoming &&
          gqlRes &&
          gqlRes.upcomingReviewsConnection &&
          gqlRes.upcomingReviewsConnection.edges && (
            <Box sx={{ mb: {xs: 6} }}>
              <Carousel
                responsive={responsive}
                arrows={false}
                renderDotsOutside={true}
                showDots={true}
                autoPlay={false}
                shouldResetAutoplay={false}
                dotListClass='wa-upcoming-custom-dot-container'
              >
                {gqlRes.upcomingReviewsConnection.edges.map(cohortNode => {
                  let cohort = cohortNode.node
                  return (
                    <Box key={cohort.id} sx={{ m: '2px' }}>
                      <Paper elevation={2} sx={{ p: 2 }}>
                        <Box sx={{ p: 0, mr: 0 }}>
                          <Typography
                            variant='h5'
                            sx={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            {cohort.school.name}
                          </Typography>
                          <Typography
                            variant='h4'
                            sx={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden'
                            }}
                          >
                            {cohort.name}
                          </Typography>
                          <Typography variant='h5'>
                            {cohort.studentsCount + ' Students in Class'}
                          </Typography>
                          <Box className='upcoming-reviews__flex-section--boxes---subsection'>
                            <Typography variant='h5'>
                              Scheduled to Submit On
                              <br />
                              {moment(cohort.endDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                            </Typography>
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  )
                })}
              </Carousel>
            </Box>
          )}
      </Box>
    )
  }
}
export default inject('userStore')(observer(withApollo(CuratorsUpcomingReviews)))
