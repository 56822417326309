import React from 'react'
import './herotabs.scss'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

class AdminReviewsFilters extends React.Component {
  render() {
    const tabs = this.props.tabs
    const defaultActiveKey = tabs[0]['eventKey']
    return (
      <div className='hero__tabs class-tracker-hero__tabs'>
        <span className='class-tracker-filters'>View By:</span>
        <Tabs
          id='controlled-tab'
          activeKey={this.props.currentFilter}
          onSelect={this.props.tabChangeHandler}
          defaultActiveKey={defaultActiveKey}
          TabScrollButtonProps={{ sx: { width: '20px' } }}
        >
          {tabs.map((tab, index) => (
            <Tab key={index} eventKey={tab.eventKey} title={tab.title} id={tab.eventKey} />
          ))}
        </Tabs>
      </div>
    )
  }
}

export default AdminReviewsFilters
