import { gql } from "@apollo/client"

export const QUERY = gql`
  query passwordReset($token: String!) {
    passwordResetToken(token: $token)
  }
`
export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($token: String!, $newPassword: String!) {
    resetAccountPassword(token: $token, newPassword: $newPassword) {
      errors {
        path
        message
      }
    }
  }
`

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordRequest($email: String) {
    forgotAccountPassword(email: $email) {
      errors {
        path
        message
      }
    }
  }
`