import { Box, Popover, styled, Tooltip, tooltipClasses, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Info } from 'react-feather'
import { formatBytes } from '../../../../utils/commonFunctions'

const NOT_AVAILABLE_TEXT = 'Not Available'

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #dadde9'
  }
}))

const PhotoMetaDetails = (props) => {
    const { role, photo } = props
    const queryData = photo && photo.exifData
    const shouldShow = ['appadmin', 'teacher', 'facilitator', 'curator'].includes(role)

    const renderStudentProject = () => {
    if(props.toggleParentModalState) props.toggleParentModalState()
    const projectDetails = window.location.pathname.includes('project-detail')
    if (!projectDetails) {
      setTimeout(() => {
        window.location = `/project-detail/${photo.project.id}`
      }, 500)
    }
  }
    return (
      <Box component='div' className='photo-detail__file-replace' sx={{ textAlign: 'center', py: { xs: 4 }}}>
        <HtmlTooltip
          placement='top'
          title={
            <React.Fragment>
              <div className='popover__section'>
                <div className='popover__title'>Technical Information</div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>File Type</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.fileType
                      ? queryData.fileType
                      : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>File Size</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.size
                      ? formatBytes(queryData.size)
                      : NOT_AVAILABLE_TEXT}{' '}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Dimensions</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.width && queryData.height
                      ? queryData.width + ' x ' + queryData.height
                      : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Exposure</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.shutterSpeedValue && queryData.aperture
                      ? queryData.shutterSpeedValue + ' sec at f/' + queryData.aperture
                      : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Exposure Program</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.exposureProgram
                      ? queryData.exposureProgram
                      : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Metering Mode</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.meteringMode
                      ? queryData.meteringMode
                      : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>ISO Speed Rating</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.iso ? 'ISO ' + queryData.iso : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Focal Length</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.focalLength
                      ? queryData.focalLength
                      : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Lens</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.lensModel
                      ? queryData.lensModel
                      : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Camera Make & Model</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.cameraModel
                      ? queryData.cameraModel
                      : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Date and Time</span>{' '}
                  <span className='popover__detail--content'>
                    {queryData && queryData.captureDate
                      ? moment(queryData.captureDate, 'YYYY-MM-DD').format('MMMM D YYYY')
                      : NOT_AVAILABLE_TEXT}
                  </span>
                </div>
              </div>
            </React.Fragment>
          }
        >
          <Typography variant='dataLabel' component='p' tabIndex='1'
            sx={{
              width: 'fit-content',
              margin: 'auto',
              cursor: 'pointer',
              svg: {
                verticalAlign: 'middle'
              }
            }}
          >
            <Info size={16} color='#0076BB'/> Technical Information
          </Typography>
        </HtmlTooltip>

        {role !== 'student' ? (
          <Typography
            variant="link"
            component='p'
            tabIndex='2'
            onClick={() => renderStudentProject(props.photo)}
            sx={{
              width: 'fit-content',
              margin: 'auto',
              mt: {xs: 1}
            }}
          >View Student Project</Typography>
        ) : null}
    </Box>
  )
}

export default PhotoMetaDetails