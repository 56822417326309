import React from 'react'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { MoreHorizontal } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Menu, alpha, styled } from '@mui/material'

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    border: '1px solid black',
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      minHeight: 'fit-content',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}))

const ModelReleaseDropDown = props => {
  const { modelRelease, role } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClose = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return
    }
    setAnchorEl(null)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const dynamicId = Math.random()

  return (
    <Box component={'div'} sx={{ textAlign: 'right' }}>
      <Button
        id={`ellipse-button-${dynamicId}`}
        aria-controls={open ? `customized-menu-${dynamicId}` : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='text'
        disableElevation
        onClick={handleClick}
        style={{
          borderRadius: 0,
          border: 0,
          padding: 0,
          minWidth: 'fit-content',
          marginTop: '-18px'
        }}
      >
        <MoreHorizontal size={24} color='black' />
      </Button>
      <StyledMenu
        id={`customized-menu-${dynamicId}`}
        MenuListProps={{
          'aria-labelledby': `ellipse-button-${dynamicId}`
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {modelRelease.signed != null ? (
          <MenuItem>
            <a
              href={
                modelRelease.linkToFile
                  ? modelRelease.linkToFile
                  : '/model-release/' + modelRelease.uuid
              }
              rel='noopener noreferrer'
              target='_blank'
              className='modelReleaseList__link-section--anchors'
            >
              View Model Release
            </a>
          </MenuItem>
        ) : null}
        {modelRelease.signed &&
        ['appadmin'].includes(role) &&
        ((modelRelease.mrStatus === 'Active' && modelRelease.adminDecision === 'Pending') ||
          (modelRelease.mrStatus === 'Completed' && modelRelease.adminDecision === 'Approve') ||
          (modelRelease.mrStatus === 'Completed' && modelRelease.adminDecision === 'Reject')) ? (
          <MenuItem
            onClick={() => {
              props?.handleModalOpen?.('Approval', true, modelRelease.uuid)
              props?.setShowApprovalModal?.(true)
            }}
          >
            Approve or Reject
          </MenuItem>
        ) : null}
        {['appadmin'].includes(role) ? (
          <MenuItem
            onClick={() => {
              props?.handleModalOpen?.('Delete', true, modelRelease.uuid)
              props?.setShowDeleteModal?.(true)
            }}
          >
            Delete Model Release
          </MenuItem>
        ) : null}
        {modelRelease.signed === null &&
        ['appadmin', 'student'].includes(role) &&
        modelRelease.mrStatus === 'Active' ? (
          <MenuItem
            onClick={() => {
              props?.handleModalOpen?.('Resend', true, modelRelease.uuid)
              props?.setShowResendModal?.(true)
            }}
          >
            Resend Model Release
          </MenuItem>
        ) : null}
      </StyledMenu>
    </Box>
  )
}

ModelReleaseDropDown.propTypes = {
  modelRelease: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired
}

export default ModelReleaseDropDown
