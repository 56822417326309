import { gql } from "@apollo/client"

export const PAGINATED_STUDENT_PHOTOS_BY_FILTER = gql`
  query classTrackerStudentRow(
    $id: ID!
    $cohortId: ID!
    $batchSize: Int!
    $baseBatchSize: Int!
    $step: Int!
    $filter: String!
  ) {
    filteredPhotosByStudentId(
      id: $id
      cohortId: $cohortId
      batchSize: $batchSize
      baseBatchSize: $baseBatchSize
      step: $step
      filter: $filter
      onlyIds: false
    ) {
      id
      url
      sizedUrl(screenWidth: 500)
      originalUrl
      cdnOriginalUrl
      downloadUrl
      updatedAt
      title
      caption
      originalTitle
      originalCaption
      classShare
      isShortListed
      submittedDate
      altText
      mrAdminNotes
      summarySent
      attribution
      submitted
      originalFileSize
      rawFileUrl
      rawFileName
      rawFileSize
      hasPeople
      totalMrRequired
      readyToPublish
      published
      adminApproved
      covidRelated
      useAgreements {
        id
        linkToFile
        signed
        sentTo
        signeeFirstName
        signeeLastName
        sentVia
        uaStatus
        adminDecision
        adminRejectionNote
        adminDecisionOn
        signedon
        relatedUaUuid
        wantsUpdates
        email
        phoneNumber
        createdAt
        uuid
      }
      modelReleases {
        id
        uuid
        sentTo
        phoneNumber
        email
        sentViaSms
        sentViaEmail
        createdAt
        firstName
        lastName
        modelFirstName
        modelLastName
        linkToFile
        signed
        sentVia
        isMinor
        isDeceased
        signeeFirstName
        signeeLastName
        mrStatus
        adminDecision
        relatedToMr
        signedOn
        adminDecisionOn
        adminRejectionNote
      }
      exifData {
        size
        width
        height
        exposureProgram
        meteringMode
        iso
        lensModel
        focalLength
        cameraModel
        captureDate
        aperture
        shutterSpeedValue
        fileType
      }
      awards {
        id
        awardClass
        nominatorInitials
        nominatorUserId
        nominatorName
        nominatorType
        isEdited
        editedOn
      }
      photoComments {
        body
        id
        commenterInitials
        commenterUserId
        commenterName
        createdAt
      }
      project {
        id
        submitted
        submittedUpdatedAt
        reviewed
        cohort {
          id
          endDate
          isReviewSend
          reviewed
        }
        student {
          id
          name
          mobileNumber
          email
          dob
        }
      }
    }
    filteredPhotosCountByStudentId(id: $id, cohortId: $cohortId, filter: $filter)
    photoIds: filteredPhotosByStudentId(
      id: $id
      cohortId: $cohortId
      batchSize: $batchSize
      baseBatchSize: $baseBatchSize
      step: $step
      filter: $filter
      onlyIds: true
    ) {
      id
    }
  }
`

export const PAGINATED_STUDENT_PHOTOS_COUNT_BY_FILTER = gql`
  query studentPhotoCountByFilter($id: ID!, $cohortId: ID!, $filter: String!) {
    filteredPhotosCountByStudentId(id: $id, cohortId: $cohortId, filter: $filter)
  }
`