import { Component, Fragment } from 'react'
import { Box, Grid } from '@mui/material'
import { inject, observer } from 'mobx-react'
import { withRouter } from '../../utils/customHoc'
import CustomBreadcrumbs from '../elements/CustomBreadcrumbs'
import AdminReviewsFilters from '../molecules/AdminReviewsFilters.js'
import ClassTrackerHeroTabs from '../molecules/ClassTrackerHeroTabs.js'
import HeroHeader from '../molecules/HeroHeader.js'
import HeroTabs from '../molecules/HeroTabs.js'
import SubHeader from '../molecules/SubHeader.js'
import variables from '../settings/_variables.scss'
import './hero.scss'

class Hero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filtersArr: this.props.filtersArr
    }
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.filtersArr) !== JSON.stringify(this.state.filtersArr)) {
      this.setState({
        ...this.state,
        filtersArr: nextProps.filtersArr
      })
    }
  }

  render() {
    const { userStore, currentTab } = this.props
    const { isAuthenticated } = userStore
    const { role } = userStore
    const tabs = this.props.tabs

    return (
      <Fragment>
        <Box className='container__hero'>
          <Box
            sx={{
              p: {
                xs: `${variables.spacing24px} ${variables.spacingRegular}`,
                sm: `${variables.spacingRegular}`,
                md: `${variables.spacingRegular} ${variables.spacingLarge}`
              }
            }}
          >
            {isAuthenticated ? (
              <Box sx={{ mb: 2 }}>
                <CustomBreadcrumbs />
              </Box>
            ) : null}
            <Grid container alignItems='center'>
              <Grid item md={12} lg={12} sm={12} xs={12} alignItems='center'>
                <HeroHeader heroHeader={this.props.heroTitle} photoCount={this.props.photoCount} />
                {this.props.heroSubTitle || this.props.heroTimeline || this.props.schoolName ? (
                  this.props.heroSubTitle || this.props.schoolName ? (
                    <SubHeader
                      subHeader={this.props.heroSubTitle}
                      schoolName={this.props.schoolName}
                      cohortName={this.props.cohortName}
                    />
                  ) : null
                ) : null}
              </Grid>
            </Grid>
            <Box />
          </Box>
          {this.props.tabs && this.props.tabs.length > 0 ? (
            <Fragment>
              <Box className='container__hero--tabs'>
                <HeroTabs
                  currentTab={this.props.currentTab}
                  tabChangeHandler={this.props.tabChangeHandler}
                  tabs={tabs}
                />
              </Box>
            </Fragment>
          ) : null}
          {window.location.pathname.includes('class-tracker') ? (
            <Fragment>
              <Box className='container__hero--tabs'>
                <ClassTrackerHeroTabs
                  currentTab={this.props.currentTab}
                  tabChangeHandler={this.props.onClick}
                  tabs={this.props.classTrackerTabs}
                  currentFilter={this.props.currentFilter}
                  collapseAllFunc={this.props.collapseAllFunc}
                  expandAll={this.props.expandAll}
                />
              </Box>
            </Fragment>
          ) : null}
          {window.location.pathname.includes('reviews') &&
          role === 'appadmin' &&
          currentTab === 'in-progress' ? (
            <Fragment>
              <Box className='container__hero--tabs'>
                <AdminReviewsFilters
                  tabChangeHandler={this.props.onClick}
                  tabs={this.props.reviewFilters}
                  currentFilter={this.props.currentFilter}
                  collapseAllFunc={this.props.collapseAllFunc}
                  expandAll={this.props.expandAll}
                />
              </Box>
            </Fragment>
          ) : null}
        </Box>
      </Fragment>
    )
  }
}

export default inject('userStore', 'breadcrumbStore')(observer(withRouter(Hero)))
