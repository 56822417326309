import React, { Component } from 'react'
import './modals.scss'
import { Typography } from '@mui/material'
import Modal from 'react-bootstrap/Modal'

class UseAgreementTermsGuardianModal extends Component {
  render() {
    const { isStudentMinor } = this.props
    return (
      <Modal
        {...this.props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={this.props.onHide}
        show={this.props.show}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <Typography variant="h2">{isStudentMinor ? 'Photograph Use Agreement for Minors' : 'Photograph Use Agreement for Adults'}</Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            isStudentMinor ?
              <>
                <Typography variant="paragraph" component="p">
                  This is a request for your permission to publish the work of a child under your guardianship [the ‘work’ includes their photograph(s) and/or video(s), text such as the title(s) and caption(s) accompanying their photograph(s) and/or video(s), and personally identifying information such as their name and image] for the <i>wrkxfmly</i> assignment organized by Working Assumptions.
                </Typography>
                <br />
                <Typography variant="paragraph" component="p">
                  By signing below, you confirm that you have seen the work of the child under your guardianship and you give permission for Working Assumptions to publish and use the work in the following ways:
                </Typography>
              </>
            : <>
              <Typography variant="paragraph" component="p">
                This is a request for your permission to publish your work [which includes your photograph(s) and/or video(s), text such as the title(s) and caption(s) accompanying your photograph(s) and/or video(s), and personally identifying information such as your name and image] for the <i>wrkxfmly</i> assignment organized by Working Assumptions.
              </Typography>
              <br />
              <Typography variant="paragraph" component="p">
                By signing below, you confirm that you are 18 years of age or older and that you give permission for Working Assumptions to publish and use your work in the following ways:
              </Typography>
            </>
          }
          <br />
          <ul>
            <li>
              Working Assumptions may store the work in our private archive, reproduce it, exhibit it, and include it in print publications, in digital formats, online, and in other media. Note that the work will not be used for any commercial purposes.
            </li>
          </ul>
          <br />
            {
              isStudentMinor ?
                <Typography variant="paragraph" component="p">
                  If you choose not to give permission, the student participating in the <i>wrkxfmly</i> assignment will still be eligible to receive an award. Their photograph(s) and/or video(s) would not be published, remaining in the <i>wrkxfmly</i> web application, visible only to select logged-in users connected to the education program.
                </Typography>
              : <Typography variant="paragraph" component="p">
                  If you choose not to give permission, you will still be eligible for an award. Your work would not be published, remaining in the <i>wrkxfmly</i> web application, visible only to select logged-in users connected to the education program.
                </Typography>
            }
            <br />
            <Typography variant="paragraph" component="p">
              You can find out more about the assignment by visiting{' '}
              <a target='_blank' href='http://waed.org'>
                waed.org
              </a>.
            </Typography>
        </Modal.Body>
      </Modal>
    )
  }
}

export default UseAgreementTermsGuardianModal
