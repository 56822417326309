import React, { Component } from 'react'
import './alert.scss'
import { AlertTriangle, Clock } from 'react-feather'
import { default as BootstrapAlert } from 'react-bootstrap/Alert'
import { Typography } from '@mui/material'

class Alert extends Component {
  render() {
    return (
      <BootstrapAlert dismissible className='alert-container'>
        <div className='alert--content'>
          <Typography variant="h3" className='strong'>
            <div
              className={this.props.alert ? 'alert__symbol-container' : 'warning__symbol-container'}
            >
              <div className='alert-icon'>
                {this.props.alert ? <AlertTriangle color={'#fff'} /> : <Clock color={'#fff'} />}

              </div>
            </div>

            <div className={this.props.alert ? 'alert-title' : 'warning-title'}>
              <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p" 
                >
                <strong>{this.props.alertTitle}</strong>
              </Typography>
            </div>
            <div className='alert-message'>
              <Typography variant="paragraph" component="p">{this.props.alertMessage}</Typography>
            </div>
          </Typography>
        </div>
        {this.props.alertList ? <Typography variant="paragraph" component="p">{this.props.alertList}</Typography> : null}
      </BootstrapAlert>
    )
  }
}

export default Alert
