import { gql } from '@apollo/client'

export const CREATE_CLASS = gql`
  mutation CreateCohort(
    $startDate: String!
    $endDate: String!
    $name: String!
    $estimatedNumberOfStudents: Int!
    $gradeIds: String!
    $teacherId: ID
    $facilitatorId: ID
    $curatorIds: [ID!]
    $otherGradeDetail: String!
    $termAccepted: Boolean!
  ) {
    createCohort(
      teacherId: $teacherId
      facilitatorId: $facilitatorId
      curatorIds: $curatorIds
      startDate: $startDate
      endDate: $endDate
      name: $name
      estimatedNumberOfStudents: $estimatedNumberOfStudents
      gradeIds: $gradeIds
      otherGradeDetail: $otherGradeDetail
      termAccepted: $termAccepted
    ) {
      id
    }
  }
`

export const ADD_COHORT_SYNC_DATES = gql`
  mutation AddCohortSyncDate($cohortId: ID!) {
    addCohortSyncDate(cohortId: $cohortId) {
      id
      firstSync
      lastSync
    }
  }
`
