import React, { useState, useEffect } from 'react'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import UserListRowAdmins from '../molecules/UserListRowAdmins.js'
import InviteStudentModal from '../molecules/InviteStudentModal.js'
import SendMessageModal from '../molecules/SendMessageModal.js'
import { observer, inject } from 'mobx-react'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import Loading from '../../ui/elements/Loading.js'
import { Box, Grid, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc.js'
import CustomPagination from '../molecules/CustomPagination.js'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

const GET_ADMINS_QUERY = gql`
  query getAdmins ($batchSize: Int!, $baseBatchSize: Int!, $step: Int!) {
    admins(batchSize: $batchSize, baseBatchSize: $baseBatchSize, step: $step){
      id
      userId
      name
      lastName
      lastSeenAt
      name
      accountDisabledAt
    }
    adminCount
  }
`
const BATCH_SIZE = 10

const UserListAdmins = (props) => {

    const {location} = props
    const [checkedReminder, setCheckedReminder] = useState(false)
    const [checkedBoxes, setNewCheckedBoxes] = useState({})
    const [modalShow] = useState(false)
    const [modalSendMessage, setModalSendMessage] = useState(false)
    const [adminCount, setAdminCount] = useState(0)
    const [step, setStep] = useState(0)
    const [batchSize] = useState(BATCH_SIZE)
    const [baseBatchSize] = useState(BATCH_SIZE)
    const [totalPages, setTotalPages] = useState(0)
    const [admins, setAdmins] = useState([])
    const navigate = useNavigate()

    const { loading } = useQuery(GET_ADMINS_QUERY,
      {variables: {
        step: step,
        batchSize: batchSize,
        baseBatchSize: baseBatchSize
      },
      fetchPolicy: 'network-only',
      onCompleted: data => {
        setAdmins( data)
        setAdminCount( data.adminCount)
        setTotalPages(Math.ceil(data.adminCount / batchSize))
        setCheckedBoxes(data.admins)
      }
    })

    const checkAllCheckedBoxes = () => {
      const listAdmins = admins.admins ? admins.admins : []
      const newState = Object.assign({}, checkedBoxes)
      const isSelected = !checkedReminder ? true : false
      listAdmins.forEach(admin => (
        newState[admin.id] = isSelected
      ))
      setNewCheckedBoxes(newState)
      setCheckedReminder(isSelected)
    }

    const setCheckedBoxes = (admins) => {
      const checkedBoxes = {}
      admins.forEach(admin => (checkedBoxes[admin.id] = false))
      setNewCheckedBoxes(checkedBoxes)
    }

    const updatedCheckedBoxes = adminId => {
      const newState = Object.assign({}, checkedBoxes)
      newState[adminId] = newState[adminId] ? false : true
      setNewCheckedBoxes(newState)
      setCheckedReminder(Object.values(newState).includes(false) ? false : true)
    }

    useEffect(() => {
      setStep(isNaN(parseInt(props?.params?.page)) ? 0 : (parseInt(props?.params?.page) - 1))
    }, [props?.params])

    const handlePageChange = page => {
      setCheckedReminder(false)
      // Update the URL
      const pathParts = location.pathname.split('/').filter(Boolean)
      if (!isNaN(pathParts[pathParts.length - 1])) {
        pathParts.pop()
      }
      const newPath = `/${pathParts.join('/')}/${page + 1}`
      navigate(newPath)
    }

    const modalClose = () => { setModalSendMessage(false) }

    const listAdmins = admins.admins ? admins.admins : []
    const selectedAdmins = listAdmins.filter(admin => checkedBoxes[admin.id]).map(admin => admin.userId)

  return(
    (loading) ? <Loading /> :
    <>
      <Box sx={{ pb: { xs: 4 } }}>
        <Box sx={{ px: { xs: 4, md: 0 } }}>
          <Grid container spacing={2}>
            <Grid item md={5} sm={12} xs={12}>
              <Typography variant='h1' className='bold'>
                Administrators ({adminCount})
              </Typography>
            </Grid>
            <Grid
              item
              md={7}
              sm={12}
              xs={12}
              sx={{
                textAlign: {
                  xs: 'left',
                  sm: 'left',
                  md: 'right'
                }
              }}
            >
              <Grid
                container
                spacing={{
                  xs: 3,
                  sm: 3,
                  md: 3
                }}
                columnSpacing={1}
                alignItems='center'
              >
                <Grid item md={12} sm={12} xs={12}>
                  <Button
                    variant='contained'
                    title='Add an Administrator'
                    onClick={() => navigate('/create-user/appadmin')}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 'fit-content'
                      }
                    }}
                  />
                  <Box sx={{ display: { xs: 'block', sm: 'none' }, height: 1 }} />
                  <Button
                    variant='outlined'
                    title='Message Selected People'
                    disabled={selectedAdmins.length < 1}
                    onClick={() => {setModalSendMessage(true)}}
                    sx={{
                      ml: 2,
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 'fit-content'
                      },
                      display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'inline-flex'
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'block',
                      md: 'none'
                    }
                  }}
                >
                  <Button
                    variant='outlined'
                    title='Message Selected People'
                    disabled={selectedAdmins.length < 1}
                    onClick={() => {setModalSendMessage(true)}}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 'fit-content'
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <>
          <SendMessageModal
            show={modalSendMessage}
            onHide={modalClose}
            userIds={selectedAdmins}
          />
          <InviteStudentModal
            show={modalShow}
            onHide={modalClose}
            classUuid={props.classUuid}
          />
        </>
        <Box
          sx={{
            mt: { xs: 5, md: 4, lg: 5 },
            mb: { xs: '1.125rem', md: 2 },
            px: { xs: 3, md: 4 }
          }}
        >
          <Grid container alignItems='center'>
            <Grid item md={1} sm={1} xs={1}>
              <Box sx={{ textAlign: 'center' }}>
                <FormCheckbox
                  checked={checkedReminder}
                  onChange={checkAllCheckedBoxes}
                  value='checkedReminder'
                  color='primary'
                />
              </Box>
            </Grid>
            <Grid
              item
              md={5}
              sm={6}
              xs={9}
              sx={{
                display: {
                  xs: 'none',
                  lg: 'block'
                }
              }}
            >
              <Typography variant='h4' className='table_header'>
                User Details
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              sm={5}
              xs={0}
              sx={{
                display: {
                  xs: 'none',
                  lg: 'block'
                }
              }}
            />
            <Grid
              item
              md={3}
              sm={0}
              xs={0}
              sx={{
                display: {
                  xs: 'none',
                  lg: 'block'
                }
              }}
            >
              <Typography variant='h4' className='table_header'>
                Activity
              </Typography>
            </Grid>
            <Grid
              item
              md={11}
              sm={11}
              xs={11}
              sx={{
                display: {
                  xs: 'block',
                  lg: 'none'
                }
              }}
            >
              <Typography variant='h4' className='table_header'>
                Select All Rows
              </Typography>
            </Grid>
          </Grid>
        </Box>
        { loading ? (
          <Box className='reivew-list-loader--wrapper'>
            <Loading />
          </Box>
        ) : null}
        { listAdmins.map(admin => (
            <UserListRowAdmins
              key={admin.id}
              lastSeenAt={admin.lastSeenAt}
              admin={admin}
              checked={checkedBoxes[admin.id] || false}
              onChecked={() => updatedCheckedBoxes(admin.id)}
              userId={admin.userId}
              showToast={props.showToast}
              refetchData={props.fetchData}
            />
          ))
        }
      </Box>
      <Box sx={{ mt: { xs: 4 } }}>
        <CustomPagination totalPages={totalPages} pageNum={props?.params?.page ? parseInt(props?.params?.page) : 1} onChange={handlePageChange}/>
      </Box>
    </>
  )
}

export default inject('userAdminsFilterStore')(observer(withRouter(withApollo(UserListAdmins))))