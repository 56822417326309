import React, { Component, Fragment } from 'react'
import './photorelease.scss'
import _ from 'lodash'
import moment from 'moment'
import { observer, inject } from 'mobx-react'
import { AlertTriangle, PlusSquare } from 'react-feather'
import UseAgreementModal from './UseAgreementModal'
import UseAgreementList from './UseAgreementList'
import { getUaByStatus, orderUseAgreements, waPrivateCipher } from '../../utils/commonFunctions'
import './useAgreementModal.scss'
import GATag from '../../utils/GATag'
import { Typography } from '@mui/material'

class UseAgreementSummary extends Component {
  constructor(props, context) {
    super(props, context)
    const birthDay =
      !!moment().diff(props.student.dob, 'years') === false
        ? 0
        : moment().diff(props.student.dob, 'years')

    this.state = {
      openOne: false,
      useAgreements:
        this.props.useAgreements && this.props.useAgreements.length > 0
          ? orderUseAgreements(getUaByStatus(this.props.useAgreements, 'Active'))
          : [],
      allUseAgreements:
        this.props.useAgreements && this.props.useAgreements.length > 0
          ? this.props.useAgreements
          : [],
      inactiveUseAgreements:
        this.props.useAgreements && this.props.useAgreements.length > 0
          ? orderUseAgreements(getUaByStatus(this.props.useAgreements, 'Inactive'))
          : [],
      resendMessage: false,
      resendUuid: '',
      totalMrRequired: this.props.totalMrRequired || 0,
      showUseAgreementModal: false,
      activeUa:
        this.props.useAgreements &&
        this.props.useAgreements.length > 0 &&
        orderUseAgreements(getUaByStatus(this.props.useAgreements, 'Active')).length > 0
          ? orderUseAgreements(getUaByStatus(this.props.useAgreements, 'Active'))[0]
          : '',
      activeUaUuid:
        this.props.useAgreements &&
        this.props.useAgreements.length > 0 &&
        orderUseAgreements(getUaByStatus(this.props.useAgreements, 'Active')).length > 0
          ? orderUseAgreements(getUaByStatus(this.props.useAgreements, 'Active'))[0].uuid
          : '',
      isStudentMinor: birthDay < 18,
      isUaApproved:
        this.props.useAgreements && this.props.useAgreements.length > 0
          ? this.checkAdminApprovedUa(this.props.useAgreements)
          : false,
      isActiveUaRejected:
        this.props.useAgreements && this.props.useAgreements.length > 0
          ? this.props.useAgreements.filter(
              ua => ua.uaStatus === 'Completed' && ua.adminDecision === 'Reject'
            ).length > 0
          : false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      useAgreements:
        nextProps.useAgreements && nextProps.useAgreements.length > 0
          ? orderUseAgreements(getUaByStatus(nextProps.useAgreements, 'Active'))
          : [],
      inactiveUseAgreements:
        nextProps.useAgreements && nextProps.useAgreements.length > 0
          ? orderUseAgreements(getUaByStatus(nextProps.useAgreements, 'Inactive'))
          : [],
      allUseAgreements:
        nextProps.useAgreements && nextProps.useAgreements.length > 0
          ? nextProps.useAgreements
          : [],
      activeUa:
        nextProps.useAgreements &&
        nextProps.useAgreements.length > 0 &&
        orderUseAgreements(getUaByStatus(nextProps.useAgreements, 'Active')).length > 0
          ? orderUseAgreements(getUaByStatus(nextProps.useAgreements, 'Active'))[0]
          : [],
      activeUaUuid:
        nextProps.useAgreements &&
        nextProps.useAgreements.length > 0 &&
        orderUseAgreements(getUaByStatus(nextProps.useAgreements, 'Active')).length > 0
          ? orderUseAgreements(getUaByStatus(nextProps.useAgreements, 'Active'))[0].uuid
          : '',
      isUaApproved:
        nextProps.useAgreements && nextProps.useAgreements.length > 0
          ? this.checkAdminApprovedUa(nextProps.useAgreements)
          : false,
      isActiveUaRejected:
        nextProps.useAgreements && nextProps.useAgreements.length > 0
          ? nextProps.useAgreements.filter(
              ua => ua.uaStatus === 'Completed' && ua.adminDecision === 'Reject'
            ).length > 0
          : false
    })
  }

  handlePDFSubmitSuccess = () => {
    this.setState({
      ...this.state,
      useAgreements: [...this.state.useAgreements, { createdAt: Date.now(), linkToFile: 'empty' }]
    })
    alert('Model release successfully uploaded')
  }

  checkAdminApprovedUa = useAgreements => {
    return (
      useAgreements.filter(ua => ua.adminDecision === 'Approve' && ua.uaStatus === 'Completed')
        .length > 0
    )
  }

  checkIfApprovalPending = useAgreements => {
    let activeUa = getUaByStatus(useAgreements, 'Active')
    return activeUa && activeUa[0]
      ? (activeUa[0].signed &&
          activeUa[0].uaStatus === 'Active' &&
          activeUa[0].adminDecision === 'Pending') ||
          (activeUa[0].signed &&
            activeUa[0].uaStatus === 'Completed' &&
            activeUa[0].adminDecision === 'Approve')
      : false
  }

  showUaModel = (opType, activeUaUuid) => {
    // window.ga('send', 'event', 'use-agreement', 'open-single', 1)
    GATag.setEvent({
      category: 'use-agreement',
      action: 'open-single',
      label: 'use agreement',
      value: 1
    })
    this.setState({
      showUseAgreementModal: true,
      uaOptype: opType,
      activeUaUuid: activeUaUuid
    }, () => {
      if(this.props.toggleEditing)
        this.props.toggleEditing(true)
    })
  }

  render() {
    const { role } = this.props.userStore
    const { photo } = this.props
    let closeUaModal = () =>
      this.setState({
        showUseAgreementModal: false
      },() => {
        if(this.props.toggleEditing)
          this.props.toggleEditing(false)
      })
    const { useAgreements, activeUaUuid, activeUa, isActiveUaRejected } = this.state
    const shouldShow = (photo && photo.submitted && photo.project.cohort.reviewed && photo.summarySent && photo.awards.length > 0 && role === 'appadmin') ||
    (photo && photo.submitted && photo.project.cohort.reviewed && photo.summarySent && photo.awards.length > 0 && ['student', 'teacher', 'facilitator'].includes(role))
    return shouldShow ? (
      <Fragment>
        <div className='body__use-agreement'>
          <div className='award-nominator__publish-photograph--header'>
            <Typography variant="h2">Use Agreement</Typography>
          </div>

          {['appadmin', 'teacher', 'facilitator'].includes(role) && !this.state.isUaApproved ? (
            <h5 className='h5--semi-strong alert mb_18 mt_1p'>
              <span className='icon-text-span'>
                <AlertTriangle size={16} /> This photograph needs an approved Use Agreement before
                it can be published.
              </span>
            </h5>
          ) : ['student'].includes(role) &&
            (this.state.useAgreements.length === 0 || isActiveUaRejected) ? (
            <h5 className='h5--semi-strong alert mb_20'>
              <span className='icon-text-span'>
                <AlertTriangle size={16} /> Add Use Agreement to this photograph.
              </span>
            </h5>
          ) : null}
          <div className='photo-release__instruction-section' id="mb_26">
            {role !== 'student' ? (
              (!photo.summarySent || photo.summarySent === null) && role === 'appadmin' ? (
                <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                  After you send the review summary to the student, they can add a Use Agreement.
                </Typography>
              ) : useAgreements.length === 0 ||
                (activeUa &&
                  ['Active'].includes(activeUa.uaStatus) &&
                  [null, 'Pending'].includes(activeUa.adminDecision)) ? (
                this.state.isStudentMinor ? (
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                    Because the student is under 18, you must ask their parent or legal guardian to grant permission to use this photograph.
                  </Typography>
                ) : (
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                    You can submit the Use Agreement form for this photograph on behalf of the
                    student by clicking the link below.
                  </Typography>
                )
              ) : activeUa &&
                activeUa.uaStatus === 'Completed' &&
                activeUa.adminDecision === 'Reject' ? (
                <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                  The student’s Use Agreement was rejected by Working Assumptions. Please check
                  below for the reason and add or send a new Use Agreement.
                </Typography>
              ) : activeUa &&
                ['Completed'].includes(activeUa.uaStatus) &&
                ['Approve'].includes(activeUa.adminDecision) ? (
                <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                  This photograph has a Use Agreement. Use Agreements are reviewed by Working
                  Assumptions before photographs are published. If any of the forms are not
                  approved, the student will receive an email asking them to try again.
                </Typography>
              ) : null
            ) : useAgreements.length === 0 ||
              (activeUa &&
                ['Active'].includes(activeUa.uaStatus) &&
                [null].includes(activeUa.adminDecision)) ? (
              !this.state.isStudentMinor ? (
                <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                  Please click below to fill out a digital Use Agreement form for this photograph.
                  The form will open in a new window.
                </Typography>
              ) : (
                <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                  Because you are under 18, you must ask your parent or legal guardian to grant permission to use this photograph.
                </Typography>
              )
            ) : activeUa &&
              activeUa.uaStatus === 'Completed' &&
              activeUa.adminDecision === 'Reject' ? (
              <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                Your Use Agreement was rejected by Working Assumptions. Please check below for the
                reason and add or send a new Use Agreement.
              </Typography>
            ) : (activeUa &&
                ['Active'].includes(activeUa.uaStatus) &&
                ['Pending'].includes(activeUa.adminDecision)) ||
              (activeUa &&
                ['Completed'].includes(activeUa.uaStatus) &&
                ['Approve'].includes(activeUa.adminDecision)) ? (
              <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >Thank you for adding a Use Agreement to this photograph.</Typography>
            ) : null}
          </div>

          {!this.checkIfApprovalPending(useAgreements) ? (
            <div className='model-release__list-mr-section'>
              {this.state.isStudentMinor ||
              ['appadmin', 'teacher', 'facilitator'].includes(role) ? (
                <h4
                  className='h4--semi-strong model-release__list-mr-add-link model-release__list--links'
                  onClick={() => this.showUaModel('add', activeUaUuid)}
                >
                  <span className='icon-text-span'>
                    <PlusSquare size={18} /> Add a Use Agreement
                  </span>
                </h4>
              ) : (
                <h4 className='h4--semi-strong model-release__list-mr-add-link model-release__list--links'>
                  <a
                    href={`/self-use-agreement/${waPrivateCipher(this.props.photoId)}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='icon-text-span'>
                      <PlusSquare size={18} /> Add a Use Agreement
                    </span>
                  </a>
                </h4>
              )}
              {useAgreements.length > 0 ? (
                <h5 className='model-release__list-mr-note'>
                  This will replace the current Use Agreement for this photograph.
                </h5>
              ) : null}
            </div>
          ) : null}

          {this.state.allUseAgreements.length > 0 ? (
            <UseAgreementList
              useAgreements={this.state.useAgreements}
              inactiveUseAgreements={this.state.inactiveUseAgreements}
              refetchPhoto={this.props.refetchPhoto}
              photoId={this.props.photoId}
              studentDetails={this.props.studentDetails}
              toggleEditing={this.props.toggleEditing}
              isStudentMinor={this.state.isStudentMinor}
              fetchProject={this.props.fetchProject}
            />
          ) : null}

          <UseAgreementModal
            show={this.state.showUseAgreementModal}
            onHide={closeUaModal}
            modelOp='add'
            photoIds={[this.props.photoId]}
            onSuccessCallback={closeUaModal}
            refetchQueries={this.props.refetchQueries}
            refetchPhoto={this.props.refetchPhoto}
            studentDetails={this.props.studentDetails}
            isStudentMinor={this.state.isStudentMinor}
          />
        </div>
      </Fragment>
    ) : null
  }
}

export default inject('userStore')(observer(UseAgreementSummary))
