import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FormCheckbox from '../elements/FormCheckbox.js'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'

class UserListRowFacilitators extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPhotos: false,
      facilitator: this.props.facilitator
    }
  }

  async componentDidMount() {
    const data = await this.props.client.readFragment({
      id: `Facilitator:${this.props.facilitator.id}`,
      fragment: gql`
        fragment readUpdatedFacilitatorBio on Facilitator {
          id
          userId
          name
          lastName
          lastSeenAt
          bio
          bioNeedsApproval
          lastSeenAt
          createdAt
          cohorts {
            id
          }
        }
      `
    })
    this.setState({
      ...this.state,
      facilitator: data
    })
  }

  render() {
    const { checked, userId } = this.props
    const { facilitator } = this.state
    return (
      <>
        <Box
          className='body__section'
          sx={{
            p: { xs: 3, md: 4 },
            borderBottom: { xs: '0.0625rem solid rgb(213, 213, 215)' },
            margin: { xs: '0px !important', md: '8px 0 !important' }
          }}
        >
          <Grid container alignItems='center'>
            <Grid item md={1} sm={1} xs={1}>
              <Box sx={{ textAlign: 'center' }}>
                <FormCheckbox
                  checked={checked}
                  onChange={() => this.props.onChecked(userId)}
                  value='checkedReminder'
                  color='primary'
                />
              </Box>
            </Grid>
            <Grid item md={5} sm={6} xs={9}>
              <Link to={'/user-details/'.concat(userId)}>
                <Typography variant='h4' className='semi-strong link link--large'>
                  {facilitator.name}
                </Typography>
              </Link>
              <Box sx={{ display: { xs: 'block', md: 'none' }, mt: { xs: 1, md: 2 } }}>
                <Link to={`/user-details/${userId}/classes`}>
                  <Typography variant='h4' className='semi-strong link link--large'>
                    {facilitator.cohorts.length} Classes
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item md={3} sm={5} xs={12} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Link to={`/user-details/${userId}/classes`}>
                <Typography variant='h4' className='semi-strong link link--large'>
                  {facilitator.cohorts.length} Classes
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              md={3}
              sm={4}
              xs={12}
              sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
            >
              {this.props.lastSeenAt ? (
                <Typography variant='h4' className='table_row'>
                  Last signed in {moment(this.props.lastSeenAt).format('LL')}
                </Typography>
              ) : (
                <Typography variant='h4' className='table_row'>
                  User has not signed up
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }
}

export default withApollo(UserListRowFacilitators)
