import React, { Component } from 'react'
import Switches from '../elements/Switches.js'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import _ from 'lodash'
import FormCheckbox from '../elements/FormCheckbox.js'
import { Col, Row } from 'react-bootstrap'
import { Typography } from '@mui/material'
import Button from '../elements/Button.js'
import { getApprovedEntityCount } from '../../utils/commonFunctions.js'

const TOGGLE_ADMIN_APPROVAL_MUTATION = gql`
  mutation ToggleAdminApproval($photoId: ID!, $isPublishable: Boolean!) {
    toggleAdminApproval(photoId: $photoId, isPublishable: $isPublishable) {
      id
    }
  }
`

const PUBLISH_PHOTO_MUTATION = gql`
  mutation PublishPhotos($photoId: ID!, $nationalCollection: Boolean!, $covidCollection: Boolean!, $opType: String!) {
    publishPhotos(photoId: $photoId, nationalCollection: $nationalCollection, covidCollection: $covidCollection, opType:$opType)
  }
`

class AdminPublishControls extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photo: props.photo ? props.photo : {},
      nationalCollection: props.photo && props.photo.adminApproved ? props.photo.adminApproved : (props.photo && !props.photo.published && props.photo.readyToPublish) ? true : false,
      covidCollection: props.photo && props.photo.covidRelated ? props.photo.covidRelated : false,
      readyToPublish: props.photo && props.photo.readyToPublish ? props.photo.readyToPublish : false,
      isPublished: props.photo && props.photo.published ? props.photo.published : false,
      enableRedyToPublish: props.photo
        ? (props.photo.hasPeople &&
            props.photo.totalMrRequired ===
              getApprovedEntityCount(props.photo.modelReleases, 'mr') &&
            getApprovedEntityCount(props.photo.useAgreements, 'ua') > 0) ||
          (props.photo.hasPeople === false &&
            getApprovedEntityCount(props.photo.useAgreements, 'ua') > 0)
        : false
    }
  }

  handleOnChange = (e, onMutate) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.checked,
      nationalCollection: e.target.checked ? true : false
    })
    onMutate({
      variables: {
        photoId: this.props.photoId,
        isPublishable: e.target.checked
      }
    })
  }

  handleOnCollectionChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.checked })
  }

  componentWillReceiveProps(nextProps) {
    if( nextProps.photo.id !== this.state.photo.id )
    {
      this.setState({
        photo: nextProps.photo ? nextProps.photo : {},
        nationalCollection: nextProps.photo && nextProps.photo.adminApproved ? nextProps.photo.adminApproved : (nextProps.photo && !nextProps.photo.published && nextProps.photo.readyToPublish) ? true : false,
        covidCollection: nextProps.photo && nextProps.photo.covidRelated ? nextProps.photo.covidRelated : false,
        readyToPublish: nextProps.photo && nextProps.photo.readyToPublish ? nextProps.photo.readyToPublish : false,
        isPublished: nextProps.photo && nextProps.photo.published ? nextProps.photo.published : false,
      })
    } else {
      this.setState({
        photo: nextProps.photo ? nextProps.photo : {},
        enableRedyToPublish: nextProps.photo
          ? (nextProps.photo.hasPeople &&
              nextProps.photo.totalMrRequired ===
                getApprovedEntityCount(nextProps.photo.modelReleases, 'mr') &&
              getApprovedEntityCount(nextProps.photo.useAgreements, 'ua') > 0) ||
            (nextProps.photo.hasPeople === false &&
              getApprovedEntityCount(nextProps.photo.useAgreements, 'ua') > 0)
          : false
      })
    }
  }

  handleSubmit = (e, onMutate, opType) => {
    e.preventDefault()
    if(opType==="unpublish") this.setState({isPublished:false})
    if(opType==="publish") this.setState({isPublished:true})
    onMutate({
      variables: {
        photoId: this.props.photoId,
        nationalCollection: this.state.nationalCollection,
        covidCollection: this.state.covidCollection,
        opType: opType
      }
    })

  }

  render() {
    const { photo, role, enableRedyToPublish } = this.props
    const shouldShow = photo &&
      photo.submitted &&
      photo.project.cohort.reviewed &&
      photo.awards.length > 0 &&
      role === 'appadmin'
    return shouldShow ? (
      <div className='award-nominator__publish-photograph'>
        <div className='award-nominator__publish-photograph--header'>
          <Typography variant="h2">Publish This Photograph</Typography>
        </div>
        <div className='award-nominator__publish-photograph--details'>
          <Mutation
            mutation={TOGGLE_ADMIN_APPROVAL_MUTATION}
            onCompleted={() => {
              this.props.refetchPhoto()
              this.setState({ loading: false })
            }}
          >
            {(onMutate, { data }) => (
              <Switches
                checked={this.state.readyToPublish}
                onChange={e => this.handleOnChange(e, onMutate)}
                disabled={!this.state.enableRedyToPublish}
                name='readyToPublish'
                switchLabel='Ready to Publish'
              />
              )}
          </Mutation>
          <div>
            <Typography variant="h4">
              Set as ready to publish when you have reviewed all details and approved all forms for this photograph.
            </Typography>
          </div>

          <div className='award-nominator__collections-section'>
            <Typography variant="paragraph" component="p">Which collection should this photograph appear in?</Typography>
            <div className='award-nominator__collections-section--options'>
              <Row>
                <Col md='true' sm={4}>
                  <FormCheckbox
                    label={<span className='award-nominator__checkbox-label'>National Collection</span>}
                    color='primary'
                    name='nationalCollection'
                    disabled={!this.state.readyToPublish || (this.state.readyToPublish && this.state.isPublished)}
                    checked={this.state.nationalCollection}
                    onChange={e => this.handleOnCollectionChange(e)}
                    style={{ marginTop: 10 }}
                  />
                </Col>
                <Col md='true' sm={4}>
                  <FormCheckbox
                    label={<span className='award-nominator__checkbox-label'>COVID-19 Collection</span>}
                    color='primary'
                    name='covidCollection'
                    disabled={!this.state.readyToPublish || (this.state.readyToPublish && this.state.isPublished)}
                    checked={this.state.covidCollection}
                    onChange={e => this.handleOnCollectionChange(e)}
                    style={{ marginTop: 10 }}
                  />
                </Col>
              </Row>
            </div>
            <Mutation
              mutation={PUBLISH_PHOTO_MUTATION}
              onCompleted={() => {
                this.props.refetchPhoto()
                this.setState({ loading: false })
              }}
            >
              {(onMutate, { data }) => (
                <>{
                  this.state.isPublished ?
                  <Button
                    variant='contained'
                    type='submit'
                    title='Unpublish Photograph'
                    onClick={e => this.handleSubmit(e, onMutate, 'unpublish')}
                  />
                  : <Button
                    variant='contained'
                    type='submit'
                    title='Publish Photograph'
                    onClick={e => this.handleSubmit(e, onMutate, 'publish')}
                    disabled={!this.state.readyToPublish || (this.state.readyToPublish && !(this.state.nationalCollection  || this.state.covidCollection)) }
                  />
                }</>
              )}
            </Mutation>
          </div>
        </div>
      </div>
    ) : null
  }
}

export default AdminPublishControls
