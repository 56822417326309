import React, { useEffect, useState } from 'react'
import Button from '../elements/Button.js'
import { gql, useQuery } from '@apollo/client'
import Loading from '../elements/Loading.js'
import { Typography, Box, Grid } from '@mui/material'
import { getTextContentByPage } from '../../content/textContent'
import FormSelect from '../elements/FormSelect'
import BaseModal from '../template/BaseModal';

const FACILITATORS_QUERY = gql`
  query FacilitatorList {
    facilitators {
      id
      name
      bio
      email
      userId
      mobileNumber
    }
  }
`
const AssignFacilitatorModal = props => {
  const [facilitatorId, setFacilitatorId] = useState(props.facilitatorId || null)
  const [facilitator, setFacilitator] = useState(props.facilitator || {})
  const [disableConfirm, setDisableConfirm] = useState(true)

  useEffect(() => {
    setFacilitatorId(props.facilitatorId)
    setFacilitator(props.facilitator)
  }, [props.facilitatorId, props.facilitator])

  const handlefacilitatorChange = (e, options) => {
    const selectedKey = Object.keys(options).find(x => options[x].value === e.target.value)
    const selectedValue = options[selectedKey]
    setFacilitatorId(e.target.value)
    setDisableConfirm(false)
    setFacilitator({
      id: e.target.value,
      name: selectedValue.label,
      bio: selectedValue.bio,
      email: selectedValue.email,
      userId: selectedValue.userId,
      mobileNumber: selectedValue.mobileNumber
    })
  }

  const handleSubmit = getFacilitator => {
    props.handleSave(getFacilitator)
    setDisableConfirm(true)
  }

  const handleCancel = () => {
    setFacilitatorId(props.facilitatorId)
    setFacilitator(props.facilitator)
    props.onHide()
  }

  const { loading, error, data } = useQuery(FACILITATORS_QUERY)

  if (loading) return <Loading />

  if (error)
    return (
      <Typography variant='paragraph' component='p'>
        Error loading classes
      </Typography>
    )

  if (data) {
    const options = data.facilitators.map(facilitator => ({
      label: facilitator.name,
      value: facilitator.id,
      bio: facilitator.bio,
      email: facilitator.email,
      userId: facilitator.userId,
      mobileNumber: facilitator.mobileNumber
    }))

    return (
      <BaseModal
        {...props}
        show={props.show}
        onClick={props.onClick}
        value={props.value}
        open={props.show}
        onClose={handleCancel}
        onHide={handleCancel}
      >
        <BaseModal.BaseModalHeader>
          Choose a Teaching Artist
        </BaseModal.BaseModalHeader>
        <BaseModal.BaseModalBody>
          {getTextContentByPage('CLASSDETAILS', 'ALL', 'ADD_FACILITATOR_TEXT')}
          <form>
            <FormSelect
              options={options}
              label='Class Teaching Artist'
              placeholder='Class Teaching Artist'
              onChange={e => handlefacilitatorChange(e, options)}
              name='facilitator'
              variant='filled'
              value={facilitatorId ? facilitatorId : null}
              inputProps={{ 'aria-label': 'Without label' }}
              fullWidth={true}
              sx={{width:{ xs:'100%', sm:'40%'}}}
            />

           <BaseModal.BaseModalFooter>
              <Box sx={{padding:'24px 0 0', marginTop:'0px'}} className='send-message__button'>
                <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button
                      variant='contained'
                      type='submit'
                      disabled={disableConfirm}
                      title='Confirm Teaching Artist'
                      onClick={() => handleSubmit(facilitator)}
                      sx={{width:{xs: '100%', md: '100%'}}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                    <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4" className='small_size_semi_bold link' onClick={handleCancel}>
                      Cancel
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </BaseModal.BaseModalFooter>
          </form>
        </BaseModal.BaseModalBody>
      </BaseModal>
    )
  }
}

export default AssignFacilitatorModal
