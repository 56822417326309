import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import '../molecules/modals.scss'
import '../base/global.scss'
import { BaseModalHeader } from '../elements/BaseModalHeader'
import { BaseModalBody } from '../elements/BaseModalBody'
import { BaseModalFooter } from '../elements/BaseModalFooter'

const BaseModal = ({ ...props }) => {
  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
      return
    }
    props.onHide(false)
  }

  return (
    <Modal
      {...props}
      size='lg'
      // centered
      open={props.open || false}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        maxHeight: { xs: 'calc(140vh - 64px)', sm: 'calc(150vh - 64px)' },
        background: 'rgba(45, 45, 45, 0.5)',
        backdropFilter: 'blur(2px)',
        overflowY: 'scroll'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: 'calc(100% - 48px)', sm: 'calc(100% - 64px)', md: '800px' },
          bgcolor: 'background.paper',
          background: '#FFFFFF',
          maxWidth: '90%'
        }}
      >
        <BaseModalHeader {...props} />
        <BaseModalBody {...props} />
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 32,
            top: 32,
            padding: 0,
            width: '16px',
            height: '16px',
            color: '#2D2D2D'
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  )
}

BaseModal.BaseModalHeader = BaseModalHeader
BaseModal.BaseModalBody = BaseModalBody
BaseModal.BaseModalFooter = BaseModalFooter
export default BaseModal
