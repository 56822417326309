import React, { useState, Fragment } from 'react'
import { Typography, Box } from '@mui/material'
import { gql, useMutation, useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { useParams } from 'react-router-dom'
import Layout from '../ui/template/Layout'
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import ErrorPage from '../pages/ErrorPage.jsx'
import DateUtils from '../utils/DateUtils.js'
import Loading from '../ui/elements/Loading'

const VerficationNewEmail = gql`
mutation EmailConfirmation($token: String!) {
  emailConfirmation(token: $token) {
    __typename
  }
}
`
const GetUserDetail = gql`
  query getUserDetails($confirmationToken: String!) {
    getUserDetails(confirmationToken: $confirmationToken) {
      id
      role
      newEmail
      confirmationSentAt
    }
  }
`

const Confirmation = props => {
  const { token } = useParams()
  const [mutate] = useMutation(VerficationNewEmail);
  const [userData, setUserData] = useState ()
  const [totalHours, setTotalHours] = useState (0)

  const callMutation = async () => {
    try {
      const response = await mutate({ variables: { token: token } });
    } catch (err) {
      return err
    }
  }

  const { data, loading } = useQuery(GetUserDetail, {
    variables: { confirmationToken: token },
    onCompleted(data) {
      setUserData(data?.getUserDetails);
      const hours = data?.getUserDetails === null ? 0 : DateUtils.getTimeDifferenceInHours(data?.getUserDetails?.confirmationSentAt);
      setTotalHours(hours)
      if (hours <= 23)
      {
        callMutation()
      }
    }
  })

  const heading = 'Invalid link.'
  const subHeading = (
    <Box>
      <Typography variant='paragraph' component='p'>
        This verification link has expired or is invalid.
      </Typography>
      <br />
      <Typography variant='paragraph' sx={{ mb: '1rem' }} component='p'>
        Visit your account details page to update your email address again, or contact {' '}
        <Box
          component={'a'}
          href='mailto:education@workingassumptions.org'
          target='_blank'
          rel='noopener noreferrer'
          sx={{ wordBreak: 'break-word' }}
        >
          <Typography component={'span'} variant='link' className='large'>
            education@workingassumptions.org
          </Typography>
        </Box> for support.
      </Typography>
    </Box>
  )
  return (
    loading ?  <Loading></Loading> :
    <>
    {(totalHours > 23) ?
     <Layout>
     <CssBaseline />
     <Fragment> <ErrorPage withInPage={true} heading={heading} subHeading={subHeading} /> </Fragment>
     </Layout>
    :
    <Layout>
      <CssBaseline />
      <Fragment>
        <Box component={'div'} className='container__body--login'>
          <Box className='container__login' sx={{px: {sm: 10, md: 13, lg: 15}, pt: {xs: 2, md: 8}, pb: {xs: 4}}}>
            <Box>
              <Stack>
                
                  <Box className='login__header'>
                    <Typography variant='h1' component='h1' className='bold' sx={{mt: {xs: 3, sm: 8, md: 0}}}>
                    {userData !== null ? "Your email address was confirmed." : "Your new email address is already verified."}
                    </Typography>
                  </Box>
                  <Box sx={{ mt: {xs: 4.1, sm: 9}}}>
                  {userData !== null ?
                    <Typography  variant='paragraph' sx={{ mb: '1rem' }} component='p'>
                      Thank you for verifying your email. You can now log into your <i>wrkxfmly</i> account using your new email address.
                    </Typography>
                    : 
                    <Typography  variant='paragraph' sx={{ mb: '1rem' }} component='p'>
                      Please log into your <i>wrkxfmly</i> account using your new email address.
                    </Typography>
                  }
                  </Box>
                
              </Stack>
            </Box>
          </Box>
        </Box>
      </Fragment>
    </Layout>
    }
    </>
  )
}

export default withApollo(Confirmation)