import React, { Component, Fragment } from 'react'
import ProjectTimelineImg from '../../assets/images/wa-project-timeline.png'

class ProjectTimeline extends Component {
  render() {
    return (
      <Fragment>
        <div className='body__project-timeline'>
          <img src={ProjectTimelineImg} width='375px' /> 
        </div>
      </Fragment>
    )
  }
}
export default ProjectTimeline
