import React, { Component, Fragment } from 'react'
import './createschool.scss'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import Hero from '../ui/organisms/Hero.js'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Typography } from '@mui/material'
import FormField from '../ui/elements/FormField.js'
import Button from '../ui/elements/Button.js'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Layout from '../ui/template/Layout'
import FormSelect from '../ui/elements/FormSelect'
import statesList from '../utils/USAStates'
import ToastNotification from '../ui/elements/ToastNotification'
import { withRouter } from '../utils/customHoc'
import { Spinner } from 'react-bootstrap'

const MUTATION = gql`
  mutation CreateSchool(
    $name: String!
    $address1: String!
    $address2: String
    $city: String!
    $state: String!
    $postCode: String!
    $website: String
    $demographicLink: String
    $schoolType: String!
    $schoolDistrict: String
  ) {
    createSchool(
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      postCode: $postCode
      website: $website
      demographicLink: $demographicLink
      schoolType: $schoolType
      schoolDistrict: $schoolDistrict
    ) {
      name
      id
    }
  }
`
const schoolTypes = [
  { value: 'Public', label: 'Public' },
  { value: 'Private', label: 'Private' },
  { value: 'Charter', label: 'Charter' },
  { value: 'Magnet', label: 'Magnet' },
  { value: 'Other', label: 'Other' }
]


function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsRoot: {
    borderBottom: '1px solid #D5D5D7'
  },
  tabsIndicator: {
    backgroundColor: '#0076BB'
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 70,
    fontSize: 13,
    fontWeight: 700,
    marginRight: 0,
    fontFamily: [
      'Calibre',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      color: '#000',
      opacity: 1
    },
    '&$tabSelected': {
      color: '#000',
      fontWeight: theme.typography.fontWeightBold,
      marginRight: 0
    },
    '&:focus': {
      color: '#000'
    }
  },
  tabSelected: {
    pointerEvents: 'all'
  },
  typography: {
    padding: 0
  }
})

class CreateSchool extends Component {
  constructor(props) {
    super(props)
    const { location, breadcrumbStore, basePath } = props
    this.state = {
      value: 'one',
      showToast: false,
      errors: {},
      submitted: false
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    breadcrumbStore.addBreadcrumb({
      pageName: 'Create a New School',
      link: location.pathname,
      basePath: basePath,
      isParent: false,
      isActive: true
    })
  }

  showToast = (toastType, message = '') => {
    const { navigate } = this.props
    this.setState({
      showToast: true,
      toastMessage: message,
      toastType: toastType
    })
    setTimeout(() => this.setState({ showToast: false }, navigate('/schools?refresh=true')), 2000)
  }

  enableStepThree() {
    return this.state.address1 && this.state.address1.trim() && this.state.city && this.state.city.trim() && this.state.state && this.state.postCode
  }

  handleSubmit(e, onMutate) {
    e.preventDefault()
    this.setState({submitted: true})
      onMutate({
        variables: {
          name: this.state.schoolName,
          address1: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          postCode: this.state.postCode,
          website: this.state.website,
          demographicLink: this.state.demographicLink,
          schoolType: this.state.schoolType,
          schoolDistrict: this.state.schoolDistrict
        }
      })
  }

  handleClose = (event, reason) => {

    if (reason === "clickaway") {
      return;
    }
    this.setState({ showToast: false })
  }


  handleFormChange(e) {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
    this.validateChange(e)
  }
  validateChange(e) {
    const urlRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
    const fieldName = e.target.name
    const value = e.target.value
    const nameFormat = /(\\|\/)/;
    switch (fieldName) {
      case 'schoolName':
        if (value === '') {

          this.setState({
            errors: {
              ...this.state.errors,
              schoolName: 'Please enter a school name.'
            }
          },
            () => this.isDisable()
          )
        } else if (value.length > 70) {
          this.setState({
            errors: {
              ...this.state.errors,
              schoolName: 'Maximum character limit 70'
            },
            disabled: true
          })
        } else if (nameFormat.test(value))
        {
          this.setState({
            errors: {
              ...this.state.errors,
              schoolName: 'Please do not use forward slash "/" in name.'
            },
            disabled: true
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, schoolName: null },
            disabled: false
          })
        }
        break
      case 'address1':
        if (value === '') {
          this.setState({
            errors: {
              ...this.state.errors,
              address1: 'Please enter an address.'
            }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, address1: null }
          })
        }
        break
      case 'postCode':
        if (value === '') {
          this.setState({
            errors: {
              ...this.state.errors,
              postCode: "Please enter the school's zip code."
            }
          },
            () => this.isDisable()
          )
        } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
          this.setState({
            errors: {
              ...this.state.errors,
              postCode: "Please enter the school's zip code."
            },
            disabled: true
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, postCode: null },
            disabled: false
          })
        }
        break
      case 'city':
        if (value === '') {
          this.setState({
            errors: {
              ...this.state.errors,
              city: "Please enter the school's city."
            }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, city: null }
          })
        }
        break
      case 'website':
        if (!urlRegexp.test(value) && value) {
          this.setState({
            errors: { ...this.state.errors, website: 'Please enter a valid URL.' }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, website: null }
          })
        }
        break
      case 'demographicLink':
        if (!urlRegexp.test(value) && value) {
          this.setState({
            errors: { ...this.state.errors, demographicLink: 'Please enter a valid URL.' }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, demographicLink: null }
          })
        }
        break
      default:
        this.setState({
          disabled: true
        })
        return
    }
  }

  isDisable = () => {
    this.setState({
      disabled: !_.isEmpty(
        Object.values(this.state.errors).filter(x => x !== undefined && x !== '' && x !== null)
      )
    })
  }

  render() {
    const { classes } = this.props
    const { value } = this.state
    const { handleFormChange } = this
    const { userStore } = this.props
    const role = userStore.role

    return (
      <Layout>
        <Mutation
          mutation={MUTATION}
          onCompleted={() => {
            this.showToast('success', `${this.state.schoolName} created.`)
          }}
        >
          {(onMutate, { data }) => {
            return (
              <Fragment>
                {this.state.showToast === true ? (
                  <ToastNotification
                    notificationType={this.state.toastType}
                    showToast={this.state.showToast}
                    onClose={this.handleClose}
                    toastMessage={this.state.toastMessage}
                  />
                ) : null}
                <Hero heroTitle='Add a New School' />
                <div className='container__body'>
                  <div className='container__create-school'>
                    <div className={classes.root}>
                      {_.includes(['appadmin'], role) ? (
                        <Tabs
                          value={value}
                          onChange={this.handleChange}
                          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                          variant='fullWidth'
                        >
                          <Tab
                            disableRipple
                            value='one'
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label='School Name'
                            onClick={
                              this.state === 'two' || 'three'
                                ? () => {
                                  this.setState({ value: 'one' })
                                }
                                : null
                            }
                          />
                          <Tab
                            disableRipple
                            value='two'
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label='School Address'
                            onClick={
                              this.state.name
                                ? () => {
                                  this.setState({ value: 'two' })
                                }
                                : null
                            }
                          />
                          <Tab
                            disableRipple
                            value='three'
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label='Additional Details'
                            onClick={
                              this.enableStepThree()
                                ? () => {
                                  this.setState({ value: 'three' })
                                }
                                : null
                            }
                          />
                        </Tabs>
                      ) : null}

                      {value === 'one' && (
                        <TabContainer>

                          <div className='create-school__tab-header'>
                            <Typography variant="h2" className='strong'>Enter the school name.</Typography>
                            <br />
                            <Typography
                              variant="paragraph"
                              sx={{
                                mb: "1rem",
                              }}
                              component="p"
                            >
                              Please use consistent names and full words
                              <br />
                              (e.g., "High School" instead of "H.S.").
                            </Typography>
                          </div>
                          <div className='create-school__tab-form'>
                            <form onSubmit={e => e.preventDefault()}>
                              <FormField
                                formFieldVariant='filled'
                                formFieldId='school-name'
                                formFieldLabel='School Name'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldType='text'
                                formFieldName='schoolName'
                                formFieldDefaultValue={this.state.schoolName}
                                onChange={handleFormChange}
                                formFieldError={this.state.errors.schoolName || null}

                              />
                              <br />
                              <br />
                              <Button
                                variant='outlined'
                                type='submit'
                                title='Continue'
                                disabled={this.state.disabled}
                                onClick={
                                  this.state.schoolName && this.state.schoolName.trim()
                                    ? () => {
                                      this.setState({ value: 'two' })
                                      this.setState({ disabled: true })
                                    }
                                    : null
                                }
                              />
                            </form>
                          </div>
                        </TabContainer>
                      )}
                      {value === 'two' && (
                        <TabContainer>
                          <div className='create-school__tab-header'>
                            <Typography variant="h2" className='strong'>Enter the school address.</Typography>
                            <br />
                            <Typography
                              variant="paragraph"
                              sx={{
                                mb: "1rem",
                              }}
                              component="p"
                            >
                              This is the address that you will mail curriculum materials to.
                            </Typography>
                          </div>
                          <div className='create-school__tab-form'>
                            <form>
                              <FormField
                                formFieldId='address-1'
                                formFieldVariant='filled'
                                formFieldLabel='Address Line 1'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldType='text'
                                formFieldName='address1'
                                formFieldDefaultValue={this.state.address1}
                                formFieldAutoComplete='address-line1'
                                onChange={handleFormChange}
                              />
                              <br />
                              <FormField
                                formFieldId='address-2'
                                formFieldVariant='filled'
                                formFieldLabel='Address Line 2 (Optional)'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldType='text'
                                formFieldName='address2'
                                formFieldDefaultValue={this.state.address2}
                                formFieldAutoComplete='address-line2'
                                onChange={handleFormChange}
                              />
                              <br />
                              <FormField
                                formFieldId='city'
                                formFieldVariant='filled'
                                formFieldLabel='City'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldType='text'
                                formFieldName='city'
                                formFieldDefaultValue={this.state.city}
                                formFieldAutoComplete='address-level2'
                                onChange={handleFormChange}
                              />
                              <br />
                              <FormSelect
                                label="State"
                                name="state"
                                variant="filled"
                                value={this.state.state ? this.state.state : ''}
                                defaultValue={this.state.state ? this.state.state : ''}
                                onChange={e => { this.setState({ state: e.target.value }) }}
                                inputProps={{ 'aria-label': 'Without label' }}
                                options={statesList}
                                fullWidth={true}
                              />
                              <br />
                              <FormField
                                formFieldId='zip-code'
                                formFieldVariant='filled'
                                formFieldLabel='Zip Code'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldType='number'
                                formFieldName='postCode'
                                formFieldDefaultValue={this.state.postCode}
                                formFieldError={this.state.errors.postCode}
                                formFieldAutoComplete='postal-code'
                                onChange={handleFormChange}
                              />
                              <br />
                              <br />
                              <Button
                                variant='outlined'
                                type='submit'
                                title='Continue'
                                disabled={this.state.disabled}
                                onClick={
                                  this.enableStepThree()
                                    ? () => {
                                      this.setState({ value: 'three' })
                                    }
                                    : null
                                }
                              />
                            </form>
                          </div>
                        </TabContainer>
                      )}
                      {value === 'three' && (
                        <TabContainer>
                          <div className='create-school__tab-header'>
                            <Typography variant="h2" className='strong'>Enter additional school details.</Typography>
                            <br />
                            <Typography
                              variant="paragraph"
                              sx={{
                                mb: "1rem",
                              }}
                              component="p"
                            >
                              Users connected to the school will see this information.
                            </Typography>
                          </div>
                          <div className='create-school__tab-form'>
                            <form>
                              <FormField
                                formFieldVariant='filled'
                                formFieldId='custom-css-outlined-school-website'
                                formFieldLabel='School Website (Optional)'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldName='website'
                                formFieldDefaultValue={this.state.website}
                                formFieldType='text'
                                formFieldError={this.state.errors.website}
                                onChange={handleFormChange}
                              />
                              <br />
                              <FormField
                                formFieldVariant='filled'
                                formFieldId='custom-css-outlined-school-demographics'
                                formFieldLabel='School Demographics (Optional)'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldName='demographicLink'
                                formFieldError={this.state.errors.demographicLink}
                                formFieldDefaultValue={this.state.demographicLink}
                                formFieldType='text'
                                onChange={handleFormChange}
                              />
                              <Typography variant="h5">
                                Enter a link to the school's directory information from{' '}
                                <a
                                  href='https://nces.ed.gov/ccd/schoolsearch/index.asp'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  www.nces.ed.gov
                                </a>{' '}
                                or similar site.
                              </Typography>

                              <br />
                              <FormSelect
                                name="schoolType"
                                variant="filled"
                                value={this.state.schoolType ? this.state.schoolType : null}
                                defaultValue={this.state.schoolType ? this.state.schoolType : null}
                                onChange={e => this.setState({ schoolType: e.target.value })}
                                inputProps={{ 'aria-label': 'Without label' }}
                                options={schoolTypes}
                                label='School Type'
                                placeholder='School Type'
                                fullWidth={true}
                              />
                              <br />
                              <FormField
                                formFieldVariant='filled'
                                formFieldId='custom-css-outlined-school-district'
                                formFieldLabel='School District (Optional)'
                                formFieldDefaultValue={this.state.schoolDistrict}
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldName='schoolDistrict'
                                formFieldType='text'
                                onChange={handleFormChange}
                              />
                              <Typography variant="h5"
                                sx={{ color: "inherit" }}
                              >
                                If this is a public school, please enter the full name of the
                                district.
                              </Typography>
                              <br />
                              <br />
                              <Button
                                type='submit'
                                title={this.state.submitted ? <span className='icon-text-span'>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  /> Creating... </span> : 'Create School'}
                                onClick={
                                  this.state.schoolType ? e => this.handleSubmit(e, onMutate) : null
                                }
                                disabled={this.state.submitted}
                              />
                            </form>
                          </div>
                        </TabContainer>
                      )}
                    </div>
                  </div>
                  <div className='create-school__cancel'>
                    <Link to='/schools'>Cancel</Link>
                  </div>
                </div>
              </Fragment>
            )
          }}
        </Mutation>
      </Layout>
    )
  }
}

CreateSchool.propTypes = {
  classes: PropTypes.object.isRequired
}

export default inject('userStore', 'breadcrumbStore')((withRouter(withStyles(styles)(observer(CreateSchool)))))
