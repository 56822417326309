import React, { Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import Footer from '../organisms/Footer'
import ScrollToTop from '../elements/ScrollToTop'
import PagesMetaTags from '../../utils/PagesMetaTags'
import HeaderMenu from './HeaderMenu'

import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../settings/theme';
import PublicMenu from './PublicMenu'
import { withRouter } from '../../utils/customHoc'

function Layout(props) {
  if (props.userStore && props.userStore.isAuthenticated) {
    return (
      <Fragment>
        <ScrollToTop>
          <HeaderMenu/>
          <PagesMetaTags />
          <ThemeProvider theme={theme}>
            <Box className='container__main'>{props.children}</Box>
            </ThemeProvider>
        </ScrollToTop>
      </Fragment>
    )
  } else {
    return (
      <>
        <PagesMetaTags />
        <ThemeProvider theme={theme}>
          <ScrollToTop>
            <Box  >
              <PublicMenu />
              <Box className='container__main' sx={{backgroundColor: '#fff'}}>{props.children}</Box>
              <Footer />
            </Box>
          </ScrollToTop>
        </ThemeProvider>
      </>
    )
  }
}

export default inject('uiStore', 'userStore', 'metadataStore')(observer(withRouter(Layout)))
