import React, { Component, Fragment } from 'react'
import './signup.scss'
import PropTypes from 'prop-types'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { Grid, Typography } from '@mui/material'

// import { withRouter } from 'react-router-dom'
import SignUpSection from '../ui/organisms/SignUpForm'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'
import { withRouter } from '../utils/customHoc'

const QUERY = gql`
  query UserInvite($uuid: String!) {
    userInvite(uuid: $uuid) {
      id
      uuid
      firstName
      lastName
      email
      role
      school {
        mailingAddress {
          state
          id
        }
        id
        name
        mailingAddress {
          address1
          address2
          city
          state
          postCode
        }
      }
    }
  }
`

class SignUpFlow extends Component {
  render() {
    const {params} = this.props
    const {uuid} = params
    return (
      <Layout>
      <Query query={QUERY} variables={{ uuid: uuid }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) {
            return <Typography variant="paragraph" component="p">Error loading classes</Typography>
          }
          if (data) {
            return (
              <Fragment>
                <div className='container__public-body'>
                  <Grid
                    container
                    className='public-body__sign-up-flow'
                    sx={{
                      pt: {xs: 12, sm: 18, md: 23, lg: 28},
                      pb: {xs: 5, sm: 10, md: 15, lg: 20},
                      px: {xs: 1.5, sm: 0}
                    }}
                  >
                    <Grid item md={1} sm={1} xs={0} />
                    <Grid item md={10} sm={10} xs={12}>
                      <SignUpSection {...this.props} userInviteData={data.userInvite} />
                    </Grid>
                    <Grid item md={1} sm={1} xs={0} />
                  </Grid>
                </div>
              </Fragment>
            )
          }
        }}
      </Query>
      </Layout>
    )
  }
}

SignUpFlow.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withRouter(SignUpFlow)
