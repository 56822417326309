import React, { Fragment, useState } from 'react'
import variables from '../ui/settings/_variables.scss'
import { observer, inject } from 'mobx-react'
import './photodetail.scss'
import { gql, useQuery } from '@apollo/client'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'
import { PHOTO_ATTRIBUTES } from '../graphql/photoAttributes'
import { checkUserAccessToPhoto } from '../utils/commonFunctions'
import ErrorPage from './ErrorPage'
import { withRouter } from '../utils/customHoc'
import { Box, Container } from '@mui/material'
import ModalHeader from '../ui/organisms/PhotoDetailsModal/ModalHeader'
import ToastNotification from '../ui/elements/ToastNotification'
import ImageSection from '../ui/organisms/PhotoDetailsModal/ImageSection'
import PhotoModalFooter from '../ui/organisms/PhotoDetailsModal/PhotoModalFooter'
import PhotoTitleCaptionSection from '../ui/organisms/PhotoDetailsModal/PhotoTitleCaptionSection'
import AwardSection from '../ui/organisms/PhotoDetailsModal/AwardSection'
import CommentSection from '../ui/organisms/PhotoDetailsModal/CommentSection'
import UseAgreementSection from '../ui/organisms/PhotoDetailsModal/UseAgreementSection'
import ModalReleaseSection from '../ui/organisms/PhotoDetailsModal/ModalReleaseSection'
import PhotoAltTextSection from '../ui/organisms/PhotoDetailsModal/PhotoAltTextSection'
import PhotoPublishSection from '../ui/organisms/PhotoDetailsModal/PhotoPublishSection'
import FileOperationSection from '../ui/organisms/PhotoDetailsModal/FileOperationSection'
import AdminSubmittedDateOverride from '../ui/organisms/PhotoDetailsModal/AdminSubmittedDateOverride'
import PhotoMetaDetails from '../ui/organisms/PhotoDetailsModal/PhotoMetaDetails'
import '../ui/molecules/modals.scss'

const PHOTO_DETAIL_PAGE_QUERY = gql`
  query PhotoDetails($id: ID!) {
    photo(id: $id) {
      ...photoAttributes
    }
  }
  ${PHOTO_ATTRIBUTES}
`


const PhotoDetailPage = props => {
  const { userStore,  params } = props
  const {role, profileId, isAuthenticated} = userStore
  const publicView = !isAuthenticated ? true : false

  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [projectSubmitted, setProjectSubmitted] = useState(false);
  const [isDetailsBeignEdited, setIsDetailsBeignEdited] = useState(false);
  const [formIsValid, setFormIsValid] = useState()


  const setToastInfo = (notificationType, message, color) => {
    setShowToast(true);
    setToastMessage(message);
    setToastType(notificationType)
    // setToastColor(color);
    // setSmallText(true);
    setTimeout(() => setShowToast(false), 5000);

  };


  const {loading, error ,data, refetch} = useQuery(PHOTO_DETAIL_PAGE_QUERY, {
    variables: {
      id: params.photoId
    },
    onCompleted(data){
      setProjectSubmitted(data.photo.project.submitted);
    }
  })

  if(loading) {
    return (
      <Layout>
        <Loading/>
      </Layout>
    )
  }

  const { photo } = data

  const curatorReviews = photo.project.cohort.curatorReviews
  const awardChipDisabled =
    ['curator'].includes(role) &&
    (photo.project.cohort.reviewed ||
      curatorReviews.filter(review => review.reviewerId === profileId).length > 0)
  const hasPageAccess = checkUserAccessToPhoto(photo, userStore)
  return <Layout>
    {(!publicView && hasPageAccess) || ( publicView && photo.published ) ? (
      <Container maxWidth={false} disableGutters sx={{backgroundColor: `${variables.white}`}}>
          {/* Sticky page header for photo deatils page */}
          <ModalHeader
            isModal={false}
            refetch={refetch}
            photoDetails={photo}
            awardChipDisabled={awardChipDisabled}
            role={role}
            publicView={publicView}
            className="container__sticky-header modal-header"
            toast= {showToast === true ? (
                <ToastNotification
                  notificationType={toastType}
                  showToast={showToast}
                  onClose={() => setShowToast(false)}
                  toastMessage={toastMessage}
                />
              ) : null
            }
          />



          {/* Photo Details body */}
          <Box className=''>
            {/* Photo details image section*/}

              <ImageSection
                photoDetails={photo}
                placeholderDark={true}
                photoImageType="Image Type "
                photoDimensions=" Dimensions "
                photoFileSize=" File Size"
                isModal={false}
                key={photo && photo.id}
                // getAdjacentImage={getNextPrevImage}
                publicView={publicView}
                refetch={refetch}
                enableNext={false}
                enablePrev={false}
                loading={loading}
              />
          </Box>

          {photo ?
            <Container>
              <Box
                sx={{
                  maxWidth: "48rem",
                  margin: "0 auto",
                }}
              >
                <Box>
                  {!publicView ? (
                    <PhotoTitleCaptionSection
                      photoId={photo && photo.id}
                      photoDetails={photo}
                      role={role}
                      projectSubmitted={projectSubmitted}
                      publicView={publicView}
                      refetch={refetch}
                      loading={loading}
                      showToast={setToastInfo}
                      setIsDetailsBeignEdited={setIsDetailsBeignEdited}
                    />
                  ) : (
                    <div className="photo-caption">
                      <blockquote className="blockquote--editable">
                        {photo && photo.caption}
                      </blockquote>
                    </div>
                  )}
                </Box>
                {!publicView && ["appadmin", "curator"].includes(role) ? (
                  <Box>
                    <AwardSection
                      photo={photo}
                      isModal={false}
                      publicView={publicView}
                      role={role}
                      refetchPhoto={refetch}
                    />
                  </Box>
                ) : null}
                {/* Photos comments section */}
                {!publicView &&
                photo &&
                photo.submitted &&
                ["appadmin", "curator"].includes(role) ? (
                  <Box>
                    <CommentSection
                      photoId={photo && photo.id}
                      comments={photo && photo.photoComments}
                      userStore={userStore}
                      id={userStore.id}
                      refetchphoto={refetch}
                      // toggleEditing={this.toggleEditing.bind(this)}
                      role={role}
                      profileId={profileId}
                      publicView={publicView}
                      photo={photo}
                    />
                  </Box>
                ) : null}

                {!publicView &&
                photo &&
                photo.submitted &&
                photo.project.cohort.reviewed &&
                photo.summarySent &&
                photo.awards.length > 0 &&
                ["appadmin", "student", "teacher", "facilitator"].includes(role) ? (
                  <Box>
                    <UseAgreementSection
                      photoId={photo && photo.id}
                      refetchPhoto={refetch}
                      photoDetails={photo}
                      project={photo && photo.project}
                      role={role}
                      student={
                        photo &&
                        photo.project &&
                        photo.project.student
                      }
                      toggleEditing={setIsDetailsBeignEdited}
                      showEdit={isDetailsBeignEdited}
                      // isShowingEdited={this.state.isShowingEdited}
                    />
                  </Box>
                ) : null}
                {!publicView &&
                photo &&
                photo.submitted &&
                photo.project.cohort.reviewed &&
                ((photo.awards.length > 0 && role === "appadmin") ||
                  (photo.summarySent &&
                    photo.hasPeople !== null &&
                    ["student", "teacher", "facilitator"].includes(role))) ? (
                  <Box>
                    <ModalReleaseSection
                      photoId={photo && photo.id}
                      refetchPhoto={refetch}
                      photoDetails={photo}
                      project={photo && photo.project}
                      role={role}
                      student={
                        photo &&
                        photo.project &&
                        photo.project.student
                      }
                      toggleEditing={setIsDetailsBeignEdited}
                      showEdit={isDetailsBeignEdited}
                    />
                  </Box>
                ) : null}
                {!publicView &&
                role === "appadmin" &&
                photo &&
                photo.submitted &&
                photo.awards.length > 0 ? (
                  <Box>
                    <PhotoAltTextSection
                      photoId={photo && photo.id}
                      photoDetails={photo}
                      refetchphoto={refetch}
                      showToast={setToastInfo}
                      role={role}
                      projectSubmitted={projectSubmitted}
                    />
                  </Box>
                ) : null}
                {!publicView ? (
                  <>
                    <Box>
                      <PhotoPublishSection
                        photoId={photo && photo.id}
                        photoDetails={photo}
                        refetchphoto={refetch}
                        showToast={setToastInfo}
                        role={role}
                        formIsValid={formIsValid}
                      />
                    </Box>
                    <Box>
                      <FileOperationSection
                        role={role}
                        photo={photo}
                        refetchPhoto={refetch}
                        userStore={userStore}
                      />
                    </Box>
                  </>
                ) : null}
                {!publicView && role === "appadmin" ? (
                  <Box>
                    <AdminSubmittedDateOverride
                      photoId={photo &&photo.id}
                      photoDetails={photo}
                      refetchPhoto={refetch}
                      role={role}
                      userStore={userStore}
                      // toggleEditing={this.toggleEditing.bind(this)}
                      showToast={setToastInfo}
                      setFormIsValid={setFormIsValid}
                    />
                  </Box>
                ) : null}
                {!publicView ? (
                  <Box>
                    <PhotoMetaDetails
                      photoId={photo && photo.id}
                      // toggleParentModalState={props.toggleParentModalState}
                      photo={photo}
                      isStudent={false}
                      role={role}
                    />
                  </Box>
                ) : null}
              </Box>
            </Container>
          :null}

          {
            photo && !publicView ? (
                <Box sx={{
                  minWidth: "100%",
                }}>
                  <PhotoModalFooter
                    role={role}
                    photoId={photo && photo.id}
                    photo={photo}
                  />
                </Box>
            ) : null
          }
      </Container>) :
      <ErrorPage errorCode='404' withInPage={publicView ? false : true}/>
    }
  </Layout>
}

export default inject('userStore')(observer(withRouter(PhotoDetailPage)))
