import React, { Component, Fragment } from 'react'

import _ from 'lodash'
import pluralize from 'pluralize'
import { Link } from 'react-router-dom'
import { Grid, Box, Typography } from '@mui/material'

class CuratorStudentListRow extends Component {
  constructor(props) {
    super(props)
    const { projects, student } = props
    const project = projects.filter(project => {
      return project.student.id === student.id
    })
    const shortListedPhotos =
      (project && project[0] && project[0].photos.filter(photo => photo.isShortListed)) || []
    this.state = {
      shortListedPhotos: shortListedPhotos,
      shortListedPhotosCount: shortListedPhotos.length,
      honorableMentionsCount:
        shortListedPhotos &&
        shortListedPhotos.filter(
          photo =>
            photo.awards.filter(awards => awards.awardClass === 'Honorable Mention').length > 0
        ).length,
      awardsOfExcellenceCount:
        shortListedPhotos &&
        shortListedPhotos.filter(
          photo =>
            photo.awards.filter(awards => awards.awardClass === 'Award of Excellence').length > 0
        ).length
    }
  }

  render() {
    const { projects, student } = this.props
    const project = projects.filter(project => {
      return project.student.id === student.id
    })

    const getPhotographs = () => {
      return (this.state.shortListedPhotosCount > 0 ? (
        <Link to={'/project-detail/' + this.state.shortListedPhotos[0].project.id}>
          <Typography variant='link' component={'p'} className='large'>
            {pluralize('Photograph', this.state.shortListedPhotosCount, true)}
          </Typography>
        </Link>
      ) : (
        <Typography variant='paragraph' component={'p'}>
          No Photographs Submitted
        </Typography>
      ))

    }
    return (
      <Box
        className='body__section--list'
        sx={{ px: { xs: 4 }, py: { xs: 3, md: 4 }, mt: 0, mb: { xs: 0.24, md: 1 } }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={3} >
            <Typography variant='dataLabel' component={'p'} className='content'>
              {student.name}
            </Typography>
            <Box display={{ xs: 'none', sm: 'block', md: 'none' }} mt={1}>{getPhotographs()}</Box>
          </Grid>

          <Grid item xs={12} display={{ sm: 'none', md: 'block' }} md={3}>
            {getPhotographs()}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {this.state.shortListedPhotosCount > 0 ? (
              <Typography variant='paragraph' component={'div'}>
                {this.state.awardsOfExcellenceCount > 0
                  ? this.state.awardsOfExcellenceCount.toString().concat(' Photographs')
                  : 'None Awarded'}
                <Typography variant='paragraph' component={'span'} display={{ sm: 'none' }}>
                  {' '}
                  (AoE){' '}
                </Typography>
              </Typography>
            ) : (
              <Typography variant='paragraph' component={'div'}>
                Not Applicable
                <Typography variant='paragraph' component={'span'} display={{ sm: 'none' }}>
                  {' '}
                  (AoE){' '}
                </Typography>
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {this.state.shortListedPhotosCount > 0 ? (
              <Typography variant='paragraph' component={'div'}>
                {this.state.honorableMentionsCount > 0
                  ? this.state.honorableMentionsCount.toString().concat(' Photographs')
                  : 'None Awarded'}
                <Typography variant='paragraph' component={'span'} display={{ sm: 'none' }}>
                  {' '}
                  (HM){' '}
                </Typography>
              </Typography>
            ) : (
              <Typography variant='paragraph' component={'div'}>
                Not Applicable
                <Typography variant='paragraph' component={'span'} display={{ sm: 'none' }}>
                  {' '}
                  (HM){' '}
                </Typography>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default CuratorStudentListRow
