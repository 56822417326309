import { useMutation } from '@apollo/client'
import { Typography, Box } from '@mui/material'
import React, { useRef, useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import Button from '../../../elements/Button'
import FormFieldMultiline from '../../../elements/FormFieldMultiline'
import { UPDATE_META_DATA_MUTATION } from '../../../../queries/PhotoDetailsQueries'

const PhotoAltTextSection = (props) => {
  const { photoId, photoDetails, role, projectSubmitted, showToast, refetch } = props

  const isEditable =
    !window.location.pathname.includes('national') &&
    ((['student'].includes(role) && !projectSubmitted) || role === 'appadmin')

  const altTextInputRef = useRef()

  const [photo, setPhoto] = useState({
    altText: photoDetails?.altText || '',
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [detailsUpdated, setDetailsUpdated] = useState(false) // Initialize as false

  const [altTextMutation, { loading }] = useMutation(UPDATE_META_DATA_MUTATION, {
    onCompleted() {
      setIsSubmitting(false)
      setDetailsUpdated(false) // Reset to false after successful update
      refetch()
      showToast('success', 'All changes saved.')
    },
    onError(error) {
      console.log(error, 'error')
    },
  })

  const handleSaveAltText = () => {
    setIsSubmitting(true)
    altTextMutation({
      variables: {
        id: photoId,
        altText: altTextInputRef.current.value,
      },
    })
  }

  const handleCancelEdit = () => {
    setPhoto({
      altText: photoDetails?.altText || ''
    })
    setDetailsUpdated(false) // Reset to false when canceling
  }

  useEffect(() => {
    setPhoto({
      altText: photoDetails?.altText || '',
    })
    setDetailsUpdated(false) // Reset to false when photoDetails change
  }, [photoDetails])

  const handlePhotoAttributeChange = (event) => {
    const { value } = event.target
    setPhoto((prevPhoto) => ({
      ...prevPhoto,
      altText: value,
    }))
    setDetailsUpdated(value !== photoDetails?.altText) // Update detailsUpdated when value changes
  }

  return (
    <Box className='award-nominator__publish-photograph' sx={{ pt: { xs: 4 }, pb: { xs: 8, lg: 10 } }}>
      <Typography variant='h2' className='strong'>
        Alt Text
      </Typography>
      <Typography variant='paragraph' component='p' sx={{mt: {xs: 3}}}>
        Briefly and accurately describe the photograph for visually impaired audiences.
      </Typography>
      <Box component={'div'} sx={{mt: {xs: 2}}}>
        {isEditable ? (
          <FormFieldMultiline
            formFieldId='enter-alt-text'
            formFieldPlaceholder='Alt Text'
            formFieldVariant='filled'
            formFieldName='altText'
            formFieldDefaultValue={photo.altText}
            fullWidth={true}
            multiline={true}
            inputRef={altTextInputRef}
            rows='3'
            onChange={handlePhotoAttributeChange}
          />
        ) : (
          `${photo.altText}`
        )}
      </Box>
      {isEditable ? (
        <Box
          component='div'
          sx= {{
            display: {xs: 'grid', sm: 'flex'},
            textAlign: 'center',
            gap: {sm: 3},
            mt: {xs: 3},
            alignItems: {sm: 'baseline'}
          }}
        >
          <Button
            title={isSubmitting ? (
              <>
                <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' /> Saving...
              </>
            ) : (
              'Save Alt Text'
            )}
            onClick={handleSaveAltText}
            disabled={!detailsUpdated || isSubmitting || loading}
          />

          {detailsUpdated && (
            <Typography
              variant='link'
              sx={{
                mt: { xs: 2, sm: 0 },
              }}
              onClick={handleCancelEdit}
            >
              Cancel
            </Typography>
          )}
        </Box>
      ) : null}
    </Box>
  )
}

export default PhotoAltTextSection
