import { useMutation } from '@apollo/client'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { orderEntities } from '../../../../utils/commonFunctions'
import Button from '../../../elements/Button'
import FormFieldMultiline from '../../../elements/FormFieldMultiline'
import DeleteCommentModal from '../../../molecules/DeleteCommentModal'
import { ADD_COMMENT_MUTATION } from '../../../../queries/PhotoDetailsQueries'
import {Typography} from '@mui/material'

const CommentSection = props => {
  const { refetchphoto, comments, role, userStore, photo, publicView, id, photoId } = props
  const [showComments, setShowComments] = useState(false)
  const [commentsArr, setCommentsArr] = useState(comments)
  const [showDeletComment, setShowDeletComment] = useState(false)
  const [deleteComment, setDeleteComment] = useState(null)
  const [newComment, setNewComment] = useState('')
  const commentRef = useRef()
  const handleAccordionShow = panel => (event, isExpanded) => {
    setShowComments(isExpanded ? panel : false)
  }

  const [addNewCommentMutation, { loading, data, error }] = useMutation(ADD_COMMENT_MUTATION, {
    onCompleted(data) {
      setNewComment('')
      if (refetchphoto) {
        refetchphoto()
      }
    }
  })

  const removeComment = commentId => {
    const comments = commentsArr.filter(comm => comm.id !== commentId)
    setCommentsArr([...orderEntities(comments)])
    setDeleteComment(null)
    if (refetchphoto) {
      refetchphoto()
    }
  }

  useEffect(() => {
    setCommentsArr(props.comments)
  }, [props.comments])

  return (
    <Box sx={{
      borderTop: '1px solid #d5d5d7',
      p: {xs: '2rem 0'},
    }}>
      <Accordion
        expanded={showComments === 'panel1'}
        onChange={handleAccordionShow('panel1')}
        disableGutters
        sx={{
          boxShadow: 'none',
          '.MuiAccordionSummary-content': {
            m: 0
          },
          '.MuiAccordionSummary-root': {
            minHeight: 'fit-content'
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{alignItems: 'baseline', p: 0}}
        >
          <Typography variant='h2' className='strong'>
            Comments ({(commentsArr && commentsArr.length) || '0'})
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{p: 0}}>
          {commentsArr &&
            commentsArr.map(comment => (
              <Box className='curator-comments__display' key={comment.id}>
                <Box className='curator-comments__display--heading'>
                  <Typography variant='status'>{comment.commenterName}</Typography>{' '}
                  <Typography variant='h4' className='small_size curator-comments__display--heading---date'>
                    {moment(comment.createdAt).format('MMMM D, YYYY')}
                  </Typography>
                </Box>
                <Typography variant='paragraph' component='p' sx={{mt: {xs: 1}}}>{comment.body}</Typography>
                {role === 'appadmin' ||
                (role === 'curator' && id === comment.commenterUserId) ? (
                  <Box component='div' sx={{mt: {xs: 1}}}>
                    <Typography
                      variant='link'
                      className='curator-comments__display--delete'
                      onClick={e => {
                        e.preventDefault()
                        setDeleteComment(comment.id)
                        setShowDeletComment(true)
                      }}
                    >
                      Delete Comment
                    </Typography>
                    <DeleteCommentModal
                      show={showDeletComment}
                      onHide={() => setShowDeletComment(false)}
                      commentId={deleteComment}
                      refetchphoto={refetchphoto}
                      removeComment={removeComment}
                      comments={commentsArr}
                    />
                  </Box>
                ) : null}
              </Box>
            ))}
          <Box component='div' sx={{mt: {xs: 4}, mb: {xs: 4, md: 3}}}>
            <FormFieldMultiline
              formFieldId='enter-comment'
              formFieldPlaceholder='Add a Comment'
              formFieldVariant='filled'
              // formFieldClasses={{
              //   root: 'text-field__outline'
              // }}
              formFieldName='body'
              formFieldDefaultValue={newComment}
              fullWidth={true}
              multiline={true}
              inputRef={commentRef}
              rows='3'
              onChange={e => setNewComment(e.target.value)}
            />
          </Box>
          <Box
            component='div'
            sx= {{
              display: {xs: 'grid', sm: 'flex'},
              textAlign: 'center',
              gap: {sm: 2, md: 3},
              mb: {sm: 1},
              alignItems: {sm: 'baseline'}
            }}
          >
            <Button
              title='Post Comment'
              disabled={!newComment}
              onClick={() =>
                addNewCommentMutation({
                  variables: {
                    photoId: photoId,
                    body: newComment
                  }
                })
              }
            />
            {
              newComment
              ? (<Typography
                  variant='link'
                  sx= {{
                    mt: {xs: 2, sm: 0}
                  }}
                  onClick={() => setNewComment('')}
                >
                  Cancel
                </Typography>)
              : null
            }
          </Box>
          <Typography variant="note" component={'p'} sx={{mt: {xs: 1}, mb: {xs: 4, md: 6}}}>
            Comments are seen only by curators and <i>wrkxfmly</i> administrators on this
            page.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default inject('userStore', 'photoCollectionStore')(observer(CommentSection))