import React, { useState } from 'react'
import { gql } from '@apollo/client';
import './modals.scss'
import Button from '../elements/Button.js'
import { Typography, Box, Grid } from '@mui/material'
import { Spinner } from 'react-bootstrap';
import { Mutation } from '@apollo/client/react/components';
import BaseModal from '../template/BaseModal';

const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id) {
      id
    }
  }
`;

export const DeleteCommentModal = (props) => {
  const [ isSubmitting, setIsSubmitting ] = useState(false)

  const handleClose = () => {
    this.setState({show: false});
    this.props.onHide(false);

  };
  return(
    <Mutation
      mutation={DELETE_COMMENT_MUTATION}
      onCompleted={()=>{
        props.onHide()
        props.removeComment(props.commentId)
        setIsSubmitting(false)
      }}
    >
      {(manageOnMutate, { data }) => (
        <BaseModal
          {...props}
          show={props.show}
          onClick={props.onClick}
          value={props.value}
          open={props.show}
          onClose={handleClose}
          onHide={props.onHide}
        >
          <BaseModal.BaseModalHeader>
            Delete Comment
          </BaseModal.BaseModalHeader>
          <BaseModal.BaseModalBody>
            <Box className='delete-event__description' sx={{pb:'0px !important'}}>
              <Typography variant="paragraph" component="p">
                Be sure you want to delete this comment.
              </Typography>
              <br/>
              <Typography variant="paragraph" component="p">
                Reminder: if you delete this comment, users will no longer see it in the web app.
              </Typography>
            </Box>
            <BaseModal.BaseModalFooter>
              <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='delete-event__button'>
                <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button
                      variant='contained'
                      type='copy'
                      title={
                        isSubmitting ? <span className='icon-text-span'>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> Deleting... </span> : 'Delete Comment'
                      }
                      disabled={isSubmitting}
                      onClick={() =>{
                        setIsSubmitting(true)
                        handleDelete(manageOnMutate, props.commentId)
                      }}
                      sx={{width:{xs: '100%', sm: '100%'}}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                    <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4" className='small_size_semi_bold link' onClick={() => props.onHide()}>
                      Cancel
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </BaseModal.BaseModalFooter>
          </BaseModal.BaseModalBody>
        </BaseModal>
      )}</Mutation>
  )
}

const handleDelete = (onMutate, commentId) => {
  onMutate({
    variables: {
      id: commentId
    }
  })
}

export default DeleteCommentModal