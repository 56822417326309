import React, { Component } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import FormFieldMultiline from '../elements/FormFieldMultiline.js'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Spinner } from 'react-bootstrap'
import { Typography, Box, Grid } from '@mui/material'
import BaseModal from '../template/BaseModal';

const MUTATION = gql`
  mutation SendUserDirectMessage($rcptUserIds: [ID!]!, $messageBody: String!) {
    sendUserDirectMessage(rcptUserIds: $rcptUserIds, messageBody: $messageBody) {
      id
    }
  }
`

const CLASS_LIST_MUTATION = gql`
  mutation SendStudentReminders($studentIds: [ID!]!, $message: String!) {
    sendStudentReminders(studentIds: $studentIds, message: $message) {
      id
    }
  }
`



class SendMessageModal extends Component {
  constructor(props) {
    super(props)
    this.state = { message: '', isSending: false }
    this.handleFormChange = this.handleFormChange.bind(this)
  }
  handleSubmit(e, onMutate) {
    e.preventDefault()
    this.setState({isSending: true})
    let variables;
    if(this.props.fromClassList) {
      variables = {
        studentIds: this.props.userIds,
        message: this.state.message
      }
    } else {
      variables = {
        rcptUserIds: this.props.userIds,
        messageBody: this.state.message
      }
    }
    onMutate({
      variables: {...variables},
    })
  }
  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmitSuccess = () => {
    this.setState({isSending: false})
    this.props.onHide()
  }
  render() {
    const {
      handleSubmitSuccess
    } = this

    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);

    };
    return (
      <Mutation
        mutation={this.props.fromClassList ? CLASS_LIST_MUTATION : MUTATION}
        onCompleted={()=>{
          handleSubmitSuccess()
        }}
      >
        {(onMutate, { data }) => {
          return (
            <BaseModal
              {...this.props}
              show={this.props.show}
              onClick={this.props.onClick}
              value={this.props.value}
              open={this.props.show}
              onClose={handleClose}
              onHide={this.props.onHide}              
            >
              <BaseModal.BaseModalHeader>
                Send a Message
              </BaseModal.BaseModalHeader>
              <BaseModal.BaseModalBody>
                <Box className='message-body'>
                  <p className='send_m_body'>
                    Please write a message to send to all selected users. A copy of the message will
                    be sent to a <i>wrkxfmly</i> administrator.
                  </p>
                </Box>
                <Box className='form-field'>
                  <FormFieldMultiline
                    formFieldId='message'
                    formFieldVariant='filled'
                    formFieldLabel='Message'
                    formFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    formFieldType='text'
                    formFieldName='message'
                    formFieldMargin='auto'
                    onChange={this.handleFormChange}
                    multiline='true'
                    sx={{
                      '.MuiInputBase-root': {
                        pt: 3
                      }
                    }}
                  />
                </Box>
                <BaseModal.BaseModalFooter>
                  <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
                    <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Button
                          variant='contained'
                          type='copy'
                          disabled={!this.state.message.trim() || this.state.isSending}
                          title= {
                            this.state.isSending ? <span className='icon-text-span'>
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            /> Sending... </span> : 'Send Message'
                          }
                          onClick={e => this.handleSubmit(e, onMutate)}
                          sx={{width:{ xs: '100%', sm: '100%' }}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                        <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={() => this.props.onHide()}>
                          Cancel
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </BaseModal.BaseModalFooter>
              </BaseModal.BaseModalBody>
            </BaseModal>
          )
        }}
      </Mutation>
    )
  }
}

export default SendMessageModal
