import React from "react";
import { Box, Typography } from '@mui/material'


const EmptyListDesign = (props) => {
  return <Box sx={{px: {xs: 4, md: 6}}}>
    <Typography variant="h3" className='semi-strong'>{props.heading}</Typography>
    <Typography variant="paragraph" component="p" sx={{mt: 2}}>{props.subheading}</Typography>
  </Box>
}

export default EmptyListDesign