import { gql } from '@apollo/client'

export const PAGINATED_COHORTS_NEDDING_REVIEW = gql`
  query PaginatedCohortsNeedingReview(
    $batchSize: Int!
    $baseBatchSize: Int!
    $step: Int!
    $search: String!
    $filter: String
    $reviewType: String!
  ) {
    inProgressReviewsConnection(
      batchSize: $batchSize
      baseBatchSize: $baseBatchSize
      step: $step
      search: $search
      filter: $filter
      reviewType: $reviewType
    ) {
      approved
      name
      school {
        mailingAddress {
          state
          id
        }
        id
        name
      }
      studentsCount
      assignment {
        id
        name
      }
      curators {
        id
        name
      }
      startDate
      startDateApproval
      endDate
      endDateApproval
      publishedAt
      published
      reviewed
      summarySent
      studentSummaryAt
      educatorSummaryAt
      id
      name
      isReviewSend
      sendForReviewOn
      curatorsDueDate
      getCohortReviewStatus
      projects {
        id
        name
        reviewed
        submittedUpdatedAt
        submitted
        photosCount
      }
      curatorReviews {
        id
        hasReviewed
        reviewerId
        createdAt
      }
    }
    inProgressReviewsCount(reviewType: $reviewType, search: $search)
  }
`

export const COHORT_COUNT_QUERY = gql`
  query CohortNeedingReviewAmount($reviewType: String!) {
    inProgressReviewsCount(reviewType: $reviewType)
  }
`

export const REOPEN_CURATORIAL_REVIEW_MUTATION = gql`
  mutation ReopenCuratorialReview($reviewIds: [ID!]!) {
    reopenCuratorialReview(reviewIds: $reviewIds)
  }
`

export const SUBMIT_REVIEW_MUTATION = gql`
  mutation SubmitCuratorialReviewsAdmin($cohortId: ID!, $curatorIds: [ID!]!) {
    submitCuratorialReviewsAdmin(cohortId: $cohortId, curatorIds: $curatorIds)
  }
`

export const UPCOMING_COHORT_REVIEW = gql`
  query UpcomingCohortsForReview($cursor: String) {
    upcomingReviewsConnection(first: 3, after: $cursor) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          approved
          name
          school {
            mailingAddress {
              state
              id
            }
            id
            name
          }
          studentsCount
          startDate
          startDateApproval
          endDate
          endDateApproval
          publishedAt
          published
          reviewed
          studentSummaryAt
          educatorSummaryAt
          id
          name
          isReviewSend
          sendForReviewOn
          curatorsDueDate
          projects {
            id
            name
            reviewed
            submittedUpdatedAt
            submitted
            photosCount
          }
        }
      }
    }
  }
`
export const SEND_CURATORS_REVIEW_MUTATION = gql`
  mutation SendCuratorsReviews($cohortId: ID!, $dueDate: String!) {
    sendCuratorsReview(cohortId: $cohortId, dueDate: $dueDate)
  }
`
export const SEND_SUMMARY_MUTATION = gql`
  mutation SendReviewSummary($cohortId: ID!, $photoIds: [ID!]!, $summaryType: String!) {
    sendReviewSummary(cohortId: $cohortId, photoIds: $photoIds, summaryType: $summaryType)
  }
`
