import { gql } from "@apollo/client";


export const USE_AGREEMENT_ATTRIBUTES = gql`
    fragment useAgreementAttributes on UseAgreement {
        id
        linkToFile
        signed
        sentTo
        signeeFirstName
        signeeLastName
        sentVia
        uaStatus
        adminDecision
        adminRejectionNote
        adminDecisionOn
        signedon
        relatedUaUuid
        wantsUpdates
        email
        phoneNumber
        createdAt
        uuid
        assetId
    }
`