import { gql } from "@apollo/client";

export const PROJECTS_LIST_QUERY = gql`
{
  currentUser {
    id
    profile {
      __typename
      ... on Student {
        id
        projects {
          id
          name
          createdAt
          updatedAt
          submitted

          photos {
            id
            url
            isShortListed
          }
          cohort {
            id
            name
            startDate
            endDate
            nextFacilitatorVisit
          }
          teacher {
            id
            name
            email
            mobileNumber
          }
          facilitator {
            id
            userId
            name
            bio
            instagramHandle
            website
            email
            mobileNumber
          }
        }
      }
    }
  }
}
`

export const PROJECT_DETAILS_QUERY = gql`
  query ProjectDetails($id: ID!) {
    project(id: $id) {
      __typename
      id
      name
      submitterId
      submitted
      submittedUpdatedAt
      cohort {
        id
        startDate
        reviewed
        endDate
        name
        school {
          id
          name
          mailingAddress {
            state
            city
            id
          }
        }
        facilitator {
          id
          userId
          name
          bio
          instagramHandle
          website
          email
          mobileNumber
        }
        teacher {
          id
          name
          email
          mobileNumber
        }
      }
      photos {
        id
        caption
        title
        originalTitle
        originalCaption
        isShortListed
        submittedDate
        altText
        attribution
        hasPeople
        submitted
        summarySent
        sentStudentSummary
        url
        cdnOriginalUrl
        originalUrl
        sizedUrl(screenWidth: 500)
        originalFileSize
        rawFileUrl
        rawFileName
        rawFileSize
        mrAdminNotes
        totalMrRequired
        adminApproved
        covidRelated
        publishable
        published
        readyToPublish
        photoComments {
          body
          id
          commenterInitials
          commenterUserId
          commenterName
          createdAt
        }
        modelReleases {
          id
          uuid
          signed
          firstName
          lastName
          createdAt
          modelFirstName
          modelLastName
          linkToFile
          sentVia
          sentTo
          type
          mrStatus
          adminDecision
          relatedToMr
          signedOn
          adminDecisionOn
          adminRejectionNote
          isMinor
          isDeceased
          signeeFirstName
          signeeLastName
        }
        useAgreements {
          id
          linkToFile
          signed
          sentTo
          signeeFirstName
          signeeLastName
          sentVia
          uaStatus
          adminDecision
          adminRejectionNote
          adminDecisionOn
          signedon
          relatedUaUuid
          wantsUpdates
          email
          phoneNumber
          createdAt
          uuid
        }
        project {
          id
          submitted
          submittedUpdatedAt
          reviewed
          student {
            id
            name
            mobileNumber
            email
          }
          cohort {
            id
            endDate
            reviewed

          }
        }
        exifData {
          size
          width
          height
          exposureProgram
          meteringMode
          iso
          lensModel
          focalLength
          cameraModel
          captureDate
          aperture
          shutterSpeedValue
          fileType
        }
        awards {
          id
          awardClass
          nominatorInitials
          nominatorUserId
          nominatorName
          nominatorType
          isEdited
          editedOn
        }
        __typename
      }
      student {
        id
        dob
        name
        mobileNumber
        email
      }
    }
  }
`

export const PAGINATED_SHORTLIST_PHOTO_QUERY = gql`
  query PaginatedProjectFinal($projectId: ID!, $isShortListed: Boolean!, $cursor: String) {
    projectPhotosConnection(
      first: 9
      projectId: $projectId
      isShortListed: $isShortListed
      after: $cursor
    ) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          updatedAt
          altText
          caption
          title
          originalTitle
          originalCaption
          isShortListed
          submittedDate
          attribution
          url
          sizedUrl(screenWidth: 500)
          summarySent
          complete
          hasPeople
          filename
          submitted
          originalFileSize
          rawFileUrl
          rawFileName
          rawFileSize
          originalUrl
          cdnOriginalUrl
          downloadUrl
          sentStudentSummary
          covidRelated
          adminApproved
          publishable
          mrAdminNotes
          totalMrRequired
          readyToPublish
          classShare
          modelReleases {
            id
            uuid
            signed
            firstName
            lastName
            createdAt
            modelFirstName
            modelLastName
            linkToFile
            sentVia
            sentTo
            type
            sentViaSms
            sentViaEmail
            pdfUploaded
            isDeceased
            phoneNumber
            email
            lastSentOn
            isMinor
            signeeFirstName
            signeeLastName
            mrStatus
            adminDecision
            relatedToMr
            signedOn
            adminDecisionOn
            adminRejectionNote
          }
          useAgreements {
            id
            linkToFile
            signed
            sentTo
            signeeFirstName
            signeeLastName
            sentVia
            uaStatus
            adminDecision
            adminRejectionNote
            adminDecisionOn
            signedon
            relatedUaUuid
            wantsUpdates
            email
            phoneNumber
            createdAt
            uuid
          }
          exifData {
            size
            width
            height
            exposureProgram
            meteringMode
            iso
            lensModel
            focalLength
            cameraModel
            captureDate
            aperture
            shutterSpeedValue
            fileType
          }
          awards {
            id
            awardClass
            nominatorInitials
            nominatorUserId
            nominatorName
            nominatorType
            isEdited
            editedOn
          }
          photoComments {
            body
            id
            commenterInitials
            commenterUserId
            commenterName
            createdAt
          }
          project {
            id
            reviewed
            submitted
            submittedUpdatedAt
            student {
              id
              name
              mobileNumber
              email
            }
            cohort {
              id
              endDate
              isReviewSend
              reviewed
              curatorReviews {
                id
                reviewerId
              }
            }
          }
        }
      }
    }
    getAllLibraryPhotos(projectId: $projectId, isShortListed: $isShortListed) {
      id
    }
    finalPhotosCount(projectId: $projectId)
  }
`


export const PAGINATED_LIBRARY_PHOTO_QUERY = gql`
  query PaginatedProjectLibrary(
    $projectId: ID!
    $batchSize: Int!
    $step: Int!
    $isShortListed: Boolean!
  ) {
    projectPhotosGalleryConnection(
      projectId: $projectId
      batchSize: $batchSize
      step: $step
      isShortListed: $isShortListed
    ) {
      id
      updatedAt
      caption
      altText
      title
      isShortListed
      submittedDate
      attribution
      url
      filename
      cdnOriginalUrl
      originalUrl
      downloadUrl
      sizedUrl(screenWidth: 500)
      summarySent
      complete
      hasPeople
      classShare
      submitted
      originalFileSize
      rawFileUrl
      rawFileName
      covidRelated
      adminApproved
      publishable
      mrAdminNotes
      totalMrRequired
      modelReleases {
        id
        uuid
        signed
        firstName
        lastName
        createdAt
        modelFirstName
        modelLastName
        linkToFile
        sentVia
        sentTo
        type
        signeeFirstName
        signeeLastName
        mrStatus
        adminDecision
        relatedToMr
        signedOn
        adminDecisionOn
        adminRejectionNote
        isMinor
        isDeceased
      }
      useAgreements {
        id
        linkToFile
        signed
        sentTo
        signeeFirstName
        signeeLastName
        sentVia
        uaStatus
        adminDecision
        adminRejectionNote
        adminDecisionOn
        signedon
        relatedUaUuid
        wantsUpdates
        email
        phoneNumber
        createdAt
        uuid
      }
      project {
        id
        submitted
        submittedUpdatedAt
        reviewed
        student {
          id
          name
          mobileNumber
          email
        }
        cohort {
          id
          endDate
          isReviewSend
          reviewed
          curatorReviews {
            id
            reviewerId
          }
        }
      }
      exifData {
        size
        width
        height
        exposureProgram
        meteringMode
        iso
        lensModel
        focalLength
        cameraModel
        captureDate
        aperture
        shutterSpeedValue
        fileType
      }
      awards {
        id
        awardClass
        nominatorInitials
        nominatorUserId
        nominatorName
        nominatorType
        isEdited
        editedOn
      }
    }
    getAllLibraryPhotos(projectId: $projectId, isShortListed: $isShortListed) {
      id
    }
    libraryPhotosCount(projectId: $projectId)
  }
`

export const LIBRARY_SIZE_QUERY = gql`
  query sizeOfLibrary($projectId: ID!) {
    libraryPhotosCount(projectId: $projectId)
  }
`