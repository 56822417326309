import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { PlusSquare } from 'react-feather'
import { getFormattedStatus, waPrivateCipher, isPublishSectionEnable } from '../../../../utils/commonFunctions'
import UseAgreementModal from '../../../molecules/UseAgreementModal'
import { GET_USE_AGREEMENTS_DATA } from '../../../../queries/PhotoDetailsQueries'
import UseAgreementListSection from './UseAgreementListSection'
import { Box, Typography } from '@mui/material'
import variable from './../../../settings/_variables.scss'

const UseAgreementSection = props => {
  const { photoId, student, photoDetails, role } = props
  const birthDay =
    student && !!moment().diff(student.dob, 'years') === false
      ? 0
      : student && moment().diff(student.dob, 'years')
  const [showUseAgreementModal, setShowUseAgreementModal] = useState(false)
  const [useAgreements, setUseAgreements] = useState([])
  const [activeUseAgreements, setActiveUseAgreements] = useState([])
  const [inactiveUseAgreements, setInactiveUseAgreements] = useState([])
  const [activeUa, setActiveUa] = useState('')
  const [isStudentMinor, setIsStudentMinor] = useState(birthDay < 18)
  const [isUaApproved, setIsUaApproved] = useState(false)
  const [isActiveUaRejected, setIsActiveUaRejected] = useState(false)
  const {
    loading: dataFetchingLoader,
    data: uaData,
    error: uaError,
    refetch: refetchUaData
  } = useQuery(GET_USE_AGREEMENTS_DATA, {
    variables: {
      id: photoId
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setUseAgreements(data.allUseAgreement)
      setInactiveUseAgreements(data.inactiveUseAgreements)
      setActiveUseAgreements(data.activeUseAgreements)
      setActiveUa(data.activeUseAgreements.length > 0 ? data.activeUseAgreements[0] : {})
      setIsUaApproved(data.approvedUa.length > 0 ? true : false)

      setIsActiveUaRejected(data.activeRejected.length > 0 ? true : false)
      setIsStudentMinor(birthDay < 18)
    }
  })

  useEffect(() => {
    setShowUseAgreementModal(false)
    refetchUaData()
  }, [props.photoId])

  const checkIfApprovalPending = () => {
    return activeUa
      ? (activeUa.signed &&
          activeUa.uaStatus === 'Active' &&
          activeUa.adminDecision === 'Pending') ||
          (activeUa.signed &&
            activeUa.uaStatus === 'Completed' &&
            activeUa.adminDecision === 'Approve')
      : false
  }

  return (
    <Box component={'div'} className='body__use-agreement' sx={{ pb: { xs: 8, lg: 10 } }}>
      <Box
        component={'div'}
        sx={{
          border: `${variable.borderThinGrey}`,
          py: { xs: 3 },
          px: { xs: 2, sm: 3 }
        }}
      >
        <Box component={'div'} className='award-nominator__publish-photograph--header'>
          <Typography variant='h2' className='strong'>
            Use Agreement
          </Typography>
          {['appadmin', 'teacher', 'facilitator'].includes(role) && !isUaApproved
            ? isPublishSectionEnable ? getFormattedStatus(
              'AlertTriangle',
              `This photograph needs an approved Use Agreement before it can be published.`
            )
            :
            getFormattedStatus(
              'AlertTriangle',
              `This photograph needs an approved Use Agreement.`
            )
            : ['student'].includes(role) && (activeUseAgreements.length === 0 || isActiveUaRejected)
            ? getFormattedStatus('AlertTriangle', `Add Use Agreement to this photograph.`)
            : null}
        </Box>
        <Box
          component={'div'}
          className='photo-release__instruction-section'
          sx={{ mt: { xs: 2, sm: 3 } }}
        >
          {role !== 'student' ? (
            photoDetails &&
            (!photoDetails.summarySent || photoDetails.summarySent === null) &&
            role === 'appadmin' ? (
              <Typography
                variant='paragraph'
                sx={{
                  mb: '1rem'
                }}
                component='p'
              >
                After you send the review summary to the student, they can add a Use Agreement.
              </Typography>
            ) : activeUseAgreements.length === 0 ||
              (activeUa &&
                ['Active'].includes(activeUa.uaStatus) &&
                [null, 'Pending'].includes(activeUa.adminDecision)) ? (
              isStudentMinor ? (
                <Typography
                  variant='paragraph'
                  sx={{
                    mb: '1rem'
                  }}
                  component='p'
                >
                  Because the student is under 18, you must ask their parent or legal guardian to
                  grant permission to use this photograph.
                </Typography>
              ) : (
                <Typography
                  variant='paragraph'
                  sx={{
                    mb: '1rem'
                  }}
                  component='p'
                >
                  You can submit the Use Agreement form for this photograph on behalf of the student
                  by clicking the link below.
                </Typography>
              )
            ) : activeUa &&
              activeUa.uaStatus === 'Completed' &&
              activeUa.adminDecision === 'Reject' ? (
              <Typography
                variant='paragraph'
                sx={{
                  mb: '1rem'
                }}
                component='p'
              >
                The student’s Use Agreement was rejected by Working Assumptions. Please check below
                for the reason and add or send a new Use Agreement.
              </Typography>
            ) : activeUa &&
              ['Completed'].includes(activeUa.uaStatus) &&
              ['Approve'].includes(activeUa.adminDecision) ? (
              isPublishSectionEnable ?
              <Typography
                variant='paragraph'
                sx={{
                  mb: '1rem'
                }}
                component='p'
              >
                This photograph has a Use Agreement. Use Agreements are reviewed by Working
                Assumptions before photographs are published. If any of the forms are not approved,
                the student will receive an email asking them to try again.
              </Typography>
              :
              <Typography
                variant='paragraph'
                sx={{
                  mb: '1rem'
                }}
                component='p'
              >
                This photograph has a Use Agreement. If any of the forms are not approved,
                the student will receive an email asking them to try again.
              </Typography>
            ) : null
          ) : activeUseAgreements.length === 0 ||
            (activeUa &&
              ['Active'].includes(activeUa.uaStatus) &&
              [null].includes(activeUa.adminDecision)) ? (
            !isStudentMinor ? (
              <Typography
                variant='paragraph'
                sx={{
                  mb: '1rem'
                }}
                component='p'
              >
                Please click below to fill out a digital Use Agreement form for this photograph. The
                form will open in a new window.
              </Typography>
            ) : (
              <Typography
                variant='paragraph'
                sx={{
                  mb: '1rem'
                }}
                component='p'
              >
                Because you are under 18, you must ask your parent or legal guardian to grant
                permission to use this photograph.
              </Typography>
            )
          ) : activeUa &&
            activeUa.uaStatus === 'Completed' &&
            activeUa.adminDecision === 'Reject' ? (
            <Typography
              variant='paragraph'
              sx={{
                mb: '1rem'
              }}
              component='p'
            >
              Your Use Agreement was rejected by Working Assumptions. Please check below for the
              reason and add or send a new Use Agreement.
            </Typography>
          ) : (activeUa &&
              ['Active'].includes(activeUa.uaStatus) &&
              ['Pending'].includes(activeUa.adminDecision)) ||
            (activeUa &&
              ['Completed'].includes(activeUa.uaStatus) &&
              ['Approve'].includes(activeUa.adminDecision)) ? (
            <Typography
              variant='paragraph'
              sx={{
                mb: '1rem'
              }}
              component='p'
            >
              Thank you for adding a Use Agreement to this photograph.
            </Typography>
          ) : null}
        </Box>
        {!checkIfApprovalPending() ? (
          <Box
            component={'div'}
            className='model-release__list-mr-section'
            sx={{ mt: { xs: 3, sm: 4, md: 3 } }}
          >
            {isStudentMinor || ['appadmin', 'teacher', 'facilitator'].includes(role) ? (
              <Typography
                variant='link'
                component={'div'}
                className={'icon-link'}
                onClick={() => {
                  props.toggleEditing && props.toggleEditing(true)
                  setShowUseAgreementModal(true)
                }}
              >
                <PlusSquare size={16} style={{ marginTop: '2px' }} /> Add a Use Agreement
              </Typography>
            ) : (
              <Typography variant='link' component={'div'}>
                <a
                  href={`/self-use-agreement/${waPrivateCipher(photoId)}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Box component={'span'} className='icon-text-span' sx={{ display: 'flex' }}>
                    <PlusSquare size={18} style={{ marginTop: '2px' }} /> Add a Use Agreement
                  </Box>
                </a>
              </Typography>
            )}
            {activeUseAgreements.length > 0 ? (
              <Typography variant='note' component={'p'} sx={{ mt: { xs: 1 } }}>
                This will replace the current Use Agreement for this photograph.
              </Typography>
            ) : null}
          </Box>
        ) : null}

        {useAgreements.length > 0 ? (
          <UseAgreementListSection
            useAgreements={activeUseAgreements}
            inactiveUseAgreements={inactiveUseAgreements}
            refetchUaData={refetchUaData}
            photoId={photoId}
            role={role}
            student={student}
            toggleEditing={props.toggleEditing}
            isStudentMinor={isStudentMinor}
          />
        ) : null}
        <UseAgreementModal
          show={showUseAgreementModal}
          onHide={() => {
            props?.toggleEditing?.(false)
            setShowUseAgreementModal(false)
          }}
          modelOp='add'
          photoIds={[photoId]}
          onSuccessCallback={() => {
            setShowUseAgreementModal(false)
            props?.toggleEditing?.(false)
          }}
          refetchUaData={refetchUaData}
          studentDetails={{ student: { ...props.student } }}
          isStudentMinor={isStudentMinor}
        />
      </Box>
    </Box>
  )
}

export default UseAgreementSection