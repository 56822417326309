import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'

import { Award, Shield, Download } from 'react-feather'
import Row from 'react-bootstrap/Row'
import moment from 'moment'
import { Col } from 'react-bootstrap'
import DownloadUtils from '../../utils/DownloadUtils'
import './photoInformation.scss'
import { getFileTitleForDownload } from '../../utils/commonFunctions'
import GATag from '../../utils/GATag'
import { Typography } from '@mui/material'

class PhotoInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShowDownloadFiles: false,
      cohortEndDate: props.photo ? moment(props.photo.project.cohort.endDate, 'YYYY-MM-DD') : null
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1000
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  async handleDownload(rawOrOriginal) {
    const { photo } = this.props
    const { downloadUrl, url, originalTitle, createdAt, exifData, project, rawFileUrl } = photo
    const { fileType } = exifData

    const year = moment(this.state.cohortEndDate).format('YYYY')
    const name =
      project &&
      project.student &&
      project.student.name &&
      project.student.name
      .trim()
      .replace(/\s+/g, ' ')
      .split(' ')
      .join('-')
      .toLowerCase()
    let fileTitle = ''
    if (originalTitle) {
      const tempTitle = getFileTitleForDownload(originalTitle)
      fileTitle = (tempTitle.length > 100 ? (tempTitle.substring(0, 100)).slice(0, -1)  :  tempTitle) + '-'
    }
    // let fileTitle = title ? getFileTitleForDownload(title) + '-' : ''
    let photoTitle = `working-assumptions-${name}-${fileTitle}${year}.${
      rawOrOriginal === 'raw' ? 'raw' : fileType
    }`
    let fileDownloadUrl = rawOrOriginal === 'raw' ? rawFileUrl : downloadUrl
    // window.ga('send', 'event', 'image', 'downloaded-footer', 1)
    GATag.setEvent({
      category: 'image',
      action: 'downloaded-footer',
      label: 'downloaded footer',
      value: 1
    })
    if (!url.includes('base64')) {
      DownloadUtils.downloadFileAndSaveAs(fileDownloadUrl, photoTitle)
    } else {
      window.open(url.replace(/^data:image\/[^;]+/, 'data:application/octet-stream'))
    }
  }

  generateDownloadJSX = photo => {
    return (
      <Fragment>
        {['rawFileUrl', 'url', 'originalUrl', 'cdnOriginalUrl', 'downloadUrl'].some(URL =>
          Object.keys(photo).includes(URL)
        ) ? (
          <div className='photo-detail-modal-footer-download'>
            <Typography variant="h4">Download Photograph</Typography>
            {['url', 'originalUrl', 'cdnOriginalUrl', 'downloadUrl'].some(URL =>
              Object.keys(photo).includes(URL)
            ) ? (
              <div className='download-file__links--link'>
                <Typography variant="h3" className="semi-strong link"
                  onClick={() => this.handleDownload('original')}
                >
                  <span className='icon-text-span'>
                    <Download size={18} />
                    {photo.exifData ? photo.exifData.fileType : ''}
                    <span style={{ marginRight: '0.5rem' }} />
                    {this.formatBytes(photo.originalFileSize)}
                  </span>
                </Typography>
              </div>
            ) : null}
            {/* https://trello.com/c/8akR8u8a/2993-photograph-detail-page-hide-or-remove-upload-raw-functionality-a-s */}
            {/* {photo.rawFileUrl !== 'No attachment' ? (
              <div className='download-file__links--link'>
                <h3 className='h3--semi-strong link' onClick={() => this.handleDownload('raw')}>
                  <span className='icon-text-span'>
                    <Download size={18} />
                    {'  '} RAW {this.formatBytes(photo.rawFileSize)}
                  </span>
                </h3>
              </div>
            ) : null} */}
          </div>
        ) : null}
      </Fragment>
    )
  }

  getFinalAward = awards => {
    if (awards.length > 0) {
      let aoeCount = awards.filter(award => award.awardClass === 'Award of Excellence').length
      let hmCount = awards.filter(award => award.awardClass === 'Honorable Mention').length

      return aoeCount > hmCount || aoeCount === hmCount ? 'aoe' : hmCount > 0 ? 'hm' : null
    }
    return null
  }

  render() {
    const { role } = this.props.userStore
    const todayDate = moment().format('YYYY-MM-DD')
    const { photo } = this.props
    const finalAward = this.getFinalAward(photo.awards)
    return (
      <div className='container__body'>
        <div className='photo__more-information'>
          <Typography variant="h3" className='semi-strong'>More About This Photograph</Typography>
          <Fragment>
            <Row>
              <Col md='true' sm={4}>
                {this.generateDownloadJSX(photo)}
              </Col>
              <Col md='true' sm={4}>
                <Typography variant="h4">Award Status</Typography>
                {!photo.project.cohort.reviewed ? (
                  this.state.cohortEndDate.isBefore(todayDate) &&
                  !photo.project.cohort.reviewed &&
                  photo.project.submitted ? (
                    <Typography variant="h3">Project Is Being Reviewed</Typography>
                  ) : !this.state.cohortEndDate.isBefore(todayDate) ||
                    this.state.cohortEndDate.isSame(todayDate) ||
                    (!photo.project.submitted &&
                      (!this.state.cohortEndDate.isBefore(todayDate) ||
                        this.state.cohortEndDate.isSame(todayDate))) ? (
                    <Typography variant="h3">Project Not Reviewed</Typography>
                  ) : null
                ) : (photo.summarySent && ['student', 'teacher', 'facilitator'].includes(role)) ||
                  (['appadmin', 'curator'].includes(role) && photo.project.cohort.reviewed) ||
                  (['curator'].includes(role) && !photo.project.cohort.reviewed) ? (
                  photo.awards.length > 0 ? (
                    finalAward === 'aoe' ? (
                      <Typography variant="h3">
                        <span className='icon-text-span'>
                          <Award size={19} />
                          Award of Excellence
                        </span>
                      </Typography>
                    ) : finalAward === 'hm' ? (
                      <Typography variant="h3">
                        <span className='icon-text-span'>
                          <Shield size={19} />
                          Honorable Mention
                        </span>
                      </Typography>
                    ) : null
                  ) : (
                    <Typography variant="h3">No Awards</Typography>
                  )
                ) : !photo.summarySent &&
                  photo.project.cohort.reviewed &&
                  ['student', 'teacher', 'facilitator'].includes(role) ? (
                  <Typography variant="h3">Project Is Being Reviewed</Typography>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md='true' sm={4}>
                <Typography variant="h4">Project Submitted On</Typography>
                <Typography variant="h3">
                  {photo.project.submitted
                    ? moment(photo.project.submittedUpdatedAt).format('LL')
                    : 'Project Not Submitted'}
                </Typography>
              </Col>
              <Col md='true' sm={4}>
                <Typography variant="h4">Photograph Published On</Typography>
                {photo.published && photo.adminApproved ? (
                  <Typography variant="h3">{moment(photo.publishUpdatedAt).format('LL')}</Typography>
                ) : (
                  <Typography variant="h3" className='semi-strong small_size'>Photograph Not Published</Typography>
                )}
              </Col>
            </Row>
          </Fragment>
        </div>
      </div>
    )
  }
}

export default inject('userStore')(observer(PhotoInformation))
