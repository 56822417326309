
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { inject, observer } from 'mobx-react'
import '../../molecules/modals.scss'
import PhotoDetailModalSub from './PhotoDetailModalSub'
import Dialog from '@mui/material/Dialog'
import Slide from '@mui/material/Slide'
import { DialogContent, DialogContentText } from '@mui/material'
import { withRouter } from '../../../utils/customHoc'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewFullScreenPhotoModal = (props) => {
  const {
    show,
    closeModal,
    location,
  } = props
  const publicView = ['/national-photos', '/collection', '/covid-collection'].includes(
    location.pathname
  )
  const onClassReview = location.pathname.includes('class-review')
  const [keyCode, setKeyCode] = useState()
  const passThroughProps = _.omit(props, [
        'staticContext',
        'photoCollectionStore',
        'userStore',
        'updateParentAwardGroups',
        'updateParentGallery',
        'getAdjacentImage',
        'toggleParentModalState',
        'photoId'
      ])

      const descriptionElementRef = useRef(null);
      useEffect(() => {
        if (show) {
          const { current: descriptionElement } = descriptionElementRef;
          if (descriptionElement !== null) {
            descriptionElement.focus();
          }
        }
      }, [show]);

  return (
    <div
      tabIndex='0'
      onKeyUp={e => {
        setKeyCode(e.keyCode)
      }}
    >
      <Dialog
        {...passThroughProps}
        fullScreen
        open={show}
        onClose={() => {
          closeModal()
        }}
        TransitionComponent={Transition}
        className={`${props.className} photo-detail-modal ${
          onClassReview ? 'class-review-photo-detail-modal' : ''
        }`}
        scroll={'body'}
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent sx={{padding: 0}}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <PhotoDetailModalSub
              publicView={publicView}
              keyCode={keyCode}
              setKeyCode={setKeyCode}
              {...props}
            />
        </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default inject('userStore', 'photoCollectionStore', 'backToPageStore', 'photosStore')(
  observer(withRouter(NewFullScreenPhotoModal))
)