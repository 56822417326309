import { useEffect, useState } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { observer, inject } from 'mobx-react'
import { useQuery } from '@apollo/client'
import Hero from '../ui/organisms/Hero.js'
import ReviewDetails from '../ui/organisms/ReviewDetails.js'
import CuratorStudentList from '../ui/organisms/CuratorStudentList.js'
import { Box, Typography } from '@mui/material'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout.js'
import { paths } from '../utils/routes.js'
import AwardProcessing from '../ui/organisms/AwardProcessing.js'
import ArchiveTools from '../ui/organisms/ArchiveTools.js'
import ClassReviewSchoolDetailsModal from '../ui/molecules/ClassReviewSchoolDetailsModal.js'
import ErrorPage from './ErrorPage.jsx'
import ToastNotification from '../ui/elements/ToastNotification.js'
import { withRouter } from '../utils/customHoc.js'
import { COHORT_BY_ID } from '../queries/classDetailsQueries.js'
import { truncateDetails } from '../utils/commonFunctions.js'

const ClassReview = props => {
  const { params, userStore, breadcrumbStore, location, basePath } = props
  const { role } = userStore

  const [currentTab, setCurrentTab] = useState('review-details')
  const [toastShow, setToastShow] = useState(false)
  const [modalSchoolDetailsShow, setModalSchoolDetailsShow] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastType, setToastType] = useState('')
  const [tabs, setTabs] = useState([])
  const [classId] = useState(params.classId)
  const [cohort, setCohort] = useState()

  useEffect(() => {
    if (params.classId) {
      const defaultTabs = [
        {
          eventKey: 'review-details',
          title: 'Submitted Photographs',
          path: paths.classReview(params.classId)
        },
        {
          eventKey: 'students',
          title: 'Student List',
          path: paths.classReviewStudents(params.classId)
        },
        {
          eventKey: 'award-processing',
          title: 'Review Status',
          path: paths.classReviewAwardProcessing(params.classId)
        }
      ]
      if (role === 'appadmin') {
        defaultTabs.push({
          eventKey: 'archive-tools',
          title: 'Archive Tools',
          path: paths.classArchiveTools(params.classId)
        })
      }
      setTabs(
        role === 'appadmin'
          ? defaultTabs
          : defaultTabs.filter(tab => tab.eventKey !== 'class-photographs')
      )
    }
  }, [params.classId, role])

  useEffect(() => {
    setCurrentTab(props?.params?.currentTab || 'review-details')
  }, [props.params.currentTab])

  useEffect(() => {
    if (cohort?.name) {
      breadcrumbStore.addBreadcrumb({
        pageName: `Review: ${cohort.name}`,
        link: location.pathname,
        basePath: basePath,
        isParent: false,
        isActive: true
      })
    }
  }, [cohort?.name, location, basePath, breadcrumbStore])

  const { loading, error } = useQuery(COHORT_BY_ID, {
    variables: {
      id: params.classId
    },
    fetchPolicy: 'cache-and-network',
    skip: !classId,
    onCompleted(data) {
      setCohort(data.cohort)
    }
  })

  if (loading)
    return (
      <Layout>
        <Typography component={'p'} textAlign={'center'}>
          <Loading />
        </Typography>
      </Layout>
    )
  if (error)
    return (
      <Layout>
        <ErrorPage errorCode='403' withInPage={true} />
      </Layout>
    )

  const showToast = (toastType, message = '') => {
    setToastShow(true)
    setToastMessage(message)
    setToastType(toastType)
  }

  const handleTabChange = newTab => {
    setCurrentTab(newTab)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToastShow(false)
  }

  const getClassReviewHeaderSubTitle = () => {
    let schoolName = cohort?.school?.name || ''
    let schoolCity = cohort?.school?.mailingAddress?.city || ''
    let schoolState = cohort?.school?.mailingAddress?.state || ''
    let schoolNameTitle = truncateDetails(schoolName, 40)
    return (
      <Box sx={{ display: { xs: 'grid', sm: 'flex' }, gap: { xs: 0.5 } }}>
        {schoolNameTitle}, {schoolCity}, {schoolState}{' '}
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>• </Box>
        <Typography variant='link' onClick={() => setModalSchoolDetailsShow(true)}>
          School Details
        </Typography>
      </Box>
    )
  }

  const modalClose = () => {
    setModalSchoolDetailsShow(false)
  }

  if (cohort) {
    return (
      <Layout>
        {toastShow === true ? (
          <ToastNotification
            notificationType={toastType}
            showToast={toastShow}
            onClose={handleClose}
            toastMessage={toastMessage}
          />
        ) : null}
        <Hero
          heroTitle={cohort.name}
          heroSubTitle={getClassReviewHeaderSubTitle()}
          currentTab={currentTab}
          tabChangeHandler={handleTabChange}
          tabs={tabs}
          heroTimeline={props.heroTimeline}
          goBackPage='/reviews'
        />
        {modalSchoolDetailsShow && (
          <ClassReviewSchoolDetailsModal
            show={modalSchoolDetailsShow}
            onHide={modalClose}
            cohortId={cohort.id}
            schoolId={cohort.school.id}
          />
        )}
        {currentTab === 'review-details' ? (
          <ReviewDetails cohort={cohort} showToast={showToast} />
        ) : currentTab === 'students' ? (
          <CuratorStudentList cohortId={cohort.id} />
        ) : currentTab === 'award-processing' ? (
          <AwardProcessing cohort={cohort} showToast={showToast} />
        ) : role === 'appadmin' && currentTab === 'archive-tools' ? (
          <ArchiveTools cohort={cohort} showToast={showToast} />
        ) : null}
      </Layout>
    )
  } else {
    return (
      <Layout>
        <ErrorPage errorCode='403' withInPage={true} />
      </Layout>
    )
  }
}

// class ClassReview extends Component {
//   constructor(props) {
//     super(props)
//     const initialTab = 'review-details'
//     const { params } = props
//     this.state = {
//       currentTab: params.currentTab ? params.currentTab : initialTab,
//       classPhotosScope: params.classPhotosScope ? params.classPhotosScope : 'all',
//       modalShow: false,
//       toastShow: false,
//       batchCounter: 1,
//       photos: [],
//       renderCount: 0,
//       pageProgress: {
//         allPageNum: 0,
//         nonePageNum: 0,
//         honorablePageNum: 0,
//         aoePageNum: 0,
//         pubPageNum: 0
//       },
//       // these are used so that the page doesn't refresh but maintains progress summary status during session
//       educatorSummarySent: false,
//       studentSummarySent: false,
//       modalSchoolDetailsShow: false
//     }
//     this.photoOnClick = this.photoOnClick.bind(this)
//     this.changeScopedPhoto = this.changeScopedPhoto.bind(this)
//     this.changeScopedProject = this.changeScopedProject.bind(this)
//     this.handleTabChange = this.handleTabChange.bind(this)
//     this.curatorTabs = [
//       {
//         eventKey: 'review-details',
//         title: 'Submitted Photographs',
//         path: paths.classReview(params.classId)
//       },
//       {
//         eventKey: 'students',
//         title: 'Student List',
//         path: paths.classReviewStudents(params.classId)
//       },
//       {
//         eventKey: 'award-processing',
//         title: 'Review Status',
//         path: paths.classReviewAwardProcessing(params.classId)
//       },
//       this.props.userStore.role === 'appadmin'
//         ? {
//             eventKey: 'archive-tools',
//             title: 'Archive Tools',
//             path: paths.classArchiveTools(params.classId)
//           }
//         : ''
//     ]
//   }

//   componentDidMount() {
//     // const { backToPageStore, location } = this.props
//     // backToPageStore.setActivePage(location.pathname)
//     // backToPageStore.setActivePageTab('review-details')
//   }

//   componentWillUnmount() {
//     // const { backToPageStore } = this.props
//     // backToPageStore.setActivePage(null)
//     // backToPageStore.setActivePageTab(null)
//     // backToPageStore.setActivePageFilter(null)
//   }

//   componentWillReceiveProps(nextProps) {
//     if (this.props?.params?.currentTab !== nextProps?.params?.currentTab) {
//       this.handleTabChange(nextProps?.params?.currentTab || 'review-details')
//     }
//   }

//   showToast = (toastType, message = '') => {
//     this.setState({
//       showToast: true,
//       toastMessage: message,
//       toastType: toastType
//     })
//     setTimeout(() => this.setState({ showToast: false, toastMessage: '' }), 5000)
//   }

//   handleTabChange(newTab) {
//     // const tab = this.findCuratorTabByEventKey(newTab)
//     // Using window.history.pushState() instead of React's history.push() to prevent the whole
//     // component from re-rendering.
//     // window.history.pushState('', '', tab.path)
//     this.setState({ currentTab: newTab, classPhotosScope: 'all' })
//     // const { backToPageStore } = this.props
//     // backToPageStore.setActivePageTab(newTab)
//   }

//   findCuratorTabByEventKey = eventKey => {
//     return this.curatorTabs.find(tab => tab.eventKey === eventKey)
//   }

//   photoOnClick(photo) {
//     this.setState({
//       currentTab: 'class-photographs',
//       classPhotosScope: 'single',
//       scopedPhotoId: photo.id,
//       indivPhoto: photo,
//       scopedProjectId: photo.project.id
//     })
//   }
//   changeScopedProject(projectId) {
//     this.setState({
//       currentTab: 'class-photographs',
//       classPhotosScope: 'project',
//       scopedPhotoId: null,
//       scopedProjectId: projectId
//     })
//   }

//   changeScopedPhoto(photo, direction, paginating = false, ids = null) {
//     let idList = ids
//     if (!ids) {
//       idList = this.props.photoCollectionStore.filteredItems
//         .slice(0, paginating ? (1 + this.state.batchCounter) * 24 : this.state.batchCounter * 24)
//         .map(photo => photo.id)
//     }
//     const currentPhotoIndex = idList.indexOf(photo.id.id || photo.id)
//     const nextPhotoIndex =
//       direction === 'next'
//         ? (currentPhotoIndex + 1) % idList.length
//         : idList.indexOf(_.slice(idList, currentPhotoIndex - 1)[0])
//     this.setState({
//       indivPhoto: this.state.photos.find(photo =>
//         photo.id === !ids
//           ? this.props.photoCollectionStore.filteredItems[nextPhotoIndex].id
//           : idList[nextPhotoIndex].id
//       )
//     })
//     if (!ids) this.photoOnClick(this.props.photoCollectionStore.filteredItems[nextPhotoIndex])
//   }

//   findPhotoById(projects, id) {
//     const foo = projects
//       .map(proj => proj.photos)
//       .flat(2)
//       .find(photo => photo.id === id)

//     return foo
//   }

//   determinePhotoApproved = projects => {
//     for (let project of projects) {
//       const approvedPhotos = project.photos.filter(photo => photo.readyToPublish)
//       if (approvedPhotos.length > 0) return true
//     }
//     return false
//   }

//   updateBatchCounter = () => {
//     this.setState({
//       ...this.state,
//       batchCounter: this.state.batchCounter + 1
//     })
//   }

//   setPhotosState = photos => {
//     if (this.state.renderCount === 0)
//       this.setState({
//         ...this.state,
//         photos: photos ? photos : [],
//         renderCount: 1
//       })
//   }

//   updateSummarySentStatus = type => {
//     if (['student', 'educator'].includes(type))
//       this.setState({
//         ...this.state,
//         [`${type}SummarySent`]: true
//       })
//   }

//   alignStateWithCache = async photoId => {
//     let newPhotos = [...this.state.photos]
//     const data = await this.props.client.readFragment({
//       id: `Photo:${photoId}`,
//       fragment: gql`
//         fragment updatedPhotoComments on Photo {
//           __typename
//           id
//           isShortListed
//           submittedDate
//           title
//           caption
//           altText
//           mrAdminNotes
//           publishable
//           adminApproved
//           attribution
//           awards {
//             id
//             awardClass
//             nominatorInitials
//             nominatorUserId
//             nominatorName
//             nominatorType
//             isEdited
//             editedOn
//           }
//           project {
//             id
//           }
//           photoComments {
//             body
//             id
//             commenterInitials
//             commenterUserId
//             commenterName
//             createdAt
//           }
//         }
//       `
//     })
//     const idx = newPhotos.find(photo => photo.id === photoId)
//     newPhotos[idx] = data

//     this.setState({
//       ...this.state,
//       photos: newPhotos
//     })
//   }

//   setAdjacentGroup = group => {
//     this.setState({
//       ...this.state,
//       adjacentGroup: group
//     })
//   }

//   containsProperty = (cohort, property) => {
//     return (
//       cohort.submittedProjects
//         .map(proj => proj.photos.filter(photo => photo[property]))
//         .filter(photosArr => photosArr.length > 0).length > 0
//     )
//   }
//   updatePaginationProgress = obj => {
//     this.setState({
//       ...this.state,
//       pageProgress: obj
//     })
//   }

//   getClassReviewHeaderTitle = cohort => {
//     return <Fragment>{cohort.name}</Fragment>
//   }

//   handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return
//     }
//     this.setState({ showToast: false })
//   }

//   getClassReviewHeaderSubTitle = cohort => {
//     let schoolName = cohort.school ? cohort.school.name : ''
//     let schoolCity = cohort.school
//       ? cohort.school.mailingAddress
//         ? cohort.school.mailingAddress.city
//         : ''
//       : ''
//     let schoolState = cohort.school
//       ? cohort.school.mailingAddress
//         ? cohort.school.mailingAddress.state
//         : ''
//       : ''
//     let schoolNameTitle =
//       schoolName.length > 40
//         ? `${schoolName.substring(0, 40)}...`
//         : schoolName.length > 0
//         ? `${schoolName}`
//         : schoolName
//     return (
//       <Box sx={{ display: { xs: 'grid', sm: 'flex' }, gap: { xs: 0.5 } }}>
//         {schoolNameTitle}, {schoolCity}, {schoolState}{' '}
//         <Box sx={{ display: { xs: 'none', sm: 'block' } }}>• </Box>
//         <Typography variant='link' onClick={() => this.setState({ modalSchoolDetailsShow: true })}>
//           School Details
//         </Typography>
//       </Box>
//     )
//   }

//   isCuratorSummarySend = projects => {
//     return projects.filter(proj => proj.reviewed).length > 0
//   }

//   modalClose = () => {
//     this.setState({
//       modalSchoolDetailsShow: false
//     })
//   }

//   render() {
//     const {
//       params,
//       userStore,
//       photoCollectionStore,
//       breadcrumbStore,
//       location,
//       basePath
//     } = this.props
//     const { role } = userStore
//     const { photoOnClick, getClassReviewHeaderTitle, getClassReviewHeaderSubTitle } = this
//     const curatorTabs =
//       role === 'appadmin'
//         ? this.curatorTabs
//         : this.curatorTabs.filter(tab => tab.eventKey !== 'class-photographs')

//     return (
//       <Layout>
//         <Query
//           query={REVIREW_BY_COHORT_ID}
//           variables={{ id: params.classId }}
//           fetchPolicy='cache-and-network'
//         >
//           {({ loading, error, data, refetch }) => {
//             if (loading) return <Loading />
//             if (error) {
//               return <ErrorPage errorCode='403' withInPage={true} />
//             }
//             if (Object.keys(data).length > 0 && data.cohort) {
//               const { cohort } = data
//               const projects =
//                 data.cohort &&
//                 data.cohort.submittedProjects.filter(
//                   project => project.submitted && project.photos.length > 0
//                 ) // shows late projects too, were missing submission data for some proejcts
//               const photos =
//                 projects &&
//                 projects
//                   .map(proj =>
//                     proj.photos.filter(photo => photo.isShortListed && photo.publishable)
//                   )
//                   .flat()
//               this.setPhotosState(photos)
//               photoCollectionStore.setItems(photos)
//               breadcrumbStore.addBreadcrumb({
//                 pageName: `Review: ${cohort.name}`,
//                 link: location.pathname,
//                 basePath: basePath,
//                 isParent: false,
//                 isActive: true
//               })
//               return (
//                 <Fragment>
//                   {this.state.showToast === true ? (
//                     <ToastNotification
//                       notificationType={this.state.toastType}
//                       showToast={this.state.showToast}
//                       onClose={this.handleClose}
//                       toastMessage={this.state.toastMessage}
//                     />
//                   ) : null}
//                   <Fragment>
//                     <Hero
//                       heroTitle={getClassReviewHeaderTitle(cohort)}
//                       heroSubTitle={getClassReviewHeaderSubTitle(cohort)}
//                       currentTab={this.state.currentTab}
//                       tabChangeHandler={this.handleTabChange}
//                       tabs={curatorTabs}
//                       heroTimeline={this.props.heroTimeline}
//                       goBackPage='/reviews'
//                     />
//                     {this.state.modalSchoolDetailsShow && (
//                       <ClassReviewSchoolDetailsModal
//                         show={this.state.modalSchoolDetailsShow}
//                         onHide={this.modalClose}
//                         cohortId={cohort.id}
//                         schoolId={cohort.school.id}
//                       />
//                     )}
//                     {this.state.currentTab === 'review-details' ? (
//                       <ReviewDetails
//                         cohort={data.cohort}
//                         photoOnClick={photoOnClick}
//                         projectIds={projects.map(proj => proj.id)}
//                         reviewSent={cohort.reviewed}
//                         pageProgress={this.state.pageProgress}
//                         updatePaginationProgress={this.updatePaginationProgress}
//                         showToast={this.showToast}
//                         setAdjacentGroup={this.setAdjacentGroup}
//                         refetchParent={refetch}
//                         updateParentSummarySentStatus={this.updateSummarySentStatus}
//                       />
//                     ) : this.state.currentTab === 'students' ? (
//                       <CuratorStudentList
//                         cohortId={cohort.id}
//                         photoOnClick={photoOnClick}
//                         changeScopedProject={this.changeScopedProject}
//                         role={role}
//                         setAdjacentGroup={this.setAdjacentGroup}
//                       />
//                     ) : this.state.currentTab === 'award-processing' ? (
//                       <AwardProcessing
//                         cohort={data.cohort}
//                         showOnly={true}
//                         changeTab={this.handleTabChange}
//                         role={role}
//                         isPublished={this.containsProperty(data.cohort, 'published')}
//                         showToast={this.showToast}
//                         summaryBeenSent={
//                           this.state.educatorSummarySent ||
//                           this.containsProperty(data.cohort, 'summarySent')
//                         }
//                         studentSummaryBeenSent={
//                           this.state.studentSummarySent ||
//                           this.containsProperty(data.cohort, 'sentStudentSummary')
//                         }
//                         reviewSent={this.isCuratorSummarySend(projects)}
//                         updateParentSummarySentStatus={this.updateSummarySentStatus}
//                         curatorsDueDate={data.cohort.curatorsDueDate}
//                       />
//                     ) : role === 'appadmin' && this.state.currentTab === 'archive-tools' ? (
//                       <ArchiveTools
//                         cohort={data.cohort}
//                         showOnly={true}
//                         modalExportDetail={false}
//                         showToast={this.showToast}
//                         changeTab={this.handleTabChange}
//                         role={role}
//                         refetchParent={refetch}
//                       />
//                     ) : null}
//                   </Fragment>
//                 </Fragment>
//               )
//             }
//             return <ErrorPage errorCode='403' withInPage={true} />
//           }}
//         </Query>
//       </Layout>
//     )
//   }
// }

export default inject('userStore', 'photoCollectionStore', 'backToPageStore', 'breadcrumbStore')(
  observer(withApollo(withRouter(ClassReview)))
)
