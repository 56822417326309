import { useMutation } from '@apollo/client'
import moment from 'moment'
import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap'
import {
  AlertTriangle,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  Clock,
  PlusSquare,
  Slash
} from 'react-feather'
import ModelReleaseDropDown from '../../../elements/ModelReleaseDropDown'
import ApproveOrRejectFormModal from '../../../molecules/ApproveOrRejectFormModal'
import DeleteModelReleaseModal from '../../../molecules/DeleteModelReleaseModal'
import { Typography, Box, Grid } from '@mui/material'
import ResendModelRequestModal from '../../../molecules/ResendModelRequestModal'
import { MANAGE_MR_NUMBERS } from '../../../../queries/PhotoDetailsQueries'
import variable from './../../../settings/_variables.scss'
import usePicsio from '../../../../../src/utils/picsioUtility'

const ModelReleasesListRowSection = props => {
  const { allRelatedMrs, photoId, role, modelName, modelUuid, modelRelease } = props
  const [showApprovalModal, setShowApprovalModal] = useState(false)
  const [showResendModal, setShowResendModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [opMrUuid, setOpMrUuid] = useState(null)
  const [openOne, setOpenOne] = useState(false)
  const [deleteType, setDeleteType] = useState(null)
  const [isDisable, setIsDisable] = useState(false)

  const { removeImage } = usePicsio()

  const [
    adddeleteModelMutation,
    { loading: deleteModelLoading, data: deleteModelData, error: deleteModelError }
  ] = useMutation(MANAGE_MR_NUMBERS, {
    onCompleted(data) {
      if(modelRelease?.assetId) {
        removeImage([modelRelease.assetId])
      }
      props?.refetchPhoto?.()
      props?.refetchMrData?.()
      setTimeout(() => {
        setIsDisable(false)
      }, 900)
    }
  })
  return (
    <Box component={'div'} className='model-release__list' sx={{ p: { xs: 2, sm: 3 } }}>
      <Grid container className='model-release__list-body-section'>
        {/* <Row>
            <Col sm={12}> */}
        <Grid item xs={12} sm={9}>
          <Typography variant='h3' className='strong' sx={{ pb: 0 }}>
            {modelName}
          </Typography>
          {(modelRelease &&
            modelRelease.signed !== true &&
            !['Approve'].includes(modelRelease.adminDecision)) ||
          (modelRelease &&
            modelRelease.signed &&
            ['Completed'].includes(modelRelease.mrStatus) &&
            ['Reject'].includes(modelRelease.adminDecision)) ||
          (modelRelease &&
            modelRelease.signed &&
            ['Active'].includes(modelRelease.mrStatus) &&
            ['Pending'].includes(modelRelease.adminDecision) &&
            modelRelease.sentVia === 'offline') ||
          !modelRelease ? (
            <Box component={'div'} sx={{ mt: { xs: 2 } }}>
              <Typography
                variant='link'
                component={'div'}
                className={'icon-link'}
                onClick={() =>
                  props.showMrModel(
                    'add',
                    modelRelease && modelRelease.uuid ? modelRelease.uuid : null
                  )
                }
              >
                <PlusSquare size={16} style={{ marginTop: '2px' }} /> Add a Release for This Model
              </Typography>

              {modelRelease && modelRelease.uuid ? (
                <Typography variant='note' component={'p'} sx={{ mt: { xs: 1 } }}>
                  This will replace the current Model Release for this model.
                </Typography>
              ) : null}
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={3} sx={{ textAlign: { sm: 'right' }, mt: { xs: 3, sm: '0' } }}>
          {['appadmin'].includes(role) ? (
            <Typography
              component={'p'}
              variant={isDisable ? 'disabled_link' : 'link'}
              className='alert-link'
              onClick={e => {
                setIsDisable(true)
                setTimeout(() => {
                  if (modelUuid) {
                    setShowDeleteModal(true)
                    setOpMrUuid(modelUuid)
                    setDeleteType('main')
                    setIsDisable(false)
                  } else {
                    if (!isDisable) {
                      adddeleteModelMutation({
                        variables: {
                          photoId: photoId,
                          opType: 'delete'
                        }
                      })
                    }
                  }
                }, 90)
              }}
            >
              Delete Model
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          {modelRelease && modelRelease.uuid ? (
            <Box
              component={'div'}
              className='model-release__list-history-section'
              sx={{ mt: { xs: ['appadmin'].includes(role) ? 4 : 0, sm: 3 } }}
            >
              <Grid
                container
                sx={{
                  py: 2,
                  px: { xs: 1, md: 2 },
                  border: `${variable.borderThinGrey}`,
                  backgroundColor: `${variable.backgroundGrey}`
                }}
              >
                <Grid item xs={10}>
                  <Box component={'div'}>
                    {modelRelease.sentVia !== 'offline' ? (
                      ['appadmin', 'student'].includes(role) ? (
                        <Typography variant='paragraph' component='p'>
                          Model Release for{' '}
                          {modelRelease.isMinor
                            ? modelRelease.isDeceased
                              ? 'minor deceased'
                              : 'minor'
                            : modelRelease.isDeceased
                            ? 'adult deceased'
                            : 'adult'}{' '}
                          sent to{' '}
                          <Box component={'strong'} sx={{ wordBreak: { xs: 'break-word' } }}>
                            {modelRelease.sentTo}
                          </Box>{' '}
                          on{' '}
                          {modelRelease.lastSentOn
                            ? moment(modelRelease.lastSentOn, 'YYYY-MM-DD').format('MMMM D, YYYY')
                            : moment(modelRelease.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                        </Typography>
                      ) : (
                        <Typography variant='paragraph' component='p'>
                          Model Release for{' '}
                          {modelRelease.isMinor
                            ? modelRelease.isDeceased
                              ? 'minor deceased'
                              : 'minor'
                            : modelRelease.isDeceased
                            ? 'adult deceased'
                            : 'adult'}{' '}
                          sent by {modelRelease.sentVia === 'email' ? 'email' : 'text message'} on{' '}
                          {modelRelease.lastSentOn
                            ? moment(modelRelease.lastSentOn, 'YYYY-MM-DD').format('MMMM D, YYYY')
                            : moment(modelRelease.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                        </Typography>
                      )
                    ) : (
                      <Typography variant='paragraph' component='p'>
                        Model Release PDF for{' '}
                        {modelRelease.isMinor
                          ? modelRelease.isDeceased
                            ? 'deceased minor'
                            : 'minor'
                          : modelRelease.isDeceased
                          ? 'deceased adult'
                          : 'adult'}{' '}
                        uploaded on{' '}
                        {modelRelease.lastSentOn
                          ? moment(modelRelease.lastSentOn, 'YYYY-MM-DD').format('MMMM D, YYYY')
                          : moment(modelRelease.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                      </Typography>
                    )}
                  </Box>
                  <Box component={'div'} className='row' sx={{ mt: { xs: 3, sm: 2 } }}>
                    <Typography variant='status' component={'div'}>
                      {modelRelease.signed ? (
                        <Box
                          component={'span'}
                          className='icon-text-span model-release__list--success'
                          sx={{ display: 'flex' }}
                        >
                          {modelRelease.sentVia !== 'offline' ? (
                            <>
                              <CheckCircle size={16} style={{ marginTop: '2px' }} /> Granted by{' '}
                              {modelRelease.isMinor || modelRelease.isDeceased
                                ? modelRelease.signeeFirstName + ' ' + modelRelease.signeeLastName
                                : modelRelease.modelFirstName + ' ' + modelRelease.modelLastName}
                              {modelRelease.signedOn
                                ? ` on ${moment(modelRelease.signedOn, 'YYYY-MM-DD').format(
                                    'MMMM D, YYYY'
                                  )}`
                                : null}
                            </>
                          ) : (
                            <>
                              <CheckCircle size={16} style={{ marginTop: '2px' }} /> PDF Uploaded
                              {modelRelease.signedOn
                                ? ` on ${moment(modelRelease.signedOn, 'YYYY-MM-DD').format(
                                    'MMMM D, YYYY'
                                  )}`
                                : null}
                            </>
                          )}
                        </Box>
                      ) : modelRelease.signed === false ? (
                        <Box
                          component={'span'}
                          className='icon-text-span model-release__list--blocked'
                          sx={{ display: 'flex' }}
                        >
                          <Slash size={16} style={{ marginTop: '2px' }} /> Declined by{' '}
                          {modelRelease.isMinor || modelRelease.isDeceased
                            ? modelRelease.signeeFirstName + ' ' + modelRelease.signeeLastName
                            : modelRelease.modelFirstName + ' ' + modelRelease.modelLastName}
                          {modelRelease.signedOn
                            ? ` on ${moment(modelRelease.signedOn, 'YYYY-MM-DD').format(
                                'MMMM D, YYYY'
                              )}`
                            : null}
                        </Box>
                      ) : (
                        <Box component={'span'} className='icon-text-span' sx={{ display: 'flex' }}>
                          <Clock size={16} style={{ marginTop: '2px' }} /> Signer Has Not Responded
                        </Box>
                      )}
                      {modelRelease.signed ? (
                        <Box component={'div'} sx={{ mt: { xs: 1 } }}>
                          <Typography variant='status' component={'div'}>
                            {modelRelease.adminDecision === 'Pending' ? (
                              ['appadmin'].includes(role) ? (
                                <Box
                                  component={'span'}
                                  className='icon-text-span model-release__list--alert'
                                  sx={{ display: 'flex' }}
                                >
                                  <AlertTriangle size={16} style={{ marginTop: '2px' }} /> Approve
                                  or Reject Model Release{' '}
                                </Box>
                              ) : (
                                <Box
                                  component={'span'}
                                  className='icon-text-span'
                                  sx={{ display: 'flex' }}
                                >
                                  <Clock size={16} style={{ marginTop: '2px' }} /> Working
                                  Assumptions Is Reviewing Model Release{' '}
                                </Box>
                              )
                            ) : modelRelease.adminDecision == 'Approve' ? (
                              <Box
                                component={'span'}
                                className='icon-text-span model-release__list--success'
                                sx={{ display: 'flex' }}
                              >
                                <CheckCircle size={16} style={{ marginTop: '2px' }} /> Approved by
                                Working Assumptions
                                {modelRelease.adminDecisionOn
                                  ? ` on ${moment(
                                      modelRelease.adminDecisionOn,
                                      'YYYY-MM-DD'
                                    ).format('MMMM D, YYYY')}`
                                  : null}
                              </Box>
                            ) : modelRelease.adminDecision == 'Reject' ? (
                              <Box
                                component={'span'}
                                className='icon-text-span'
                                sx={{ display: 'flex' }}
                              >
                                <Slash size={16} style={{ marginTop: '2px' }} /> Rejected by Working
                                Assumptions
                                {modelRelease.adminDecisionOn
                                  ? ` on ${moment(
                                      modelRelease.adminDecisionOn,
                                      'YYYY-MM-DD'
                                    ).format('MMMM D, YYYY')}`
                                  : null}
                              </Box>
                            ) : null}
                          </Typography>
                          {modelRelease.adminDecision === 'Reject' ? (
                            <Typography
                              variant='note'
                              component={'div'}
                              className='body text-break'
                              sx={{ mt: { xs: 3, sm: 1 } }}
                            >
                              {modelRelease.adminRejectionNote
                                ? modelRelease.adminRejectionNote
                                : ''}
                            </Typography>
                          ) : null}
                        </Box>
                      ) : null}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  {(['student', 'teacher', 'facilitator'].includes(role) &&
                    modelRelease.signed === null &&
                    modelRelease.mrStatus === 'Inactive') ||
                  (['teacher', 'facilitator'].includes(role) &&
                    modelRelease.signed === null) ? null : (
                    <ModelReleaseDropDown
                      modelRelease={modelRelease}
                      setShowApprovalModal={() => {
                        setOpMrUuid(modelRelease.uuid)
                        setShowApprovalModal(true)
                      }}
                      setShowDeleteModal={() => {
                        setOpMrUuid(modelRelease.uuid)
                        setShowDeleteModal(true)
                      }}
                      setShowResendModal={() => {
                        setOpMrUuid(modelRelease.uuid)
                        setShowResendModal(true)
                      }}
                      role={role}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Grid>
        {
          <>
            {allRelatedMrs && allRelatedMrs.length > 0 && ['appadmin'].includes(role) ? (
              <Grid item xs={12}>
                <Collapse in={openOne}>
                  <Box component={'div'}>
                    {allRelatedMrs
                      .filter(mr => mr.mrStatus !== 'Deleted')
                      .map((newMr, index) => (
                        <Box
                          component={'div'}
                          className='model-release__list-history-section'
                          sx={{ mt: 1 }}
                        >
                          <Grid
                            container
                            sx={{
                              py: 2,
                              px: { xs: 1, md: 2 },
                              border: `${variable.borderThinGrey}`,
                              backgroundColor: `${variable.backgroundGrey}`
                            }}
                          >
                            <Grid item xs={10}>
                              <Box
                                component={'div'}
                                className='model-release__list-history-mr-status--inactive model-release__list-history-mr-status'
                              >
                                {newMr.sentVia !== 'offline' ? (
                                  ['appadmin', 'student'].includes(role) ? (
                                    <Typography variant='paragraph' component='p'>
                                      Model Release for{' '}
                                      {newMr.isMinor
                                        ? newMr.isDeceased
                                          ? 'minor deceased'
                                          : 'minor'
                                        : newMr.isDeceased
                                        ? 'adult deceased'
                                        : 'adult'}{' '}
                                      sent to{' '}
                                      <Box
                                        component={'strong'}
                                        sx={{ wordBreak: { xs: 'break-word' } }}
                                      >
                                        {newMr.sentTo}
                                      </Box>{' '}
                                      on{' '}
                                      {moment(newMr.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                                    </Typography>
                                  ) : (
                                    <Typography variant='paragraph' component='p'>
                                      Model Release for{' '}
                                      {newMr.isMinor
                                        ? newMr.isDeceased
                                          ? 'minor deceased'
                                          : 'minor'
                                        : newMr.isDeceased
                                        ? 'adult deceased'
                                        : 'adult'}{' '}
                                      sent by {newMr.sentVia === 'email' ? 'email' : 'text message'}{' '}
                                      on{' '}
                                      {newMr.lastSentOn
                                        ? moment(newMr.lastSentOn, 'YYYY-MM-DD').format(
                                            'MMMM D, YYYY'
                                          )
                                        : moment(newMr.createdAt, 'YYYY-MM-DD').format(
                                            'MMMM D, YYYY'
                                          )}
                                    </Typography>
                                  )
                                ) : (
                                  <Typography variant='paragraph' component='p'>
                                    Model Release PDF for{' '}
                                    {newMr.isMinor
                                      ? newMr.isDeceased
                                        ? 'deceased minor'
                                        : 'minor'
                                      : newMr.isDeceased
                                      ? 'deceased adult'
                                      : 'adult'}{' '}
                                    uploaded on{' '}
                                    {moment(newMr.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                component={'div'}
                                className='model-release__list-history-mr-status--inactive'
                              >
                                <Typography variant='status' component={'div'}>
                                  {newMr.signed ? (
                                    <Box
                                      component={'span'}
                                      className='icon-text-span model-release__list--success'
                                      sx={{ display: 'flex' }}
                                    >
                                      {newMr.sentVia !== 'offline' ? (
                                        <>
                                          <CheckCircle size={16} style={{ marginTop: '2px' }} />{' '}
                                          Granted by{' '}
                                          {newMr.isMinor || newMr.isDeceased
                                            ? newMr.signeeFirstName + ' ' + newMr.signeeLastName
                                            : newMr.modelFirstName + ' ' + newMr.modelLastName}
                                          {newMr.signedOn
                                            ? ` on ${moment(newMr.signedOn, 'YYYY-MM-DD').format(
                                                'MMMM D, YYYY'
                                              )}`
                                            : null}
                                        </>
                                      ) : (
                                        <>
                                          <CheckCircle size={16} style={{ marginTop: '2px' }} /> PDF
                                          Uploaded
                                          {newMr.signedOn
                                            ? ` on ${moment(newMr.signedOn, 'YYYY-MM-DD').format(
                                                'MMMM D, YYYY'
                                              )}`
                                            : null}
                                        </>
                                      )}
                                    </Box>
                                  ) : newMr.signed === false ? (
                                    <Box
                                      className='icon-text-span model-release__list--blocked'
                                      sx={{ display: 'flex' }}
                                    >
                                      <Slash size={16} style={{ marginTop: '2px' }} /> Declined by{' '}
                                      {newMr.isMinor || newMr.isDeceased
                                        ? newMr.signeeFirstName + ' ' + newMr.signeeLastName
                                        : newMr.modelFirstName + ' ' + newMr.modelLastName}
                                      {newMr.signedOn
                                        ? ` on ${moment(newMr.signedOn, 'YYYY-MM-DD').format(
                                            'MMMM D, YYYY'
                                          )}`
                                        : null}
                                    </Box>
                                  ) : (
                                    <Box
                                      component={'span'}
                                      className='icon-text-span'
                                      sx={{ display: 'flex' }}
                                    >
                                      <Clock size={16} style={{ marginTop: '2px' }} /> Signer Has
                                      Not Responded(Expired){' '}
                                    </Box>
                                  )}
                                </Typography>
                                {newMr.signed ? (
                                  <Box component={'div'}>
                                    <Typography
                                      variant='h4'
                                      component={'div'}
                                      className='semi-strong'
                                    >
                                      {newMr.adminDecision === 'Pending' ? (
                                        ['appadmin'].includes(role) ? (
                                          <Box
                                            component={'span'}
                                            className='icon-text-span model-release__list--alert'
                                            sx={{ display: 'flex' }}
                                          >
                                            <AlertTriangle size={16} style={{ marginTop: '2px' }} />{' '}
                                            Approve or Reject Model Release{' '}
                                          </Box>
                                        ) : (
                                          <Box
                                            component={'span'}
                                            className='icon-text-span'
                                            sx={{ display: 'flex' }}
                                          >
                                            <Clock size={16} style={{ marginTop: '2px' }} /> Working
                                            Assumptions Is Reviewing Model Release{' '}
                                          </Box>
                                        )
                                      ) : newMr.adminDecision === 'Approve' ? (
                                        <Box
                                          component={'span'}
                                          className='icon-text-span model-release__list--success'
                                          sx={{ display: 'flex' }}
                                        >
                                          <CheckCircle size={16} style={{ marginTop: '2px' }} />{' '}
                                          Approved by Working Assumptions
                                          {newMr.adminDecisionOn
                                            ? ` on ${moment(
                                                newMr.adminDecisionOn,
                                                'YYYY-MM-DD'
                                              ).format('MMMM D, YYYY')}`
                                            : null}
                                        </Box>
                                      ) : newMr.adminDecision === 'Reject' ? (
                                        <Box
                                          component={'span'}
                                          className='icon-text-span model-release__list--blocked'
                                          sx={{ display: 'flex' }}
                                        >
                                          <Slash size={16} style={{ marginTop: '2px' }} /> Rejected
                                          by Working Assumptions
                                          {newMr.adminDecisionOn
                                            ? ` on ${moment(
                                                newMr.adminDecisionOn,
                                                'YYYY-MM-DD'
                                              ).format('MMMM D, YYYY')}`
                                            : null}
                                        </Box>
                                      ) : null}
                                    </Typography>
                                    {newMr.adminDecision === 'Reject' ? (
                                      <Typography
                                        component={'p'}
                                        variant='note'
                                        className='body text-break'
                                      >
                                        {newMr.adminRejectionNote ? newMr.adminRejectionNote : ''}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                ) : null}
                              </Box>
                              {/* {newMr.adminDecision === 'Reject' ? (
                                    <Box component={'div'}>
                                      <p className='body text-break'>
                                        {newMr.adminRejectionNote}
                                      </p>
                                    </Box>
                                  ) : null} */}
                            </Grid>
                            <Grid item xs={2}>
                              <ModelReleaseDropDown
                                modelRelease={newMr}
                                role={role}
                                setShowApprovalModal={() => {
                                  setOpMrUuid(newMr.uuid)
                                  setShowApprovalModal(true)
                                }}
                                setShowDeleteModal={() => {
                                  setOpMrUuid(newMr.uuid)
                                  setShowDeleteModal(true)
                                }}
                                setShowResendModal={() => {
                                  setOpMrUuid(newMr.uuid)
                                  setShowResendModal(true)
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                  </Box>
                </Collapse>
                <Box
                  component={'div'}
                  onClick={() => setOpenOne(!openOne)}
                  aria-expanded={openOne}
                  sx={{ mt: { xs: 3 }, display: 'flex', gap: 1, cursor: 'pointer' }}
                >
                  <Box component={'div'} className='photo-release__chevron'>
                    {openOne ? <ChevronUp /> : <ChevronDown />}
                  </Box>
                  <Box component={'div'}>
                    <Typography
                      variant='h4'
                      className='small_size strong'
                      sx={{ textDecoration: 'underline' }}
                    >
                      {openOne
                        ? 'Hide expired and rejected Model Releases for this model'
                        : 'Show expired and rejected Model Releases for this model'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ) : null}
          </>
        }
        <ApproveOrRejectFormModal
          uuid={modelRelease && modelRelease.uuid ? modelRelease.uuid : null}
          show={showApprovalModal}
          onHide={() => {
            setOpMrUuid(null)
            setShowApprovalModal(false)
          }}
          entityType='model'
          refetchPhoto={props.refetchPhoto}
          refetchMrData={props.refetchMrData}
        />
        <DeleteModelReleaseModal
          show={showDeleteModal}
          onHide={() => {
            setOpMrUuid(null)
            setShowDeleteModal(false)
          }}
          photoId={photoId}
          modelUuid={opMrUuid}
          assetId={modelRelease?.assetId}
          onSuccessCallback={() => setShowDeleteModal(false)}
          refetchPhoto={props.refetchPhoto}
          studentDetails={props.studentDetails}
          deleteType={deleteType}
          refetchMrData={props.refetchMrData}
        />
        <ResendModelRequestModal
          show={showResendModal}
          onHide={() => {
            setOpMrUuid(null)
            setShowResendModal(false)
          }}
          photoId={photoId}
          modelRelease={modelRelease}
          modelUuid={modelRelease && modelRelease.uuid ? modelRelease.uuid : null}
          onSuccessCallback={() => setShowResendModal(false)}
          refetchPhoto={props.refetchPhoto}
          studentDetails={props.studentDetails}
          refetchMrData={props.refetchMrData}
        />
      </Grid>
    </Box>
  )
}

export default ModelReleasesListRowSection
