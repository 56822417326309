import { gql } from '@apollo/client'

export const PHOTO_COLLECTION_QUERY = gql`
         query PaginatedGallery(
           $batchSize: Int!
           $baseBatchSize: Int!
           $step: Int!
           $filter: String!
           $type: String!
         ) {
           galleryConnection(
             batchSize: $batchSize
             baseBatchSize: $baseBatchSize
             step: $step
             filter: $filter
             type: $type
           ) {
             id
             caption
             attribution
             title
             altText
             url
             sizedUrl(screenWidth: 500)
             cdnOriginalUrl
             originalUrl
             originalTitle
             originalCaption
             downloadUrl
             createdAt
             submittedDate
             project {
               id
               student {
                 id
                 name
                 attribution
               }
               cohort {
                 id
                 reviewed
                 endDate
                 curatorReviews {
                   id
                   reviewerId
                 }
               }
             }
             exifData {
               size
               width
               height
               exposureProgram
               meteringMode
               iso
               lensModel
               focalLength
               cameraModel
               captureDate
               aperture
               shutterSpeedValue
               fileType
             }
             awards {
               awardClass
               id
             }
           }
           galleryConnectionPhotoIds(filter: $filter, type: $type) {
             id
           }
         }
       `


export const PHOTO_COLLECTION_COUNT_QUERY = gql`
  query sizeOfGallery($filter: String!, $type: String!) {
    gallerySize(filter: $filter, type: $type)
  }
`
