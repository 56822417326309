import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { truncateDetails } from '../../utils/commonFunctions'
import { Grid, Typography } from '@mui/material'
class UserBioApprovalTableRows extends Component {
  render() {
    const { user } = this.props
    return (
      <Grid container spacing={2} className='dashboard__info-list-rows'>
        <Grid item lg={8} md={7} sm={6} xs={12}>
          <Link to={`/user-details/${user.id}`}>
            <Typography variant='h4' className='semi-strong'>
              {' '}
              {truncateDetails(user.firstName + ' ' + user.lastName)}
            </Typography>
          </Link>
        </Grid>
        <Grid item lg={4} md={5} sm={6} xs={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Typography variant='h4'>
            {moment(user.updatedAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

export default UserBioApprovalTableRows
