import React, { Component, Fragment } from 'react'
import './projectprogress.scss'
import Button from '../elements/Button.js'
import moment from 'moment'
import { withApollo } from '@apollo/client/react/hoc'
import { Box, Typography, Grid, StepConnector } from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'

import { observer, inject } from 'mobx-react'
import SubmitProjectModal from '../molecules/SubmitProjectModal'
import ProjectPorgressPublicationSection from '../molecules/ProjectPorgressPublicationSection'
import DateUtils from '../../utils/DateUtils'

class ProjectProgress extends Component {
  userStore = this.props.userStore
  role = this.userStore.role

  constructor(props, context) {
    super(props, context)
    const birthDay =
      !!moment().diff(props.student.dob, 'years') === false
        ? 0
        : moment().diff(props.student.dob, 'years')
    this.state = {
      photos: props.photos,
      showProjectModel: false,
      submitted: props.submitted,
      age: birthDay
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      photos: nextProps.photos
    })
  }

  handleSubmit = (e, onMutate) => {
    e.preventDefault()
    onMutate({
      variables: {
        projectId: this.props.projectId,
        useAgreementType: this.state.age < 18 ? this.state.useAgreementType : 'Self',
        useAgreementValue: this.state.publishing
      },
      refetchQueries: [`ProjectDetails`]
    })
    this.setState({ submitted: true })
  }

  openSubmitProject = e => {
    e.preventDefault()
    this.setState({
      showProjectModel: true
    })
  }

  projectSubmitted = isSubmitted => {
    this.setState({
      submitted: isSubmitted
    })
  }

  render() {
    const {
      projectProgressHeader,
      photos,
      shortlistedPhotos,
      incompleteShortlistedPhotos,
      submittedOn,
      curatorsReviewed
    } = this.props

    const first = ['student'].includes(this.role)
      ? 'Upload photographs to your Library. Review them with your teacher and teaching artist. If you like, you can share them with your class too.'
      : 'The student should upload photographs to their Library and can review them with teaching staff.'

    const second = ['student'].includes(this.role)
      ? 'Begin choosing which photographs you might submit to Working Assumptions. Move photographs from your Library into your Shortlist'
      : 'The student must move the photographs that they are thinking about submitting to Working Assumptions to their Shortlist.'

    const third = ['student'].includes(this.role)
      ? 'Each photograph in your Shortlist needs a title and a caption before you can submit your project.'
      : 'Each photograph in the student’s Shortlist needs a title and a caption before they can submit their project.'

    const fourth = ['student'].includes(this.role)
      ? 'You’re ready to submit your project to Working Assumptions. Make sure to review your work with your teacher first!'
      : 'Have the student follow the on-screen instructions to submit their project.'

    const steps = [
      {
        label: 'Upload Photographs to Library',
        description: first
      },
      {
        label: 'Build Your Shortlist',
        description: second
      },
      {
        label: 'Write Titles and Captions',
        description: third
      },
      {
        label: 'Submit Your Project',
        description: fourth
      }
    ]

    const active_step =
      photos.length > 0
        ? shortlistedPhotos.length > 0
          ? incompleteShortlistedPhotos === 0
            ? 3
            : 2
          : 1
        : 0

    const { submitted } = this.state
    let closeProjectModel = () => this.setState({ showProjectModel: false })
    return (
      <Fragment>
        <Box
          sx={{
            background: '#f7f7f7',
            pb: 8,
            px: { xs: 4, md: 6 },
            pt: {
              xs: 5,
              sm: 5,
              md: 6,
              lg: 8
            }
          }}
        >
          <Box>
            <Box>
              <Typography variant='h2' className='strong'>
                {projectProgressHeader}
              </Typography>
            </Box>

            {['student', 'teacher', 'facilitator', 'appadmin'].includes(this.role) ? (
              <div
                className={
                  submitted ||
                  (!submitted &&
                    moment(this.props.cohort.endDate, 'YYYY-MM-DD').isBefore(
                      moment().format('YYYY-MM-DD')
                    ))
                    ? 'project-progress__submitted'
                    : 'project-progress__steps-section'
                }
              >
                {submitted &&
                !this.props.isSummarySend &&
                (!curatorsReviewed ||
                  (curatorsReviewed &&
                    ['student', 'teacher', 'facilitator'].includes(this.role))) ? (
                  <Grid container sx={{ mt: '1rem' }}>
                    <Grid item md={7}>
                      <Typography variant='paragraph' component='p'>
                        {['student'].includes(this.role) ? 'You' : 'The student'} submitted this
                        project to Working Assumptions on {moment(submittedOn).format('LL')}.
                      </Typography>
                      {['student'].includes(this.role) ? (
                        <Typography variant='paragraph' component='p'>
                          Our curators are reviewing your work. We will contact you when the review
                          is complete.
                        </Typography>
                      ) : ['teacher', 'facilitator'].includes(this.role) ? (
                        <Typography
                          variant='paragraph'
                          sx={{
                            mb: '1rem'
                          }}
                          component='p'
                        >
                          Our curators are reviewing all student work. We will contact the student,
                          teacher, and teaching artist for this class when the review is complete.
                        </Typography>
                      ) : ['appadmin'].includes(this.role) ? (
                        <Typography
                          variant='paragraph'
                          sx={{
                            mb: '1rem'
                          }}
                          component='p'
                        >
                          This project is in curatorial review. When the review is complete, you
                          will see a list of awarded photographs to process. Then you can notify the
                          student, teacher, and teaching artist about the awards.
                        </Typography>
                      ) : null}
                      {['student'].includes(this.role) ? (
                        <Typography variant='paragraph' component='p'>
                          Thank you for taking part in the <i>wrkxfmly</i> assignment!
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                ) : !submitted &&
                  DateUtils.isDatePassed(this.props.cohort.endDate) ? (
                  <Grid container sx={{ mt: '16px' }}>
                    <Grid item md={7}>
                      {['student'].includes(this.role) ? (
                        <>
                          <Typography variant='paragraph' component='p'>
                            Because the assignment submission due date for this class has passed,
                            you can no longer submit this project to Working Assumptions.
                          </Typography>
                          <Typography variant='paragraph' component='p'>
                            You can still view all of your work in the <strong>Photographs</strong>{' '}
                            tab.
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant='paragraph' component='p'>
                            This student did not submit their project by the assignment submission
                            due date.
                          </Typography>
                          {['teacher', 'facilitator'].includes(this.role) ? (
                            <Typography variant='paragraph' component='p'>
                              If multiple students in this class did not submit, please speak to
                              Working Assumptions about the possibility of extending the assignment
                              to give the students another chance to submit.
                            </Typography>
                          ) : ['appadmin'].includes(this.role) ? (
                            <Typography variant='paragraph' component='p'>
                              If multiple students in this class did not submit, you can speak to
                              the class teacher about moving back the assignment submission date to
                              give the students another chance to submit if the time to school
                              year-end allows.
                            </Typography>
                          ) : null}
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : submitted && this.props.isSummarySend ? (
                  <Grid container sx={{ mt: '16px' }}>
                    <Grid item md={7}>
                      {['student'].includes(this.role) ? (
                        this.props.hasWonAward ? (
                          <>
                            <Typography variant='paragraph' component='p'>
                              Congratulations! Our curators have reviewed your project, and you are one of the outstanding students whose photograph(s) received an award.
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography variant='paragraph' component='p'>
                              Our curators have reviewed your project, but your photograph(s) did
                              not receive an award.
                            </Typography>
                            <Typography variant='paragraph' component='p'>
                              Thank you for taking part in the <i>wrkxfmly</i> assignment. We hope
                              that your passion for photography continues to enrich your life.
                            </Typography>
                          </>
                        )
                      ) : (
                        <>
                          {this.props.hasWonAward ? (
                            <>
                              <Typography variant='paragraph' component='p'>
                                Our curators have reviewed this project, and the student’s
                                photograph(s) received an award.
                              </Typography>
                              {['appadmin'].includes(this.role) ? (
                                <Typography variant='paragraph' component='p'>
                                  {/* Please begin the Model Release review process by adding models and
                                  notes, where applicable, to each awarded photograph below. Then,
                                  go to the Class Review page to send the review summary for the
                                  class. */}
                                </Typography>
                              ) : null}
                            </>
                          ) : (
                            <>
                              <Typography variant='paragraph' component='p'>
                                Our curators have reviewed this project. The student’s photograph(s)
                                did not receive an award.
                              </Typography>
                              {['teacher', 'facilitator'].includes(this.role) ? (
                                <Typography
                                  variant='paragraph'
                                  sx={{
                                    mb: '1rem'
                                  }}
                                  component='p'
                                >
                                  The student has been notified by email at the email address
                                  supplied when they submitted this project, and they have been
                                  thanked for their participation in this assignment.
                                </Typography>
                              ) : ['appadmin'].includes(this.role) ? (
                                <Typography
                                  variant='paragraph'
                                  sx={{
                                    mb: '1rem'
                                  }}
                                  component='p'
                                >
                                  When you send the summary for this class, the student will be
                                  notified by email at the email address supplied when they
                                  submitted this project, and they will be thanked for their
                                  participation in this assignment.
                                </Typography>
                              ) : null}
                            </>
                          )}
                        </>
                      )}
                      {this.props.hasWonAward ? (
                        <ProjectPorgressPublicationSection
                          role={this.role}
                          photos={photos}
                          isStudentMinor={this.state.age < 18}
                          studentDetails={this.props.student}
                          projectId={this.props.projectId}
                          showToast={this.props.showToast}
                          refetchQueries={this.props.refetchQueries}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                ) : submitted &&
                  !this.props.isSummarySend &&
                  curatorsReviewed &&
                  ['appadmin'].includes(this.role) ? (
                  <Grid container sx={{ mt: '16px' }}>
                    <Grid item md={7}>
                      {this.props.hasWonAward ? (
                        <>
                          <Typography variant='paragraph' component='p'>
                            Our curators have reviewed this project, and the student’s photograph(s)
                            received an award.
                          </Typography>
                          <Typography variant='paragraph' component='p'>
                            Please begin the Model Release review process by adding models and
                            notes, where applicable, to each awarded photograph below. Then, go to
                            the Class Review page to send the review summary for the class.
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant='paragraph' component='p'>
                            Our curators have reviewed this project. The student’s photograph(s) did
                            not receive an award.
                          </Typography>
                          <Typography variant='paragraph' component='p'>
                            When you send the summary for this class, the student will be notified
                            by email at the email address supplied when they submitted this project,
                            and they will be thanked for their participation in this assignment.
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Box
                      sx={{
                        maxWidth: {
                          xs: '100%',
                          sm: '100%',
                          md: '58%',
                          lg: '58%',
                          xl: '58%'
                        },
                        mt: 4
                      }}
                    >
                      <Stepper
                        activeStep={active_step}
                        orientation='vertical'
                        connector={<StepConnector sx={{ minHeight: 3, ml: 2 }} />}
                      >
                        {steps.map((step, index) => (
                          <Step key={step.label}>
                            <StepLabel
                              StepIconProps={{
                                classes: {
                                  text: 'project-progress__step-text',
                                  root: 'project-progress__step-icon-text'
                                }
                              }}
                            >
                              <Box sx={{ ml: 2 }}>
                                <Typography variant='h3' className='semi-strong'>
                                  {step.label}
                                </Typography>
                              </Box>
                            </StepLabel>
                            <StepContent
                              classes={{
                                root: 'project-progress__step-content--root'
                              }}
                            >
                              <Box sx={{ pt: 1, pl: 2 }}>
                                <Box>
                                  <Typography variant='paragraph'>{step.description}</Typography>
                                </Box>
                                {submitted ||
                                (!submitted &&
                                  DateUtils.isDatePassed(this.props.cohort.endDate))
                                   ? null : index === 3 &&
                                  ['appadmin', 'student'].includes(this.role) ? (
                                  <Box sx={{ mt: 4, ml: {xs: -8.5, sm: 0 }, mr: {xs: -1, sm: 0}}}>
                                    <Button
                                      variant='contained'
                                      title={
                                        this.role === 'appadmin'
                                          ? 'Submit Project'
                                          : 'Submit Your Project'
                                      }
                                      onClick={e => this.openSubmitProject(e)}
                                      disabled={
                                        !(
                                          shortlistedPhotos.length > 0 &&
                                          incompleteShortlistedPhotos === 0
                                        )
                                      }
                                      sx={{
                                        width: { xs: '100%', sm: '50%', md: 'auto' }
                                      }}
                                    />
                                    <SubmitProjectModal
                                      show={this.state.showProjectModel}
                                      onHide={closeProjectModel}
                                      studentDetails={this.props.student}
                                      projectId={this.props.projectId}
                                      showToast={this.props.showToast}
                                      projectSubmitted={this.projectSubmitted}
                                      role={this.role}
                                    />
                                  </Box>
                                ) : null}
                              </Box>
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  </>
                )}
              </div>
            ) : null}
          </Box>
        </Box>
      </Fragment>
    )
  }
}

export default inject('userStore')(observer(withApollo(ProjectProgress)))
