import React, { Component } from 'react'
import './modals.scss'
import { observer, inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import { truncateDetails } from '../../utils/commonFunctions'
import {Grid, Typography} from '@mui/material'
import { Box } from '@mui/material'
import BaseModal from '../template/BaseModal';


class UserDetailsModal extends Component {
  render() {
    const {role} = this.props.userStore
    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);

    };

    return (
      <BaseModal
        {...this.props}
        // size='lg'
        // aria-labelledby='contained-modal-title-vcenter'
        // centered
        // show={this.props.show}
        // onClick={this.props.onClick}
        value={this.props.value}
        // modalClose={this.props.onHide}
        open={this.props.show}
        onClose={handleClose}
        onHide={this.props.onHide}
      >
        <BaseModal.BaseModalHeader className='user-details__header'>
          <Box id='contained-modal-title-vcenter' className='user-details__header__title'>
            <Typography variant="h3" className='strong'>{this.props.name}</Typography>
            <Typography variant="h4">{this.props.userType}</Typography>
          </Box>
        </BaseModal.BaseModalHeader>
        <BaseModal.BaseModalBody>
          <Box>
          {role==='student' ? (
             null
            ) : (
              <div>
                {/* <hr className="add-border"/> */}
                <div className='user-details__contact'>
                  <Typography variant="h4" className='semi-strong'>Contact Information</Typography>
                  
                  <Grid container justify="space-around" spacing={2} sx={{pb: role === 'appadmin' ? '0px' : '8px'}}>
                  {this.props.email ? (
                     <Grid item xs>
                        <Typography variant="h5">Email</Typography>
                        <a
                          href={'mailto:' + this.props.email}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <Typography variant="h4" className='semi-strong small_size'>{truncateDetails(this.props.email.trim(), 40)}</Typography>
                        </a>
                      </Grid>
                    ):
                    ( 
                    <Grid item xs>
                      <Typography variant="h5">Email</Typography>
                      <Typography variant="h4">None Provided</Typography>
                    </Grid>
                    )}
                      <Grid item xs>
                        <Typography variant="h5">Mobile Number</Typography>
                        <Typography variant="h4">{this.props.mobileNumber ? this.props.mobileNumber : 'None Provided'}</Typography>
                      </Grid>
                  </Grid>
                </div>
              </div>
            )}
          </Box>
            
              {
                role === 'appadmin' ? <Box sx={{padding:'24px 0 8px', marginTop:'0px', alignContent:'center'}} className='send-message__button'><Link to={`/user-details/${this.props.userId}`} onClick={() => this.props.tabChangeHandler('account-details')}>View User Profile</Link></Box> : null
              }

        </BaseModal.BaseModalBody>
      </BaseModal>
    )
  }
}

export default inject('userStore')(observer(UserDetailsModal))