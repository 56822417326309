import React, { useState } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import FormField from '../elements/FormField.js'
import { Typography, Grid, Box } from '@mui/material'
import _ from 'lodash'
import BaseModal from '../template/BaseModal';
import Validations from '../../utils/Validations'
import { validationText } from '../../content/validationText.js'
import { gql, useQuery, useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Spinner } from 'react-bootstrap'

const CheckUserEmailExist = gql`
  query CheckUserEmailExist($email: String!) {
    checkUserEmailExist(email: $email)
  }
`
const UpdateAccountEmail = gql`
  mutation UpdateAccountEmail($oldEmail: String!, $newEmail: String!) {
    updateAccountEmail(oldEmail: $oldEmail, newEmail: $newEmail) {
     __typename
    }
  }
`
const EditEmailAddressModal = props => {
  const [email, setEmail] = useState(props.user.email)
  const [errors, setErrors] = useState([])
  const [isFetchData, setIsFetchData] = useState(false)
  const { user, userStore } = props
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)
  
  const handleFormChange = e => {
    setIsSubmitDisabled(true)
    validateEmail(e.target.value)
  }

  const { refetch } = useQuery(CheckUserEmailExist, {
    skip: !isFetchData,
    variables: {
      email: email
    },
    onCompleted(data) {
      if (data.checkUserEmailExist === true)
      {
        setErrors({
          email: data.checkUserEmailExist
            ? validationText.user.error.newEmailExist
            : ''
        })
        setIsSubmitDisabled(true)
      }
    }
  })

  const validateEmail = (newEmail) => {
    setEmail(newEmail)
    setIsFetchData(true)
    const validEmail = Validations.isValidEmail(newEmail && newEmail.toLowerCase())
    if (validEmail && user.email !== newEmail) {
      setIsSubmitDisabled(false)
      setShowSpinner(false)
      setErrors([])
      refetch()
    }
    else {
      setErrors({ email: user.email === newEmail ? validationText.user.error.newEmailSameAsOld : validationText.user.error.email })
    }
  }

  const initialState = () => {
      setEmail(props.user.email)
      setErrors([])
      setShowSpinner(false)
  }

  const cancelProfileUpdate = () => {
    initialState()
    props.onHide();
  }

  const [updateUser] = useMutation(UpdateAccountEmail, {
    variables: {
      oldEmail: props.user.email,
      newEmail: email
    },
    async onCompleted() {
      props.onHide()
      setShowSpinner(false)
      await props?.fetchUserDetails?.()
      props.showToast('success', ((_.includes(['Student', 'Teacher', 'Facilitator', 'Curator'], props.user.role) || _.includes(['appadmin'], userStore.role)) && props.user.id === userStore.id) ?  "Verification link sent to "+ email +". Click on the link in the email to complete the verification process."  : "The user can now log in with their updated email address. A confirmation email was sent to both old and updated email addresses." )
    }
  })

  return (
    <BaseModal
      {...props}
      show={props.show}
      onClick={props.onClick}
      value={props.value}
      open={props.show}
      onClose={cancelProfileUpdate}
      onHide={cancelProfileUpdate}
    >
      <BaseModal.BaseModalHeader>
        {(_.includes(['Student', 'Teacher', 'Facilitator', 'Curator'], props.user.role) || _.includes(['appadmin'], userStore.role)) && props.user.id === userStore.id ? 'Update Your Account Email' : 'Update User Account Email'}{' '}
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Grid container>
          <Grid item className='delete-user__description'>
            <Typography
              variant='paragraph'
              sx={{
                mb: '1rem'
              }}
              component='p'
            >
              {(_.includes(['Student', 'Teacher', 'Facilitator', 'Curator'], props.user.role) || _.includes(['appadmin'], userStore.role)) && props.user.id === userStore.id ?
              "Enter a new email address below. This will replace your current email address when you log into your wrkxfmly account." : "If the user cannot access their account, you can update the account's primary email address on the user's behalf."} {' '}
            </Typography>
            <Typography
              variant='paragraph'
              sx={{
                mb: '1rem'
              }}
              component='p'
            >
              {(_.includes(['Student', 'Teacher', 'Facilitator', 'Curator'], props.user.role) || _.includes(['appadmin'], userStore.role)) && props.user.id === userStore.id ?
              "You will receive an email to the new address. Be sure to click the link in the email within 24 hours to verify the change of address." : "Be sure to ask the user for permission before you make this change."} {" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={7} className='class-details__grade-level-inputs'>
            <>
              <FormField
                formFieldLabel='New Email Address'
                formFieldVariant='filled'
                formFieldType='email'
                formFieldName='email'
                disabled={false}
                formFieldAutoComplete='email'
                formFieldValue={email || ''}
                onChange={handleFormChange}
                formFieldError={errors.email}
                className='custom-input-bg'
                />
            </>
          </Grid>
          <Grid item ></Grid>
        </Grid>
        <BaseModal.BaseModalFooter>
          <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
            <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant='contained'
                  type='copy'
                  title={showSpinner ? <span className='icon-text-span'>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> Updating... </span> : 'Update Email Address'}
                  disabled={isSubmitDisabled ? true : false}
                  onClick={() => {updateUser(); setIsSubmitDisabled(true); setShowSpinner(true) }}
                  sx={{width:{ xs: '100%', sm: '100%' }}}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={cancelProfileUpdate}>
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </BaseModal.BaseModalFooter>

      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

export default withApollo(EditEmailAddressModal)