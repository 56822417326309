import React, { Component, Fragment } from 'react'
import { gql } from '@apollo/client'
import './photoeditor.scss'
import { Typography } from '@mui/material'
import { observer, inject } from 'mobx-react'
import { Mutation, Query } from '@apollo/client/react/components'
import NakedFormField from '../elements/NakedFormField.js'
import Loading from '../elements/Loading.js'
import Button from '../elements/Button.js'

const QUERY = gql`
  query Photo($photoId: ID!) {
    photo(id: $photoId) {
      id
      caption
      title
      attribution
      url
      altText
      mrAdminNotes
      exifData {
        size
        width
        height
      }
      project {
        id
        submitted
      }
    }
  }
`

const MUTATION = gql`
  mutation UpdatePhotoMetadata($id: ID!, $title: String, $caption: String) {
    updatePhotoMetadata(id: $id, title: $title, caption: $caption) {
      id
      caption
      date
      title
      altText
      mrAdminNotes
    }
  }
`

class PhotoEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: {
        caption: false,
        altText: false,
        title: false
      },
      photo: {
        title: props.photo.title,
        caption: props.photo.caption
      },
      updated: true
    }
    this.storedPhoto = {
      title: props.photo.title,
      caption: props.photo.caption
    }
    this.titleInputRef = React.createRef()
    this.captionInputRef = React.createRef()
    this.cancleEvent = this.cancleEvent.bind(this)
  }

  componentDidMount() {
    [this.titleInputRef.current, this.captionInputRef.current].forEach(
      elm =>
        elm &&
        elm.classList.toggle(
          `${elm.value & (elm.value.trim() === '') ? 'greyed-out' : 'blackened-out'}`
        )
    )
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.photo.id !== nextProps.photo.id) {
      this.setState({
        ...this.state,
        editing: {
          caption: false,
          altText: false,
          title: false
        },
        photo: {
          title: nextProps.photo.title || '',
          caption: nextProps.photo.caption || ''
        },
        updated: true
      })
    }
  }

  handlePhotoAttributeChange = event => {
    const { name, value } = event.target
    const oldPhoto = { ...this.state.photo }
    if (name.toLowerCase() === 'caption' && value === '')
      event.target.setAttribute('placeholder', '')
    this.setState({
      ...this.state,
      photo: { ...oldPhoto, [name]: value },
      updated: false
    })
  }

  toggleEdit = key => {
    this.setState({
      editing: Object.assign(this.state.editing, {
        [key]: !this.state.editing[key]
      })
    })
  }

  adjustInputColor = elem => {
    if (!elem) return
    const type = elem.name.toLowerCase()
    if (this.state.photo[`${type}`] && this.state.photo[`${type}`].trim() === '') {
      const placeholder =
        type === 'title'
          ? 'Add a Title'
          : 'Write a caption about your photograph here. What does this image mean to you? What does it tell us about how work impacts your life and the lives of the people around you?'
      elem.setAttribute('placeholder', placeholder)
      elem.classList.replace('blackened-out', 'greyed-out')
    } else {
      elem.classList.replace('greyed-out', 'blackened-out')
    }
    this.toggleEdit(elem.name)
    this.props.toggleEditing(false)
  }

  clearElementText = elem => {
    this.toggleEdit(elem.name)
    const placeholder =
      elem.name.toLowerCase() === 'title'
        ? this.state.photo.title || ''
        : this.state.photo.caption || ''
    if (placeholder === '') elem.classList.replace('blackened-out', 'greyed-out')
    elem.setAttribute('placeholder', placeholder)
    this.props.toggleEditing(true)
  }

  mutateAttributeChange = (queryData, onMutate) => {
    let data = { ...queryData.photo }
    let elements = ['title', 'caption']
    data.id = this.props.photoId
    elements.forEach(type => {
      const elem = this[`${type}InputRef`].current
      // const updateTypeRequired = elem.value !== this.props.photo[`${type}`]
      data[`${type}`] = elem.value
      // data.id = this.props.photoId
      // if (updateTypeRequired) {
      //   onMutate({
      //     variables: data
      //   })
      // }
    })
    onMutate({
      variables: data
    })
    this.setState({
      ...this.state,
      editing: {
        caption: false,
        altText: false,
        title: false
      },
      photo: {
        title: this[`titleInputRef`].current.value,
        caption: this[`captionInputRef`].current.value
      },
      updated: true
    })
    this.props.refetchPhoto()
    this.props.updateParentGallery(queryData.photo)
  }

  cancleEvent = () => {
    this.setState({
      ...this.state,
      editing: {
        caption: false,
        altText: false,
        title: false
      },
      photo: {
        caption: this.storedPhoto.caption,
        title: this.storedPhoto.title
      }
    })
    this.props.refetchPhoto(this.props.photoId)
    this.props.showToast('All changes have been discarded.', 'blue')
  }

  render() {
    const { photoId, userStore } = this.props
    const { handlePhotoAttributeChange } = this
    const { role } = userStore

    return (
      <Query query={QUERY} variables={{ photoId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <Typography variant="paragraph" component="p">Error :(</Typography>
          if(data) {
            const queryData = data
            const { photo } = queryData

            const hasBeenSubmitted = photo.project.submitted
            const isEditable =
              !window.location.pathname.includes('national') &&
              ((['student'].includes(role) && !hasBeenSubmitted) || role === 'appadmin')
            return (
              <Mutation mutation={MUTATION}>
                {(onMutate, { data }) => (
                  <Fragment>
                    <div className='body__editor--active'>
                      <div>
                        <div
                          className={`${isEditable ? 'body__editor--title' : 'body__editor--title2'}`}
                        >
                          <Fragment>
                            <h1
                              className={`${
                                this.state.editing.title ? 'h1--editing' : 'h1--editable'
                              }`}
                            >
                              {isEditable ? (
                                <NakedFormField
                                  type='text'
                                  name='title'
                                  placeholder={this.state.photo.title || 'Add a Title'}
                                  fullWidth={true}
                                  onChange={handlePhotoAttributeChange}
                                  value={this.state.photo.title || ''}
                                  onBlur={() => this.adjustInputColor(this.titleInputRef.current)}
                                  onFocus={() => this.clearElementText(this.titleInputRef.current)}
                                  inputRef={this.titleInputRef}
                                />
                              ) : (photo.title ? photo.title : <div className='body__editor--placeholder'>Add a Title</div>
                              )}
                            </h1>
                          </Fragment>
                        </div>
                        <div className='body__editor--name'>
                          {queryData.photo.name === 'Anonymous' ? (
                            <h3>
                              Anonymous, {queryData.photo.attribution.year} ©
                              <i>All Rights Reserved</i>
                            </h3>
                          ) : (
                            <h3>
                              {queryData.photo.attribution.replace('NotSupplied', '')}, {queryData.photo.attribution.year} ©
                              <i> {queryData.photo.attribution.replace('NotSupplied', '')}</i>
                            </h3>
                          )}
                        </div>
                        <div
                          className={`${
                            isEditable ? 'body__editor--caption' : 'body__editor--caption2'
                          }`}
                        >
                          <Fragment>
                            <blockquote
                              className={`${
                                this.state.editing.caption
                                  ? 'blockquote--editing'
                                  : 'blockquote--editable'
                              }`}
                            >
                              {isEditable ? (
                                <NakedFormField
                                  multiline={true}
                                  fullWidth={true}
                                  type='text'
                                  name='caption'
                                  placeholder='Write a caption about your photograph here. What does this image mean to you? What does it tell us about how work impacts your life and the lives of the people around you?'
                                  onChange={handlePhotoAttributeChange}
                                  value={this.state.photo.caption || ''}
                                  onBlur={() => this.adjustInputColor(this.captionInputRef.current)}
                                  onFocus={() => this.clearElementText(this.captionInputRef.current)}
                                  inputRef={this.captionInputRef}
                                />
                              ) : (
                                photo.caption ? photo.caption : <div className='body__editor--placeholder'>Write a caption about your photograph here. What does this image mean to you? What does it tell us about how work impacts your life and the lives of the people around you?</div>
                              )}
                            </blockquote>
                          </Fragment>
                          {/* <a
                            rel='noopener noreferrer'
                            target='_blank'
                            href={
                              this.props.userStore.permaLinkedResources['Caption and Title Writing']
                            }
                          >
                            How to write a photograph caption
                          </a> */}
                          <br />
                          {isEditable ? (
                            <div className='photo-editor-page__button-section'>
                              <Button
                                title='Save Changes'
                                className='outline-buttons photo-editor-page'
                                variant='outlined'
                                onClick={() => this.mutateAttributeChange(queryData, onMutate)}
                                disabled={
                                  ((!this.state.editing.title && !this.state.editing.caption) && this.state.updated) ||
                                  !(
                                      (this.captionInputRef.current &&
                                        photo.caption !== this.captionInputRef.current.value.trim()) ||
                                      (this.titleInputRef.current &&
                                        photo.title !== this.titleInputRef.current.value.trim())
                                  )
                                }
                              />
                              {'  '}
                              { ((!this.state.editing.title && !this.state.editing.caption) && this.state.updated) ||
                                  !(
                                      (this.captionInputRef.current &&
                                        photo.caption !== this.captionInputRef.current.value.trim()) ||
                                      (this.titleInputRef.current &&
                                        photo.title !== this.titleInputRef.current.value.trim())
                                  )  ? null : <Typography variant="h4"className='semi-strong link' onClick={() => this.cancleEvent()}>
                                  Cancel
                                </Typography>}
                            </div>
                          ) : null}
                        </div>
                        <div />
                      </div>
                    </div>
                  </Fragment>
                )}
              </Mutation>
            )
          }
        }}
      </Query>
    )
  }
}

export default inject('userStore')(observer(PhotoEditor))
