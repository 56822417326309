import { useState } from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

const Switches = (props) => {
  const [checked, setChecked] = useState(false)

  const handleChange = (event) => {
    setChecked(event.target.checked)
  };

  return (
    <FormGroup sx={{alignItems:props.alignitems ? props.alignitems : null}}>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleChange}
            value="checked"
            {...props}
          />
        }
        className={props.className || ''}
        label={props.label}
        labelPlacement={props.placement}
      />
    </FormGroup>
  );
};

export default Switches
