import React from 'react';
import { observer, inject } from 'mobx-react';
import './resources.scss';
import Hero from '../ui/organisms/Hero.js';
import ResourceList from '../ui/organisms/ResourceList.js';
import Layout from '../ui/template/Layout';
import { withRouter } from '../utils/customHoc';

const Resources = ({ location, userStore, resourceStore, breadcrumbStore, basePath }) => {
  breadcrumbStore.resetBreadcrumb()
  breadcrumbStore.addBreadcrumb({
    pageName: 'Resources',
    link: location.pathname,
    basePath: basePath,
    isParent: true,
    isActive: true
  })
  return (
    <Layout>
      <Hero heroTitle='Resources' />
      <ResourceList resources={resourceStore.categories} />
    </Layout>
  );
};

export default inject('userStore', 'resourceStore', 'breadcrumbStore')(observer(withRouter(Resources)))
