import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Radio from '@mui/material/Radio'
import { Typography, Box, Grid } from '@mui/material'
import BaseModal from '../template/BaseModal'
import Button from '../elements/Button'
import PasswordFormField from '../elements/PasswordFormField'
import Cancel from '../elements/Cancel'
import { validationText } from '../../content/validationText'

const SEND_STUDENT_FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordRequest($id: ID) {
    forgotAccountPassword(id: $id) {
      errors {
        path
        message
      }
    }
  }
`

const UPDATE_PASSWORD_MUTATION = gql`
  mutation resetStudentPassword($newPassword: String!, $studentId: ID!) {
    resetStudentPassword(newPassword: $newPassword, studentId: $studentId) {
      errors {
        path
        message
      }
    }
  }
`

const ResetPasswordModal = props => {
  const [resetType, setResetType] = useState('email')
  const [errors, setErrors] = useState({})
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [studentId] = useState(props.studentId)

  const [sendStudentForgotPassword] = useMutation(SEND_STUDENT_FORGOT_PASSWORD_MUTATION, {
    onCompleted: () => {
      handleClose()
      props?.showToast('success', validationText.user.password.emailSent)
    }
  })

  const [resetStudentPassword] = useMutation(UPDATE_PASSWORD_MUTATION, {
    onCompleted: data => {
      checkUpdatePasswordRespForErrors(data)
      handleClose()
    }
  })

  const sendPasswordReset = e => {
    e.preventDefault()
    sendStudentForgotPassword({
      variables: {
        id: studentId
      }
    })
  }

  const handleResetTypeChange = type => {
    setResetType(type)
  }

  const handleUpdatePassword = e => {
    e.preventDefault()

    const passwordLengthError = validationText.user.password.format
    const passwordMismatchError = validationText.user.password.notmatch

    if (newPassword !== passwordConfirmation && newPassword.length < 8) {
      setErrors({
        ...errors,
        newPasswordError: passwordLengthError + ' ' + passwordMismatchError
      })
    }

    if (newPassword !== passwordConfirmation && newPassword.length > 8) {
      setErrors({ ...errors, newPasswordError: passwordMismatchError })
    }

    if (newPassword === passwordConfirmation && newPassword.length < 8) {
      setErrors({ ...errors, newPasswordError: passwordLengthError })
    }

    if (newPassword === passwordConfirmation && newPassword.length >= 8) {
      setErrors({ ...errors, newPasswordError: null })
      resetStudentPassword({
        variables: {
          newPassword,
          studentId
        }
      })
    }
  }

  const checkUpdatePasswordRespForErrors = response => {
    if (response.resetStudentPassword.errors) {
      props?.showToast('error', validationText.user.password.error)
      setErrors({ ...errors })
    } else {
      props?.showToast('success', validationText.user.password.success)
      setNewPassword('')
      setPasswordConfirmation('')
      setErrors({ newPasswordError: null, passwordConfirmationError: null })
    }
  }

  const checkForErrors = event => {
    const elem = event.target
    const { name, value } = elem
    let errorMsg = { ...errors }
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    if (name === 'newPassword') {
      errorMsg.newPasswordError =
        value.length < 8 || !specialChars.test(value) ? validationText.user.password.format : null
      if (passwordConfirmation) {
        errorMsg.passwordConfirmationError =
          passwordConfirmation !== value ? validationText.user.password.notmatch : null
      }
    }
    if (name === 'passwordConfirmation') {
      errorMsg.passwordConfirmationError =
        newPassword !== value ? validationText.user.password.notmatch : null
    }
    setErrors(errorMsg)
  }

  const handleClose = () => {
    setNewPassword('')
    setPasswordConfirmation('')
    setErrors({})
    setResetType('email')
    props?.onHide(false)
  }

  return (
    <BaseModal open={props.show} onClose={handleClose} onHide={handleClose}>
      <BaseModal.BaseModalHeader>Reset Student Password</BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Box>
          <Typography variant='paragraph' component='p'>
            Select an option below to help this student regain access to their account.
          </Typography>
        </Box>
        <Box>
          <form className='project-progress__form-container' noValidate autoComplete='on'>
            <div className='reset_password--radio__section--custom'>
              <div className='project-progress__form-field' id='margin__bottom__10px'>
                <div className='reset-student__password-security--reset-password'>
                  <FormControl component='fieldset'>
                    <RadioGroup aria-label='publish-type' name='publishType' row>
                      <FormControlLabel
                        value='checkedEmail'
                        required={true}
                        control={<Radio color='primary' checked={resetType === 'email'} />}
                        label={
                          <Typography variant='dataLabel'>
                            Have Working Assumptions send a reset link.
                          </Typography>
                        }
                        labelPlacement='end'
                        onChange={() => handleResetTypeChange('email')}
                      />
                      <FormControlLabel
                        value='checkedText'
                        required={true}
                        control={<Radio color='primary' />}
                        label={
                          <Typography variant='dataLabel'>
                            Enter a new password on behalf of the student.
                          </Typography>
                        }
                        labelPlacement='end'
                        checked={resetType === 'self'}
                        onChange={() => handleResetTypeChange('self')}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
          </form>
        </Box>

        {resetType === 'email' ? (
          <Box>
            <Typography
              variant='paragraph'
              sx={{
                mb: '1rem'
              }}
              component='p'
            >
              The student will receive an email to their registered email address. Ask the student
              to click the link in the email and follow the instructions to reset their password.
            </Typography>
            <BaseModal.BaseModalFooter>
              <Box
                sx={{
                  padding: '24px 0 0',
                  marginTop: '0px',
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row-reverse' },
                  textAlign: { xs: 'center', sm: 'start' }
                }}
              >
                <Button
                  variant='contained'
                  type='copy'
                  title='Send Reset Link'
                  disabled={false}
                  sx={{ width: { xs: '100%', sm: '33%' } }}
                  onClick={sendPasswordReset}
                />
                <Cancel
                  sx={{
                    mr: { sm: 2 },
                    mt: { xs: 2 },
                    width: 'max-content'
                  }}
                  onClick={handleClose}
                />
              </Box>
            </BaseModal.BaseModalFooter>
          </Box>
        ) : resetType === 'self' ? (
          <form className='my-account__form-container' noValidate autoComplete='off'>
            <Typography
              variant='paragraph'
              sx={{
                mb: '1rem'
              }}
              component='p'
            >
              If the student cannot access emails from Working Assumptions to their registered email
              address, you can reset the password on the student’s behalf.
            </Typography>
            <Typography
              sx={{
                fontSize: {
                  xs: '1.25rem',
                  sm: '1.25rem',
                  md: '1.125rem',
                  lg: '1.125rem',
                  xl: '1.125rem'
                },
                lineHeight: '1.2',
                fontFamily: 'Calibre,Helvetica,Verdana,sans-serif !important'
              }}
              className='reset_p_body'
            >
              Be sure to ask the student for permission before you reset their password.
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div className='form-field col--left'>
                  <PasswordFormField
                    onChange={event => {
                      setNewPassword(event.target.value)
                      checkForErrors(event)
                    }}
                    passwordFormFieldName='newPassword'
                    passwordFormFieldId='outlined-adornment-new-password'
                    passwordFormFieldVariant='filled'
                    passwordFormFieldAutoComplete='off'
                    passwordFormFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    passwordFormFieldLabel='New Password'
                    PasswordFormFieldValue={newPassword}
                    formFieldError={errors.newPasswordError || null}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ pl: { sm: '16px' } }}>
                <div className='form-field col--right'>
                  <PasswordFormField
                    onChange={event => {
                      setPasswordConfirmation(event.target.value)
                      checkForErrors(event)
                    }}
                    passwordFormFieldName='passwordConfirmation'
                    passwordFormFieldId='outlined-adornment-repeat-password'
                    passwordFormFieldVariant='filled'
                    passwordFormFieldAutoComplete='off'
                    PasswordFormFieldValue={passwordConfirmation}
                    passwordFormFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    passwordFormFieldLabel='Repeat New Password'
                    formFieldError={errors.passwordConfirmationError || null}
                  />
                </div>
              </Grid>
            </Grid>
            <BaseModal.BaseModalFooter>
              <Box
                sx={{
                  padding: '24px 0 0',
                  marginTop: '0px',
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row-reverse' },
                  textAlign: { xs: 'center', sm: 'start' }
                }}
              >
                <Button
                  onClick={e => handleUpdatePassword(e)}
                  type='submit'
                  disabled={
                    !(
                      newPassword &&
                      passwordConfirmation &&
                      !errors.newPasswordError &&
                      !errors.passwordConfirmationError
                    )
                  }
                  title='Reset Password'
                  variant='contained'
                  sx={{ width: { xs: '100%', sm: '33%' } }}
                />
                <Cancel
                  sx={{
                    mr: { sm: 2 },
                    mt: { xs: 2 },
                    width: 'max-content'
                  }}
                  onClick={handleClose}
                />
              </Box>
            </BaseModal.BaseModalFooter>
          </form>
        ) : null}
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

export default ResetPasswordModal
