import _ from 'lodash'
import Cancel from '../../elements/Cancel'
import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import Button from '../../elements/Button'
import variables from '../../settings/_variables.scss'
import { CheckCircle } from 'react-feather'
import FormField from '../../elements/FormField'
import { inject } from 'mobx-react'
import { useState } from 'react'
import { validationText } from '../../../content/validationText.js'
import { USER_UPDATE_MUTATION } from '../../../queries/userDetailsQueries.js'
import { useMutation } from '@apollo/client'
import { Spinner } from 'react-bootstrap'

const AttributionInfo = props => {
  const { user } = props
  const { profile } = user
  const role = profile.__typename
  const [attribution, setAttribution] = useState(user.profile.attribution)
  const [instagramHandle, setInstagramHandle] = useState(user.profile.instagramHandle)
  const [bioErrors, setBioErrors] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)

  const [updateUserMutation] = useMutation(USER_UPDATE_MUTATION, {
    variables: {
      id: user.id ? user.id : null,
      attribution: attribution,
      instagramHandle: instagramHandle
    },
    onCompleted(data) {
      props?.fetchUserDetails?.()
      setIsProcessing(false)
      props.showToast('success', validationText.user.success.update)
    }
  })

  const handleSubmit = async () => {
    try {
      setIsProcessing(true)
      await updateUserMutation()
    } catch (error) {
      console.error('Error updaing user:', error)
    }
  }

  const toggleAttribution = value => {
    setAttribution(value)
  }

  const handleFormChange = e => {
    setInstagramHandle(e.target.value)
    validateChange(e.target.value)
  }

  const validateChange = value => {
    let instaRegex = /@(?:[\w][\.]{0,1})*[\w]/
    if (!instaRegex.test(value)) {
      setBioErrors({
        ...bioErrors,
        instagramHandle: validationText.user.error.instagramHandle
      })
    } else {
      setBioErrors({
        ...bioErrors,
        instagramHandle: null
      })
    }
  }

  const cancelProfileUpdate = () => {
    setAttribution(user.profile.attribution)
    setInstagramHandle(user.profile.instagramHandle)
    setBioErrors([])
  }

  const isSubmitDisabled = () => {
    return (attribution === user.profile.attribution &&
      instagramHandle === user.profile.instagramHandle) ||
      Object.values(bioErrors).filter(val => val !== null).length > 0 ||
      isProcessing === true
      ? true
      : false
  }

  return _.includes(['Student'], role) ? (
    <Box>
      <form className='my-account__form-container' noValidate autoComplete='on'>
        <Box
          sx={{
            borderTop: `1px solid ${variables.lightGrey}`,
            pb: { xs: 7, sm: 10, md: 10, lg: 10 },
            pt: { xs: 7, sm: 10, md: 10, lg: 10 }
          }}
        >
          <Grid container>
            <Grid item md={7} sm={12} xs={12}>
              <Box sx={{ pb: 2 }}>
                <Typography variant='h2' component='h2'>
                  Attribution
                </Typography>
              </Box>
            </Grid>
            <Grid item md={7} sm={12} xs={12}>
              {props.userStore.role === 'student' ||
              props.userStore.role === 'facilitator' ||
              props.userStore.role === 'curator' ||
              props.userStore.role === 'teacher' ? (
                <Box>
                  <Typography variant='paragraph' component='p'>
                    Tell us how you would like to be credited should we publish your photographs.
                    This choice will apply to all of your photographs.
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography variant='paragraph' component='p'>
                    Tell us how the student wants to be credited should we publish their
                    photographs. This choice will apply to all of the student’s photographs.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 5, mb: 5 }}>
            <Grid container spacing={3}>
              <Grid item md={4} sm={6} xs={12}>
                <Button
                  variant={attribution === true ? 'selected-segmented' : 'segmented'}
                  onClick={() => toggleAttribution(true)}
                  boldTitle={
                    <Grid container alignItems='center'>
                      <Grid item md={10} xs={10} sm={10} sx={{ textAlign: 'left' }}>
                        <Typography variant='h4' className='table_header'>
                          {user.firstName + ' ' + user.lastName}
                        </Typography>
                      </Grid>
                      {attribution === true ? (
                        <Grid item md={2} xs={2} sm={2} sx={{ height: '20px' }}>
                          <CheckCircle color={`${variables.greenAccent}`} size={20} />
                        </Grid>
                      ) : null}
                    </Grid>
                  }
                  sx={{
                    width: '100%'
                  }}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Button
                  variant={attribution === false ? 'selected-segmented' : 'segmented'}
                  onClick={() => toggleAttribution(false)}
                  boldTitle={
                    <Grid container alignItems='center'>
                      <Grid item md={10} xs={10} sm={10} sx={{ textAlign: 'left' }}>
                        <Typography variant='h4' className='table_header'>
                          Anonymous
                        </Typography>
                      </Grid>
                      {attribution === false ? (
                        <Grid item md={2} xs={2} sm={2} sx={{ height: '20px' }}>
                          <CheckCircle color={`${variables.greenAccent}`} size={20} />
                        </Grid>
                      ) : null}
                    </Grid>
                  }
                  sx={{
                    width: '100%'
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {_.includes(['Student', 'Teacher'], role) ? (
            <Box sx={{ mb: 5 }}>
              <Grid container>
                <Grid item md={7} sm={12} xs={12}>
                  <Box sx={{ mb: 3 }}>
                    <Typography variant='paragraph' component='p'>
                      {_.includes(['appadmin'], props.userStore.role) ? (
                        <>
                          Please provide the student’s Instagram handle if they want to be mentioned
                          should we publish their work on our social media channels.
                        </>
                      ) : (
                        <>
                          Please provide your Instagram handle if you want to be mentioned should we
                          publish your work on our social media channels.
                        </>
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box>
                <Grid container spacing={3}>
                  <Grid item md={4} sm={6} xs={12}>
                    <FormField
                      formFieldId='instagram-username'
                      formFieldVariant='filled'
                      formFieldLabel='Instagram Username'
                      formFieldType='text'
                      formFieldName='instagramHandle'
                      onChange={handleFormChange}
                      formFieldValue={instagramHandle || ''}
                      formFieldError={bioErrors?.instagramHandle || null}
                      formFieldInputPropsStart={<InputAdornment position='start'>@</InputAdornment>}
                      className='custom-input-bg'
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ) : null}
          <Box
            sx={{
              display: {
                xs: 'block',
                sm: 'flex',
                md: 'flex'
              }
            }}
          >
            <Button
              title={
                isProcessing ? (
                  <span className='icon-text-span'>
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />{' '}
                    Saving...{' '}
                  </span>
                ) : (
                  'Save Changes'
                )
              }
              onClick={handleSubmit}
              disabled={isSubmitDisabled()}
              sx={{ width: { xs: '100%', sm: 'auto', md: 'auto' } }}
            />
            <Box
              sx={{
                ml: { xs: 0, sm: 2, md: 2 },
                p: { xs: 3, sm: 2, md: 2 },
                textAlign: { xs: 'center', sm: 'left', md: 'left' }
              }}
            >
              <Cancel onClick={cancelProfileUpdate} showToast={props.showToast} />
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  ) : null
}

export default inject('userStore')(AttributionInfo)
