import React from 'react'
import TeacherAwardResultsRow from '../molecules/TeacherAwardResultsRow'
import { getTextContentByPage } from '../../content/textContent'
import { Box, Grid, Typography } from '@mui/material'

const TeacherAwardResults = props => {
  return (
    <Box sx={{ mt: { xs: 5, md: 6, lg: 10 }, px: { xs: 4, md: 6 }, pb: 4 }}>
      <Typography variant='h1' className='bold'>
        Students with Awards ({props.awardedProjects.length})
      </Typography>

      <Typography variant='paragraph' component='p' sx={{ mt: 2 }}>
        {getTextContentByPage('CLASSDETAILS', props.role.toUpperCase(), 'AWARDRESULT_INTRO')}
      </Typography>

      {props.awardedProjects.length > 0 && (
        <Box
          sx={{
            display: { xs: 'none', md: 'block' },
            mt: { md: props.awardedProjects.length > 0 ? 5 : 'auto' }
          }}
        >
          <Grid container spacing={2} sx={{ ml: 0 }}>
            <Grid item md={4}>
              <Typography variant='dataLabel' component={'span'}>
                Student Name
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant='dataLabel' component={'span'}>
                Award of Excellence
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant='dataLabel' component={'span'}>
                Honorable Mention
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        sx={{
          mt: {
            xs: props.awardedProjects.length > 0 ? 5 : 'auto',
            md: props.awardedProjects.length > 0 ? 1 : 'auto'
          },
          ml: {
            xs: -4,
            md: 'auto'
          },
          mr: {
            xs: -2,
            md: 'auto'
          }
        }}
      >
        {props.awardedProjects.map(project => (
          <TeacherAwardResultsRow project={project} showToast={props.showToast} key={project.id}/>
        ))}
      </Box>
    </Box>
  )
}

export default TeacherAwardResults
