import { useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import FilledInput from '@mui/material/FilledInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import variable from '../settings/_variables.scss'


const StyledFormControl = styled(FormControl)({
  width: '100%',
})

const PasswordFormField = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <>
      <StyledFormControl variant={props.passwordFormFieldVariant} error={!!props.formFieldError}>
        <InputLabel htmlFor={`${props.passwordFormFieldVariant}-adornment-password`} sx={{  color: props.formFieldError ? `${variable.redError} !important` : `${variable.gery53}` }}>{props.passwordFormFieldLabel}</InputLabel>
        <FilledInput
          id={props.passwordFormFieldId}
          label={props.passwordFormFieldLabel}
          variant={props.passwordFormFieldVariant}
          autoComplete={props.passwordFormFieldAutoComplete}
          name={props.passwordFormFieldName}
          type={showPassword ? 'text' : 'password'}
          onChange={props.onChange}
          value={props.PasswordFormFieldValue || ''}
          margin='dense'
          fullWidth
          error={props.formFieldError ? true : false}
          inputprops={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='Toggle password visibility'
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          inputlabelprops={{
            shrink: true,
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          sx={{
            ...props.sx,
            svg: { color: props.formFieldError ? `${variable.redError}` : `${variable.gery53}` },
          }}
        />
      </StyledFormControl>
      {props.formFieldError ? (
        <FormHelperText className='component-error-text'>{props.formFieldError}</FormHelperText>
      ) : null}
    </>
  )
}

PasswordFormField.propTypes = {
  passwordFormFieldId: PropTypes.string.isRequired,
  passwordFormFieldLabel: PropTypes.string.isRequired,
  passwordFormFieldVariant: PropTypes.oneOf(['standard', 'filled', 'outlined']),
  passwordFormFieldAutoComplete: PropTypes.string,
  passwordFormFieldName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  // PasswordFormFieldValue: PropTypes.string.isRequired,
  formFieldError: PropTypes.string,
}

export default PasswordFormField
