import React, { Component, Fragment } from 'react'
import { ArrowRight } from 'react-feather'
import { Link } from 'react-router-dom'
import './termsprivacy.scss'
import Layout from '../ui/template/Layout'
import { Typography } from '@mui/material'



class WebTermsEnglish extends Component {
  render() {
    return (
      <Layout>
        <div className='container__public-body'>
          <div className='public-body__terms-and-policies'>
            <div className='terms-and-policies__hero'>
              <div className='terms-and-policies__hero--header'>
                <h4 className='h4--semi-strong'><Link to='/web-terms-english'>English</Link> / <Link to='/web-terms-espanol'>Español</Link></h4>
                <h1 className='h1--strong public'>Website Terms and Policies</h1>
              </div>
              <div className='terms-and-policies__hero--copy'>
                <h2 className='h2--medium'>
                For terms and policies relating to the student web application, please see <strong><Link to='/app-terms-english'>Application Terms and Policies</Link></strong>.
                </h2>
                <br />
                <h3 className='h3--semi-strong'><Link to="/web-terms-english">Website Terms of Use <ArrowRight size={21} /></Link></h3>
                <h3 className='h3--semi-strong'><Link to="/web-privacy-english">Website Privacy Policy <ArrowRight size={21} /></Link></h3>
              </div>
            </div>

            <div className='terms-and-policies__content'>
              <h1 className='h1--semi-strong'>Website Terms of Use</h1>
              
              <div className='terms-and-policies__content--section'>
                <div className='terms-and-policies__content--description'>
                  <h2 className='h2--semi-strong'>Section Name</h2>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                    >Longer legal text, in plain English. Cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
  Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Donec ullamcorper nulla non metus auctor fringilla. Etiam porta sem malesuada magna mollis euismod. Donec id elit non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed consectetur. Maecenas faucibus mollis interdum.</Typography>
                </div>
                <div className='terms-and-policies__content--summary'>
                  <h4 className='h4--semi-strong'>
                  What This Means
                  </h4>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                    >Brief summary of section. Donec sed odio dui. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Donec id elit non mi porta gravida at eget metus.</Typography>
                </div>
              </div>

              <div className='terms-and-policies__content--section'>
                <div className='terms-and-policies__content--description'>
                  <h2 className='h2--semi-strong'>Section Name</h2>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                    >Longer legal text, in plain English. Cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
  Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Donec ullamcorper nulla non metus auctor fringilla. Etiam porta sem malesuada magna mollis euismod. Donec id elit non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed consectetur. Maecenas faucibus mollis interdum.</Typography>
                </div>
                <div className='terms-and-policies__content--summary'>
                  <h4 className='h4--semi-strong'>
                  What This Means
                  </h4>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                    >Brief summary of section. Donec sed odio dui. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Donec id elit non mi porta gravida at eget metus.</Typography>
                </div>
              </div>

              <div className='terms-and-policies__content--section'>
                <div className='terms-and-policies__content--description'>
                  <h2 className='h2--semi-strong'>Section Name</h2>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                    >Longer legal text, in plain English. Cursus magna, vel scelerisque nisl consectetur et. Cras mattis consectetur purus sit amet fermentum. Curabitur blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.
  Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Donec ullamcorper nulla non metus auctor fringilla. Etiam porta sem malesuada magna mollis euismod. Donec id elit non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed consectetur. Maecenas faucibus mollis interdum.</Typography>
                </div>
                <div className='terms-and-policies__content--summary'>
                  <h4 className='h4--semi-strong'>
                  What This Means
                  </h4>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                    >Brief summary of section. Donec sed odio dui. Integer posuere erat a ante venenatis dapibus posuere velit aliquet. Donec id elit non mi porta gravida at eget metus.</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default WebTermsEnglish
