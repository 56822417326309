import React, { Fragment, useState } from 'react'
import './login.scss'
import Button from '../ui/elements/Button'
import PasswordFormField from '../ui/elements/PasswordFormField.js'
import FormField from '../ui/elements/FormField.js'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'
import { Box, Grid, Typography } from '@mui/material'
import {
  QUERY,
  RESET_PASSWORD_MUTATION,
  FORGOT_PASSWORD_MUTATION
} from './../queries/ResetPasswordQueries'
import ToastNotification from '../ui/elements/ToastNotification'
import { withRouter } from '../utils/customHoc'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { Spinner } from 'react-bootstrap'

const ResetPassword = props => {
  const { token } = useParams()
  const navigate = useNavigate()

  const [errors, setErrors] = useState({})
  const [email, setEmail] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [passwordResetSent, setPasswordResetSent] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastType, setToastType] = useState('')
  const [isTokenValid, setIsTokenValid] = useState(false)

  const { loading, error } = useQuery(QUERY, {
    variables: {
      token
    },
    onCompleted(data) {
      setIsTokenValid(data?.passwordResetToken || false)
    }
  })

  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION, {
    variables: {
      token: token,
      newPassword: newPassword
    },
    onCompleted() {
      showToastMsg('success', 'Your password was reset. Please log in to continue.')
      setSubmitted(false)
      sessionStorage.setItem('resetToast', true)
      navigate('/login')
    }
  })

  const [forgotPassword] = useMutation(FORGOT_PASSWORD_MUTATION, {
    variables: {
      email: email
    },
    onCompleted(data) {
      checkResponseForErrors(data)
      setSubmitted(false)
    }
  })

  const handleSubmit = () => {
    setSubmitted(true)
    forgotPassword()
  }

  const checkResponseForErrors = data => {
    if (data.forgotAccountPassword.errors) {
      setPasswordResetSent(false)
    } else {
      setPasswordResetSent(true)
    }
  }

  const validateEmail = testString => {
    var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
    setErrors({ email: '' })
    if (!emailpattern.test(testString)) {
      setErrors({ email: 'Please enter a valid email address' })
    } else {
      setErrors({})
      setErrors({ email: '' })
    }
  }

  const handleFormChange = e => {
    if (e.target.name === 'email') {
      validateEmail(e.target.value)
      setEmail(e.target.value)
    }
    if (e.target.name === 'newPassword') {
      setNewPassword(e.target.value)
      validateChange(e)
    }
    if (e.target.name === 'passwordConfirmation') {
      setPasswordConfirmation(e.target.value)
      validateChange(e)
    }
  }

  const validateChange = e => {
    const fieldName = e.target.name
    const value = e.target.value

    switch (fieldName) {
      case 'newPassword':
        validatePassword(value, 'newPassword')
        break
      case 'passwordConfirmation':
        validatePassword(value, 'passwordConfirmation')
        break
      default:
        return
    }
  }

  const validatePassword = (value, element = 'newPassword') => {
    const passwordLengthError = 'Passwords need to be at least 8 characters long.'
    const passwordMismatchError = 'Passwords do not match.'
    const passwordValue = element === 'newPassword' ? value : newPassword
    const passwordConfirmValue = element !== 'newPassword' ? value : passwordConfirmation
    const length = value.length

    if (element === 'newPassword') {
      if (passwordValue && length < 8) {
        setErrors({ ...errors, newPasswordError: passwordLengthError })
      }

      if (passwordValue && length >= 8) {
        setErrors({ ...errors, newPasswordError: '' })
      }

      if (passwordConfirmValue && passwordValue !== passwordConfirmValue && length < 8) {
        setErrors({
          ...errors,
          newPasswordError: passwordLengthError,
          passwordConfirmationError:
            passwordConfirmValue.length < 8
              ? passwordLengthError + ' ' + passwordMismatchError
              : passwordMismatchError
        })
      }

      if (passwordConfirmValue && passwordValue !== passwordConfirmValue && length >= 8) {
        setErrors({
          ...errors,
          newPasswordError: '',
          passwordConfirmationError:
            passwordConfirmValue.length >= 8
              ? passwordMismatchError
              : passwordLengthError + ' ' + passwordMismatchError
        })
      }
    } else {
      if (passwordConfirmValue && passwordValue !== passwordConfirmValue && length < 8) {
        setErrors({
          ...errors,
          passwordConfirmationError: passwordLengthError + ' ' + passwordMismatchError
        })
      }

      if (passwordConfirmValue && passwordValue === passwordConfirmValue && length < 8) {
        setErrors({ ...errors, passwordConfirmationError: passwordLengthError })
      }

      if (passwordConfirmValue && passwordValue !== passwordConfirmValue && length >= 8) {
        setErrors({ ...errors, passwordConfirmationError: passwordMismatchError })
      }
    }

    if (passwordValue === passwordConfirmValue && length >= 8) {
      setErrors({ ...errors, newPasswordError: '', passwordConfirmationError: '' })
      setIsDisabled(false)
    } else if (passwordValue && passwordValue === passwordConfirmValue && length < 8) {
      setErrors({
        ...errors,
        newPasswordError: passwordLengthError,
        passwordConfirmationError: passwordLengthError
      })
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowToast(false)
  }

  const showToastMsg = (toastType, message = '') => {
    setShowToast(true)
    setToastMessage(message)
    setToastType(toastType)
  }

  const handleUpdatePassword = () => {
    setSubmitted(true)
    setIsDisabled(true)
    resetPassword()
  }

  if (loading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    )
  }

  if (error) {
    return (
      <Layout>
        <Typography variant='paragraph' component='p'>
          Error loading page
        </Typography>
      </Layout>
    )
  }

  return (
    <Layout>
      {isTokenValid ? (
        <>
          {showToast === true && (
            <ToastNotification
              notificationType={toastType}
              showToast={showToast}
              onClose={handleClose}
              toastMessage={toastMessage}
            />
          )}
          <Box component={'div'} className='container__body--login'>
            <Box
              className='container__login'
              sx={{ px: { md: 13, lg: 15 }, pt: { xs: 2, md: 8 }, pb: { xs: 4 } }}
            >
              <Box className='login__header'>
                <Typography
                  variant='h1'
                  component='h1'
                  className='bold'
                  sx={{ mt: { xs: 3, sm: 8, md: 0 } }}
                >
                  Reset your password.
                </Typography>
              </Box>
              <Box sx={{ mt: { xs: 2 } }}>
                <Typography variant='paragraph' component='p' sx={{ mb: { xs: 5 } }}>
                  Choose a password that you can easily remember and that is at least 8 characters
                  long.
                </Typography>
                <Grid
                  container
                  alignItems='center'
                  spacing={5}
                  paddingLeft={{ xs: 0 }}
                  mb={{ xs: 1, sm: 6 }}
                >
                  <Grid item xs={12}>
                    <PasswordFormField
                      passwordFormFieldId='newPassword'
                      passwordFormFieldVariant='filled'
                      passwordFormFieldName='newPassword'
                      passwordFormFieldType='password'
                      passwordFormFieldLabel='New Password'
                      PasswordFormFieldValue={newPassword}
                      onChange={event => handleFormChange(event)}
                      formFieldError={errors.newPasswordError || null}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordFormField
                      passwordFormFieldId='passwordConfirmation'
                      passwordFormFieldVariant='filled'
                      passwordFormFieldName='passwordConfirmation'
                      passwordFormFieldType='password'
                      passwordFormFieldLabel='Repeat New Password'
                      PasswordFormFieldValue={passwordConfirmation}
                      onChange={event => handleFormChange(event)}
                      formFieldError={errors.passwordConfirmationError || null}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mt={{
                      xs:
                        errors.newPasswordError || errors.passwordConfirmationError
                          ? '-10px'
                          : '20px'
                    }}
                  >
                    <Button
                      variant='contained'
                      onClick={handleUpdatePassword}
                      disabled={
                        !newPassword ||
                        !passwordConfirmation ||
                        errors.newPasswordError ||
                        errors.passwordConfirmationError ||
                        newPassword !== passwordConfirmation ||
                        isDisabled
                      }
                      fullWidth
                      sx={{
                        width: { xs: '100%', sm: '33%', md: 'auto' }
                      }}
                      title={
                        submitted ? (
                          <span className='icon-text-span'>
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />{' '}
                            Reset Password
                          </span>
                        ) : (
                          'Reset Password'
                        )
                      }
                      lableFontClass='strong'
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box component={'div'} className='container__body--login'>
            <Box
              className='container__login'
              sx={{
                px: { sm: 10, md: 13, lg: 15 },
                pt: { xs: '40px', sm: '80px !important', md: '80px !important' },
                pb: { xs: '40px', sm: '80px !important', md: '80px !important' }
              }}
            >
              {/* TO DO: MAKE THIS ACTUALLY SHOW WHEN FORM FOR PASSWORD RESET IS SUBMITTED!! */}
              {passwordResetSent ? (
                <Box className='login__header'>
                  <Typography
                    variant='h1'
                    component='h1'
                    className='bold'
                    sx={{ mt: { xs: 3, sm: 8 }, mb: { xs: 3, sm: 8 } }}
                  >
                    Reset link sent.
                  </Typography>
                  <Typography
                    variant='paragraph'
                    sx={{
                      mb: '1rem'
                    }}
                    component='p'
                  >
                    A link to reset your password has been sent to {email}.
                  </Typography>
                  <Typography
                    variant='paragraph'
                    sx={{
                      mb: '1rem'
                    }}
                    component='p'
                  >
                    If you do not receive an email soon, please check your spam folder or contact{' '}
                    <a href='mailto:education@workingassumptions.org'>
                      education@workingassumptions.org
                    </a>{' '}
                    for help.
                  </Typography>
                </Box>
              ) : (
                <>
                  <Box>
                    <Typography variant='h2' className='strong' sx={{ mb: { xs: 2, md: 3 } }}>
                      This reset link has expired.
                    </Typography>
                    <Typography variant='paragraph' component={'p'}>
                      Please enter the email address for your account to request a new password
                      reset link below.
                    </Typography>
                  </Box>
                  <Grid container alignItems='center' spacing={5} paddingLeft={{ xs: 0 }}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sx={{
                        mt: { xs: 5 },
                        mb: {
                          xs: errors && errors.email ? '10px !important' : '0px !important',
                          md: errors && errors.email ? '10px !important' : '0px !important'
                        }
                      }}
                    >
                      <FormField
                        formFieldId='outlined-email'
                        formFieldLabel='Email'
                        formFieldVariant='filled'
                        formFieldClasses={{
                          root: 'text-field__outline'
                        }}
                        formFieldType='email'
                        formFieldName='email'
                        formFieldError={errors ? errors.email : ''}
                        onChange={event => handleFormChange(event)}
                        formFieldValue={email}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={!email || (errors?.email ? true : false) || submitted}
                        title={
                          submitted ? (
                            <span className='icon-text-span'>
                              <Spinner
                                as='span'
                                animation='border'
                                size='sm'
                                role='status'
                                aria-hidden='true'
                              />{' '}
                              Send Reset Link
                            </span>
                          ) : (
                            'Send Reset Link'
                          )
                        }
                        sx={{
                          width: { xs: '100%', sm: '40%', md: 'auto' }
                        }}
                      >
                        Send Reset Link
                      </Button>
                    </Grid>
                    <Grid
                      item
                      className='login__disclaimer'
                      xs={12}
                      sx={{
                        pt: {
                          xs: '40px',
                          sm: '80px !important',
                          md: '80px !important'
                        },
                        pl: { xs: '40px', sm: 0 }
                      }}
                    >
                      <Typography variant='h4'>
                        If you can't access the email address for your account, please contact
                        {'  '}
                        <a
                          style={{ fontSize: '17px' }}
                          href='mailto:education@workingassumptions.org'
                        >
                          education@workingassumptions.org
                        </a>
                        .
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Layout>
  )
}

export default withRouter(ResetPassword)
