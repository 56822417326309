import React, { Component } from 'react'
import { Box, Typography } from '@mui/material'
import './button.scss'
import { Award, Shield, Slash, Upload } from 'react-feather'
import { Button as MuiButton, styled } from '@mui/material'
import variables from '../settings/_variables.scss'

const StyledButton = styled(MuiButton)`
  border-radius: 0px;
  box-shadow: none;
`

class Button extends Component {
  render() {
    return (
      <StyledButton
        component={this.props?.component || 'button'}
        to={this.props?.to || undefined}
        disableElevation
        variant={this.props.variant || 'outlined'}
        className={this.props.className}
        disabled={
          this.props.disabled !== undefined
            ? this.props.disabled
            : this.props.onClick
            ? false
            : true
        }
        onClick={this.props.onClick}
        sx={{
          borderColor: `${variables.blueAccent}`,
          textTransform: 'none',
          width: this.props.btnWidth || 'auto',
          height: this.props.btnHeight || 'auto',
          p: {
            xs: 2,
            sm: 2,
            md: '12px 16px',
            lg: '12px 16px'
          },
          lineHeight: 1.2,
          ...this.props.sx
        }}
        size={this.props.size || 'large'}
        color={this.props.color}
        type={this.props.type || 'button'}
      >
        {this.props.title ? (
          <Box component='span' className='button__title' sx={{ display: 'flex' }}>
            {this.props.icon ? (
              <Box className='button__icon' sx={{ mr: 1 }}>
                {this.props.icon === 'Award' ? (
                  <Award />
                ) : this.props.icon === 'Shield' ? (
                  <Shield />
                ) : this.props.icon === 'Slash' ? (
                  <Slash />
                ) : this.props.icon === 'Upload' ? (
                  <Upload size={16} />
                ) : null}
              </Box>
            ) : null}

            <Typography
              variant='h4'
              className={`btn-label ${
                this.props.lableFontClass ? this.props.lableFontClass : 'semi-strong'
              }`}
            >
              {this.props.title}
            </Typography>
          </Box>
        ) : null}
        {this.props.boldTitle ? (
          <Box sx={{ width: '100%' }}>
            <Typography variant='paragraph' component='div' className='strong'>
              {this.props.boldTitle}
            </Typography>
          </Box>
        ) : null}
        {this.props.subTitle ? (
          <div className='button__subtitle'>
            <Typography variant='paragraph' component='p'>
              {this.props.subTitle}
            </Typography>
          </div>
        ) : null}
      </StyledButton>
    )
  }
}

export default Button
