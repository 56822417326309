import React, { Component } from 'react'
import { Info } from 'react-feather'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Typography } from '@mui/material'
import moment from 'moment'
import { formatBytes } from '../../utils/commonFunctions'

const NOT_AVAILABLE_TEXT = 'Not Available'

class AdminPhotoMeta extends Component {
  renderStudentProject = photo => {
    if(this.props.toggleParentModalState) this.props.toggleParentModalState()
    const projectDetails = window.location.pathname.includes('project-detail')
    if (!projectDetails) {
      setTimeout(() => {
        window.location = `/project-detail/${photo.project.id}`
      }, 500)
    }
  }
  render() {
    const queryData = this.props.photo.exifData
    const { role } = this.props
    const shouldShow = ['appadmin', 'teacher', 'facilitator', 'curator'].includes(role)
    return shouldShow ? (
      <div className={`body__photo-meta ${this.props.isStudent ? '' : 'body__photo-meta--admin'}`}>
        <OverlayTrigger
          trigger='hover'
          placement='top'
          overlay={
            <Popover id='popover-positioned-top'>
              <div className='popover__section'>
                <div className='popover__title'>
                  Technical Information
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>File Type</span> <span className='popover__detail--content'>{queryData && queryData.fileType ? queryData.fileType : NOT_AVAILABLE_TEXT}</span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>File Size</span> <span className='popover__detail--content'>{queryData && queryData.size ? formatBytes(queryData.size) : NOT_AVAILABLE_TEXT} </span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Dimensions</span> <span className='popover__detail--content'>{queryData && queryData.width && queryData.height ? queryData.width + ' x ' + queryData.height : NOT_AVAILABLE_TEXT}</span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Exposure</span> <span className='popover__detail--content'>{queryData && queryData.shutterSpeedValue && queryData.aperture ? queryData.shutterSpeedValue + ' sec at f/' + queryData.aperture : NOT_AVAILABLE_TEXT}</span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Exposure Program</span> <span className='popover__detail--content'>{queryData && queryData.exposureProgram ? queryData.exposureProgram : NOT_AVAILABLE_TEXT}</span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Metering Mode</span> <span className='popover__detail--content'>{queryData && queryData.meteringMode ? queryData.meteringMode : NOT_AVAILABLE_TEXT}</span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>ISO Speed Rating</span> <span className='popover__detail--content'>{queryData && queryData.iso ? 'ISO ' + queryData.iso : NOT_AVAILABLE_TEXT}</span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Focal Length</span> <span className='popover__detail--content'>{queryData && queryData.focalLength ? queryData.focalLength : NOT_AVAILABLE_TEXT}</span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Lens</span> <span className='popover__detail--content'>{queryData && queryData.lensModel ? queryData.lensModel : NOT_AVAILABLE_TEXT}</span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Camera Make & Model</span> <span className='popover__detail--content'>{queryData && queryData.cameraModel ? queryData.cameraModel : NOT_AVAILABLE_TEXT}</span>
                </div>
                <div className='popover__detail'>
                  <span className='popover__detail--label'>Date and Time</span> <span className='popover__detail--content'>
                  {queryData && queryData.captureDate ? moment(queryData.captureDate, 'YYYY-MM-DD').format('MMMM D YYYY') : NOT_AVAILABLE_TEXT}</span>
                </div>
              </div>
            </Popover>
          }
        >
          <Typography variant="paragraph" component="p">
            <Info size={23} color='#0076BB' /> &nbsp; Technical Information
          </Typography>
        </OverlayTrigger>
        { role !== 'student' ?
          <div
            className='photo-meta__student-project'
            onClick={() => this.renderStudentProject(this.props.photo)}
          >
            <Typography variant="h3" className="semi-strong link">View Student Project</Typography>
          </div> 
        : null }
      </div>
    ) : null
  }
}

export default AdminPhotoMeta
