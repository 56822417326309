import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { truncateDetails } from '../../utils/commonFunctions'
import { Box, Grid } from '@mui/material'
import { Typography } from '@mui/material'
class TaVisitTableRows extends Component {
  render() {
    const { cohort, isEndDate } = this.props
    return (
      <Box className='dashboard__info-list-rows'>
        <Grid container spacing={2}>
          <Grid item md={4} sm={4} xs={12}>
            <Link to={`/class-detail/${cohort.id}`}>
              <Typography variant='h4' className='semi-strong truncate'>
                {cohort.name}
              </Typography>
            </Link>
          </Grid>
          <Grid item md={4} sm={4} xs={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Typography variant='h4' className='semi-strong'>
              {cohort.facilitator ? cohort.facilitator.name : ''}
            </Typography>
          </Grid>
          <Grid item md={4} sm={4} xs={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Typography variant='h4'>
              {moment(cohort.nextFacilitatorVisit, 'YYYY-MM-DD').format('MMMM D, YYYY')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default TaVisitTableRows
