import React from 'react';
import { TextField, FormHelperText, FormControl, Box } from '@mui/material';
import variables from '../settings/_variables.scss'

const FormField = (props) => {
  return (
    <Box>
      <FormControl
        fullWidth
        variant={props.formFieldVariant}
        style={{ ...props.formControlStyle }}
      >
        <TextField
          id={props.formFieldId}
          label={props.formFieldLabel}
          sx={{
            label: {
              color: props.formFieldError ? `${variables.redError} !important` : `${variables.gery53}`
            },
            mt: 0,
            mb: 0,
            ...props.style,
            ...props.sx
          }}
          margin='dense'
          type={props.formFieldType}
          name={props.formFieldName}
          placeholder={props.formFieldPlaceholder}
          autoComplete={props.formFieldAutoComplete}
          defaultValue={props.formFieldDefaultValue}
          onChange={props.onChange}
          min={props.min}
          disabled={props.disabled}
          max={props.max}
          variant={props.formFieldVariant}
          value={props.formFieldValue}
          fullWidth={true}
          rows={props.formFieldRows}
          multiline={props.multiline ? true : false}
          required={props.formFieldRequired}
          error={props.formFieldError ? true : false}
          onBlur={props.onBlur}
          inputRef={props.inputRef || null}
          InputProps={props.formInputProps}
          className={props.className}
        />
      </FormControl>
      {props.formFieldError ? (
        <FormHelperText className='component-error-text'>{props.formFieldError}</FormHelperText>
      ) : null}
    </Box>
  )
};

export default FormField;
