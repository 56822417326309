import React, { Component, Fragment } from 'react'
import { Provider } from 'mobx-react'
import {ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink} from '@apollo/client';

import UIStore from './stores/UIStore.js'
import UserStore from './stores/UserStore.js'
import PhotoCollectionStore from './stores/PhotoCollectionStore'
import FilterStore from './stores/FilterStore.js'
import ResourcesStore from './stores/ResourcesStore.js'
import MetadataStore from './stores/MetadataStore.js'
import BackToPageStore from './stores/BackToPageStore.js'
import BreadcrumbStore from './stores/BreadcrumbStore.js'

import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import Cookies from 'universal-cookie'
import CheckAuth from './ui/organisms/CheckAuth.js'
import * as Sentry from '@sentry/browser'

import introspectionQueryResultData from './fragmentTypes.json'
import PhotosStore from './stores/PhotosStore.js'
import { relayStylePagination } from '@apollo/client/utilities'
import DateUtils from './utils/DateUtils.js';

DateUtils.createMoment()

const cookies = new Cookies()

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'access-token': cookies.get('WA_ACCESS_TOKEN'),
      'token-type': 'Bearer',
      client: cookies.get('WA_CLIENT_TOKEN'),
      expiry: cookies.get('WA_EXPIRY'),
      uid: cookies.get('WA_UID')
    }
  }
})

const baseLink = ApolloLink.from([
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      )
    if (networkError) console.log(`[Network error]: ${networkError}`)
  }),
  new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    credentials: 'same-origin'
  })
])

const cache = new InMemoryCache({
  introspectionQueryResultData,
  typePolicies: {
    Query: {
      fields: {
        projectPhotosConnection: relayStylePagination(),
        getPaginatedReviewPhotos: relayStylePagination(),
        getCohortsConnectionByUser: relayStylePagination()
      }
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(baseLink),
  cache
})

function handleFirstTab(e) {
  if (e.keyCode === 9) {
    // the "I am a keyboard user" key
    document.body.classList.add('user-is-tabbing')
    window.removeEventListener('keydown', handleFirstTab)
  }
}

window.addEventListener('keydown', handleFirstTab)

class App extends Component {
  componentDidMount() {
    const ele = document.getElementsByClassName('global-progress-indicator')[0]
    if (ele) {
      ele.outerHTML = ''
    }
  }
  render() {
    const uiStore = new UIStore()
    const userStore = new UserStore()
    const photoCollectionStore = new PhotoCollectionStore()
    const projectFilterStore = new FilterStore()
    const schoolFilterStore = new FilterStore()
    const userStudentsFilterStore = new FilterStore()
    const userTeachersFilterStore = new FilterStore()
    const userFacilitatorsFilterStore = new FilterStore()
    const userCuratorsStore = new FilterStore()
    const userAdminsFilterStore = new FilterStore()
    const resourceStore = new ResourcesStore()
    const metadataStore = new MetadataStore()
    const backToPageStore = new BackToPageStore()
    const photosStore = new PhotosStore()
    const breadcrumbStore = new BreadcrumbStore()

    return (
      <ApolloProvider client={client}>
        <Provider
          uiStore={uiStore}
          userStore={userStore}
          photoCollectionStore={photoCollectionStore}
          projectFilterStore={projectFilterStore}
          schoolFilterStore={schoolFilterStore}
          userStudentsFilterStore={userStudentsFilterStore}
          userTeachersFilterStore={userTeachersFilterStore}
          userFacilitatorsFilterStore={userFacilitatorsFilterStore}
          userCuratorsStore={userCuratorsStore}
          userAdminsFilterStore={userAdminsFilterStore}
          resourceStore={resourceStore}
          metadataStore={metadataStore}
          backToPageStore={backToPageStore}
          photosStore={photosStore}
          breadcrumbStore={breadcrumbStore}
        >
          <Fragment>
            <CheckAuth />
          </Fragment>
        </Provider>
      </ApolloProvider>
    )
  }
}

export default App
