import { Box } from '@mui/material'
import React, { Component } from 'react'

class WrkXFmlyLogo extends Component {
  render() {
    const { logoHeight, fill, sx } = this.props
    return (
      <Box className='wrkxfmly_logo' style={{...this.props.style}} sx={{...sx}}>
        <svg
          version='1.1'
          id='Layer_1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 224.8 40.3'
          fill={fill}
          height={logoHeight|| 'inherit'}
        >
          <g>
            <path
              id='wrkfmly_1_'
              className='st0'
              d='M144.8,6.5c-1.7,0-2.5,1-2.5,2.3v1.4h4.1v6.6h-4.1v15.4h-8.7V16.8h-3v-6.6h3V8.7
            c0-6,3.9-8.7,9.6-8.7c1.3,0,2.3,0.1,3.3,0.4v6.3C145.9,6.6,145.4,6.5,144.8,6.5z M21.6,32.1l-3-9.8l-3,9.8H8.5L1,10.2h8.7L13.1,21
            l3.3-10.9h4.8L24.5,21l3.4-10.9h8.4l-7.5,22H21.6z M39,32.1v-22h8.8v2.4l0.6-0.6c1.3-1.5,2.9-2.2,4.9-2.2c0.7,0,1.3,0.1,1.6,0.3
            v8.3c-1-0.4-1.9-0.5-3.1-0.5c-2.1,0-3.6,0.8-4,2.2l0,0v12.2H39z M176.7,32.1v-12c0-1.7-0.8-2.8-2.2-2.8c-1.2,0-2.2,0.8-2.5,2.1l0,0
            v12.7h-8.8v-12c0-1.7-0.8-2.8-2.2-2.8c-1.2,0-2.2,0.8-2.5,2.1l0,0v12.7h-8.8v-22h8.8v2.4l0.6-0.6c1.2-1.4,3.4-2.2,5.7-2.2
            c2.5,0,4.5,0.8,6.1,2.4l0.2,0.2l0.2-0.2c1-0.9,3.3-2.5,6.9-2.5c4.6,0,7.5,3,7.5,7.9v14.6H176.7z M189.7,32.1V0.4h8.8v31.7H189.7z
             M73,32.1l-4.5-7.9l-1.5,2v5.9h-8.8V0.4H67v16.5l5.3-6.7h10.3L75,19l8,13.1H73z M205.4,39.8l3.3-8.5l-8.2-21.1h9.1l3.7,11.4
            l3.5-11.4h8.6l-11.3,29.6H205.4z'
            />
            <polygon
              id='X_1_'
              className='st1'
              points='87.2,26 93.1,20.1 87.2,14.1 96.6,4.7 105.9,11.2 115.2,4.7 124.7,14.1 118.7,20.1
            124.7,26 115.2,35.4 105.9,28.9 96.6,35.4 	'
            />
          </g>
        </svg>
      </Box>
    )
  }
}

export default WrkXFmlyLogo
