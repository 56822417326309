import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Settings, Trash2 } from 'react-feather'
import DeleteEventModal from './DeleteEventModal.js'
import moment from 'moment'
import { Typography } from '@mui/material'

class EventListRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteEvent: false
    }
  }

  render() {
    const { event } = this.props
    const waEvent = event
    let modalClose = () => this.setState({ deleteEvent: false })
    return (
      <div className='events__row' key={waEvent.id}>
        <Row>
          <Col sm={3}>
            {waEvent.linkToFeaturedImage === 'No attachment' ? (
              <div className='photo-container__empty'>
                <Typography variant="h4">No Event Photo</Typography>
              </div>
            ) : (
              <div
                className='photo-container__event-photo'
                style={{ backgroundImage: `url('${waEvent.linkToFeaturedImage}')` }}
              />
            )}
          </Col>
          <Col sm={7}>
            <div className='events__event-details'>
              <Typography variant="h3" className='semi-strong'>{waEvent.name}</Typography>
              <Typography variant="h5">
                {waEvent.eventStart
                  ? moment(waEvent.eventStart).format('MMMM D, YYYY')
                  : 'No Start Date Selected'}{' '}
                {waEvent.eventEnd ? ' – ' : null}
                {waEvent.eventEnd ? moment(waEvent.eventEnd).format('MMMM D, YYYY') : null}
              </Typography>
              {waEvent.published ? (
                <Typography variant="h5" className="h5 semi-strong" style={{ color: '#008160' }}>
                  Published
                </Typography>
              ) : (
                <Typography variant="h5" className="h5 semi-strong">Not Published</Typography>
              )}
            </div>
          </Col>
          <Col sm={2}>
            <div className='events__actions'>
              <Settings onClick={() => this.props.toggleEditEvent(waEvent)} />
              &nbsp;&nbsp;&nbsp;
              <Trash2 onClick={() => this.setState({ deleteEvent: true })} />
              <DeleteEventModal
                show={this.state.deleteEvent}
                onHide={modalClose}
                eventId={waEvent.id}
                showToast={this.props.showToast}
                resource={this.state.targetResource}
                category={this.props.category}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default EventListRow
