import React from 'react'
import { Box, Typography } from '@mui/material'

export default function SectionHeadingAndIntro(props) {
  return (
    <>
      <Typography className='bold' variant='h1'>
        {props.heading}
      </Typography>
      <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 }, maxWidth: '698px' }}>
        {props.introText}
      </Typography>
    </>
  )
}
