import React, { Fragment, useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { ChevronDown, ChevronUp } from 'react-feather'
import UseAgreementListRowSection from './UseAgreementListRowSection'
import { Box, Typography } from '@mui/material'

const UseAgreementListSection = props => {
    const { photoId, isStudentMinor, role, student, refetchUaData } = props
    const [accordionOpen, setAccordionOpen] = useState(false)
    const [useAgreements, setUseAgreements] = useState(props.useAgreement)
    const [inactiveUseAgreements, setInactiveUseAgreements] = useState(
      props.inactiveUseAgreements
    )
    useEffect(() => {
        setUseAgreements(props.useAgreements)
        setInactiveUseAgreements(props.inactiveUseAgreements)
    }, [props.useAgreement, props.inactiveUseAgreements])
    return (
      <Box className='use-agreement__list-section' sx={{mt: {xs: 4, sm: 3}}}>
        <Fragment>
          {useAgreements && useAgreements.length > 0
            ? useAgreements.map(ua => (
                <UseAgreementListRowSection
                  role={role}
                  useAgreement={ua}
                  photoId={photoId}
                  student={student}
                  uaUuid={ua.uuid}
                  isStudentMinor={props.isStudentMinor}
                  key={`ua-${ua.id}`}
                  refetchUaData={refetchUaData}
                />
              ))
            : null}
          {['appadmin'].includes(role) && inactiveUseAgreements.length > 0 ? (
            <>
              <Collapse in={accordionOpen}>
                <Box>
                  {' '}
                  {inactiveUseAgreements.map(ua => (
                    <UseAgreementListRowSection
                      role={role}
                      useAgreement={ua}
                      photoId={photoId}
                      refetchUaData={refetchUaData}
                      student={student}
                      uaUuid={ua.uuid}
                      isStudentMinor={isStudentMinor}
                      key={`ua-admin-${ua.id}`}
                    />
                  ))}
                </Box>
              </Collapse>
              <Box component={'div'}
                onClick={() => setAccordionOpen(!accordionOpen)}
                aria-expanded={accordionOpen}
                sx={{mt: {xs: 3, sm: 4, md: 3}, display: 'flex', gap: 1, cursor: 'pointer'}}
              >
                <Box component={'div'} className='photo-release__chevron'>
                  {accordionOpen ? <ChevronUp /> : <ChevronDown />}
                </Box>
                <Box component={'div'}>
                  <Typography variant="h4" className="small_size strong" sx={{textDecoration: 'underline'}}>
                  {accordionOpen
                      ? 'Hide expired and rejected Use Agreements for this photograph'
                      : 'Show expired and rejected Use Agreements for this photograph'}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : null}
        </Fragment>
      </Box>
    )
}

export default UseAgreementListSection