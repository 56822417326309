import React, { useCallback, useEffect, useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Award, Shield } from 'react-feather'
import './photoStatusStack.scss'
import { withApollo } from '@apollo/client/react/hoc'
import { gql, useMutation } from '@apollo/client'
import { PHOTO_ATTRIBUTES } from '../../graphql/photoAttributes'
import { Box, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc'

const NOMINATE_FOR_AWARD_MUTATION = gql`
  mutation UpdatePhotoAward($awardId: String, $photoId: ID!, $awardClass: String!) {
    updatePhotoAward(awardId: $awardId, photoId: $photoId, awardClass: $awardClass) {
      id
      photo {
        ...photoAttributes
      }
    }
  }
  ${PHOTO_ATTRIBUTES}
`
const PhotoActionBar = props => {
  const [awardType, setAwardType] = useState('')
  const [disabledState, setDisabledState] = useState(props.disabled)

  const AoE = 'Award of Excellence'
  const HM = 'Honorable Mention'

  const getExistingAward = useCallback((photoAwards) => {
    if (photoAwards.length === 0) {
      return ''
    }
    if (!['student'].includes(props.role)) {
      const nominatorsAward = photoAwards
        .filter(award => award.nominatorUserId === props.userStore.id)
        .map(award => award.awardClass)

      if (nominatorsAward.length > 0) {
        return nominatorsAward[0]
      }
      return ''
    } else {
      return photoAwards[0].awardClass
    }
  }, [props.role, props.userStore.id])

  useEffect(() => {
    const existingAward = getExistingAward(props.photo.awards)
    setAwardType(existingAward)
  }, [props.photo.awards, getExistingAward])

  const [nominateForAward] = useMutation(NOMINATE_FOR_AWARD_MUTATION, {
    async onCompleted(data) {
      const existingAward = await getExistingAward(data.updatePhotoAward.photo.awards)
      setAwardType(existingAward)
      setDisabledState(false)
    }
  })

  const submitAward = (photoId, awardType) => {
    setDisabledState(true)
    nominateForAward({
      variables: {
        photoId: photoId,
        awardClass: awardType
      }
    })
  }

  const hasAccess = ['curator'].includes(props.role)

  return (
    <Box
      className={
        props.forStatusStack
          ? !hasAccess && !disabledState
            ? 'review__stack-award-buttons'
            : 'review__stack-curator-award-buttons'
          : 'photo-details-action-bar'
      }
    >
      {props.thumbnail && hasAccess ? <Typography component='span'>Your review:</Typography> : null}
      <Box
        pt={props.thumbnail && hasAccess ? 0 : ''}
        className={`${'review__stack-award-button__icon'.concat(
          awardType === AoE ? '--selected' : ''
        )} ${disabledState ? 'award-chips--disabled' : ''}`}
      >
        <Box
          component={'button'}
          className={!disabledState ? 'link ' : ''}
          title={AoE}
          disabled={disabledState}
          onClick={!disabledState ? () => submitAward(props.photo.id, AoE) : null}
          sx={{
            svg: { verticalAlign: 'middle' },
            border: 'none',
            background: 'transparent',
            color: 'inherit'
          }}
        >
          <Award size={18} /> AoE
        </Box>
      </Box>
      <Box
        pt={props.thumbnail && hasAccess ? 0 : ''}
        className={`${'review__stack-award-button__icon'.concat(
          awardType === HM ? '--selected' : ''
        )} ${disabledState ? 'award-chips--disabled' : ''}`}
      >
        <Box
          component={'button'}
          className={!disabledState ? 'link ' : ''}
          disabled={disabledState}
          title={HM}
          onClick={!disabledState ? () => submitAward(props.photo.id, HM) : null}
          sx={{
            svg: { verticalAlign: 'middle' },
            border: 'none',
            background: 'transparent',
            color: 'inherit'
          }}
        >
          <Shield size={18} /> HM
        </Box>
      </Box>
    </Box>
  )
}

export default inject('userStore')(observer(withApollo(withRouter(PhotoActionBar))))
