import { gql } from "@apollo/client";

export const STUDENT_LIST_BY_COHORT_ID = gql`
  query getCohortById($cohortId: ID!) {
    cohort(id: $cohortId) {
      id
      students {
        id
        name
        userId
      }
      submittedProjects {
        id
        submitted
        reviewed
        name
        submittedUpdatedAt
        captionAssetId
        captionsPendingPicsioSync
        lastSync
        captionFileUpdatedAt
        student {
          id
          name
          mobileNumber
          email
        }
        photos {
          project {
            id
            submitted
            submittedUpdatedAt
            reviewed
          }
          id
          isShortListed
          submittedDate
          mrAdminNotes
          totalMrRequired
          originalUrl
          title
          caption
          originalTitle
          originalCaption
          assetId
          lastSync
          formsPendingPicsioSync
          exifData {
            size
            width
            height
            fileType
          }
          awards {
            id
            awardClass
            nominatorInitials
            nominatorUserId
            nominatorName
            nominatorType
            isEdited
            editedOn
            updatedAt
          }
        }
      }
      school {
        id
        name
      }
      teacher {
        name
        id
      }
      facilitator {
        name
        id
      }
    }
  }
`