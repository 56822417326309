import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import '../ui/organisms/userlist.scss'
import Hero from '../ui/organisms/Hero.js'
import UserListStudents from '../ui/organisms/UserListStudents.js'
import UserListTeachers from '../ui/organisms/UserListTeachers.js'
import UserListFacilitators from '../ui/organisms/UserListFacilitators.js'
import UserListCurators from '../ui/organisms/UserListCurators.js'
import UserListAdmins from '../ui/organisms/UserListAdmins.js'
import Layout from '../ui/template/Layout'
import ToastNotification from '../ui/elements/ToastNotification'
import { withRouter } from '../utils/customHoc'
import { inject, observer } from 'mobx-react'

const UsersList = (props) => {
  const { location, breadcrumbStore, basePath, userStore } = props

  const [currentTab, setCurrentTab] = useState('students')
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastType, setToastType] = useState('')
  const [role] = useState(userStore.role)

  useEffect(() => {
    setCurrentTab(props?.params?.currentTab || 'students')
  },[props?.params?.currentTab])

  useEffect(() => {
    breadcrumbStore.resetBreadcrumb()
    breadcrumbStore.addBreadcrumb({
      pageName: 'Users',
      link: location.pathname,
      basePath: basePath,
      isParent: true,
      isActive: true,
    })
  }, [breadcrumbStore, location.pathname, basePath])

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab)
    setToastType('')
    setToastMessage('')
  }

  const showToastrMessage = (toastType, message = '') => {
    setToastType(toastType)
    setToastMessage(message)

    setTimeout(() => {
      setShowToast(false)
      setToastMessage('')
    }, 5000)
  }

  const tabs = [
    { eventKey: 'students', title: 'Students', path: '/users/students' },
    { eventKey: 'teachers', title: 'Teachers', path: '/users/teachers' },
    { eventKey: 'facilitators', title: 'Teaching Artists', path: '/users/facilitators' },
    { eventKey: 'curators', title: 'Curators', path: '/users/curators' },
    { eventKey: 'admins', title: 'Administrators', path: '/users/admins' },
  ]

  return (
    <Layout>
      {showToast === true ? (
        <ToastNotification
          notificationType={toastType}
          showToast={showToast}
          onClose={() => setShowToast(false)}
          toastMessage={toastMessage}
        />
      ) : null}

      <Hero
        heroTitle='Users'
        currentTab={currentTab}
        tabChangeHandler={handleTabChange}
        tabs={tabs}
      />
      <Box sx={{ pt: { xs: 4, sm: 4, md: 6, lg: 10 }, pl: { md: 6 }, pr: { md: 6 } }}>
        {currentTab === 'students' ? (
          <UserListStudents role={role} />
        ) : currentTab === 'teachers' ? (
          <UserListTeachers role={role} />
        ) : currentTab === 'facilitators' ? (
          <UserListFacilitators role={role} />
        ) : currentTab === 'curators' ? (
          <UserListCurators role={role} />
        ) : currentTab === 'admins' ? (
          <UserListAdmins
            role={role}
            showToast={showToastrMessage}
          />
        ) : null}
      </Box>
    </Layout>
  )
}

export default inject('breadcrumbStore', 'userStore')(observer(withRouter(UsersList)))