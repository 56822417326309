import { Fragment, useEffect, useState, useRef } from 'react'
import '../molecules/herotabs.scss'
import { useQuery } from '@apollo/client'
import { COHORT_PAGINATED_PHOTOS_BY_AWARD_QUERY } from '../../queries/ClassReviewTabQueries'
import { Box, Grid, ImageListItem, ImageListItemBar, Hidden, useMediaQuery } from '@mui/material'
import PhotoOverlay from './PhotoOverlay'
import ShortlistPhoto from '../elements/ShortlistPhoto'
import PhotoStatusStack from './PhotoStatusStack'
import NewFullScreenPhotoModal from '../organisms/PhotoDetailsModal/NewFullScreenPhotoModal'
import { inject, observer } from 'mobx-react'
import { Loader } from 'react-feather'
import { Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc'
import { ArrowDown } from 'react-feather'
import DateUtils from '../../utils/DateUtils'

const NewClassReviewGallery = props => {
  const { cohortId, awardClass, role, photosStore } = props
  const [allPhotosCount, setAllPhotosCount] = useState(0)
  const [currentAwardClass, setCurrentAwardClass] = useState(awardClass)
  const [allPhotos, setAllPhotos] = useState(null)
  const [endCursor, setEndCursor] = useState('')
  const [hasNextPage, setHasNextPage] = useState(false)
  const [selectedPhotoId, setSelectedPhotoId] = useState(-1)
  const [showPhotoModal, setShowPhotoModal] = useState(false)
  const [modalFadeOut, setModalFadeOut] = useState(false)
  const prevCohortRef = useRef()
  const isXXLScreen = useMediaQuery('(min-width: 1536px)')
  const [isLoading, setIsLoading] = useState(false)

  const {
    loading: reviewGalleryLoading,
    fetchMore: reviewGalleryFetchMore
  } = useQuery(COHORT_PAGINATED_PHOTOS_BY_AWARD_QUERY, {
    variables: {
      cohortId: cohortId,
      awardClass: currentAwardClass,
      cursor: endCursor
    },
    onCompleted(data) {
      const photos = data.getPaginatedReviewPhotos.edges.map(edge => edge.node)
      if (awardClass !== 'All' && awardClass !== 'None') {
        setAllPhotos(
          photos.filter(
            photo => photo.awards.filter(award => award.awardClass === awardClass).length > 0
          )
        )
      } else if (awardClass === 'None') {
        setAllPhotos(photos.filter(photo => photo.awards.length === 0))
      } else {
        setAllPhotos(photos)
      }
      setAllPhotosCount(data.cohortAwardTypeCount)
      if (data.getPaginatedReviewPhotos.pageInfo.hasNextPage) {
        setEndCursor(data.getPaginatedReviewPhotos.pageInfo.endCursor)
      }
      setHasNextPage(data.getPaginatedReviewPhotos.pageInfo.hasNextPage)
      const getIds = data.getPaginatedReviewPhotosIds.map(photo => photo.id)
      photosStore.setPhotoStore('allphotos', [...getIds])
      props.refetchReviewCounts()
      setIsLoading(false)
    }
  })
  const existingAward = photoAwards => {
    if (photoAwards.length === 0) {
      return 'None'
    }
    const nominatorsAward = photoAwards
      .filter(award => award.nominatorUserId === props.currentUserStore.id)
      .map(award => award.awardClass)

    if (nominatorsAward.length === 0) {
      return 'None'
    }
    return nominatorsAward[0]
  }

  const fetchMoreGalleryPhotos = () => {
    if (!hasNextPage) return []
    setIsLoading(true)
    reviewGalleryFetchMore({
      variables: {
        cursor: endCursor
      }
    })
  }

  useEffect(() => {
    const handleScroll = e => {
      const elem = e.target.scrollingElement
      const vh = elem.scrollHeight
      const currVh = elem.scrollTop
      const threshold = vh / 4 + 50
      if (currVh > threshold) {
        fetchMoreGalleryPhotos()
      }
    }
    if (!isXXLScreen) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (!isXXLScreen) {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [hasNextPage, endCursor, isXXLScreen])

  useEffect(() => {
    if (props.awardClass !== currentAwardClass || cohortId !== prevCohortRef.current) {
      setAllPhotos(null)
      setCurrentAwardClass(props.awardClass)
      setEndCursor('')
      setHasNextPage(false)
      reviewGalleryFetchMore({
        variables: {
          awardClass: props.awardClass,
          cursor: ''
        }
      })
    }
  }, [props.awardClass, cohortId, currentAwardClass, reviewGalleryFetchMore])

  useEffect(() => {
    prevCohortRef.current = cohortId
  }, [cohortId])

  return reviewGalleryLoading ? (
    <Box textAlign={'center'} pt={4} pb={8}><Loader /></Box>
  ) : (
    <Fragment>
      <Box sx={{ px: { xs: 4, md: 6 }, pt: { xs: 5, md: 6 }, pb: { xs: 4 } }}>
        <Fragment>
          <Box>
            {allPhotos && allPhotos.length > 0 ? (
              <Grid container columnSpacing={2}>
                {allPhotos.map((photo, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <ImageListItem key={index}>
                      <Box className='class-review-masonry-photo'>
                        <Box className='class-review-masonry-photo-container' key={photo.id}>
                          <PhotoOverlay
                            photo={photo}
                            photoOnClick={photo => {
                              setSelectedPhotoId(photo.id)
                              setShowPhotoModal(true)
                            }}
                            hideComments={true}
                            isCohortEnded={DateUtils.isDatePassed(photo.project.cohort.endDate)}
                            page='review-gallery'
                          />
                          <ShortlistPhoto
                            photo={photo}
                            placeholderDark={true}
                            key={index}
                            awardGiven={props.awardGiven}
                            role={role}
                          />
                        </Box>
                        <ImageListItemBar
                          title={
                            <PhotoStatusStack
                              photo={photo}
                              role={role}
                              photoAward={existingAward(photo.awards)}
                              isCohortReviewed={photo.project.cohort.reviewed}
                            />
                          }
                          position='below'
                          sx={{ '.MuiImageListItemBar-titleWrap': { padding: 0 } }}
                        />
                      </Box>
                    </ImageListItem>
                  </Grid>
                ))}
                {hasNextPage && (
                  <Hidden xlDown>
                    {isLoading ? (
                      <Typography component='p' sx={{ width: '100%', textAlign: 'center' }}>
                        <Loader />
                      </Typography>
                    ) : (
                      <Typography
                        variant='link'
                        component='p'
                        className='large'
                        onClick={fetchMoreGalleryPhotos}
                        sx={{ width: '100%', textAlign: 'center' }}
                      >
                        Load More <ArrowDown />
                      </Typography>
                    )}
                  </Hidden>
                )}
              </Grid>
            ) : (
              <>
                <Typography variant='paragraph' component='p'>
                  There are no submitted photographs matching this filter right now.
                </Typography>
                <Typography variant='paragraph' component='p'>
                  Try selecting a different filter to see photographs.
                </Typography>
              </>
            )}
          </Box>
        </Fragment>
        {selectedPhotoId !== -1 ? (
          <NewFullScreenPhotoModal
            show={showPhotoModal}
            closeModal={() => {
              setModalFadeOut(true)
              setTimeout(() => {
                setShowPhotoModal(false)
                setModalFadeOut(false)
                setSelectedPhotoId(-1)
                // reviewGalleryFetchMore({
                //   variables: {
                //     cursor: ''
                //   }
                // })
              }, 500)
            }}
            photoId={selectedPhotoId}
            isModal={true}
            placeholderDark={true}
            showToast={props.showToast}
            totalCount={allPhotosCount}
            componentCallFrom='allphotos'
            toggleParentModalState={(pushState = true, shouldRefetch = false) => {
              if (pushState) {
                window.history.pushState(null, '', props.backToPageStore.activePage)
              }
              setModalFadeOut(true)
              setTimeout(() => {
                setShowPhotoModal(false)
                setSelectedPhotoId(-1)
                setModalFadeOut(false)
              }, 500)
            }}
            photoCurrentIndex={allPhotos.findIndex(elem => elem.id === selectedPhotoId)}
            animation={false}
            className={`animated ${
              showPhotoModal && !modalFadeOut ? 'fadeInUpBig' : 'fadeOutDownBig'
            }`}
          />
        ) : null}
      </Box>
    </Fragment>
  )
}

export default inject('photosStore', 'userStore', 'backToPageStore')(
  observer(withRouter(NewClassReviewGallery))
)
