import { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Typography, Box, Grid } from '@mui/material'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox'
import { REOPEN_CURATORIAL_REVIEW_MUTATION } from './../../queries/reviewQueries'
import './modals.scss'
import BaseModal from '../template/BaseModal';

const ReopenCuratorialReviewModal = props => {
  const [reviewIds, setReviewIds] = useState([])
  const [selectedCurators, setSelectedCurators] = useState({})
  const [showToast, setShowToast] = useState(false)

  const { curators, curatorReviews } = props
  const toastMsg = 'Curatorial review(s) has been reopened.'
  const [reopenMutate] = useMutation(REOPEN_CURATORIAL_REVIEW_MUTATION, {
    onCompleted: () => {
      props.showToast('success', toastMsg)
      props.onHide()
      setReviewId([])
      setSelectedCurators({})
    }
  })

  const handleReopenReview = e => {
    e.preventDefault()
    reopenMutate({
      variables: {
        reviewIds: reviewIds
      },
      refetchQueries: [`ClassReview`]
    })
  }

  const setReviewId = (e, curatorId) => {
    const updatedSelectedCurators = { ...selectedCurators }
    updatedSelectedCurators[curatorId] = e.target.checked
    const curatorRev = curatorReviews.filter(rev => rev.reviewerId === curatorId)
    if (e.target.checked) {
      if (!reviewIds.includes(curatorRev[0].id)) {
        setReviewIds([...reviewIds, curatorRev[0].id])
      }
    } else {
      setReviewIds(reviewIds.filter(id => id !== curatorRev[0].id))
    }

    setSelectedCurators(updatedSelectedCurators)
  }

  const handleClose = () => {
    setReviewIds([])
    setSelectedCurators({})
    props.onHide();
  };

  useEffect(() => {
    if (showToast) {
      props.showToast && props.showToast('success', toastMsg)
      props.onHide()
      setReviewIds([])
      setSelectedCurators({})
      setShowToast(false)
    }
  }, [showToast, props])

  return (
    <BaseModal
      {...props}
      show={props.show}
      onClick={props.onClick}
      value={props.value}
      open={props.show}
      onClose={handleClose}
      onHide={handleClose}
    >
        <BaseModal.BaseModalHeader>
          Reopen a Curatorial Review
        </BaseModal.BaseModalHeader>
        <BaseModal.BaseModalBody>
          <Box className='reopen-review-modal__section'>
            <Typography variant='paragraph' component='p'>
              Be sure that you want to reopen the review(s).
            </Typography>

            <Typography variant='paragraph' component='p'>
              Reminder: This will set the status of this class back to 'In Curatorial Review'. The
              selected curators must resubmit before you can process awards.
            </Typography>

            <p className='reopen-review-modal__section--question'>
              Which curatorial reviews do you want to reopen?
            </p>

            <div className='margin_bottom_27'>
              {curators.map(curator => (
                <div
                  key={Math.random()
                    .toString(36)
                    .substring(2, 7)}
                >
                  <FormCheckbox
                    label={curator.name}
                    name={curator.id}
                    checked={selectedCurators[curator.id] || false}
                    color='primary'
                    disabled={!curatorReviews.some(rev => rev.reviewerId === curator.id)}
                    onChange={e => setReviewId(e, curator.id)}
                  />
                </div>
              ))}
            </div>
          </Box>
          <BaseModal.BaseModalFooter>
            <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
              <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    variant='contained'
                    type='copy'
                    title='Reopen Curatorial Review(s)'
                    disabled={reviewIds.length === 0}
                    onClick={e => {
                      handleReopenReview(e)
                      setShowToast(true)
                    }}
                    sx={{width:{ xs: '100%', sm: '100%' }}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                  <Typography
                    sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} 
                    variant="h4"
                    className='small_size_semi_bold link'
                    onClick={handleClose}
                  >
                    Cancel
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </BaseModal.BaseModalFooter>
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

export default ReopenCuratorialReviewModal