import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Box, Chip, FilledInput, FormHelperText } from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    },
  },
}

const FormSelect = (props) => {

  const getChipIcon = (value) => {
    const selectedKey = Object.keys(props.selectedOptions).find(x => props.selectedOptions[x].value === value)
    const selectedOption = props.selectedOptions[selectedKey]
    return <Chip sx={{px: '8px'}} key={selectedOption?.value} label={selectedOption?.label} />
  }

  return (
    <div>
      <FormControl
        variant={props.variant}
        fullWidth={props.fullWidth}
        style={{ ...props.formControlStyle }}
        disabled={props.disabled || false}

      >
        <InputLabel id={props.labelId}>{props.label}</InputLabel>
        {!props.multiple ? (
          <Select
            labelId={props.labelId}
            label={props.label}
            id={props.selectId}
            value={props.value || ''}
            defaultValue={props.defaultValue}
            displayEmpty
            onChange={props.onChange}
            inputProps={props.inputProps}
            name={props.name}
            className={props.className}
            sx={props.sx}
            MenuProps={{ disableScrollLock: false, ...MenuProps }}
            error={props.formFieldError ? true : false}
          >
            {props.options &&
              props.options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        ) : (
          <Select
            labelId={props.labelId}
            label={props.label}
            id={props.selectId}
            value={props.value || []}
            defaultValue={props.defaultValue}
            displayEmpty
            onChange={props.onChange}
            inputProps={props.inputProps}
            name={props.name}
            className={props.className}
            sx={props.sx}
            multiple
            error={props.formFieldError ? true : false}
            input={<FilledInput id='select-multiple-chip' label='Chip' />}
            renderValue={selected => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected &&
                  selected.map((value, index) => {
                    return getChipIcon(value)
                  })}
              </Box>
            )}
            MenuProps={{ disableScrollLock: false, ...MenuProps }}
          >
            {props.options &&
              props.options.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        )}
      </FormControl>
      {props.formFieldError ? (
        <FormHelperText className='component-error-text'>{props.formFieldError}</FormHelperText>
      ) : null}
    </div>
  )
}

export default  FormSelect