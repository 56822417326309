import React, { Fragment, useState, useEffect} from 'react'
import './resources.scss'
import ResourceHero from '../ui/molecules/ResourceHero.js'
import Hero from '../ui/organisms/Hero.js'
import ResourceCategoryList from '../ui/molecules/ResourceCategoryList.js'
import Layout from '../ui/template/Layout'
import { observer, inject } from 'mobx-react'
import { withRouter } from '../utils/customHoc'

const requestOptions = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_PAT}`,
  },
}

const ResourceCategory = props => {
  const [resourceData, setResourceData]= useState([])
  const { location, params, resourceStore, breadcrumbStore, basePath, userStore } = props
  const category = params.category.replace(/-+/g, ' ')
  const categoryId = params.categoryId
  const { role } = userStore

  let newResourceData = []
  if(resourceStore.resources.length > 0) {
    newResourceData = resourceStore.resources.filter(resource => {
      return resource.fields.Category ? resource.fields.Category.includes(categoryId) :''
    })
  } else {
    newResourceData = resourceData
  }

  let orderedResources = [...newResourceData]

  if(resourceStore.categories.length > 0) {
    const categoryResources = []
    const category = resourceStore.categories.filter(cat => {
      return cat.id === categoryId
    })
    if(category[0].fields && category[0].fields.Resources.length > 0) {
      const resourceOrdered = category[0].fields.Resources
      resourceOrdered.forEach(function(key) {
        let found = false;
        orderedResources = orderedResources.filter(function(res) {
          if(!found && res.id === key) {
            categoryResources.push(res);
            found = true;
            return false;
          } else {
            return true;
          }
        })
      })
      orderedResources = [...categoryResources]
    }
    if (["student", "teacher", "facilitator"].includes(role) && category[0].fields.Name === 'Web App Guides')
    {
      breadcrumbStore.resetBreadcrumb()
    }
  }

  const checkPageType = (["student", "teacher", "facilitator"].includes(role) && category === 'Web App Guides')
  breadcrumbStore.addBreadcrumb({
    pageName: checkPageType ?  "App How-To" : category,
    link: checkPageType ? "" : location.pathname,
    basePath: checkPageType ? "" : basePath,
    isParent: checkPageType ? true : false,
    isActive: true
  })

  const fetchAllResources = (offset = '') => {
    const {params } = props
    const categoryId = params.categoryId
    let resourceUrl = process.env.REACT_APP_AIRTABLE_BASE_URL + process.env.REACT_APP_AIRTABLE_APP_ID + '/Resources?view=Grid%20view'
    if(offset !== '') {
      resourceUrl= process.env.REACT_APP_AIRTABLE_BASE_URL + process.env.REACT_APP_AIRTABLE_APP_ID + '/Resources?view=Grid%20view&offset=' + offset
    }
    fetch(resourceUrl, requestOptions)
    .then(response => response.json())
    .then(data => {
      const existingResources = resourceData
      const mergedResources = [...existingResources, ...data.records]
      if(data.offset) {
        fetchAllResources(data.offset)
      } else {
        const newResourceData = mergedResources.filter(resource => {
          return resource.fields.Category ? resource.fields.Category.includes(categoryId) :''
        })
        setResourceData(newResourceData)
      }

    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.')
    })
  }

  useEffect(() => {
    fetchAllResources()
    // eslint-disable-next-line
  }, [])

  return (
    <Layout>
      <Fragment>
        <Hero heroTitle={category} heroBreadcrumb='Resources' />
        <div className='container__body full-width'>
          <div className='resource-category__container'>
            <ResourceHero category={params.category} />
            <ResourceCategoryList
              resources={orderedResources}
              category={category}
              showToast={props.showToast}
            />
          </div>
        </div>
      </Fragment>
    </Layout>
  )
}

export default inject('resourceStore', 'breadcrumbStore', 'userStore')(observer(withRouter(ResourceCategory)))
