import React, { useState } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { Typography, Box, Grid } from '@mui/material'
import { Spinner } from 'react-bootstrap'
import { SUBMIT_REVIEW_MUTATION } from '../../queries/reviewQueries'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox'
import BaseModal from '../template/BaseModal';

function SubmitCuratorialReviewAdminModal(props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [curatorIds, setCuratorIds] = useState([])
  const [selectedCurators, setSelectedCurators] = useState({})

  const handleClick = (e, onMutate) => {
    e.preventDefault()
    setIsSubmitting(true)
    onMutate({
      variables: {
        cohortId: props.cohortId,
        curatorIds: curatorIds
      },
      refetchQueries: [`ClassReview`]
    })
  }

  const setCuratorId = (e, curatorId) => {
    e.preventDefault()
    const updatedSelectedCurators = { ...selectedCurators }
    updatedSelectedCurators[curatorId] = e.target.checked

    setCuratorIds([...curatorIds, curatorId])
    setSelectedCurators({ ...updatedSelectedCurators })
  }

  const { curators, curatorReviews } = props

  const handleClose = () => {
    setSelectedCurators({})
    props?.onHide?.();

  };

  return (
    <BaseModal
      {...props}
      show={props.show}
      onClick={props.onClick}
      value={props.value}
      open={props.show}
      onClose={handleClose}
      onHide={handleClose}
    >
      <BaseModal.BaseModalHeader>
        Submit a Curatorial Review
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Box className='submit-your-review-modal__section'>
          <Typography
            variant='paragraph'
            sx={{
              mb: '1rem'
            }}
            component='p'
          >
            Be sure that you want to submit the review(s) on behalf of the curator(s).
          </Typography>
          <Typography
            variant='paragraph'
            sx={{
              mb: '1rem'
            }}
            component='p'
          >
            Reminder: The selected curators will not be able to change their awards after their
            review is submitted.
          </Typography>

          <Typography variant='paragraph' component='p' sx={{ mb: '8px', mt: '1rem' }}>
            Which curatorial reviews do you want to submit?
          </Typography>

          <div className='margin_bottom_27'>
            {curators.map(curator => {
              return (
                <div
                  key={Math.random()
                    .toString(36)
                    .substring(2, 7)}
                >
                  <FormCheckbox
                    label={curator.name}
                    color='primary'
                    checked={selectedCurators[curator.id] || false}
                    disabled={
                      curatorReviews.filter(rev => rev.reviewerId === curator.id).length > 0
                    }
                    onChange={e => setCuratorId(e, curator.id)}
                  />
                </div>
              )
            })}
          </div>
        </Box>
        <BaseModal.BaseModalFooter>
          <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
            <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
              <Grid item xs={12} sm={6} md={4}>
                <Mutation
                  mutation={SUBMIT_REVIEW_MUTATION}
                  onCompleted={() => {
                    setIsSubmitting(false)
                    props.onHide()
                    props.showToast('success', 'This class review has been sent to the administrator.')
                  }}
                >
                  {(onMutate, { data }) => (
                    <Button
                      variant='contained'
                      type='Submit'
                      title={
                        isSubmitting ? (
                          <span className='icon-text-span'>
                            <Spinner
                              as='span'
                              animation='border'
                              size='sm'
                              role='status'
                              aria-hidden='true'
                            />{' '}
                            Submitting...{' '}
                          </span>
                        ) : (
                          'Submit Curatorial Review(s)'
                        )
                      }
                      disabled={isSubmitting}
                      onClick={e => handleClick(e, onMutate)}
                      sx={{width:{xs:'100%', sm:'100%'}}}
                    />
                  )}
                </Mutation>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>  
                <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={handleClose}>
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </BaseModal.BaseModalFooter>
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

export default SubmitCuratorialReviewAdminModal
