import React, { useCallback, useEffect, useState } from 'react'
import { Nav } from 'react-bootstrap'
import { Box } from '@mui/material'

import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { useQuery } from '@apollo/client'
import { COHORT_AWARD_COUNT_QUERY } from '../../queries/ClassReviewTabQueries'

const ClassReviewSubTabs = props => {
  // const tabs = props.tabs

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const buttonGroupRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [popperWidth, setPopperWidth] = React.useState('auto')
  const [allPhotosCount, setAllPhotosCount] = useState(0)
  const [aoePhotosCount, setAoePhotosCount] = useState(0)
  const [honorablePhotosCount, setHonorablePhotosCount] = useState(0)
  const [nonePhotosCount, setNonePhotosCount] = useState(0)
  const [cohortId] = useState(props.cohortId)

  const { refetch } = useQuery(COHORT_AWARD_COUNT_QUERY, {
    skip: !cohortId,
    variables: {
      cohortId: cohortId
    },
    onCompleted(data) {
      setAllPhotosCount(data.allCount)
      setAoePhotosCount(data.aoeCount)
      setHonorablePhotosCount(data.hmCount)
      setNonePhotosCount(data.noneCount)
    }
  })

  const refetchCount = useCallback(() => {
    refetch()
    props?.setRefetchCount?.(false)
  }, [props, refetch])

  useEffect(() => {
    if (props?.refetchCount) {
      refetchCount()
    }
  }, [props?.refetchCount, refetchCount])

  useEffect(() => {
    const handleResize = () => {
      if (buttonGroupRef.current) {
        setPopperWidth(buttonGroupRef.current.clientWidth)
      }
    }

    // Attach the resize event listener
    window.addEventListener('resize', handleResize)

    // Initial width setup
    if (buttonGroupRef.current) {
      setPopperWidth(buttonGroupRef.current.clientWidth)
    }

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    props.handelTabChange(tabs[index].eventKey)
    setOpen(false)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const tabs = [
    {
      eventKey: 'all',
      title: 'All (' + allPhotosCount + ')',
      count: allPhotosCount
    },
    {
      eventKey: 'award-of-excellence',
      title: 'Award of Excellence (' + aoePhotosCount + ')',
      count: aoePhotosCount
    },
    {
      eventKey: 'honorable-mention',
      title: 'Honorable Mention (' + honorablePhotosCount + ')',
      count: honorablePhotosCount
    },
    {
      eventKey: 'none',
      title: 'No Award (' + nonePhotosCount + ')',
      count: nonePhotosCount
    }
  ]
  const defaultActiveKey = tabs[0]['eventKey']
  return (
    <Box
      className='container__hero class-review__sub-menu'
      sx={{ p: 0, m: 0, pt: { xs: 5 }, border: 'none' }}
    >
      <Box
        sx={{
          display: {
            md: 'block',
            sm: 'none',
            xs: 'none'
          }
        }}
      >
        <Nav
          variant='pills'
          defaultActiveKey={defaultActiveKey}
          activeKey={props.currentTab}
          onSelect={selectedKey => props.handelTabChange(selectedKey)}
        >
          {tabs.map((tab, index) => (
            <Nav.Link disabled={tab.count ? false : true} key={index} eventKey={tab.eventKey}>
              {tab.title}
            </Nav.Link>
          ))}
        </Nav>
      </Box>
      <Box
        sx={{
          display: {
            md: 'none',
            sm: 'block',
            xs: 'block'
          },
          p: 0,
          m: 0,
          textAlign: 'center',
          px: { xs: 4 }
        }}
      >
        <ButtonGroup
          variant='contained'
          ref={node => {
            anchorRef.current = node
            buttonGroupRef.current = node
          }}
          aria-label='split button'
          sx={{
            boxShadow: 'none',
            width: { xs: '100%', sm: 'auto' }
          }}
        >
          <Button
            onClick={handleClick}
            sx={{
              minWidth: '270px !important',
              backgroundColor: '#fff',
              color: '#8e5ba6',
              borderRadius: '0',
              border: '1px solid #8e5ba6',
              boxShadow: 'none',
              padding: '1rem',
              '&:hover': {
                backgroundColor: '#e9deee'
              },
              width: { xs: '100%', sm: 'auto' },
              fontSize: '1.25rem'
            }}
            endIcon={<ArrowDropDownIcon />}
          >
            {tabs[selectedIndex].title}
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
            minWidth: '270px',
            backgroundColor: '#e9deee',
            color: '#8e5ba6',
            borderRadius: '0',
            border: '1px solid #8e5ba6',
            boxShadow: 'none',
            width: popperWidth,
            fontSize: '1.25rem'
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu' autoFocusItem>
                    {tabs.map((option, index) => (
                      <MenuItem
                        key={`review-sub-meu-item-${index}`}
                        disabled={option.count ? false : true}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                        sx={{
                          color: '#8e5ba6',
                          fontSize: '1.25rem'
                        }}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  )
}

export default ClassReviewSubTabs
