import { Box, Typography } from '@mui/material'
import { AlertTriangle, Award, CheckCircle, Clock, Slash, Info } from 'react-feather'
import { useLocation, useParams } from 'react-router-dom'

export const isPublishSectionEnable = false

export const useBasePath = () => {
  const location = useLocation()
  const params = useParams()

  return Object.values(params).reduce(
    (path, param) => path.replace('/' + param, ''),
    location.pathname
  )
}

export const cacheImages = async srcArray => {
  await srcArray.map(src => {
    return new Promise(function(resolve, reject) {
      const img = new Image()
      img.src = src
      img.onload = resolve
      img.onerror = reject
    })
  })
}

export const truncateDetails = (textToTruncate, textLimit = 20) => {
  const content = textToTruncate.trim()
  return content.length > textLimit
    ? (content.substring(0, textLimit - 3)).trim() + '...'
    : content
}

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getAllCuurentMr = modelReleases => {
  return modelReleases.filter(
    mr =>
      (mr.mrStatus === 'Active' && mr.adminDecision === null) ||
      (mr.mrStatus === 'Active' && mr.adminDecision === 'Pending') ||
      (mr.mrStatus === 'Completed' && mr.adminDecision === 'Approve') ||
      (mr.mrStatus === 'Completed' && mr.adminDecision === 'Reject')
  )
}

export const getUaByStatus = (useAgreements, status = '') => {
  if (status === 'Active') {
    return useAgreements.filter(
      ua =>
        (ua.uaStatus === 'Active' && ua.adminDecision === null) ||
        (ua.uaStatus === 'Active' && ua.adminDecision === 'Pending') ||
        (ua.uaStatus === 'Completed' && ua.adminDecision === 'Approve') ||
        (ua.uaStatus === 'Completed' && ua.adminDecision === 'Reject')
    )
  } else if (status === 'Inactive') {
    return useAgreements.filter(
      ua =>
        (ua.uaStatus === 'Inactive' && ua.adminDecision === null) ||
        (ua.uaStatus === 'Inactive' && ua.adminDecision === 'Reject') ||
        (ua.uaStatus === 'Inactive' && ua.adminDecision === 'Pending')
    )
  } else {
    return useAgreements.filter(ua => ua.uaStatus !== 'Delete')
  }
}

export const orderUseAgreements = useAgreements => {
  return useAgreements.sort(
    (a, b) =>
      new Date(...b.createdAt.split('/').reverse()) - new Date(...a.createdAt.split('/').reverse())
  )
}

export const orderEntities = entities => {
  let localEntity = entities ? [...entities] : []
  return localEntity
    ? localEntity.sort(
        (a, b) =>
          new Date(...b.createdAt.split('/').reverse()) -
          new Date(...a.createdAt.split('/').reverse())
      )
    : []
}

export const waPrivateCipher = (text, salt = 'WAEDUCATION') => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0))
  const byteHex = n => ('0' + Number(n).toString(16)).substr(-2)
  const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code)

  return text
    .split('')
    .map(textToChars)
    .map(applySaltToChar)
    .map(byteHex)
    .join('')
}

export const waPrivateDecipher = (encoded, salt = 'WAEDUCATION') => {
  const textToChars = text => text.split('').map(c => c.charCodeAt(0))
  const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code)
  return encoded
    .match(/.{1,2}/g)
    .map(hex => parseInt(hex, 16))
    .map(applySaltToChar)
    .map(charCode => String.fromCharCode(charCode))
    .join('')
}

export const getStudentFromPhoto = photo => {
  if (photo) {
    return photo.project.student
  }
  return {}
}

export const getApprovedEntityCount = (entityData, entityType) => {
  if (entityType === 'mr') {
    return entityData.filter(
      entity => entity.mrStatus === 'Completed' && entity.adminDecision === 'Approve'
    ).length
  } else if (entityType === 'ua') {
    return entityData.filter(
      entity => entity.uaStatus === 'Completed' && entity.adminDecision === 'Approve'
    ).length
  }
}

export const getFileTitleForDownload = fileName => {
  return fileName
    .trim()
    .replace(/\s+/g, ' ')
    .split(' ')
    .join('-')
    .toLowerCase()
}

export const gradeLevels = [
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: 'Other', label: 'Other' }
]

export const selectStyles = {
  menu: styles => ({ ...styles, zIndex: 999, height: 'auto' }),
  control: styles => ({ ...styles, height: 'auto', borderColor: '#c4c4c4', borderWidth: '2px' }),
  placeholder: styles => ({ ...styles, color: '#757575', fontSize: '14px', paddingLeft: '2px' })
}

export const getGradeLevelObjUsingGradeIds = gradeIds => {
  let gradeLevel = []
  gradeIds !== null &&
    gradeIds.split(',').forEach(gradeId => {
      gradeLevel.push({
        value: gradeId,
        label: gradeId
      })
    })
  return gradeLevel
}

export const checkStudentAccessToPhoto = (studentDetails, userStore) => {
  const { role, email } = userStore
  if (
    ['student'].includes(role) &&
    studentDetails.student.email.toLowerCase() === email.toLowerCase()
  ) {
    return true
  }
  return false
}

export const checkUserAccessToPhoto = (photo, userStore) => {
  const { role, email } = userStore
  const { project } = photo
  const { cohort } = project
  const { curators, facilitator, teacher } = cohort
  if (['appadmin'].includes(role)) {
    return true
  } else if (
    ['curator'].includes(role) &&
    (curators.filter(cu => cu.email === email).length > 0 || photo.published)
  ) {
    return true
  } else if (
    (['facilitator'].includes(role) && (facilitator.email === email || photo.published)) ||
    (['teacher'].includes(role) && (teacher.email === email || photo.published))
  ) {
    return true
  } else if (checkStudentAccessToPhoto(project, userStore) || photo.published) {
    return true
  }
  return false
}

export const getCollectionPageYearFilter = yearly_data => {
  const collectionFilter = []
  yearly_data.map(data => {
    var data_json = JSON.parse(data)
    collectionFilter.push(data_json)
  })
  return collectionFilter
}

export const getDefaultCollectionYear = (yearly_data, only_year = false) => {
  const collectionFilter = []
  let year_data = [...yearly_data]
  year_data.sort((year1, year2) => {
    let year1_parsed_data = JSON.parse(year1)
    let year2_parsed_data = JSON.parse(year2)
    return year1_parsed_data.value !== 'all' || year2_parsed_data.value !== 'all'
      ? Number(year1_parsed_data.year) < Number(year2_parsed_data.year)
        ? 1
        : Number(year1_parsed_data.year) > Number(year2_parsed_data.year)
        ? -1
        : 0
      : 0
  })

  year_data.map(data => {
    var data_json = JSON.parse(data)
    collectionFilter.push(data_json)
  })
  return only_year ? collectionFilter[1].year : collectionFilter[1]
}

export const getYearPhotoCount = (yearly_data, dataYear) => {
  let data = yearly_data.filter(yearObj => {
    let year_parsed_obj = JSON.parse(yearObj)
    return year_parsed_obj.year === dataYear
  })
  let parsed_data = JSON.parse(data[0])
  return Number(parsed_data.count)
}

const IconMap = {
  Clock,
  CheckCircle,
  AlertTriangle,
  Slash,
  Award,
  Info
}
const ClassMap = {
  AlertTriangle: 'status_alert',
  CheckCircle: 'model-release__list--success',
  Slash: 'model-release__list--blocked',
  Clock: '',
  Award: 'model-release__list--success',
  Info: ''
}

export const getFormattedStatus = (icon, statusText, style = {}) => {
  const IconComponent = IconMap[icon]
  const CssClass = ClassMap[icon]
  return (
    <Typography
      variant='status'
      component={'div'}
      className={`${CssClass}`}
      sx={{ mt: { xs: 1 }, ...style }}
    >
      <Box component={'span'} className='icon-text-span' sx={{ display: 'flex' }}>
        <IconComponent size={16} style={{ marginTop: 3 }} /> {statusText}
      </Box>
    </Typography>
  )
}
