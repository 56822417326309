import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Calendar } from 'react-feather'
import UserDetailsModal from '../molecules/UserDetailsModal.js'
import { Typography, Grid, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import variables from '../../../src/ui/settings/_variables.scss'

const role = 'Student'

class ProjectsListRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalFacilitatorDetailsShow: false,
      modalTeacherDetailsShow: false
    }
  }
  render() {
    let modalClose = () => {
      this.setState({
        modalFacilitatorDetailsShow: false,
        modalTeacherDetailsShow: false
      })
    }
    const { project } = this.props
    return (
      <Box
        sx={{ px: { md: 6 }, mb: { md: 1 } }}
      >
        <Grid
          container
          rowGap={{ xs: 2 }}
          columnSpacing={{sm: 3, md : '0'}}
          sx={{ backgroundColor: variables.white, borderBottom: { xs: variables.borderThinGrey }, px: { xs: 3, md: 4 }, py: { xs: 3, md: 4 } }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Link to={`/project-detail/${project.id}`}>
              <Typography variant='link' className='large'>
                Photography • <i>wrkxfmly</i>
              </Typography>
            </Link>

            <Typography variant='dataLabel' className='content' component='p' sx={{ mt: 1 }}>
              {project.cohort.name}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} display={{xs: 'none', sm: 'unset'}}>
            <Box>
              <Typography variant='dataLabel' sx={{ mr: 1 }}>
                Teacher:
              </Typography>
              <Typography variant='dataLabel' className='content'>
                {_.includes(['Student'], role) ? (
                  project.teacher.name
                ) : (
                  <Link
                    component='a'
                    to={'#'}
                    onClick={() => this.setState({ modalTeacherDetailsShow: true })}
                  >
                    <Typography variant='link' className='large'>
                      {project.teacher.name}
                    </Typography>
                  </Link>
                )}
              </Typography>

              <UserDetailsModal
                show={this.state.modalTeacherDetailsShow}
                onHide={modalClose}
                name={project.teacher.name}
                userType='Teacher'
                email={project.teacher.email}
                mobileNumber={project.teacher.mobileNumber}
                userId={project.teacher.userId}
                tabChangeHandler={this.props.tabChangeHandler}
              />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant='dataLabel' sx={{ mr: 1 }}>
                Teaching Artist:
              </Typography>
              <Typography variant='dataLabel' className='content'>
                {project.facilitator ? (
                  project.facilitator.name
                ) : (
                  'No Teaching Artist assigned.'
                )}
              </Typography>

              {project.facilitator ? (
                <UserDetailsModal
                  show={this.state.modalFacilitatorDetailsShow}
                  onHide={modalClose}
                  name={project.facilitator.name}
                  bio={project.facilitator.bio}
                  userType='Teaching Artist'
                  website={project.facilitator.website}
                  email={project.facilitator.email}
                  mobileNumber={project.facilitator.mobileNumber}
                  instagramHandle={project.facilitator.instagramHandle}
                  userId={project.facilitator.userId}
                  tabChangeHandler={this.props.tabChangeHandler}
                />
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant='dataLabel' className='content' component='p'>
              {moment(project.cohort.startDate).format('LL')} —{'  '}
              {moment(project.cohort.endDate).format('LL')}
            </Typography>
            <Typography variant='note' component='p' sx={{ mt: 1 }}>
              <Calendar size={17} /> Next Visit:{' '}
              {project.cohort.nextFacilitatorVisit
                ? moment(project.cohort.nextFacilitatorVisit, 'YYYY-MM-DD').format('MMMM D, YYYY')
                : 'None Scheduled'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default ProjectsListRow
