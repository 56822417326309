import React, { useState } from 'react'
import { MoreHorizontal } from 'react-feather'
import PropTypes from 'prop-types'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import RemoveStudentModal from '../molecules/RemoveStudentModal.js'
import ReopenStudentProjectModal from '../molecules/ReopenStudentProjectModal.js'
import { Button, MenuItem, styled, Menu, alpha } from '@mui/material'

const REMOVE_STUDENT_MUTATION = gql`
  mutation RemoveStudentFromCohort($studentId: ID!, $cohortId: ID!) {
    removeStudentFromCohort(studentId: $studentId, cohortId: $cohortId) {
      id
    }
  }
`
const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    border: '1px solid black',
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}))

const DropdownMenu = props => {
  const [modalRemoveStudent, setModalRemoveStudent] = useState(false)
  const [modalReopenStudentProject, setModalReopenStudentProject] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClose = event => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return
    }
    setAnchorEl(null)
  }

  const handleRemoveStudent = (e, onMutate) => {
    e.preventDefault()
    onMutate({
      variables: {
        cohortId: props.cohortId,
        studentId: props.studentId
      },
      refetchQueries: ['ClassStudentDetails']
    })
    setModalRemoveStudent(false)
  }

  const openRemoveStudentDialog = e => {
    e.preventDefault()
    setModalRemoveStudent(true)
  }

  const openStudentProjectModal = e => {
    e.preventDefault()
    setModalReopenStudentProject(true)
  }

  const modalRemoveStudentClose = () => setModalRemoveStudent(false)
  const modalReopenStudentProjectClose = () => setModalReopenStudentProject(false)

  const toastMsg = 'The student was successfully removed from the class.'

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <div>
      <Button
        id='ellipse-button'
        aria-controls={open ? 'customized-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='text'
        disableElevation
        onClick={handleClick}
        style={{
          borderRadius: 0,
          border: 0,
          padding: 0,
          minWidth: 'fit-content'
        }}
      >
        <MoreHorizontal size={24} color='black' />
      </Button>
      <StyledMenu
        id='customized-menu'
        MenuListProps={{
          'aria-labelledby': 'ellipse-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={e => props.openResetModal(e)} disableRipple>
          Reset Password
        </MenuItem>
        <Mutation
          mutation={REMOVE_STUDENT_MUTATION}
          onCompleted={() => {
            props.showToast && props.showToast('success', toastMsg)
          }}
        >
          {(onMutate, { data }) =>
            props.role === 'appadmin' || (props.role === 'teacher' && !props.isClassPublished) ? (
              <div>
                {props.enableRemove ? (
                  <div>
                    <MenuItem onClick={e => openRemoveStudentDialog(e)}>
                      Remove Student from Class
                    </MenuItem>
                    <RemoveStudentModal
                      show={modalRemoveStudent}
                      onHide={modalRemoveStudentClose}
                      handleRemoveStudent={handleRemoveStudent}
                      onMutate={onMutate}
                      photoCount={props.photoCount}
                    />
                  </div>
                ) : (
                  <MenuItem disabled={true}>Remove Student from Class</MenuItem>
                )}
              </div>
            ) : null
          }
        </Mutation>
        {['appadmin', 'teacher', 'facilitator'].includes(props.role) &&
        props.projectId &&
        !props.isClassEnded &&
        props.projectSubmitted ? (
          <div>
            <MenuItem onClick={e => openStudentProjectModal(e)} disableRipple>
              Reopen Student Project
            </MenuItem>
            <ReopenStudentProjectModal
              show={modalReopenStudentProject}
              onHide={modalReopenStudentProjectClose}
              showToast={props.showToast}
              projectId={props.projectId}
              studentName={props.student.name}
              role={props.role}
            />
          </div>
        ) : null}
      </StyledMenu>
    </div>
  )
}

DropdownMenu.propTypes = {
  classes: PropTypes.object.isRequired
}

export default DropdownMenu
