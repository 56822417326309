import React, { Fragment, useEffect, useState } from 'react'
import ReviewListRow from '../molecules/ReviewListRow.js'
import EmptyListDesign from '../molecules/EmptyListDesign'
import { getTextContentByPage } from '../../content/textContent'
import { Box, Grid, Typography } from '@mui/material'
import CustomPagination from '../molecules/CustomPagination.js'

const accentColors = ['#005691', '#00746B', '#F68842', '#8E5BA6', '#C95147', '#FFCC32']

const ReviewList = props => {
  const [cohorts, setCohorts] = useState({})
  const [profileId, setProfileId] = useState(0)
  const [role, setRole] = useState('')
  const [reviewType, setReviewType] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(0)
  const [showPagination, setShowPagination] = useState(true)

  useEffect(() => {
    setCohorts(props.cohorts)
    setProfileId(props.profileId)
    setRole(props.role)
    setReviewType(props?.reviewType)
    setTotalPages(props.totalPages)
    setPage(props.page)
    setShowPagination(props?.showPagination === false ? false : true)
  }, [props])

  const userDetails = window.location.pathname.includes('user-details')
  return cohorts?.length ? (
    <Fragment>
      <Box sx={{ display: { xs: 'none', md: 'block' }, mb: 2, mt: 5 }}>
        <Grid container sx={{ px: 10 }} rowGap={{ xs: 2 }}>
          <Grid item md={4}>
            <Typography variant='dataLable' component={'span'}>
              Class Details
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant='dataLable' component={'span'}>
              Review Status
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant='dataLable' component={'span'}>
              Dates
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {cohorts.map(cohort => (
        <ReviewListRow
          profileId={profileId}
          key={userDetails ? cohort.id : cohort.id}
          cohort={userDetails ? cohort : cohort}
          color={
            userDetails
              ? accentColors[cohort.id % accentColors.length]
              : accentColors[cohort.id % accentColors.length]
          }
          role={role}
          reviewType={reviewType}
        />
      ))}
      {showPagination ? (
        <Box sx={{ mt: 4, mr: { md: 4 } }}>
          <CustomPagination totalPages={totalPages} pageNum={page + 1} onChange={props?.setPage} />
        </Box>
      ) : (
        <Box sx={{ pt: 4 }} />
      )}
    </Fragment>
  ) : (
    <EmptyListDesign
      heading={getTextContentByPage('CURATOR', role.toUpperCase(), 'REVIEW_EMPTYINTRO')}
      subheading={getTextContentByPage('CURATOR', role.toUpperCase(), 'REVIEW_EMPTYSUBHEADING')}
    />
  )
}

// class ReviewList extends Component {
//   render() {
//     const userDetails = window.location.pathname.includes('user-details')
//     return this.props.cohorts?.length ? (
//       <Fragment>
//         <Box sx={{ display: { xs: 'none', md: 'block' }, mb: 2, mt: 5 }}>
//           <Grid container sx={{ px: 10 }} rowGap={{ xs: 2 }}>
//             <Grid item md={4}>
//               <Typography variant='dataLable' component={'span'}>
//                 Class Details
//               </Typography>
//             </Grid>
//             <Grid item md={4}>
//               <Typography variant='dataLable' component={'span'}>
//                 Review Status
//               </Typography>
//             </Grid>
//             <Grid item md={4}>
//               <Typography variant='dataLable' component={'span'}>
//                 Dates
//               </Typography>
//             </Grid>
//           </Grid>
//         </Box>
//         {this.props.cohorts.map(cohort => (
//           <ReviewListRow
//             profileId={this.props.profileId}
//             key={userDetails ? cohort.id : cohort.id}
//             cohort={userDetails ? cohort : cohort}
//             color={
//               userDetails
//                 ? accentColors[cohort.id % accentColors.length]
//                 : accentColors[cohort.id % accentColors.length]
//             }
//             role={this.props.role}
//             reviewType={this.props.reviewType}
//           />
//         ))}
//         <Box sx={{ mt: 4, mr: {md: 4} }}>
//           <CustomPagination
//             totalPages={this.props.totalPages}
//             pageNum={this.props.page + 1}
//             onChange={this.props.setPage}
//           />
//         </Box>
//       </Fragment>
//     ) : (
//       <EmptyListDesign
//         heading={getTextContentByPage(
//           'CURATOR',
//           this.props.role.toUpperCase(),
//           'REVIEW_EMPTYINTRO'
//         )}
//         subheading={getTextContentByPage(
//           'CURATOR',
//           this.props.role.toUpperCase(),
//           'REVIEW_EMPTYSUBHEADING'
//         )}
//       />
//     )
//   }
// }

export default ReviewList
