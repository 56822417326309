import React, { Fragment, useState } from 'react'
import './modals.scss'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { gql, useQuery } from '@apollo/client'
import moment from 'moment'
import { Typography } from '@mui/material'
import Loading from '../elements/Loading.js'
import UserDetailsModal from './UserDetailsModal'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import BaseModal from '../template/BaseModal'

const cohortSchoolQuery = gql`
  query Cohort($id: ID!) {
    cohort(id: $id) {
      id
      gradeLevel
      teacher {
        name
        id
        userId
        email
        mobileNumber
      }
      submittedProjects {
        id
      }
      students {
        id
      }
      facilitator {
        name
        id
        userId
        bio
        instagramHandle
        website
        email
        mobileNumber
      }
      school {
        id
        name
        website
        demographicLink
        schoolDistrict
        createdAt
        schoolType
        mailingAddress {
          id
          city
          state
          address1
          address2
          postCode
        }
        students {
          id
        }
        photos {
          id
          awards {
            awardClass
            id
          }
        }
      }
    }
  }
`
const NOT_AVAILABLE = 'Not Available'

const ClassReviewSchoolDetailsModal = props => {
  const [cohort, setCohort] = useState({})
  const [school, setSchool] = useState({})
  const [modalFacilitatorDetailsShow, setModalFacilitatorDetailsShow] = useState(false)
  const [modalTeacherDetailsShow, setModalTeacherDetailsShow] = useState(false)
  
  const { role } = props.userStore
  const classReview = window.location.pathname.includes('class-review')

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: 'calc(100% - 48px)', sm: 'calc(100% - 64px)', md: '800px' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    pb: 4
  }

  const { loading, error, data } = useQuery(cohortSchoolQuery, {
    skip: !props.cohortId,
    variables: { id: props.cohortId },
    onCompleted(data) {
      setCohort(data.cohort)
      setSchool(data.cohort.school)
    }
  })

  if (error) {
    return (
      <Typography variant='paragraph' component='p'>
        Error loading classes
      </Typography>
    )
  }

  const modalClose = () => {
    setModalFacilitatorDetailsShow(false)
    setModalTeacherDetailsShow(false)
  }

  const handleClose = () => {
    props?.onHide?.(false)
  }

  const awardsOfExcellence =
    school?.photos?.filter(
      p => p.awards.filter(a => a.awardClass === 'Award of Excellence').length > 0
    ).length
  const honorableMentions =
    school?.photos?.filter(
      p => p.awards.filter(a => a.awardClass === 'Honorable Mention').length > 0
    ).length

  return (
    <BaseModal
      {...props}
      show={props.show}
      onClick={props.onClick}
      value={props.value}
      open={props.show}
      onClose={handleClose}
      onHide={props.onHide}
      sx={{ style }}
    >
      <BaseModal.BaseModalHeader>
        {loading && !data ? (
          'Loading...'
        ) : (
          <>
            {school.name}
            {school.mailingAddress ? (
              <Fragment>
                <Typography variant='h4'>
                  {school.mailingAddress.address1}, {school.mailingAddress.address2}
                </Typography>
                <Typography variant='h4'>
                  {school.mailingAddress.city}, {school.mailingAddress.state}{' '}
                  {school.mailingAddress.postCode}
                </Typography>
              </Fragment>
            ) : null}
          </>
        )}
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        {loading && !data ? (
          <Typography component={'div'}>
            <Loading />
          </Typography>
        ) : (
          <>
            <Box>
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <Typography variant='h3' className='semi-strong' sx={{ paddingBottom: '2px' }}>
                    School Information
                  </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                  <Typography variant='h4'>School Website</Typography>
                  {school.website ? (
                    <a
                      href={school.website}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ overflowWrap: 'break-word' }}
                    >
                      <Typography
                        variant='link'
                        sx={{
                          fontWeight: '400',
                          lineHeight: '1.334',
                          letterSpacing: '0.2px',
                          fontFamily: '"Calibre Semibold", Helvetica, Verdana, sans-serif'
                        }}
                      >
                        {school.website.length > 40
                          ? school.website.substring(0, 40) + '...'
                          : school.website}
                      </Typography>
                    </a>
                  ) : (
                    <Typography variant='h5' className='school-detail-link'>
                      {NOT_AVAILABLE}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                  <Typography variant='h4'>School Demographics</Typography>
                  {school.demographicLink ? (
                    <a
                      href={school.demographicLink}
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ overflowWrap: 'break-word' }}
                    >
                      <Typography
                        variant='link'
                        sx={{
                          fontWeight: '400',
                          lineHeight: '1.334',
                          letterSpacing: '0.2px',
                          fontFamily: '"Calibre Semibold", Helvetica, Verdana, sans-serif'
                        }}
                      >
                        {school.demographicLink.length > 40
                          ? school.demographicLink.substring(0, 40) + '...'
                          : school.demographicLink}
                      </Typography>
                    </a>
                  ) : (
                    <Typography variant='h5' className='school-detail-link'>
                      {NOT_AVAILABLE}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '32px !important' }}>
                  <Typography variant='h4'>School Type</Typography>
                  <Typography variant='h5' className='school-detail-link'>
                    {school.schoolType ? school.schoolType : NOT_AVAILABLE}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '32px !important' }}>
                  <Typography variant='h4'>School District</Typography>
                  <Typography variant='h5' className='school-detail-link'>
                    {school.schoolDistrict ? school.schoolDistrict : NOT_AVAILABLE}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container spacing={2}>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ marginTop: '9px', paddingTop: '32px !important' }}
                >
                  <Typography variant='h3' className='semi-strong'>
                    {classReview ? 'School Program History' : 'Program Information'}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                  <Typography variant='h4'>Date Joined</Typography>
                  <Typography variant='h5' className='school-detail-link'>
                    {moment(school.createdAt).format('LL')}
                  </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                  <Typography variant='h4'>Students</Typography>
                  <Typography variant='h5' className='school-detail-link'>
                    {school?.students?.length} Students Enrolled
                  </Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                  {awardsOfExcellence || honorableMentions ? (
                    <div>
                      <Typography variant='h4'>Awards</Typography>
                      <Typography variant='h5' className='school-detail-link'>
                        {awardsOfExcellence} Awards of Excellence
                        <br />
                        {honorableMentions} Honorable Mentions
                      </Typography>
                    </div>
                  ) : null}
                </Grid>
                <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                  <Typography variant='h4'>Photographs</Typography>
                  <Typography variant='h5' className='school-detail-link'>
                    {school?.photos?.length} Submitted
                    <br />
                    &nbsp;
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {classReview ? (
              <Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ marginTop: '9px', paddingTop: '32px !important' }}
                  >
                    <Typography variant='h3' className='semi-strong'>
                      Class Information
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                    <Typography variant='h4'>Teacher</Typography>
                    {cohort.teacher ? (
                      <Fragment>
                        <Typography
                          variant='h5'
                          className='semi-strong link'
                          onClick={() => setModalTeacherDetailsShow(true)}
                        >
                          {cohort.teacher.name}
                        </Typography>

                        <UserDetailsModal
                          show={modalTeacherDetailsShow}
                          onHide={modalClose}
                          name={cohort.teacher.name}
                          userType='Teacher'
                          email={cohort.teacher.email}
                          mobileNumber={cohort.teacher.mobileNumber}
                          userId={cohort.teacher.userId}
                        />
                      </Fragment>
                    ) : (
                      <Typography variant='h5'>{NOT_AVAILABLE}</Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                    <Typography variant='h4'>Teaching Artist</Typography>
                    {cohort.facilitator ? (
                      <Fragment>
                        <Typography
                          variant='h5'
                          className='semi-strong link'
                          onClick={() => setModalFacilitatorDetailsShow(true)}
                        >
                          {cohort.facilitator.name}
                        </Typography>
                        <UserDetailsModal
                          show={modalFacilitatorDetailsShow}
                          onHide={modalClose}
                          name={cohort.facilitator.name}
                          bio={cohort.facilitator.bio}
                          userType='Teaching Artist'
                          website={cohort.facilitator.website}
                          email={cohort.facilitator.email}
                          mobileNumber={cohort.facilitator.mobileNumber}
                          instagramHandle={cohort.facilitator.instagramHandle}
                          userId={cohort.facilitator.userId}
                        />
                      </Fragment>
                    ) : (
                      <Typography variant='h5'>{NOT_AVAILABLE}</Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                    <Typography variant='h4'>Grade Level(s)</Typography>
                    <Typography variant='h5' className='school-detail-link'>
                      {cohort.gradeLevel}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                    <Typography variant='h4'>Total Students in Class</Typography>
                    <Typography variant='h5' className='school-detail-link'>
                      {cohort?.students?.length} Students
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
                    <Typography variant='h4'>Total Projects Submitted</Typography>
                    <Typography variant='h5' className='school-detail-link'>
                      {cohort?.submittedProjects?.length} Submitted
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : null}
            {role === 'appadmin' ? (
              <Box
                sx={{ padding: { xs: '0 0 8px', sm: '24px 0 8px' }, marginTop: '0px' }}
                className='send-message__button'
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Link
                      sx={{ width: { xs: '100%', sm: '100%' } }}
                      className='school-details__view-more'
                      to={'/school-details/' + school.id}
                    >
                      View More School Details
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box
                sx={{ paddingBottom: '8px', marginTop: '0px' }}
                className='send-message__button'
              />
            )}
          </>
        )}
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

// class ClassReviewSchoolDetailsModal extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       modalFacilitatorDetailsShow: false,
//       modalTeacherDetailsShow: false
//     }
//     this.classReview = window.location.pathname.includes('class-review')
//   }
//   render() {
//     let modalClose = () => {
//       this.setState({
//         modalFacilitatorDetailsShow: false,
//         modalTeacherDetailsShow: false
//       })
//     }

//     const handleClose = () => {
//       this.setState({ show: false })
//       this.props.onHide(false)
//     }

//     const style = {
//       position: 'absolute',
//       top: '50%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//       width: { xs: 'calc(100% - 48px)', sm: 'calc(100% - 64px)', md: '800px' },
//       bgcolor: 'background.paper',
//       boxShadow: 24,
//       pb: 4
//     }

//     return (
//       <Query query={cohortSchoolQuery} variables={{ id: this.props.cohortId }}>
//         {({ loading, error, data }) => {
//           if (loading) return <Loading />
//           if (error) {
//             return (
//               <Typography variant='paragraph' component='p'>
//                 Error loading classes
//               </Typography>
//             )
//           }
//           if (data) {
//             const { cohort } = data
//             const { school } = cohort
//             const role = this.props.userStore.role
//             const students = school.students.length
//             const awardsOfExcellence = school.photos.filter(
//               p => p.awards.filter(a => a.awardClass === 'Award of Excellence').length > 0
//             ).length
//             const honorableMentions = school.photos.filter(
//               p => p.awards.filter(a => a.awardClass === 'Honorable Mention').length > 0
//             ).length

//             return (
//               <BaseModal
//                 {...this.props}
//                 show={this.props.show}
//                 onClick={this.props.onClick}
//                 value={this.props.value}
//                 open={this.props.show}
//                 onClose={handleClose}
//                 onHide={this.props.onHide}
//                 sx={{ style }}
//               >
//                 <BaseModal.BaseModalHeader>
//                   {school.name}
//                   {school.mailingAddress ? (
//                     <Fragment>
//                       <Typography variant='h4'>
//                         {school.mailingAddress.address1}, {school.mailingAddress.address2}
//                       </Typography>
//                       <Typography variant='h4'>
//                         {school.mailingAddress.city}, {school.mailingAddress.state}{' '}
//                         {school.mailingAddress.postCode}
//                       </Typography>
//                     </Fragment>
//                   ) : null}
//                 </BaseModal.BaseModalHeader>
//                 <BaseModal.BaseModalBody>
//                   <Box>
//                     <Grid container spacing={2}>
//                       <Grid item md={12} sm={12} xs={12}>
//                         <Typography
//                           variant='h3'
//                           className='semi-strong'
//                           sx={{ paddingBottom: '2px' }}
//                         >
//                           School Information
//                         </Typography>
//                       </Grid>
//                       <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                         <Typography variant='h4'>School Website</Typography>
//                         {school.website ? (
//                           <a
//                             href={school.website}
//                             target='_blank'
//                             rel='noopener noreferrer'
//                             style={{ overflowWrap: 'break-word' }}
//                           >
//                             <Typography
//                               variant='link'
//                               sx={{
//                                 fontWeight: '400',
//                                 lineHeight: '1.334',
//                                 letterSpacing: '0.2px',
//                                 fontFamily: '"Calibre Semibold", Helvetica, Verdana, sans-serif'
//                               }}
//                             >
//                               {school.website.length > 40
//                                 ? school.website.substring(0, 40) + '...'
//                                 : school.website}
//                             </Typography>
//                           </a>
//                         ) : (
//                           <Typography variant='h5' className='school-detail-link'>
//                             {NOT_AVAILABLE}
//                           </Typography>
//                         )}
//                       </Grid>
//                       <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                         <Typography variant='h4'>School Demographics</Typography>
//                         {school.demographicLink ? (
//                           <a
//                             href={school.demographicLink}
//                             target='_blank'
//                             rel='noopener noreferrer'
//                             style={{ overflowWrap: 'break-word' }}
//                           >
//                             <Typography
//                               variant='link'
//                               sx={{
//                                 fontWeight: '400',
//                                 lineHeight: '1.334',
//                                 letterSpacing: '0.2px',
//                                 fontFamily: '"Calibre Semibold", Helvetica, Verdana, sans-serif'
//                               }}
//                             >
//                               {school.demographicLink.length > 40
//                                 ? school.demographicLink.substring(0, 40) + '...'
//                                 : school.demographicLink}
//                             </Typography>
//                           </a>
//                         ) : (
//                           <Typography variant='h5' className='school-detail-link'>
//                             {NOT_AVAILABLE}
//                           </Typography>
//                         )}
//                       </Grid>
//                       <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '32px !important' }}>
//                         <Typography variant='h4'>School Type</Typography>
//                         <Typography variant='h5' className='school-detail-link'>
//                           {school.schoolType ? school.schoolType : NOT_AVAILABLE}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '32px !important' }}>
//                         <Typography variant='h4'>School District</Typography>
//                         <Typography variant='h5' className='school-detail-link'>
//                           {school.schoolDistrict ? school.schoolDistrict : NOT_AVAILABLE}
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                   </Box>
//                   <Box>
//                     <Grid container spacing={2}>
//                       <Grid
//                         item
//                         md={12}
//                         sm={12}
//                         xs={12}
//                         sx={{ marginTop: '9px', paddingTop: '32px !important' }}
//                       >
//                         <Typography variant='h3' className='semi-strong'>
//                           {this.classReview ? 'School Program History' : 'Program Information'}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                         <Typography variant='h4'>Date Joined</Typography>
//                         <Typography variant='h5' className='school-detail-link'>
//                           {moment(school.createdAt).format('LL')}
//                         </Typography>
//                       </Grid>
//                       <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                         <Typography variant='h4'>Students</Typography>
//                         <Typography variant='h5' className='school-detail-link'>
//                           {students} Students Enrolled
//                         </Typography>
//                       </Grid>
//                       <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                         {awardsOfExcellence || honorableMentions ? (
//                           <div>
//                             <Typography variant='h4'>Awards</Typography>
//                             <Typography variant='h5' className='school-detail-link'>
//                               {awardsOfExcellence} Awards of Excellence
//                               <br />
//                               {honorableMentions} Honorable Mentions
//                             </Typography>
//                           </div>
//                         ) : null}
//                       </Grid>
//                       <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                         <Typography variant='h4'>Photographs</Typography>
//                         <Typography variant='h5' className='school-detail-link'>
//                           {school.photos.length} Submitted
//                           <br />
//                           &nbsp;
//                         </Typography>
//                       </Grid>
//                     </Grid>
//                   </Box>
//                   {this.classReview ? (
//                     <Box>
//                       <Grid container spacing={2}>
//                         <Grid
//                           item
//                           md={12}
//                           sm={12}
//                           xs={12}
//                           sx={{ marginTop: '9px', paddingTop: '32px !important' }}
//                         >
//                           <Typography variant='h3' className='semi-strong'>
//                             Class Information
//                           </Typography>
//                         </Grid>
//                         <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                           <Typography variant='h4'>Teacher</Typography>
//                           {cohort.teacher ? (
//                             <Fragment>
//                               <Typography
//                                 variant='h5'
//                                 className='semi-strong link'
//                                 onClick={() => this.setState({ modalTeacherDetailsShow: true })}
//                               >
//                                 {cohort.teacher.name}
//                               </Typography>

//                               <UserDetailsModal
//                                 show={this.state.modalTeacherDetailsShow}
//                                 onHide={modalClose}
//                                 name={cohort.teacher.name}
//                                 userType='Teacher'
//                                 email={cohort.teacher.email}
//                                 mobileNumber={cohort.teacher.mobileNumber}
//                                 userId={cohort.teacher.userId}
//                               />
//                             </Fragment>
//                           ) : (
//                             <Typography variant='h5'>{NOT_AVAILABLE}</Typography>
//                           )}
//                         </Grid>
//                         <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                           <Typography variant='h4'>Teaching Artist</Typography>
//                           {cohort.facilitator ? (
//                             <Fragment>
//                               <Typography
//                                 variant='h5'
//                                 className='semi-strong link'
//                                 onClick={() => this.setState({ modalFacilitatorDetailsShow: true })}
//                               >
//                                 {cohort.facilitator.name}
//                               </Typography>
//                               <UserDetailsModal
//                                 show={this.state.modalFacilitatorDetailsShow}
//                                 onHide={modalClose}
//                                 name={cohort.facilitator.name}
//                                 bio={cohort.facilitator.bio}
//                                 userType='Teaching Artist'
//                                 website={cohort.facilitator.website}
//                                 email={cohort.facilitator.email}
//                                 mobileNumber={cohort.facilitator.mobileNumber}
//                                 instagramHandle={cohort.facilitator.instagramHandle}
//                                 userId={cohort.facilitator.userId}
//                               />
//                             </Fragment>
//                           ) : (
//                             <Typography variant='h5'>{NOT_AVAILABLE}</Typography>
//                           )}
//                         </Grid>
//                         <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                           <Typography variant='h4'>Grade Level(s)</Typography>
//                           <Typography variant='h5' className='school-detail-link'>
//                             {cohort.gradeLevel}
//                           </Typography>
//                         </Grid>
//                         <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                           <Typography variant='h4'>Total Students in Class</Typography>
//                           <Typography variant='h5' className='school-detail-link'>
//                             {cohort.students.length} Students
//                           </Typography>
//                         </Grid>
//                         <Grid item md={6} sm={6} xs={12} sx={{ paddingTop: '16px !important' }}>
//                           <Typography variant='h4'>Total Projects Submitted</Typography>
//                           <Typography variant='h5' className='school-detail-link'>
//                             {cohort.submittedProjects.length} Submitted
//                           </Typography>
//                         </Grid>
//                       </Grid>
//                     </Box>
//                   ) : null}
//                   {role === 'appadmin' ? (
//                     <Box
//                       sx={{ padding: { xs: '0 0 8px', sm: '24px 0 8px' }, marginTop: '0px' }}
//                       className='send-message__button'
//                     >
//                       <Grid container>
//                         <Grid item xs={12}>
//                           <Link
//                             sx={{ width: { xs: '100%', sm: '100%' } }}
//                             className='school-details__view-more'
//                             to={'/school-details/' + this.props.schoolId}
//                           >
//                             View More School Details
//                           </Link>
//                         </Grid>
//                       </Grid>
//                     </Box>
//                   ) : (
//                     <Box
//                       sx={{ paddingBottom: '8px', marginTop: '0px' }}
//                       className='send-message__button'
//                     />
//                   )}
//                 </BaseModal.BaseModalBody>
//               </BaseModal>
//             )
//           }
//         }}
//       </Query>
//     )
//   }
// }

export default inject('userStore')(observer(ClassReviewSchoolDetailsModal))
