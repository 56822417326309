import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardMedia, Typography } from '@mui/material'

function ResourceLink(props) {
  const linkTo = `/resource-category/${props.resourceId}/${props.resourceLinkTitle.replace(/\s+/g, '-')}`;
  return (
    <Card  sx={{boxShadow: 'none', textAlign:'center', mb: {xs: 2, sm: 3}}}>
      <Link to={linkTo}>
        <CardMedia
          component="img"
          alt={props.resourceLinkTitle}
          image={props.resourceImage && props.resourceImage[0].url}
          sx={{ height: 'auto', pb: 1 }}
        />
      </Link>
      <Link to={linkTo}>
        <Typography variant="h4" className='strong link' my={1} pb={1} gutterBottom sx={{fontSize: {md: '1.125rem'}}}>
          {props.resourceLinkTitle}
        </Typography>
      </Link>
    </Card>
  )
}

export default ResourceLink
