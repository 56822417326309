import { useMutation } from '@apollo/client'
import { Typography, Box, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Button from '../../../elements/Button'
import DatePicker from '../../../elements/DatePicker'
import { OVERRIDE_TITLE_AND_CAPTION_MUTATION } from '../../../../queries/PhotoDetailsQueries'
import FormField from '../../../elements/FormField.js'
import FormFieldMultiline from '../../../elements/FormFieldMultiline.js'
import { isPublishSectionEnable } from '../../../../utils/commonFunctions'


const AdminSubmittedDateOverride = props => {
  const { photoId, photoDetails, refetchPhoto, role, userStore } = props
  const { isAuthenticated } = userStore
  const publicView = !isAuthenticated ? true : false

  const [firstPhotoID, setFirstPhotoID] = useState(photoDetails && photoDetails.id)

  const [submittedDate, setSubmittedDate] = useState(photoDetails && photoDetails.submittedDate)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const [editedTitle, setEditedTitle] = useState(photoDetails && photoDetails.editedTitle)

  const [editedTitleError, setEditedTitleError] = useState(photoDetails?.editedTitleError || null)

  const [editedCaption, setEditedCaption] = useState(photoDetails && photoDetails.editedCaption)

  const [editedCaptionError, setEditedCaptionError] = useState(
    photoDetails?.editedCaptionError || null
  )

  useEffect(() => {
    props.setFormIsValid?.(editedTitleError || editedCaptionError ? false : true);
  },[props, editedTitleError, editedCaptionError])

  const [overrideTitleAndCaptionMutation] = useMutation(OVERRIDE_TITLE_AND_CAPTION_MUTATION, {
    onCompleted(data) {
      refetchPhoto()
      setIsSubmitting(false)
      props.showToast('success', 'All overrides were saved.')
    }
  })

  const validateTitle = e => {
    const { name, value } = e.target
    if (name === 'editedTitle') {
      setEditedTitle(value || '')
      setEditedTitleError(value ? null : 'Please enter a title before publishing this photograph.')
    }
  };


  const validateCaption = e => {
    const { name, value } = e.target
    if (name === 'editedCaption') {
      setEditedCaption(value || '')
      setEditedCaptionError(value ? null : 'Please enter a caption before publishing this photograph.');
    }
  }

  const resetValues = () => {
    setEditedTitle(props.photoDetails && props.photoDetails.editedTitle)
    setEditedCaption(props.photoDetails && props.photoDetails.editedCaption)
    setSubmittedDate(props.photoDetails.submittedDate)
    setEditedTitleError(null)
    setEditedCaptionError(null)
  }

  useEffect(() => {
    if (submittedDate == null) {
      setSubmittedDate(props.photoDetails && props.photoDetails.submittedDate)
    }

    if (firstPhotoID !== props.photoDetails.id) {
      resetValues()
      setFirstPhotoID(props.photoDetails && props.photoDetails.id)
    }
  }, [props])

  useEffect(() => {
    props.setFormIsValid?.(editedTitle !== props.photoDetails.editedTitle || editedCaption !== props.photoDetails.editedCaption ? false : true);
  },[props, editedTitle, editedCaption])

  return (
    <>
      {isPublishSectionEnable ? (!publicView && photoDetails && photoDetails.submitted && ['appadmin'].includes(role) ? (
        <Box
          component='div'
          className='photo-detail__file-replace'
          sx={{ pt: { xs: 4 }, pb: { xs: 8, lg: 10 } }}
        >
          <Typography variant='h2' className='strong'>
            Admin Overrides
          </Typography>
          <Typography variant='paragraph' component='p' sx={{ mt: { xs: 3 } }}>
            Before you publish this photograph, review the student’s original title and caption for
            any spelling or grammar errors. Enter the final version of the title and caption below.
          </Typography>
          <Typography sx={{ pt: '8px', pb: '32px' }} variant='paragraph' component='p'>
            This text will only appear when the photograph is published in our public collections.
            Students who are logged in to the web app will still see the original title and caption
            when they view their own projects.
          </Typography>
          <form onSubmit={e => e.preventDefault()}>
            <FormField
              formFieldVariant='filled'
              formFieldId='Edited-Title'
              formFieldLabel='Edited Title'
              formFieldClasses={{
                root: 'text-field__outline'
              }}
              formFieldType='text'
              formFieldName='editedTitle'
              formFieldValue={editedTitle ? editedTitle : ''}
              onChange={e => {
                validateTitle(e)
              }}
              onBlur={e => {
                validateTitle(e)
              }}
              formFieldError={editedTitleError || null}
            />
            <Box xs={12} sx={{ pt: editedTitleError ? '10px' : '40px' }}>
              <FormFieldMultiline
                formFieldVariant='filled'
                formFieldId='Edited-Caption'
                formFieldLabel='Edited Caption'
                multiline={true}
                rows='3'
                formFieldType='text'
                formFieldName='editedCaption'
                formFieldDefaultValue={editedCaption ? editedCaption : ''}
                onChange={e => {
                  validateCaption(e)
                }}
                onBlur={e => {
                  validateCaption(e)
                }}
                formFieldError={editedCaptionError || null}
                sx={{
                  '.MuiInputBase-root': {
                    pt: '24px'
                  }
                }}
              />
            </Box>
          </form>

          <Typography
            variant='paragraph'
            component='p'
            sx={{ pt: editedCaptionError ? '10px' : '40px' }}
          >
            If the project submission date does not reflect the year that the photographs was
            originally submitted, enter a different date here.
          </Typography>
          <Typography sx={{ pt: '8px' }} variant='paragraph' component='p'>
            The year from the new date will appear in the photograph attribution when published to
            the National Collection.
          </Typography>
          <Grid container sx={{ mt: { xs: 4 } }}>
            <Grid item xs={12} sm={6} md={4}>
              <DatePicker
                onChange={date => {
                  setSubmittedDate(date)
                }}
                value={submittedDate}
                label='Photograph Submission Date'
                sx={{ label: { zIndex: 'inherit' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                component='div'
                sx={{
                  display: { xs: 'grid', sm: 'flex' },
                  textAlign: 'center',
                  gap: { xs: 2, md: 3 },
                  mt: { xs: 4 },
                  alignItems: { sm: 'baseline' }
                }}
              >
                <Button
                  title={
                    isSubmitting ? (
                      <span className='icon-text-span'>
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />{' '}
                        Saving...
                      </span>
                    ) : (
                      'Save Overrides'
                    )
                  }
                  onClick={() => {
                    setIsSubmitting(true)
                    overrideTitleAndCaptionMutation({
                      variables: {
                        photoId: photoId,
                        editedTitle: editedTitle,
                        editedCaption: editedCaption,
                        submittedDate: submittedDate
                      }
                    })
                  }}
                  disabled={
                    submittedDate === null ||
                    submittedDate == 'Invalid Date' ||
                    editedTitleError !== null ||
                    editedCaptionError !== null ||
                    ['', null, undefined].includes(editedTitle) ||
                    ['', null, undefined].includes(editedCaption) ||
                    (props.photoDetails.submittedDate === submittedDate &&
                      props.photoDetails.editedTitle?.trim() === editedTitle?.trim() &&
                      props.photoDetails.editedCaption?.trim() === editedCaption?.trim())
                  }
                  sx={{
                    width: { sm: '33%', md: 'auto' }
                  }}
                />
                {props.photoDetails.submittedDate !== submittedDate ||
                props.photoDetails.editedTitle?.trim() !== editedTitle?.trim() ||
                props.photoDetails.editedCaption?.trim() !== editedCaption?.trim() ? (
                  <Typography variant='link' onClick={() => resetValues()}>
                    Cancel
                  </Typography>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : null) : null}
    </>
  )
}

export default AdminSubmittedDateOverride
