import React, { Component } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import { AlertTriangle } from 'react-feather'
import { Box, Grid, Typography } from '@mui/material'
import BaseModal from '../template/BaseModal';
import { getFormattedStatus } from '../../utils/commonFunctions'

class RemoveStudentModal extends Component {
  render() {
    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);

    };
    return (
      <BaseModal
      open={this.props.show}
      onClose={handleClose}
      onHide={this.props.onHide}
    >  
      <BaseModal.BaseModalHeader>
        Remove Student from Class
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
          {/* <div className='invite-student__description' id="padding-bottom-1rem" > */}
          <Box>
            <Grid container>
              <Grid item>
                <Typography variant="paragraph" component="p" sx={{pt:'5px'}}>Be sure that you want to remove this student from the class. If so:</Typography>
                <Typography variant="paragraph" component="p" sx={{pt:'5px'}}>1. The student’s project for this class will be deleted. They will no longer be able to access or upload work to this project.</Typography>
                <Typography variant="paragraph" component="p" sx={{pt:'5px'}}>2. The student's <i>wrkfmly</i> account and corresponding login will be retained. If the student has completed other projects in the web app, these will not be affected.</Typography>
              </Grid>
              {
                this.props.photoCount ?
                <Grid item sx={{ color: 'red',
                  border: '1px solid red',
                  padding: '16px',
                  marginTop: '20px'
                }}>
                {getFormattedStatus('AlertTriangle', `This student's project contains` + ` ${this.props.photoCount}` + ' photographs. These photographs will be deleted when the student is removed from the class.', { fontFamily: 'system-ui', lineHeight: '25.6px', fontSize: '16px'})}
                </Grid>
                : null
              }
            </Grid>
            <BaseModal.BaseModalFooter>
            <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
              <Grid container direction="row" dir="rtl">
                <Grid item xs={12} sm={6} md={4}>
                  <Button variant='contained' sx={{width:{ xs: '100%', sm: '100%' }}} title='Remove Student' disabled={false} onClick={(e) => this.props.handleRemoveStudent(e, this.props.onMutate)}/>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                  <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4" className='small_size_semi_bold link' onClick={() => this.props.onHide()}>
                    Cancel
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            </BaseModal.BaseModalFooter>
          </Box>
        </BaseModal.BaseModalBody>
      </BaseModal>
    )
  }
}

export default RemoveStudentModal
