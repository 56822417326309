import React, { useEffect, useState } from 'react'
import Button from '../elements/Button.js'
import ClassList from './ClassList.js'
import { withApollo } from '@apollo/client/react/hoc'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import UpcomingVisits from '../molecules/UpcomingVisits'
import { observer, inject } from 'mobx-react'
import Loading from '../elements/Loading.js'
import ListSearchBar from '../elements/ListSearchBar'
import { COHORT_LIST_QUERY, UPCOMING_TA_VISIT_DATES } from '../../queries/classListQueries'
import { getTextContentByPage } from '../../content/textContent'
import EmptyListDesign from '../molecules/EmptyListDesign'
import { Box, Grid, Typography } from '@mui/material'
import ErrorPage from '../../pages/ErrorPage'
import { useQuery } from '@apollo/client'

const BATCH_SIZE = 9

const CurrentClassesList = props => {
  const [role] = useState(props.userStore.role)
  const [upcomingVisits, setUpcomingVisits] = useState([])
  const [searchKey, setSearchKey] = useState('')
  const [step, setStep] = useState(0)
  const [batchSize] = useState(BATCH_SIZE)
  const [baseBatchSize] = useState(BATCH_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [listType, setListType] = useState(props.type)

  const navigate = useNavigate()
  const location = useLocation()

  const { loading, error, data } = useQuery(COHORT_LIST_QUERY, {
    variables: {
      batchSize: batchSize,
      baseBatchSize: baseBatchSize,
      step: step,
      search: searchKey,
      listType: listType
    },
    fetchPolicy: 'network-only' // Use this fetch policy if you want to always fetch data from the network
  })

  const cohorts = data?.cohortsConnection || []
  const cohortCount =
    (listType === 'current'
      ? data?.cohortCount
      : props.type === 'upcoming'
      ? data?.upcomingCohortCount
      : data?.pastCohortCount) || 0

  const handlePageChange = page => {
    setStep(page)
    // Update the URL
    const pathParts = location.pathname.split('/').filter(Boolean)
    if (!isNaN(pathParts[pathParts.length - 1])) {
      pathParts.pop()
    }
    const newPath = `/${pathParts.join('/')}/${page + 1}`
    navigate(newPath)
  }

  useEffect(() => {
    props.client
      .watchQuery({
        query: UPCOMING_TA_VISIT_DATES,
        variables: {
          batchSize: 6,
          dayDiff: 7,
          step: 0,
          visitPage: 'classes'
        },
        fetchPolicy: 'network-only'
      })
      .subscribe(({ data }) => {
        setUpcomingVisits(data.taVisitDatesList)
      })
  }, [props.client])

  useEffect(() => {
    setStep(isNaN(props.page) ? 0 : props.page - 1)
    setListType(props.type)
  }, [props])

  useEffect(() => {
    if(searchKey) {
      handlePageChange(0)
    }
  }, [searchKey])

  useEffect(() => {
    setTotalPages(Math.ceil(cohortCount / batchSize))
  }, [batchSize, cohortCount])

  return (
    <Box sx={{ mt: { xs: 5, sm: 5, md: 6, lg: 10 }, pb: { xs: 4 } }}>
      <Box sx={{ px: { xs: 3, sm: 4, md: 6 } }}>
        {loading && (
          <Box className='classes__loading'>
            <Loading />
          </Box>
        )}
        {error && (
          <Box className='classes__loading'>
            <ErrorPage />
          </Box>
        )}
        {listType === 'current' || listType === 'upcoming' ? (
          <>
            <UpcomingVisits cohorts={upcomingVisits} role={role} />
          </>
        ) : null}

        <Grid container sx={{ mb: 5, mt: { xs: 6, sm: 7, md: 6 } }}>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant='h1' className='semi-bold'>
              {listType === 'current'
                ? 'Current Classes'
                : listType === 'upcoming'
                ? 'Upcoming Classes'
                : 'Past Classes'}{' '}
              ({cohortCount})
            </Typography>
            <Typography variant='paragraph' component='p' sx={{ mt: 2 }}>
              {getTextContentByPage(
                'CLASSLIST',
                role.toUpperCase(),
                'INTRO',
                listType.toUpperCase()
              )}
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            sm={12}
            xs={12}
            textAlign={{ xs: 'start', sm: 'start', md: 'end' }}
            display={{ xs: 'block', sm: 'flex', md: 'block' }}
            mt={{ xs: 4, sm: 4, md: 0 }}
          >
            <Grid container alignItems='center'>
              {!['appadmin', 'teacher'].includes(role) ? null : (
                <Grid
                  item
                  sx={{ mb: { md: 3, sm: 0, xs: 3 }, pr: { xs: 0, sm: 2, md: 0 } }}
                  md={12}
                  sm={4}
                  xs={12}
                >
                  <Link to='/create-class'>
                    <Button
                      variant='contained'
                      title='Create a New Class'
                      disabled={false}
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: 'auto'
                        }
                      }}
                    />
                  </Link>
                </Grid>
              )}
              <Grid item md={12} sm={8} xs={12}>
                <ListSearchBar
                  placeHolder={
                    listType === 'current'
                      ? 'Search for a Current Class'
                      : listType === 'upcoming'
                      ? 'Search for an Upcoming Class'
                      : 'Search for a Past Class'
                  }
                  id='student-search'
                  onChange={e => setSearchKey(e.target.value)}
                  value={searchKey}
                  style={{
                    maxWidth: {
                      xs: '100%',
                      sm: '100%',
                      md: '350px'
                    }
                  }}
                  formControlStyle={{ alignItems: 'end', mt: { sm: 0, xs: 1 } }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {cohortCount > 0 ? (
        <ClassList
          cohorts={cohorts}
          role={role}
          totalPages={totalPages}
          page={step}
          setPage={handlePageChange}
        />
      ) : null}
      <Box>
        {cohortCount <= 0 &&
          (searchKey !== '' ? (
            <EmptyListDesign
              heading={getTextContentByPage('CLASSLIST', role.toUpperCase(), 'EMPTYSEARCHINTRO')}
              subheading={getTextContentByPage(
                'CLASSLIST',
                role.toUpperCase(),
                'EMPTYSEARCHSUBHEADING'
              )}
            />
          ) : (
            <EmptyListDesign
              heading={getTextContentByPage(
                'CLASSLIST',
                role.toUpperCase(),
                'EMPTYINTRO',
                listType.toUpperCase()
              )}
              subheading={getTextContentByPage(
                'CLASSLIST',
                role.toUpperCase(),
                'EMPTYSUBHEADING',
                listType.toUpperCase()
              )}
            />
          ))}
      </Box>
    </Box>
  )
}

export default inject('userStore')(observer(withApollo(CurrentClassesList)))
