import moment from 'moment'
import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { AlertTriangle, CheckCircle, Clock, Slash } from 'react-feather'
import UseAgreementDropDown from '../elements/UseAgreementDropDown'
import ApproveOrRejectFormModal from './ApproveOrRejectFormModal'
import DeleteUseAgreementModal from './DeleteUseAgreementModal'
import { Typography } from '@mui/material'
import ResendUseAgreementModal from './ResendUseAgreementModal'

class UseAgreementListRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showApprovalModal: false,
      showResendModal: false,
      showDeleteModal: false,
      photoId: this.props.photoId,
      useAgreement: this.props.useAgreement,
      openOne: false,
      opMrUuid: null
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      useAgreement: nextProps.useAgreement
    })
  }

  handleModalOpen = (modalType, value) => {
    this.setState({
      [`show${modalType}Modal`]: value
    })
  }

  render() {
    const { role, studentDetails } = this.props
    const { useAgreement } = this.state
    const { student } = studentDetails
    let showApprovalModalClose = () => this.setState({ showApprovalModal: false })
    let showResendModalClose = () => this.setState({ showResendModal: false })
    let showDeleteModalClose = () => this.setState({ showDeleteModal: false })
    return <div className='model-release__list-body-section'>
      <Row>
        <Col sm={12}>
          { <>
            {
              this.state.useAgreement && this.state.useAgreement.uuid ?
                <div className='model-release__list-history-section'>
                  <hr className='model-release__list-history-divider'/>
                  <Row>
                    <Col sm={10}>
                      <div className={`model-release__list-history-mr-status ${useAgreement.uaStatus === 'Inactive' ? 'model-release__list-history-mr-status--inactive' : ''}`}>
                        {
                          this.props.isStudentMinor ? (
                            !['offline','pdf'].includes(useAgreement.sentVia) ?(
                              (['appadmin', 'student'].includes(role) ?
                                <Typography variant="paragraph" component="p">
                                  Use Agreement sent to <strong>{ useAgreement.sentTo }</strong> on { moment(useAgreement.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }
                                </Typography>
                              :
                                <Typography variant="paragraph" component="p">
                                  Use Agreement sent by {useAgreement.sentVia === 'email'? 'email' : 'text'} on { moment(useAgreement.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }.
                                </Typography>
                              )
                            ) : <Typography variant="paragraph" component="p">
                              Use Agreement PDF uploaded on { moment(useAgreement.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }
                            </Typography>
                          ): <Typography variant="paragraph" component="p">
                            Use Agreement signed by student on { moment(useAgreement.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }.
                          </Typography>
                        }
                        {

                        }
                      </div>
                      <div className={useAgreement.uaStatus === 'Inactive' ? 'row model-release__list-history-mr-status--inactive' : 'row'}>
                        <Typography variant="h4" className='semi-strong' id="mb_12">
                        {
                          useAgreement.signed ?
                            <span className='icon-text-span model-release__list--success'>
                              { !['offline','pdf'].includes(useAgreement.sentVia) ?
                                <><CheckCircle size={16}/> Granted by { !['self'].includes(useAgreement.sentVia)? useAgreement.signeeFirstName + ' ' +useAgreement.signeeLastName : student.name }{useAgreement.signedon ? ` on ${moment(useAgreement.signedon, 'YYYY-MM-DD').format('MMMM D, YYYY')}`: null}</>
                              : <><CheckCircle size={16}/> PDF Uploaded{useAgreement.signedon ? ` on ${moment(useAgreement.signedon, 'YYYY-MM-DD').format('MMMM D, YYYY')}`: null}</>}
                            </span>
                          : useAgreement.signed === false ?
                            <span className='icon-text-span model-release__list--blocked'>
                              <Slash size={16}/> Declined by { !['self'].includes(useAgreement.sentVia)? useAgreement.signeeFirstName + ' ' +useAgreement.signeeLastName : student.name }{useAgreement.signedon ? ` on ${moment(useAgreement.signedon, 'YYYY-MM-DD').format('MMMM D, YYYY')}`: null}
                            </span>
                          : <span className='icon-text-span'>
                              <Clock size={16}/> Signer Has Not Responded{useAgreement.uaStatus === 'Inactive' ? ' (Expired)':''}
                            </span>
                        }
                        {
                          useAgreement.signed ?
                            <div className='model-release__list--admin-decision-status'>
                              <Typography variant="h4" className='semi-strong'>
                                {
                                  useAgreement.adminDecision === 'Pending' ?
                                    (['appadmin'].includes(role) ?
                                      <span className='icon-text-span model-release__list--alert'><AlertTriangle size={16}/> Approve or Reject Use Agreement </span>
                                    : <span className='icon-text-span'><Clock size={16}/> Working Assumptions Is Reviewing Use Agreement</span>
                                    )
                                  : useAgreement.adminDecision === 'Approve' ?
                                    <span className='icon-text-span model-release__list--success'><CheckCircle size={16}/> Approved by Working Assumptions{useAgreement.adminDecisionOn ? ` on ${ moment(useAgreement.adminDecisionOn, 'YYYY-MM-DD').format('MMMM D, YYYY') }`: null}</span>
                                  : useAgreement.adminDecision == 'Reject' ?
                                    <span className='icon-text-span model-release__list--blocked'><Slash size={16}/> Rejected by Working Assumptions{useAgreement.adminDecisionOn ? ` on ${ moment(useAgreement.adminDecisionOn, 'YYYY-MM-DD').format('MMMM D, YYYY') }`: null}</span>
                                  : null
                                }
                              </Typography>
                              {
                                useAgreement.adminDecision === 'Reject' ?
                                  <div>
                                    <p className='body text-break'>
                                      { useAgreement.adminRejectionNote ? useAgreement.adminRejectionNote : '' }
                                    </p>
                                  </div>
                                : null
                              }
                            </div>
                          : null
                        }
                        </Typography>
                      </div>
                    </Col>
                    {
                      (['student', 'teacher', 'facilitator'].includes(role) && useAgreement.signed === null && useAgreement.uaStatus === 'Inactive') || [ 'teacher', 'facilitator'].includes(role) && useAgreement.signed === null && ['Inactive', 'Active'].includes(useAgreement.uaStatus) ?
                        null
                      : <Col sm={2}>
                          <UseAgreementDropDown
                            useAgreement={useAgreement}
                            handleModalOpen={this.handleModalOpen}
                            role={role}
                          />
                        </Col>
                    }
                  </Row>
                </div>
              : null
            }

            </>
          }
          <ApproveOrRejectFormModal
            uuid={useAgreement && useAgreement.uuid ? useAgreement.uuid : null}
            show={this.state.showApprovalModal}
            onHide={showApprovalModalClose}
            entityType='ua'
            refetchPhoto={this.props.refetchPhoto}
            fetchProject={this.props.fetchProject}
          />
          <DeleteUseAgreementModal
            show={this.state.showDeleteModal}
            onHide={showDeleteModalClose}
            photoId={this.state.photoId}
            uaUuid={useAgreement && useAgreement.uuid ? useAgreement.uuid : null}
            onSuccessCallback={showDeleteModalClose}
            refetchPhoto={this.props.refetchPhoto}
            refetchQueries={this.props.refetchQueries}
            studentDetails={this.props.studentDetails}
          />
          <ResendUseAgreementModal
            show={this.state.showResendModal}
            onHide={showResendModalClose}
            photoId={this.state.photoId}
            useAgreement={useAgreement}
            uaUuid={useAgreement && useAgreement.uuid ? useAgreement.uuid : null}
            onSuccessCallback={showResendModalClose}
            refetchPhoto={this.props.refetchPhoto}
            refetchQueries={this.props.refetchQueries}
            studentDetails={this.props.studentDetails}
          />
        </Col>
      </Row>
    </div>
  }
}

export default UseAgreementListRow