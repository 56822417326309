import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Component, Fragment } from 'react'
import Photo from '../elements/Photo'
import FullScreenPhotoModal from '../molecules/FullScreenPhotoModal'
import PhotoOverlay from '../molecules/PhotoOverlay'
import PhotoStatusStack from '../molecules/PhotoStatusStack'
import ProjectDetailsAoeAwardSection from '../molecules/ProjectDetailsAoeAwardSection'
import { Typography } from '@mui/material'
import ProjectDetailsHmAwardSection from '../molecules/ProjectDetailsHmAwardSection'

class StudentAwardResults extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPhotoId: -1,
      showPhotoModal: false,
      awardedPhotos: this.getAllawardedPhotos(this.props.photos)
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      awardedPhotos: this.getAllawardedPhotos(nextProps.photos)
    })
  }

  getAllawardedPhotos = (photos) => {
    return photos.filter(photo => photo.awards.length > 0)
  }
  thumbnailOnClick = photo => {
    this.setState({
      ...this.state,
      selectedPhotoId: photo.id,
      showPhotoModal: true
    })
  }
  photoModelReleaseStatus(photo, role) {
    return <PhotoStatusStack photo={photo} role={role} awardResults={false}/>
  }
  getAdjacentImage = async (direction, photoId) => {
    if (direction && !['prev', 'next'].includes(direction)) return
    const awardedPhotos = this.state.awardedPhotos
    const currIdx = awardedPhotos.findIndex(elem => elem.id === photoId)
    if (direction === 'prev') {
      this.setState({
        ...this.state,
        selectedPhotoId: currIdx === 0 ? awardedPhotos[awardedPhotos.length - 1].id : awardedPhotos[currIdx - 1].id
      })
    } else {
      this.setState({
        ...this.state,
        selectedPhotoId:
          currIdx === awardedPhotos.length - 1 ? awardedPhotos[0].id : awardedPhotos[currIdx + 1].id
      })
    }
  }

  updateParentGallery = photo => {
    const photos = this.state.awardedPhotos
    const currIdx = photos.findIndex(elem => elem.id === photo.id)
    const currentNode = photos[currIdx]
    let newPhoto = {...currentNode}
    if (!newPhoto) return // it's missing from the list?
    newPhoto.title = photo.title
    newPhoto.caption = photo.caption
    newPhoto.url = photo.url
    newPhoto.cdnOriginalUrl = photo.url
    newPhoto.originalUrl = photo.url
    newPhoto.sizedUrl = photo.url
    newPhoto.photoComments = photo.photoComments || []
    newPhoto.awards = photo.awards || []
    newPhoto.totalMrRequired = photo.totalMrRequired
    newPhoto.modelReleases = Object.keys(photo).includes('modelReleases')
      ? photo.modelReleases
      : newPhoto.modelReleases
    newPhoto.useAgreements = Object.keys(photo).includes('useAgreements')
      ? photo.useAgreements
      : newPhoto.useAgreements
    newPhoto.rawFileName = photo.rawFileName
    newPhoto.rawFileSize = photo.rawFileSize
    newPhoto.rawFileUrl = photo.rawFileUrl
    newPhoto.originalFileSize = photo.originalFileSize
    newPhoto.exifData = photo.exifData
    newPhoto.filename = photo.filename
    this.setState({
      ...this.state,
      awardedPhotos: photos
    })
  }

  render() {
    const awardedPhotos = this.state.awardedPhotos

    const aoeAwardedPhotos = awardedPhotos.filter(photo =>  {
      let aoeCount = photo.awards.filter(award => award.awardClass === 'Award of Excellence').length
      let hmCount = photo.awards.filter(award => award.awardClass === 'Honorable Mention').length
      return aoeCount > hmCount || aoeCount === hmCount
    })

    const hmAwardedPhotos = awardedPhotos.filter(photo =>  {
      let aoeCount = photo.awards.filter(award => award.awardClass === 'Award of Excellence').length
      let hmCount = photo.awards.filter(award => award.awardClass === 'Honorable Mention').length
      return hmCount > 0 && aoeCount < hmCount
    })

    // const aoeAwardedPhotos = awardedPhotos.filter(photo => photo.awards.filter(award => award.awardClass === 'Award of Excellence').length > 0)
    // const hmAwardedPhotos = awardedPhotos.filter(photo => photo.awards.filter(award => award.awardClass === 'Honorable Mention').length > 0)
    const {role} = this.props
    return (
      <Fragment>
        <div className='body__shortlist'>
          <div className='shortlist__text'>
            <Typography variant="h2" className='strong'>
              Awarded Photographs
              {awardedPhotos.length === 0 ? ' (0)' : <span> ({awardedPhotos.length})</span>}
            </Typography>
            <Typography variant="paragrap" component="p" sx={{ paddingTop: '10px' }}>
              These photographs received an award from the Working Assumptions curators.
            </Typography>
          </div>
          {aoeAwardedPhotos.length > 0 ? (
            <ProjectDetailsAoeAwardSection
              photos={aoeAwardedPhotos}
              role={role}
              showToast={this.props.showToast}
            />
          ) :
          null}
          {hmAwardedPhotos.length > 0 ? (
            <ProjectDetailsHmAwardSection
              photos={hmAwardedPhotos}
              role={role}
              showToast={this.props.showToast}
            />
          ) : null}
        </div>
      </Fragment>
    )
  }
}

export default inject('backToPageStore')(observer(StudentAwardResults))
