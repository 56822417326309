import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FormCheckbox from '../elements/FormCheckbox.js'
import moment from 'moment'
import { Box, Grid, Typography } from '@mui/material'

class UserListRowTeachers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPhotos: false
    }
  }

  render() {
    const { teacher, checked, userId } = this.props

    return (
      <>
        <Box
          className='body__section'
          sx={{
            p: { xs: 3, md: 4 },
            borderBottom: { xs: '0.0625rem solid rgb(213, 213, 215)' },
            margin: { xs: '0px !important', md: '8px 0 !important' }
          }}
        >
          <Grid container alignItems='center'>
            <Grid item md={1} sm={1} xs={1}>
              <Box sx={{ textAlign: 'center' }}>
                <FormCheckbox
                  checked={checked}
                  onChange={() => this.props.onChecked(userId)}
                  value='checkedReminder'
                  color='primary'
                />
              </Box>
            </Grid>
            <Grid item lg={5} sm={6} xs={9}>
              <Link to={'/user-details/'.concat(userId)}>
                <Typography
                  variant='h4'
                  className='semi-strong link link--large'
                  sx={{ pb: 1, textDecoration: 'underline' }}
                >
                  {teacher.name}
                </Typography>
              </Link>
              <Typography
                variant='h4'
                className='table_row'
                sx={{ mt: { md: 1 }, wordWrap: 'break-word' }}
              >
                {teacher.school.name}
              </Typography>
              <Box sx={{ display: { xs: 'block', md: 'none' }, mt: 3 }}>
                <Link to={`/user-details/${userId}/classes`}>
                  <Typography variant='h4' className='semi-strong link link--large'>
                    {teacher.cohorts.length} Classes
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid
              item
              lg={3}
              sm={5}
              xs={12}
              sx={{ display: { xs: 'none', md: 'block' }, mb: { md: 5 } }}
            >
              <Link to={`/user-details/${userId}/classes`}>
                <Typography variant='h4' className='semi-strong link link--large'>
                  {teacher.cohorts.length} Classes
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              lg={3}
              sm={4}
              xs={12}
              sx={{ display: { xs: 'none', sm: 'none', lg: 'block' }, mb: { md: 5 } }}
            >
              {this.props.lastSeenAt ? (
                <Typography variant='h4' className='table_row'>
                  Last signed in {moment(this.props.lastSeenAt).format('LL')}
                </Typography>
              ) : (
                <Typography variant='h4' className='table_row'>
                  User has not signed up
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }
}

export default UserListRowTeachers
