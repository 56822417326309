import React, { Component, Fragment } from 'react'
import Dropzone from 'react-dropzone'
import Button from '../elements/Button.js'
import { DirectUpload } from 'activestorage'

const uploadFile = (file, updateFileSignedId, updatePreview, refetchQueries) => {
  // TODO: Make this URL not static
  const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
  const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'photo')
  const preview = URL.createObjectURL(file)
  updatePreview(preview)
  upload.create((error, blob) => {
    if (error) {
      // Handle the error
    } else {
      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow

      updateFileSignedId(blob.signed_id)
      // TODO redirect to photo detail page?
    }
  })
}

class EventPhotoUploader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  onDrop = (files, updateFileSignedId, updatePreview) => {
    // event.preventDefault()
    // const files = event.dataTransfer.files;

    Array.from(files).forEach(file => uploadFile(file, updateFileSignedId, updatePreview))
  }
  render() {
    // const photos = { this.props.photos }
    return (
      <Dropzone
        photos={this.props.photos}
        onDrop={files =>
          this.onDrop(files, this.props.updateFileSignedId, this.props.updatePreview)
        }
        accept={('image/tiff', 'image/jpeg')}
      >
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <Fragment>
              <div className='container__uploader'>
                <div {...getRootProps()} className='uploader__button'>
                  <input {...getInputProps()} />
                  <Button
                    variant={this.props.buttonVariant}
                    title={this.props.buttonTitle}
                    disabled={false}
                  />
                </div>
              </div>
            </Fragment>
          )
        }}
      </Dropzone>
    )
  }
}

export default EventPhotoUploader
