import React, { Component, Fragment } from 'react'
import Image from 'react-bootstrap/Image'
import assignmentOverview from '../../assets/images/assignment-overview.png'
import assignmentActivities from '../../assets/images/assignment-activities.png'
import additionalResources from '../../assets/images/additional-resources.png'
import goingBeyond from '../../assets/images/going-beyond.png'
import help from '../../assets/images/help.png'
import photographyTips from '../../assets/images/photography-tips.png'
import writingTips from '../../assets/images/writing-tips.png'
import forms from '../../assets/images/forms.png'
import { Typography } from '@mui/material'

class PublicResourceList extends Component {
  render() {
    const { resources } = this.props
    return (
      <Fragment>
        <div className='body__resource-list'>
          <div className='resource-list__category'>
            <div className='resource-list__category--image'>
              <Image src={assignmentOverview} fluid />
            </div>
            <div className='resource-list__category--header'>
              <Typography variant="h2">Assignment Overview</Typography>
            </div>
            <div className='resource-list__category--links'>
              {resources
                .filter(resource => resource.category === 'Assignment Overview')
                .map(resource => (
                  <a rel='noopener noreferrer' href={resource.linkToResource}>
                    {resource.name}
                  </a>
                ))}
            </div>
          </div>

          <div className='resource-list__category'>
            <div className='resource-list__category--image'>
              <Image src={assignmentActivities} fluid />
            </div>
            <div className='resource-list__category--header'>
              <Typography variant="h2">Assignment Activities</Typography>
            </div>
            <div className='resource-list__category--links'>
              {resources
                .filter(resource => resource.category === 'Assignment Activities')
                .map(resource => (
                  <a rel='noopener noreferrer' href={resource.linkToResource}>
                    {resource.name}
                  </a>
                ))}
            </div>
          </div>

          <div className='resource-list__category'>
            <div className='resource-list__category--image'>
              <Image src={photographyTips} fluid />
            </div>
            <div className='resource-list__category--header'>
              <Typography variant="h2">Photography Tips</Typography>
            </div>
            <div className='resource-list__category--links'>
              {resources
                .filter(resource => resource.category === 'Photography Tips')
                .map(resource => (
                  <a rel='noopener noreferrer' href={resource.linkToResource}>
                    {resource.name}
                  </a>
                ))}
            </div>
          </div>

          <div className='resource-list__category'>
            <div className='resource-list__category--image'>
              <Image src={writingTips} fluid />
            </div>
            <div className='resource-list__category--header'>
              <Typography variant="h2">Writing Tips</Typography>
            </div>
            <div className='resource-list__category--links'>
              {resources
                .filter(resource => resource.category === 'Writing Tips')
                .map(resource => (
                  <a rel='noopener noreferrer' href={resource.linkToResource}>
                    {resource.name}
                  </a>
                ))}
            </div>
          </div>

          <div className='resource-list__category'>
            <div className='resource-list__category--image'>
              <Image src={additionalResources} fluid />
            </div>
            <div className='resource-list__category--header'>
              <Typography variant="h2">Additional Resources</Typography>
            </div>
            <div className='resource-list__category--links'>
              {resources
                .filter(resource => resource.category === 'Additional Resources')
                .map(resource => (
                  <a rel='noopener noreferrer' href={resource.linkToResource}>
                    {resource.name}
                  </a>
                ))}
            </div>
          </div>

          <div className='resource-list__category'>
            <div className='resource-list__category--image'>
              <Image src={forms} fluid />
            </div>
            <div className='resource-list__category--header'>
              <Typography variant="h2">Forms</Typography>
            </div>
            <div className='resource-list__category--links'>
              {resources
                .filter(resource => resource.category === 'Forms')
                .map(resource => (
                  <a rel='noopener noreferrer' href={resource.linkToResource}>
                    {resource.name}
                  </a>
                ))}
            </div>
          </div>

          <div className='resource-list__category'>
            <div className='resource-list__category--image'>
              <Image src={goingBeyond} fluid />
            </div>
            <div className='resource-list__category--header'>
              <Typography variant="h2">Going Beyond</Typography>
            </div>
            <div className='resource-list__category--links'>
              {resources
                .filter(resource => resource.category === 'Going Beyond')
                .map(resource => (
                  <a rel='noopener noreferrer' href={resource.linkToResource}>
                    {resource.name}
                  </a>
                ))}
            </div>
          </div>

          <div className='resource-list__category'>
            <div className='resource-list__category--image'>
              <Image src={help} fluid />
            </div>
            <div className='resource-list__category--header'>
              <Typography variant="h2">Help</Typography>
            </div>
            <div className='resource-list__category--links'>
              {resources
                .filter(resource => resource.category === 'Help')
                .map(resource => (
                  <a rel='noopener noreferrer' href={resource.linkToResource}>
                    {resource.name}
                  </a>
                ))}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default PublicResourceList
