import { Box, Divider, Grid, Typography } from '@mui/material'
import React, { Component } from 'react'
import ClassApprovalTable from '../molecules/ClassApprovalTable'
import ClassEndTable from '../molecules/ClassEndTable'
import ClassStartTable from '../molecules/ClassStartTable'
import OverDueCuratorialReviewTable from '../molecules/OverDueCuratorialReviewTable'
import SendAwardSummariesTable from '../molecules/SendAwardSummariesTable'
import SendToCuratorTable from '../molecules/SendToCuratorTable'
import TaVisitTable from '../molecules/TaVisitTable'
import UserBioApprovalTable from '../molecules/UserBioApprovalTable'
import './adminDashboard.scss'

class AdminDashboard extends Component {
  render() {
    return (
      <Box sx={{ px: { xs: 2, md: 0 } }}>
        <Box sx={{ mt: '16px', mb: '28px' }}>
          <Typography variant='h2' className='strong'>
            Classes
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <ClassStartTable />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <ClassEndTable />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <ClassApprovalTable />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <TaVisitTable />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ backgroundColor: 'rgb(45, 45, 45)', mt: '32px', mb: '32px' }} />

        <Box sx={{ mt: '16px', mb: '28px' }}>
          <Typography variant='h2'>Reviews</Typography>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <SendToCuratorTable />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <SendAwardSummariesTable />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <OverDueCuratorialReviewTable />
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ backgroundColor: 'rgb(45, 45, 45)', mt: '32px', mb: '32px' }} />

        <Box sx={{ mt: '16px', mb: '28px' }}>
          <Typography variant='h2'>Users</Typography>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <UserBioApprovalTable />
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }
}

export default AdminDashboard
