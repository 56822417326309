import React, { Component, Fragment } from 'react'
import { gql } from '@apollo/client'
import '../organisms/photoeditor.scss'
import Loading from '../elements/Loading.js'
import { Mutation } from '@apollo/client/react/components'
import Button from '../elements/Button.js'
import { Spinner } from 'react-bootstrap'
import DatePicker from '../elements/DatePicker'
import DateUtils from '../../utils/DateUtils'
import { Typography } from '@mui/material'

const MUTATION = gql`
  mutation EditPhotoSubmittedDate($photoId: ID!, $submittedDate: String!) {
    editPhotoSubmittedDate(photoId: $photoId, submittedDate: $submittedDate) {
      id
      submittedDate
    }
  }
`

class AdminPhotoSubmittedDateOverride extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: {
        submittedDate: false
      },
			photo_id: props.photo.id,
      submittedDate: props.photo.submittedDate,
			isSubmitting: false
    }
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (this.props.photo.id !== nextProps.photo.id) {
      this.setState({
        ...this.state,
        submittedDate: nextProps.photo.submittedDate
      })
    }
  }

	static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.photo.id !== prevState.photo_id) {
      return {
        submittedDate: nextProps.photo.submittedDate
      }
    } else return null
  }

  toggleEdit = key => {
    this.setState({
      editing: Object.assign(this.state.editing, {
        [key]: !this.state.editing[key]
      })
    })
  }

  mutateAttributeChange = (onMutate) => {
    this.setState({
      isSubmitting: true
    })
		onMutate({
			variables:{
				submittedDate: DateUtils.formatDateString(this.state.submittedDate),
				photoId: this.props.photoId
			}
		})
  }

	handleFormChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {
    const { handleFormChange } = this
    return (
      <div className='photo-alttext__section'>
        <Mutation
          mutation={MUTATION}
          onCompleted={() => {
            this.setState({
              isSubmitting: false
            })
          }}
        >
          {(onMutate, { data }) => (
            <Fragment>
              <div className='award-nominator__publish-photograph--header'>
                <Typography variant="h2">Admin Overrides</Typography>
              </div>
              <div className='photo-alttext__instructions'>
                <Typography variant="paragraph" component="p">
                  If the project submission date does not reflect the year that the photographs was originally submitted, enter a different date here.
                </Typography>
								<Typography variant="paragraph" component="p">
									The year from the new date will appear in the photograph attribution when published to the National Collection.
								</Typography>
              </div>
              <div>
                <DatePicker
                  onChange={date =>
                    handleFormChange({
                      target: {
                        value: date,
                        name: 'submittedDate'
                      }
                    })
                  }
                  overflow={'hidden'}
                  value={this.state.submittedDate}
                  label='Photograph Submission Date'
                  sx={{width: '35%'}}
                />
                <div className='photo-editor-page__button-section'>
									<Button
										title={this.state.isSubmitting ? <span className='icon-text-span'><Spinner
											as="span"
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/> Saving...</span> : 'Save Override'}
										className='outline-buttons photo-editor-alt-page'
										variant='outlined'
										onClick={() => this.mutateAttributeChange(onMutate)}
										disabled={this.state.isSubmitting}
									/>
								</div>
              </div>
            </Fragment>
          )}
        </Mutation>
      </div>
    )
  }
}

export default AdminPhotoSubmittedDateOverride
