import React, {Fragment} from 'react'
import Layout from '../ui/template/Layout'
import { withRouter } from '../utils/customHoc.js'
import { gql, useQuery } from '@apollo/client'
import { useState } from 'react'
import Loading from '../ui/elements/Loading.js'
import './homepage.scss';
import {
  Box,
  Typography,
  Grid
} from '@mui/material'


const QUERY = gql`
  query GetUseAgreementDetail($uuid: String!) {
    getUseAgreementDetail(uuid: $uuid)
    {
      id
      photo
      {
        submitter
        {
          studentIsAdult
        }
      }
    }
  }
`

const UseAgreementDetail = (params) => {

  const [isStudentAdult, setIsStudentAdult] = useState()
 
  const { loading, error, data } = useQuery(QUERY, {
    variables: {uuid: params.params.agreementUuid }, fetchPolicy: 'network-only',
    onCompleted(data) {
      setIsStudentAdult(data.getUseAgreementDetail.photo.submitter.studentIsAdult)
    }
  });

  const MinorAboutWa = (
    <Box>
      <Box>
        <Typography variant='h4' className='semi-strong'>
          About Working Assumptions
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant='paragraph'>
          Working Assumptions is a non-profit arts and education organization. We explore and
          celebrate the importance of work, family and care through innovative projects and
          programs. Since 2013, our <i>wrkxfmly</i> photography assignment has inspired countless
          conversations in classrooms and within families about what care looks like and how work
          and family overlap and influence daily life.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          Your student has been recognized for their <i>wrkxfmly</i> photography and we would like
          to include their excellent work in a digital research archive we are building to preserve
          and honor the quality of their unique perspective. The photographs and captions held in
          this archive advance our mission of seeing cultures of care through the eyes of young
          adults.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          Digital technology is changing photography and the way we see and remember our lives.
          Agreements are even more important to foster understanding between the photographed and
          photographer. We are rigorous in making sure that recognizable people in photographs know
          and understand their rights as models and that the photographer’s work and intellectual
          property is valued.
        </Typography>
      </Box>
    </Box>
  )

  const AdultAboutWa = (
    <Box>
      <Box>
        <Typography variant='h4' className='semi-strong'>
          About Working Assumptions
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant='paragraph'>
          Working Assumptions is a non-profit arts and education organization. We explore and
          celebrate the importance of work, family and care through innovative projects and
          programs. Since 2013, our <i>wrkxfmly</i> photography assignment has inspired countless
          conversations in classrooms and within families about what care looks like and how work
          and family overlap and influence daily life.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          You have been recognized for your <i>wrkxfmly</i> photography and we would like to include
          your excellent work in a digital research archive we are building to preserve and honor
          the quality of your unique perspective. The photographs and captions held in this archive
          advance our mission of seeing cultures of care through the eyes of young adults.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          Digital technology is changing photography and the way we see and remember our lives.
          Agreements are even more important to foster understanding between the photographed and
          photographer. We are rigorous in making sure that recognizable people in photographs know
          and understand their rights as models and that the photographer’s work and intellectual
          property is valued.
        </Typography>
      </Box>
    </Box>
  )

  const AdultPermissionText = (
    <Box sx={{ mt: 3 }}>
      <Box>
        <Typography variant='h4' className='semi-strong'>
          Use Agreement Permission
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant='paragraph'>
          The permission requested here allows your work to be included in the Working Assumptions
          archive. The “work” includes your photograph(s) and/or video(s), text such as the title(s)
          and caption(s) accompanying your photograph(s) and/or video(s), and personally identifying
          information such as your name and image. By signing, you (“Photographer”) give permission
          to Working Assumptions to conserve and utilize the work in the following mission-aligned
          ways:
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Grant of Permissions:</b> Photographer grants Working Assumptions a non-exclusive,
          royalty-free, perpetual, and irrevocable right to use, reproduce, distribute, display and
          publish the work(s) created by the Photographer for non-profit mission-aligned purposes,
          as well as store Photographer’s work(s) in Working Assumption’s archive, make it available
          for research collaborations, and include it in print publications, in digital formats,
          online, and in other media.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Ownership and Copyright:</b> Photographer confirms that they are the sole owner of the
          work(s) and holds all necessary rights, including copyright, to grant Working Assumptions
          the permissions stated in this Agreement. Photographer agrees to provide any additional
          documentation or releases if requested by Working Assumptions to verify Photographer’s
          ownership and permissions.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Non-Profit Use:</b> Working Assumptions confirms that Photographer’s work(s) will not
          be used for any commercial purposes (i.e., for profit or for advertising or promotion) and
          agrees to credit Photographer as specified in permission forms.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Modifications and Derivative Works:</b> Working Assumptions may crop, edit, or make
          minor adjustments to the work(s) as necessary for their intended use, without altering the
          integrity or intended meaning of the image.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Consent and Release:</b> Photographer acknowledges that their participation in the
          creation of the work(s) was voluntary. Therefore, Photographer hereby releases Working
          Assumptions and its representatives from any liability arising out of the use of the
          work(s) as permitted by this Agreement.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Indemnification:</b> Photographer agrees to indemnify and hold Working Assumptions
          harmless from any claims, damages, or liabilities arising out of the use of the work(s) as
          permitted by this Agreement, including but not limited to claims of infringement,
          defamation, or violation of privacy or publicity rights.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Governing Law:</b> This Agreement shall be governed by and construed in accordance with
          the laws of California. Any legal actions or proceedings arising out of or relating to
          this Agreement shall be brought in the courts of California.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Entire Agreement:</b> This Agreement constitutes the entire understanding between the
          parties and supersedes all prior negotiations, understandings, or agreements, whether oral
          or written, relation to the subject matter herein.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Severability:</b> If any provision of this Agreement is held to be invalid, illegal, or
          unenforceable, the validity, legality, or enforceability of the remaining provisions shall
          not in any way be affected or impaired.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph' component='p'>
          By presenting this document for Photographer’s signature, Working Assumptions thereby
          acknowledges and agrees to the terms and conditions of this Use Agreement.
        </Typography>
        <Typography variant='paragraph' component='p' sx={{ mt: 1 }}>
          By signing, Photographer acknowledges and agrees to the terms and conditions of this Use
          Agreement.
        </Typography>
      </Box>
    </Box>
  )

  const MinorPermissionText = (
    <Box sx={{ mt: 3 }}>
      <Box>
        <Typography variant='h4' className='semi-strong'>
          Use Agreement Permission
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant='paragraph'>
          The permission requested here allows the work of the minor under your guardianship to be
          included in the Working Assumptions archive. The “work” includes their photograph(s)
          and/or video(s), text such as the title(s) and caption(s) accompanying their photograph(s)
          and/or video(s), and personally identifying information such as their name and image. By
          signing, you confirm that you have seen the work of the minor under your guardianship, and
          you give permission to Working Assumptions to conserve and utilize the work under the
          following agreement:
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Grant of Permissions:</b> Parent/Legal Guardian grants Working Assumptions a
          non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce,
          distribute, display and publish the work(s) created by the minor under your guardianship
          for non-profit mission-aligned purposes, as well as store the work in Working Assumption’s
          archive, make it available for research collaborations, and include it in print
          publications, in digital formats, online, and in other media.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Ownership and Copyright:</b> Parent/Legal Guardian confirms that Minor is the sole
          creator of the work(s) and that no other persons created the work(s) or have any rights to
          them. Parent/Legal Guardian agrees to provide any additional documentation or releases if
          requested by Working Assumptions to verify creation and permissions.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Non-Profit Use:</b> Working Assumptions confirms that the Minor’s work(s) will not be
          used for any commercial (i.e. for profit or for advertising or promotion) purposes and
          agrees to credit the Minor photographer as specified in permission forms.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Modifications and Derivative Works:</b> Working Assumptions may crop, edit, or make
          minor adjustments to the work(s) as necessary for their intended use, without altering the
          integrity or intended meaning of the image.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Consent and Release:</b> Parent/Legal Guardian acknowledges that Minor’s participation
          in the creation of the work(s) was voluntary. Therefore, Parent/Legal Guardian and Minor
          hereby release Working Assumptions and its representatives from any liability arising out
          of the use of the work(s) as permitted by this Agreement.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Assurances:</b> Parent/Legal Guardian assures Working Assumptions that the work(s)
          attached to this Agreement are original to the Minor and solely created by them.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Indemnification:</b> Parent/Legal Guardian agrees to indemnify and hold Working
          Assumptions harmless from any claims, damages, or liabilities arising out of the use of
          the work(s) as permitted by this Agreement, including but not limited to claims of
          infringement, defamation, or violation of privacy or publicity rights.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Governing Law:</b> This Agreement shall be governed by and construed in accordance with
          the laws of California. Any legal actions or proceedings arising out of or relating to
          this Agreement shall be brought in the courts of California.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Entire Agreement:</b> This Agreement constitutes the entire understanding between the
          parties and supersedes all prior negotiations, understandings, or agreements, whether oral
          or written, relation to the subject matter herein.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          <b>Severability:</b> If any provision of this Agreement is held to be invalid, illegal, or
          unenforceable, the validity, legality, or enforceability of the remaining provisions shall
          not in any way be affected or impaired.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          By presenting this document for Parent/Legal Guardian’s signing, Working Assumptions
          thereby acknowledges and agrees to the terms and conditions of this Use Agreement.
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant='paragraph'>
          By signing, Parent/Legal Guardian acknowledges and agrees to the terms and conditions of
          this Use Agreement.
        </Typography>
      </Box>
    </Box>
  )
  if (loading) return <Loading />
  if (error) {
  return (
      <Typography variant='paragraph' component='p'>
      Error loading classes
      </Typography>
  )
  }
  if (data) {
    return (
      <Layout>
        <Fragment>
          <Box>
            <Box sx={{ px: 4, py: 3 }}>
              <Grid container spacing={1} sx={{paddingLeft:'4rem !important'}}>
                <Grid item md={11} sm={11} xs={10}>
                  <Typography variant='h1' className='strong'>

                    Photograph Use Agreement for {isStudentAdult ? 'Minors' :  'Adults' }
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10} sx={{ paddingTop:'4rem !important'}}>
                  <Typography  component='p' variant='blockQuote'>
                      Last Updated: February 20, 2024
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ padding:'4rem 6rem 6rem' }}>
              {isStudentAdult ? MinorAboutWa : AdultAboutWa}
              {isStudentAdult ? MinorPermissionText : AdultPermissionText }
            </Box>
          </Box>
        </Fragment>
      </Layout>
    )
  }
}
export default withRouter(UseAgreementDetail)