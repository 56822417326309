import React, { Component } from 'react'
import { Typography, Grid, Box } from '@mui/material'
import './modals.scss'
import Button from '../elements/Button.js'
import FormFieldMultiline from '../elements/FormFieldMultiline.js'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Spinner } from 'react-bootstrap'
import BaseModal from '../template/BaseModal';

const NOTIFY_STUDENT = gql`
  mutation StudentAwardNotification($id: ID!, $customMessage: String) {
    studentAwardNotification(id: $id, customMessage: $customMessage)
  }
`

class SendReminderNotificationModal extends Component {
  constructor(props) {
    super(props)
    this.state = { message: '',
    isFromSubmitting: false }
    this.handleFormChange = this.handleFormChange.bind(this)
  }

  handleSubmit(e, onMutate) {
    onMutate({
      variables: {
        id: this.props.projectId,
        customMessage: this.state.message
      }
    })
    this.setState({message:'',isFromSubmitting:true})
  }
  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);

    };
    return (
      <BaseModal
        {...this.props}
        show={this.props.show}
        onClick={this.props.onClick}
        value={this.props.value}
        open={this.props.show}
        onClose={handleClose}
        onHide={this.props.onHide}
      >
        <BaseModal.BaseModalHeader>
          Send Missing Forms Reminder
        </BaseModal.BaseModalHeader>
        <BaseModal.BaseModalBody>
          <Box className='invite-student__description'>
            <Typography variant="paragraph" component="p">
              This will send an automatic email to the student with a list of awarded photographs that do not currently have the required Use Agreement and Model Release forms.
            </Typography>
            <br/>
            <Typography variant="paragraph" component="p">
              Please add a note to the student with any additional details. The note should be brief and should not repeat any information that is already included on the photograph pages.
            </Typography>
          </Box>
          <Box className='form-field full-size'>
            <FormFieldMultiline
              formFieldId='message'
              formFieldVariant='filled'
              formFieldLabel='Missing Forms Guidance Note'
              formFieldClasses={{
                root: 'text-field__outline'
              }}
              formFieldType='text'
              formFieldName='message'
              onChange={this.handleFormChange}
              formFieldMargin='auto'
              //   onChange={handleFormChange}
              multiline='true'
              sx={{
                '.MuiInputBase-root': {
                  pt: '18px',
                  pb: '0px'
                },
                '.MuiInputBase-root:before': {
                  height:'0px'
                }
              }}
            />
          </Box>
          <BaseModal.BaseModalFooter>
            <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
              <Mutation
                mutation={NOTIFY_STUDENT}
                onCompleted={data => {
                  this.setState({isFromSubmitting:false})
                  this.props.showToast('success','Missing Forms Reminder Send Successfully')
                  this.props.onHide()

                }}
              >
                {(onMutate, { data }) => {
                  return (
                    <>
                      <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
                        <Grid item xs={12} sm={6} md={4}>
                          <Button
                            type='copy'
                            title={
                              this.state.isFromSubmitting ? (
                                <span className='icon-text-span'>
                                  <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                  />{' '}
                                  {'Sending...'}
                                </span>
                              ) : (
                                'Send Missing Forms Reminder'
                              )
                            }
                            disabled={!(this.state.message && this.state.message.trim().length >=1) }
                            onClick={e => this.handleSubmit(e, onMutate)}
                            variant='contained'
                            sx={{width:{ xs: '100%', sm: '100%' }}}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                          <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={() => this.props.onHide()}>
                            Cancel
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )
                }}
              </Mutation>
            </Box>
          </BaseModal.BaseModalFooter>
        </BaseModal.BaseModalBody>
      </BaseModal>
    )
  }
}

export default SendReminderNotificationModal
