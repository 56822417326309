import React, { Component, Fragment } from 'react'
import './events.scss'
import Hero from '../ui/organisms/Hero.js'
import Layout from '../ui/template/Layout'

class Notifications extends Component {
  render() {
    return (
      <Layout>
        <Hero heroTitle='Notifications' />

        <div className='container__body'>
          <div className='events__header'>
            <div className='events__header--title'>
              <h1>Notification Settings</h1>
            </div>
          </div>
          Notification settings will go here!
        </div>
      </Layout>
    )
  }
}

export default Notifications
