import React, { useState } from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { StudentListRow } from './StudentListRow'
import FileDtailsModal from './FileDetailsModal'
import Button from '../../elements/Button'
import { RefreshCw } from 'react-feather'
import FormCheckbox from '../../elements/FormCheckbox'
import usePicsio from '../../../utils/picsioUtility'
import { validationText } from '../../../content/validationText'
import { Spinner } from 'react-bootstrap'

export const StudentList = ({ data, collectionIds, showToast, refetchParent }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [newFiles, setNewFiles] = useState()
  const [selectedStudent, setSelectedStudent] = useState()
  const [selectedStudentProject, setSelectedStudentProject] = useState()
  const [isAwardUpdated, setIsAwardUpdated] = useState(false)
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [checkboxes, setCheckboxes] = useState([])
  const [isProcessing, setIsProcessing] = useState(false)

  const { uploadPhoto } = usePicsio()

  const getSelectedStudents = () => {
    return document.querySelectorAll('input[type="checkbox"][name="studentIds"]:not(:disabled)')
  }

  const updateCheckboxes = (allChecked = false) => {
    const checkboxes = getSelectedStudents()

    const updatedCheckboxes = Array.from(checkboxes).map(checkbox => ({
      id: checkbox.value,
      isChecked: allChecked ? !selectAllChecked : checkbox.checked
    }))

    return updatedCheckboxes
  }

  const toggleCheckbox = (id, check) => {
    const updatedCheckboxes = updateCheckboxes()
    setCheckboxes(updatedCheckboxes)
    setSelectAllChecked(updatedCheckboxes.every(item => item.isChecked))
  }

  const toggleSelectAll = () => {
    const updatedCheckboxes = updateCheckboxes(true)
    setCheckboxes(updatedCheckboxes)
    setSelectAllChecked(!selectAllChecked)
  }

  const uploadToPicsio = async (student, studentProject, awardedPhotos, cohort, awardUpdated) => {
    try {
      const response = await uploadPhoto(
        student,
        studentProject,
        awardedPhotos,
        collectionIds,
        cohort,
        awardUpdated
      )

      return response
    } catch (error) {
      throw error
    }
  }

  const syncAll = async () => {
    const students = checkboxes?.filter(checkbox => checkbox.isChecked)

    let count = 0
    let success = true // Assume success until proven otherwise

    try {
      setIsProcessing(true)
      await Promise.all(
        students.map(async student => {
          try {
            const studentToProcess = data.cohort.students?.find(s => s.id === student.id)
            const studentProject = data.cohort.submittedProjects.filter(
              project => project.student.id === student.id
            )
            const awardedPhotoList =
              studentProject?.[0]?.photos?.filter(photo => photo.awards.length > 0) || []
            const objectsWithoutAssetId = awardedPhotoList.filter(obj => !obj.assetId)
            const objectsWithUpdatedAward = awardedPhotoList.filter(obj =>
              obj?.awards?.some(
                award =>
                  award.nominatorType === 'AppAdmin' &&
                  award.updatedAt > studentProject?.[0]?.lastSync &&
                  studentProject?.[0]?.captionFileUpdatedAt > studentProject?.[0]?.lastSync
              )
            )
            const awardUpdated =
              objectsWithUpdatedAward?.length || objectsWithoutAssetId?.length ? true : false
            await uploadToPicsio(
              studentToProcess,
              studentProject?.[0],
              awardedPhotoList,
              data.cohort,
              awardUpdated
            )
            count++
          } catch (error) {
            success = false
          }
        })
      )
    } catch (error) {
      showToast?.('error', validationText.picsio.syncFile.error)
      success = false
    } finally {
      setIsProcessing(false)
      if (count === students.length && success) {
        showToast?.('success', validationText.picsio.syncFile.success)
        toggleCheckbox()
        setSelectAllChecked(false)
      } else {
        showToast?.('error', validationText.picsio.syncFile.error)
      }
    }
  }

  return (
    <>
      <Typography className='strong' variant='h3'>
        New and Modified Files
      </Typography>
      <Box component={'div'} sx={{ maxWidth: '689px', my: 3 }}>
        <Typography component={'p'} variant='paragraph'>
          Use the list below to check for new forms or changes made to awarded work in the web app
          since the last time this class was synced to the Pics.io archive.
        </Typography>
        <Typography component={'p'} variant='paragraph' sx={{ my: 1 }}>
          When you sync a student's work, any new forms are added to the matching collection in
          Pics.io. Any new or modified metadata for the chosen work is also updated.
        </Typography>
        <Typography component={'p'} variant='paragraph'>
          Note that any changes made in Pics.io are not synced back to the web app.
        </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Button
          title={
            <span className='icon-text-span'>
              {isProcessing ? (
                <Spinner
                  as='span'
                  animation='border'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                  style={{ marginRight: '8px' }}
                />
              ) : (
                <RefreshCw size={18} />
              )}
              Sync Files from All Selected Students to Pics.io
            </span>
          }
          variant='contained'
          disabled={checkboxes?.filter(checkbox => checkbox.isChecked)?.length <= 0 || isProcessing}
          onClick={syncAll}
          sx={{ width: { xs: '100%', sm: 'max-content' } }}
        />
      </Box>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={1}>
          <FormCheckbox sx={{ mx: 2 }} onChange={toggleSelectAll} checked={selectAllChecked} />
        </Grid>
        <Grid item xs={11} display={{ sm: 'none' }}>
          <Typography variant='dataLabel' component={'p'} pl={1}>
            Select All Students
          </Typography>
        </Grid>
        <Grid item xs={4} display={{ xs: 'none', sm: 'inherit' }}>
          <Typography variant='dataLabel' component={'p'} pl={1}>
            Awarded Student Name
          </Typography>
        </Grid>
        <Grid item xs={4} display={{ xs: 'none', sm: 'inherit' }}>
          <Typography variant='dataLabel' component={'p'}>
            File Status
          </Typography>
        </Grid>
      </Grid>

      {data.cohort.students.map(student => (
        <StudentListRow
          key={`st-record-${student.id}`}
          student={student}
          projects={data.cohort.submittedProjects}
          collectionIds={collectionIds}
          cohort={data.cohort}
          showToast={showToast}
          refetchParent={refetchParent}
          showModal={setOpenDialog}
          setNewFiles={setNewFiles}
          setSelectedStudent={setSelectedStudent}
          setSelectedStudentProject={setSelectedStudentProject}
          setIsAwardUpdated={setIsAwardUpdated}
          isChecked={(checkboxes.find(item => item.id === student.id) || {})?.isChecked || false}
          toggleCheckbox={toggleCheckbox}
          parentProcessing={isProcessing}
        />
      ))}
      <FileDtailsModal
        show={openDialog}
        showModal={setOpenDialog}
        files={newFiles}
        student={selectedStudent}
        collectionIds={collectionIds}
        showToast={showToast}
        refetchParent={refetchParent}
        project={selectedStudentProject}
        isAwardUpdated={isAwardUpdated}
        cohort={data.cohort}
      />
    </>
  )
}
