/* eslint-disable array-callback-return */
import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import './modals.scss'
import Modal from 'react-bootstrap/Modal'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { observer, inject } from 'mobx-react'
import AdminPhotoMeta from '../molecules/AdminPhotoMeta.js'
import AdminPublishControls from '../molecules/AdminPublishControls.js'
import PhotoInformation from '../molecules/PhotoInformation.js'
import ModelReleaseSummary from '../molecules/ModelReleaseSummary.js'
import PhotoComments from '../molecules/PhotoComments.js'
import PhotoEditor from '../organisms/PhotoEditor'

import Loading from '../elements/Loading'

// import { withRouter } from 'react-router'
import { PHOTO_ATTRIBUTES } from '../../graphql/photoAttributes'
import PhotoAltTextEditor from './PhotoAltTextEditor'
import UseAgreementSummary from './UseAgreementSummary'
import { getApprovedEntityCount } from '../../utils/commonFunctions'
import Toast from '../elements/Toast'
import PhotoFeature from './PhotoFeature'
import PhotoDetailsHeader from './PhotoDetailsHeader'
import PhotoDetailsAwardSection from './PhotoDetailsAwardSection'
import PhotoDetailsFileOperations from './PhotoDetailsFileOperations'
import GATag from '../../utils/GATag'
import AdminPhotoSubmittedDateOverride from './AdminPhotoSubmittedDateOverride'
import { withRouter } from '../../utils/customHoc'

const PHOTO_DETAIL_QUERY = gql`
  query PhotoDetails($id: ID!) {
    photo(id: $id) {
      ...photoAttributes
    }
  }
  ${PHOTO_ATTRIBUTES}
`

class FullScreenPhotoModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photoId: props.photoId,
      data: props.photoDetails,
      batchCounter: this.props.batchCounter,
      showEdit: false,
      toastShow: false,
      showAlert: false,
      isShowingEdited: false,
      zoomEnabled: false,
      shouldAllowUpdate: true,
      photoReplaced: false,
      displayUrl: props.photoDetails
        ? props.photoDetails.cdnOriginalUrl ||
          props.photoDetails.url ||
          props.photoDetails.originalUrl ||
          props.photoDetails.sizedUrl
        : null,
      zoomUrl: props.photoDetails ? props.photoDetails.originalUrl : null,
      isEditing: false,
      enableRedyToPublish: props.photoDetails
        ? (props.photoDetails.hasPeople &&
            props.photoDetails.totalMrRequired ===
              getApprovedEntityCount(props.photoDetails.modelReleases, 'mr') &&
            getApprovedEntityCount(props.photoDetails.useAgreements, 'ua') > 0) ||
          (props.photoDetails.hasPeople === false &&
            getApprovedEntityCount(props.photoDetails.useAgreements, 'ua') > 0)
        : false,
      editAward: null,
      showEditAwardModal: false,
      imagWidth: '53vw',
      enableNext: props.photoCurrentIndex === props.totalCount - 1 ? false : true,
      enablePrev: props.photoCurrentIndex === 0 ? false : true,
    }
    // Create reference of DOM object
    this.imgRef = React.createRef()
    this.parentUrl = ''
  }

  handleResize = () =>
    this.setState({
      imagWidth:
        this.imgRef.current && this.imgRef.current.width ? this.imgRef.current.width + 'px' : '53vw'
    })

  componentDidUpdate(prevProps) {
    if(this.props.photoId !== prevProps.photoId) {
      this.initWindowState()
      this.refetchPhoto(prevProps.photoId)
    }
  }
  componentDidMount() {
    const { backToPageStore, location } = this.props
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    backToPageStore.setActivePage(location.pathname)
    // this.parentUrl = window.location.pathname
    this.initWindowState()
    window.onpopstate = e => {
      this.props.toggleParentModalState(false)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
    this.handleResize()
    // window.history.pushState(null, '', this.parentUrl)
  }

  initWindowState = () => {
    if (this.props.photoId) {
      window.history.pushState(
        { isModal: true },
        'Photo Details',
        '/photo-detail/' + this.props.photoId
      )
      GATag.setEvent({
        category: 'photo-detail',
        action: 'viewed',
        label: '/photo-detail/' + this.props.photoId,
        value: 1
      })
    }
  }

  toggleShowEdit = () => {
    this.setState({
      showEdit: !this.state.showEdit
    })
  }

  handleEditToggleChange = () => {
    this.setState({ isShowingEdited: !this.state.isShowingEdited })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.shouldAllowUpdate && nextProps.photoId !== prevState.photoId) {
      return {
        photoId: nextProps.photoId,
        data: nextProps.photoDetails,
        shouldAllowUpdate: true,
        displayUrl: nextProps.photoDetails
          ? nextProps.photoDetails.cdnOriginalUrl ||
            nextProps.photoDetails.url ||
            nextProps.photoDetails.originalUrl ||
            nextProps.photoDetails.sizedUrl
          : null,
        zoomUrl: nextProps.photoDetails ? nextProps.photoDetails.originalUrl : null,
        loading: false,
        enableNext: nextProps.photoCurrentIndex === nextProps.totalCount - 1 ? false : true,
        enablePrev: nextProps.photoCurrentIndex === 0 ? false : true,
        enableRedyToPublish: nextProps.photoDetails
          ? (nextProps.photoDetails.hasPeople &&
              nextProps.photoDetails.totalMrRequired ===
                getApprovedEntityCount(nextProps.photoDetails.modelReleases, 'mr') &&
              getApprovedEntityCount(nextProps.photoDetails.useAgreements, 'ua') > 0) ||
            (nextProps.photoDetails.hasPeople === false &&
              getApprovedEntityCount(nextProps.photoDetails.useAgreements, 'ua') > 0)
          : false
      }
    } else {
      return {
        // data: nextProps.photoDetails
      }
    }
  }

  renderStudentProject = photo => {
    this.props.toggleParentModalState()
    const projectDetails = window.location.pathname.includes('project-detail')
    if (!projectDetails) {
      setTimeout(() => {
        window.location = `/project-detail/${photo.project.id}`
      }, 500)
    }
  }

  refetchPhoto = async () => {
    this.props.client
      .watchQuery({
        query: PHOTO_DETAIL_QUERY,
        variables: {
          id: this.state.data.id
        },
        fetchPolicy: 'cache-and-network'
      })
      .subscribe(res => {
        if(res.data.photo.id === this.state.photoId){
          this.setState({ data: res.data.photo, shouldAllowUpdate: false })
          if (this.props.updateParentGallery) this.props.updateParentGallery(res.data.photo)
          if (this.props.updateParentAwardGroups) this.props.updateParentAwardGroups(res.data.photo)
          // update in photo colleciton store.
          if (this.props.photoCollectionStore && this.props.photoCollectionStore.items.length > 0) {
            const photo = this.props.photoCollectionStore.items.filter(
              obj => obj.id === this.props.photoId
            )[0]
            const idx = this.props.photoCollectionStore.items.indexOf(photo)
            const newItems = this.props.photoCollectionStore.items
              ? [...this.props.photoCollectionStore.items]
              : []
            newItems[idx] = res.data.photo
            this.props.photoCollectionStore.setItems(newItems)
          }
          this.setState({ ...this.state, shouldAllowUpdate: true })
        }
      })
  }

  replacePhotoToast = () => {
    this.setState({
      photoReplaced: true
    })
    setTimeout(() => this.setState({ photoReplaced: false }), 2000)
  }

  updateRAW = (base64, fileSize, fileName) => {
    let data = { ...this.state.data }
    data.rawFileUrl = base64
    data.rawFileName = fileName
    data.rawFileSize = fileSize
    this.setState({
      ...this.state,
      data
    })
  }

  updateImage = async (srcString = '') => {
    let data = { ...this.state.data }
    data.url = srcString
    data.cdnOriginalUrl = srcString
    data.originalUrl = srcString
    data.sizedUrl = srcString
    this.setState({
      ...this.state,
      data,
      base64URL: srcString
    })
    this.props.updateParentGallery(data)
  }

  setShouldRefetchParent = shouldRefetchParent => {
    this.setState({
      ...this.state,
      shouldRefetchParent
    })
  }

  getAdjacentImageKeyboard = e => {
    if (!e || !e.keyCode) return
    if (
      ((e.keyCode === 37 && this.state.enablePrev) ||
        (e.keyCode === 39 && this.state.enableNext)) &&
      !this.state.isEditing
    ) {
      this.props.getAdjacentImage &&
        this.props.getAdjacentImage(e.keyCode === 37 ? 'prev' : 'next', this.state.data.id)
    }
  }

  toggleEditing = isEditing => {
    this.setState({
      isEditing: isEditing
    })
  }

  showToast = (message, color) => {
    this.setState({
      toastShow: true,
      toastMessage: message,
      toastColor: color,
      smallText: true
    })
    setTimeout(() => this.setState({ toastShow: false }), 5000)
  }

  render() {
    const { userStore, location } = this.props
    const { role, profileId } = userStore
    const { data } = this.state
    const publicView = ['/national-photos', '/collection', '/covid-collection'].includes(
      location.pathname
    )
      ? true
      : false
    let photoAwards = []

    if (this.state.data) {
      const { awards } = this.state.data
      awards &&
        awards.map(award => {
          if (!photoAwards.includes(award.awardClass)) {
            photoAwards.push(award.awardClass)
          }
        })
    }

    if (data && Object.keys(data).length > 0) {
      const passThroughProps = _.omit(this.props, [
        'staticContext',
        'photoCollectionStore',
        'userStore',
        'updateParentAwardGroups',
        'updateParentGallery',
        'getAdjacentImage',
        'toggleParentModalState',
        'photoId'
      ])
      const onClassReview = window.location.pathname.includes('class-review')
      const curatorReviews = data.project.cohort.curatorReviews
      const awardChipDisabled =
        ['curator'].includes(role) &&
        (data.project.cohort.reviewed ||
          curatorReviews.filter(review => review.reviewerId === profileId).length > 0)
      return (
        <div tabIndex='0' onKeyUp={e => this.getAdjacentImageKeyboard(e)}>
          <Modal
            {...passThroughProps}
            show={this.props.show}
            onClick={this.props.onClick}
            value={this.props.value}
            className={`${this.props.className} photo-detail-modal ${
              onClassReview ? 'class-review-photo-detail-modal' : ''
            }`}
            onHide={() => {
              this.props.toggleParentModalState(true)
              this.refetchPhoto(data.id)
            }}
          >
            {/* Modal Header Section */}
            <Modal.Header closeButton onHide={() => this.props.toggleParentModalState(false, true)}>
              {/* Code for showing out success and warming text on full screen modal */}
              {this.state.toastShow === true ? (
                <Toast
                  toastMessage={this.state.toastMessage}
                  toastColor={this.state.toastColor}
                  textSize={this.state.smallText}
                />
              ) : null}

              {/* Photo details header section */}
              <PhotoDetailsHeader
                photo={data}
                role={role}
                isModal={true}
                forStatusStack={false}
                awardChipDisabled={awardChipDisabled}
                setShouldRefetchParent={this.setShouldRefetchParent}
                updateParentGallery={this.props.updateParentGallery}
                publicView={publicView}
              />
            </Modal.Header>

            {/* Image Preview Section */}
            <PhotoFeature
              photo={data}
              placeholderDark={this.props.placeholderDark}
              photoImageType='Image Type '
              photoDimensions=' Dimensions '
              photoFileSize=' File Size'
              showEdit={this.state.showEdit}
              handleToggle={this.toggleShowEdit.bind(this)}
              isModal={true}
              enableNext={this.state.enableNext}
              enablePrev={this.state.enablePrev}
              shouldAllowUpdate={this.state.shouldAllowUpdate}
              getAdjacentImage={this.props.getAdjacentImage}
              key={'fsm-'+data.id}
              publicView={publicView}
            />

            <Modal.Body>
              {/* Photo title and caption editing section */}
              <Fragment>
                {!publicView ? (
                  <PhotoEditor
                    showEdit={this.state.showEdit}
                    handleToggle={this.toggleShowEdit.bind(this)}
                    photoId={data.id}
                    isShowingEdited={this.state.isShowingEdited}
                    photo={data}
                    updateParentGallery={this.props.updateParentGallery}
                    fetchProject={this.props.refetchProject}
                    toggleEditing={this.toggleEditing.bind(this)}
                    showToast={this.showToast}
                    refetchPhoto={this.refetchPhoto}
                    publicView={publicView}
                  />
                ) : (
                  <div className='photo-caption'>
                    <blockquote className='blockquote--editable'>{data.caption}</blockquote>
                  </div>
                )}
              </Fragment>

              <div className='photo-detail-modal-body'>
                {/* Photos awards section */}
                <PhotoDetailsAwardSection
                  photo={data}
                  isModal={true}
                  publicView={publicView}
                  role={role}
                  refetchPhoto={this.refetchPhoto}
                />

                {/* Photos comments section */}
                <PhotoComments
                  photoId={this.state.data.id}
                  comments={this.state.data.photoComments}
                  userStore={userStore}
                  refetchphoto={this.refetchPhoto}
                  toggleEditing={this.toggleEditing.bind(this)}
                  role={role}
                  profileId={profileId}
                  publicView={publicView}
                  photo={this.state.data}
                />

                {/* Use Agreement Section */}
                <UseAgreementSummary
                  useAgreements={this.state.data.useAgreements}
                  photoId={this.state.data.id}
                  refetchPhoto={this.refetchPhoto}
                  refetchQueries={[`PhotoDetails`]}
                  photo={data}
                  toggleEditing={this.toggleEditing.bind(this)}
                  studentDetails={this.state.data.project}
                  showEdit={this.state.showEdit}
                  isShowingEdited={this.state.isShowingEdited}
                  fetchProject={this.props.refetchProject}
                  student={this.props.student}
                  publicView={publicView}
                />

                {!publicView ? (
                  <Fragment>
                    {/* Model Release Section */}
                    <ModelReleaseSummary
                      modelReleases={this.state.data.modelReleases}
                      hasPeople={this.state.data.hasPeople}
                      totalMrRequired={this.state.data.totalMrRequired}
                      photoId={this.state.data.id}
                      refetchPhoto={this.refetchPhoto}
                      refetchQueries={[`PhotoDetails`]}
                      photo={data}
                      toggleEditing={this.toggleEditing.bind(this)}
                      studentDetails={this.state.data.project}
                      showEdit={this.state.showEdit}
                      isShowingEdited={this.state.isShowingEdited}
                      adminNotes={this.state.data.mrAdminNotes}
                      fetchProject={this.props.refetchProject}
                      publicView={publicView}
                    />
                    <PhotoAltTextEditor
                      photoId={data.id}
                      photo={data}
                      toggleEditing={this.toggleEditing.bind(this)}
                    />
                    <AdminPublishControls
                      photoId={this.state.data.id}
                      photo={this.state.data}
                      role={role}
                      refetchPhoto={this.refetchPhoto}
                      enableRedyToPublish={this.state.enableRedyToPublish}
                    />
                    <PhotoDetailsFileOperations
                      role={role}
                      photo={this.state.data}
                      updateImage={this.updateImage}
                      refetchPhoto={this.refetchPhoto}
                      replacePhotoToast={this.replacePhotoToast}
                      updateRAW={this.updateRAW}
                      userStore={userStore}
                    />
                    {
                      role === 'appadmin' ?
                        <AdminPhotoSubmittedDateOverride
                          photoId={data.id}
                          photo={data}
                          toggleEditing={this.toggleEditing.bind(this)}
                        />
                      : null
                    }
                    <AdminPhotoMeta
                      photoId={this.state.data.id}
                      toggleParentModalState={this.props.toggleParentModalState}
                      photo={this.state.data}
                      isStudent={false}
                      role={role}
                    />
                  </Fragment>
                ) : null}
              </div>
              <div className='photo-detail-modal-footer'>
                {!publicView ? (
                  <PhotoInformation
                    photoId={this.state.data.id}
                    photo={this.state.data}
                    newRawFile={this.state.data.rawFileUrl}
                  />
                ) : null}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
    } else {
      return <Fragment>{this.props.show ? <Loading /> : <></>}</Fragment>
    }
  }
}

export default inject('userStore', 'photoCollectionStore', 'backToPageStore')(
  observer(withApollo(withRouter(FullScreenPhotoModal)))
)
