import React, { Fragment, Component } from 'react'
import FormField from '../ui/elements/FormField.js'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
// import { withRouter } from 'react-router-dom'
import Layout from '../ui/template/Layout'
import { Typography, Box, Grid} from '@mui/material'
import Button from '../ui/elements/Button.js'
import { withRouter } from '../utils/customHoc.js'

const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPasswordRequest($email: String) {
    forgotAccountPassword(email: $email) {
      errors {
        path
        message
      }
    }
  }
`

class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = { passwordResetSent: false, email: '', errors: '' }
  }
  handleSubmit(e, onMutate) {
    e.preventDefault()
    this.setState({ submitted: true })
    if (!this.state.submitted) {
      onMutate({
        variables: {
          email: this.state.email
        }
      })
    }
  }

  checkResponseForErrors(data) {
    const {navigate} = this.props
    if (data.forgotAccountPassword.errors) {
      this.setState({ passwordResetSent: false, errors:{email: 'No user found with this email'}})
    } else {
      navigate('/login?password-reset-toast=1')
    }
  }

  validateEmail(testString) {
    var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({ errors: { email: '' } });
    if (!emailpattern.test(testString)) {
      this.setState({ errors: { email: 'Please enter a valid email address' } });
    }
  }

  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    if (e.target.name === 'email') {
      this.validateEmail(e.target.value)
    }
  }

  render() {
    return (
      <Layout>
        <Grid item>
          <Box className='container__body--login' component={'div'}>
            <Box className='container__login' sx={{pb: {xs: 5, sm: 10}}}>
              {/* TO DO: MAKE THIS ACTUALLY SHOW WHEN FORM FOR PASSWORD RESET IS SUBMITTED!! */}
              {this.state.passwordResetSent ? (
                <>
                  <Typography variant='h4' gutterBottom>
                    Reset link sent.
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    A link to reset your password has been sent to {this.state.email}.
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    If you do not receive an email soon, please check your spam folder or contact{' '}
                    <a href='mailto:education@workingassumptions.org'>education@workingassumptions.org</a>{' '}
                    for help.
                  </Typography>
                </>
              ) : (
                <Mutation
                  mutation={FORGOT_PASSWORD_MUTATION}
                  onCompleted={data => {
                    this.checkResponseForErrors(data);
                    this.setState({ submitted: false });
                  }}
                >
                  {(onMutate, { data }) => {
                    return (
                      <Box sx={{maxWidth: {sm: 500, md: 620, lg: 520}, display: 'inline-block'}}>
                        <Box component={'div'} className='login__header' sx={{ mb: 4, mt: {xs: 3, sm: 8, lg: 2}}}>
                          <Typography
                            variant='h1'
                            component='h1'
                            className='bold'
                            sx={{p: {xs: '0 2rem', sm: 0}}}
                          >
                            Forgot your password?
                          </Typography>
                          <Typography
                            variant='paragraph'
                            component='p'
                            mt={{xs: 2, md: 3}}
                          >
                            Enter the email address for your account and we’ll send you a link to
                            reset your password.
                          </Typography>
                        </Box>
                        <Box component='form' noValidate>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormField
                                  formFieldId='outlined-email'
                                  formFieldLabel='Email'
                                  formFieldVariant='filled'
                                  formFieldType='email'
                                  formFieldName='email'
                                  formFieldError={this.state.errors ? this.state.errors.email : ''}
                                  onChange={event => this.setState({ email: event.target.value }, () => this.validateEmail(this.state.email))}
                                />
                            </Grid>
                            <Grid item
                              xs={12}
                              mt={{
                                xs: this.state.errors && this.state.errors.email ? '-10px' : '20px',
                                md: this.state.errors && this.state.errors.email ? '-10px' : '20px'
                              }}
                              pt={{ xs:'20px !important' }}
                            >
                              <Button
                                variant='contained'
                                onClick={e => this.handleSubmit(e, onMutate)}
                                disabled={!this.state.email || (this.state.errors && this.state.errors.email)}
                                sx={{width: {xs: '100%', sm: 'auto'}}}
                                title='Send Reset Link'
                              />
                            </Grid>
                            <Grid item xs={12} mt={{xs: 3, sm: 8}}>
                              <Typography
                                variant='paragraph'
                                component='p'
                              >
                                If you can't access the email address for your account, please contact{' '}
                                <a
                                  style={{ fontSize: 'inherit', wordBreak:'break-word', textDecoration: 'underline' }}
                                  href='mailto:education@workingassumptions.org'
                                >
                                  education@workingassumptions.org
                                </a>
                                .
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    );
                  }}
                </Mutation>
              )}
            </Box>
          </Box>
        </Grid>
      </Layout>
    )
  }
}

export default withRouter(ForgotPassword)
