import { useMutation } from '@apollo/client'
import moment from 'moment'
import { validationText } from '../content/validationText'
import {
  ADD_ASSET,
  ADD_ASSET_ID_TO_MR,
  ADD_ASSET_ID_TO_UA,
  ADD_CAPTION_ASSET_ID_TO_PROJECT
} from '../queries/assetQueries'
import { ADD_COHORT_SYNC_DATES } from '../queries/createClassQueries'

const usePicsio = () => {
  const PAGE_SIZE = 1
  const HEADERS = { 'Content-Type': 'application/json' }
  const API_URL = process.env.REACT_APP_PICSIO_API_URL
  const ENDPOINTS = {
    login: `${API_URL}login`,
    refreshToken: `${API_URL}users/refreshToken`,
    tags: `${API_URL}tags`,
    buildS3UploadLink: `${API_URL}images/buildS3UploadLink`,
    completeS3Multipart: `${API_URL}images/completeS3Multipart`,
    images: `${API_URL}images`,
    synchCollection: collectionId => `${API_URL}collections/${collectionId}/sync`,
    updateCustomField: `${API_URL}customFieldsValue/batch`,
    attachCollection: `${API_URL}batch/assets/addCollection`,
    searchCollections: query => `${API_URL}collections/search/${query}`,
    linkAssets: `${API_URL}linkedAssets/link`,
    removeCollection: `${API_URL}batch/assets/removeCollection`,
    removeImage: `${API_URL}images/trash`,
    unlinkAssets: `${API_URL}linkedAssets/unlink`
  }

  const [addAsset] = useMutation(ADD_ASSET)
  const [addMRAsset] = useMutation(ADD_ASSET_ID_TO_MR)
  const [addUAAsset] = useMutation(ADD_ASSET_ID_TO_UA)
  const [addCohortSyncDates] = useMutation(ADD_COHORT_SYNC_DATES)
  const [addCaptionAssetIdToProject] = useMutation(ADD_CAPTION_ASSET_ID_TO_PROJECT)

  const login = async () => {
    try {
      const response = await fetch(ENDPOINTS.login, {
        method: 'POST',
        headers: {
          ...HEADERS,
          'X-Picsio-App-Id': process.env.REACT_APP_PICSIO_APP_ID
        },
        body: JSON.stringify({
          email: process.env.REACT_APP_PICSIO_USERNAME,
          password: process.env.REACT_APP_PICSIO_PASSWORD
        })
      }).catch(error => {
        throw error
      })

      const data = await response.json()
      if (data?.errors) {
        throw new Error(data?.errors?._id)
      }
      return data?.tokens
    } catch (error) {
      throw error
    }
  }

  const refreshTokens = async token => {
    try {
      const response = await fetch(ENDPOINTS.refreshToken, {
        method: 'POST',
        headers: HEADERS,
        body: JSON.stringify({
          refreshToken: token
        })
      })

      const data = await response.json()
      if (!data?.accessToken) {
        return await login()
      }
      return data
    } catch (error) {
      throw error
    }
  }

  const handleAuth = async () => {
    try {
      let tokens = ''
      const refreshToken = localStorage.getItem('refreshToken')
      if (refreshToken) {
        tokens = await refreshTokens(refreshToken)
      } else if (!tokens?.accessToken) {
        tokens = await login()
      }
      if (tokens?.errors?.length) {
        throw new Error(tokens)
      }
      localStorage.setItem('refreshToken', tokens?.refreshToken || '')
      return tokens
    } catch (error) {
      throw error
    }
  }

  const sendFile = (url, chunk, partNum) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = () => {
        const etag = xhr.getResponseHeader('etag')
        resolve({ etag, partNum })
      }
      xhr.onerror = err => {
        reject(err)
      }
      xhr.open('PUT', url, true)
      xhr.setRequestHeader('content-disposition', 'attachment')
      xhr.send(chunk)
    })
  }

  const buildS3UploadLink = async (fileName, collectionId, fileSize, mimeType, bearerToken) => {
    return fetch(ENDPOINTS.buildS3UploadLink, {
      method: 'POST',
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${bearerToken}`
      },
      body: JSON.stringify({
        fileName,
        collectionId,
        fileSize,
        mimeType
      })
    }).then(response => response.json())
  }

  const uploadChunks = async (file, urlsData) => {
    const chunkNums = urlsData.length
    const chunkSize = file.size / chunkNums

    const fileBlobChunks = Array.from({ length: chunkNums }, (_, num) => {
      const start = num * chunkSize
      const end = (num + 1) * chunkSize
      return num < chunkNums
        ? file.slice(start, end, file.type)
        : file.slice(start, undefined, file.type)
    })

    const resAll = await Promise.all(
      urlsData.map(async urlObject => {
        try {
          const chunk = fileBlobChunks[urlObject.partNum - 1]
          return await sendFile(urlObject.url, chunk, urlObject.partNum)
        } catch (err) {
          throw err
        }
      })
    )
    return resAll.map(item => ({
      ETag: item.etag,
      PartNumber: item.partNum
    }))
  }

  const completeS3Multipart = async (parts, uploadId, storageId, bearerToken) => {
    await fetch(ENDPOINTS.completeS3Multipart, {
      method: 'POST',
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${bearerToken}`
      },
      body: JSON.stringify({
        parts,
        uploadId,
        storageId
      })
    })
  }

  const createImageAsset = async (
    photo,
    fileName,
    fileExtension,
    fileSize,
    collectionId,
    storageId,
    bearerToken,
    title = null,
    isForm = false
  ) => {
    const response = await fetch(ENDPOINTS.images, {
      method: 'POST',
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${bearerToken}`
      },
      body: JSON.stringify({
        additionalFields: {
          assigneeIds: [],
          keywordsIds: [],
          color: 'nocolor',
          comment: '',
          rating: 0,
          title: title || photo.originalTitle,
          description: isForm ? '' : photo.originalCaption,
          flag: 'accepted'
        },
        assetData: {
          storageId: storageId,
          name: fileName,
          fileSize,
          fileType: fileExtension,
          tags: [{ _id: collectionId }],
          studentCaption: photo.caption
        }
      })
    }).catch(error => {
      throw error
    })
    const data = await response.json()
    return data
  }

  const syncCollection = async (collectionId, bearerToken) => {
    await fetch(ENDPOINTS.synchCollection(collectionId), {
      method: 'POST',
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${bearerToken}`
      },
      body: JSON.stringify({
        strategy: 'subtree'
      })
    })
  }

  const uploadPhotoInCollection = async (student, photo, collectionId, photoNames, accessToken) => {
    const fileExtension = photoNames[photo.id].extension
    const fileName = `${photoNames[photo.id].fileName}.${fileExtension}`
    try {
      const file = await fetch(photo.originalUrl).then(r => r.blob())
      const fileSize = file.size
      const mimeType = file.type
      const bearerToken = accessToken

      const { urls: urlsData, uploadId, storageId } = await buildS3UploadLink(
        fileName,
        collectionId,
        fileSize,
        mimeType,
        bearerToken
      )

      const parts = await uploadChunks(file, urlsData)
      await completeS3Multipart(parts, uploadId, storageId, bearerToken)
      const imageAssetResponse = await createImageAsset(
        photo,
        fileName,
        fileExtension,
        fileSize,
        collectionId,
        storageId,
        bearerToken
      )
      return imageAssetResponse
    } catch (error) {
      throw error
    }
  }

  const uploadUAForms = async (photo, collectionId, accessToken) => {
    if (!photo?.formsPendingPicsioSync?.ua?.assetId) {
      try {
        const fileName =
          photo?.formsPendingPicsioSync?.ua?.fileName ||
          photo?.formsPendingPicsioSync?.ua?.blob?.filename
        const file = await fetch(photo?.formsPendingPicsioSync?.ua?.url).then(r => r.blob())
        const fileSize = file.size
        const mimeType = photo?.formsPendingPicsioSync?.ua?.blob?.content_type || 'application/pdf'
        const extension = mimeType.split('/').pop()
        const bearerToken = accessToken
        const title = photo?.formsPendingPicsioSync?.ua?.title

        const { urls: urlsData, uploadId, storageId } = await buildS3UploadLink(
          fileName,
          collectionId,
          fileSize,
          mimeType,
          bearerToken
        )

        const parts = await uploadChunks(file, urlsData)
        await completeS3Multipart(parts, uploadId, storageId, bearerToken)
        const formAssetResponse = await createImageAsset(
          photo,
          fileName,
          extension,
          fileSize,
          collectionId,
          storageId,
          bearerToken,
          title,
          true
        )

        await attachFormCollection(
          formAssetResponse._id,
          process.env?.REACT_APP_PICSIO_UA_COLLECTION_ID,
          bearerToken
        )

        //Updated sync info in database
        await addUAAsset({
          variables: { id: photo?.formsPendingPicsioSync?.ua?.id, assetId: formAssetResponse._id }
        })

        return formAssetResponse
      } catch (error) {
        throw error
      }
    }
  }

  const uploadMRForms = async (photo, collectionId, accessToken) => {
    try {
      const formAssetResponse = []
      const bearerToken = accessToken

      for (const mrForm of Object.values(photo?.formsPendingPicsioSync?.mr?.forms || {})) {
        if (!mrForm.assetId) {
          const fileName = mrForm?.fileName || mrForm?.blob?.filename
          const file = await fetch(mrForm?.url).then(r => r.blob())
          const fileSize = file.size
          const mimeType = mrForm?.blob?.content_type || 'application/pdf'
          const extension = mimeType.split('/').pop()
          const title = mrForm?.title

          const { urls: urlsData, uploadId, storageId } = await buildS3UploadLink(
            fileName,
            collectionId,
            fileSize,
            mimeType,
            bearerToken
          )

          const parts = await uploadChunks(file, urlsData)
          await completeS3Multipart(parts, uploadId, storageId, bearerToken)
          const response = await createImageAsset(
            photo,
            fileName,
            extension,
            fileSize,
            collectionId,
            storageId,
            bearerToken,
            title,
            true
          )
          formAssetResponse[mrForm.id] = response

          await attachFormCollection(
            response._id,
            process.env?.REACT_APP_PICSIO_MR_COLLECTION_ID,
            bearerToken
          )
          //Updated sync info in database
          await addMRAsset({ variables: { id: mrForm.id, assetId: response._id } })
        }
      }
      return formAssetResponse
    } catch (error) {
      throw error
    }
  }

  const uploadCaptions = async (project, collectionId, accessToken, awardUpdated) => {
    if (awardUpdated && project?.captionAssetId && project?.captionsPendingPicsioSync?.count) {
      await removeImage([project?.captionAssetId], accessToken)
    } else if (project?.captionAssetId) {
      return project?.captionAssetId
    }

    try {
      const fileName = project?.captionsPendingPicsioSync?.blob?.filename
      const file = await fetch(project?.captionsPendingPicsioSync?.url).then(r => r.blob())
      const fileSize = project?.captionsPendingPicsioSync?.blob?.byte_size
      const mimeType = project?.blob?.content_type || 'pdf'
      const bearerToken = accessToken
      const title =
        project?.captionsPendingPicsioSync?.title || `Caption Originals - ${project?.student?.name}`

      const { urls: urlsData, uploadId, storageId } = await buildS3UploadLink(
        fileName,
        collectionId,
        fileSize,
        mimeType,
        bearerToken
      )

      const parts = await uploadChunks(file, urlsData)
      await completeS3Multipart(parts, uploadId, storageId, bearerToken)

      const response = await fetch(ENDPOINTS.images, {
        method: 'POST',
        headers: {
          ...HEADERS,
          Authorization: `Bearer ${bearerToken}`
        },
        body: JSON.stringify({
          additionalFields: {
            assigneeIds: [],
            keywordsIds: [],
            color: 'nocolor',
            comment: '',
            rating: 0,
            title: title,
            description: '',
            flag: 'accepted'
          },
          assetData: {
            storageId: storageId,
            name: fileName,
            fileSize,
            fileType: 'pdf',
            tags: [{ _id: collectionId }],
            studentCaption: ''
          }
        })
      })
      const data = await response.json()

      //Updated sync info in database
      await addCaptionAssetIdToProject({
        variables: { id: project?.id, captionAssetId: data._id }
      })
      return data?._id
    } catch (error) {
      throw error
    }
  }

  const uploadFormsInCollection = async (photo, collectionIds, accessToken) => {
    try {
      const uaFormsResponse = photo?.formsPendingPicsioSync?.ua?.unsyncedCount
        ? await uploadUAForms(photo, collectionIds?.uaCollectionId, accessToken)
        : null
      const mrFormsResponse = photo?.formsPendingPicsioSync?.mr?.unsyncedCount
        ? await uploadMRForms(photo, collectionIds?.mrCollectionId, accessToken)
        : null
      return {
        ua: uaFormsResponse,
        mr: mrFormsResponse
      }
    } catch (error) {
      throw error
    }
  }

  const updateCustonFieldValue = async (assetId, photo, cohort, accessToken) => {
    const CUSTOM_FIELDS = [
      { title: 'StudentCaption', type: 'string', value: photo.originalCaption },
      { title: 'State', type: 'string', value: cohort?.school?.mailingAddress?.state },
      { title: 'Teacher', type: 'string', value: cohort?.teacher?.name },
      { title: 'TeachingArtist', type: 'string', value: cohort?.facilitator?.name }
    ]

    const validFields = CUSTOM_FIELDS.filter(
      field => field.value !== undefined && field.value !== null && field.value !== ''
    )

    const promises = validFields.map(async field => {
      try {
        const response = await fetch(ENDPOINTS.updateCustomField, {
          method: 'PUT',
          headers: {
            ...HEADERS,
            Authorization: `Bearer ${accessToken}`
          },
          body: JSON.stringify({
            images: [assetId],
            title: field.title,
            type: field.type,
            value: field.value
          })
        })

        if (!response.ok) {
          console.error(`Error updating custom field: ${field.title}`)
        }
      } catch (error) {
        console.error(`Error updating custom field: ${field.title}`, error)
      }
    })

    await Promise.all(promises)
  }

  const getAwardCollectionId = awards => {
    if (!awards || !awards?.length) {
      return null
    }
    let aoeAwardsCount = awards.filter(award => award.awardClass === 'Award of Excellence').length
    let hmAwardCount = awards.filter(award => award.awardClass === 'Honorable Mention').length
    return aoeAwardsCount > hmAwardCount || aoeAwardsCount === hmAwardCount
      ? {
          add: process.env.REACT_APP_PICSIO_AOE_COLLECTION_ID,
          remove: process.env.REACT_APP_PICSIO_HM_COLLECTION_ID
        }
      : {
          add: process.env.REACT_APP_PICSIO_HM_COLLECTION_ID,
          remove: process.env.REACT_APP_PICSIO_AOE_COLLECTION_ID
        }
  }

  const attachAwardCollection = async (assetId, photo, accessToken) => {
    setTimeout(() => {}, 6000)
    const awardCollectionId = getAwardCollectionId(photo?.awards)
    if (awardCollectionId?.remove) {
      await removeCollection([assetId], awardCollectionId?.remove, accessToken)
    }
    if (awardCollectionId?.add) {
      await attachCollection(assetId, awardCollectionId?.add, accessToken)
    }
  }

  const attachFormCollection = async (assetId, collectionId, accessToken) => {
    setTimeout(() => {}, 6000)
    await attachCollection(assetId, collectionId, accessToken)
  }

  const attachCollection = (assetId, collectionId, accessToken) => {
    fetch(ENDPOINTS.attachCollection, {
      method: 'POST',
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        ids: [assetId],
        collectionId: collectionId,
        isMove: false
      })
    })
  }

  // Define a function to process photos in paginated form
  const processPhotosInPages = async (
    student,
    photos,
    collectionIds,
    cohort,
    photoNames,
    captionAssetId,
    accessToken,
    awardUpdated
  ) => {
    const responses = []

    await Promise.all(
      photos.map(async photo => {
        let photoAssetId = photo?.assetId
        const photoAssetIdArray = photoAssetId ? [photoAssetId] : []
        const modelReleaseIds = photo?.modelReleases
          ? photo.modelReleases
              .filter(release => release.assetId !== null)
              .map(release => release.assetId)
          : []
        const useAgreementIds = photo?.useAgreements
          ? photo.useAgreements
              .filter(agreement => agreement.assetId !== null)
              .map(agreement => agreement.assetId)
          : []

        const associatedUAAssets = photoAssetIdArray.concat(useAgreementIds)
        const associatedMRAssets = [].concat(modelReleaseIds)
        const associatedCaptionAsset = captionAssetId
          ? photoAssetIdArray.concat([captionAssetId])
          : []

        if (!photoAssetId) {
          const response = await uploadPhotoInCollection(
            student,
            photo,
            collectionIds?.photoCollectionId,
            photoNames,
            accessToken
          )
          photoAssetId = response._id
          associatedUAAssets.push(photoAssetId)
          //associatedMRAssets.push(photoAssetId)
          associatedCaptionAsset.push(photoAssetId)

          //Add custo field StudentCaption value
          await updateCustonFieldValue(photoAssetId, photo, cohort, accessToken)

          // //Attach respective award collection
          // await attachAwardCollection(photoAssetId, photo, accessToken)

          //Updated sync info in database
          await addAsset({ variables: { photoId: photo.id, assetId: photoAssetId } })
          await addCohortSyncDates({ variables: { cohortId: cohort.id } })

          responses.push(response)
        }

        if (awardUpdated) {
          //Attach respective award collection
          await attachAwardCollection(photoAssetId, photo, accessToken)
        }

        //Upload forms
        const formsResponse = await uploadFormsInCollection(photo, collectionIds, accessToken)
        for (const key in formsResponse) {
          if (Array.isArray(formsResponse[key])) {
            formsResponse[key]?.forEach(item => {
              if (item && item?._id) {
                key === 'ua' ? associatedUAAssets.push(item._id) : associatedMRAssets.push(item._id)
              }
            })
          } else {
            if (formsResponse[key]?._id) {
              key === 'ua'
                ? associatedUAAssets.push(formsResponse[key]._id)
                : associatedMRAssets.push(formsResponse[key]._id)
            }
          }
        }
        if (associatedUAAssets.length > 1) {
          await linkAssets(associatedUAAssets)
        }
        if (associatedMRAssets.length > 0) {
          associatedMRAssets.forEach(async mr => {
            await linkAssets([photoAssetId, mr])
          })
        }
        if (associatedCaptionAsset.length > 1) {
          await linkAssets(associatedCaptionAsset)
        }
      })
    )
    return responses
  }

  const createCollection = async cohort => {
    try {
      const userTokens = await handleAuth()
      if (userTokens?.accessToken) {
        const schoolName = `${cohort.school.mailingAddress.state} - ${cohort.school.name}`
        const cohortEndDate = new Date(cohort.endDate)
        const className = `${cohortEndDate.getFullYear()} - ${cohort.name}`
        const originalPhotographs = 'Original Photographs'
        const forms = 'Forms'
        const mrForms = 'Forms - Model Releases'
        const uaForms = 'Forms - Use Agreements'
        const captions = 'Caption Originals'

        const tagNames = [
          { name: schoolName, path: '', entity: 'school' },
          { name: className, path: schoolName, entity: 'class' },
          { name: captions, path: `${schoolName}/${className}/`, entity: 'captions' },
          { name: forms, path: `${schoolName}/${className}/`, entity: 'forms' },
          { name: mrForms, path: `${schoolName}/${className}/${forms}`, entity: 'mr' },
          { name: uaForms, path: `${schoolName}/${className}/${forms}`, entity: 'ua' },
          { name: originalPhotographs, path: `${schoolName}/${className}/`, entity: 'photo' }
        ]
        const responses = []
        for (const tagName of tagNames) {
          const tagResponse = await fetch(ENDPOINTS.tags, {
            method: 'POST',
            headers: { ...HEADERS, Authorization: `Bearer ${userTokens?.accessToken}` },
            body: JSON.stringify({
              tag: { name: tagName.name },
              path: `${process.env.REACT_APP_PICSIO_ROOT_PATH}${tagName.path}`
            })
          })
          responses[tagName.entity] = await tagResponse.json()
        }
        return responses
      } else {
        throw new Error(validationText.picsio.createCollection.error)
      }
    } catch (error) {
      throw error
    }
  }

  const uploadPhoto = async (student, project, photos, collectionIds, cohort, awardUpdated) => {
    try {
      const responses = []
      const userTokens = await handleAuth()
      if (userTokens?.accessToken) {
        const photoNames = []
        photos.map(photo => {
          const fileExtension = photo.exifData.fileType
          const year = moment(photo.createdAt).format('YYYY')
          const name = (student?.name || '')
            .replace(/\s+/g, ' ')
            .trim()
            .split(' ')
            .join('-')
          let baseFileName = `working-assumptions-${name}-${(photo.title.trim() || '')
            .replace(/\s+/g, ' ')
            .trim()
            .split(' ')
            .join('-')}-${year}`

          let counter = 1
          let fileName = `${baseFileName}-${counter}`
          while (
            photoNames.some(entry => entry.fileName === fileName && entry.photoId !== photo.id)
          ) {
            counter++
            fileName = `${baseFileName}-${counter}`
          }

          photoNames[photo.id] = { photoId: photo.id, fileName: fileName, extension: fileExtension }
          return { photoId: photo.id, fileName: fileName, extension: fileExtension }
        })

        //Upload Captions
        let captionAssetId = null
        try {
          captionAssetId = await uploadCaptions(
            project,
            collectionIds?.captionsCollectionId,
            userTokens.accessToken,
            awardUpdated
          )
        } catch (error) {
          throw error
        }

        for (let i = 0; i < photos.length; i += PAGE_SIZE) {
          const photosBatch = photos.slice(i, i + PAGE_SIZE)
          const batchResponses = await processPhotosInPages(
            student,
            photosBatch,
            collectionIds,
            cohort,
            photoNames,
            captionAssetId,
            userTokens.accessToken,
            awardUpdated
          )
          responses.push(...batchResponses)
        }

        await syncCollection(collectionIds?.classCollectionId, userTokens.accessToken)
      }
      return responses
    } catch (error) {
      console.log('Error => ', error)
      throw error
    }
  }

  const searchCollections = async searchSring => {
    try {
      const userTokens = await handleAuth()
      if (userTokens?.accessToken) {
        const response = await fetch(ENDPOINTS.searchCollections(searchSring), {
          method: 'GET',
          headers: { ...HEADERS, Authorization: `Bearer ${userTokens?.accessToken}` }
        })
        return response
      }
    } catch (error) {
      throw error
    }
  }

  const linkAssets = async assetIds => {
    try {
      const userTokens = await handleAuth()
      if (userTokens?.accessToken) {
        const response = await fetch(ENDPOINTS.linkAssets, {
          method: 'POST',
          headers: { ...HEADERS, Authorization: `Bearer ${userTokens?.accessToken}` },
          body: JSON.stringify({
            assetIds: assetIds
          })
        })
        return response
      }
    } catch (error) {
      throw error
    }
  }

  const removeCollection = async (assetIds, collectionId, accessToken) => {
    if (!assetIds?.length || !collectionId) {
      return
    }

    try {
      const response = await fetch(ENDPOINTS.removeCollection, {
        method: 'POST',
        headers: { ...HEADERS, Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify({
          ids: assetIds,
          collectionId: collectionId
        })
      })

      return response
    } catch (error) {
      throw error
    }
  }

  const removeImage = async (assetIds, accessToken = null) => {
    if (!assetIds?.length) {
      return
    }

    try {
      if (!accessToken) {
        const userTokens = await handleAuth()
        accessToken = userTokens?.accessToken
      }
      const response = await fetch(ENDPOINTS.removeImage, {
        method: 'PUT',
        headers: { ...HEADERS, Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify({
          ids: assetIds
        })
      })

      return response
    } catch (error) {
      throw error
    }
  }

  const unlinkAssets = async (assetIds, accessToken) => {
    if (assetIds?.length < 2) {
      return
    }

    try {
      const response = await fetch(ENDPOINTS.unlinkAssets, {
        method: 'POST',
        headers: { ...HEADERS, Authorization: `Bearer ${accessToken}` },
        body: JSON.stringify({
          assetIds: assetIds
        })
      })

      return response
    } catch (error) {
      throw error
    }
  }

  return {
    createCollection,
    uploadPhoto,
    searchCollections,
    removeCollection,
    unlinkAssets,
    removeImage
  }
}

export default usePicsio
