import React, { Component, Fragment } from 'react'
import Dropzone from 'react-dropzone'
import Button from '../elements/Button.js'
import { DirectUpload } from 'activestorage'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { observer, inject } from 'mobx-react'
import * as Sentry from '@sentry/browser'
import { Box } from '@mui/material'

const CREATE_PHOTO_MUTATION = gql`
  mutation UpsertPhoto($fileSignedId: String!, $projectId: ID, $photoId: ID) {
    upsertPhoto(fileSignedId: $fileSignedId, projectId: $projectId, photoId: $photoId) {
      id
    }
  }
`

class PhotoUploader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message: '',
      fileCount: 0,
      uploadedFileCount: 0
    }
  }

  onDrop = (files, onMutate) => {
    files.length > 0 &&
    this.updateMessage(
      `${files.length || 1} photographs(s) uploading. Please do not leave the page.`,
      'warning'
    )
    this.setState({ ...this.state, fileCount: files.length })
    const filteredImages = [];
    let counter = 0;
    Array.from(files).forEach(file => {
      const image = new Image();
      image.addEventListener('load', () => {
          if (image.width < 800 ) {
            filteredImages.push(file)
          }
          counter+=1
          if(counter === files.length) {
            if(filteredImages.length > 0) {
              this.updateMessage(`One or more photographs cannot be uploaded because the file dimensions are too small. Please upload files with a minimum width of 800px.`, 'error')
            } else {
              this.handelOnDropImages(files, onMutate)
            }
          }
      });
      image.src = URL.createObjectURL(file)}
    )
  }
  handelOnDropImages = (files, onMutate) => {
    Array.from(files).forEach(file =>
      this.uploadFile(file, onMutate, this.props.projectId, this.props.photoId, files.length)
    )
  }
  uploadFile = (file, onMutate, projectId, photoId, filesCount = 1) => {
    const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
    const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'photo')

    upload.create((error, blob) => {
      if (error) {
        Sentry.captureException(
          new Error(`Something went wrong uploading a photo, error is ${error}`)
        )
      } else {
        onMutate({
          variables: {
            fileSignedId: blob.signed_id,
            projectId: projectId,
            photoId: photoId
          }
        })
        this.props.setUploadedFileCount && this.props.setUploadedFileCount(filesCount)
        this.setState({uploadedFileCount: this.state.uploadedFileCount + 1})
      }
    })
  }

  onUploadComplete = res => {
    const { onUploadCompleteCb } = this.props
    if (
      onUploadCompleteCb &&
      this.state.fileCount > 0
      && this.state.fileCount === this.state.uploadedFileCount
    ) {
      onUploadCompleteCb(res)
      this.updateMessage(
        `${this.state.fileCount} photograph(s) successfully uploaded to Library.`,
        'success'
      )
      this.setState({ ...this.state, message: 'upload successful', fileCount: 0, uploadedFileCount: 0 })
      if(this.props.refetchProject) {
        this.props.refetchProject()
      }
    }
    // this.setState({...this.state, uploadedFileCount: this.state.uploadedFileCount + 1}, () => {
    //   if (
    //     onUploadCompleteCb &&
    //     this.state.fileCount > 0
    //     && this.state.fileCount === this.state.uploadedFileCount
    //   ) {
    //     onUploadCompleteCb(res)
    //     this.updateMessage(
    //       `${this.state.fileCount} photograph(s) successfully uploaded to Library.`,
    //       'success'
    //     )
    //     this.setState({ ...this.state, message: 'upload successful', fileCount: 0 })
    //   }
    // })

  }

  updateMessage = (message, toastType) => {
    const { showToast } = this.props
    this.setState({
      ...this.state,
      message: message
    })
    showToast && showToast(toastType, message)
  }

  checkfileSizeError = (files) => {

    files.map(file => {
      const image = new Image();
      image.addEventListener('load', () => {
          if(image.width < 800)
            return false
      });
      image.src = URL.createObjectURL(file)
    })
  }

  render() {
    return (
      <Mutation mutation={CREATE_PHOTO_MUTATION} onCompleted={res => {
          this.onUploadComplete(res)
          if(this.props.refetchProject) {
            this.props.refetchProject()
          }
        }}>
        {(onMutate, { data, loading, error }) => (
          <Dropzone
            className='dropzone'
            data-cy='dropzone'
            onDrop={files => {
              if (files.length === 0) {
                return;
              } else if(files.length > 10){
                this.updateMessage('Please upload no more than 10 photographs at a time.', 'error')
              } else {
                this.onDrop(files, onMutate)
              }
            }}
            accept={('image/tiff', 'image/jpeg')}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              if (loading)
                !this.state.message.includes('Please do not leave the page.') &&
                  this.state.fileCount > 0 &&
                  this.updateMessage(
                    `${this.state.fileCount || 1} photographs(s) uploading. Please do not leave the page.`,
                    'warning'
                  )

              if (error)
                (this.state.message.includes('800px') || this.state.message.includes('10 photographs at a time.')) &&
                  this.updateMessage(this.state.message, 'error')

              return (
                <Fragment>
                  <Box>
                    <div {...getRootProps()} className='uploader__button'>
                      <input {...getInputProps()} />
                      <Button
                        variant={this.props.buttonVariant}
                        title={this.props.buttonTitle}
                        disabled={false}
                        icon='Upload'
                        sx={{width:{xs:'100%', sm:'50%', md:'auto'} }}
                      />
                    </div>

                  </Box>
                </Fragment>
              )
            }}
          </Dropzone>
        )}
      </Mutation>
    )
  }
}

export default inject('userStore')(observer(PhotoUploader))
