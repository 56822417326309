import { action, makeAutoObservable } from 'mobx'
import moment from 'moment'
import Observable from 'zen-observable-ts'

class UserStore {
  firstName = ''
  lastName = ''
  role = ''
  isAuthenticated = false
  lastSeenAt = null
  lastSignOutAt = null
  profileId = ''
  id = null
  photoRatings = []
  permaLinkedResources = {}
  email = ''
  phoneNumber = ''
  initials = ''

  constructor() {
    // Call it here
    makeAutoObservable(this)
  }

  updatePermaLinkedResources = action(resources => {
    this.permaLinkedResources = resources.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.linkToResource }),
      {}
    )
  })
  setFirstName = action(firstName => {
    this.firstName = firstName
  })

  setLastName = action(lastName => {
    this.lastName = lastName
  })

  setRole = action(role => {
    this.role = role.toLowerCase()
  })

  setLastSeenAt = action(time => {
    this.lastSeenAt = time
  })

  setLastSignOutAt = action(time => {
    this.lastSignOutAt = time
  })

  setProfileId = action(id => {
    this.profileId = id
  })

  setPhotoRatings = action(ratings => {
    this.photoRatings = ratings
  })

  setId = action(id => {
    this.id = id
  })

  setIsAuthenticated = action(isAuthenticated => {
    this.isAuthenticated = isAuthenticated
  })

  setEmail = action(address => {
    this.email = address
  })

  setPhoneNumber = action(phoneNumber => {
    this.phoneNumber = phoneNumber
  })

  setInitials = action(initials => {
    this.initials = initials
  })
}

export default UserStore
