import React, { Component } from 'react'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { observer, inject } from 'mobx-react'
import Cookies from 'universal-cookie'
import { withRouter } from '../utils/customHoc'
// import { withRouter } from 'react-router-dom'

const LOGOUT_MUTATION = gql`
  mutation SignOut {
    signOut {
      id
    }
  }
`
const cookies = new Cookies()

class Logout extends Component {
  render() {
    const { userStore, breadcrumbStore } = this.props
    return (
      <Mutation
        mutation={LOGOUT_MUTATION}
        update={(cache, { data: { doLogin } }) => {
          userStore.setFirstName('')
          userStore.setLastName('')
          userStore.setRole('')
          userStore.setProfileId('')
          userStore.setEmail('')
          userStore.setIsAuthenticated(false)
          userStore.setPhoneNumber('')
          breadcrumbStore.setCurrentUserRole('')
          cookies.remove('WA_ACCESS_TOKEN')
          cookies.remove('WA_CLIENT_TOKEN')
          cookies.remove('WA_EXPIRY')
          cookies.remove('WA_UID')
        }}
      >
        {(doLogout, { data }) => {
          doLogout()
          window.localStorage.removeItem('bc')
          window.location.pathname = '/'
          return ''
        }}
      </Mutation>
    )
  }
}

export default inject('userStore', 'breadcrumbStore')(observer(withRouter(Logout)))
