import React, { useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import UserDetailsModal from './UserDetailsModal'
import moment from 'moment'
import { SCHOOL_PROJECTS_DETAIL } from '../../queries/schoolDetailsQueries'
import { useQuery } from '@apollo/client'
import Loading from '../elements/Loading.js'

const SchoolDetailsFooter = props => {
  const { school } = props
  const { teachers } = school
  const facilitators = [
    ...new Set(school.facilitators)
  ]
  const [teacherModal, setTeacherModal] = useState(false)
  const [teacherDetails, setTeacherDetails] = useState({})
  const [taModal, setTaModal] = useState(false)
  const [taDetails, setTaDetails] = useState({})
  const [cohorts, setCohorts] = useState({})

  const { loading } = useQuery(SCHOOL_PROJECTS_DETAIL, {
    variables: { id: props.school.id },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setCohorts(data.school.cohorts)
    }
  })

  if(loading){ return (<><Box sx={{padding: 10, position: 'relative'}}><Loading/></Box></>)}
  else{
    return (
      <Box sx={{ py: { xs: 6 } }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant='h2'>Program Information</Typography>
        </Box>
        <Box>
          <Grid container spacing={4}>
            <Grid item md={4} sm={6} xs={12}>
              <Box>
                <Typography variant='dataLabel'>Teachers</Typography>
              </Box>
              <Box>
                {teachers.map(teacher => (
                  <Box key={`teacher-map-${teacher.id}`}>
                    <Typography
                      variant='link'
                      component='a'
                      className='large'
                      href={'#'}
                      onClick={e => {
                        e.preventDefault()
                        setTeacherModal(true)
                        setTeacherDetails(teacher)
                      }}
                    >
                      {teacher.name}
                    </Typography>
                  </Box>
                ))}
                <UserDetailsModal
                  show={teacherModal}
                  onHide={() => setTeacherModal(false)}
                  name={teacherDetails && teacherDetails.name}
                  userType='Teacher'
                  email={teacherDetails && teacherDetails.email}
                  mobileNumber={teacherDetails && teacherDetails.mobileNumber}
                  userId={teacherDetails && teacherDetails.userId}
                />
              </Box>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Box>
                <Typography variant='dataLabel'>Teaching Artists</Typography>
              </Box>
              <Box>
                {facilitators.map(facilitator => (
                  <Box key={`facilitator-map-${facilitator.id}`}>
                    <Typography
                      variant='link'
                      component='a'
                      className='large'
                      href={'#'}
                      onClick={e => {
                        e.preventDefault()
                        setTaModal(true)
                        setTaDetails(facilitator)
                      }}
                    >
                      {facilitator.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <UserDetailsModal
                show={taModal}
                onHide={() => setTaModal(false)}
                name={taDetails && taDetails.name}
                bio={taDetails && taDetails.bio}
                userType='Teaching Artist'
                website={taDetails && taDetails.website}
                email={taDetails && taDetails.email}
                mobileNumber={taDetails && taDetails.mobileNumber}
                instagramHandle={taDetails && taDetails.instagramHandle}
                userId={taDetails && taDetails.userId}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Box>
                <Typography variant='dataLabel'>Date Joined</Typography>
              </Box>
              <Box>
                <Typography variant='dataLabel' className='content'>
                  {moment(school.createdAt).format('LL')}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Box>
                <Typography variant='dataLabel'>Students in Program</Typography>
              </Box>
              <Box>
                <Typography variant='dataLabel' className='content'>
                  {new Set(school.cohorts.map(cohort => cohort.students).flat(2)).size} Students
                  Enrolled
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Box>
                <Typography variant='dataLabel'>Awards</Typography>
              </Box>
              <Box>
                <Typography variant='dataLabel' className='content'>
                  {cohorts.reduce((total, cohort) => {
                    return (
                      total +
                      cohort.projects.reduce((total, project) => {
                        return (
                          total +
                          project.photos.filter(
                            p =>
                              p.awards.filter(a => a.awardClass === 'Award of Excellence').length > 0
                          ).length
                        )
                      }, 0)
                    )
                  }, 0)}{' '}
                  Awards of Excellence
                </Typography>
              </Box>
              <Box>
                <Typography variant='dataLabel' className='content'>
                  {cohorts.reduce((total, cohort) => {
                    return (
                      total +
                      cohort.projects.reduce((total, project) => {
                        return (
                          total +
                          project.photos.filter(
                            p => p.awards.filter(a => a.awardClass === 'Honorable Mention').length > 0
                          ).length
                        )
                      }, 0)
                    )
                  }, 0)}{' '}
                  Honorable Mentions
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography variant='dataLabel'>Photographs</Typography>
              </Box>
              <Box>
                <Typography variant='dataLabel' className='content'>
                  {cohorts.reduce((total, cohort) => {
                    return (
                      total +
                      cohort.projects.reduce((total, project) => {
                        return total + project.photos.filter(p => p.isShortListed).length
                      }, 0)
                    )
                  }, 0)}{' '}
                  Submitted
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  }
}
export default SchoolDetailsFooter
