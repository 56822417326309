import { Fragment } from 'react'
import Layout from '../ui/template/Layout'
import { Box, Grid, Link, List, ListItem, Typography } from '@mui/material'

const TermsOfUseEnglish = () => {

  const getListItem = (content) => {
    return (
      <ListItem sx={{ display: 'list-item', listStyleType: 'disc', p: 0 }}>{content}</ListItem>
    )
  }

  const getParaHeading = (content) => {
    return (
      <Typography variant='h1'>{content}</Typography>
    )
  }

  return (
    <Layout>
      <Fragment>
        <Box className='container__public-body' sx={{ py: 10, px: { xs: 4, md: 6, lg: 20 } }}>
          <Box display='flex'>
            <Typography variant='h1' className='extra-size'>Terms and Conditions</Typography>
          </Box>

          <Grid container sx={{ mt: { xs: 10 }, rowGap: { xs: 5 } }}>
            <Grid item xs={12} md={10}>
              <Typography variant='h2'>
                Website Terms of Use
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography component='p' variant='blockQuote'>
                Agreement Between User and Working Assumptions Foundation
              </Typography>
              <Typography component='p' variant='blockQuote'>
                Last modified: August 18, 2019
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography component='p' variant='blockQuote'>
                Working Assumptions Foundation (“Working Assumptions,” “we,” “us,” or “our”)
                appreciates your interest in our organization and your visit to the various
                websites owned or operated by Working Assumptions, including but not limited to{' '}
                <Link href='http://www.workingassumptions.org' target='_blank' rel='noopener noreferrer'><Typography variant='link' className='large' componenet='span'>www.workingassumptions.org</Typography></Link>,{' '}
                <Link href='http://www.showingptw.org' target='_blank' rel='noopener noreferrer'><Typography variant='link' className='large' componenet='span'>www.showingptw.org</Typography></Link>,&nbsp; and{' '}
                <Link href='http://www.waed.org' target='_blank' rel='noopener noreferrer'><Typography variant='link' className='large' componenet='span'>www.waed.org</Typography></Link>, (the “Site”). These Terms of Use
                (“Terms”) set forth the basic terms and conditions that govern your visit to and
                use of the Site. Please read them carefully, as your use of the Site constitutes
                your consent to be bound by these Terms. If you do not agree to these Terms,
                please do not use the Site.
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: { xs: 8 }, rowGap: { xs: 8 } }}>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>1. No Unlawful Use.</>)}
              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                You are granted a non-exclusive, non-transferable, revocable license to access
                and use the Site strictly in accordance with these Terms. As a condition of
                your use of the Site, you warrant to Working Assumptions that you will not use
                the Site for any purpose that is unlawful or prohibited by these Terms. You
                may not use the Site in any manner which could damage, disable, overburden, or
                impair the Site or interfere with any other party’s use and enjoyment of the
                Site. You may not obtain or attempt to obtain any materials or information
                through any means not intentionally made available or provided for through the
                Site.
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>2. Site Contents.</>)}
              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                All materials on this Site are owned or licensed by Working Assumptions and/or
                its third party providers and are protected by United States and international
                intellectual property laws. The text, images, graphics, video files, and their
                arrangement on the Site are all subject to copyright and other intellectual
                property protection. The information, materials, and other contents of this
                Site may not be copied, displayed, distributed, licensed, modified, published,
                reposted, reproduced, reused, sold, transmitted, used to create a derivative
                work, or otherwise used for public or commercial purposes without the express
                written consent of Working Assumptions. Access to and use of the Site is
                solely for your personal use. You may download, copy, or print the contents of
                the Site for your personal reference only. No right, title, or interest in any
                of the contents of the Site is transferred to you as a result of any
                downloading, copying, printing, or use of the Site.
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>3. Privacy.</>)}
              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                For information on how Working Assumptions collects, uses, and discloses
                information from its users, please review our Privacy Policy. Your use of the
                Site indicates your consent to the data practices stated in our Privacy
                Policy.
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>4. Trademarks.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                All trademarks, service marks, trade dress, slogans, and logos displayed on
                the Site are the exclusive property of Working Assumptions, Working
                Assumptions’ suppliers and partners, or their respective owners, and may not
                be copied, imitated, or used, in whole or in part, without the prior written
                permission of Working Assumptions or the applicable trademark holder. Nothing
                in these Terms shall constitute a waiver of any trademark or other
                intellectual property rights concerning a name, logo, or trademark. Please be
                advised that Working Assumptions may enforce its intellectual property rights
                to the fullest extent of the law.
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>5. Links to Other Websites.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                The Site may include links to other websites or other sources of information.
                Working Assumptions provides such links only as a convenience to you. Working
                Assumptions has neither reviewed the contents of these third-party websites
                nor does Working Assumptions claim any responsibility for the content or
                suitability of these third-party websites and Working Assumptions makes no
                express or implied warranty about the accuracy, copyright compliance,
                legality, merchantability, or any other aspect of the content of such links.
                The inclusion of links does not imply endorsement of the websites by Working
                Assumptions or any association with their operation. The use of third party
                websites is entirely at your own risk. By accessing these links, you
                acknowledge that such other sites or locations are not under the control of
                Working Assumptions and you agree that Working Assumptions shall not be
                responsible for any information or additional links found at such site or
                location, or for your use of such information.
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>6. Age.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                Working Assumptions does not knowingly collect, either online or offline,
                personal information from persons under the age of 13. If you are under the
                age of 18, you may use the Site only with permission of a parent or guardian.
              </Typography>

            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>7. Copyright and Intellectual Property Infringement Complaints.</>)}

              <Typography component='div' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                <Grid container sx={{ rowGap: { xs: 5 } }}>
                  <Grid item>
                    You will not modify, publish, transmit, reverse engineer, participate in the
                    transfer or sale of, create derivative works, or in any way exploit any of the
                    content, in whole or in part, found on the Site. Working Assumptions content
                    is not for resale. Your use of the Site does not entitle you to make any
                    unauthorized use of any protected content, and in particular you will not
                    delete or alter any proprietary rights or attribution notices in any content.
                    You will use protected content solely for your personal use, and will make no
                    other use of the content without the express written permission of Working
                    Assumptions and the copyright owner. You agree that you do not acquire any
                    ownership rights in any protected content. Working Assumptions does not grant
                    you any licenses, express or implied, to the intellectual property of Working
                    Assumptions or its licensors except as expressly authorized by these Terms.
                  </Grid>
                  <Grid item>
                    Notification to Working Assumptions. If you believe in good faith that
                    material on the Site infringes on your copyright, you (or your agent) may send
                    Working Assumptions a notification in accordance with the requirements set
                    forth in the Digital Millennium Copyright Act of 1998 (“DMCA”), requesting
                    that Working Assumptions remove or block access to the claimed infringing
                    material. Such notification shall include the following information, in
                    accordance with the DMCA:
                  </Grid>
                  <Grid item><List sx={{ py: 0, pl: 4 }}>
                    {getListItem(<>a physical or electronic signature of a person authorized to act on
                      behalf of the owner of the exclusive right that is claimed to be
                      infringed;</>)}

                    {getListItem(<>identification of the copyrighted work(s) claimed to have been
                      infringed;</>)}

                    {getListItem(<>a description of the claimed infringing material and where the claimed
                      infringing material is located on the Site;</>)}

                    {getListItem(<>your address, telephone number, and email address;</>)}

                    {getListItem(<>a statement by you that you have a good faith belief that the disputed
                      use is not authorized by the copyright owner, its agent, or the law; and</>)}

                    {getListItem(<>a statement by you, under penalty of perjury, that the information in
                      your notification is accurate and that you are the copyright owner or
                      authorized to act on behalf of the owner of the exclusive right that is
                      claimed to be infringed.</>)}
                  </List>
                  </Grid>
                  <Grid item>
                    Working Assumptions may send information provided in a notification to the
                    person who provided the allegedly infringing work. If you believe in good
                    faith that someone has wrongfully filed a notification of copyright
                    infringement against you, the DMCA permits you to send Working Assumptions a
                    counter-notification.
                  </Grid>
                  <Grid item>
                    Notifications and counter-notifications must meet the then current
                    requirements imposed by the DMCA and/or the Copyright Office; see
                    www.copyright.gov. Working Assumptions will only respond to DMCA notifications
                    and counter-notifications that it receives by mail or e-mail at the addresses
                    below:
                  </Grid>
                  <Grid item>
                    <Typography component='p' variant='blockQuote'>Working Assumptions Foundation</Typography>
                    <Typography component='p' variant='blockQuote'>Attn: Managing Director</Typography>
                    <Typography component='p' variant='blockQuote'>1700 Shattuck Avenue, #25</Typography>
                    <Typography component='p' variant='blockQuote'>Berkeley, CA 94709</Typography>
                    <Link href='mailto:info@workingassumptions.org'><Typography variant='link' className='large'>info@workingassumptions.org</Typography></Link>
                  </Grid>
                </Grid>
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>8. International Use.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                The Site is controlled, operated, and administered by Working Assumptions from
                our offices within the United States. Because this Site can be accessed
                internationally, you agree to comply with all local laws, rules, and
                regulations including, without limitation, all laws, rules, and regulations in
                effect in the country in which you reside and the country from which you
                access the Site. The information on this Site is not intended for distribution
                to, or use by, any person or entity in any jurisdiction or country where such
                distribution or use would be contrary to law or regulation, or which would
                subject Working Assumptions or its affiliates to any registration requirement
                within such jurisdiction or country.
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>9. Indemnification.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                As a condition of your use of this Site, you agree to defend, indemnify and
                hold harmless Working Assumptions, its officers, directors, employees,
                independent contractors, service providers, consultants, members, agents,
                agencies, third party partners, and advisors (individually and together, the
                “Indemnified Parties”), from and against any claims, damages, costs,
                liabilities, and expenses (including, but not limited to, reasonable
                attorneys’ fees) arising out of your use of or inability to use the Site,
                including without limitation any actual or threatened suit, demand or claim
                made against the Indemnified Parties, arising out of or relating to your
                conduct, your violation of these Terms or any other Working Assumptions terms,
                policies, or agreements available on or through the Site, or your violation of
                the rights of any third party. Working Assumptions reserves the right, at its
                own cost, to assume the exclusive defense and control of any matter otherwise
                subject to indemnification by you, in which event you agree to fully cooperate
                with Working Assumptions in asserting any available defenses.
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>10. Liability Disclaimer.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                EXCEPT AS OTHERWISE EXPRESSLY PROVIDED HEREIN, THE SITE, ALL CONTENT OF THE
                SITE AND ALL SERVICES OFFERED IN CONNECTION WITH THE SITE ARE PROVIDED ON AN
                AS IS AND AS AVAILABLE WITH ALL FAULTS BASIS. TO THE FULLEST EXTENT PERMITTED
                BY APPLICABLE LAW, WORKING ASSUMPTIONS DISCLAIMS ALL REPRESENTATIONS AND
                WARRANTIES OF ANY KIND IN CONNECTION THEREWITH, EITHER EXPRESS OR IMPLIED,
                INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. IN ADDITION, WORKING
                ASSUMPTIONS DOES NOT WARRANT THAT YOUR USE OF THE SITE, OR THE OPERATION OR
                FUNCTION OF THE SITE, ANY COMPONENT THEREOF, OR ANY SERVICES OFFERED IN
                CONNECTION THEREWITH: WILL BE UNINTERRUPTED OR ERROR FREE; THAT DEFECTS
                THEREWITH WILL BE CORRECTED; OR THAT THE SITE OR ITS SERVER ARE FREE OF
                VIRUSES OR OTHER HARMFUL ELEMENTS. ALTHOUGH WORKING ASSUMPTIONS ENDEAVORS TO
                PROVIDE CURRENT, ACCURATE, AND RELIABLE INFORMATION ON THE SITE, IT DOES NOT
                WARRANT, OR MAKE ANY REPRESENTATIONS REGARDING THE ACCURACY, RELIABILITY OR
                ANY USE OF INFORMATION ON THE SITE.

              </Typography>

            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>11. Limitation on Liability.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                BY USING THE SITE, YOU ACKNOWLEDGE: (1) THAT YOUR USE OF THE SITE IS AT YOUR
                SOLE RISK; (2) THAT YOU ASSUME FULL RESPONSIBILITY FOR ALL COSTS ASSOCIATED
                WITH YOUR USE OF THE SITE; AND (3) THAT WORKING ASSUMPTIONS SHALL NOT BE
                LIABLE FOR ANY DAMAGES, LOSSES, OR LIABILITIES OF ANY KIND RELATED TO YOUR USE
                OF OR INABILITY TO USE THE SITE, INCLUDING WITHOUT LIMITATION, DIRECT,
                INDIRECT, INCIDENTAL, SPECIAL, COMPENSATORY, OR CONSEQUENTIAL DAMAGES, LOST
                PROFITS, AND/OR LOSS OF OR DAMAGE TO PROPERTY WHETHER THE ALLEGED LIABILITY IS
                BASED ON CONTRACT, TORT, NEGLIGENCE STRICT LIABILITY, OR ANY OTHER BASIS, EVEN
                IF WORKING ASSUMPTIONS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN
                NO EVENT SHALL WORKING ASSUMPTIONS BE LIABLE FOR ANY DAMAGES, LOSSES, OR
                LIABILITIES, IN CONNECTION WITH YOUR RELIANCE ON OR USE OR INABILITY TO USE
                THE INFORMATION, MATERIALS, PRODUCTS, OR SERVICES ON THE SITE, OR IN
                CONNECTION WITH ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION,
                DEFECT, OR DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, OR LINE OR
                SYSTEM FAILURE, EVEN IF WORKING ASSUMPTIONS IS ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES, LOSSES, OR EXPENSES. NEITHER WORKING ASSUMPTIONS NOR ANY PARTY
                INVOLVED IN CREATING, PRODUCING, OR DELIVERING THIS SITE SHALL BE LIABLE FOR
                ANY DAMAGES, INCLUDING WITHOUT LIMITATION, DIRECT, INCIDENTAL, CONSEQUENTIAL,
                INDIRECT, OR PUNITIVE DAMAGES ARISING OUT OF ACCESS TO, USE OF OR INABILITY TO
                USE THIS SITE, OR ANY ERRORS OR OMISSION IN THE CONTENT THEREOF. THIS
                LIMITATION INCLUDES DAMAGES TO, OR FOR ANY VIRUSES THAT INFECT YOUR COMPUTER
                EQUIPMENT. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
                LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF
                THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS
                TO DISCONTINUE USING THE SITE.
              </Typography>
              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 5 } }}>
                WORKING ASSUMPTIONS RESERVES THE RIGHT TO ALTER OR REMOVE THE CONTENT OF THE
                SITE OR SUSPEND OR TERMINATE YOUR USE IN ANY WAY, AT ANY TIME, FOR ANY REASON,
                WITHOUT PRIOR NOTIFICATION, AND WILL NOT BE LIABLE IN ANY WAY FOR POSSIBLE
                CONSEQUENCES OF SUCH CHANGES.
              </Typography>

            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>12. Electronic Communications.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                Visiting the Site or sending emails to Working Assumptions constitutes
                electronic communications. You consent to receive electronic communications
                and you agree that all agreements, notices, disclosures and other
                communications that Working Assumptions provides to you electronically, via
                email and on the Site, satisfy any legal requirement that such communications
                be in writing.
              </Typography>

            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>13. Choice of Law and Forum.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                The Site is controlled and operated by Working Assumptions from its offices in
                Berkeley, California, and these Terms shall be governed by and interpreted in
                accordance with the laws of the State of California, without giving effect to
                its conflict of law principles.
              </Typography>

            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>14. Severability.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                If any provision of these Terms is found to be invalid, void, or for any
                reason unenforceable, it will be construed in such a manner that would make
                the provision valid or enforceable. If it is not possible to construe the
                provision in such a manner that would make it valid or enforceable, then only
                the term or portion of the provision that renders it unenforceable will be
                stricken without affecting the enforceability of the remaining provisions.
              </Typography>

            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>15. Complete Agreement.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                These Terms and the Privacy Policy constitute the entire agreement between you
                and Working Assumptions relating to the Site and your use of the Site, and
                supersede any prior agreements or understandings not incorporated herein.
              </Typography>

            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>16. Termination.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                Notwithstanding anything in these Terms or on the Site, Working Assumptions
                reserves the right, without notice and in its sole discretion, to terminate
                your license to use the Site, and to block or prevent your future access to
                and use of the Site or any other site operated by Working Assumptions.

              </Typography>

            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>17. Modification.</>)}

              <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                We reserve the right to change, edit, delete, or revise these Terms at any
                time, and such changes will be effectively immediately. If you object to such
                changes, your sole recourse is to cease using the Site. We will notify you of
                any changes or modifications by posting the modified Terms of Use on the Site
                and indicating at the top of the Terms of Use the date on which it was last
                updated. Your use of the Site after a change has been posted constitutes your
                acceptance of and consent to the amended Terms of Use.
              </Typography>

            </Grid>
            <Grid item xs={12} md={10}>
              {getParaHeading(<>18. Contact Us.</>)}

              <Typography component='div' variant='blockQuote' sx={{ mt: { xs: 2 } }}>
                Questions or comments about the Site may be directed to us by: (i) emailing{' '}
                <Link href='mailto:info@workingassumptions.org'><Typography variant='link' className='large'>info@workingassumptions.org</Typography></Link>
                ; (ii) calling us at (510) 704-9734; or (iii) writing us at the following
                address with your message:

                <Typography component='p' variant='blockQuote' sx={{ mt: { xs: 5 } }}>Working Assumptions Foundation</Typography>
                <Typography component='p' variant='blockQuote'>Attn: Managing Director</Typography>
                <Typography component='p' variant='blockQuote'>1700 Shattuck Avenue, #25</Typography>
                <Typography component='p' variant='blockQuote'>Berkeley, CA 94709</Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Fragment>
    </Layout>
  )
}

export default TermsOfUseEnglish
