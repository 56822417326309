import { action, makeAutoObservable } from 'mobx'

class PhotosStore {
  photoStore = {}

  constructor() {
    // Call it here
    makeAutoObservable(this)
  }

  setPhotoStore = action((componentName, photos) => {
    this.photoStore[componentName] = [ ...photos ]
    // const currentstore = { ...this.photoStore }
    // if (currentstore.length > 0 && currentstore[componentName]) {
    //   const currentPhotos = {...currentstore[componentName]}
    //   if (currentPhotos.length > 0) {
    //     const photo = currentPhotos.find(pho => pho.id === )
    //   }
    // } else {

    // }
  })

  setCurrentPhotoId = action(photoId => {
    this.currentPhotoId = photoId
  })

  setPrevPhotoId = action(photoId => {
    this.prevPhotoId = photoId
  })

  setActivePageName = action(activePageName => {
    this.activePageName = activePageName
  })
}

export default PhotosStore
