import React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import { inject, observer } from 'mobx-react'

import DesktopMenuPublic from './DesktopMenuPublic'
import MobileMenuPublic from './MobileMenuPublic'
import MainLogo from '../../assets/svgs/mainLogo.jsx'
import { withRouter } from '../../utils/customHoc'

const PublicMenu = props => {
  const logoLink = 'https://www.workingassumptions.org'
  return (
    <AppBar position='fixed' sx={{ backgroundColor: '#fff' }}>
      <Container maxWidth='xl' spacing={2}>
        <Toolbar disableGutters spacing={2} sx={{ px: { md: 4 } }}>
          <DesktopMenuPublic />
          <MobileMenuPublic />
          <Box
            component='a'
            href={logoLink}
            target='_blank'
            sx={{
              pr: { xs: 2, md: 0 },
              right: { xs: 0 },
              position: { xs: 'absolute', md: 'inherit' }
            }}
          >
            <MainLogo fill='#33333c' />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default inject('userStore')(observer(withRouter(PublicMenu)))
