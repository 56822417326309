import { Component, Fragment } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { Box, Typography } from '@mui/material'
import Loading from '../ui/elements/Loading.js'
import ToastNotification from '../ui/elements/ToastNotification'
import ClassList from '../ui/organisms/ClassList.js'
import Hero from '../ui/organisms/Hero.js'
import SchoolInformation from '../ui/organisms/SchoolInformation.js'
import Layout from '../ui/template/Layout'
import { inject, observer } from 'mobx-react'
import { withRouter } from '../utils/customHoc'
import { SCHOOL_DETAIL } from '../queries/schoolDetailsQueries'

class SchoolDetails extends Component {
  constructor(props) {
    super(props)
    const { params } = props
    this.state = {
      currentTab: params.currentTab ? params.currentTab : 'school-details',
      modalShow: false,
      toastShow: false,
      schoolName: '',
      school: {},
      loading: true,
      error: {}
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true
    })
    this.handleTabChange(this.props?.params?.currentTab || 'school-details' )
    this.refetchSchoolDetail();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props?.params?.currentTab !== nextProps?.params?.currentTab) {
      this.handleTabChange(nextProps?.params?.currentTab || 'school-details' )
    }
  }

  handleTabChange(newTab) {
    this.setState({ currentTab: newTab })
  }

  handleClose = (event, reason) => {

    if (reason === "clickaway") {
      return;
    }
    this.setState({ showToast: false })
  }

  showToast = (toastType, message = '') => {
    this.setState({
      showToast: true,
      toastMessage: message,
      toastType: toastType
    })
  }
  refetchSchoolDetail = async() => {
    const { params, location, breadcrumbStore, basePath } = this.props
    const data = await this.props.client.query({
      query: SCHOOL_DETAIL,
      variables: {
        id: params.schoolId
      }
    })
    breadcrumbStore.addBreadcrumb({
      pageName: data.data.school.name,
      pageLink: location.pathname,
      basePath: basePath,
      isParent: false,
      isActive: true
    })
    this.setState({
      school: data.data.school,
      loading: false,
      schoolName: data.data.school.name
    })
  }

  render() {
    const tabs = [
      { eventKey: 'school-details', title: 'School Details', path: `/school-details/${this.props.params.schoolId}` },
      { eventKey: 'classes', title: 'Classes', path: `/school-details/${this.props.params.schoolId}/classes` }
    ]
    const { school, loading } = this.state
    return (
      <Layout>
        {loading ? (
          <Loading />
        ) : (
          <Fragment>
            {this.state.showToast === true ? (
              <ToastNotification
                notificationType={this.state.toastType}
                showToast={this.state.showToast}
                onClose={this.handleClose}
                toastMessage={this.state.toastMessage}
              />
            ) : null}
            <Hero
              heroTitle={school.name}
              currentTab={this.state.currentTab}
              tabChangeHandler={this.handleTabChange.bind(this)}
              tabs={tabs}
            />

            {this.state.currentTab === 'school-details' ? (
              <SchoolInformation school={school} showToast={this.showToast} refetchSchoolDetail={this.refetchSchoolDetail}/>
            ) : this.state.currentTab === 'classes' ? (
              <Box sx={{ mt: { xs: 4, md: 6, lg: 10 } }}>
                <Typography variant='h1' className='bold' sx={{ px: { xs: 4, md: 6 } }}>
                  School Classes ({school.cohorts.length})
                </Typography>

                <Box sx={{ mt: 5 }}>
                  <ClassList id={school.id} entity='school' showPagination={false}/>
                </Box>
              </Box>
            ) : null}
          </Fragment>
        )}
      </Layout>
    )
  }
}

export default inject('breadcrumbStore')(observer(withRouter(withApollo(SchoolDetails))))
