import { action, computed, makeAutoObservable } from 'mobx'
import _ from 'lodash'
import moment from 'moment'

class FilterStore {
  items = []
  filters = []
  filtersByKey = {}
  functions = {}
  valuesMap = {}
  searchQuery = ''

  constructor() {
    // Call it here
    makeAutoObservable(this)
  }

  setItems = action(items => {
    this.items = items
  })

  setSearchQuery = action(searchQuery => {
    this.searchQuery = searchQuery
  })

  setFilters = action(filters => {
    let functions = {}
    let filtersByKey = {}
    let dropDownFunctions = {}

    filters.forEach(filter => {
      filtersByKey[filter.key] = filter

      switch (filter.type) {
        case 'multiple-select':
          filter.choices.forEach(choice => {
            functions[filter.key] = functions[filter.key] || {}
            functions[filter.key][choice.title] = choice.func
          })
          break
        case 'drop-down':
          functions[filter.key] = filter.func
          break
        case 'date':
          functions[filter.key] = filter.getter
          break
        default:
          alert('Invalid option setFilters()')
      }
    })

    this.filters = filters
    this.filtersByKey = filtersByKey
    this.functions = functions
    this.dropDownFunctions = dropDownFunctions
  })

  toggleFilter = action((filterKey, value) => {
    let valuesMapCopy = { ...this.valuesMap }
    let oldValues = valuesMapCopy[filterKey] || []
    let valuesDude = [...oldValues]
    if (oldValues.includes(value)) {
      _.remove(oldValues, x => {
        return x === value
      })
    } else {
      oldValues.push(value)
    }
    valuesMapCopy[filterKey] = oldValues
    this.valuesMap = valuesMapCopy
    // this.valuesMap = { ...this.valuesMap }[filterKey] = values
  })

  updateDropdownValue = action((filterKey, value) => {
    let valuesMap = { ...this.valuesMap }
    if (value === undefined) {
      delete valuesMap[filterKey]
    } else {
      valuesMap[filterKey] = value
    }
    this.valuesMap = valuesMap
  })

  updateDateRangeValue = action((filterKey, toOrFrom, value) => {
    let valuesMap = { ...this.valuesMap }

    if (!['toDate', 'fromDate'].includes(toOrFrom)) {
      alert('Invalid toOrFrom')
    }

    if (!value) {
      try {
        delete valuesMap[filterKey][toOrFrom]
      } catch {}
      if (_.isEmpty(valuesMap[filterKey])) {
        delete valuesMap[filterKey]
      }
    } else {
      valuesMap[filterKey] = valuesMap[filterKey] || {}
      valuesMap[filterKey][toOrFrom] = value
    }
    this.valuesMap = valuesMap
  })

  get filteredItems() {
    let items = [...this.items]
    const functions = this.functions

    for (var filterKey in this.valuesMap) {
      const filter = this.filtersByKey[filterKey]
      let value
      let values
      switch (filter.type) {
        case 'multiple-select':
          values = this.valuesMap[filterKey]
          values.forEach(value => {
            const func = functions[filterKey][value]
            items = items.filter(func)
          })
          break
        case 'drop-down':
          value = this.valuesMap[filterKey]
          const funcBar = functions[filterKey]
          items = items.filter(item => {
            return funcBar(item, value)
          })
          break
        case 'date':
          const fromDate = this.valuesMap[filterKey]['fromDate']
          const toDate = this.valuesMap[filterKey]['toDate']
          const getter = functions[filterKey]
          const func = item => {
            const date = getter(item)

            if (Array.isArray(date)) {
              const dateRanges = date
              if (fromDate && toDate) {
                // Comparing a date range
                return _.some(dateRanges, range => {
                  return (
                    moment(fromDate).isAfter(range.startDate) &&
                    moment(toDate).isBefore(range.endDate)
                  )
                })
              } else if (fromDate) {
                return _.some(dateRanges, range => {
                  return moment(range.endDate).isAfter(fromDate)
                })
              } else if (toDate) {
                return _.some(dateRanges, range => {
                  return moment(range.startDate).isBefore(toDate)
                })
              }
            } else {
              // Comparing a single date
              if (fromDate && toDate) {
                return moment(date).isBetween(fromDate, toDate)
              } else if (fromDate) {
                return moment(date).isAfter(fromDate)
              } else if (toDate) {
                return moment(date).isBefore(toDate)
              }
            }
            return true
          }
          if (filter.reject) {
            items = _.reject(items, func)
          } else {
            items = _.filter(items, func)
          }
          break
        default:
          alert('Invalid option filteredItems()')
      }
    }

    return items
  }

  get activePills() {
    let activePills = []
    for (var filterKey in this.valuesMap) {
      const filter = this.filtersByKey[filterKey]
      switch (filter.type) {
        case 'multiple-select':
          const valuesDude = this.valuesMap[filterKey]
          valuesDude.forEach(value => {
            activePills.push({
              key: value,
              label: value,
              func: () => {
                this.toggleFilter(filterKey, value)
              }
            })
          })
          break
        case 'drop-down':
          const value = this.valuesMap[filterKey]
          activePills.push({
            key: filter.key,
            label: value,
            func: () => {
              this.updateDropdownValue(filterKey, undefined)
            }
          })
          break
        case 'date':
          const values = this.valuesMap[filterKey]
          const fromDate = values['fromDate']
          const toDate = values['toDate']
          if (fromDate && toDate) {
            activePills.push({
              key: filter.key,
              label: `${filter.title} ${moment(fromDate).format('MM-DD-YY')} to ${moment(
                toDate
              ).format('MM-DD-YY')}`,
              func: () => {
                this.updateDateRangeValue(filterKey, 'toDate', undefined)
                this.updateDateRangeValue(filterKey, 'fromDate', undefined)
              }
            })
          } else if (fromDate) {
            activePills.push({
              key: filter.key,
              label: `${filter.title} from ${moment(fromDate).format('MM-DD-YY')}`,
              func: () => {
                this.updateDateRangeValue(filterKey, 'fromDate', undefined)
              }
            })
          } else if (toDate) {
            activePills.push({
              key: filter.key,
              label: `${filter.title} to ${moment(toDate).format('MM-DD-YY')}`,
              func: () => {
                this.updateDateRangeValue(filterKey, 'toDate', undefined)
              }
            })
          }
          break
        default:
          alert('Invalid option activePills')
      }
    }
    return activePills
  }
}

export default FilterStore