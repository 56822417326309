import React, { Component, Fragment } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import Modal from 'react-bootstrap/Modal'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Spinner } from 'react-bootstrap'
import { Typography, Box, Grid } from '@mui/material'
import BaseModal from '../template/BaseModal';

const SUBMIT_REVIEW_MUTATION = gql`
  mutation SubmitReview($cohortId: ID!) {
    submitReview(cohortId: $cohortId)
  }
`

class SubmitCuratorialReviewModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleSendReview(e, onMutate) {
    this.setState({
      ...this.state,
      reviewSent:true
    })

  }
  handleClick(e, onMutate) {
    e.preventDefault()
    this.setState({
      isSubmitting: true
    })
    onMutate({
      variables: {
        cohortId: this.props.cohortId
      },
      refetchQueries: [`ClassReview`]
    })
  }
  render() {
    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);
  
    };
    return (
      <BaseModal
        {...this.props}
        show={this.props.show}
        onClick={this.props.onClick}
        value={this.props.value}
        open={this.props.show}
        onClose={handleClose}
        onHide={this.props.onHide}
      >
        <BaseModal.BaseModalHeader>
          Submit Your Curatorial Review
        </BaseModal.BaseModalHeader>
        <BaseModal.BaseModalBody>
          <Box className='submit-your-review-modal__section'>
            <Typography
            variant="paragraph"
            sx={{
              mb:"1rem",
            }}
            component="p"
            >
            Be sure that you are ready to submit your curatorial review.
            </Typography>
            <Typography
              variant="paragraph"
              sx={{
                mb:"1rem",
              }}
              component="p"
            >
              Reminder: after you submit, you will not be able to edit your awards. The other curators for this class and the <i>wrkxfmly</i> administrator will receive emails letting them know that you have submitted your review.
            </Typography>
          </Box>
          <BaseModal.BaseModalFooter>
            <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
              <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
                <Grid item xs={12} sm={6} md={4}>
                  <Mutation
                    mutation={SUBMIT_REVIEW_MUTATION}
                    onCompleted={() => {
                      this.setState({
                        isSubmitting: false
                      })
                      this.props.onHide()
                      this.props.showToast('success', 'This class review has been sent to the administrator.')
                    }}
                  >
                    {(onMutate, { data }) => (
                      <Button
                        variant='contained'
                        type='Submit'
                        title={this.state.isSubmitting ? <span className='icon-text-span'>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          /> Submitting... </span>
                        : 'Submit Your Curatorial Review' }
                        disabled={this.state.isSubmitting }
                        onClick={e => this.handleClick(e, onMutate)}
                        sx={{width:{xs:'100%', sm:'100%'}}}
                      />
                      )}
                  </Mutation>
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                  <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={() => this.props.onHide()}>
                    Cancel
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </BaseModal.BaseModalFooter>
        </BaseModal.BaseModalBody>
      </BaseModal>
    )
  }
}

export default SubmitCuratorialReviewModal
