import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

const SubHeaderLinks = (props) => {
    const CLASS_REVIEW = '/class-review/'+props.projectDetails.cohort.id
    const CLASS_REVIEW_STUDENT_LIST = CLASS_REVIEW+'/students'
    const CLASS_DETAILS = '/class-detail/'+props.projectDetails.cohort.id
    const CLASS_DETAILS_STUDENT_LIST = CLASS_DETAILS+'/student-list'
    const today = moment().format('YYYY-MM-DD')
    const completedCohorts = moment(props.projectDetails.cohort.endDate, 'YYYY-MM-DD').isBefore(today)
    const subtitleLinks = {
        'curator': {
          'All Review Photographs' : CLASS_REVIEW,
          'Student List' : CLASS_REVIEW_STUDENT_LIST
        },
        'appadmin': {
          'Class Details': CLASS_DETAILS
        },
        'teacher': {
          'Class Details': CLASS_DETAILS,
          'Student List' : CLASS_DETAILS_STUDENT_LIST
        },
        'facilitator': {
          'Class Details': CLASS_DETAILS,
          'Student List' : CLASS_DETAILS_STUDENT_LIST
        },
        'student': {
        }
    }
    if(completedCohorts){
      if (props.role === 'appadmin') {
        subtitleLinks[props.role]['Class Review'] = CLASS_REVIEW
      }
    }
    let activeLinks = null
    if (subtitleLinks[props.role]) {
        activeLinks = Object.entries(subtitleLinks[props.role]).map(([k, v]) => (
            <React.Fragment key={k}>
                {' • '}
                <u style={{color: 'rgb(0, 118, 187)'}}><Link to={v}>{k}</Link></u>
            </React.Fragment>
        ));
    }

    return activeLinks
}

export default SubHeaderLinks
