
import { gql } from '@apollo/client'
import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { Typography, Grid, Box } from '@mui/material'
import Button from '../elements/Button.js'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import libphonenumber from 'google-libphonenumber'
import { Spinner } from 'react-bootstrap'
import BaseModal from '../template/BaseModal';

const ManageModelReleaseMutation = gql`
  mutation ManageModelRelease(
    $photoId: ID!
    $modelUuid: String!
    $manageType: String!
  ) {
    manageModelRelease(
      photoId: $photoId
      modelUuid: $modelUuid
      manageType:$manageType
    ) {
      id
    }
  }`

const styles = theme => ({
  root: {
    display: 'flex',
    marginBottom: '16px'
  },
  formControl: {
    margin: 0
  },
  group: {
    margin: 0
  }
})

class ResendModelRequestModal extends Component {
  constructor(props) {
    super(props)
    let resendType = props.modelRelease && props.modelRelease.sentViaSms ? 'text' : 'email'
    let resendDetails = props.modelRelease && props.modelRelease.sentViaSms ? props.modelRelease.phoneNumber : props.modelRelease && props.modelRelease.sentViaEmail ? props.modelRelease.email : ''
    this.state = {
      resendMessage: false,
      modelUuid:props.modelUuid,
      isSubmitting: false
    }

    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this)
  }
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.modelRelease ) {
      let resendType = nextProps.modelRelease.pdfUploaded ? 'pdf' : nextProps.modelRelease.sentViaSms ? 'text' : 'email'
      let resendDetails = nextProps.modelRelease.sentViaSms ? nextProps.modelRelease.phoneNumber : nextProps.modelRelease.sentViaEmail ? nextProps.modelRelease.email : ''
      this.setState({
        ...this.state,
        resendType: resendType,
        sendTo:resendDetails,
        modelUuid:nextProps.modelUuid,
        isSubmitting: false
      })
    }
  }

  phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
  handleFormSubmit = (onMutate) => {
    this.setState({isSubmitting: true})
    onMutate({
      variables: {
        photoId: this.props.photoId,
        modelUuid: this.state.modelUuid,
        manageType:'resend',
      },
      refetchQueries: [`PhotoDetails`]
    })
  }

  handleSubmitSuccess = () => {
    this.setState({isSubmitting: false})
    this.props.refetchPhoto()
    this.props.refetchMrData && this.props.refetchMrData()
    this.props.onHide()
  }

  render() {
    const {
      handleSubmitSuccess
    } = this
    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);

    };
    return <Mutation
      mutation={ManageModelReleaseMutation}
      onCompleted={()=>{
        this.setState({
          isSubmitting: false
        })
        handleSubmitSuccess()
      }}
    >
      {(manageOnMutate, { data }) => (
        <BaseModal
        {...this.props}
        show={this.props.show}
        onClick={this.props.onClick}
        value={this.props.value}
        open={this.props.show}
        onClose={handleClose}
        onHide={this.props.onHide}
      >
        <BaseModal.BaseModalHeader>
          Resend Model Release Request
        </BaseModal.BaseModalHeader>
        <BaseModal.BaseModalBody>
          <Box className='invite-student__description'>
            <Typography variant="paragraph" component="p">
              Be sure that you want to resend the Model Release request.
            </Typography>
          </Box>
          <Box>
            <Typography variant="paragraph" component="p">
              Reminder: the message will be sent to the email address or mobile number that was originally provided. If you need to send to a different email address or mobile number, do <i>not</i> resend request. Instead, add a new Model Release with the updated contact information.
            </Typography>
          </Box>
          <form noValidate autoComplete='on'>
          <BaseModal.BaseModalFooter>
            <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
              <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={this.state.isSubmitting}
                    title={
                      this.state.isSubmitting ? <span className='icon-text-span'>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> Resending... </span> : 'Resend Request'
                    }
                    onClick={() => this.handleFormSubmit(manageOnMutate)}
                    sx={{width:{ xs: '100%', sm: '100%' }}}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                  <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={() => this.props.onHide()}>
                    Cancel
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </BaseModal.BaseModalFooter>
          </form>
        </BaseModal.BaseModalBody>
      </BaseModal>
      ) }
    </Mutation>
  }
}

ResendModelRequestModal.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ResendModelRequestModal)