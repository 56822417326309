import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import { inject, observer } from 'mobx-react'

import { getTextContentByPage } from '../content/textContent'
import { PROJECTS_LIST_QUERY } from '../queries/projectDetailsQueries'
import GlobalLoader from '../ui/elements/GlobalLoader'
import Hero from '../ui/organisms/Hero.js'
import ProjectsList from '../ui/organisms/ProjectsList.js'
import Layout from '../ui/template/Layout'
import { withRouter } from '../utils/customHoc'
import './projects.scss'

const NewProjects = props => {
  const { location, breadcrumbStore, basePath, navigate } = props

  const [userProjects, setUserProjects] = useState(null)
  const [currentUserRole, setCurrentUserRole] = useState(null)

  const { loading, data, error, refetch } = useQuery(PROJECTS_LIST_QUERY, {
    cacheTime: 0,
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setCurrentUserRole(data.currentUser.profile.__typename)
      setUserProjects(data.currentUser.profile.projects)
      if (currentUserRole === 'Student' && userProjects.length === 1) {
        navigate(`/project-detail/${userProjects[0].id}`)
      }
    }
  })

  useEffect(() => {
    breadcrumbStore.resetBreadcrumb()
    breadcrumbStore.addBreadcrumb({
      pageName: 'Projects',
      link: location.pathname,
      basePath: basePath,
      isParent: true,
      isActive: true
    })
  }, [])

  if (loading) {
    return <GlobalLoader />
  } else if (error) {
    return (
      <Typography variant='paragraph' component='p'>
        Error :(
      </Typography>
    )
  } else {
    return (
      <Layout>
        <Box>
          <Hero heroTitle='Your Projects' />
          <Box sx={{ mt: { xs: 4, md: 6, lg: 10 } }}>
            <Typography variant='h1' className='bold' sx={{ px: { xs: 4, md: 6 } }}>
              Projects ({userProjects.length})
            </Typography>
            {userProjects.length < 1 ? (
              <Typography variant='paragraph' component='p' sx={{ mt: 2 }}>
                {getTextContentByPage('PROJECTLIST', currentUserRole.toUpperCase(), 'INTRO')}
              </Typography>
            ) : null}
            {userProjects.length ? <ProjectsList projects={userProjects} /> : null}
          </Box>
        </Box>
      </Layout>
    )
  }
}

export default inject('breadcrumbStore')(observer(withRouter(NewProjects)))
