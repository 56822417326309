import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FormCheckbox from '../elements/FormCheckbox.js'
import DeleteUserModal from './DeleteUserModal.js'
import { inject, observer } from 'mobx-react'
import Switches from '../elements/Switches.js'
import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'

class UserListRowAdmins extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPhotos: false,
      accountDisabledAt: props.admin.accountDisabledAt
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.admin.accountDisabledAt !== this.state.accountDisabledAt) {
      this.setState({
        accountDisabledAt: nextProps.admin.accountDisabledAt
      })
    }
  }

  render() {
    const { admin, checked, userId, userStore } = this.props
    let modalClose = () => this.setState({ deleteUser: false })
    const { id } = userStore
    return (
      <>
        <Box
          className='body__section'
          sx={{
            p: { xs: 3, md: 4 },
            borderBottom: { xs: '0.0625rem solid rgb(213, 213, 215)' },
            margin: { xs: '0px !important', md: '8px 0 !important' }
          }}
        >
          <Grid container alignItems='center' spacing={2}>
            <Grid item md={1} sm={1} xs={1}>
              <Box sx={{ textAlign: 'center' }}>
                <FormCheckbox
                  checked={checked}
                  onChange={() => this.props.onChecked(userId)}
                  value='checkedReminder'
                  color='primary'
                />
              </Box>
            </Grid>
            <Grid item md={5} sm={6} xs={11}>
              <Link to={'/user-details/'.concat(userId)}>
                <Typography
                  variant='h4'
                  className='semi-strong link link--large'
                  sx={{ textDecoration: 'underline' }}
                >
                  {admin.name}
                </Typography>
              </Link>
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'none',
                    md: 'none'
                  }
                }}
              >
                {id !== userId ? (
                  <Box>
                    <Switches
                      checked={!admin.accountDisabledAt}
                      onChange={e => {
                        this.setState({ deleteUser: true })
                      }}
                      name='userAccess'
                      label={
                        <Typography variant='h4'>
                          {admin.accountDisabledAt ? 'Activate User' : 'Deactivate User'}
                        </Typography>
                      }
                    />
                    <DeleteUserModal
                      show={this.state.deleteUser}
                      onHide={modalClose}
                      userId={userId}
                      showToast={this.props.showToast}
                      refetchData={this.props.refetchData}
                      accountDisabledAt={this.state.accountDisabledAt}
                    />
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid
              item
              md={3}
              sm={5}
              xs={12}
              sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}
            >
              {id !== userId ? (
                <Box>
                  <Switches
                    checked={!admin.accountDisabledAt}
                    onChange={e => {
                      this.setState({ deleteUser: true })
                    }}
                    name='userAccess'
                    label={
                      <Typography variant='h4'>
                        {admin.accountDisabledAt ? 'Activate User' : 'Deactivate User'}
                      </Typography>
                    }
                  />
                  <DeleteUserModal
                    show={this.state.deleteUser}
                    onHide={modalClose}
                    userId={userId}
                    showToast={this.props.showToast}
                    refetchData={this.props.refetchData}
                    accountDisabledAt={this.state.accountDisabledAt}
                  />
                </Box>
              ) : null}
            </Grid>
            <Grid
              item
              md={3}
              sm={4}
              xs={12}
              sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
            >
              {this.props.lastSeenAt ? (
                <Typography variant='h4' className='table_row'>
                  Last signed in {moment(this.props.lastSeenAt).format('LL')}
                </Typography>
              ) : (
                <Typography variant='h4' className='table_row'>
                  User has not signed up
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }
}
export default inject('userStore')(observer(UserListRowAdmins))
