import React, { Component, Fragment } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Typography, Box, Grid } from '@mui/material'
import { Mutation, Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { observer, inject } from 'mobx-react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import Hero from '../ui/organisms/Hero.js'
import FormField from '../ui/elements/FormField.js'
import Button from '../ui/elements/Button.js'
import DatePicker from '../ui/elements/DatePicker'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'
import FormSelect from '../ui/elements/FormSelect.js'
import ToastNotification from '../ui/elements/ToastNotification.js'
import { withRouter } from '../utils/customHoc.js'
import { gradeLevels, selectStyles } from '../utils/commonFunctions'
import { CREATE_CLASS } from '../queries/createClassQueries.js'
import { SCHOOL_LIST } from '../queries/schoolDetailsQueries.js'
import Progression from '../ui/elements/Progression.js'
import { validationText } from '../content/validationText.js'
import FormCheckbox from '../ui/elements/FormCheckbox.js'
import DateUtils from '../utils/DateUtils.js'

function TabContainer(props) {
  return (
    <Typography component='div' sx={{ p: { xs: 3, sm: '32px 120px 24px', md: '32px 120px 24px' } }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

class CreateClass extends Component {
  static COMMON_TABS = ['Name and Grade', 'Dates', 'Class Size']
  static TEACHER_TABS = [...CreateClass.COMMON_TABS, 'Terms']
  static FACILITATOR_TABS = ['School and Teacher', ...CreateClass.COMMON_TABS]
  static APP_ADMIN_TABS = [...CreateClass.FACILITATOR_TABS, 'Support']

  constructor(props) {
    super(props)
    const { breadcrumbStore, location, basePath } = this.props
    this.state = {
      value: 0,
      modalShow: false,
      toastShow: false,
      isCreatingClass: false,
      errors: {},
      enableNext: false,
      enablePrev: false,
      currentTabs: this.props.userStore.role === 'teacher' ? CreateClass.TEACHER_TABS : (this.props.userStore.role === 'facilitator' ? CreateClass.FACILITATOR_TABS : (this.props.userStore.role === 'appadmin' ? CreateClass.APP_ADMIN_TABS : null)),
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleTeacherChange = this.handleTeacherChange.bind(this)
    this.handleFacilitatorChange = this.handleFacilitatorChange.bind(this)
    this.handleCuratorChange = this.handleCuratorChange.bind(this)
    this.handleSchoolChange = this.handleSchoolChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleGradeChange = this.handleGradeChange.bind(this)
    breadcrumbStore.addBreadcrumb({
      pageName: 'Create a New Class',
      link: location.pathname,
      basePath: basePath,
      isParent: false,
      isActive: true
    })
  }


  showToast = (toastType, message = '') => {
    const { navigate } = this.props
    this.setState({
      toastShow: true,
      toastMessage: message,
      toastType: toastType
    })
    setTimeout(() => this.setState({ showToast: false }, navigate('/classes/current')), 2000)
  }

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    this.setState({ toastShow: false })
  }

  handleSchoolChange(choice, schoolOptions) {
    const selectedKey = Object.keys(schoolOptions).find(x => schoolOptions[x].value === choice.target.value)
    const selectedValue = schoolOptions[selectedKey]
    this.setState({
      schoolId: choice.target.value,
      teacherOptions: selectedValue.teachers,
      schoolChoice: choice,
      teacherId: '',
      teacherChoice: ''
    }, () => this.validateStepperForms(this.state.value))
  }

  handleTeacherChange(choice) {
    this.setState({
      teacherId: choice.target.value,
      teacherChoice: choice.target
    }, () => this.validateStepperForms(this.state.value))
  }

  handleCuratorChange(choice, curatorOptions) {
    const selected = choice.target.value && choice.target.value.map(e => {
      const selectedKey = Object.keys(curatorOptions).find(x => curatorOptions[x].value === e)
      return curatorOptions[selectedKey]
    })
    this.setState({
      curatorIds: choice.target.value,
      curatorChoice: selected
    }, () => this.validateStepperForms(this.state.value))
  }

  handleGradeChange = (choice) => {
    const selected = choice.target.value && choice.target.value.map(e => {
      const selectedKey = Object.keys(gradeLevels).find(x => gradeLevels[x].value === e)
      return gradeLevels[selectedKey]
    })
    this.setState({
      gradeIds: choice.target.value,
      gradeLevel: selected,
      otherGradeDetails: choice.target.value.map(choice => choice.value).includes('Other') ? this.state.otherGradeDetails : ''
    }, () => this.validateStepperForms(this.state.value))
  }

  handleFacilitatorChange(choice) {
    this.setState({
      facilitatorId: choice.target.value,
      facilitatorChoice: choice.target
    })
    this.validateStepperForms(this.state.value)
  }

  handleSubmit(e, onMutate) {
    e.preventDefault()
    this.setState({
      isCreatingClass: true
    })
    if (!this.state.submitted) {
      onMutate({
        variables: {
          curatorIds: this.state.curatorIds,
          schoolId: this.state.schoolId,
          facilitatorId: this.state.facilitatorId,
          teacherId: this.state.teacherId,
          startDate: DateUtils.createMoment(this.state.startDate).toDate(),
          endDate: DateUtils.createMoment(this.state.endDate).toDate(),
          name: this.state.name,
          gradeIds: this.state.gradeIds.toString(),
          estimatedNumberOfStudents: parseInt(this.state.estimatedNumberOfStudents),
          otherGradeDetail: this.state.otherGradeDetail ? this.state.otherGradeDetail : '',
          termAccepted: this.state.termAccepted || false
        },
      })
    }
  }

  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : (['startDate', 'endDate'].includes(e.target.name) ? e.target.value : e.target.value) }, () => {
      this.validateChange(e)
      this.validateStepperForms(this.state.value)
    })
  }

  validateChange(e) {
    const fieldName = e.target.name
    const value = e.target.value
    const nameFormat = /(\\|\/)/;
    switch (fieldName) {
      case 'name':
        if (value.length > 40) {
          this.setState({
            errors: { ...this.state.errors, name: validationText.assignmentDetails.error.name.length }
          })
        } else if (nameFormat.test(value))
        {
          this.setState({
            errors: {
              ...this.state.errors, name: validationText.assignmentDetails.error.name.specialCharacters
            }
          })
        } else {
          this.setState({ errors: { ...this.state.errors, name: null } })
        }
        break
      case 'startDate':
        let startDate = moment(value).add({hours: 0, minutes: 0, seconds: 0})
        let earliestDate = moment('YYYY-MM-DD').add({hours: 0, minutes: 0, seconds: 0})
        let endDate = this.state.endDate || null
        let startError = { startDate: null, endDate: null }
        if (startDate.isBefore(earliestDate)) {
          startError.startDate = validationText.assignmentDetails.error.date.todayOrFutureDate
        }
        if (endDate && moment(endDate).add({hours: 0, minutes: 0, seconds: 0}).isSameOrBefore(startDate)) {
          startError.endDate = validationText.assignmentDetails.error.date.validEndDate
        }
        this.setState({ errors: { ...this.state.errors, ...startError } })
        break
      case 'endDate':
        let date = moment(value)
        let start = this.state?.startDate || null
        let endError = { startDate: null, endDate: null }
        if (date.isSameOrBefore(start)) {
          endError.endDate = validationText.assignmentDetails.error.date.validEndDate
        }
        this.setState({ errors: { ...this.state.errors, ...endError } })
        break
      case 'estimatedNumberOfStudents':
        if (value && (value <= 0 || !(/^[0-9]*$/.test(value)))) {
          this.setState({ [e.target.name]: '' })
        }
        break
      default:
        return
    }
  }

  handleStepChange = (stepKey) => {
    const { value } = this.state;
    if (stepKey < value) {
      this.setState({ value: stepKey, enablePrev: stepKey === 0 ? false : true, enableNext: true });
    } else {
      const validateResult = this.validateStepperForms(value);
      this.setState({ value: validateResult ? stepKey : value }, () => {
        this.setState({ enablePrev: this.state.value === 0 ? false : true, enableNext: this.validateStepperForms(stepKey) })
      });
    }
  }

  validateStepperForms = (activeStep) => {
    const { teacherId, schoolId, name, gradeLevel, startDate, endDate, estimatedNumberOfStudents } = this.state;
    const { role } = this.props.userStore
    switch (activeStep) {
      case 0:
        let isValidStep0 = teacherId && schoolId ? true : false;
        if (!_.includes(['appadmin', 'facilitator'], role)) {
          isValidStep0 = name && name.length <= 40 && gradeLevel && (
            !_.includes(this.state.gradeIds, 'Other')
            || (_.includes(this.state.gradeIds, 'Other')
              && this.state.otherGradeDetail
              && this.state.otherGradeDetail.trim().length)) ? true : false;
        }
        this.setState({ enableNext: isValidStep0 });
        return isValidStep0;

      case 1:
        let isValidStep1 = name && name.length <= 40 && gradeLevel && (
          !_.includes(this.state.gradeIds, 'Other')
          || (_.includes(this.state.gradeIds, 'Other')
            && this.state.otherGradeDetail
            && this.state.otherGradeDetail.trim().length)) ? true : false;
        if (!_.includes(['appadmin', 'facilitator'], role)) {
          isValidStep1 = startDate && endDate && !this.state.errors?.startDate?.length && !this.state.errors?.endDate?.length ? true : false;
        }
        this.setState({ enableNext: isValidStep1 });
        return isValidStep1;

      case 2:
        let isValidStep2 = startDate && endDate && !this.state.errors?.startDate?.length && !this.state.errors?.endDate?.length ? true : false;
        if (!_.includes(['appadmin', 'facilitator'], role)) {
          isValidStep2 = estimatedNumberOfStudents ? true : false;
        }
        this.setState({ enableNext: isValidStep2 });
        return isValidStep2;

      case 3:
        let isValidStep3 = estimatedNumberOfStudents ? true : false;
        if (!_.includes(['appadmin', 'facilitator'], role)) {
          isValidStep3 = this.state.currentTabs.length - 1 !== activeStep ? true : false;
        }
        this.setState({ enableNext: isValidStep3 });
        return isValidStep3;

      case 4:
        const isLastStep = this.state.currentTabs.length - 1 !== activeStep ? true : false;
        this.setState({ enableNext: isLastStep });
        return isLastStep;

      default:
        this.setState({ enableNext: false });
        return false;
    }
  }

  getHeader = (title, subTitle) => {
    return (<Box sx={{ textAlign: 'center', p: '1.25rem 0 0.5625rem' }}>
      <Typography variant='h1' component='h1' className='bold'>{title}</Typography>
      <Typography variant='paragraph' component='div' sx={{ mt: { xs: 2 } }}>{subTitle}</Typography>
    </Box>)
  }

  render() {
    const { value } = this.state
    const { handleSubmit, handleFormChange } = this
    const { userStore } = this.props
    const role = userStore.role

    return (
      <Layout>
        <Mutation
          mutation={CREATE_CLASS}
          onCompleted={() => {
            if (role === 'appadmin') {
              this.showToast('success',
                `${this.state.name
                } created. The teacher and teaching artist for this class can now add visit dates and invite students.`
              )
            } else {
              this.showToast('success', `${this.state.name} created.`)
            }
          }}
        >
          {(onMutate, { data }) => {
            return (
              <Fragment>
                {this.state.toastShow === true ? (
                  <ToastNotification
                    notificationType={this.state.toastType}
                    showToast={this.state.toastShow}
                    onClose={this.handleClose}
                    toastMessage={this.state.toastMessage}
                  />
                ) : null}
                <Hero heroTitle='Create a New Class' />
                <Box className='container__body' sx={{ px: { xs: 4, md: 6, lg: 30 }, py: { xs: 5, sm: 10, md: 15, lg: 20 } }}>
                  <Box className='white' >
                    <Box>
                      <Progression steps={this.state.currentTabs} handleStepChange={this.handleStepChange} activeStep={value} enablePrev={this.state.enablePrev} enableNext={this.state.enableNext} />
                      {this.state.value === 0 && _.includes(['appadmin', 'facilitator'], role) && (
                        <Query
                          query={SCHOOL_LIST}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <Loading />
                            if (error) {
                              return <Typography variant="paragraph" component="p">Error loading classes</Typography>
                            }
                            if (data) {
                              const schoolOptions = data.schools.map(school => ({
                                label: school.name,
                                value: school.id,
                                teachers: school.teachers.map(teacher => ({
                                  label: teacher.name,
                                  value: teacher.id
                                }))
                              }))

                              return (
                                <TabContainer>
                                  {this.getHeader(
                                    'What is the class school and teacher?',
                                    'Be sure that you have checked with the teacher before you create a class on their behalf.')}
                                  <Grid container gap={{ xs: 5 }} sx={{ mt: { xs: 5 } }}>
                                    <Grid item xs={12}><FormSelect
                                      options={schoolOptions}
                                      label='School Name'
                                      placeholder='School Name'
                                      onChange={e => this.handleSchoolChange(e, schoolOptions)}
                                      name='schoolName'
                                      variant="filled"
                                      value={this.state.schoolId ? this.state.schoolId : null}
                                      inputProps={{ 'aria-label': 'Without label' }}
                                      fullWidth={true}
                                    /></Grid>
                                    <Grid item xs={12}><FormSelect
                                      options={this.state.teacherOptions}
                                      label='Class Teacher'
                                      placeholder='Class Teacher'
                                      styles={selectStyles}
                                      onChange={this.handleTeacherChange}
                                      name='teacherName'
                                      value={this.state.teacherId}
                                      variant="filled"
                                      inputProps={{ 'aria-label': 'Without label' }}
                                      fullWidth={true}
                                    /></Grid>
                                  </Grid>
                                  <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    mt: { xs: 5 }
                                  }}>
                                    <Button
                                      variant='contained'
                                      type='button'
                                      title='Continue'
                                      disabled={this.state.teacherId && this.state.schoolId ? false : true}
                                      onClick={() => {
                                        if (this.state.teacherId && this.state.schoolId) {
                                          this.handleStepChange(1)
                                        }
                                      }}
                                      sx={{ width: { xs: '100%', sm: '50%', md: 'auto' } }}
                                    />
                                  </Box>
                                </TabContainer>
                              )
                            }
                          }}
                        </Query>
                      )}
                      {this.state.value === (_.includes(['appadmin', 'facilitator'], role) ? 1 : 0) && (
                        <TabContainer>
                          {this.getHeader(
                            'What is the class name and grade?',
                            role.toLowerCase() === 'teacher'
                              ? <>Use a short class name that your students will recognize. <br />For example, “Beginner Photography - Period 4”.</>
                              : <>Use a short class name that teachers and students will recognize. <br />For example, “Beginner Photography - Period 4”.</>)}

                          <Grid container gap={{ xs: 5 }} sx={{ mt: { xs: 5 } }}>
                            <Grid item xs={12}><FormField
                              formFieldVariant='filled'
                              formFieldId='custom-css-outlined-class-name'
                              formFieldLabel='Class Name'
                              formFieldType='class-name'
                              formFieldError={this.state.errors.name}
                              formFieldName='name'
                              formFieldDefaultValue={this.state.name}
                              onChange={handleFormChange}
                            /></Grid>
                            <Grid item xs={12}>
                              <Typography
                                variant="paragraph"
                                sx={{
                                  mb: "1rem",
                                }}
                                component="p"
                              >
                                If the class contains mixed grades, select all grades that apply.
                              </Typography>
                              <FormSelect
                                options={gradeLevels}
                                label='Class Grade(s)'
                                placeholder='Class Grade(s)'
                                name='gradeLevel'
                                multiple={true}
                                onChange={this.handleGradeChange}
                                value={this.state.gradeIds}
                                selectedOptions={this.state.gradeLevel}
                                variant="filled"
                                inputProps={{ 'aria-label': 'Without label' }}
                                fullWidth={true}
                              />
                              {
                                this.state.gradeIds && this.state.gradeIds.includes('Other') ?
                                  <Box sx={{ mt: { xs: 5 } }}>
                                    <FormField
                                      formFieldVariant='filled'
                                      formFieldId='custom-css-outlined-class-grade'
                                      formFieldLabel='Other Grade Detail'
                                      formFieldType='text'
                                      formFieldName='otherGradeDetail'
                                      formFieldDefaultValue={this.state.otherGradeDetail}
                                      onChange={handleFormChange}
                                    />
                                  </Box>
                                  : null
                              }
                            </Grid>
                          </Grid>
                          <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: { xs: 'column', sm: 'row' },
                            mt: { xs: 5 }
                          }}>
                            <Button
                              sx={{ width: { xs: '100%', sm: '50%', md: 'auto' } }}
                              variant='contained'
                              type='button'
                              title='Continue'
                              disabled={this.state.name &&
                                this.state.name.trim() &&
                                this.state.name.length <= 40 &&
                                this.state.gradeLevel &&
                                this.state.errors.name === null &&
                                this.state.gradeLevel.length &&
                                (!_.includes(this.state.gradeIds, 'Other') || (_.includes(this.state.gradeIds, 'Other') && this.state.otherGradeDetail && this.state.otherGradeDetail.trim().length)) ? false : true}
                              onClick={() => {
                                if (this.state.name && this.state.gradeLevel.length) {
                                  this.handleStepChange(_.includes(['appadmin', 'facilitator'], this.props.userStore.role) ? 2 : 1)
                                }
                              }}
                            />

                          </Box>
                        </TabContainer>
                      )}
                      {this.state.value === (_.includes(['appadmin', 'facilitator'], role) ? 2 : 1) && (
                        <TabContainer>
                          {this.getHeader('When will the class start and end?', role.toLowerCase() === 'teacher'
                            ? <>Aim for a class length of 6 to 8 weeks. Be sure to check with the <i>wrkxfmly</i> administrator before selecting class dates.</>
                            : <>Aim for a class length of 6 to 8 weeks. Be sure to check with the class teacher before selecting class dates.</>
                          )}
                          <Grid container gap={{ xs: 5 }} sx={{ mt: { xs: 5 } }}>
                            <Grid item xs={12}>
                              <DatePicker
                                onChange={date =>
                                  handleFormChange({
                                    target: {
                                      value: date,
                                      name: 'startDate'
                                    }
                                  })
                                }
                                alignCenter={true}
                                value={this.state.startDate || undefined}
                                helperText={this.state.errors.startDate}
                                error={this.state.errors.startDate || undefined}
                                label='Class Start Date'
                                parentClass='create-class-datepicker'
                                name='startDate'
                                // disablePast
                                minDate={DateUtils.createMoment().format('YYYY-MM-DD')}
                              />
                              <Typography variant="h5" sx={{
                                textAlign: "center",
                                mt: 2
                              }}>
                                This is the first day that students can join the class and begin projects.
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <DatePicker
                                onChange={date =>
                                  handleFormChange({
                                    target: {
                                      value: date,
                                      name: 'endDate'
                                    }
                                  })
                                }
                                value={this.state.endDate}
                                alignCenter={true}
                                name={'endDate'}
                                helperText={this.state.errors.endDate}
                                error={this.state.errors.endDate}
                                label='Class Submission Date'
                                parentClass='create-class-datepicker'
                                minDate={DateUtils.createMoment(this.state?.startDate).add(1, 'days')}
                              />
                              <Typography variant="h5" sx={{
                                textAlign: "center",
                                mt: 2
                              }}>
                                This is the last day that students can submit projects.
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: { xs: 'column', sm: 'row' },
                            mt: { xs: 5 }
                          }}>
                            <Button
                              sx={{ width: { xs: '100%', sm: '50%', md: 'auto' } }}
                              variant='contained'
                              type='button'
                              title='Continue'
                              disabled={(this.state.name &&
                                this.state.gradeLevel &&
                                (!_.includes(this.state.gradeIds, 'Other') || (_.includes(this.state.gradeIds, 'Other') && this.state.otherGradeDetail && this.state.otherGradeDetail.length)) &&
                                this.state.startDate &&
                                this.state.errors.startDate === null &&
                                this.state.errors.endDate === null &&
                                this.state.endDate) ? false : true}
                              onClick={() => {
                                if (
                                  this.state.name &&
                                  this.state.gradeLevel &&
                                  this.state.startDate &&
                                  this.state.endDate
                                ) {
                                  this.handleStepChange(_.includes(['appadmin', 'facilitator'], this.props.userStore.role) ? 3 : 2)
                                }
                              }}
                            />
                          </Box>
                        </TabContainer>
                      )}
                      {this.state.value === (_.includes(['appadmin', 'facilitator'], role) ? 3 : 2) && (
                        <TabContainer>
                          {this.getHeader('What is the estimated class size?', 'Enter an estimate of the number of students in this class. It’s fine if this changes later on.')}
                          <Grid container gap={{ xs: 5 }} sx={{ mt: { xs: 5 } }}>
                            <Grid item xs={12}>
                              <FormField
                                formFieldVariant='filled'
                                formFieldId='custom-css-outlined-number-students'
                                formFieldLabel='Number of Students'
                                formFieldName='estimatedNumberOfStudents'
                                formFieldDefaultValue={this.state.estimatedNumberOfStudents}
                                formFieldType='number'
                                onChange={handleFormChange}
                                formInputProps={{ min: 1, step: 1 }}
                              />
                            </Grid>
                          </Grid>
                          <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: { xs: 'column', sm: 'row' },
                            mt: { xs: 5 }
                          }}>
                            <Button
                              sx={{ width: { xs: '100%', sm: '50%', md: 'auto' } }}
                              variant={'contained'}
                              title={this.state.isCreatingClass ? <span className='icon-text-span'>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                /> Creating... </span> : _.includes(['appadmin', 'teacher'], role) ? 'Continue' : 'Create Class'}
                              disabled={this.state.isCreatingClass || !this.state.estimatedNumberOfStudents ? true : false}
                              onClick={
                                _.includes(['appadmin', 'teacher'], role)
                                  ? () => {
                                    if (this.state.estimatedNumberOfStudents) {
                                      this.handleStepChange(_.includes(['appadmin', 'facilitator'], this.props.userStore.role) ? 4 : 3)
                                    }
                                  }
                                  : e => {
                                    handleSubmit(e, onMutate)
                                  }
                              }
                            />
                          </Box>
                          {_.includes(['appadmin'], role) ? null : (
                            <Typography variant='note' component={'p'} sx={{ mt: { xs: 4 } }}>
                              After your class is created, a <i>wrkxfmly</i> administrator will
                              approve it. Then you can schedule class visits and invite students
                              to join.
                            </Typography>
                          )}
                        </TabContainer>
                      )}
                      {(!_.includes(['appadmin', 'facilitator'], role)) && this.state.value === 3 && (
                        <TabContainer>
                          {this.getHeader('Grant permission for students to create accounts.', <>
                            <Typography variant='paragraph' component={'p'} sx={{ textAlign: 'left' }}>In order to complete the <i>wrkxfmly</i> assignment, each student in your class must sign up for a web app account.</Typography>
                            <Typography variant='paragraph' component={'p'} sx={{ textAlign: 'left', mt: { xs: 2 } }}>Student data is visible to Working Assumptions employees and may be shared within our systems as needed to support the program.</Typography>
                            <Typography variant='paragraph' component={'p'} sx={{ textAlign: 'left', mt: { xs: 2 } }}>Photographs, titles, and captions submitted by students as part of their assignment may be stored in the Working Assumptions digital research archive, visible to individuals connected to Working Assumptions.</Typography>
                            <Typography variant='paragraph' component={'p'} sx={{ textAlign: 'left', mt: { xs: 2 } }}>Before you create this class, please confirm that:</Typography></>)}
                          <Box sx={{ mt: { xs: 5 }, display: { xs: 'grid' }, gap: { xs: 3 } }}>
                            <FormCheckbox
                              label={
                                <>You have reviewed our <Link to='/terms-of-use' target="_blank" rel="noopener noreferrer"><Typography variant='link' className='large' componenet='span'>Application Terms of Use</Typography></Link> and <Link to='/privacy-policy' target="_blank" rel="noopener noreferrer"><Typography variant='link' className='large' componenet='span'>Application Privacy Policy</Typography></Link> and that these comply with any relevant guidelines in your school district.</>
                              }
                              color='primary'
                              name='termAccepted'
                              checked={this.state.termAccepted || false}
                              onChange={handleFormChange}
                            />
                            <FormCheckbox
                              label={
                                <>You grant permission for students to create web app accounts and upload work under the terms above.</>
                              }
                              color='primary'
                              name='permission'
                              checked={this.state.permission || false}
                              onChange={handleFormChange}
                            />
                          </Box>
                          <Box sx={{
                            mt: { xs: 5 },
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: { xs: 'column', md: 'row' },
                            gap: { xs: '24px', sm: '16px' },
                            pb: { xs: role === 'teacher' && this.state.value === 3 ? 2 : 0 }
                          }}
                          >
                            <Button
                              variant={role === 'appadmin' ? 'outlined' : 'contained'}
                              title={this.state.isCreatingClass ? <span className='icon-text-span'>
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                /> Creating... </span> : 'Accept and Create Class'}
                              disabled={this.state.isCreatingClass || !this.state.permission || !this.state.termAccepted ? true : false}
                              onClick={
                                role === 'appadmin'
                                  ? () => {
                                    if (this.state.estimatedNumberOfStudents) {
                                      this.handleStepChange(4)
                                    }
                                  }
                                  : e => {
                                    handleSubmit(e, onMutate)
                                  }
                              }
                            />
                            {role === 'teacher' && (
                              <Button
                                variant={'outlined'}
                                title={'Decline'}
                                disabled={this.state.isCreatingClass ? true : false}
                                component={Link} to='/classes/current'
                              >
                              </Button>
                            )}
                          </Box>
                        </TabContainer>
                      )}
                      {this.state.value === 4 && role === 'appadmin' && (
                        <Query
                          query={gql`
                          query CuratorAndFacilitatorList {
                            curators {
                              id
                              name
                            }
                            facilitators {
                              id
                              name
                            }
                          }
                        `}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <Loading />
                            if (error) {
                              return <Typography variant="paragraph" component="p">Error loading classes</Typography>
                            }
                            if (data) {
                              const facilitatorOptions = data.facilitators.map(f => ({
                                label: f.name,
                                value: f.id
                              }))
                              const curatorOptions = data.curators.map(c => ({
                                label: c.name,
                                value: c.id
                              }))

                              return (
                                <TabContainer>
                                  {this.getHeader('Who will support this class?', 'Select a teaching artist to mentor the class and one or more curators to perform the curatorial review.')}
                                  <Grid container gap={{ xs: 5 }} sx={{ mt: { xs: 5 } }}>
                                    <Grid item xs={12}><FormSelect
                                      options={facilitatorOptions}
                                      label='Select Teaching Artist'
                                      placeholder='Select Teaching Artist'
                                      onChange={this.handleFacilitatorChange}
                                      name='facilitatorName'
                                      value={this.state.facilitatorId}
                                      variant="filled"
                                      inputProps={{ 'aria-label': 'Without label' }}
                                      fullWidth={true}
                                    /></Grid>
                                    <Grid item xs={12}>
                                      <FormSelect
                                        options={curatorOptions}
                                        label='Select Curators'
                                        placeholder='Select Curators'
                                        name='curatorName'
                                        multiple={true}
                                        onChange={e => this.handleCuratorChange(e, curatorOptions)}
                                        value={this.state.curatorIds}
                                        selectedOptions={this.state.curatorChoice}
                                        variant="filled"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        fullWidth={true}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: { xs: 'column', sm: 'row' },
                                    mt: { xs: 5 }
                                  }}>
                                    <Button
                                      variant='contained'
                                      title={this.state.isCreatingClass ? <span className='icon-text-span'>
                                        <Spinner
                                          as="span"
                                          animation="border"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                        /> Creating... </span> : 'Create Class'}
                                      onClick={e => {
                                        handleSubmit(e, onMutate)
                                      }}
                                      disabled={this.state.isCreatingClass || !this.state.facilitatorId ? true : false}
                                      sx={{ width: { xs: '100%', sm: '50%', md: 'auto' } }}
                                    />
                                  </Box>
                                </TabContainer>
                              )
                            }
                          }}
                        </Query>
                      )}
                    </Box>
                    {((role === 'teacher' && this.state.value !== 3) || (role !== 'teacher')) && (<Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      pb: { xs: 5 }
                    }}>
                      <Link to='/classes/current'>
                        <Typography variant='link' component={'span'}>
                          Cancel
                        </Typography>
                      </Link>
                    </Box>)}
                  </Box>

                </Box>
              </Fragment>
            )
          }}
        </Mutation>
      </Layout>
    )
  }
}

// CreateClass.propTypes = {
//   classes: PropTypes.object.isRequired
// }

export default inject('userStore', 'breadcrumbStore')(withRouter(observer(CreateClass)))
