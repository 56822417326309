import React, { Component, Fragment } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { inject, observer } from 'mobx-react'
import { gql } from '@apollo/client'
import ModelReleaseModal from './ModelReleaseModal'
import './modelReleasesList.scss'
import ModelReleasesListRow from './ModelReleasesListRow'
import { getAllCuurentMr } from '../../utils/commonFunctions'

const ManageModelReleaseMutation = gql`
  mutation ManageModelRelease(
    $photoId: ID!
    $modelUuid: String!
    $manageType: String!
  ) {
    manageModelRelease(
      photoId: $photoId
      modelUuid: $modelUuid
      manageType:$manageType
    ) {
      id
    } 
  }
`

class ModelReleasesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resendMessage: false,
      resendUuid:'',
      modelRelease:{},
      photoId: this.props.photoId,
      showEditModel: false,
      showNewModel:false,
      showResendModal: false,
      modelOp: 'add'
    }
    this.showResendRequestMessage = this.showResendRequestMessage.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.photoId !== nextProps.photoId) {
      return {
        photoId: nextProps.photoId
      }
    } else return null
  }

  manageModelRelease = (onMutate, uuid, type) => {
    onMutate({
      variables: {
        photoId: this.props.photoId,
        modelUuid: uuid,
        manageType:type
      },
      refetchQueries: [`PhotoDetails`]
    })
  }

  showResendRequestMessage = (modelUuid) => {
    this.setState({showResendModal:false,resendMessage:true, resendUuid : modelUuid})
    setTimeout(() => this.setState({ resendMessage: false, resendUuid:'' }), 5000)
  }

  showMrModel = (opType, mrUuid=null) => {
    this.setState({
      showNewModel: true,
      modelOp: opType,
      existingMrUuid:mrUuid,
    })
  }

  getMrByStatus = (modelReleases, byStatus, byAdminDecision ='Pending') => {
    return modelReleases.filter( mr => mr.mrStatus === byStatus && mr.adminDecision === byAdminDecision)
  }
  
  getRelatedMrForActiveMr = (mrUuid) => {
    return this.props.modelReleases.filter( mr => (mr.relatedToMr === mrUuid || mr.mrStatus==='Expired')).sort(
      (a, b) =>
        new Date(...b.createdAt.split('/').reverse()) - new Date(...a.createdAt.split('/').reverse())
    )
  }

  render() {
    const { role } = this.props.userStore
    
    let showNewModalClose = () => this.setState({ showNewModel: false}, () => {
      // this.props.toggleEditing(false)
    })

    const { totalMrRequired, modelReleases } = this.props
    const allActiveMr = getAllCuurentMr(modelReleases)
    const notAddedMr = parseInt(totalMrRequired) - parseInt(allActiveMr.length) 

    return (
      <div className='model-release__list-section'>
        <Fragment>
          { allActiveMr.length > 0  ? 
            <Mutation mutation={ManageModelReleaseMutation} onCompleted={()=>{
              this.props.refetchPhoto()
              this.props.fetchProject()
            }}>
              {(manageOnMutate, { data }) => (
                allActiveMr.map( mr => ( 
                  <ModelReleasesListRow
                    role={role}
                    manageModelQuery={ManageModelReleaseMutation}
                    modelName={mr.modelFirstName ? mr.modelFirstName + ' ' +mr.modelLastName : '[Signed Model Release]'}
                    manageNewModel={this.props.manageNewModel}
                    modelRelease={mr}
                    allRelatedMrs={this.getRelatedMrForActiveMr(mr.uuid)}
                    showMrModel={this.showMrModel}
                    photoId={this.state.photoId}
                    refetchPhoto={this.props.refetchPhoto}
                    refetchQueries={this.props.refetchQueries}
                    studentDetails={this.props.studentDetails}
                    modelUuid = {mr.uuid}
                  />
                ))
              )}
            </Mutation>
          : null }

          { notAddedMr > 0 ? 
            [...Array(notAddedMr)].map((newMr, index) => (
              <ModelReleasesListRow
                role={role}
                manageModelQuery={this.props.manageModelQuery}
                modelName={`Model ${index + 1}`}
                manageNewModel={this.props.manageNewModel}
                toggleEditing={this.props.toggleEditing}
                showMrModel={this.showMrModel}
                showNewModalClose={showNewModalClose}
                refetchPhoto={this.props.refetchPhoto}
                fetchProject={this.props.fetchProject}
              />
            )) 
          : null}
        </Fragment>

      <ModelReleaseModal 
        show={this.state.showNewModel}
        onHide={showNewModalClose}
        modelOp={this.state.modelOp}
        photoId={this.state.photoId}
        onSuccessCallback={showNewModalClose}
        refetchQueries={this.props.refetchQueries}
        refetchPhoto={this.props.refetchPhoto}
        studentDetails={this.props.studentDetails}
        existingMrUuid = {this.state.existingMrUuid}
      />
    </div> )
  }
}

export default inject('userStore')(observer(ModelReleasesList))