import { gql } from '@apollo/client'

export const ADD_ASSET = gql`
  mutation AddAsset($photoId: ID!, $assetId: String!) {
    addAsset(photoId: $photoId, assetId: $assetId) {
      id
      assetId
      lastSync
    }
  }
`

export const ADD_ASSET_ID_TO_MR = gql`
  mutation AddAssetIdToMr($id: ID!, $assetId: String!) {
    addAssetIdToMr(id: $id, assetId: $assetId) {
      id
      assetId
      lastSync
    }
  }
`
export const ADD_ASSET_ID_TO_UA = gql`
mutation AddAssetIdToUa($id: ID!, $assetId: String!) {
  addAssetIdToUa(id: $id, assetId: $assetId) {
    id
    assetId
    lastSync
  }
}
`

export const ADD_CAPTION_ASSET_ID_TO_PROJECT = gql`
mutation AddCaptionAssetIdToProject($id: ID!, $captionAssetId: String!) {
  addCaptionAssetIdToProject(id: $id, captionAssetId: $captionAssetId) {
    id
    captionAssetId
    lastSync
  }
}
`