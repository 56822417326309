import { Box, MobileStepper, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'

const Progression = props => {
  const [steps, setSteps] = useState(props.steps)
  const [stepCount, setStepCount] = useState(props.steps.length || 0)
  const [activeStep, setActiveStep] = useState(props.activeStep || 0)
  const [enablePrev, setEnablePrev] = useState(props?.enablePrev || false)
  const [enableNext, setEnableNext] = useState(props?.enableNext || false)

  useEffect(() => {
    setSteps(props.steps)
    setStepCount(props.steps.length)
  }, [props.steps])

  useEffect(() => {
    setActiveStep(props.activeStep)
  }, [props.activeStep])

  useEffect(() => {
    setEnableNext(props.enableNext)
  }, [props.enableNext])

  useEffect(() => {
    setEnablePrev(props.enablePrev)
  }, [props.enablePrev])

  const handleActiveStep = index => {
    props?.handleStepChange(index)
  }

  const handleNextButton = () => {
    const variant = !enableNext ? 'disabled_link' : 'link'
    const clickFn = !enableNext ? null : () => handleActiveStep(activeStep + 1)
    return (
      <Typography
        variant={variant}
        onClick={clickFn}
        disabled={enableNext}
        sx={{
          cursor: !enableNext ? '' : 'pointer',
          mr: { sm: 0.5 },
          textDecoration: 'none',
          svg: { verticalAlign: 'middle' }
        }}
        className='regular'
      >
        Next <ChevronRight size={24} />
      </Typography>
    )
  }

  const handleBackButton = () => {
    const variant = !enablePrev ? 'disabled_link' : 'link'
    const clickFn = !enablePrev ? null : () => handleActiveStep(activeStep > 0 ? activeStep - 1 : 0)
    return (
      <Typography
        variant={variant}
        onClick={clickFn}
        disabled={enablePrev}
        sx={{
          cursor: !enablePrev ? '' : 'pointer',
          ml: { sm: 0.5 },
          textDecoration: 'none',
          svg: { verticalAlign: 'middle' }
        }}
        className='regular'
      >
        <ChevronLeft size={24} />
        Back
      </Typography>
    )
  }

  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'block'
          },
          width: '100%',
          pt: { md: 5 },
          pb: { md: 4 }
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} onClick={() => handleActiveStep(index)}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'none'
          },
          pt: { xs: 1, sm: 5 }
        }}
      >
        <MobileStepper
          variant='text'
          steps={stepCount}
          position='static'
          activeStep={activeStep}
          nextButton={handleNextButton()}
          backButton={handleBackButton()}
        />
      </Box>
    </>
  )
}

export default Progression
