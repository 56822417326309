import React, { Fragment, useState, useEffect } from 'react'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import UserListRowTeachers from '../molecules/UserListRowTeachers.js'
import InviteStudentModal from '../molecules/InviteStudentModal.js'
import SendMessageModal from '../molecules/SendMessageModal.js'
import { observer, inject } from 'mobx-react'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import Loading from '../../ui/elements/Loading.js'
import ListSearchBar from '../elements/ListSearchBar.js'
import EmptyListDesign from '../molecules/EmptyListDesign.js'
import { getTextContentByPage } from '../../content/textContent'
import { Box, Grid, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc.js'
import CustomPagination from '../molecules/CustomPagination.js'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

const GET_TEACHERS_QUERY = gql`
  query PaginatedTeachers($search: String, $batchSize: Int!, $baseBatchSize: Int!, $step: Int!) {
    teachersConnection(search: $search, batchSize: $batchSize, baseBatchSize: $baseBatchSize, step: $step) {
      id
      userId
      name
      lastName
      lastSeenAt
      school {
        name
      }
      cohorts {
        id
      }
    }
    teacherCount(search: $search)
  }
`

const BATCH_SIZE = 10

const UserListTeachers = (props) => {
  const {location} = props
  const [checkedReminder, setCheckedReminder] = useState(false)
  const [checkedBoxes, setNewCheckedBoxes] = useState({})
  const [modalShow] = useState(false)
  const [modalSendMessage, setModalSendMessage] = useState(false)
  const [teacherCount, setTeacherCount] = useState(0)
  const [searchKey, setSearchKey] = useState('')
  const [step, setStep] = useState(0)
  const [batchSize] = useState(BATCH_SIZE)
  const [baseBatchSize] = useState(BATCH_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [teachers, setTeachers] = useState([])
  const navigate = useNavigate()

  const { loading } = useQuery(GET_TEACHERS_QUERY,
    {variables: {
      search: searchKey,
      step: step,
      batchSize: batchSize,
      baseBatchSize: baseBatchSize
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setTeachers( data)
      setTeacherCount( data?.teacherCount)
      setTotalPages(Math.ceil(data?.teacherCount / batchSize))
      setCheckedBoxes(data.teachersConnection)
    }
  })

  const checkAllCheckedBoxes = () => {
    const listTeachers = teachers.teachersConnection ? teachers.teachersConnection : []
    const newState = Object.assign({}, checkedBoxes)
    const isSelected = !checkedReminder ? true : false
    listTeachers.forEach(teacher => (
      newState[teacher.id] = isSelected
    ))
    setNewCheckedBoxes(newState)
    setCheckedReminder(isSelected)
  }

  const setCheckedBoxes = (teachers) => {
    const checkedBoxes = {}
    teachers.forEach(teacher => (checkedBoxes[teacher.id] = false))
    setNewCheckedBoxes(checkedBoxes)
  }

  const updatedCheckedBoxes = teacherId => {
    const newState = Object.assign({}, checkedBoxes)
    newState[teacherId] = newState[teacherId] ? false : true
    setNewCheckedBoxes(newState)
    setCheckedReminder(Object.values(newState).includes(false) ? false : true)
  }

  useEffect(() => {
    if(searchKey) {
      handlePageChange(0)
    }
  }, [searchKey])

  useEffect(() => {
    setTotalPages(Math.ceil(teacherCount / batchSize))
  }, [batchSize, teacherCount])

  useEffect(() => {
    setStep(isNaN(parseInt(props?.params?.page)) ? 0 : (parseInt(props?.params?.page) - 1))
  }, [props?.params])

  const handlePageChange = page => {
    setCheckedReminder(false)
    // Update the URL
    const pathParts = location.pathname.split('/').filter(Boolean)
    if (!isNaN(pathParts[pathParts.length - 1])) {
      pathParts.pop()
    }
    const newPath = `/${pathParts.join('/')}/${page + 1}`
    navigate(newPath)
  }

  const modalClose = () => { setModalSendMessage(false) }

  const listTeachers = teachers.teachersConnection ? teachers.teachersConnection : []
  const selectedTeachers = listTeachers.filter(teacher => checkedBoxes[teacher.id]).map(teacher => teacher.userId)
  return(
    <>
      <Box sx={{ pb: { xs: 4 } }}>
        <Box sx={{ px: { xs: 4, md: 0 } }}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant='h1' className='bold'>
                Teachers ({teacherCount})
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                textAlign: {
                  xs: 'rignt',
                  sm: 'right',
                  md: 'right'
                }
              }}
            >
              <Grid
                container
                spacing={{
                  xs: 3,
                  sm: 4,
                  md: 3
                }}
                columnSpacing={1}
                alignItems='center'
              >
                <Grid item md={12} sm={12} xs={12}>
                  <Button
                    variant='contained'
                    title='Add a Teacher'
                    onClick={() => navigate('/create-user/teacher')}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 'fit-content'
                      }
                    }}
                  />

                  <Button
                    title='Message Selected People'
                    disabled={selectedTeachers.length < 1}
                    onClick={() => setModalSendMessage(true)}
                    sx={{
                      ml: 2,
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 'fit-content'
                      },
                      display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'inline-flex'
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'block',
                      md: 'none'
                    }
                  }}
                >
                  <Box>
                    <ListSearchBar
                      placeHolder='Search for a Teacher Name'
                      id='teacher-search'
                      onChange={e => setSearchKey(e.target.value)}
                      value={searchKey}
                      sx={{ width: '100%' }}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'block',
                      md: 'none'
                    }
                  }}
                >
                  <Button
                    title='Message Selected People'
                    disabled={selectedTeachers.length < 1}
                    onClick={() => setModalSendMessage(true)}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 'fit-content'
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'none',
                      md: 'block'
                    }
                  }}
                >
                  <Box sx={{ mt: { md: '-6px' } }}>
                    <ListSearchBar
                      placeHolder='Search for a Teacher Name'
                      id='teacher-search'
                      onChange={e => setSearchKey(e.target.value)}
                      value={searchKey}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SendMessageModal
            show={modalSendMessage}
            onHide={modalClose}
            userIds={selectedTeachers}
          />
          <InviteStudentModal
            show={modalShow}
            onHide={modalClose}
            classUuid={props.classUuid}
          />
        </Box>
        { loading ? (
          <Box className='reivew-list-loader--wrapper'>
            <Loading />
          </Box>
        ) : null}
        { listTeachers.length > 0 ? (
            <Fragment>
              <Box
                sx={{
                  mt: { xs: 5, md: 4, lg: 5 },
                  mb: { xs: '1.125rem', md: 2 },
                  px: { xs: 3, md: 4 }
                }}
              >
                <Grid container alignItems='center'>
                  <Grid item md={1} sm={1} xs={1}>
                    <Box sx={{ textAlign: 'center' }}>
                      <FormCheckbox
                        checked={checkedReminder}
                        onChange={checkAllCheckedBoxes}
                        value='checkedReminder'
                        color='primary'
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sm={6}
                    xs={9}
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'block'
                      }
                    }}
                  >
                    <Typography variant='dataLabel' component={'p'}>
                      User Details
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={5}
                    xs={0}
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'block'
                      }
                    }}
                  >
                    <Typography variant='dataLabel' component={'p'}>
                      Classes
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={0}
                    xs={0}
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'block'
                      }
                    }}
                  >
                    <Typography variant='dataLabel' component={'p'}>
                      Activity
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={11}
                    sm={11}
                    xs={11}
                    sx={{
                      display: {
                        xs: 'block',
                        lg: 'none'
                      }
                    }}
                  >
                    <Typography variant='dataLabel' component={'p'}>
                      Select All Rows
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {listTeachers.map(teacher => (
                <UserListRowTeachers
                  key={teacher.id}
                  lastSeenAt={teacher.lastSeenAt}
                  teacher={teacher}
                  checked={checkedBoxes[teacher.id] || false}
                  onChecked={() => updatedCheckedBoxes(teacher.id)}
                  userId={teacher.userId}
                />
              ))}
              <Box sx={{ mt: { xs: 4 } }}>
                <CustomPagination totalPages={totalPages} pageNum={props?.params?.page ? parseInt(props?.params?.page) : 1} onChange={handlePageChange}/>
              </Box>
            </Fragment>
          ) : (
            <Box sx={{ mt: { xs: 4 } }}>
              <EmptyListDesign
                heading={getTextContentByPage(
                  'USERLIST',
                  props.role.toUpperCase(),
                  'TEACHERLIST_EMPTYINTRO'
                )}
                subheading={getTextContentByPage(
                  'USERLIST',
                  props.role.toUpperCase(),
                  'TEACHERLIST_EMPTYSUBHEADING'
                )}
              />
            </Box>
          )
        }
      </Box>
    </>
  )

}

export default inject('userTeachersFilterStore')(observer(withRouter(withApollo(UserListTeachers))))
