import React, { Component } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import Modal from 'react-bootstrap/Modal'
import FormField from '../elements/FormField.js'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Typography } from '@mui/material'

const CreatePhotoTagMutation = gql`
  mutation ManagePhotoTags(
    $name: String
    $action: String!
  ) {
    managePhotoTags(
      name: $name
      action: $action
    ) {
      id
    }
  }
`

const EditPhotoTagMutation = gql`
  mutation ManagePhotoTags(
    $tagId: ID
    $name: String
    $action: String!
  ) {
    managePhotoTags(
      tagId:$tagId
      name: $name
      action: $action
    ) {
      id
    }
  }
`

class PhotoTagModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      action: 'create',
      errors: {}
    }
    this.handleFormChange = this.handleFormChange.bind(this)
  }
  componentWillReceiveProps = (nextProps) => {
    if(nextProps.tagId != this.props.tagId) {
      this.setState({
        action:nextProps.tagId > 0 ? 'edit' : 'create',
        photoTagName:nextProps.tagName,
        tagId:nextProps.tagId,
        errors:{}
      })
    }
  }
  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleFormSubmit(onMutate) {
    let errors = {}
    if (!this.state.photoTagName) {
      errors.photoTagName = 'Cannot be blank'
    }
    this.setState({ errors: { ...errors } })
    let variables = {
      name: this.state.photoTagName,
      action: this.state.action
    }
    if (Object.keys(errors).length === 0) {
      if(this.state.action === 'edit') {
        variables = {
          tagId: this.state.tagId,
          ...variables
        }
      }
      onMutate({
        variables: {...variables},
      })
    }
  }
  render() {
    const {
      handleFormChange
    } = this
    return (
      <Mutation
        mutation={this.state.action == 'edit' ? EditPhotoTagMutation : CreatePhotoTagMutation}
        onCompleted={() => {
          this.props.onHide()
          this.setState({tagId:0, photoTagName:''})
          let toastMessage = 'Photo Tag Created Successfully'
          if(this.state.action == 'edit') {
            toastMessage = 'Photo Tag Updated Successfully'
          }
          this.props.showToast(toastMessage)
        }}
        onError={(error)=>{
          this.setState({ errors: { photoTagName: error.graphQLErrors[0].message} })
        }}
      >
        {(onMutate, { data }) => {
          return (
            <Modal
              {...this.props}
              size='lg'
              aria-labelledby='contained-modal-title-vcenter'
              centered
              show={this.props.show}
              onClick={this.props.onClick}
              value={this.props.value}
            >
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  <Typography variant="h2">{this.state.action == 'edit'?'Edit Photo Tag':'Add New Photo Tag'}</Typography>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='form-field'>
                  <FormField
                    formFieldId='outlined-fname'
                    formFieldFullWidth='false'
                    formFieldLabel='Tag Name'
                    formFieldVariant='filled'
                    formFieldError={this.state.errors.photoTagName || null}
                    formFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    formFieldType='text'
                    formFieldName='photoTagName'
                    onChange={handleFormChange}
                    formFieldValue={this.state.photoTagName}
                  />
                </div>
                <div className='send-message__button'>
                  <Button

                    type='copy'
                    title={this.state.action =='edit' ? 'Update':'Add' }
                    onClick={() => this.handleFormSubmit(onMutate)}/>
                  {'  '}
                  <Typography variant="h4"className='semi-strong link' ml={2} onClick={() => this.props.onHide()}>
                    Cancel
                  </Typography>
                </div>
              </Modal.Body>
            </Modal>)
      }}</Mutation>
    )
  }
}

export default PhotoTagModal
