import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import Button from '../elements/Button.js'
import {
  Grid,
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio
} from '@mui/material'
import FormFieldMultiline from '../elements/FormFieldMultiline.js'
import { Spinner } from 'react-bootstrap'
import BaseModal from '../template/BaseModal'

const ApproveRejectFormMutation = gql`
  mutation ApproveRejectForms(
    $uuid: String!
    $adminDecision: String!
    $rejectNote: String!
    $formType: String!
  ) {
    approveRejectForms(
      uuid: $uuid
      adminDecision: $adminDecision
      rejectNote: $rejectNote
      formType: $formType
    ) {
      id
    }
  }
`

const ApproveOrRejectFormModal = ({ show, onClick, value, onHide, fetchProject, ...props }) => {
  const [adminDecision, setAdminDecision] = useState('Approve')
  const [adminRejectNote, setAdminRejectNote] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState('')

  const handleApprovalChange = value => {
    setAdminDecision(value)
    setAdminRejectNote(value === 'Approve' ? '' : adminRejectNote)
  }

  const handleFormSubmit = async () => {
    const formError =
      adminDecision === 'Reject' && adminRejectNote.length < 1 ? 'Please provide a reason.' : ''
    setError(formError)
    setIsSubmitting(true)

    if (formError === '') {
      await submitApproveRejectDecision({
        variables: {
          uuid: props?.uuid,
          adminDecision,
          rejectNote: adminRejectNote,
          formType: props?.entityType
        }
      })

      fetchProject?.()
      handleClose()
    }
  }

  const handleClose = () => {
    setAdminDecision('Approve')
    setAdminRejectNote('')
    setIsSubmitting(false)
    setError('')
    onHide?.(false)
  }

  const [submitApproveRejectDecision] = useMutation(ApproveRejectFormMutation, {
    onCompleted: () => {
      handleClose()
      props?.refetchPhoto?.()
      props?.refetchUaData?.()
      props?.refetchMrData?.()
    },
    refetchQueries: ['ProjectDetails']
  })

  return (
    <BaseModal
      {...props}
      show={show}
      onClick={onClick}
      value={value}
      open={show}
      onClose={handleClose}
      onHide={handleClose}
    >
      <BaseModal.BaseModalHeader>Approve or Reject Form</BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Typography variant={'p'} component={'p'} className='invite-student__description'>
          If this form is correct, please mark it as approved.
        </Typography>
        <Typography variant={'p'} component={'p'} className='invite-student__description'>
          If this form is incorrect, please mark it as rejected and provide a reason. The student
          will be able to add a new form the next time they log in.
        </Typography>

        <form noValidate autoComplete='on'>
          <Grid container direction='column' alignItems='stretch' spacing={2}>
            <Grid item>
              <FormControl component='fieldset'>
                <RadioGroup
                  aria-label='admin-decision'
                  name='adminDecision'
                  value={adminDecision}
                  sx={{ flexDirection: 'row' }}
                  onChange={e => handleApprovalChange(e.target.value)}
                >
                  <FormControlLabel
                    value='Approve'
                    control={<Radio color='primary' />}
                    label='Approve Form'
                    labelPlacement='end'
                    checked={adminDecision === 'Approve'}
                  />
                  <FormControlLabel
                    value='Reject'
                    control={<Radio color='primary' />}
                    label='Reject Form'
                    labelPlacement='end'
                    checked={adminDecision === 'Reject'}
                  />
                </RadioGroup>
              </FormControl>
              {adminDecision === 'Reject' && (
                <Grid item>
                  <FormFieldMultiline
                    formFieldId='adminRejectNote'
                    formFieldVariant='filled'
                    disabled={adminDecision !== 'Reject'}
                    formFieldPlaceholder='Reason Form is Rejected'
                    formFieldType='text'
                    formFieldName='adminRejectNote'
                    formFieldMargin='auto'
                    formFieldValue={adminRejectNote || ''}
                    onChange={e => setAdminRejectNote(e.target.value)}
                    multiline='true'
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <BaseModal.BaseModalFooter>
            <Box
              sx={{ padding: '24px 0 0', marginTop: '0px', alignContent: 'center' }}
              className='send-message__button'
            >
              <Grid container direction='row' sx={{ justifyContent: { xs: 'center', sm: 'end' } }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    variant='contained'
                    type='submit'
                    disabled={
                      isSubmitting ||
                      error ||
                      (adminDecision === 'Reject' && adminRejectNote.length < 1)
                    }
                    title={
                      isSubmitting ? (
                        <span className='icon-text-span'>
                          <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />{' '}
                          Saving...{' '}
                        </span>
                      ) : (
                        'Save'
                      )
                    }
                    onClick={handleFormSubmit}
                    sx={{ width: { xs: '100%', sm: '100%' }, float: 'right' }}
                  />
                </Grid>
              </Grid>
            </Box>
          </BaseModal.BaseModalFooter>
        </form>
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

export default ApproveOrRejectFormModal
