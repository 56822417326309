import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import variables from '../settings/_variables.scss'


class SchoolListRow extends Component {
  render() {
    const { school } = this.props
    const linkTo = '/school-details/' + school.id
    return (
      <Box
        sx={{ px: { md: 6 }, mb: { md: 1 } }}
      >
        <Grid
          container
          key={school.id}
          rowGap={{ xs: 2 }}
          columnSpacing={{sm: 3, md : '0'}}
          sx={{ backgroundColor: variables.white, px: { xs: 3, md: 4 }, py: { xs: 3, md: 4 }, borderBottom: { xs: variables.borderThinGrey } }}
          >
          <Grid item md={5} sm={6} xs={12}>
            <Box className='school-list__school-name'>
              <Link to={linkTo}>
                <Typography
                  variant='link'
                  className='large'
                  sx={{wordWrap: 'break-word'}}
                >
                  {school.name}
                </Typography>
              </Link>
              <Typography variant='h4' sx={{ mt: { xs: 1, sm: 1, md: 2 } }}>
                {school.city ? `${school.city}, ` : null}
                {school.state ? `${school.state}` : null}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Link to={linkTo + '/classes'}>
              <Typography
                variant='link'
                className='strong'
                sx={{ mt: { xs: 3, sm: 0, md: 0 } }}
              >
                {school.cohorts.length} Classes
              </Typography>
            </Link>
          </Grid>
          <Grid item md={4} sm={12} xs={12} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            <Typography variant='h4'>
              {school.students} Students Enrolled
            </Typography>
          </Grid>
        </Grid>
      </Box>
    )
  }
}

export default SchoolListRow
