import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'

class SendAwardSummariesTableRows extends Component {
  getMaxReviewedDate = cohort => {
    let curatorsDates = cohort.curatorReviews.map(review => moment(review.createdAt))
    return moment.max(curatorsDates)
  }

  getTotalAwardNumber = cohort => {
    let totalAwards = 0
    // eslint-disable-next-line array-callback-return
    cohort.photos.map(photo => {
      totalAwards = totalAwards + photo.awards.length
    })
    return totalAwards
  }

  render() {
    const { cohort } = this.props
    return (
      <Grid container spacing={2} className='dashboard__info-list-rows'>
        <Grid item md={4} sm={4} xs={12}>
          <Link to={`/class-review/${cohort.id}`}>
            <Typography variant='h4' className='semi-strong truncate'>
              {cohort.name}
            </Typography>
          </Link>
        </Grid>
        <Grid item md={4} sm={4} xs={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Typography variant='h4'>{this.getTotalAwardNumber(cohort)} Awards</Typography>
        </Grid>
        <Grid item md={4} sm={4} xs={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Typography variant='h4'>
            {this.getMaxReviewedDate(cohort).format('MMMM D, YYYY')}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

export default SendAwardSummariesTableRows
