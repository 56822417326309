// import './formfield.scss'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import InputBase from '@mui/material/InputBase'
import { styled } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'

const Input = styled(InputBase)(({ theme }) => ({
  // display: 'flex',
  // backgroundColor: 'white',
  // marginBottom: 15,
  // height: 'auto',
  // margin: theme.spacing(1),
  // '& input::placeholder': {
  //   color: 'blue'
  // },
  // '& label.Mui-focused': {
  //   color: '#0076BB'
  // },
  // '& .MuiInput-underline:after': {
  //   borderBottomColor: '#0076BB'
  // }
}))

const NakedFormField = (props) => {
  return (
    <Fragment>
      <Input
        margin={'dense'}
        multiline={props.multiline}
        fullWidth={props.fullWidth}
        placeholder={props.placeholder}
        onClick={props.onClick}
        onFocus={props.onFocus}
        name={props.name}
        type={props.type}
        onChange={props.onChange}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        value={props.value ? props.value : ''}
        error={props.formFieldError ? true : false}
        onBlur={props.onBlur}
        inputRef={props.inputRef}
        rows={props.rows}
        endAdornment={props.endAdornment}
        sx={{...props.sx}}
      />
      {props.formFieldError ? (
        <FormHelperText className='component-error-text'>{props.formFieldError}</FormHelperText>
      ) : null}
    </Fragment>
  )
}

// NakedFormField.propTypes = {
//   classes: PropTypes.object.isRequired
// }

export default NakedFormField
