import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const BpIcon = styled('span')(() => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '1px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(0, 0, 0, 0.12)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
  'input:disabled:hover ~ &': {
    boxShadow: 'none',
    background: 'rgba(0, 0, 0, 0.12)',
  },
});

function FormCheckbox(props) {
  const { sx, sxcheckbox } = props

  return (
    <FormControlLabel
      {...props}
      sx={{
        ...sx
      }}
      control={
        <Checkbox
          id={props.id}
          checked={props.checked}
          color={props.color}
          onChange={props.onChange}
          value={props.value}
          name={props.name}
          error={props.error ? true : false}
          required={props.required || false}
          inputRef={props.inputRef || null}
          disabled={props?.disabled || false}
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: {xs: 16}
            },
            ...sxcheckbox
          }}
        />
      }
      label={
        <Typography variant='paragraph'>{props.label}</Typography>
      }
    />
  )
}

FormCheckbox.propTypes = {
  sx: PropTypes.object,
  error: PropTypes.bool
}

export default FormCheckbox
