import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
class OverDueCuratorialReviewTableRows extends Component {
  render() {
    const { cohort } = this.props
    return (
      <Grid container spacing={2} className='dashboard__info-list-rows'>
        <Grid item md={8} sm={6} xs={12}>
          <Link to={`/class-review/${cohort.id}`}>
            <Typography variant='h4' className='semi-strong truncate'>
              {cohort.name}
            </Typography>
          </Link>
        </Grid>
        <Grid item md={4} sm={6} xs={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Typography variant='h4'>
            {moment(cohort.curatorsDueDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

export default OverDueCuratorialReviewTableRows
