import React from 'react'
import { MessageSquare } from 'react-feather'
import PhotoActionBar from './PhotoActionBar'
import { Box } from '@mui/material'
import DateUtils from '../../utils/DateUtils'

const PhotoDetailsHeader = props => {
  const { photo, isModal, publicView, role, awardChipDisabled } = props
  return (
    <Box
      className={
        !isModal ? 'container__sticky-header modal-header' : 'container__sticky-header--empty-div'
      }
    >
      {!publicView &&
      ((['curator'].includes(role) && photo.project.submitted) ||
        (['appadmin'].includes(role) &&
          photo.project.submitted &&
          photo.project.cohort.reviewed)) ? (
        <div className='fsm-screen-resolution-container'>
          <PhotoActionBar
            photo={photo}
            role={role}
            photoAwards={photo.awards}
            forStatusStack={false}
            setShouldRefetchParent={props.setShouldRefetchParent}
            updateParentGallery={props.updateParentGallery}
            disabled={awardChipDisabled}
          />
        </div>
      ) : null}

      {!publicView ? (
        <div className='fsm-photo-comment-header-section'>
          {photo.photoComments && photo.photoComments.length > 0 ? (
            <div className='fsm-photo-comment-header-section-icon'>
              <span className='fsm-comment-box-icon'>
                <MessageSquare />
              </span>
            </div>
          ) : null}
          <div className='fsm-photo-comment-header-section-timestamp'>
            <div className='photo-details-header-timestamp'>
              {`${
                photo && photo.updatedAt
                  ? 'Updated on ' + DateUtils.createMoment(photo.updatedAt).format('LL')
                  : ''
              }`}
            </div>
            <div className='fsm-screen-resolution-text'>
              {photo.exifData.hasOwnProperty('width')
                ? photo.exifData.width + ' x ' + photo.exifData.height
                : ''}
            </div>
          </div>
        </div>
      ) : null}
    </Box>
  )
}

export default PhotoDetailsHeader
