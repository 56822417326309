import { Box } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import ModelReleaseModal from '../../../molecules/ModelReleaseModal'
import ModelReleasesListRowSection from './ModelReleasesListRowSection'

const ModalReleaseListSection= props => {
    const {role, photoId, photoDetails, modelReleases, activeModelReleases, totalMrRequired, studentDetails} = props

    const [showNewModel, setShowNewModel] = useState(false)
    const [existingMrUuid, setExistingMrUuid] = useState(null)
    const [modelOp, setModelOp] = useState('add')
    const [notAddedMrCount, setNotAddedMrCount] = useState(0)

    useEffect(() => {
      setNotAddedMrCount(props.notAddedMrCount)
    }, [props.notAddedMrCount])

    useEffect(() => {
      props?.setIsModalOpen?.(showNewModel)
    }, [showNewModel])
    

const getRelatedMrForActiveMr = mrUuid => {
  return modelReleases
    .filter(mr => mr.relatedToMr === mrUuid || mr.mrStatus === 'Expired')
    .sort(
      (a, b) =>
        new Date(...b.createdAt.split('/').reverse()) -
        new Date(...a.createdAt.split('/').reverse())
    )
}
    return (
      <Box
        component='div'
        className='model-release__list-section'
        sx={{
          mt:{xs: 5, sm: 3, md: 4},
        }}
      >
        <Fragment>
          {activeModelReleases.length > 0
            ? activeModelReleases.map(mr => (
                <ModelReleasesListRowSection
                  key={`mr-${mr.uuid}`}
                  role={role}
                  modelName={
                    mr.modelFirstName
                      ? mr.modelFirstName + ' ' + mr.modelLastName
                      : '[Signed Model Release]'
                  }
                  toggleEditing={props.toggleEditing}
                  photoId={photoId}
                  refetchPhoto={props.refetchPhoto}
                  refetchMrData={props.refetchMrData}
                  modelRelease={mr}
                    allRelatedMrs={getRelatedMrForActiveMr(mr.uuid)}
                  showMrModel={(opType, mrUuid = null) => {
                    setShowNewModel(true)
                    setModelOp(opType)
                    setExistingMrUuid(mrUuid)
                  }}
                  studentDetails={props.studentDetails}
                  modelUuid={mr.uuid}
                />
              ))
            : null}
          {notAddedMrCount > 0
            ? [...Array(notAddedMrCount)].map((newMr, index) => (
                <ModelReleasesListRowSection
                  role={role}
                  modelName={`Model ${index + 1}`}
                  toggleEditing={props.toggleEditing}
                  photoId={photoId}
                  refetchPhoto={props.refetchPhoto}
                  refetchMrData={props.refetchMrData}
                  showMrModel={(opType, mrUuid = null) => {
                    setShowNewModel(true)
                    setModelOp(opType)
                    setExistingMrUuid(mrUuid)
                  }}
                />
              ))
            : null}
          <ModelReleaseModal
            show={showNewModel}
            onHide={() => setShowNewModel(false)}
            modelOp={modelOp}
            photoId={photoId}
            onSuccessCallback={() =>setShowNewModel(false)}
            refetchPhoto={props.refetchPhoto}
            refetchMrData={props.refetchMrData}
            studentDetails={{student: {...studentDetails}}}
            existingMrUuid={existingMrUuid}
          />
        </Fragment>
      </Box>
    )
}

export default ModalReleaseListSection