import React, { Component, Fragment } from 'react'
import '../elements/formfield.scss'
import Button from '../elements/Button.js'
import PasswordFormField from '../elements/PasswordFormField.js'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Box, Container, Grid, Typography } from '@mui/material'
import '../base/global.scss'
const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePasswordMutation($oldPassword: String!, $newPassword: String!) {
    updateAccountPassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      errors {
        path
        message
      }
    }
  }
`

class PasswordSecurity extends Component {
  constructor(props) {
    super(props)
    this.userStore = this.props.userStore
    this.state = { errors: {} }
    this.handleDeactivateAccount = this.handleDeactivateAccount.bind(this)
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this)
  }

  handleDeactivateAccount(e, onMutate) {
    e.preventDefault()
    this.setState({
      errors: { ...this.state.errors, newPasswordError: null, oldPasswordError: null }
    })

    onMutate({
      variables: {
        id: this.props.id,
        password: this.state.disableAccountPassword
      },
      refetchQueries: [`Profile`]
    })
  }
  checkDeactivateAccountRespForErrors(response) {
    if (response.updateAccountDisabledAt.errors) {
      this.setState({
        errors: {
          ...this.state.errors,
          deactivateAccountPassword: response.updateAccountDisabledAt.errors
            ? response.updateAccountDisabledAt.errors.message
            : null
        }
      })
    } else {
      if (this.props.accountDisabledAt) {
        this.props.showToast('success', 'Your account was reactivated.')
      } else {
        this.props.showToast('error', 'Your account was deactivated.')
      }
      this.setState({
        id: null,
        disableAccountPassword: ''
      })
    }
  }

  handleUpdatePassword(e, onMutate) {
    e.preventDefault()

    const { id } = this.props
    const { oldPassword, newPassword, passwordConfirmation } = this.state

    const passwordLengthError = 'Passwords need to be at least 8 characters long.'
    const passwordMismatchError = 'Passwords do not match.'
    if (newPassword !== passwordConfirmation && newPassword.length < 8) {
      this.setState({
        errors: {
          ...this.state.errors,
          newPasswordError: passwordLengthError + ' ' + passwordMismatchError
        }
      })
    }

    if (newPassword !== passwordConfirmation && newPassword.length > 8) {
      this.setState({ errors: { ...this.state.errors, newPasswordError: passwordMismatchError } })
    }

    if (newPassword === passwordConfirmation && newPassword.length < 8) {
      this.setState({ errors: { ...this.state.errors, newPasswordError: passwordLengthError } })
    }

    if (newPassword === passwordConfirmation && newPassword.length >= 8) {
      this.setState({
        errors: { ...this.state.errors, newPasswordError: null, oldPasswordError: null }
      })
      onMutate({
        variables: {
          id,
          oldPassword,
          newPassword
        },
        refetchQueries: [`Profile`]
      })
    }
  }
  checkUpdatePasswordRespForErrors(response) {
    if (response.updateAccountPassword.errors) {
      this.setState({
        errors: {
          ...this.state.errors,
          oldPasswordError: response.updateAccountPassword.errors
            ? response.updateAccountPassword.errors.message
            : null
        }
      })
    } else {
      this.props.showToast('success', 'Password updated.')
      this.setState({
        id: null,
        oldPassword: '',
        newPassword: '',
        passwordConfirmation: '',
        errors: { ...this.state.errors, newPasswordError: null, oldPasswordError: null }
      })
      const container = document.querySelector('.my-account__password-security--reset-password')
      Array.from(container.querySelectorAll('input')).map(input => (input.value = ''))
    }
  }

  validateChange(e) {
    const fieldName = e.target.name
    const value = e.target.value
    switch (fieldName) {
      case 'newPassword':
        this.validatePassword(value, 'newPassword')
        break
      case 'passwordConfirmation':
        this.validatePassword(value, 'passwordConfirmation')
        break
      default:
        return
    }
  }
  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : e.target.checked })
    this.validateChange(e)
  }

  validatePassword(value, element = 'newPassword') {
    const { newPassword, passwordConfirmation } = this.state

    const passwordLengthError = 'Passwords need to be at least 8 characters long.'
    const passwordMismatchError = 'Passwords do not match.'
    const length = element === 'newPassword' ? newPassword.length : passwordConfirmation.length

    this.setState({ [`${element}Error`]: '' })
    if (newPassword && length < 8 && element === 'newPassword') {
      this.setState({ [`${element}Error`]: passwordLengthError })
    } else if (newPassword !== passwordConfirmation && passwordConfirmation && length < 8) {
      this.setState({
        passwordConfirmationError: passwordLengthError + ' ' + passwordMismatchError
      })
    } else if (newPassword !== passwordConfirmation && passwordConfirmation && length >= 8) {
      this.setState({ passwordConfirmationError: passwordMismatchError })
    } else if (newPassword === passwordConfirmation && length < 8) {
      this.setState({ [`${element}Error`]: passwordLengthError })
    } else if (newPassword === passwordConfirmation && length >= 8) {
      this.setState({ newPasswordError: '', passwordConfirmationError: '' })
    }
  }
  render() {
    return (
      <Box
        sx={{
          backgroundColor: '#f7f7f7',
          py: { xs: 5, md: 6, lg: 10 },
          px: { xs: 4, md: 6 }
        }}
      >
        <Typography variant='h1' component='p' className='bold'>
          Change Password
        </Typography>
        <form className='my-account__form-container' noValidate autoComplete='off'>
          <div className='my-account__password-security'>
            <Mutation
              mutation={UPDATE_PASSWORD_MUTATION}
              onCompleted={data => {
                this.checkUpdatePasswordRespForErrors(data)
              }}
            >
              {(onMutate, { data }) => {
                return (
                  <Box
                    sx={{
                      borderBottom: '0.0625rem solid #d5d5d7',
                      mb: 6,
                      pb: 6,
                      pt: 3
                    }}
                  >
                    <Typography variant='h2' className='semi-strong' sx={{ mb: { xs: 4 } }}>
                      Confirm Current Password
                    </Typography>

                    <Grid container columnSpacing={2}>
                      <Grid item xs={12} md={6} className='custom-input-bg'>
                        <PasswordFormField
                          PasswordFormFieldValue={this.state.oldPassword}
                          onChange={event => this.setState({ oldPassword: event.target.value })}
                          passwordFormFieldName='oldPassword'
                          passwordFormFieldId='outlined-adornment-current-password'
                          passwordFormFieldVariant='filled'
                          passwordFormFieldAutoComplete='off'
                          passwordFormFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          passwordFormFieldLabel='Current Password'
                          formFieldError={this.state.errors.oldPasswordError || null}
                        />
                      </Grid>
                    </Grid>
                    <Typography
                      variant='h2'
                      className='semi-strong'
                      sx={{ mt: { xs: 6 }, mb: { xs: 4 } }}
                    >
                      Enter New Password
                    </Typography>

                    <Grid container rowGap={5} columnSpacing={2}>
                      <Grid item xs={12} md={6} className='custom-input-bg'>
                        <PasswordFormField
                          PasswordFormFieldValue={this.state.newPassword}
                          passwordFormFieldName='newPassword'
                          passwordFormFieldId='newPassword'
                          passwordFormFieldType='password'
                          passwordFormFieldVariant='filled'
                          passwordFormFieldAutoComplete='off'
                          passwordFormFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          passwordFormFieldLabel='New Password'
                          onChange={event => {
                            this.setState({ newPassword: event.target.value }, () =>
                              this.handleFormChange(event)
                            )
                          }}
                          formFieldError={
                            this.state.newPasswordError ? this.state.newPasswordError : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className='custom-input-bg'>
                        <PasswordFormField
                          PasswordFormFieldValue={this.state.passwordConfirmation}
                          onChange={event =>
                            this.setState({ passwordConfirmation: event.target.value }, () =>
                              this.handleFormChange(event)
                            )
                          }
                          passwordFormFieldName='passwordConfirmation'
                          passwordFormFieldId='passwordConfirmation'
                          passwordFormFieldVariant='filled'
                          passwordFormFieldType='password'
                          passwordFormFieldAutoComplete='off'
                          passwordFormFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          passwordFormFieldLabel='Repeat New Password'
                          formFieldError={
                            this.state.passwordConfirmationError
                              ? this.state.passwordConfirmationError
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Box
                          mt={{
                            xs:
                              this.state.newPasswordError || this.state.passwordConfirmationError
                                ? '10px'
                                : '40px'
                          }}
                        >
                          <Button
                            onClick={e => this.handleUpdatePassword(e, onMutate)}
                            type='submit'
                            disabled={
                              !(
                                this.state.oldPassword &&
                                this.state.oldPassword.trim() &&
                                this.state.newPassword &&
                                this.state.newPassword.trim() &&
                                this.state.passwordConfirmation &&
                                this.state.passwordConfirmation.trim() &&
                                !(
                                  this.state.newPasswordError ||
                                  this.state.passwordConfirmationError
                                )
                              )
                            }
                            title='Save Updates'
                            sx={{ width: { xs: '100%', sm: '33%', md: 'auto' } }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )
              }}
            </Mutation>
            <Box>
              <Typography variant='h2' component='h2'>
                Delete Account
              </Typography>
              <Typography variant='paragraph' component='p' sx={{ mt: 2 }}>
                To delete your account, please contact Working Assumptions at{' '}
                <a href='mailto:education@workingassumptions.org/?subject=Please Delete My Account '>
                  {' '}
                  education@workingassumptions.org
                </a>
                .
              </Typography>
            </Box>
          </div>
        </form>
      </Box>
    )
  }
}

export default PasswordSecurity
