import React, { Component, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { Trash2, MinusSquare, PlusSquare, Download, Eye } from 'react-feather'
import { gql } from '@apollo/client'
import DeletePhotoModal from '../molecules/DeletePhotoModal.js'
import _ from 'lodash'
import { Mutation } from '@apollo/client/react/components'
import moment from 'moment'
import DownloadUtils from '../../utils/DownloadUtils.js'
import { getFileTitleForDownload } from '../../utils/commonFunctions'
import GATag from '../../utils/GATag.js'
import { Typography, Box } from '@mui/material'
import { useMutation } from '@apollo/client'

const MUTATION = gql`
  mutation TogglePhotoShortlist($id: ID!, $is_short_listed: Boolean!) {
    togglePhotoShortlist(id: $id, isShortListed: $is_short_listed) {
      id
    }
  }
`

const CLASS_SHARE_MUTATION = gql`
  mutation TogglePhotoShare($id: ID!, $class_share: Boolean!) {
    togglePhotoShare(id: $id, classShare: $class_share) {
      id
    }
  }
`

const PhotoOverlay = props => {
  const { userStore, page } = props

  const [role] = useState(userStore.role)
  const [modalShow, setModalShow] = useState(false)
  const [photo, setPhoto] = useState(props.photo)
  const [photoShare, setPhotoShare] = useState(props.photo.classShare ? true : false)
  const [isFinal, setIsFinal] = useState(props.photo.isShortListed ? true : false)

  const classDetail = window.location.pathname.includes('class-detail')
  const classReview = window.location.pathname.includes('class-review')
  const awardedPhotosPage = window.location.pathname.includes('awarded-photos')

  const collectionView =
    window.location.pathname.includes('collection') ||
    window.location.pathname.includes('national') ||
    window.location.pathname.includes('covid-collection')
  const projectDetail = window.location.pathname.includes('project-detail')
  const classTracker = window.location.pathname.includes('class-tracker')

  useEffect(() => {
    setPhoto(props.photo)
    setPhotoShare(props.photo.classShare ? true : false)
    setIsFinal(props.photo.isShortListed ? true : false)
  }, [props.photo])

  const [photoShortlistMutation] = useMutation(MUTATION, {
    onCompleted: data => {
      props?.updateParentState?.(data.togglePhotoShortlist.id)
      if (isFinal) {
        props?.showToast?.('success', 'Photograph moved to final photograph list.')
      } else {
        props?.showToast?.('success', 'Photograph removed from final photograph list.')
      }
      props?.refetchProject?.()
    },
    refetchQueries: [`ProjectDetails`]
  })

  const [onShareMutate] = useMutation(CLASS_SHARE_MUTATION, {
    onCompleted: () => {
      props?.updateClassSharePhotosCount?.(photoShare)
    },
    refetchQueries: [`ProjectDetails`]
  })

  const handleSubmit = (photoId, isShortListed) => {
    setIsFinal(isShortListed)
    photoShortlistMutation({
      variables: {
        id: photoId,
        is_short_listed: isShortListed
      }
    })
  }

  const submitRatingChange = (e, onMutate, photoId, rating) => {
    onMutate({
      variables: {
        photoId: parseInt(photoId),
        rating: rating
      },
      refetchQueries: [`UserStore`]
    })
  }

  const handleShare = (photoId, share) => {
    setPhotoShare(share)
    onShareMutate({
      variables: {
        id: photoId,
        class_share: share
      }
    })
  }

  const handleDownload = async () => {
    const { downloadUrl, originalTitle, exifData, project } = photo
    const { fileType } = exifData
    const year = moment(project.cohort.endDate).format('YYYY')
    const name = project.student.name
      .trim()
      .replace(/\s+/g, ' ')
      .split(' ')
      .join('-')
      .toLowerCase()

    let fileTitle = ''
    if (originalTitle) {
      const tempTitle = getFileTitleForDownload(originalTitle)
      fileTitle = (tempTitle.length > 100 ? (tempTitle.substring(0, 100)).slice(0, -1)  :  tempTitle) + '-'
    }
    const photoTitle = `working-assumptions-${name}-${fileTitle}${year.toLowerCase()}.${fileType}`
    GATag.setEvent({
      category: 'image',
      action: 'downloaded-thumbnail',
      label: 'downloaded thumbnail',
      value: 1
    })
    DownloadUtils.downloadFileAndSaveAs(downloadUrl, photoTitle)
  }

  const getStudentName = photo => {
    const classReview = window.location.pathname.includes('class-review')
    const nationalPhotos = window.location.pathname.includes('national-photos')
    const projectDetail = window.location.pathname.includes('project-detail')
    const classTracker = window.location.pathname.includes('class-tracker')
    const publicSite =
      window.location.pathname.includes('collection') ||
      window.location.pathname.includes('covid-collection')
    const awardPage = window.location.pathname.includes('awarded-photos')
    if (
      (classReview && ['curator', 'appadmin'].includes(role)) ||
      (projectDetail && ['curator'].includes(role)) ||
      ((classTracker || awardPage) && ['appadmin', 'facilitator', 'teacher'].includes(role)) ||
      (props?.page === 'class-tracker' && ['appadmin', 'facilitator', 'teacher'].includes(role)) ||
      nationalPhotos ||
      publicSite
    ) {
      return (
        <Typography variant='h5'>{photo.attribution.replace('NotSupplied', '').trim()}</Typography>
      )
    }
  }

  const getUpdateDate = photo => {
    const projectDetail = window.location.pathname.includes('project-detail')
    if (projectDetail && ['appadmin', 'facilitator', 'teacher', 'student'].includes(role)) {
      return (
        <Typography variant='h5'>
          {photo.updatedAt ? `Updated ${moment(photo.updatedAt).fromNow()}` : null}
        </Typography>
      )
    }
  }

  const getDownloadIcon = photo => {
    const awardedPhotosPage = window.location.pathname.includes('awarded-photos')
    const classReview = window.location.pathname.includes('class-review')
    const nationalPhotos = window.location.pathname.includes('national-photos')
    const projectDetail = window.location.pathname.includes('project-detail')
    const classTracker = window.location.pathname.includes('class-tracker')
    if (
      ['appadmin'].includes(role) &&
      (awardedPhotosPage ||
        classReview ||
        nationalPhotos ||
        projectDetail ||
        classTracker ||
        page === 'class-tracker')
    ) {
      return (
        <div className='photo-container__hover-content--download' onClick={handleDownload}>
          <Download size={20} />
        </div>
      )
    }
  }

  const getTrashIcon = photo => {
    const projectDetail = window.location.pathname.includes('project-detail')

    if (!photo.isShortListed && projectDetail && ['appadmin', 'student'].includes(role)) {
      return (
        <div className='photo-container__hover-content--top-right'>
          <h3 className='h3--strong photo-overlay__trash-icon' onClick={() => setModalShow(true)}>
            <Trash2 size={24} />
          </h3>
          <DeletePhotoModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            onSuccessCallback={() => setModalShow(false)}
            showToast={props?.showToast}
            updateParentState={props?.updateParentState}
            photo={photo}
            refetchProject={() => {
              props?.refetchProject?.()
              props?.setRefetchAllSection?.(true)
            }}
          />
        </div>
      )
    }
  }

  const getClassShareUnshareButtons = photo => {
    const { page, projectSubmitted } = props
    const projectDetail = window.location.pathname.includes('project-detail')
    const classTracker = window.location.pathname.includes('class-tracker')

    if (
      (projectDetail && ['appadmin', 'facilitator', 'teacher', 'student'].includes(role)) ||
      (classTracker && ['appadmin', 'facilitator', 'teacher'].includes(role)) ||
      (page === 'class-tracker' && ['appadmin', 'facilitator', 'teacher'].includes(role))
    ) {
      const cls =
        !projectSubmitted &&
        projectDetail &&
        ['appadmin', 'student'].includes(role) &&
        !photo.submitted
          ? 'photo-container__hover-content--bottom-left-above'
          : 'photo-container__hover-content--bottom-left'
      return (
        <>
          {_.includes(['student', 'teacher', 'appadmin', 'facilitator'], role) ? (
            <div className={cls}>
              {photoShare ? (
                <Typography
                  variant='h5'
                  className='semi-strong'
                  onClick={
                    _.includes(['student', 'teacher', 'facilitator', 'appadmin'], role)
                      ? () => handleShare(photo.id, false)
                      : null
                  }
                >
                  <span className='icon-text-span'>
                    <Eye size={24} fill={'#ffffff'} /> Unshare with Class
                  </span>
                </Typography>
              ) : (
                <Typography
                  variant='h5'
                  className='semi-strong'
                  onClick={
                    _.includes(['student', 'teacher', 'facilitator', 'appadmin'], role)
                      ? () => handleShare(photo.id, true)
                      : null
                  }
                >
                  <span className='icon-text-span'>
                    <Eye size={24} /> Share with Class
                  </span>
                </Typography>
              )}
            </div>
          ) : null}
        </>
      )
    }
  }

  const getShortlistRemoveIcon = photo => {
    const { projectSubmitted } = props
    const projectDetail = window.location.pathname.includes('project-detail')
    if (
      !projectSubmitted &&
      projectDetail &&
      ['appadmin', 'student'].includes(role) &&
      !photo.submitted
    ) {
      return (
        <>
          <div className='photo-container__hover-content--bottom-left'>
            {photo.isShortListed ? (
              <Typography
                variant='h5'
                className='semi-strong'
                onClick={
                  !photo.submitted &&
                  _.includes(['student', 'appadmin'], role) &&
                  !props.isCohortEnded
                    ? () => handleSubmit(photo.id, false)
                    : null
                }
              >
                <span className='icon-text-span'>
                  <MinusSquare size={24} /> Remove from Shortlist
                </span>
              </Typography>
            ) : (
              <Typography
                variant='h5'
                className='semi-strong'
                onClick={
                  !photo.submitted &&
                  _.includes(['student', 'appadmin'], role) &&
                  !props.isCohortEnded
                    ? () => handleSubmit(photo.id, true)
                    : null
                }
              >
                <span className='icon-text-span'>
                  <PlusSquare size={24} /> Move to Shortlist
                </span>
              </Typography>
            )}
          </div>
        </>
      )
    }
  }

  return (
    <Box
      component='div'
      title={photo.altText}
      className={
        props.overlayVariant === 'grid-gallery'
          ? `photo-container__hover-content--grid-gallery classReview`
          : 'photo-container__hover-content classReview'
      }
    >
      {getDownloadIcon(photo)}

      <div className='photo-container__hover-content--photo-title'>
        {props.photoOnClick ? (
          <Typography
            variant='h3'
            className='semi-strong'
            sx={{
              color: 'inherit',
              pb: 0
            }}
            onClick={() => props?.photoOnClick?.(photo)}
          >
            {photo.project.submitted
              ? photo.originalTitle === '' ||
                photo.originalTitle === null ||
                photo.originalTitle === undefined
                ? 'Untitled Photograph'
                : photo.originalTitle?.length <= 20
                ? photo.originalTitle
                : photo.originalTitle.substring(0, 20) + '...'
              : photo.title === '' || photo.title === null || photo.title === undefined
              ? 'Untitled Photograph'
              : photo.title?.length <= 20
              ? photo.title
              : photo.title.substring(0, 20) + '...'}
          </Typography>
        ) : (
          <Link to={`/photo-detail/${photo.id}`}>
            <Typography
              variant='h3'
              className='semi-strong'
              sx={{
                color: 'inherit'
              }}
            >
              {photo.project.submitted
                ? photo.originalTitle === '' || photo.originalTitle === null
                  ? 'Untitled Photograph'
                  : photo.originalTitle
                : photo.title === '' || photo.title === null
                ? 'Untitled Photograph'
                : photo.title}
            </Typography>
          </Link>
        )}

        {getUpdateDate(photo)}

        {getStudentName(photo)}
      </div>

      {getTrashIcon(photo)}

      {getClassShareUnshareButtons(photo)}

      {getShortlistRemoveIcon(photo)}

      {(classDetail ||
        classReview ||
        collectionView ||
        projectDetail ||
        classTracker ||
        awardedPhotosPage ||
        props.page === 'class-tracker') &&
      props.photoOnClick ? (
        <div
          className='photo-container__hover-content--clickable-area'
          onClick={() => props.photoOnClick(photo)}
        />
      ) : (
        <Link to={`/photo-detail/${photo.id}`}>
          <div className='photo-container__hover-content--clickable-area' />
        </Link>
      )}
    </Box>
  )
}

// class PhotoOverlay extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       modalShow: false,
//       photo: props.photo,
//       photoShare: props.photo.classShare ? true : false,
//       isFinal: props.photo.isShortListed ? true : false
//     }
//     this.handleSubmit = this.handleSubmit.bind(this)
//     this.handleDownload = this.handleDownload.bind(this)
//     this.handleShare = this.handleShare.bind(this)
//   }

//   componentWillReceiveProps(nextProps) {
//     this.setState({ photo: nextProps.photo })
//   }

//   handleSubmit(onMutate, photoId, is_short_listed) {
//     this.setState({
//       isFinal: is_short_listed
//     })
//     onMutate({
//       variables: {
//         id: photoId,
//         is_short_listed: is_short_listed
//       }
//     })
//   }

//   submitRatingChange(e, onMutate, photoId, rating) {
//     onMutate({
//       variables: {
//         photoId: parseInt(photoId),
//         rating: rating
//       },
//       refetchQueries: [`UserStore`]
//     })
//   }

//   handleShare(onShareMutate, photoId, share) {
//     this.setState(
//       {
//         ...this.state,
//         photoShare: share
//       },
//       () => this.props.updateClassSharePhotosCount && this.props.updateClassSharePhotosCount(share)
//     )
//     onShareMutate({
//       variables: {
//         id: photoId,
//         class_share: share
//       },
//       refetchQueries: [`ProjectDetails`]
//     })
//   }

//   async handleDownload() {
//     const { photo } = this.props
//     const { downloadUrl, title, createdAt, exifData, project } = photo
//     const { fileType } = exifData
//     const year = moment(createdAt).format('YYYY')
//     const name = project.student.name
//       .trim()
//       .replace(/\s+/g, ' ')
//       .split(' ')
//       .join('-')
//       .toLowerCase()

//     let photoTitle = `working-assumptions-${name}-${
//       title ? getFileTitleForDownload(title) + '-' : ''
//     }${year.toLowerCase()}.${fileType}`
//     GATag.setEvent({
//       category: 'image',
//       action: 'downloaded-thumbnail',
//       label: 'downloaded thumbnail',
//       value: 1
//     })
//     DownloadUtils.downloadFileAndSaveAs(downloadUrl, photoTitle)
//   }

//   getStudentName = photo => {
//     const { userStore } = this.props
//     const role = userStore.role
//     const classReview = window.location.pathname.includes('class-review')
//     const nationalPhotos = window.location.pathname.includes('national-photos')
//     const projectDetail = window.location.pathname.includes('project-detail')
//     const classTracker = window.location.pathname.includes('class-tracker')
//     const publicSite =
//       window.location.pathname.includes('collection') ||
//       window.location.pathname.includes('covid-collection')
//     const awardPage = window.location.pathname.includes('awarded-photos')
//     if (
//       (classReview && ['curator', 'appadmin'].includes(role)) ||
//       (projectDetail && ['curator'].includes(role)) ||
//       ((classTracker || awardPage) && ['appadmin', 'facilitator', 'teacher'].includes(role)) ||
//       (this.props.page === 'class-tracker' &&
//         ['appadmin', 'facilitator', 'teacher'].includes(role)) ||
//       nationalPhotos ||
//       publicSite
//     ) {
//       return (
//         <Typography variant='h5'>{photo.attribution.replace('NotSupplied', '').trim()}</Typography>
//       )
//     }
//   }

//   getUpdateDate = photo => {
//     const { userStore } = this.props
//     const role = userStore.role
//     const projectDetail = window.location.pathname.includes('project-detail')
//     if (projectDetail && ['appadmin', 'facilitator', 'teacher', 'student'].includes(role)) {
//       return (
//         <Typography variant='h5'>
//           {photo.updatedAt ? `Updated ${moment(photo.updatedAt).fromNow()}` : null}
//         </Typography>
//       )
//     }
//   }

//   getDownloadIcon = photo => {
//     const { userStore, page } = this.props
//     const role = userStore.role
//     const awardedPhotosPage = window.location.pathname.includes('awarded-photos')
//     const classReview = window.location.pathname.includes('class-review')
//     const nationalPhotos = window.location.pathname.includes('national-photos')
//     const projectDetail = window.location.pathname.includes('project-detail')
//     const classTracker = window.location.pathname.includes('class-tracker')
//     if (
//       ['appadmin'].includes(role) &&
//       (awardedPhotosPage ||
//         classReview ||
//         nationalPhotos ||
//         projectDetail ||
//         classTracker ||
//         page === 'class-tracker')
//     ) {
//       return (
//         <div className='photo-container__hover-content--download' onClick={this.handleDownload}>
//           <Download size={20} />
//         </div>
//       )
//     }
//   }

//   getTrashIcon = photo => {
//     const { userStore } = this.props
//     const role = userStore.role
//     let modalClose = () => this.setState({ modalShow: false })
//     const projectDetail = window.location.pathname.includes('project-detail')

//     if (!photo.isShortListed && projectDetail && ['appadmin', 'student'].includes(role)) {
//       return (
//         <div className='photo-container__hover-content--top-right'>
//           <h3
//             className='h3--strong photo-overlay__trash-icon'
//             onClick={() => this.setState({ modalShow: true })}
//           >
//             <Trash2 size={24} />
//           </h3>
//           <DeletePhotoModal
//             show={this.state.modalShow}
//             onHide={modalClose}
//             onSuccessCallback={modalClose}
//             showToast={this.props.showToast}
//             updateParentState={this.props.updateParentState}
//             photo={photo}
//             refetchProject={() => {
//               this.props.refetchProject()
//               this.props.setRefetchAllSection(true)
//             }}
//           />
//         </div>
//       )
//     }
//   }

//   getClassShareUnshareButtons = photo => {
//     const { handleShare } = this
//     const { userStore, page, projectSubmitted } = this.props
//     const role = userStore.role
//     const projectDetail = window.location.pathname.includes('project-detail')
//     const classTracker = window.location.pathname.includes('class-tracker')

//     if (
//       (projectDetail && ['appadmin', 'facilitator', 'teacher', 'student'].includes(role)) ||
//       (classTracker && ['appadmin', 'facilitator', 'teacher'].includes(role)) ||
//       (page === 'class-tracker' && ['appadmin', 'facilitator', 'teacher'].includes(role))
//     ) {
//       const cls =
//         !projectSubmitted &&
//         projectDetail &&
//         ['appadmin', 'student'].includes(role) &&
//         !photo.submitted
//           ? 'photo-container__hover-content--bottom-left-above'
//           : 'photo-container__hover-content--bottom-left'
//       return (
//         <>
//           {_.includes(['student', 'teacher', 'appadmin', 'facilitator'], role) ? (
//             <Mutation
//               mutation={CLASS_SHARE_MUTATION}
//               onCompleted={() => {
//                 if (this.props.fetchCohortData) {
//                   this.props.fetchCohortData()
//                 }
//               }}
//             >
//               {(onShareMutate, { data }) => {
//                 return (
//                   <div className={cls}>
//                     {this.state.photoShare ? (
//                       <Typography
//                         variant='h5'
//                         className='semi-strong'
//                         onClick={
//                           _.includes(['student', 'teacher', 'facilitator', 'appadmin'], role)
//                             ? () => handleShare(onShareMutate, photo.id, false)
//                             : null
//                         }
//                       >
//                         <span className='icon-text-span'>
//                           <Eye size={24} fill={'#ffffff'} /> Unshare with Class
//                         </span>
//                       </Typography>
//                     ) : (
//                       <Typography
//                         variant='h5'
//                         className='semi-strong'
//                         onClick={
//                           _.includes(['student', 'teacher', 'facilitator', 'appadmin'], role)
//                             ? () => handleShare(onShareMutate, photo.id, true)
//                             : null
//                         }
//                       >
//                         <span className='icon-text-span'>
//                           <Eye size={24} /> Share with Class
//                         </span>
//                       </Typography>
//                     )}
//                   </div>
//                 )
//               }}
//             </Mutation>
//           ) : null}
//         </>
//       )
//     }
//   }

//   getShortlistRemoveIcon = (onMutate, photo) => {
//     const { handleSubmit } = this
//     const { userStore, projectSubmitted } = this.props
//     const role = userStore.role
//     const projectDetail = window.location.pathname.includes('project-detail')
//     if (
//       !projectSubmitted &&
//       projectDetail &&
//       ['appadmin', 'student'].includes(role) &&
//       !photo.submitted
//     ) {
//       return (
//         <>
//           <div className='photo-container__hover-content--bottom-left'>
//             {photo.isShortListed ? (
//               <Typography
//                 variant='h5'
//                 className='semi-strong'
//                 onClick={
//                   !photo.submitted &&
//                   _.includes(['student', 'appadmin'], role) &&
//                   !this.props.isCohortEnded
//                     ? () => handleSubmit(onMutate, photo.id, false)
//                     : null
//                 }
//               >
//                 <span className='icon-text-span'>
//                   <MinusSquare size={24} /> Remove from Shortlist
//                 </span>
//               </Typography>
//             ) : (
//               <Typography
//                 variant='h5'
//                 className='semi-strong'
//                 onClick={
//                   !photo.submitted &&
//                   _.includes(['student', 'appadmin'], role) &&
//                   !this.props.isCohortEnded
//                     ? () => handleSubmit(onMutate, photo.id, true)
//                     : null
//                 }
//               >
//                 <span className='icon-text-span'>
//                   <PlusSquare size={24} /> Move to Shortlist
//                 </span>
//               </Typography>
//             )}
//           </div>
//         </>
//       )
//     }
//   }

//   render() {
//     const { photo } = this.state
//     const classDetail = window.location.pathname.includes('class-detail')
//     const classReview = window.location.pathname.includes('class-review')
//     const awardedPhotosPage = window.location.pathname.includes('awarded-photos')

//     const collectionView =
//       window.location.pathname.includes('collection') ||
//       window.location.pathname.includes('national') ||
//       window.location.pathname.includes('covid-collection')
//     const projectDetail = window.location.pathname.includes('project-detail')
//     const classTracker = window.location.pathname.includes('class-tracker')
//     return (
//       <Mutation
//         mutation={MUTATION}
//         onCompleted={data => {
//           this.props.updateParentState(data.togglePhotoShortlist.id)
//           // if(this.props.refetchProject) {
//           //   this.props.refetchProject()
//           // }
//           if (this.state.isFinal) {
//             this.props.showToast('success', 'Photograph moved to final photograph list.')
//           } else {
//             this.props.showToast('success', 'Photograph removed from final photograph list.')
//           }
//           if (this.props.refetchProject) {
//             this.props.refetchProject()
//           }
//         }}
//       >
//         {(onMutate, { data }) => {
//           return (
//             <div
//               title={photo.altText}
//               className={
//                 this.props.overlayVariant === 'grid-gallery'
//                   ? `photo-container__hover-content--grid-gallery classReview`
//                   : 'photo-container__hover-content classReview'
//               }
//             >
//               {this.getDownloadIcon(photo)}

//               <div className='photo-container__hover-content--photo-title'>
//                 {this.props.photoOnClick ? (
//                   <Typography
//                     variant='h3'
//                     className='semi-strong'
//                     sx={{
//                       color: 'inherit',
//                       pb: 0
//                     }}
//                     onClick={() => this.props.photoOnClick(photo)}
//                   >
//                     {photo.project.submitted
//                       ? photo.originalTitle === '' || photo.originalTitle === null || photo.originalTitle === undefined
//                         ? 'Untitled Photograph'
//                         : photo.originalTitle?.length <= 20
//                         ? photo.originalTitle
//                         : photo.originalTitle.substring(0, 20) + '...'
//                       : photo.title === '' || photo.title === null || photo.title === undefined
//                       ? 'Untitled Photograph'
//                       : photo.title?.length <= 20
//                       ? photo.title
//                       : photo.title.substring(0, 20) + '...'}
//                   </Typography>
//                 ) : (
//                   <Link to={`/photo-detail/${photo.id}`}>
//                     <Typography
//                       variant='h3'
//                       className='semi-strong'
//                       sx={{
//                         color: 'inherit'
//                       }}
//                     >
//                       {photo.project.submitted
//                         ? photo.originalTitle === '' || photo.originalTitle === null
//                           ? 'Untitled Photograph'
//                           : photo.originalTitle
//                         : photo.title === '' || photo.title === null
//                         ? 'Untitled Photograph'
//                         : photo.title}
//                     </Typography>
//                   </Link>
//                 )}

//                 {this.getUpdateDate(photo)}

//                 {this.getStudentName(photo)}
//               </div>

//               {this.getTrashIcon(photo)}

//               {this.getClassShareUnshareButtons(photo)}

//               {this.getShortlistRemoveIcon(onMutate, photo)}

//               {(classDetail ||
//                 classReview ||
//                 collectionView ||
//                 projectDetail ||
//                 classTracker ||
//                 awardedPhotosPage ||
//                 this.props.page === 'class-tracker') &&
//               this.props.photoOnClick ? (
//                 <div
//                   className='photo-container__hover-content--clickable-area'
//                   onClick={() => this.props.photoOnClick(photo)}
//                 />
//               ) : (
//                 <Link to={`/photo-detail/${photo.id}`}>
//                   <div className='photo-container__hover-content--clickable-area' />
//                 </Link>
//               )}
//             </div>
//           )
//         }}
//       </Mutation>
//     )
//   }
// }

export default inject('userStore')(PhotoOverlay)
