import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import FormCheckbox from '../elements/FormCheckbox.js'
import { ChevronDown, Award, Shield, MessageSquare, ChevronUp } from 'react-feather'
import { withApollo } from '@apollo/client/react/hoc'
import PhotoOverlay from './PhotoOverlay.js'
import Loading from '../elements/Loading.js'
import DropdownMenu from '../elements/DropdownMenu.js'
import ResetPasswordModal from './ResetPasswordModal.js'
import { getStatusesForPage } from '../../content/textContent.js'
import { inject, observer } from 'mobx-react'
import ReactImageFallback from 'react-image-fallback'
import galleryPlaceholder from '../../assets/images/gallery-placeholder-dark.svg'
import { Box, Grid } from '@mui/material'
import { Typography } from '@mui/material'
import { PAGINATED_STUDENT_PHOTOS_BY_FILTER } from '../../queries/classTrackerQueries.js'
import { useQuery } from '@apollo/client'
import CustomPagination from './CustomPagination.js'
import NewFullScreenPhotoModal from '../organisms/PhotoDetailsModal/NewFullScreenPhotoModal.js'
import variables from '../settings/_variables.scss'
import DateUtils from '../../utils/DateUtils.js'

const BATCH_SIZE = 9

/* IMPLEMENT PHOTO COUNT PER FILTER AND FW GETADJACENTIMAGE TIL YOU GET IT */

const ClassTrackerStudentListRow = props => {
  const { photosStore, cohort, student, currentFilter, checked, userId, role, expandAll } = props
  const cohortId = cohort.id
  const project = student.projects.filter(project => project.cohort.id === cohortId)[0]

  const [totalPages, setTotalPages] = useState(0)
  const [pageNum, setPageNum] = useState(0)
  const [filterPhotoCount, setFilterPhotoCount] = useState(0)
  const [modalResetPassword, setModalResetPassword] = useState(false)
  const [showPhotoSection, setShowPhotoSection] = useState(expandAll)
  const [filteredPhotos, setFilteredPhotos] = useState([])
  const [selectedPhotoId, setSelectedPhotoId] = useState(-1)
  const [showPhotoModal, setShowPhotoModal] = useState(false)
  const [modalFadeOut, setModalFadeOut] = useState(false)

  const photoGridRef = useRef()

  const { loading } = useQuery(PAGINATED_STUDENT_PHOTOS_BY_FILTER, {
    variables: {
      batchSize: BATCH_SIZE,
      baseBatchSize: BATCH_SIZE,
      cohortId: cohortId,
      step: pageNum,
      id: student.id,
      filter: currentFilter
    },
    fetchPolicy: 'network-only',
    skip: !showPhotoSection,
    onCompleted(data) {
      let totalPhotos = data.filteredPhotosCountByStudentId
      setFilterPhotoCount(data.filteredPhotosCountByStudentId)
      setTotalPages(Math.ceil(totalPhotos / BATCH_SIZE))
      setFilteredPhotos(data.filteredPhotosByStudentId)
      const getIds = data.photoIds.map(photo => photo.id)
      photosStore.setPhotoStore(`studentTrackerRow${student.id}`, [...getIds])
    }
  })

  useEffect(() => {
    pageNum > 1 && !showPhotoModal && photoGridRef.current && photoGridRef.current.scrollIntoView()
  }, [pageNum, showPhotoModal])

  useEffect(() => {
    setPageNum(0)
  }, [props.currentFilter])

  useEffect(() => {
    setPageNum(0)
    setShowPhotoSection(props.expandAll)
  }, [props.expandAll])

  const thumbnailOnClick = photo => {
    setSelectedPhotoId(photo.id)
    setShowPhotoModal(true)
  }

  const getFinalAward = awards => {
    const aoe = 'Award of Excellence'
    const hm = 'Honorable Mention'
    let aoeAwardsCount = awards.filter(award => award.awardClass === aoe).length
    let hmAwardCount = awards.filter(award => award.awardClass === hm).length
    let finalAward =
      aoeAwardsCount > hmAwardCount
        ? aoe
        : hmAwardCount > aoeAwardsCount
        ? 'Honorable Mention'
        : aoeAwardsCount && hmAwardCount && aoeAwardsCount === hmAwardCount
        ? 'Award of Excellence'
        : ''

    return [aoe, hm].includes(finalAward) ? (
      <Box className='photo-conainer--award'>
        <Typography variant='h4'>
          <span className='photo-container__hover-content--top-right---overlay'>
            {finalAward === aoe ? (
              <Award size={16} />
            ) : finalAward === hm ? (
              <Shield size={16} />
            ) : null}
          </span>
        </Typography>
      </Box>
    ) : null
  }

  return (
    <Box
      className='class-tracker__student-list__row'
      sx={{ mt: { lg: 1 }, py: { xs: 3, md: 4 }, pr: { md: 2, lg: 2 }, pl: { xs: 3, md: 2 } }}
      ref={photoGridRef}
    >
      <Grid container spacing={{ md: 1, lg: 2 }}>
        <Grid item xs={1} sx={{ alignSelf: 'center', textAlign: { md: 'end', lg: 'center' } }}>
          <FormCheckbox
            checked={checked}
            onChange={() => props.onChecked(userId)}
            value='checkedReminder'
            sxcheckbox={{
              '& .MuiSvgIcon-root': {
                fontSize: { xs: 24 }
              }
            }}
          />
        </Grid>

        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <Link to={`/project-detail/${project && project.id}`}>
                <Typography variant='link' className='large'>
                  {student.name.replace('NotSupplied', '').trim()}
                </Typography>
              </Link>

              <Typography variant='h4' className='table_row' sx={{ mt: { xs: 1, md: 2 } }}>
                {/* {`${project.photos.length} Photographs Uploaded`} */}
                {`${project.photosCounter.uploaded} Photographs Uploaded`}
              </Typography>
            </Grid>
            <Grid item display={{ xs: 'none', lg: 'block' }} lg={4}>
              <Typography variant='h4' className='table_row' sx={{ pl: { lg: 2 } }}>
                {/* {`${project.photos.filter(img => img.isShortListed).length}`} Photographs in
                Shortlist */}
                {`${project.photosCounter.shortlist}`} Photographs in Shortlist
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mt: { xs: 1, md: 0 }, pl: { lg: 2 } }} lg={4}>
              <Typography variant='h4' className='table_row' display={{ lg: 'none' }}>
                {/* {`${project.photos.filter(img => img.isShortListed).length}`} Photographs in
                Shortlist */}
                {`${project.photosCounter.shortlist}`} Photographs in Shortlist
              </Typography>
              <Box display={{ xs: 'none', md: 'block' }} sx={{ mt: { md: 2, lg: -1 } }}>
                {getStatusesForPage('CLASSDETAILS_STUDENTLIST', project, props.userStore)}
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ mt: { xs: 2 } }} display={{ md: 'none' }}>
              {getStatusesForPage('CLASSDETAILS_STUDENTLIST', project, props.userStore)}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2}>
          <Box display='flex' component={'div'} sx={{ float: 'right' }}>
            <DropdownMenu
              studentUserId={student.userId}
              studentId={student.id}
              student={student}
              cohortId={cohortId}
              cohortName={cohort.name}
              cohortEndDate={cohort.endDate}
              isClassEnded={DateUtils.isDatePassed(cohort.endDate)}
              showToast={props.showToast}
              openResetModal={e => {
                e.preventDefault()
                setModalResetPassword(true)
              }}
              role={role}
              isClassPublished={props.isClassPublished}
              projectId={project.id}
              projectSubmitted={project.submitted}
              enableRemove={
                project.photosCounter.uploaded && props.role !== 'appadmin' ? false : true
              }
              photoCount={project.photosCounter.uploaded}
            />
            <ResetPasswordModal
              show={modalResetPassword}
              onHide={() => setModalResetPassword(false)}
              studentId={student.userId}
              showToast={props.showToast}
            />
            <Box
              onClick={() => setShowPhotoSection(!showPhotoSection)}
              sx={{
                ml: 2,
                cursor: 'pointer',
                display: { xs: 'none', md: 'block' },
                svg: { verticalAlign: 'middle' }
              }}
            >
              {!showPhotoSection ? <ChevronDown size={16} /> : <ChevronUp size={16} />}
            </Box>
          </Box>
        </Grid>

        {showPhotoSection && (
          <Box sx={{ mt: 4, px: 4, width: '100%', display: { xs: 'none', md: 'block' } }}>
            {loading ? (
              <Box sx={{ p: 4, minHeight: 12, ml: 4, textAlign: 'center' }}>
                <Loading inContainer />
              </Box>
            ) : filteredPhotos.length > 0 ? (
              <>
                <Grid container columnSpacing={2} rowGap={4}>
                  {filteredPhotos.map((photo, index) => (
                    <Grid
                      pb={0}
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={`${Math.floor(Math.random() * Math.random()) +
                        photo.id +
                        index}-student`}
                    >
                      <Box className='gallery__photo-container'>
                        {photo.awards &&
                        (!['teacher', 'facilitator'].includes(role) ||
                          (['teacher', 'facilitator'].includes(role) && photo.summarySent))
                          ? getFinalAward(photo.awards)
                          : null}
                        <PhotoOverlay
                          photo={photo}
                          photoOnClick={() => thumbnailOnClick(photo)}
                          isCohortEnded={DateUtils.isDatePassed(cohort.endDate)}
                          page='class-tracker'
                          fetchCohortData={props.fetchCohortData}
                          cohort={cohort}
                        />
                        <ReactImageFallback
                          src={photo.sizedUrl || photo.url}
                          fallbackImage='https://i.pinimg.com/originals/9b/96/92/9b9692c9f0db9f6276e6bd29a98c25e0.png'
                          initialImage={galleryPlaceholder}
                          alt={photo.altText}
                          key={photo.id}
                          className='photo photo_letterbox_type'
                          onClick={() => thumbnailOnClick(photo)}
                        />
                        {photo.photoComments.length > 0 &&
                        ['appadmin', 'curator'].includes(role) ? (
                          <div className='message-notifier__photo-overlay--shortList'>
                            <MessageSquare />
                          </div>
                        ) : null}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Box sx={{ mt: 4 }}>
                  <CustomPagination
                    totalPages={totalPages}
                    pageNum={pageNum + 1}
                    onChange={setPageNum}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    p: 4,
                    minHeight: 12,
                    border: `1px solid ${variables.lightGrey}`,
                    ml: 4,
                    backgroundColor: `${variables.backgroundGrey}`
                  }}
                >
                  <Typography component={'p'} variant='paragraph' sx={{ textAlign: 'center' }}>
                    This student has no photographs matching the selected filters.
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        )}
        {selectedPhotoId !== -1 ? (
          <NewFullScreenPhotoModal
            show={showPhotoModal}
            closeModal={() => {
              setModalFadeOut(true)
              setTimeout(() => {
                setShowPhotoModal(false)
                setModalFadeOut(false)
                setSelectedPhotoId(-1)
              }, 500)
            }}
            photoId={selectedPhotoId}
            isModal={true}
            placeholderDark={true}
            showToast={props.showToast}
            totalCount={filterPhotoCount}
            componentCallFrom={`studentTrackerRow${student.id}`}
            toggleParentModalState={(pushState = true, shouldRefetch = false) => {
              setModalFadeOut(true)
              // setFadeOut(false)
              setTimeout(() => {
                setShowPhotoModal(false)
                setSelectedPhotoId(-1)
                setModalFadeOut(false)
              }, 500)
            }}
            photoCurrentIndex={filteredPhotos.findIndex(elem => elem.id === selectedPhotoId)}
            animation={false}
            className={`animated ${
              showPhotoModal && !modalFadeOut ? 'fadeInUpBig' : 'fadeOutDownBig'
            }`}
          />
        ) : null}
      </Grid>
    </Box>
  )
}

export default inject('userStore', 'backToPageStore', 'photosStore')(
  observer(withApollo(ClassTrackerStudentListRow))
)
