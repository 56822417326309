import { Box, Typography } from '@mui/material';
import React from 'react';

const HeroSubheader = ({ subHeader, schoolName, cohortName }) => {
  return (
    <Box sx={{mt: {xs : 0.5, sm: 1}}}>
      {subHeader && <Typography component='h3' variant='dataLabel'>{subHeader}</Typography>}
      {schoolName && cohortName && <Typography component='h3' variant='dataLabel'>{`${schoolName} ${'\u2022'} ${cohortName}`}</Typography>}
    </Box>
  );
};

export default HeroSubheader;
