import { gql } from '@apollo/client'

export const AWARDED_PHOTOS = gql`
  query AwardedPhotos($id: ID!, $batchSize: Int, $baseBatchSize: Int, $step: Int, $award: String!) {
    awardedPhotos(
      id: $id
      batchSize: $batchSize
      baseBatchSize: $baseBatchSize
      step: $step
      award: $award )
    {
      id
      updatedAt
      caption
      title
      originalTitle
      originalCaption
      altText
      isShortListed
      submittedDate
      attribution
      url
      sizedUrl(screenWidth: 500)
      summarySent
      complete
      hasPeople
      submitted
      originalFileSize
      rawFileUrl
      rawFileName
      rawFileSize
      originalUrl
      cdnOriginalUrl
      downloadUrl
      sentStudentSummary
      mrAdminNotes
      totalMrRequired
      adminApproved
      covidRelated
      publishable
      published
      readyToPublish
      modelReleases {
        id
        uuid
        signed
        firstName
        lastName
        createdAt
        modelFirstName
        modelLastName
        linkToFile
        sentVia
        sentTo
        type
        sentViaSms
        sentViaEmail
        pdfUploaded
        isDeceased
        phoneNumber
        email
        lastSentOn
        isMinor
        signeeFirstName
        signeeLastName
        mrStatus
        adminDecision
        relatedToMr
        signedOn
        adminDecisionOn
        adminRejectionNote
      }
      exifData {
        size
        width
        height
        exposureProgram
        meteringMode
        iso
        lensModel
        focalLength
        cameraModel
        captureDate
        aperture
        shutterSpeedValue
        fileType
      }
      awards {
        id
        awardClass
        nominatorInitials
        nominatorUserId
        nominatorName
        isEdited
        editedOn
      }
      photoComments {
        body
        id
        commenterInitials
        commenterUserId
        commenterName
        createdAt
      }
      project {
        id
        reviewed
        submitted
        submittedUpdatedAt
        student {
          id
          name
          mobileNumber
          dob
          email
        }
        cohort {
          id
          endDate
          isReviewSend
          reviewed
          curatorReviews {
            id
            reviewerId
          }
        }
      }
      useAgreements {
        id
        linkToFile
        signed
        sentTo
        signeeFirstName
        signeeLastName
        sentVia
        uaStatus
        adminDecision
        adminRejectionNote
        adminDecisionOn
        signedon
        relatedUaUuid
        wantsUpdates
        email
        phoneNumber
        createdAt
        uuid
      }
    }
    awardedPhotosIds: awardedPhotos(
      id: $id
      award: $award
    ) {
      id
    }
  }
`
export const AWARDED_PHOTO_AMOUNT_QUERY = gql`
  query awardedPhotosCount(
    $id: ID!
    $award: String!
  ) {
    awardedPhotosCount(id: $id, award: $award)
  }
`