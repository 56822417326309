import React, { Component, Fragment } from 'react'
import Dropzone from 'react-dropzone'
import Button from '../elements/Button.js'
import { DirectUpload } from 'activestorage'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Box, Typography, Grid } from '@mui/material'
import { validationText } from '../../content/validationText.js'
import { truncateDetails } from '../../utils/commonFunctions'

const UPLOAD_PDF_USEAGREEMENT_MUTATION = gql`
  mutation CreateModelRelease(
    $photoId: ID!
    $modelReleaseType: String!
    $isMinor: Boolean
    $fileSignedId: String
  ) {
    createModelRelease(
      photoId: $photoId
      modelReleaseType: $modelReleaseType
      isMinor: $isMinor
      fileSignedId: $fileSignedId
    ) {
      id
    }
  }
`

const UPDATE_PDF_USEAGREEMENT_MUTATION = gql`
  mutation ManageModelRelease(
    $photoId: ID!
    $modelUuid: String!
    $manageType: String!
    $sendType: String
    $fileSignedId: String
  ) {
    manageModelRelease(
      photoId: $photoId
      modelUuid: $modelUuid
      manageType: $manageType
      sendType:$sendType
      fileSignedId: $fileSignedId
    ) {
      id
    }
  }
`

const resendModelRequest = (file, onMutate, upProps) => {
  const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
  const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'photo')

  upload.create((error, blob) => {
    if (error) {
      // Handle the error
    } else {
      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow
      onMutate({
        variables: {
          photoId: upProps.photoId,
          modelUuid:upProps.modelUuid,
          manageType:upProps.uploadType,
          sendType: 'pdf',
          fileSignedId: blob.signed_id,
        },
        refetchQueries: upProps.refetchQueries
      })
      // TODO redirect to photo detail page?
    }
  })
}

class   ModelReleaseUploader extends Component {
  photoId = this.props.photoId
  constructor(props) {
    super(props)
    this.state = {
      photoId: this.props.photoId,
      files:{}
    }
  }
  onDrop = (files, onMutate) => {
    // event.preventDefault()
    // const files = event.dataTransfer.files;
    this.setState({files:files})
    if(this.props.uploadType === 'resend') {
      Array.from(files).forEach(file => {
        resendModelRequest(file, onMutate, this.props)
      })
    } else {
      this.props.uploadFiles(files)
    }
  }
  render() {
    return (
      <Mutation
        mutation={this.props.uploadType === 'resend' ? UPDATE_PDF_USEAGREEMENT_MUTATION :UPLOAD_PDF_USEAGREEMENT_MUTATION}
        onCompleted={() => {
          this.props.onSuccessCallback()
        }}
      >
        {(onMutate, { data }) => (
          <Dropzone
            photos={this.props.photos}
            onDrop={ files => this.onDrop(files, onMutate) }
            accept={{"image/jpg": [".png", ".jpeg", ".jpg"], "application/pdf": [".pdf"]}}
            disabled={this.props.disabled}
            multiple={false}
          >
            {({ getRootProps, getInputProps, fileRejections }) => {
              let fileRejectionItems = fileRejections.map(({ file, errors }) => (
                errors.map(e => (
                  (e.code === "file-invalid-type") ? 
                  validationText.fileUpload.fileInvalidType.error : e.message
                ))
              ));
              return (
                <Fragment>
                    <Grid container className='model-release__upload-button'>
                      <Grid item xs={12} sm={4}>
                        <Box {...getRootProps()} className='uploader__button mr-ua__uploader__button'>
                          <input {...getInputProps()} />
                          <Button variant={this.props.secondaryStyle ? '' : 'contained' } title='Upload Signed Release' disabled={false} />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} sx={{alignContent:{xs: 'center'}}}>
                        {this.state.files.length > 0 ? <Box className="mr-upload-file-name" sx={{ml:{xs: 0}}}>
                            <Typography variant="h4" className='semi-strong' sx={{wordWrap:{xs:'break-word'}}}>
                              {truncateDetails(this.state.files[0].name.trim(), 90)}
                            </Typography>
                          </Box>
                          : fileRejectionItems ? 
                            <Box className="mr-upload-file-name" sx={{alignContent:{xs: 'center'}}}>
                              <Typography variant="h4" className='component-error-text'>
                                {fileRejectionItems}
                              </Typography>
                            </Box>
                        : null}
                      </Grid>
                    </Grid>
                </Fragment>
              )
            }}
          </Dropzone>
        )}
      </Mutation>
    )
  }
}

export default ModelReleaseUploader
