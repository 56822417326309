import React, { Component, Fragment } from 'react'
import PhotoGallery from '../organisms/PhotoGallery.js'
import Shortlist from '../molecules/Shortlist.js'
import ProjectProgress from './ProjectProgress.js'
import ProjectDetailsMoreInformation from '../molecules/ProjectDetailsMoreInformation.js'
import { withApollo } from '@apollo/client/react/hoc'
import Loading from '../elements/Loading'
import { ArrowLeft } from 'react-feather'
import { Box, Typography } from '@mui/material'
import StudentAwardResults from './StudentAwardResults.js'
import DateUtils from '../../utils/DateUtils.js'

const NavStyles = {
  marginTop: '-6.5vh',
  marginBottom: '5vh'
}

class ProjectDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tmpPhotoBeingPassed: null,
      project: props.project || null,
      uploadedFileCount: 0,
      libTmpPhoto: null,
      refetchAll: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      project: nextProps.project
    })
  }

  isComplete = photo => {
    const { caption, title } = photo
    const textValid = caption && caption.length > 0 && title && title.length > 0
    return textValid
  }

  changeTmpPhoto = id => {
    this.setState({
      ...this.state,
      tmpPhotoIdBeingPassed: id
    })
  }

  changeLibTmpPhoto = id => {
    this.setState({
      ...this.state,
      libTmpPhoto: id
    })
  }

  updateUploadToast = (message, color) => {
    this.props.showToast && this.props.showToast(message, color)
  }

  setUploadedFileCount = fileCount => {
    this.setState({
      ...this.state,
      expectedFileCount: fileCount,
      uploadedFileCount: fileCount
    })
  }

  setRefetchAllSection = toRefetch => {
    this.setState({
      ...this.state,
      refetchAll: toRefetch
    })
  }

  render() {
    if (this.state.project) {
      const project = this.state.project
      const photos = this.state.project.photos

      const { submitted, cohort, student } = project

      const { resetScope, role } = this.props
      const projectId = project.id

      const shortlistedPhotos = photos.filter(p => p.isShortListed)
      const dob = project.student && project.student.dob
      const isCohortEnded = DateUtils.isDatePassed(cohort.endDate)
      const isSummarySend = project.photos.filter(photo => photo.summarySent).length > 0
      const hasWonAward = project.photos.filter(photo => photo.awards.length > 0).length > 0

      return (
        <Box>
          <Fragment>
            <Box>
              {resetScope ? (
                <Fragment>
                  <Box
                    className='photo-meta__student-project'
                    onClick={() => resetScope('students')}
                    style={NavStyles}
                  >
                    <ArrowLeft style={{ color: '#0076BB' }} />{' '}
                    <Typography variant='h4' className='semi-strong link'>
                      Student List
                    </Typography>
                  </Box>
                </Fragment>
              ) : null}
              {this.props.currentTab === 'project-status' ? (
                <ProjectProgress
                  projectProgressHeader={
                    submitted && !isSummarySend
                      ? 'Project Submitted'
                      : !submitted &&
                      isCohortEnded
                      ? // moment(cohort.endDate, 'YYYY-MM-DD')
                        //   .hour(23)
                        //   .minute(59)
                        //   .second(59)
                        //   .isBefore(moment().format('YYYY-MM-DD'))
                        'Project Not Submitted'
                      : isSummarySend
                      ? 'Submission Results'
                      : 'Project Progress'
                  }
                  projectId={projectId}
                  submitterId={project.submitterId}
                  dob={dob}
                  useAgreement={project.useAgreement}
                  submittedOn={project.submittedUpdatedAt}
                  photos={photos}
                  shortlistedPhotos={shortlistedPhotos}
                  project={project}
                  refetchQueries={this.props.refetchProjectData}
                  showToast={this.props.showToast}
                  incompleteShortlistedPhotos={
                    shortlistedPhotos.length -
                    shortlistedPhotos.filter(photo => this.isComplete(photo)).length
                  }
                  submitted={submitted}
                  curatorsReviewed={cohort.reviewed}
                  reviewSummarySent={project.photos.filter(photo => photo.summarySent).length > 0}
                  student={student}
                  cohort={cohort}
                  isSummarySend={isSummarySend}
                  hasWonAward={hasWonAward}
                />
              ) : null}

              {(this.props.currentTab === 'project-status' &&
                !submitted &&
                !isCohortEnded) ||
              this.props.currentTab === 'photographs' ? (
                <>
                  <Shortlist
                    photoOnClick={this.props.photoOnClick}
                    shortlistHeader={submitted ? 'Submitted Photographs' : 'Shortlist'}
                    isSubmitted={submitted}
                    showToast={this.props.showToast}
                    projectId={projectId}
                    photoToAdd={this.state.tmpPhotoIdBeingPassed}
                    addFinalPhotoToLibrary={_photo => this.changeLibTmpPhoto(_photo)}
                    refetchProject={this.props.refetchProjectData}
                    updateHeader={this.props.updateHeader}
                    currentTab={this.props.currentTab}
                    submittedOn={project.submittedUpdatedAt}
                    isCohortEnded={isCohortEnded}
                    student={student}
                    setRefetchAllSection={this.setRefetchAllSection}
                    refetchData={this.state.refetchAll}
                  />

                  {role !== 'curator' && (
                    <PhotoGallery
                      projectId={projectId}
                      projectSubmitted={submitted}
                      photoOnClick={this.props.photoOnClick}
                      showToast={this.props.showToast}
                      photoToAdd={this.state.libTmpPhoto}
                      addLibPhotoToFinal={_photo => this.changeTmpPhoto(_photo)}
                      updateUploadToast={this.updateUploadToast}
                      updateHeader={this.props.updateHeader}
                      currPhotos={this.state.project.photos}
                      refetchProject={this.props.refetchProjectData}
                      currentTab={this.props.currentTab}
                      submittedOn={project.submittedUpdatedAt}
                      isCohortEnded={isCohortEnded}
                      student={student}
                      setRefetchAllSection={this.setRefetchAllSection}
                      refetchData={this.state.refetchAll}
                    />
                  )}
                </>
              ) : null}
              {this.props.currentTab === 'project-status' &&
              isSummarySend &&
              hasWonAward &&
              ['appadmin', 'facilitator', 'teacher', 'student'].includes(role) ? (
                <StudentAwardResults photos={photos} role={role} student={student} />
              ) : null}
            </Box>

            <Box sx={{ borderTop: '2px solid #e8e8e9' }}>
              <ProjectDetailsMoreInformation cohort={cohort} project={this.state.project} />
            </Box>
          </Fragment>
        </Box>
      )
    } else {
      return <Loading />
    }
  }
}

export default withApollo(ProjectDetail)
