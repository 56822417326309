import React, { Component, Fragment } from 'react'
import './events.scss'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from '../ui/elements/Button.js'
import Switches from '../ui/elements/Switches.js'
import FormField from '../ui/elements/FormField.js'
import FormFieldMultiline from '../ui/elements/FormFieldMultiline.js'
// import Form from 'react-bootstrap/Form'
import Hero from '../ui/organisms/Hero.js'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import DatePicker from '../ui/elements/DatePicker.js'
import EventPhotoUploader from '../ui/organisms/EventPhotoUploader'
// import { withRouter } from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import DateUtils from '../utils/DateUtils'
import Layout from '../ui/template/Layout'
import { Typography } from '@mui/material'
import { withRouter } from '../utils/customHoc'

const MUTATION = gql`
  mutation CreateEvent(
    $name: String
    $slug: String
    $eventStart: String
    $eventEnd: String
    $timingNote: String
    $location: String
    $summary: String
    $description: String
    $featuredImagePhotographerName: String
    $featuredImagePhotographTitle: String
    $featuredImagePhotographYear: String
    $published: Boolean
    $fileSignedId: String
  ) {
    createEvent(
      name: $name
      slug: $slug
      eventStart: $eventStart
      eventEnd: $eventEnd
      timingNote: $timingNote
      location: $location
      summary: $summary
      description: $description
      featuredImagePhotographerName: $featuredImagePhotographerName
      featuredImagePhotographTitle: $featuredImagePhotographTitle
      featuredImagePhotographYear: $featuredImagePhotographYear
      published: $published
      fileSignedId: $fileSignedId
    ) {
      event {
        id
      }
      errors {
        path
        message
      }
    }
  }
`

class CreateEvent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: { slug: 'cannot be blank' },
      deleteEvent: false
      // continueOne: 'true'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
    this.updateFileSignedId = this.updateFileSignedId.bind(this)
    this.checkForErrors = this.checkForErrors.bind(this)
    this.validateSlug = this.validateSlug.bind(this)
    this.updatePreviewImage = this.updatePreviewImage.bind(this)
    this.togglePublishing = this.togglePublishing.bind(this)
    this.validateEventForPublishing = this.validateEventForPublishing.bind(this)
  }

  checkForErrors(data) {
    if (data && data.createEvent && data.createEvent.errors) {
      this.setState({ submitted: false })
      // Note the errors hash keys are snake_case not camelCase because they come back from Ruby directly
      var parsed_errors = data.createEvent.errors.reduce(function(map, obj) {
        map[obj.path] = obj.message
        return map
      }, {})

      if (Object.entries(parsed_errors).length === 0 && parsed_errors.constructor === Object) {
        this.props.history.push('/events')
      }
      this.setState({
        errors: parsed_errors
      })

      console.log(parsed_errors)
    }
  }

  validateSlug(e) {
    const slugPattern = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
    this.setState({ slug: e.target.value })
    if (!slugPattern.test(e.target.value) && e.target.value) {
      this.setState({ errors: { slug: 'Event slug must only use letters, numbers, and hyphens.' } })
    } else {
      this.setState({ errors: { slug: null } })
    }
  }
  validateUrl(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    )

    return res !== null
  }
  handleFormChange(e) {
    const value = e.target.name === 'published' ? e.target.checked : e.target.value
    this.setState({ [e.target.name]: value })
  }

  togglePublishing(e) {
    e.preventDefault()
    e.stopPropagation()
    this.validateEventForPublishing(e)
  }

  validateEventForPublishing(e) {
    let errors = {}
    e.preventDefault()
    if (!this.state.name) {
      errors = {
        ...errors,
        name: 'Please enter an event name before publishing.'
      }
    } else if (this.state.name.length > 40) {
      errors = {
        ...errors,
        name: 'Event name must be shorter than 40 characters.'
      }
    } else {
      errors = { ...errors, name: null }
    }
    if (!this.state.eventStart) {
      errors = {
        ...errors,
        eventStart: 'Please enter an event start date before publishing.'
      }
    } else {
      errors = { ...errors, eventStart: null }
    }
    if (!this.state.timingNote) {
      errors = {
        ...errors,
        timingNote: 'Please enter event time details before publishing.'
      }
    } else {
      errors = { ...errors, timingNote: null }
    }
    if (!this.state.location) {
      errors = {
        ...errors,
        location: 'Please enter event location details before publishing.'
      }
    } else {
      errors = { ...errors, location: null }
    }
    if (!this.state.summary) {
      errors = {
        ...errors,
        summary: 'Please enter a short summary of the event before publishing.'
      }
    } else if (this.state.summary > 200) {
      errors = {
        ...errors,
        summary: 'Event summary must be shorter than 200 characters.'
      }
    } else {
      errors = { ...errors, summary: null }
    }
    if (!this.state.description) {
      errors = {
        ...errors,
        description: 'Please enter a description of the event before publishing.'
      }
    } else {
      errors = { ...errors, description: null }
    }
    if (this.state.externalLink && !this.validateUrl(this.state.externalLink)) {
      errors = {
        ...errors,
        externalLink: 'Please enter a valid URL or leave blank.'
      }
    } else {
      errors = { ...errors, externalLink: null }
    }
    if (Object.keys(errors).filter(key => errors[key] !== null).length > 0) {
      this.setState({ published: false })
    } else this.setState({ published: this.state.published ? false : true })
    this.setState({ errors: { ...this.state.errors, ...errors } })
  }

  updateFileSignedId(fileSignedId) {
    this.setState({ fileSignedId: fileSignedId })
  }

  handleSubmit(e, onMutate) {
    e.preventDefault()
    this.setState({ submitted: true })
    if (!this.state.submitted) {
      onMutate({
        variables: {
          name: this.state.name,
          slug: this.state.slug,
          eventStart: DateUtils.formatDateString(this.state.eventStart),
          eventEnd: DateUtils.formatDateString(this.state.eventEnd),
          timingNote: this.state.timingNote,
          location: this.state.location,
          summary: this.state.summary,
          description: this.state.description,
          featuredImagePhotographerName: this.state.featuredImagePhotographerName,
          featuredImagePhotographTitle: this.state.featuredImagePhotographTitle,
          featuredImagePhotographYear: this.state.featuredImagePhotographYear,
          published: this.state.published,
          fileSignedId: this.state.fileSignedId
        },
        refetchQueries: [`EventsQuery`]
      })
    }
  }

  updatePreviewImage(url) {
    this.setState({ linkToFeaturedImage: url })
  }

  render() {
    const {
      handleFormChange,
      updateFileSignedId,
      handleSubmit,
      validateSlug,
      validateEventForPublishing
    } = this
    return (
      <Layout>
        <Mutation
        mutation={MUTATION}
        onCompleted={data => {
          this.checkForErrors(data)
        }}
      >
        {(onMutate, { data }) => {
          return (
            <Fragment>
              <Hero heroTitle='New Event' />

              <div className='container__body'>
                <div className='events__header'>
                  <div className='events__header--title'>
                    <Typography variant='h1' className='bold'>Event Details</Typography>
                  </div>
                </div>
                <div className='body__events'>
                  <form
                    className='my-account__form-container'
                    noValidate
                    autoComplete='on'
                    onSubmit={() => {
                      alert('real submit')
                    }}
                  >
                    <div className='events__form-group'>
                      <Typography variant="h3" className='semi-strong'>Event Name</Typography>
                      <Typography variant="paragraph"
                        component="p"
                      >Names should be written in title case and a maximum of 40 characters.</Typography>
                      <Row>
                        <div className='form-field full-width'>
                          <FormField
                            formFieldId='custom-css-outlined-event-name'
                            formFieldLabel='Event Name'
                            formFieldVariant='filled'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='name'
                            formFieldMaxLength='40'
                            //   formFieldDefaultValue={this.state.eventName}
                            onChange={handleFormChange}
                            formFieldError={this.state.errors.name || null}
                          />
                        </div>
                      </Row>
                    </div>

                    <div className='events__form-group'>
                      <Typography variant="h3" className='semi-strong'>Event Slug</Typography>
                      <Typography variant="paragraph"
                          sx={{
                            mb:"1rem",
                          }}
                          component="p"
                          >The unique URL slug for this event.</Typography>
                      <Typography variant="h5">
                        For example: "bowling-green-pop-up-exhibition".
                        <br />
                        Note: Changing this slug will break all existing links to the event!
                      </Typography>
                      <Row>
                        <div className='form-field full-width'>
                          <FormField
                            formFieldId='custom-css-outlined-event-name'
                            formFieldLabel='Event Slug'
                            formFieldVariant='filled'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='slug'
                            formFieldMaxLength='40'
                            //   formFieldDefaultValue={this.state.eventName}
                            onChange={validateSlug}
                            formFieldError={this.state.errors.slug}
                          />
                        </div>
                      </Row>
                    </div>

                    <div className='events__form-group'>
                      <Typography variant="h3" className='semi-strong'>Date and Time</Typography>
                      <Typography variant="paragraph"
                    component="p"

                      >If the event occurs on a single day, do not select an end date.</Typography>
                      <Row>
                        <Col md='true'>
                          <div className='form-field col--left'>
                            <DatePicker
                              onChange={date =>
                                handleFormChange({
                                  target: {
                                    value: date,
                                    name: 'eventStart'
                                  }
                                })
                              }
                              label='Event Start'
                              value={this.state.eventStart}
                              helperText={this.state.errors.eventStart}
                              error={this.state.errors.eventStart}
                            />
                          </div>
                        </Col>
                        <Col md='true'>
                          <div className='form-field col--right'>
                            <DatePicker
                              onChange={date =>
                                handleFormChange({
                                  target: {
                                    value: date,
                                    name: 'eventEnd'
                                  }
                                })
                              }
                              label='Event End'
                              value={this.state.eventEnd}
                              helperText={this.state.errors.eventEnd}
                              error={this.state.errors.eventEnd}
                            />
                          </div>
                        </Col>
                      </Row>
                      <br />
                      <Typography variant="paragraph"
                    component="p"

                      >Enter additional details about the event time.</Typography>
                      <Typography variant="h5">For example: "11 AM - 4 PM, Monday to Friday".</Typography>
                      <br />
                      <Row>
                        <div className='form-field full-size'>
                          <FormFieldMultiline
                            formFieldId='event-time'
                            formFieldVariant='filled'
                            formFieldLabel='Event Time'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldPlaceholder='Describe the timing for your event.'
                            formFieldType='text'
                            formFieldName='timingNote'
                            formFieldError={this.state.errors.timingNote}
                            formFieldMargin='auto'
                            onChange={handleFormChange}
                            multiline='true'
                          />
                        </div>
                      </Row>
                    </div>

                    <div className='events__form-group'>
                      <Typography variant="h3" className='semi-strong'>Location</Typography>
                      <Typography
                        variant="paragraph"
                        sx={{
                          mb:"1rem",
                        }}
                        component="p"
                        >Enter the full address of the event location.</Typography>
                      <Row>
                        <div className='form-field full-size'>
                          <FormFieldMultiline
                            formFieldId='event-location'
                            formFieldVariant='filled'
                            formFieldLabel='Event Location'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='location'
                            formFieldMargin='auto'
                            formFieldError={this.state.errors.location}
                            onChange={handleFormChange}
                            multiline='true'
                          />
                        </div>
                      </Row>
                    </div>

                    <div className='events__form-group'>
                      <Typography variant="h3" className='semi-strong'>Details</Typography>
                      <Typography
                          variant="paragraph"
                          sx={{
                            mb:"1rem",
                          }}
                          component="p"
                        >
                          Enter a brief summary of the event (between 150 and 200 characters).
                      </Typography>
                      <Row>
                        <div className='form-field full-size'>
                          <FormFieldMultiline
                            formFieldId='event-summary'
                            formFieldVariant='filled'
                            formFieldLabel='Event Summary'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='summary'
                            formFieldMargin='auto'
                            formFieldError={this.state.errors.summary}
                            onChange={handleFormChange}
                            multiline='true'
                            formFieldMaxLength='200'
                          />
                        </div>
                      </Row>
                      <br />
                      <Typography
                        variant="paragraph"
                        sx={{
                          mb:"1rem",
                        }}
                        component="p"
                        >Enter a description of the event.</Typography>
                      <Row>
                        <div className='form-field full-size'>
                          <FormFieldMultiline
                            formFieldId='event-description'
                            formFieldVariant='filled'
                            formFieldLabel='Event Description'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='description'
                            formFieldMargin='auto'
                            formFieldError={this.state.errors.description}
                            onChange={handleFormChange}
                            multiline='true'
                          />
                        </div>
                      </Row>
                      <Typography variant="paragraph"
                        sx={{
                          mb:"1rem",
                        }}
                        component="p"
                          >
                        Enter a link to additional information about the event, such as the venue
                        website.
                      </Typography>
                      <Row>
                        <div className='form-field full-size'>
                          <FormField
                            formFieldId='event-external-link'
                            formFieldVariant='filled'
                            formFieldLabel='Event Link (Optional)'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='externalLink'
                            formFieldMargin='auto'
                            formFieldError={this.state.errors.externalLink}
                            onChange={handleFormChange}
                            multiline='true'
                          />
                        </div>
                      </Row>
                    </div>

                    <div className='events__form-group'>
                      <Typography variant="h3" className='semi-strong'>Featured Image</Typography>
                      <Typography variant="paragraph"
                      component="p"

                      >This image will appear on the event page.</Typography>
                      <Typography variant="h5">Images should be saved as JPGs and be 2500px on the longest edge.</Typography>
                      <br />
                      <Row>
                        <div className='form-field'>
                          {this.state.linkToFeaturedImage ? (
                            <Image src={this.state.linkToFeaturedImage} fluid={true} />
                          ) : null}
                          <EventPhotoUploader
                            updateFileSignedId={updateFileSignedId}
                            buttonTitle='Upload Image'
                            updatePreview={this.updatePreviewImage}
                          />
                        </div>
                      </Row>
                      <Row>
                        <br />
                        <div className='form-field full-width'>
                          <FormField
                            formFieldId='custom-css-outlined-photographer-name'
                            formFieldLabel='Photographer Name (Optional)'
                            formFieldVariant='filled'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='featuredImagePhotographerName'
                            //   formFieldDefaultValue={this.state.eventName}
                            onChange={handleFormChange}
                            //   formFieldError={this.state.errors.eventName || null}
                          />
                        </div>
                      </Row>
                      <Row>
                        <div className='form-field full-width'>
                          <FormField
                            formFieldId='custom-css-outlined-photograph-title'
                            formFieldLabel='Photograph Title (Optional)'
                            formFieldVariant='filled'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='text'
                            formFieldName='featuredImagePhotographTitle'
                            //   formFieldDefaultValue={this.state.eventName}
                            onChange={handleFormChange}
                            //   formFieldError={this.state.errors.eventName || null}
                          />
                        </div>
                      </Row>
                      <Row>
                        <div className='form-field full-width'>
                          <FormField
                            formFieldId='custom-css-outlined-photograph-year'
                            formFieldLabel='Photograph Year (Optional)'
                            formFieldVariant='filled'
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='number'
                            formFieldName='featuredImagePhotographYear'
                            //   formFieldDefaultValue={this.state.eventName}
                            onChange={handleFormChange}
                            formFieldError={this.state.errors.eventName || null}
                          />
                        </div>
                      </Row>
                    </div>

                    <div className='events__form-group create-switch'>
                      <Switches
                        checked={this.state.published}
                        // disabled={this.state.publishable}
                        onClick={this.togglePublishing}
                        name='published'
                        formFieldError={
                          this.state.errors.lengtth > 0
                            ? 'Please resolve errors before publishing.'
                            : null
                        }
                      />
                      <div className='notification-preferences__switch-label'>
                        <Typography variant="h4">Publish Event</Typography>
                      </div>
                    </div>

                    <div className='events__form-group'>
                      <div className='events__form-group--buttons'>
                        <Button
                          type='submit'
                          title='Save Updates'
                          onClick={e => handleSubmit(e, onMutate)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Fragment>
          )
        }}
      </Mutation>
      </Layout>
    )
  }
}

export default withRouter(CreateEvent)
