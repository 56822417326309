import React, { useEffect, useState } from 'react'
import Button from '../elements/Button.js'
import { Typography, Box, Grid } from '@mui/material'
import { gql, useQuery } from '@apollo/client'
import Loading from '../elements/Loading.js'
import { getTextContentByPage } from '../../content/textContent'
import FormSelect from '../elements/FormSelect'
import BaseModal from '../template/BaseModal';

const CURATORS_QUERY = gql`
  query CuratorList {
    curators {
      id
      name
      bio
      email
      userId
      mobileNumber
    }
  }
`

const AssignCuratorModal = props => {
  const [curatorIds, setCuratorIds] = useState(props.curatorIds || null)
  const [curators, setCurators] = useState(props.curators || {})
  const [disableConfirm, setDisableConfirm] = useState(true)

  useEffect(() => {
    setCuratorIds(props.curatorIds)
    setCurators(props.curators)
  }, [props.curatorIds, props.curators])

  const handleCuratorChange = (e, curatorOptions) => {
    const selected =
      e.target.value &&
      e.target.value.map(e => {
        const selectedKey = Object.keys(curatorOptions).find(x => curatorOptions[x].value === e)
        return curatorOptions[selectedKey]
      })

      setCuratorIds(e.target.value)
    setCurators(selected)
    setDisableConfirm(false)
  }

  const handleSubmit = (curatorIds, curators) => {
    props.handleSave(curatorIds, curators)
    setDisableConfirm(true)
  }

  const handleCancel = () => {
    setCuratorIds(props.curatorIds)
    setCurators(props.curators)
    props.onHide()
  }

  const { loading, error, data } = useQuery(CURATORS_QUERY)

  if (loading) return <Loading />

  if (error)
    return (
      <Typography variant='paragraph' component='p'>
        Error loading classes
      </Typography>
    )

    if (data) {
      const options = data.curators.map(curator => ({
        label: curator.name,
        name: curator.name,
        value: curator.id,
        bio: curator.bio,
        email: curator.email,
        userId: curator.userId,
        mobileNumber: curator.mobileNumber
      }))

      return (
        <BaseModal
          {...props}
          show={props.show}
          onClick={props.onClick}
          value={props.value}
          open={props.show}
          onClose={handleCancel}
          onHide={handleCancel}
          >
          <BaseModal.BaseModalHeader>
            Choose Curator(s)
          </BaseModal.BaseModalHeader>
          <BaseModal.BaseModalBody>
            {getTextContentByPage('CLASSDETAILS', 'ALL', 'ADD_CURATORS_TEXT')}
            <form>
              <FormSelect
                options={options}
                label='Class Curator(s)'
                placeholder='Class Curator(s)'
                name='curatorName'
                multiple={true}
                onChange={e => handleCuratorChange(e, options)}
                value={curatorIds}
                selectedOptions={curators}
                variant='filled'
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth={true}
              />
              <BaseModal.BaseModalFooter>
                <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
                  <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Button
                        variant='contained'
                        disabled={disableConfirm}
                        type='submit'
                        title='Confirm Curator(s)'
                        onClick={() =>
                          handleSubmit(curatorIds, curators)
                        }
                        sx={{width:{ xs: '100%', sm: '100%' }}}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                      <Typography
                        sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}}
                        variant="h4"
                        className='small_size_semi_bold link'
                        onClick={handleCancel}
                      >
                        Cancel
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </BaseModal.BaseModalFooter>
            </form>
          </BaseModal.BaseModalBody>
        </BaseModal>
      )
    }
}

export default AssignCuratorModal
