import { action, makeAutoObservable } from 'mobx'

class UIStore {
  currentTabIndex = 0

  constructor() {
    // Call it here
    makeAutoObservable(this)
  }

  setCurrentTab = action(newTabIndex => {
    this.currentTabIndex = newTabIndex
  })
}

export default UIStore
