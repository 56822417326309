import { Component, Fragment } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { Box, Typography } from '@mui/material'
import { inject, observer } from 'mobx-react'
import Masonry from 'react-masonry-css'
import { AWARDED_PHOTOS, AWARDED_PHOTO_AMOUNT_QUERY } from '../queries/awardedPhotoQueries'
import Loading from '../ui/elements/Loading'
import ShortlistPhoto from '../ui/elements/ShortlistPhoto'
import PhotoOverlay from '../ui/molecules/PhotoOverlay'
import PhotoStatusStack from '../ui/molecules/PhotoStatusStack'
import Hero from '../ui/organisms/Hero'
import NewFullScreenPhotoModal from '../ui/organisms/PhotoDetailsModal/NewFullScreenPhotoModal'
import Layout from '../ui/template/Layout'
import { withRouter } from '../utils/customHoc'
import ErrorPage from './ErrorPage'
import DateUtils from '../utils/DateUtils'
// import './about.scss'

class AwardedPhotoPage extends Component {
  constructor(props) {
    super(props)
    const { params } = props
    this.state = {
      showPhotoModal: false,
      toastShow: false,
      pageNum: 0,
      data: [],
      loading: true,
      batchSize: 24,
      awardedPhotosCount: 0,
      projectId: params.projectId,
      award: params.award,
      selectedPhotoId: -1,
      student: null,
      errors: null
    }
  }
  async componentDidMount() {
    const { params } = this.props
    const { awardedPhotosCount } = (await this.props.client.query({
      fetchPolicy: 'network-only',
      query: AWARDED_PHOTO_AMOUNT_QUERY,
      variables: {
        id: params.projectId,
        award: params.award
      }
    })).data

    this.setState(
      {
        ...this.state,
        awardedPhotosCount
      },
      async () => await this.fetchMoreAwardedPhotos(this.state.pageNum)
    )

    window.addEventListener('scroll', this.handleScroll)
  }
  handleScroll = e => {
    const elem = e.target.scrollingElement
    const vh = elem.scrollHeight
    const currVh = elem.scrollTop
    const threshold = vh / 4 + 200
    if (currVh > threshold) {
      this.fetchMoreAwardedPhotos(this.state.pageNum + 1)
    }
  }
  fetchMoreAwardedPhotos = async (step) => {
    const { photosStore } = this.props
    const { batchSize, awardedPhotosCount } = this.state
    if (step * batchSize > awardedPhotosCount) return []
    this.setState({
      ...this.state,
      loading: true
    })
    return await this.props.client
      .watchQuery({
        fetchPolicy: 'network-only',
        query: AWARDED_PHOTOS,
        variables: {
          batchSize: batchSize,
          baseBatchSize: batchSize,
          id: this.state.projectId,
          step,
          award: this.state.award
        }
      })
      .subscribe(({ data }) => {
        if (!data) return
        const awardedPhotos = [...this.state.data]
        const ids = awardedPhotos.map(photo => photo.id)
        const newIds = data.awardedPhotos.map(photo => photo.id)
        let isNewData = false
        for (let newId of newIds) {
          if (!ids.includes(newId)) {
            isNewData = true
            break
          }
        }
        if (isNewData) {
          this.mergeIncomingData(data.awardedPhotos)
        } else {
          this.setState({
            ...this.state,
            loading: false
          })
        }
        const getIds = data.awardedPhotosIds.map(photo => photo.id)
        photosStore.setPhotoStore('awardedPhotos', [...getIds])
      }, (error) => {
        this.setState({
          ...this.state,
          loading: false,
          errors: [...error.graphQLErrors]
        })
      })
  }
  mergeIncomingData = async (data) => {
    if (!data) {
      this.setState({
        ...this.state,
        loading: false
      })
      return
    }

    if (
      this.state.pageNum > 0 &&
      data[data.length - 1].id === this.state.data[this.state.data.length - 1].id
    )
      return

    const awardedPhotos = [...this.state.data]
    const ids = awardedPhotos.map(photo => photo.id)
    for (let obj of data) {
      if (!ids.includes(obj.id)) {
        awardedPhotos.push(obj)
        ids.push(obj.id)
      }
    }

    this.setState({
      ...this.state,
      data: awardedPhotos,
      student: awardedPhotos[0].project ? { ...awardedPhotos[0].project.student } : {},
      loading: false,
    })
  }
  generateChildren = (collection) => {
    const { role } = this.props.userStore
    return collection
      ? collection.map((photo, index) => {
        return (
          <Fragment key={'Fragment' + photo.id}>
            <div className="class-review-masonry-photo">
              <div className='class-review-masonry-photo-container' key={photo.id}>
                <PhotoOverlay
                  photo={photo}
                  photoOnClick={() => this.thumbnailOnClick(photo)}
                  hideComments={true}
                  isCohortEnded={DateUtils.isDatePassed(photo.project.cohort.endDate)}
                  page='awarded-photos'
                />
                <ShortlistPhoto
                  photo={photo}
                  key={index}
                  placeholderDark={true}
                  photoOnClick={() => this.thumbnailOnClick(photo)}
                  viewBadge={true}
                  role={role}
                />
              </div>
              <PhotoStatusStack
                photo={photo}
                role={role}
              />
            </div>
          </Fragment>
        )
      })
      : null
  }
  updateParentGallery = photo => {
    const photos = this.state.data
    const currIdx = photos.findIndex(elem => elem.id === photo.id)
    const newPhotoNode = photos[currIdx]
    if (!newPhotoNode) return // it's missing from the list?
    let newPhoto = { ...newPhotoNode }
    newPhoto.title = photo.title
    newPhoto.caption = photo.caption
    newPhoto.url = photo.url
    newPhoto.cdnOriginalUrl = photo.url
    newPhoto.originalUrl = photo.url
    newPhoto.sizedUrl = photo.url
    newPhoto.photoComments = photo.photoComments || []
    newPhoto.awards = photo.awards || []
    newPhoto.totalMrRequired = photo.totalMrRequired
    newPhoto.modelReleases = photo.modelReleases
    newPhoto.rawFileName = photo.rawFileName
    newPhoto.rawFileSize = photo.rawFileSize
    newPhoto.rawFileUrl = photo.rawFileUrl
    photos[currIdx] = { ...newPhoto }
    this.setState({
      awardedPhotos: photos
    })
  }
  thumbnailOnClick = photo => {
    this.setState({
      ...this.state,
      selectedPhotoId: photo.id,
      showPhotoModal: true
    })
  }
  getAdjacentImage = async (direction, photoId) => {
    if (direction && !['prev', 'next'].includes(direction)) return

    const photos = this.state.data
    const currIdx = photos.findIndex(elem => elem.id === photoId)
    if (direction === 'prev') {
      this.setState({
        ...this.state,
        selectedPhotoId: currIdx === 0 ? photos[photos.length - 1].id : photos[currIdx - 1].id
      })
    } else {
      if (this.state.awardedPhotosCount - 1 >= currIdx && !photos[currIdx + 1]) {
        await this.fetchMoreAwardedPhotos(this.state.pageNum + 1, null, true)
      } else {
        this.setState({
          ...this.state,
          selectedPhotoId:
            currIdx === this.state.awardedPhotosCount - 1 ? photos[0].id : photos[currIdx + 1].id
        })
      }
    }
  }
  render() {
    const { params } = this.props
    let photos = this.state.data
      ? this.state.data
      : null
    const { errors, loading } = this.state
    const childElements = this.generateChildren(photos)
    return (
      <Layout>
        {errors ? (
          <ErrorPage errorCode='403' withInPage={true} />
        ) : loading ? (
          <Loading />
        ) : (
          <>
            <Hero
              heroTitle={`${params.award === 'award-of-excellence'
                ? 'Award of Excellence Photograph(s) by '
                : params.award === 'honorable-mention'
                  ? 'Honorable Mention Photograph(s) by '
                  : ''
                } ${this.state.student ? this.state.student.name : ''}`}
            />
            {this.state.loading ? (
              <Loading />
            ) : this.state.data.length > 0 ? (
              <Fragment>
                <Box sx={{ px: { xs: 4, md: 6 }, pt: { xs: 5, md: 6, lg: 10 } }}>
                  <Masonry
                    breakpointCols={{
                      default: 3,
                      768: 2,
                      576: 1
                    }}
                    className='photo-grid'
                    columnClassName='photo-grid__column'
                  >
                    {childElements}
                  </Masonry>
                </Box>
              </Fragment>
            ) : (
              <div className='award-results-no-award-text'>
                <Typography variant="paragraph" component="p">No photo are being awarded</Typography>
              </div>
            )}
            {this.state.selectedPhotoId !== -1 ? (
              <NewFullScreenPhotoModal
                show={this.state.showPhotoModal}
                photoId={this.state.selectedPhotoId}
                totalCount={this.state.awardedPhotosCount}
                closeModal={() => {
                  this.setState({ ...this.state, fadeOut: true }, () => {
                    setTimeout(
                      () =>
                        this.setState({
                          showPhotoModal: false,
                          selectedPhotoId: -1,
                          fadeOut: false
                        }),
                      500
                    )
                  })
                }}
                isModal={true}
                placeholderDark={true}
                componentCallFrom='awardedPhotos'
                toggleParentModalState={(pushState = true, shouldRefetch = false) => {
                  if (pushState) {
                    window.history.pushState(null, '', this.props.backToPageStore.activePage)
                  }
                  this.setState({ ...this.state, fadeOut: true }, () => {
                    setTimeout(
                      () =>
                        this.setState({
                          showPhotoModal: false,
                          selectedPhotoId: -1,
                          fadeOut: false
                        }),
                      500
                    )
                  })
                }}
                photoCurrentIndex={photos.findIndex(
                  elem => elem.id === this.state.selectedPhotoId
                )}
                animation={false}
                className={`animated ${this.state.showPhotoModal && !this.state.fadeOut
                  ? 'fadeInUpBig'
                  : 'fadeOutDownBig'
                  }`}
                student={this.state.student}
              />
            ) :
              null}
          </>
        )}
      </Layout>
    )
  }
}

export default inject('photosStore', 'userStore', 'resourceStore', 'backToPageStore')(observer(withApollo(withRouter(AwardedPhotoPage))))