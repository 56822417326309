import { action, makeAutoObservable } from 'mobx'

class BackToPageStore {
  activePage = null
  activePageTab = null
  activePageFilter = null
  uniqueId = null

  constructor() {
    // Call it here
    makeAutoObservable(this)
  }

  setActivePage = action(pageName => {
    this.activePage = pageName
  })

  setUniqueId = action(uniqueId => {
    this.uniqueId = uniqueId
  })

  setActivePageTab = action(tabName => {
    this.activePageTab = tabName
  })

  setActivePageFilter = action(filterName => {
    this.activePageFilter = filterName
  })
}

export default BackToPageStore
