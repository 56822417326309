import React, { Component, Fragment } from 'react'
import { inject, observer } from 'mobx-react'
import './modelReleasesList.scss'
import UseAgreementListRow from './UseAgreementListRow'
import { Collapse } from 'react-bootstrap'
import { ChevronDown, ChevronUp } from 'react-feather'
import { Typography } from '@mui/material'

class UseAgreementList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resendMessage: false,
      resendUuid:'',
      useAgreement:{},
      photoId: this.props.photoId,
      showResendModal: false,
      modelOp: 'add',
      openOne:false,
      useAgreements: props.useAgreements,
      inactiveUseAgreements: props.inactiveUseAgreements
    }
    this.showResendRequestMessage = this.showResendRequestMessage.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      useAgreements: nextProps.useAgreements,
      inactiveUseAgreements: nextProps.inactiveUseAgreements
    })
  }

  showResendRequestMessage = (modelUuid) => {
    this.setState({showResendModal:false,resendMessage:true, resendUuid : modelUuid})
    setTimeout(() => this.setState({ resendMessage: false, resendUuid:'' }), 5000)
  }

  getUaByStatus = (useAgreements, byStatus, byAdminDecision ='Pending') => {
    return useAgreements.filter( ua => ua.uaStatus === byStatus && ua.adminDecision === byAdminDecision)
  }

  getRelatedUaForActiveUa = (uaUuid) => {
    return this.props.useAgreements.filter( ua => ua.relatedToMr === uaUuid)
  }

  render() {
    const { role } = this.props.userStore
    const { useAgreements, inactiveUseAgreements } = this.state

    return (
      <div className='use-agreement__list-section'>
        <Fragment>
          { this.state.useAgreements.length > 0  ? 
              this.state.useAgreements.map( ua => ( 
                <UseAgreementListRow
                  role={role}
                  useAgreement={ua}
                  allRelatedMrs={this.getRelatedUaForActiveUa(ua.uuid)}
                  photoId={this.state.photoId}
                  refetchPhoto={this.props.refetchPhoto}
                  refetchQueries={this.props.refetchQueries}
                  studentDetails={this.props.studentDetails}
                  uaUuid = {ua.uuid}
                  isStudentMinor={this.props.isStudentMinor}
                  key={`ua-${ua.id}`}
                />
              ))
          : null }

          { ['appadmin'].includes(role) && this.state.inactiveUseAgreements.length > 0  ? 
              <>
                <Collapse in={this.state.openOne}>
                  <div> {
                    this.state.inactiveUseAgreements.map( ua => ( 
                      <UseAgreementListRow
                        role={role}
                        useAgreement={ua}
                        allRelatedMrs={this.getRelatedUaForActiveUa(ua.uuid)}
                        photoId={this.state.photoId}
                        refetchPhoto={this.props.refetchPhoto}
                        refetchQueries={this.props.refetchQueries}
                        studentDetails={this.props.studentDetails}
                        uaUuid = {ua.uuid}
                        isStudentMinor={this.props.isStudentMinor}
                        fetchProject={this.props.fetchProject}
                        key={`ua-admin-${ua.id}`}
                      />
                    ))
                    }
              
                  </div>
              
                </Collapse>
              
                <hr className='use-agreement__list-history-divider' id="mb_10"/>
              
                <div 
                  className='photo-release__statuses model-release__list-accordion'
                  onClick={() => {
                    this.setState({ openOne: !this.state.openOne })
                  }}
                  aria-expanded={this.state.openOne}
                >
              
                  <div className='photo-release__chevron'>
                    {this.state.openOne ? <ChevronUp /> : <ChevronDown />}
                  </div>

                  <div className='photo-release__accordion'>
                  
                    <Typography variant="h4" className="strong">{this.state.openOne ? 'Hide expired and rejected Use Agreements for this photograph' : 'Show expired and rejected Use Agreements for this photograph'}</Typography>
                  
                  </div>
                
                </div>
              
              </>

          : null }
        </Fragment>
      </div> 
    )
  }
}

export default inject('userStore')(observer(UseAgreementList))