import { Box, Typography } from '@mui/material'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { myAccountQuery } from '../queries/myAccountQueries'
import Loading from '../ui/elements/Loading.js'
import ToastNotification from '../ui/elements/ToastNotification'
import Alert from '../ui/molecules/Alert.js'
import AccountDetails from '../ui/organisms/AccountDetails.js'
import Hero from '../ui/organisms/Hero.js'
import PasswordSecurity from '../ui/organisms/PasswordSecurity.js'
import Layout from '../ui/template/Layout'
import { withRouter } from '../utils/customHoc'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

const MyAccount = props => {
  const { currentTab: initialTab } = useParams()
  const { breadcrumbStore, location, basePath } = props
  const tabs = [
    { eventKey: 'account-details', title: 'Account Details', path: '/account-details' },
    // { eventKey: 'notification-preferences', title: 'Notification Preferences' },
    {
      eventKey: 'password-and-security',
      title: 'Password and Delete Account',
      path: '/account-details/password-and-security'
    }
  ]

  const [currentUser, setCurrentUser] = useState({})
  const [currentTab, setCurrentab] = useState()
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastType, setToastType] = useState('')

  useEffect(() => {
    setCurrentab(initialTab || 'account-details')
  }, [initialTab])

  useEffect(() => {
    breadcrumbStore.resetBreadcrumb()
    breadcrumbStore.addBreadcrumb({
      pageName: 'My Account',
      link: location.pathname,
      basePath: basePath,
      isParent: true,
      isActive: true
    })
  }, [basePath, breadcrumbStore, location.pathname])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowToast(false)
  }

  const showToastMsg = (toastType, message = '') => {
    setShowToast(true)
    setToastMessage(message)
    setToastType(toastType)
  }

  const handleTabChange = newTab => {
    setCurrentab(newTab)
  }

  const { loading, data, error, refetch } = useQuery(myAccountQuery, {
    cacheTime: 0,
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setCurrentUser(data.currentUser)
    }
  })

  if (loading) return <Loading />
  if (error) {
    return (
      <Typography variant='paragraph' component='p'>
        Error loading classes
      </Typography>
    )
  }

  return (
    <Layout>
      {showToast === true ? (
        <ToastNotification
          notificationType={toastType}
          showToast={showToast}
          onClose={handleClose}
          toastMessage={toastMessage}
        />
      ) : null}

      {currentUser.accountDisabledAt ? (
        <Alert
          alertTitle={
            'Your account will be permanently deleted on ' +
            moment(currentUser.accountDisabledAt)
              .add(30, 'days')
              .weekday(1)
              .format('LL')
          }
          alertMessage='If you change your mind, please reactivate your account below.'
          alertStatusRed={true}
        />
      ) : null}
      <Box sx={{ mt: 0 }}>
        {props.isBioPage ? (
          <Hero heroTitle='My Biography' />
        ) : (
          <Hero
            heroTitle='My Account'
            currentTab={currentTab}
            tabChangeHandler={handleTabChange}
            tabs={tabs}
          />
        )}
      </Box>

      {currentTab === 'account-details' ? (
        <AccountDetails
          user={currentUser}
          showToast={showToastMsg}
          isBioPage={props.isBioPage}
          isAccountDetail={true}
          fetchUserDetails={refetch}
        />
      ) : currentTab === 'password-and-security' ? (
        <PasswordSecurity
          accountDisabledAt={currentUser.accountDisabledAt}
          id={currentUser.id}
          showToast={showToastMsg}
        />
      ) : null}
    </Layout>
  )
}

export default inject('userStore', 'breadcrumbStore')(observer(withRouter(MyAccount)))
