import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import './homepage.scss'
import heroImageOne from '../assets/images/working-assumptions-avery-hough-aoe-lonely-2020-1096x1000-RS.jpg'
import heroImageTwo from '../assets/images/working-assumptions-monica-carson-aoe-covid-19-enters-mind-of-youth-2020-1096x1000-RS.jpg'
import heroImageThree from '../assets/images/working-assumptions-guillermo-gaucin-aoe-confusion-2020-1096x1000-RS.jpg'
import heroImageFour from '../assets/images/working-assumptions-alexa-lorton-aoe-separate-2020-1096x1000-RS.jpg'
import heroImageFive from '../assets/images/working-assumptions-alicia-mares-aoe-untitled-2020-02-1096x1000-RS.jpg'
import heroImageSix from '../assets/images/working-assumptions-thomas-martin-aoe-alone-2020-1096x1000-RS.jpg'
import heroImageSeven from '../assets/images/working-assumptions-emily-cormack-aoe-caution-2020-1096x1000-RS.jpg'
import aboutImage from '../assets/images/working-assumptions-education-genesis-harris.jpg'
import quoteImageOne from '../assets/images/working-assumptions-education-robert-wheeler.jpg'
import quoteImageTwo from '../assets/images/working-assumptions-education-anya-ivory.jpg'
import quoteImageThree from '../assets/images/working-assumptions-education-sofia-araujo.jpg'
import { ArrowRight, Download, Mail } from 'react-feather'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'
import { Box, Grid, Typography } from '@mui/material'
import variables from '../ui/settings/_variables.scss'

// About us page
import exploringImage from '../assets/images/working-assumptions-education-about-df.jpg'
import aboutImageOne from '../assets/images/working-assumptions-education-about-curriculum.jpg'
import aboutImageTwo from '../assets/images/working-assumptions-education-about-curators.jpg'
import aboutImageThree from '../assets/images/working-assumptions-education-about-awards.jpg'
import aboutMap from '../assets/images/working-assumptions-education-about-map-042523.png'
import aboutSectionImage from '../assets/images/working-assumptions-risa-darlington-horta-untitled-01-2019-RT.jpg'
import { inject, observer } from 'mobx-react'

const EVENTS_QUERY = gql`
  query HomePageNextEvent {
    nextEvent {
      id
      name
      slug
      summary
      eventStart
      eventEnd
      linkToFeaturedImage
      published
    }
  }
`

const heroImages = [
  {
    id: 1,
    src: heroImageOne,
    alt: 'hero1'
  },
  {
    id: 2,
    src: heroImageTwo,
    alt: 'hero2'
  },
  {
    id: 3,
    src: heroImageThree,
    alt: 'hero3'
  },
  {
    id: 4,
    src: heroImageFour,
    alt: 'hero4'
  },
  {
    id: 5,
    src: heroImageFive,
    alt: 'hero5'
  },
  {
    id: 6,
    src: heroImageSix,
    alt: 'hero6'
  },
  {
    id: 7,
    src: heroImageSeven,
    alt: 'hero7'
  }
]
const randomImage = heroImages[Math.floor(Math.random() * heroImages.length)]

class Homepage extends Component {
  render() {
    return (
      <Layout>
        <Query query={EVENTS_QUERY}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) {
              return (
                <Typography variant='paragraph' component='p'>
                  Error loading classes
                </Typography>
              )
            }
            if (data) {
              return (
                <Fragment>
                  <Box className='container__public-body' sx={{background: `${variables.white}`}}>
                    <Box className='public-body__homepage'>
                      <Box className='homepage__hero'>
                        <Grid
                          container
                          direction='row'
                          justify='flex-end'
                          alignItems='center'
                        >
                          <Grid item md={7} sm={12} xs={12} sx={{pt: 0}}>
                            <Box className='homepage__hero--image'>
                              <Box component='img' src={randomImage.src} sx={{ width: '100%' }} />
                            </Box>
                          </Grid>
                          <Grid item md={5} sm={12} xs={12} pt={{ xs: '1rem !important', md: 0 }}>
                            <Box
                              sx={{
                                mb: {
                                  xs: '45px',
                                  md: 'auto'
                                }
                              }}
                              className='homepage__hero--copy'
                            >
                              <Typography
                                variant='h1'
                                className='oversize'
                                sx={{
                                  paddingBottom: '1rem',
                                  color: `${variables.publicBlue}`,
                                  letterSpacing: '0.2px'
                                }}
                              >
                                Welcome to <i>wrkxfmly</i>
                              </Typography>

                              <Typography
                                variant='paragraph'
                                component={'p'}
                                sx={{
                                  mb: '1rem',
                                  color: 'rgb(45, 45, 45)'
                                }}
                              >
                                Our high school education program gives students the space to share
                                their stories about the interplay of work and family through
                                photography.
                              </Typography>
                              <Box>
                                <Link to='/collection'>
                                  <Typography
                                    variant='link'
                                    className='large'
                                    sx={{
                                      color: `${variables.publicBlue}`,
                                      svg: { verticalAlign: 'middle' }
                                    }}
                                  >
                                    Explore Student Photographs <ArrowRight size={20} />
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box className='homepage__explore-photographs' sx={{ pt: 0 }}>
                        <Grid container direction='row' alignItems='center'>
                          <Grid item lg={6} md={6} sm={6} xs={12} justify='flex-end' >
                            <Box className='homepage__explore-photographs--copy'>
                              <Typography
                                variant='h1'
                                className='oversize'
                                sx={{
                                  color: `${variables.white}`
                                }}
                              >
                                About
                              </Typography>

                              <Typography
                                variant='paragraph'
                                component={'p'}
                                sx={{
                                  mt: '1rem',
                                  color: `${variables.white}`
                                }}
                              >
                                The <i>wrkxfmly</i> program is a multi-week assignment for high
                                school photography students. The assignment supports students in
                                making photographs and writing captions about work and family in
                                their own lives, from their own perspectives. <i>wrkxfmly</i> sparks
                                discussion, deepens family and community connections, and fosters
                                empathy and understanding. Photography classes, afterschool
                                programs, and community organizations across the country participate
                                in <i>wrkxfmly</i>, guided by professional teaching artists and
                                classroom teachers.
                              </Typography>
                              <Box
                                className='homepage__explore-photographs--cta'
                                sx={{ mt: '1rem' }}
                              >
                                <Link to='/collection'>
                                  <Typography
                                    variant='link'
                                    className='large'
                                    sx={{
                                      color: `${variables.white}`,
                                      textDecoration: 'underline'
                                    }}
                                  >
                                    Explore National Collection <ArrowRight size={20} />
                                  </Typography>
                                </Link>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className='homepage__explore-photographs--copy'>
                              <Box
                                component='img'
                                src={aboutImage}
                                sx={{
                                  width: '100%',
                                  height:{ xs:'auto', sm:'480px', md:'auto'}
                                }}
                              />
                              <Typography
                                variant='h6'
                                className='regular'
                                sx={{
                                  color: `${variables.white}`,
                                  mt: '1rem'
                                }}
                              >
                                Photograph by Genesis Harris, <i>In My Zone</i>, 2018
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box sx={{ mt: { xs: 5, sm: 10, lg: 15 }, mb: {xs: 10} }}>
                        <Grid
                          container
                          gap={{ xs: 5, sm: 0 }}
                          px={{
                            xs: '2rem',
                            sm: '3rem',
                            md: '5rem',
                            lg: '5rem'
                          }}
                        >
                          <Grid item md={6} sm={6} xs={12}>
                            <Typography
                              variant='h1'
                              className='bold'
                              sx={{
                                color: `${variables.blueHover}`,
                                mb: 2,
                                mt: { md: 5, lg: 10 }
                              }}
                            >
                              Flexible Curriculum
                            </Typography>
                            <Typography variant='h4' className='regular'>
                              We provide participating classes with a curriculum that includes a
                              custom web application, integrated print resources, and support from{' '}
                              <a
                                href='https://www.workingassumptions.org/about'
                                rel='noopener noreferrer'
                                target='_blank'
                              >
                                Working Assumptions staff
                              </a>{' '}
                              — all funded by Working Assumptions. Our curriculum gives teachers the
                              flexibility to run the assignment in keeping with their teaching style
                              and alongside their existing class schedule.
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            sx={{
                              pl: {
                                xs: '0',
                                sm: 2,
                                md: '4rem'
                              }
                            }}
                          >
                            <Box
                              component='img'
                              src={aboutImageOne}
                              alt={''}
                              sx={{
                                width: '100%',
                                clipPath:
                                  'polygon(48% 0, 100% 0, 100% 49%, 80% 100%, 21% 100%, 0 56%)'
                              }}
                            />
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          gap={{ xs: 5, sm: 0 }}
                          px={{
                            xs: '2rem',
                            sm: '3rem',
                            md: '5rem',
                            lg: '5rem'
                          }}
                          mt={{
                            xs: 5,
                            sm: 10
                          }}
                        >
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            sx={{
                              mt: { md: 5, lg: 10 },
                              display: {sm: 'none'},
                            }}
                          >
                            <Typography
                              variant='h1'
                              className='bold'
                              sx={{
                                color: `${variables.blueHover}`,
                                mb: 2
                              }}
                            >
                              Professional Mentorship
                            </Typography>
                            <Typography variant='h4' className='regular'>
                              All students in the program receive feedback sessions from our
                              dedicated teaching artists. Our team includes photographers,
                              photojournalists, and artists committed to helping students find ways
                              to push their work further.
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                          >
                            <Box
                              component='img'
                              src={aboutImageTwo}
                              alt={''}
                              sx={{
                                width: '100%',
                                clipPath: 'polygon(0 0, 79% 0, 100% 29%, 77% 100%, 34% 100%, 0 83%)'
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            sx={{
                              mt: { md: 5, lg: 10 },
                              display: {xs: 'none', sm: 'block'},
                              pl: {sm: 2, md: 8}
                            }}
                          >
                            <Typography
                              variant='h1'
                              className='bold'
                              sx={{
                                color: `${variables.blueHover}`,
                                mb: 2
                              }}
                            >
                              Professional Mentorship
                            </Typography>
                            <Typography variant='h4' className='regular'>
                              All students in the program receive feedback sessions from our
                              dedicated teaching artists. Our team includes photographers,
                              photojournalists, and artists committed to helping students find ways
                              to push their work further.
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          gap={{ xs: 5, sm: 0 }}
                          px={{
                            xs: '2rem',
                            sm: '3rem',
                            md: '5rem',
                            lg: '5rem'
                          }}
                          mt={{
                            xs: 5,
                            sm: 10
                          }}
                        >
                          <Grid item md={6} sm={6} xs={12} sx={{ mt: { md: 5, lg: 10 }, pr: {sm: 2, md: 0} }}>
                            <Typography
                              variant='h1'
                              sx={{
                                fontFamily: 'Calibre Bold,Helvetica,Verdana,sans-serif',
                                fontSize: '2rem',
                                color: `${variables.blueHover}`,
                                mb: 2
                              }}
                            >
                              National Award of Excellence
                            </Typography>
                            <Typography
                              variant='h3'
                              sx={{
                                fontFamily: '"Calibre", Helvetica, Verdana, sans-serif',
                                fontSize: '1.25rem'
                              }}
                            >
                              At the end of the assignment, students submit a final portfolio of
                              photographs to Working Assumptions for review by professional
                              curators. Selected photographs are published online and shown in
                              nationwide exhibitions, highlighting the importance of seeing cultures
                              of care through the eyes of young adults.
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            sx={{
                              pl: {
                                xs: '0',
                                sm: '0',
                                md: '4rem'
                              }
                            }}
                          >
                            <Box
                              component='img'
                              src={aboutImageThree}
                              alt={''}
                              sx={{
                                width: '100%',
                                clipPath: 'polygon(0 0, 90% 0, 100% 35%, 84% 100%, 34% 100%, 0 57%)'
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid
                          container
                          columnSpacing={{ sm: 2 }}
                          alignItems={{ xs: 'center', sm: 'flex-start' }}
                          direction={{
                            xs: 'column',
                            sm: 'row',
                            md: 'row',
                            lg: 'row'
                          }}
                          padding={{
                            xs: '3rem 2rem 5rem',
                            sm: '5rem 3rem 7.5rem',
                            md: '5rem 5rem 7.5rem',
                            lg: '5rem 5rem 10rem'
                          }}
                          sx={{
                            backgroundColor: '#ffe8d4',
                            clipPath: 'polygon(100% 0, 100% 89%, 25% 100%, 0 90%, 0 0)'
                          }}
                        >
                          <Grid item md={6} sm={6} xs={12}>
                            <Typography
                              variant='h1'
                              className='oversize'
                              sx={{
                                color: `${variables.publicGreen}`,
                                mt: { md: 5 }
                              }}
                            >
                              Get Involved
                            </Typography>
                            <Typography
                              variant='paragraph'
                              sx={{ m: { xs: '1rem 0' } }}
                              component='p'
                            >
                              New schools are always welcome to participate in the program. If you
                              are a teacher or a teaching artist, get in touch for more information
                              on bringing <i>wrkxfmly</i> to your students.
                            </Typography>
                            <Typography
                              variant='link'
                              component='a'
                              href='https://airtable.com/shrnq3Sof4mra2K1O'
                              target='_blank'
                              rel='noopener noreferrer'
                              sx={{
                                color: `${variables.publicGreen}`,
                                textDecoration: 'underline',
                                svg: { verticalAlign: 'middle' }
                              }}
                            >
                              Contact Us <ArrowRight size={20} />
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            sx={{
                              pl: {
                                xs: 0,
                                sm: 0,
                                md: '4rem'
                              },
                              pt: {
                                xs: 5.8,
                                sm: '0'
                              }
                            }}
                          >
                            <Box
                              component='img'
                              src={exploringImage}
                              alt={''}
                              sx={{ width: '100%' }}
                            />
                            <Typography
                              variant='h5'
                              sx={{
                                marginTop: 2
                              }}
                            >
                              Photograph by DF, <i>Abroad</i> 2018
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box
                        className='homepage__featured-photos'
                        sx={{
                          p: {
                            xs: `8px ${variables.spacingRegular} ${variables.spacingSmall}`,
                            sm: `52px ${variables.spacingRegular} 0`,
                            md: `55px ${variables.spacingRegular} ${variables.spacingLarge}`,
                            lg: `${variables.spacingLarge} 70px ${variables.spacingLarge}`
                          }
                        }}
                      >
                        <Box className='homepage__photo-quote'>
                          <Grid
                            container
                            // spacing={{ md: 2 }}
                            direction='row'
                            justify='flex-end'
                            alignItems='center'
                          >
                            <Grid item md={6} sm={12} xs={12}>
                              <Box>
                                <Box component='img' src={quoteImageOne} sx={{ width: '100%' }} />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              sm={12}
                              xs={12}
                              sx={{
                                padding: {
                                  xs: '2rem 0 0',
                                  sm: '2rem 1rem 20px 0',
                                  md: '1rem 0 1rem 2rem'
                                }
                              }}
                            >
                              <Typography variant='h3' className='semi-strong'>
                                “The photographs I took for this assignment were inspired by my
                                mother’s commitment to both her work and family. After work, she
                                spends her evenings at home taking care of my siblings and me. When
                                the house quiets down she heads back to work in her home office.”
                              </Typography>
                              <Typography variant='h6' className='regular' mt={{ xs: 4 }}>
                                Photograph by Robert Wheeler, <i>Untitled</i>, 2018
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box className='homepage__photo-quote' sx={{ mt: { md: 3, lg: 2 } }}>
                          <Grid
                            container
                            // spacing={{ md: 2 }}
                            direction='row'
                            justify='flex-end'
                            alignItems='center'
                          >
                            <Grid
                              item
                              md={6}
                              sm={12}
                              xs={12}
                              sx={{
                                display: {
                                  xs: 'none',
                                  sm: 'none',
                                  md: 'block'
                                },
                                padding: {
                                  xs: '2rem 0 0',
                                  sm: '2rem 0',
                                  md: '1rem 2rem 1rem 0'
                                }
                              }}
                            >
                              <Typography variant='h3' className='semi-strong'>
                                “This picture is of my father and my brother late at night. My dad
                                works all day and then has team practice or a game after school. He
                                is also a ride-share driver and, more often than not, comes home in
                                the early morning... He tries to stay up and spend time with us,
                                especially with my younger brother but he is very tired and often
                                falls asleep.”
                              </Typography>
                              <Typography variant='h6' className='regular' mt={{ xs: 4 }}>
                                Photograph by Anya Ivory, <i>Untitled</i>, 2018
                              </Typography>
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                              <Box>
                                <Box component='img' src={quoteImageTwo} sx={{ width: '100%' }} />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              sm={12}
                              xs={12}
                              sx={{
                                display: {
                                  xs: 'block',
                                  sm: 'block',
                                  md: 'none'
                                },
                                padding: {
                                  xs: '2rem 0 0',
                                  sm: '2rem 0 20px'
                                }
                              }}
                            >
                              <Typography variant='h3' className='semi-strong'>
                                “This picture is of my father and my brother late at night. My dad
                                works all day and then has team practice or a game after school. He
                                is also a ride-share driver and, more often than not, comes home in
                                the early morning... He tries to stay up and spend time with us,
                                especially with my younger brother but he is very tired and often
                                falls asleep.”
                              </Typography>
                              <Typography variant='h6' className='regular' mt={{ xs: 4 }}>
                                Photograph by Anya Ivory, <i>Untitled</i>, 2018
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box className='homepage__photo-quote' sx={{ mt: { md: 3, lg: 2 } }}>
                          <Grid
                            container
                            // spacing={{ md: 2 }}
                            direction='row'
                            justify='flex-end'
                            alignItems='center'
                          >
                            <Grid item md={6} sm={12} xs={12}>
                              <Box>
                                <Box component='img' src={quoteImageThree} sx={{ width: '100%' }} />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              md={6}
                              sm={12}
                              xs={12}
                              sx={{
                                padding: {
                                  xs: '2rem 0',
                                  sm: '2rem 0 48px',
                                  md: '1rem 0 1rem 2rem'
                                }
                              }}
                            >
                              <Typography variant='h3' className='semi-strong'>
                                “A six-year-old wants to pursue a dance career. Her name is Victoria
                                and she is my sister. She takes dance classes every Thursday. When
                                classes are no longer in session, she spends her days watching
                                aspiring ballet professionals on mom’s phone and practices what she
                                already knows on her own. She is a very determined young girl.”
                              </Typography>
                              <Typography variant='h6' className='regular' mt={{ xs: 4 }}>
                                Photograph by Sofia Araujo, <i>Striving</i>, 2018
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>

                      <Box
                        padding={{
                          xs: '5rem 2rem 2rem',
                          sm: '5rem 3rem 2rem',
                          md: '5rem 5rem 2rem'
                        }}
                        backgroundColor={variables.publicLightBlue}
                      >
                        <Grid
                          container
                          direction={{ xs: 'column', sm: 'row' }}
                          gap={{ xs: 2, md: 0 }}
                        >
                          <Grid item xs={12} sm={10} md={6}>
                            <Typography
                              variant='h1'
                              className='oversize'
                              sx={{
                                color: `${variables.blueHover}`
                              }}
                            >
                              Our Reach
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={10} md={6}>
                            <Typography variant='h4'>
                              In 2013, the <i>wrkxfmly</i> program began in one California school. Since
                              then, it has reached 16 states and Washington, D.C., 85 schools and
                              community organizations, and over 4,000 students.
                            </Typography>
                          </Grid>
                          <Typography variant='div' textAlign={'center'} mt={4}>
                            <Box component='img' src={aboutMap} alt={''} sx={{ width: '100%' }} />
                          </Typography>
                        </Grid>
                      </Box>

                      <Box>
                        <Grid
                          container
                          spacing={2}
                          alignItems={{ xs: 'center', sm: 'flex-start' }}
                          padding={{
                            xs: '5rem 2rem 5rem',
                            sm: '5rem 3rem',
                            md: '5rem',
                            lg: '5rem 5rem 5rem'
                          }}
                        >
                          <Grid item xs={12} sm={10} md={6}>
                            <Typography
                              variant='h1'
                              className='oversize'
                              sx={{
                                color: `${variables.blueHover}`
                              }}
                            >
                              Know a student in the program?
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={10} md={6}>
                            <Typography variant='h4'>
                              We are here to speak to family members and friends of students
                              participating in our assignments. If you have any questions about the
                              program, please get in touch.
                            </Typography>

                            <Box sx={{ mt: 4 }} display='grid'>
                              <Typography
                                variant='h4'
                                component='a'
                                className='small_size_semi_bold'
                                rel='noopener noreferrer'
                                target='_blank'
                                href={this.props.resourceStore.getResourceUrlByName('Family Guide')}
                                sx={{
                                  textDecoration: 'underline',
                                  svg: { verticalAlign: 'middle' },
                                  color: `${variables.blueHover}`
                                }}
                              >
                                <Download size={20} />
                                {'   '}Download Family Guide
                              </Typography>

                              <Typography
                                variant='h4'
                                className='small_size_semi_bold'
                                component='a'
                                href='mailto:education@workingassumptions.org'
                                sx={{
                                  textDecoration: 'underline',
                                  svg: { verticalAlign: 'middle' },
                                  mt: 2,
                                  color: `${variables.blueHover}`
                                }}
                              >
                                <Mail size={20} />
                                {'   '}Email Us
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className='homepage__about-section'
                        padding={{
                          xs: '5rem 2rem',
                          sm: '5rem 3rem 7.5rem',
                          md: '5rem 5rem 7.5rem',
                          lg: '5rem 5rem 10rem'
                        }}
                      >
                        <Grid container direction='row'>
                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className='homepage__explore-photographs--about-wa'>
                              <Typography
                                variant='h1'
                                className='oversize'
                                sx={{
                                  paddingBottom: '1rem',
                                  color: `${variables.white}`
                                }}
                              >
                                About Working Assumptions
                              </Typography>

                              <Typography
                                variant='paragraph'
                                sx={{
                                  mb: '1rem',
                                  color: `${variables.white}`
                                }}
                              >
                                We envision a future where society values care. To get there,
                                society has to see care. At Working Assumptions, we think in
                                pictures. We remember in pictures. We make jokes in pictures. We
                                talk in pictures. Strong pictures and good art feed us with energy
                                and propel us. We love making things. We experiment. We let ideas
                                emerge from photographs, and we don’t rush good ideas. We make art
                                happen about work and family.
                              </Typography>
                              <Box className='homepage__explore-photographs--cta' sx={{mt: 2, mb: {xs: 6, sm: 'auto'}}}>
                                <Typography
                                  component='a'
                                  href='https://workingassumptions.org'
                                  rel='noopener noreferrer'
                                  target='_blank'
                                  variant='link'
                                  sx={{
                                    color: `${variables.white}`,
                                    svg: { verticalAlign: 'middle' },
                                    ':hover': {
                                      color: `${variables.white}`
                                    }
                                  }}
                                  className='large'
                                >
                                  Learn More <ArrowRight size={20} />
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Box className='homepage__explore-photographs--image'>
                              <Box component='img' src={aboutSectionImage} sx={{p: {xs: '0', sm: '0 0 0 32px'}}}/>
                              <Typography variant='h6' className='regular' mt={2}>
                                Photograph by Risa Darlington-Horta, <i>Untitled</i>, 2019
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box
                        padding={{
                          xs: '5rem 2rem',
                          sm: '5rem 3rem',
                          md: '5rem',
                          lg: '5rem'
                        }}
                      >
                        <Grid
                          container
                          justify='flex-start'
                          alignItems='start'
                          direction={{ xs: 'column', md: 'row' }}
                          gap={{ xs: 2, md: 0 }}
                        >
                          <Grid item xs={12} sm={10} md={6}>
                            <Typography
                              variant='h1'
                              className='oversize'
                              sx={{
                                color: `${variables.blueHover}`
                              }}
                            >
                              Stay Informed
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={10} md={6}>
                            <Typography variant='h4' className='regular' mb={{ xs: 2 }}>
                              Sign up to receive updates about Working Assumptions shows, projects,
                              and more.
                            </Typography>

                            <Typography
                              variant='h4'
                              className='small_size_semi_bold'
                              component='a'
                              rel='noopener noreferrer'
                              target='_blank'
                              sx={{
                                textDecoration: 'underline',
                                svg: { verticalAlign: 'middle' },
                                color: `${variables.blueHover}`
                              }}
                              href='https://airtable.com/shrv2p2cPmtVIBlqN'
                            >
                              Join Our Mailing List
                              <ArrowRight size={20} />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Fragment>
              )
            }
          }}
        </Query>
      </Layout>
    )
  }
}

export default inject('userStore', 'resourceStore')(observer(Homepage))
