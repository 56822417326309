import { action, makeAutoObservable } from 'mobx'

class ResourcesStore {
	resources = []
	categories = []

  constructor() {
    // Call it here
    makeAutoObservable(this)
  }

	setCategories = action(categories => {
    this.categories = [...this.categories, ...categories]
  })
  setResources = action(resources => {
    this.resources = [...this.resources, ...resources]
  })
  getResourceUrlByName = action(resourceName => {
    let url = '#'
    let resources = this.resources.filter(resource => resource.fields.Name === resourceName)
    if (resources.length > 0) {
      if(resources[0].fields.PDF && resources[0].fields.PDF.length > 0)
        url=resources[0].fields.PDF[0].url
    }
    return  url
  })
}

export default ResourcesStore
