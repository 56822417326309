import React, { Component } from 'react'
import { Typography } from '@mui/material'
class UniqueLink extends Component {
  render() {
    return (
      <div className='invite-student__copy-link--link'>
        <Typography variant="h4" sx={{mb:0, pb:0}}>{this.props.randomString}</Typography>      
      </div>
    )
  }
}

export default UniqueLink
