import { gql } from '@apollo/client'
import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import Button from '../elements/Button.js'
import { Typography, Grid, Box } from '@mui/material'
import { PHOTO_ATTRIBUTES } from '../../graphql/photoAttributes'
import { Spinner } from 'react-bootstrap'
import './photoStatusStack.scss'
import { Award, Shield, Slash } from 'react-feather'
import BaseModal from '../template/BaseModal';


const NOMINATE_FOR_AWARD_MUTATION = gql`
  mutation UpdatePhotoAward($awardId: String, $photoId: ID!, $awardClass: String!) {
    updatePhotoAward(awardId: $awardId, photoId: $photoId, awardClass: $awardClass) {
      id
      photo {
        ...photoAttributes
      }
    }
  }
  ${PHOTO_ATTRIBUTES}
`

class EditAwardModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      awardId: this.props.awardId,
      photoId: this.props.photoId,
      nominatorName: this.props.nominatorName,
      awardClass: this.props.awardClass,
      isSubmitting: false
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      awardId: nextProps.awardId,
      photoId: nextProps.photoId,
      nominatorName: nextProps.nominatorName,
      awardClass: nextProps.awardClass,
    })
  }

  handleFormSubmit = (onMutate) => {
    this.setState({
      isSubmitting: true
    })
    onMutate({
      variables: {
        awardId: this.state.awardId,
        photoId: this.state.photoId,
        awardClass:this.state.awardClass
      }
    })
  }

  handleSubmitSuccess = () => {
    this.props.onHide()
    this.props.refetchPhoto()
  }

  submitAward = (awardType) => {
    this.setState({
      awardClass: awardType
    })
  }
  render() {
    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);
  
    };
    return <BaseModal
      {...this.props}
      show={this.props.show}
      onClick={this.props.onClick}
      value={this.props.value}
      open={this.props.show}
      onClose={handleClose}
      onHide={this.props.onHide}
    >
      <BaseModal.BaseModalHeader>
        Edit Award
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Box className='edit-award__modal-description'>
          <Typography variant="paragraph" component="p">Be sure you want to edit this award on behalf of the curator.</Typography>
          <Typography variant="paragraph" component="p">Reminder: this will remove the curator’s previous award choice from the web app.</Typography>
        </Box>
        <Box className='invite-student__description'>
          <Typography variant="paragraph" component="p">
            What is the updated award for {this.state.nominatorName}?
          </Typography>
        </Box>
        <Box className='edit-award__section'>
          <Box className={'edit-award__stack-award-button__icon'.concat(this.state.awardClass === 'Award of Excellence' ? '--selected' : '') }>
            <span
              className='link'
              title="Award of Excellence"
              onClick = {() => this.submitAward('Award of Excellence')}>
                <Award size={18}/> AoE
            </span>
          </Box>
          <Box className={'edit-award__stack-award-button__icon'.concat(this.state.awardClass === 'Honorable Mention' ? '--selected' : '')}>
            <span
              className='link'
              title="Honorable Mention"
              onClick = {() => this.submitAward('Honorable Mention')}>
                <Shield size={18}/> HM
            </span>
          </Box>
          <Box className={'edit-award__stack-award-button__icon'.concat(this.state.awardClass === 'None' ? '--selected' : '')}>
            <span
              className='link'
              title="Remove Award"
              onClick = {() => this.submitAward('None')}>
                <Slash size={18}/> Remove Award
            </span>
          </Box>
        </Box>
        <Mutation
          mutation={NOMINATE_FOR_AWARD_MUTATION}
          onCompleted={()=>{
            this.setState({isSubmitting: false})
            this.handleSubmitSuccess()
          }}
        >
          {(manageOnMutate, { data }) => (
            <BaseModal.BaseModalFooter>
              <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='edit-award__submit-buttons'>
                <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button
                      variant='contained'
                      type='submit'
                      disabled={this.state.isSubmitting }
                      title={this.state.isSubmitting ? <span className='icon-text-span'>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> Submitting... </span>
                      : 'Submit Updated Award' }
                      onClick={() => this.handleFormSubmit(manageOnMutate)}
                      sx={{width:{xs: '100%', sm: '100%'}}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                    <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4" className='small_size_semi_bold link' onClick={() => this.props.onHide()}>
                      Cancel
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </BaseModal.BaseModalFooter>
        )}</Mutation>
      </BaseModal.BaseModalBody>
    </BaseModal>
  }
}

export default EditAwardModal