import React from 'react'
import { Typography, Snackbar, Alert } from '@mui/material'
import variables from '../settings/_variables.scss'

const ToastNotification = props => {
  const { showToast, onClose, notificationType, toastMessage } = props
  const colorMap = {
    success: variables.greenStatus,
    warning: variables.yellowSccent,
    error: variables.redStatus,
    info: variables.blueAccent
  }
  const bgColor = colorMap[notificationType] || variables.greenStatus

  return (
    <Snackbar
      open={showToast}
      autoHideDuration={notificationType === 'warning' ? null : 6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={{ width: { sm: 350, md: 400 } }}
    >
      <Alert
        severity={notificationType}
        variant='filled'
        onClose={onClose}
        sx={{ backgroundColor: bgColor, width: '100%' }}
        closeText='X'
      >
        <Typography variant='h6' className='regular'>
          {toastMessage}
        </Typography>
      </Alert>
    </Snackbar>
  )
}

export default ToastNotification
