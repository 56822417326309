import React, { Component, Fragment } from 'react'
import { gql } from '@apollo/client'
import { Typography } from '@mui/material'
import '../organisms/photoeditor.scss'
import { observer, inject } from 'mobx-react'
import { Mutation, Query } from '@apollo/client/react/components'
import NakedFormField from '../elements/NakedFormField.js'
import Loading from '../elements/Loading.js'
import Button from '../elements/Button.js'
import { Spinner } from 'react-bootstrap'

const QUERY = gql`
  query Photo($photoId: ID!) {
    photo(id: $photoId) {
      id
      caption
      title
      attribution
      url
      altText
      mrAdminNotes
      project {
        id
        submitted
      }
    }
  }
`
const MUTATION = gql`
  mutation UpdatePhotoMetadata($id: ID!, $altText: String) {
    updatePhotoMetadata(id: $id, altText: $altText) {
      id
      caption
      date
      title
      altText
      mrAdminNotes
    }
  }
`

class PhotoAltTextEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: {
        altText: false
      },
      photo: {
        altText: props.photo.altText
      },
      isSubmitting: false
    }
    this.altTextInputRef = React.createRef()
  }

  componentDidMount() {
    const $refs = [this.altTextInputRef.current].forEach(
      elm =>
        elm &&
        elm.classList.toggle(
          `${elm.value & (elm.value.trim() === '') ? 'greyed-out' : 'blackened-out'}`
        )
    )
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.photo.id !== nextProps.photo.id) {
      this.setState({
        ...this.state,
        editing: {
          altText: false
        },
        photo: {
          altText: nextProps.photo.altText || ''
        },
        updated: true
      })
    }
  }

  handlePhotoAttributeChange = event => {
    const { name, value } = event.target
    const oldPhoto = { ...this.state.photo }
    if (name.toLowerCase() === 'altText' && value === '')
      event.target.setAttribute('placeholder', '')
    this.setState({
      ...this.state,
      photo: { ...oldPhoto, [name]: value },
      updated: false
    })
  }

  toggleEdit = key => {
    this.setState({
      editing: Object.assign(this.state.editing, {
        [key]: !this.state.editing[key]
      })
    })
  }

  adjustInputColor = elem => {
    if (!elem) return
    const type = elem.name.toLowerCase()
    if (this.state.photo[`${type}`] && this.state.photo[`${type}`].trim() === '') {
      const placeholder = 'Alt Text'
      elem.setAttribute('placeholder', placeholder)
      elem.classList.replace('blackened-out', 'greyed-out')
    } else {
      elem.classList.replace('greyed-out', 'blackened-out')
    }
    this.toggleEdit(elem.name)
    this.props.toggleEditing(false)
  }

  clearElementText = elem => {
    this.toggleEdit(elem.name)
    const placeholder = this.state.photo.altText || ''
    if (placeholder === '') elem.classList.replace('blackened-out', 'greyed-out')
    elem.setAttribute('placeholder', placeholder)
    this.props.toggleEditing(true)
  }

  mutateAttributeChange = (queryData, onMutate) => {
    this.setState({
      isSubmitting: true
    })
    const elem = this[`altTextInputRef`].current
    const updateTypeRequired = elem.value !== this.props.photo.altText
    if (updateTypeRequired) {
      onMutate({
        variables: Object.assign(queryData.photo, {
          altText: elem.value,
          id: this.props.photoId
        })
      })
    }
    this.setState({
      ...this.state,
      editing: {
        altText: false
      },
      photo: {
        altText: this[`altTextInputRef`].current.value
      }
    })
  }

  render() {
    const { photoId, userStore, photo } = this.props
    const { mutateAttributeChange, handlePhotoAttributeChange } = this
    const { role } = userStore
    const shouldShow = (role === 'appadmin' &&
    photo.submitted &&
    photo.awards.length > 0)
    return shouldShow ? (
      <Query query={QUERY} variables={{ photoId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <Typography variant="paragraph" component="p">Error :(</Typography>

          const queryData = data
          const { photo } = queryData

          const hasBeenSubmitted = photo.project.submitted
          const isEditable =
            !window.location.pathname.includes('national') &&
            ((['student'].includes(role) && !hasBeenSubmitted) || role === 'appadmin')
          return (
            <div className='photo-alttext__section'>
              <Mutation
                mutation={MUTATION}
                onCompleted={() => {
                  this.setState({
                    isSubmitting: false
                  })
                }}
              >
                {(onMutate, { data }) => (
                  <Fragment>
                    <div className='award-nominator__publish-photograph--header'>
                      <Typography variant="h2">Alt Text</Typography>
                    </div>
                    <div className='photo-alttext__instructions'>
                      <Typography variant="paragraph" component="p">Briefly and accurately describe the photograph for visually impaired audiences.</Typography>
                    </div>
                    <div >
                      <div>
                        <div
                          className={`mw-100 ${
                            isEditable ? 'body__editor--caption' : 'body__editor--caption2'
                          } body__editor--alt-text`}
                        >
                          <Fragment>
                            <blockquote
                              className={`${
                                this.state.editing.altText
                                  ? 'blockquote--editing'
                                  : 'blockquote--editable'
                              }`}
                            >
                              {isEditable ? (
                                <NakedFormField
                                  multiline={true}
                                  fullWidth={true}
                                  type='text'
                                  name='altText'
                                  placeholder='Alt Text'
                                  onChange={handlePhotoAttributeChange}
                                  value={this.state.photo.altText || ''}
                                  onBlur={() => this.adjustInputColor(this.altTextInputRef.current)}
                                  onFocus={() => this.clearElementText(this.altTextInputRef.current)}
                                  inputRef={this.altTextInputRef}
                                  rows={10}
                                />
                              ) : (
                                `${photo.altText || ''}`
                              )}
                            </blockquote>
                          </Fragment>
                          {isEditable ? (
                            <div className='photo-editor-page__button-section'>
                              <Button
                                title={this.state.isSubmitting ? <span className='icon-text-span'><Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                /> Saving...</span> : 'Save Alt Text'}
                                className='outline-buttons photo-editor-alt-page'
                                // variant='outline-dark'
                                onClick={() => this.mutateAttributeChange(queryData, onMutate)}
                                disabled={
                                  (!(
                                    !this.state.updated &&
                                    (this.state.editing.altText ||
                                      (this.altTextInputRef.current &&
                                        photo.altText !== this.altTextInputRef.current.value))
                                  )) || this.state.isSubmitting
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                        <div />
                      </div>
                    </div>
                  </Fragment>
                )}
              </Mutation>
            </div>
          )
        }}
      </Query>
    ) : null
  }
}

export default inject('userStore')(observer(PhotoAltTextEditor))
