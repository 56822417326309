import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Box, Grid, Typography } from '@mui/material'
import './projectDetailsMoreInformation.scss'

const ProjectDetailsMoreInformation = ({ cohort, project }) => {
  const [currentProject, setCurrentProject] = useState(project);
  const { name, facilitator, teacher, endDate } = cohort

  useEffect(() => {
    setCurrentProject(project);
  }, [project]);

  return (
    <Box sx={{ flexGrow: 1, px: {xs: 4, md: 6}, mt: { xs: 6 }, backgroundColor:'#f7f7f7' }}>
      <Typography variant='h2' className='strong'>More About This Project</Typography>
      <Grid container spacing={4} sx={{pb: 6, mt: 0}}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant='dataLabel' component='p'>Class Name</Typography>
          <Typography variant='dataLabel' className='content' component='p'>{name}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant='dataLabel' component='p'>Assignment Submission Due Date</Typography>
          <Typography variant='dataLabel' className='content' component='p'>{moment(endDate).format('LL')}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant='dataLabel' component='p'>Project Submitted On</Typography>
          <Typography variant='dataLabel' className='content' component='p'>
            {currentProject && currentProject.submitted ? (currentProject.submittedUpdatedAt ? moment(currentProject.submittedUpdatedAt).format('LL') : 'Project Not Submitted') : 'Project Not Submitted'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant='dataLabel' component='p'>Class Teacher</Typography>
          <Typography variant='dataLabel' className='content' component='p'>{teacher && teacher.name ? teacher.name : ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant='dataLabel' component='p'>Teaching Artist</Typography>
          <Typography variant='dataLabel' className='content' component='p'>{facilitator && facilitator.name ? facilitator.name : ''}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProjectDetailsMoreInformation
