import { action, makeAutoObservable } from 'mobx'
import { isPublishSectionEnable } from '../utils/commonFunctions'

const breadcrumbRouteAssociations = [{
  dashboard: {
    pageName: 'Dashboard',
    link: '/dashboard',
    child: [],
    roles: ['appadmin']
  },
  classes: {
    pageName: 'Classes',
    link: '/classes',
    child: ['/current', '/create-class', '/class-detail', '/project-detail'],
    roles: ['facilitator', 'teacher', 'appadmin', 'curator']
  },
  users: {
    pageName: 'Users',
    link: '/users',
    child: ['/create-user', '/user-details'],
    roles: ['appadmin']
  },
  projects: {
    pageName: 'Projects',
    link: '/projects',
    child: ['/project-detail', '/self-use-agreement'],
    roles: ['student']
  },
  schools: {
    pageName: 'Schools',
    link: '/schools',
    child: ['/school-details'],
    roles: ['appadmin',]
  },
  reviews: {
    pageName: 'Reviews',
    link: '/reviews',
    child: ['/class-review'],
    roles: ['curator', 'appadmin']
  },
  resources: {
    pageName: 'Resources',
    link: '/resources',
    child: ['/resource-category']
  },
  notifications: ['/notifications'],
  events: ['/events'],
  account: {
    pageName: 'Account Details',
    link: '/account-details',
    child: []
  },
  biography: ['/my-biography']
}]

  const collectionMenuItem = {
    pageName: 'National Photos',
    link: '/national-photos',
    child: []
  }
  if (isPublishSectionEnable)
    // Adding first and second menu item through splice methods arr.splice(index, 0, element);
  {
    breadcrumbRouteAssociations.splice(5, 0, collectionMenuItem)
  }
class BreadcrumbStore {
  breadcrumbs =
    window.localStorage.getItem('bc') !== '' ? JSON.parse(window.localStorage.getItem('bc')) : []
  currentUserRole = null
  constructor() {
    // Call it here
    makeAutoObservable(this)
    window.localStorage.setItem('bc', JSON.stringify(this.breadcrumbs))
  }

  defaultBredcrumbObjec = () => {
    return {}
  }

  setCurrentUserRole = action(role => {
    this.currentUserRole = role
  })

  addBreadcrumb = action(pageDetails => {
    const { link, basePath } = pageDetails
    if (this.breadcrumbs.length > 0) {
      const isBreadcrumbAva = this.breadcrumbs.findIndex(
        crumb => link === crumb.link || basePath === crumb.basePath
      )
      // let parentRoute = null
      // let parentRouteKey = null
      // for (const [key, value] of Object.entries(breadcrumbRouteAssociations)) {
      //   if (value.child && value.child.find(v => v === basePath) && value.roles.includes(this.currentUserRole.toLowerCase())) {
      //     parentRoute = value.link
      //     parentRouteKey = key
      //   }
      // }
      // console.log(parentRouteKey)
      // console.log(parentRoute)
      // if (parentRoute === this.breadcrumbs[0].basePath) {
      //   if (isBreadcrumbAva < 0) {
      //     this.breadcrumbs.push(pageDetails)
      //   } else {
      //     this.breadcrumbs = this.breadcrumbs.slice(0, isBreadcrumbAva + 1)
      //     this.breadcrumbs[this.breadcrumbs.length - 1] = {
      //       ...this.breadcrumbs[this.breadcrumbs.length - 1],
      //       ...pageDetails,
      //       isActive: true
      //     }
      //   }
      //   this.breadcrumbs[this.breadcrumbs.length - 2] = {
      //     ...this.breadcrumbs[this.breadcrumbs.length - 2],
      //     isActive: false
      //   }
      // } else {
      //   this.breadcrumbs = []
      //   this.breadcrumbs = [
      //     {
      //       ...breadcrumbRouteAssociations[parentRouteKey],
      //       basePath: breadcrumbRouteAssociations[parentRouteKey].link
      //     },
      //     {
      //       ...pageDetails
      //     }
      //   ]
      // }
      if (isBreadcrumbAva < 0) {
        this.breadcrumbs.push(pageDetails)
      } else {
        this.breadcrumbs = this.breadcrumbs.slice(0, isBreadcrumbAva + 1)
        this.breadcrumbs[this.breadcrumbs.length - 1] = {
          ...this.breadcrumbs[this.breadcrumbs.length - 1],
          ...pageDetails,
          isActive: true
        }
      }
      this.breadcrumbs[this.breadcrumbs.length - 2] = {
        ...this.breadcrumbs[this.breadcrumbs.length - 2],
        isActive: false
      }
    } else {
      this.breadcrumbs = [{ ...pageDetails }]
    }

    window.localStorage.setItem('bc', JSON.stringify(this.breadcrumbs))
  })

  resetBreadcrumb = action(() => {
    this.breadcrumbs = []
  })
}

export default BreadcrumbStore
