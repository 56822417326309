import React, { Component } from 'react'
import { gql } from '@apollo/client'
import '../organisms/photoeditor.scss'
import { Typography, Box, Grid } from '@mui/material'
import { observer, inject } from 'mobx-react'
import { Mutation, Query } from '@apollo/client/react/components'
import Loading from '../elements/Loading.js'
import Button from '../elements/Button.js'
import { Spinner } from 'react-bootstrap'
import FormFieldMultiline from '../elements/FormFieldMultiline'

const QUERY = gql`
  query Photo($photoId: ID!) {
    photo(id: $photoId) {
      id
      caption
      title
      attribution
      url
      altText
      mrAdminNotes
      project {
        id
        submitted
      }
    }
  }
`
const MUTATION = gql`
  mutation UpdatePhotoMetadata($id: ID!, $mrAdminNotes: String) {
    updatePhotoMetadata(id: $id, mrAdminNotes: $mrAdminNotes) {
      id
      caption
      date
      title
      altText
      mrAdminNotes
    }
  }
`

class ModelReleasesNoteEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editing: {
        mrAdminNotes: false
      },
      photo: {
        mrAdminNotes: props.photo.mrAdminNotes
      },
      isSubmitting: false
    }
    this.mrAdminNotesInputRef = React.createRef()
  }

  componentDidMount() {
    const $refs = [this.mrAdminNotesInputRef.current].forEach(
      elm =>
        elm &&
        elm.classList.toggle(
          `${elm.value & (elm.value.trim() === '') ? 'greyed-out' : 'blackened-out'}`
        )
    )
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.photo.id !== nextProps.photo.id) {
      this.setState({
        ...this.state,
        editing: {
          mrAdminNotes: false
        },
        photo: {
          mrAdminNotes: nextProps.photo.mrAdminNotes || null
        },
        updated: true
      })
    }
  }

  handlePhotoAttributeChange = event => {
    const { name, value } = event.target
    const oldPhoto = { ...this.state.photo }
    if (name.toLowerCase() === 'mrAdminNotes' && value === '')
      event.target.setAttribute('placeholder', '')
    this.setState({
      ...this.state,
      photo: { ...oldPhoto, [name]: value },
      updated: false
    })
  }

  toggleEdit = key => {
    this.setState({
      editing: Object.assign(this.state.editing, {
        [key]: !this.state.editing[key]
      })
    })
  }

  adjustInputColor = elem => {
    if (!elem) return
    const type = elem.name.toLowerCase()
    if (this.state.photo[`${type}`] && this.state.photo[`${type}`].trim() === '') {
      const placeholder = 'Model Release Note'
      elem.setAttribute('placeholder', placeholder)
      elem.classList.replace('blackened-out', 'greyed-out')
    } else {
      elem.classList.replace('greyed-out', 'blackened-out')
    }
    this.toggleEdit(elem.name)
    this.props.toggleEditing(false)
  }

  clearElementText = elem => {
    this.toggleEdit(elem.name)
    const placeholder = this.state.photo.mrAdminNotes || ''
    if (placeholder === '') elem.classList.replace('blackened-out', 'greyed-out')
    elem.setAttribute('placeholder', placeholder)
    this.props.toggleEditing(true)
  }

  mutateAttributeChange = (queryData, onMutate) => {
    this.setState({
      isSubmitting: true
    })
    const elem = this[`mrAdminNotesInputRef`].current
    const updateTypeRequired = elem.value !== this.props.photo.mrAdminNotes
    if (updateTypeRequired) {
      onMutate({
        variables: {
          mrAdminNotes: elem.value,
          id: this.props.photoId
        }})
    }
    this.setState({
      ...this.state,
      editing: {
        mrAdminNotes: false
      },
      photo: {
        mrAdminNotes: this[`mrAdminNotesInputRef`].current.value
      }
    })
  }

  render() {
    const { photoId, userStore } = this.props
    const { mutateAttributeChange, handlePhotoAttributeChange } = this
    const { role } = userStore

    return (
      <Query query={QUERY} variables={{ photoId }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) return <Typography variant="paragraph" component="p">Error :(</Typography>

          const queryData = data
          const { photo } = queryData

          // const hasBeenSubmitted = photo.project.submitted
          const isEditable =  role === 'appadmin'
          return (
            <Box component={'div'}>
              <Mutation
                mutation={MUTATION}
                onCompleted={() => {
                  this.setState({
                    isSubmitting: false
                  })
                }}
              >
                {(onMutate, { data }) => (
                  <Grid
                    container
                    sx={{
                      textAlign: { xs: 'center', sm: 'left' }
                    }}
                    gap={{ xs: 2, sm: 3 }}
                    alignItems='center'
                  >
                    <Grid item xs={12}>
                      {isEditable ? (
                        <FormFieldMultiline
                          formFieldId='mr-admin-notes'
                          formFieldPlaceholder='Model Release Note'
                          formFieldVariant='filled'
                          formFieldName='mrAdminNotes'
                          formFieldDefaultValue={this.state.photo.mrAdminNotes || ''}
                          fullWidth={true}
                          multiline={true}
                          inputRef={this.mrAdminNotesInputRef}
                          rows='3'
                          onChange={handlePhotoAttributeChange}
                        />
                      ) : (
                        `${photo.mrAdminNotes || ''}`
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} sx={{mt: {xs: 1, sm: 0}}}>
                      {isEditable ? (
                        <Button
                          title={this.state.isSubmitting ? <span className='icon-text-span'><Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          /> Saving...</span> : 'Save Note'}
                          onClick={() => this.mutateAttributeChange(queryData, onMutate)}
                          disabled={
                            (!(
                              !this.state.updated &&
                              (this.state.editing.mrAdminNotes ||
                                (this.mrAdminNotesInputRef.current &&
                                  photo.mrAdminNotes !== this.mrAdminNotesInputRef.current.value))
                            )) || this.state.isSubmitting
                          }
                          sx={{width: {xs: '100%'}}}
                        />
                      ) : null}
                    </Grid>

                  {
                    this.state?.photo?.mrAdminNotes !== this.props?.photo?.mrAdminNotes
                    &&  !this.state.isSubmitting
                    ? <Grid
                        item
                        xs={12}
                        sm={3}
                      >
                          <Typography
                            variant='link'
                            component={'p'}
                            onClick={() => {this.setState({
                                photo: {
                                  mrAdminNotes: this.props.photo.mrAdminNotes
                                }
                              })
                            }}
                          >
                            Cancel
                          </Typography>
                        </Grid>
                      : null
                    }

                  </Grid>
                )}
              </Mutation>
            </Box>
          )
        }}
      </Query>
    )
  }
}

export default inject('userStore')(observer(ModelReleasesNoteEditor))
