import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { inject, observer } from 'mobx-react';
import Layout from '../ui/template/Layout';
import './ErrorPage.scss';
import { getTextContentByPage } from '../content/textContent';
import { useNavigate } from 'react-router-dom';

const ErrorPage = ({errorCode, heading, subHeading, userStore, withInPage}) => {
  const [loginType] = useState(userStore.isAuthenticated ? 'PRIVATE' : 'PUBLIC')
  const [pageType] = useState(errorCode || '');
  const navigate = useNavigate()

  useEffect(() => {
    if(errorCode === '403' && !userStore.isAuthenticated) {
      navigate('/login')
    }
  }, [errorCode, navigate, userStore.isAuthenticated])

  const getPageContent = (textIntroHeading, textIntroSubHeading) => {
    return (
      <Grid sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', paddingX: 2, backgroundColor: '#f7f7f7' }}>
        <Grid item>
          <Box sx={{ mb: 2, color: '#005691' }}>
            <Typography variant="h2" component="h2">{textIntroHeading}</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="p" component="div" >{textIntroSubHeading}</Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const textIntroHeading = heading ? heading : getTextContentByPage('ERRORPAGE', loginType, `${pageType}_HEADING`);
  const textIntroSubHeading = subHeading ? subHeading : getTextContentByPage('ERRORPAGE', loginType, `${pageType}_SUBTEXT`);
  const pageContent = getPageContent(textIntroHeading, textIntroSubHeading);

  return withInPage ? pageContent : <Layout>{pageContent}</Layout>;
}

export default inject('userStore')(observer(ErrorPage));
