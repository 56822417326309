import MetaTags from 'react-meta-tags'
// import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { withRouter } from './customHoc'

function PagesMetaTags(props) {
    const index = props.index !== undefined ? props.index : true

    if(!index) {
        return (
            <MetaTags>
                <>{!index ? <meta name="robots" content="noindex" /> : ''}</>
            </MetaTags>
        )
    } else {
        const metaTags = props.metadataStore
        const pathname = window.location.pathname
        let page
        if (pathname === '/' || pathname === '')
            page = 'home'
        else
            page = pathname.substring(1).toLowerCase();

        let metaData = metaTags.metadata.filter(mData => (mData.fields.Page.toLowerCase() === page))
        let defaultMetaData = metaTags.metadata.filter(data => (data.fields.Page.toLowerCase() === 'default'))
        let pageMetaData = metaData.length ? metaData : defaultMetaData

        return (
            <MetaTags>
                <>{!index ? <meta name="robots" content="noindex" /> : ''}</>
                {
                    pageMetaData.length ?
                    <>
                        <title>{pageMetaData[0].fields.Title}</title>
                        <meta name="description" content={pageMetaData[0].fields.Description} />
                        <meta name="keywords" content={pageMetaData[0].fields.Keywords} />

                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={pageMetaData[0].fields.Title} />
                        <meta property="og:description" content={pageMetaData[0].fields.Description} />
                        <meta property="og:image"  content={pageMetaData[0].fields ? (pageMetaData[0].fields.Image ? pageMetaData[0].fields.Image[0].url : '') : ''} />
                        <meta property="og:keywords" content={pageMetaData[0].fields.Keywords} />

                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content={pageMetaData[0].fields.Title} />
                        <meta name="twitter:description" content={pageMetaData[0].fields.Description} />
                        <meta name="twitter:image" content={pageMetaData[0].fields ? (pageMetaData[0].fields.Image ? pageMetaData[0].fields.Image[0].url : '') : ''} />
                        <meta name="twitter:keywords" content={pageMetaData[0].fields.Keywords} />
                    </>
                    :
                    <></>
                }
            </MetaTags>
        )
    }
}

export default inject('metadataStore')(observer(withRouter(PagesMetaTags)))
