import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Box, Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { Component } from 'react'
import statesList from '../../utils/USAStates.js'
import Button from '../elements/Button.js'
import FormField from '../elements/FormField.js'
import FormSelect from '../elements/FormSelect.js'
import SchoolDetailsFooter from '../molecules/SchoolDetailsFooter.js'
import variables from '../settings/_variables.scss'
import { validationText } from '../../content/validationText.js'
import Cancel from '../elements/Cancel.js'

const MUTATION = gql`
  mutation UpdateSchool(
    $id: Int!
    $name: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $postCode: String
    $website: String
    $demographicLink: String
    $schoolType: String
    $schoolDistrict: String
  ) {
    updateSchool(
      id: $id
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      postCode: $postCode
      website: $website
      demographicLink: $demographicLink
      schoolType: $schoolType
      schoolDistrict: $schoolDistrict
    ) {
      name
      id
    }
  }
`

const schoolTypeOptions = [
  { value: 'Public', label: 'Public' },
  { value: 'Private', label: 'Private' },
  { value: 'Charter', label: 'Charter' },
  { value: 'Magnet', label: 'Magnet' },
  { value: 'Other', label: 'Other' }
]

class SchoolInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalFacilitatorDetailsShow: false,
      modalTeacherDetailsShow: false,
      modalTeacher: {},
      modalFacilitator: {},
      errors: {},
      disabled: true,
      schoolName: props.school.name,
      address1: props.school.mailingAddress.address1,
      address2: props.school.mailingAddress.address2 || '',
      city: props.school.mailingAddress.city,
      state: props.school.mailingAddress.state,
      zip: props.school.mailingAddress.postCode,
      schoolWebsite: props.school.website || '',
      demographicLink: props.school.demographicLink || '',
      schoolType: props.school.schoolType,
      schoolDistrict: props.school.schoolDistrict || ''
    }
    this.initialState = this.state
    this.handleFormChange = this.handleFormChange.bind(this)
    this.validateChange = this.validateChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e, onMutate) {
    e.preventDefault()
    this.setState({ submitted: true })
    if (!this.state.submitted) {
      const body = {
        name: this.state.schoolName,
        address1: this.state.address1,
        address2: this.state.address2,
        city: this.state.city,
        state: this.state.state,
        postCode: this.state.zip,
        website: this.state.schoolWebsite,
        demographicLink: this.state.demographicLink,
        schoolType: this.state.schoolType,
        schoolDistrict: this.state.schoolDistrict
      }
      onMutate({
        variables: {
          id: parseInt(this.props.school.id),
          ...body
        }
      })
      this.initialState = { ...this.initialState, ...body }
    }
  }
  handleFormChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      disabled: false
    })
    this.validateChange(e)
  }
  validateChange(e) {
    const urlRegexp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
    const fieldName = e.target.name
    const value = e.target.value
    const nameFormat = /(\\|\/)/;
    switch (fieldName) {
      case 'schoolName':
        if (value === '') {
          this.setState(
            {
              errors: {
                ...this.state.errors,
                schoolName: validationText.school.error.name
              }
            },
            () => this.isDisable()
          )
        } else if (value.length > 70 ) {
          this.setState({
            errors: {
              ...this.state.errors,
              schoolName: 'Maximum character limit 70'
            },
            disabled:true
          })
        } else if (nameFormat.test(value))
        {
          this.setState({
            errors: {
              ...this.state.errors,
              schoolName: 'Please do not use forward slash "/" in name.'
            },
            disabled: true
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, schoolName: null }
          })
        }
        break
      case 'address1':
        if (value === '') {
          this.setState(
            {
              errors: {
                ...this.state.errors,
                address1: validationText.school.error.address
              }
            },
            () => this.isDisable()
          )
        } else {
          this.setState({
            errors: { ...this.state.errors, address1: null }
          })
        }
        break
      case 'zip':
        if (value === '') {
          this.setState({
            errors: {
              ...this.state.errors,
              zip: validationText.school.error.zip
            }
          })
        } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
          this.setState(
            {
              errors: {
                ...this.state.errors,
                zip: validationText.school.error.zip
              }
            },
            () => this.isDisable()
          )
        } else {
          this.setState({
            errors: { ...this.state.errors, zip: null }
          })
        }
        break
      case 'city':
        if (value === '') {
          this.setState(
            {
              errors: {
                ...this.state.errors,
                city: validationText.school.error.city
              }
            },
            () => this.isDisable()
          )
        } else {
          this.setState({
            errors: { ...this.state.errors, city: null }
          })
        }
        break
      case 'schoolWebsite':
        if (!urlRegexp.test(value) && value) {
          this.setState(
            {
              errors: { ...this.state.errors, schoolWebsite: validationText.school.error.url }
            },
            () => this.isDisable()
          )
        } else {
          this.setState({
            errors: { ...this.state.errors, schoolWebsite: null }
          })
        }
        break
      case 'demographicLink':
        if (!urlRegexp.test(value) && value) {
          this.setState(
            {
              errors: { ...this.state.errors, demographicLink: validationText.school.error.url }
            },
            () => this.isDisable()
          )
        } else {
          this.setState({
            errors: { ...this.state.errors, demographicLink: null }
          })
        }
        break
      default:
        return
    }
  }

  isDisable = () => {
    this.setState({
      disabled: !_.isEmpty(
        Object.values(this.state.errors).filter(x => x !== undefined && x !== '' && x !== null)
      )
    })
  }

  handleCancel = () => {
    this.setState({ ...this.initialState })
    this.props.showToast('info', validationText.generic.cancel)
  }

  render() {
    const { handleSubmit, handleFormChange } = this
    const { school } = this.props
    return (
      <Box container sx={{ px: { xs: 4 }, pt: { xs: 5, sm: 5, md: 6, lg: 10 } }}>
        <Box>
          <Grid container gap={{xs: 2}}>
            <Grid item sm={10} md={8}>
            <Typography variant='h1' className='bold'>
                School Details
              </Typography>
            </Grid>
            <Grid item sm={10} md={8}>
            <Typography variant='paragraph' component='p'>
                Any changes that you make to this information will be seen by all users connected to
                this school.
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{mt:{ xs: 3}}}>
            <Mutation
              mutation={MUTATION}
              onCompleted={() => {
                this.props.showToast(
                  'success',
                  validationText.school.success.update
                )
                this.props.refetchSchoolDetail()
                this.setState({ disabled: true, submitted: false })
              }}
            >
              {(onMutate, { data }) => {
                return (
                  <Box>
                    <form noValidate>
                      <Box>
                        <Grid container sx={{ mb: { xs: 7, sm: 6, md: 6 } }} spacing={3}>
                          <Grid item sm={8} xs={12} lg={4}>
                            <FormField
                              formFieldId='custom-css-outlined-school-name'
                              formFieldLabel='School Name'
                              formFieldVariant='filled'
                              formFieldType='text'
                              formFieldName='schoolName'
                              formFieldError={this.state.errors.schoolName || null}
                              onChange={handleFormChange}
                              formFieldValue={this.state.schoolName}
                              className='custom-input-bg'
                            />
                          </Grid>
                        </Grid>
                        <Box sx={{ mb: { xs: 7, sm: 6, md: 6 } }}>
                          <Box sx={{ mb: 3 }}>
                            <Typography variant='h2' className='semi-strong'>
                              School Address
                            </Typography>
                          </Box>
                          <Box>
                            <Grid container spacing={3} rowSpacing={5}>
                              <Grid item sm={8} xs={12} lg={4}>
                                <FormField
                                  formFieldId='custom-css-outlined-address-1'
                                  formFieldLabel='Address Line 1'
                                  formFieldVariant='filled'
                                  formFieldType='text'
                                  formFieldName='address1'
                                  formFieldError={this.state.errors.address1 || null}
                                  onChange={handleFormChange}
                                  formFieldValue={this.state.address1}
                                  className='custom-input-bg'
                                />
                              </Grid>
                              <Grid item sm={8} xs={12} lg={4}>
                                <FormField
                                  formFieldId='custom-css-outlined-address-2'
                                  formFieldLabel='Address Line 2 (Optional)'
                                  formFieldVariant='filled'
                                  formFieldType='text'
                                  formFieldName='address2'
                                  onChange={handleFormChange}
                                  formFieldValue={this.state.address2}
                                  className='custom-input-bg'
                                />
                              </Grid>
                              <Grid item sm={8} xs={12} lg={4.01}>
                                <FormField
                                  formFieldId='custom-css-outlined-city'
                                  formFieldLabel='City'
                                  formFieldVariant='filled'
                                  formFieldType='text'
                                  formFieldName='city'
                                  formFieldError={this.state.errors.city || null}
                                  onChange={handleFormChange}
                                  formFieldValue={this.state.city}
                                  className='custom-input-bg'
                                />
                              </Grid>
                              <Grid item sm={4.01} xs={12} lg={2}>
                                <FormSelect
                                  label='State'
                                  name='state'
                                  variant='filled'
                                  value={this.state.state || ''}
                                  defaultValue={this.state.state || ''}
                                  onChange={handleFormChange}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  options={statesList}
                                  fullWidth={true}
                                  className='custom-input-bg'
                                />
                              </Grid>
                              <Grid item sm={3.99} xs={12} lg={2}>
                                <FormField
                                  formFieldId='custom-css-outlined-zip'
                                  formFieldLabel='Zip Code'
                                  formFieldVariant='filled'
                                  formFieldType='number'
                                  formFieldName='zip'
                                  formFieldError={this.state.errors.zip || null}
                                  onChange={handleFormChange}
                                  formFieldValue={this.state.zip}
                                  className='custom-input-bg'
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <Box sx={{ mb: 5 }}>
                          <Typography variant='h2' className='semi-strong'>
                            Additional Information
                          </Typography>

                          <Grid
                            container
                            sx={{ mt: { xs: 3 } }}
                            rowGap={{ xs: 5, md: 6.25 }}
                            columnSpacing={3}
                          >
                            <Grid item sm={6} xs={12} lg={4}>
                              <FormField
                                formFieldId='custom-css-outlined-school-website'
                                formFieldLabel='School Website (Optional)'
                                formFieldVariant='filled'
                                formFieldType='text'
                                formFieldName='schoolWebsite'
                                formFieldError={this.state.errors.schoolWebsite || null}
                                onChange={handleFormChange}
                                formFieldValue={this.state.schoolWebsite}
                                className='custom-input-bg'
                              />
                            </Grid>
                            <Grid item sm={6} xs={12} lg={4}>
                              <FormField
                                formFieldId='custom-css-outlined-school-demographics'
                                formFieldLabel='School Demographics (Optional)'
                                formFieldVariant='filled'
                                formFieldType='text'
                                formFieldName='demographicLink'
                                formFieldError={this.state.errors.demographicLink || null}
                                onChange={handleFormChange}
                                formFieldValue={this.state.demographicLink}
                                className='custom-input-bg'
                              />
                            </Grid>

                            <Grid item sm={6} xs={12} lg={4.01}>
                              <FormSelect
                                label='School Type'
                                name='schoolType'
                                variant='filled'
                                value={this.state.schoolType || ''}
                                defaultValue={this.state.schoolType || ''}
                                onChange={handleFormChange}
                                inputProps={{ 'aria-label': 'Without label' }}
                                options={schoolTypeOptions}
                                fullWidth={true}
                                className='custom-input-bg'
                              />
                            </Grid>
                            <Grid item sm={6} xs={12} lg={3.99}>
                              <FormField
                                formFieldId='custom-css-outlined-school-district'
                                formFieldLabel='School District (Optional)'
                                formFieldVariant='filled'
                                formFieldType='text'
                                formFieldName='schoolDistrict'
                                onChange={handleFormChange}
                                formFieldValue={this.state.schoolDistrict || null}
                                className='custom-input-bg'
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid
                          container
                          sx={{
                            mt: { xs: 5 },
                            textAlign: { xs: 'center', sm: 'left' }
                          }}
                          rowGap={{ xs: 3, sm: 2 }}
                          columnSpacing={{xs: 3, md: 2}}
                          alignItems='center'
                        >
                          <Grid item xs={12} sm={6} md={2.1} lg={1.5}>
                            <Button
                              variant='contained'
                              type='submit'
                              title='Save Changes'
                              onClick={e => handleSubmit(e, onMutate)}
                              disabled={  Object.keys(this.state.errors).length !== 0 ? (this.state.errors.schoolName ||
                                this.state.errors.zip ||
                                this.state.errors.city ||
                                this.state.errors.address1) :  this.state.disabled}
                              sx={{ width: { xs: '100%'} }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <Cancel onClick={this.handleCancel} showToast={this.props.showToast} />
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  </Box>
                )
              }}
            </Mutation>
          </Box>
        </Box>

        <Box
          sx={{
            mt: { xs: 7, sm: 10, md: 10 },
            borderTop: `${variables.borderThinGrey}`
          }}
        >
          <SchoolDetailsFooter school={school} />
        </Box>
      </Box>
    )
  }
}

export default SchoolInformation
