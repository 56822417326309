import React, { useEffect, useState } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import Hero from '../ui/organisms/Hero.js'
import { observer, inject } from 'mobx-react'
import Layout from '../ui/template/Layout'
import CurrentClassesList from '../ui/organisms/CurrentClassesList.js'
import { withRouter } from '../utils/customHoc'
import { useParams } from 'react-router-dom'

const Classes = props => {
  const { currentTab: initialTab, page } = useParams()
  const { userStore, breadcrumbStore, location, basePath } = props
  const [role] = useState(userStore.role)
  const [currentTab, setCurrentTab] = useState(initialTab + '_class')

  const allRoleTabs = [
    { eventKey: 'upcoming_class', title: 'Upcoming', path: '/classes/upcoming' },
    { eventKey: 'current_class', title: 'Current', path: '/classes/current' },
    { eventKey: 'past_class', title: 'Past', path: '/classes/past' }
  ]

  useEffect(() => {
    setCurrentTab(props.params.currentTab + '_class')
  }, [props.params.currentTab])

  useEffect(() => {
    breadcrumbStore.resetBreadcrumb()
    breadcrumbStore.addBreadcrumb({
      pageName: 'Classes',
      link: location.pathname,
      basePath: basePath,
      isParent: true,
      isActive: true
    })
  }, [location, basePath, breadcrumbStore])

  const handleTabChange = newTab => {
    setCurrentTab(newTab)
  }

  return (
    <Layout>
      <Hero
        heroTitle={role === 'appadmin' ? 'Classes' : 'Your Classes'}
        tabs={allRoleTabs}
        currentTab={currentTab}
        tabChangeHandler={handleTabChange}
      />
      {currentTab === 'current_class' ? (
        <CurrentClassesList type='current' key='1' page={page}/>
      ) : currentTab === 'upcoming_class' ? (
        <CurrentClassesList type='upcoming' key='2' page={page}/>
      ) : (
        <CurrentClassesList type='past' key='3' page={page}/>
      )}
    </Layout>
  )
}

export default inject('userStore', 'breadcrumbStore')(observer(withRouter(withApollo(Classes))))
