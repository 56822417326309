import { action, makeAutoObservable } from 'mobx'
import _ from 'lodash'

const publicFilters = ['Locaction', 'Published Date']
const studentFilters = [...publicFilters, 'Award of Excellence', 'Honorable Mention']
const teacherFilters = [
  ...studentFilters,
  'None',
  'Published',
  'Unpublished',
  'Model Release Confirmed',
  'Model Release Declined',
  'Waiting for Model Release',
  'May Need Model Release',
  'No People in Photograph',
  'Use Agreement Confirmed',
  'Use Agreement Declined',
  'Use Agreement Pending',
  'Submitted Date'
]
const facilitatorFilters = [...teacherFilters]
const curatorFilters = [...facilitatorFilters, 'Edited Photograph', 'Edited Caption']
const appadminFilters = [...curatorFilters, 'RAW']
class PhotoCollectionStore {
  items = {}
  searchTerm = ''
  // [category Display name, category system name, type of UI, [options]]
  rawFilterDisplays = [
    [
      'Releases',
      'model-releases',
      'multiple-select',
      [
        'Model Release Confirmed',
        'Model Release Declined',
        'Waiting for Model Release',
        'May Need Model Release',
        'No People in Photograph'
      ]
    ],
    [
      'Awards',
      'photo-awards',
      'multiple-select',
      ['Award of Excellence', 'Honorable Mention', 'None']
    ],
    [
      'Use Agreement',
      'use-agreements',
      'multiple-select',
      ['Use Agreement Confirmed', 'Use Agreement Declined', 'Use Agreement Pending']
    ],
    ['Files', 'files', 'multiple-select', ['RAW', 'Edited Photograph', 'Edited Caption']],
    ['Location', 'location', 'drop-down', ['Locaction']],
    ['Photo Status', 'photo-status', 'multiple-select', ['Published', 'Unpublished']],
    ['Published Date', 'published-date', 'date', ['Published Date']],
    ['Submitted Date', 'submitted-date', 'date', ['Submitted Date']]
  ]
  //default filter displays to public
  filterDisplays = this.rawFilterDisplays.filter(
    filterDisplay =>
      filterDisplay[3].filter(filterVal => _.includes(publicFilters, filterVal)).length > 0
  )

  filterValues = this.rawFilterDisplays
    .map(category => category[1])
    .reduce((obj, item) => {
      obj[item] = []
      return obj
    }, {})
  //   filterValues = {
  //   'model-releases': [],
  //   'photo-awards': [],
  //   'published-date': [],
  //   'photo-published': [],
  //   'use-agreements': []
  // }


  constructor() {
    // Call it here
    makeAutoObservable(this)
  }

  setRole(role) {
    this.role = role
    this.updateFilters()
  }
  getLocationsList() {
    // return this.items.map()
    return Object.keys(this.items).length === 0
      ? []
      : Array.from(new Set(this.items.map(i => i.project.cohort.school.mailingAddress.state))).map(
          stateAbbreviation => ({
            value: stateAbbreviation,
            label: stateAbbreviation
          })
        )
  }
  updateFilters() {
    this.updateApprovedFilterSet()
    this.filterDisplays = this.rawFilterDisplays.filter(
      filterDisplay =>
        filterDisplay[3].filter(filterVal => _.includes(this.approvedFilters, filterVal)).length > 0
    )
    this.filterValues = this.filterDisplays
      .map(category => category[1])
      .reduce((obj, item) => {
        obj[item] = []
        return obj
      }, {})
  }
  updateApprovedFilterSet() {
    this.approvedFilters = this.role
      ? this.role === 'appadmin'
        ? appadminFilters
        : this.role === 'curator'
        ? curatorFilters
        : this.role === 'facilitator'
        ? facilitatorFilters
        : this.role === 'teacher'
        ? teacherFilters
        : this.role === 'student'
        ? studentFilters
        : publicFilters
      : publicFilters
  }
  setItems = action(items => {
    this.items = items
  })
  setSearchTerm = action(searchTerm => {
    this.searchTerm = searchTerm
  })

  applySearchFilter(items) {
    if (this.searchTerm) {
      return items.filter(
        item =>
          item.project.student.name === this.searchTerm ||
          item.project.cohort.name === this.searchTerm ||
          item.project.cohort.school.name === this.searchTerm
      )
    } else return items
  }
  updateActiveFilters = action((key, value) => {
    let filterValues = { ...this.filterValues }
    filterValues[key] = value
    this.filterValues = filterValues

    // this.filterValues = { ...this.filterValues, [key]: value }
  })

  toggleMultipleSelect = (key, choice, callback) => {
    let choices = [...this.filterValues[key]]

    if (choices.includes(choice)) {
      choices = choices.filter(x => x !== choice)
    } else {
      choices.push(choice)
    }

    this.updateActiveFilters(key, choices)
    callback()
  }
  updateDateRangeFilters = (key, field, event, callback) => {
    if (!event.target) return
    let value = event.target.value
    let filterValues = { ...this.filterValues }
    filterValues[key] = filterValues[key][0] || filterValues[key][1] ? filterValues[key] : []
    field === 'fromDate' ? (filterValues[key][0] = value) : (filterValues[key][1] = value)
    this.filterValues = filterValues
    callback()
  }
  applyAwardFilters(items) {
    let activeFilters = this.filterValues['photo-awards']
      ? this.filterValues['photo-awards'].flat()
      : []
    if (activeFilters.length === 0) return items

    let filteredItems = items.filter(function(photo) {
      let photoAwards = photo.awards.map(a => a.awardClass)
      if (
        activeFilters.indexOf('Honorable Mention') > -1 &&
        photoAwards.indexOf('Honorable Mention') > -1
      )
        return true
      else if (
        activeFilters.indexOf('Award of Excellence') > -1 &&
        photoAwards.indexOf('Award of Excellence') > -1
      )
        return true
      else if (
        activeFilters.indexOf('Use Agreement Pending') > -1 &&
        photoAwards.indexOf('None') > -1
      )
        return true
      else return false
    })
    return filteredItems
  }
  applyStatusFilters(items) {
    let activeFilters = this.filterValues['photo-status']
      ? this.filterValues['photo-status'].flat()
      : []
    if (activeFilters.length === 0) return items
    let filteredItems = items.filter(function(photo) {
      if (activeFilters.indexOf('Short Listed') > -1 && photo.isShortListed) return true
      else if (activeFilters.indexOf('Library') > -1 && !photo.isShortListed) return true
      else return false
    })
    return filteredItems
  }
  applyUseAgreementFilters(items) {
    let activeFilters = this.filterValues['use-agreements']
      ? this.filterValues['use-agreements'].flat()
      : []
    if (activeFilters.length === 0) return items

    let filteredItems = items.filter(function(photo) {
      if (
        activeFilters.indexOf('Use Agreement Confirmed') > -1 &&
        photo.project.useAgreement &&
        photo.project.useAgreement.signed
      )
        return true
      else if (
        activeFilters.indexOf('Use Agreement Declined') > -1 &&
        photo.project.useAgreement &&
        photo.project.useAgreement.signed === false
      )
        return true
      else if (
        activeFilters.indexOf('Use Agreement Pending') > -1 &&
        (photo.project.useAgreement === null || photo.project.useAgreement.signed === null)
      )
        return true
      else return false
    })
    return filteredItems
  }
  applyReleaseFilters(items) {
    let activeFilters = this.filterValues['model-releases']
      ? this.filterValues['model-releases'].flat()
      : []

    if (activeFilters.length === 0) return items

    let filteredItems = items.filter(function(photo) {
      if (activeFilters.indexOf('No People in Photograph') > -1 && photo.hasPeople === false)
        return true
      else if (
        activeFilters.indexOf('Model Release Confirmed') > -1 &&
        photo.modelReleases.filter(mr => mr.signed).length > 0
      )
        return true
      else if (
        activeFilters.indexOf('Model Release Confirmed') > -1 &&
        photo.hasPeople &&
        photo.modelReleases.filter(mr => mr.signed).length > 0
      )
        return true
      else if (
        activeFilters.indexOf('Model Release Declined') > -1 &&
        photo.hasPeople &&
        photo.modelReleases.length > 0 &&
        photo.modelReleases.filter(mr => mr.signed === true).length === 0 &&
        photo.modelReleases.filter(mr => mr.signed === false).length > 0
      )
        return true
      else if (
        activeFilters.indexOf('Waiting for Model Release') > -1 &&
        photo.hasPeople &&
        (photo.modelReleases.length === 0 ||
          (photo.modelReleases.length > 0 &&
            photo.modelReleases.filter(mr => mr.signed === null).length > 0))
      )
        return true
      else if (
        activeFilters.indexOf('May Need Model Release') > -1 &&
        photo.hasPeople === null &&
        photo.modelReleases.length === 0
      )
        return true
      // base case
      else return false
    })
    return filteredItems
  }
  applySubmissionDateFilters(items) {
    const activeFilters = this.filterValues['submitted-date']
      ? this.filterValues['submitted-date'].flat()
      : []
    const end = new Date(activeFilters[1])
    const start = new Date(activeFilters[0])
    if (activeFilters.length === 0) return items
    let filteredItems = items.filter(
      photo => {
        const activeFilters = this.filterValues['submitted-date']
          ? this.filterValues['submitted-date'].flat()
          : []
        const end = new Date(activeFilters[1])
        const start = new Date(activeFilters[0])
        const submissionDate = new Date(photo.project.submittedUpdatedAt)
        //(startDate is not null xnor is after startDate) AND (endDate is not null XNOR is before endDate)
        const isAfterStart = start < submissionDate
        const isBeforeEnd = end > submissionDate
        return !isNaN(start.getTime()) === isAfterStart && !isNaN(end.getTime()) === isBeforeEnd
      },
      { end, start }
    )
    return filteredItems
  }

  applyLocationFilter(items) {
    const activeFilters = this.filterValues['location'].flat()
    let filteredItems =
      activeFilters.length > 0
        ? items.filter(function(photo) {
            return _.includes(activeFilters, photo.project.cohort.school.mailingAddress.state)
          })
        : items
    return filteredItems
  }

  get filteredItems() {
    let items = Object.keys(this.items).length === 0 ? [] : [...this.items]
    items = this.applySearchFilter(items)
    items = this.applyLocationFilter(items)
    items = this.applyAwardFilters(items)
    items = this.applyReleaseFilters(items)
    items = this.applyUseAgreementFilters(items)
    items = this.applySubmissionDateFilters(items)
    items = this.applyStatusFilters(items)
    return items
  }
}

export default PhotoCollectionStore
