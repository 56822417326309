import { Box, Typography } from '@mui/material'
import BaseModal from '../../template/BaseModal'
import { Link } from 'react-router-dom'
import Cancel from '../../elements/Cancel'
import Button from '../../elements/Button'
import { RefreshCw } from 'react-feather'
import { useState } from 'react'
import usePicsio from '../../../utils/picsioUtility'
import { Spinner } from 'react-bootstrap'
import { validationText } from '../../../content/validationText'
import moment from 'moment'

const FileDtailsModal = props => {
  const { uploadPhoto } = usePicsio()
  const [isDisabled, setIsDisabled] = useState(false)

  const handleClose = () => {
    props.showModal(false)
  }

  const uploadToPicsio = async () => {
    setIsDisabled(true)
    try {
      const uploadResponse = await uploadPhoto(
        props.student,
        props.project,
        props.files,
        props.collectionIds,
        props.cohort,
        props.isAwardUpdated
      )
      if (uploadResponse) {
        props?.refetchParent?.()
        props?.showToast?.('success', validationText.picsio.syncFile.success)
      }
    } catch (error) {
      props?.showToast?.('error', validationText.picsio.syncFile.error)
    } finally {
      setIsDisabled(false)
      handleClose()
    }
  }

  return (
    <BaseModal open={props.show} onClose={handleClose} onHide={handleClose}>
      <BaseModal.BaseModalHeader>
        {props.student?.name} Picsio Sync Status
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Typography variant='note' component='p' mb={1}>
          Project caption summary:{' '}
          {props?.project?.captionAssetId && !props?.project?.captionsPendingPicsioSync?.count ? (
            `sent to Picsio on ${moment(props?.project?.lastSync).format('LL')}`
          ) : (
            <strong>not synced</strong>
          )}
        </Typography>
        {props?.files?.length
          ? props.files.map((file, index) => (
              <Box key={`${file.id}-${index}`} sx={{ mt: index > 0 ? '16px' : 'auto' }}>
                <Link target='_blank' to={`/photo-detail/${file.id}`}>
                  <Typography key={file.id} variant='link' component='p'>
                    {file.title}
                  </Typography>
                </Link>
                <Typography component={'div'} mt={1}>
                  <Typography variant='note' component='p'>
                    Image file:{' '}
                    {file?.assetId ? (
                      `sent to Picsio on ${moment(file?.lastSync).format('LL')}`
                    ) : (
                      <strong>not synced</strong>
                    )}
                  </Typography>
                  <Typography variant='note' component='p'>
                    {!file?.formsPendingPicsioSync?.ua?.count ? (
                      'No approved Use Agreement available'
                    ) : (
                      <>
                        Use Agreement:{' '}
                        {file?.formsPendingPicsioSync?.ua?.syncedCount ? (
                          `sent to Picsio on ${moment(
                            file?.formsPendingPicsioSync?.ua?.lastSync
                          ).format('LL')}`
                        ) : (
                          <strong>not synced</strong>
                        )}
                      </>
                    )}
                  </Typography>
                  <Typography variant='note' component='p'>
                    {!file?.formsPendingPicsioSync?.mr?.count
                      ? 'No approved Model Releases available'
                      : `Model Release(s): ${
                          file?.formsPendingPicsioSync?.mr?.syncedCount
                        } sent to Picsio${
                          file?.formsPendingPicsioSync?.mr?.latestSyncDate
                            ? ' on ' +
                              moment(file?.formsPendingPicsioSync?.mr?.latestSyncDate).format('LL')
                            : ''
                        }`}
                    {file?.formsPendingPicsioSync?.mr?.unsyncedCount ? (
                      <>
                        {', '}
                        <strong>
                          {file?.formsPendingPicsioSync?.mr?.unsyncedCount} not synced
                        </strong>
                      </>
                    ) : null}
                  </Typography>
                </Typography>
              </Box>
            ))
          : null}
        <BaseModal.BaseModalFooter>
          <Box
            sx={{
              pt: 3,
              display: 'flex',
              alignItems: 'center',
              flexDirection: { xs: 'column', md: 'row-reverse' }
            }}
          >
            <Button
              title={
                <span className='icon-text-span'>
                  {isDisabled ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      style={{ marginRight: '8px' }}
                    />
                  ) : (
                    <RefreshCw size={18} />
                  )}
                  Sync to Pics.io
                </span>
              }
              variant='contained'
              disabled={isDisabled}
              onClick={uploadToPicsio}
              sx={{ width: { xs: '100%', md: 'max-content' } }}
            />
            <Cancel onClick={handleClose} sx={{ mr: { md: 2 }, mt: { xs: 3, md: 0 } }} />
          </Box>
        </BaseModal.BaseModalFooter>
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

export default FileDtailsModal
