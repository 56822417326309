import React, { Component } from 'react'
import '../ui/organisms/studentlist.scss'
import Hero from '../ui/organisms/Hero.js'
import SchoolList from '../ui/organisms/SchoolList.js'
import { withApollo } from '@apollo/client/react/hoc'
import Layout from '../ui/template/Layout'
import { withRouter } from '../utils/customHoc'
import { inject, observer } from 'mobx-react'
import { PAGINATED_SCHOOL_LIST } from '../queries/schoolDetailsQueries'

class Schools extends Component {
  constructor(props) {
    super(props)
    const {breadcrumbStore, location, basePath} = props
    this.state = {
      gqlRes: null,
      searchKey:'',
      schoolCount:0
    }
    breadcrumbStore.resetBreadcrumb()
    breadcrumbStore.addBreadcrumb({
      pageName: 'Schools',
      link: location.pathname,
      basePath: basePath,
      isParent: true,
      isActive: true
    })
  }

  async componentDidMount() {
    this.timer = null;
    if (this.props.location.search.includes('refresh')) {
      window.location = '/schools'
    }

    const mockObj = {
      hasNextPage: true,
      endCursor: ''
    }
    await this.fetchMoreSchools(mockObj)

    const data = await this.props.client.query({
      query: PAGINATED_SCHOOL_LIST,
      variables: {
        cursor: '',
        search:this.state.searchKey
      }
    })
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  fetchMoreSchools = async ({ endCursor, hasNextPage }) => {
    if (!hasNextPage) return []
    this.setState({
      ...this.state,
      loading: true
    })
    const data = await this.props.client.query({
      query: PAGINATED_SCHOOL_LIST,
      variables: {
        cursor: endCursor,
        search: this.state.searchKey
      }
    })
    return this.mergeIncomingData(data.data)
  }

  mergeIncomingData = data => {
    if (
      this.state.gqlRes &&
      this.state.gqlRes.schoolsConnection.pageInfo.endCursor ===
        data.schoolsConnection.pageInfo.endCursor
    )
      return
    const current = (this.state.gqlRes && this.state.gqlRes.schoolsConnection) || { edges: [] }
    const { edges } = current
    let countSchool = data.schoolCount
    let newEdges = [...edges, ...data.schoolsConnection.edges]
    this.setState({
      ...this.state,
      gqlRes: {
        schoolsConnection: {
          edges: newEdges,
          pageInfo: data.schoolsConnection.pageInfo
        },
        schoolCount:countSchool
      },
      schoolCount:countSchool,
      loading: false
    })
  }

  handleScroll = e => {
    const elem = e.target.scrollingElement
    const vh = elem.scrollHeight
    const currVh = elem.scrollTop
    const threshold = vh / 4 + 200
    if (currVh > threshold) {
      this.fetchMoreSchools(this.state.gqlRes.schoolsConnection.pageInfo)
    }
  }

  handelSearch = (value) => {
    clearTimeout(this.timer);
    this.setState({
      ...this.state,
      searchKey:value
    })
    this.timer = setTimeout(this.triggerChange, 2000);
  }

  triggerChange = () => {
    this.setState({
      ...this.state,
      loading:true,
      gqlRes:null
    }, async() => {
      const mockObj = {
        hasNextPage: true,
        endCursor: ''
      }
      await this.fetchMoreSchools(mockObj)
    })
  }

  render() {
    const pageReady =
      this.state.gqlRes &&
      this.state.gqlRes.schoolsConnection &&
      this.state.gqlRes.schoolsConnection.edges &&
      this.state.gqlRes.schoolsConnection.edges.length > 0
    return (
      <Layout>
        <Hero heroTitle='Schools' />
        <SchoolList schools={this.state.gqlRes} schoolCount={this.state.schoolCount} handelSearch={this.handelSearch} searchVal={this.state.searchKey} pageReady={pageReady} loading={this.state.loading} role={'appadmin'}/>
      </Layout>
    )
  }
}

export default inject('breadcrumbStore')(observer(withRouter(withApollo(Schools))))
