import React, { Component, Fragment } from 'react'
import './publicevents.scss'
import { ArrowLeft } from 'react-feather'
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import moment from 'moment'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'
import { Typography } from '@mui/material'

const EVENT_DETAILS_QUERY = gql`
  query PublicEventDetails($slug: String!) {
    event(slug: $slug) {
      id
      name
      slug
      summary
      description
      eventStart
      eventEnd
      linkToFeaturedImage
      timingNote
      location
      featuredImagePhotographerName
      featuredImagePhotographTitle
      featuredImagePhotographYear
    }
  }
`
class PublicEventDetails extends Component {
  render() {
    return (
      <Layout>
      <Query query={EVENT_DETAILS_QUERY} variables={{ slug: this.props.slug }}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) {
            return <Typography variant="paragraph" component="p">Error loading classes</Typography>
          }
          if (data) {
            return (
              <Fragment>
                <div className='container__public-body'>
                  <div className='public-body__public-events'>
                    <div className='public-events__active-events'>
                      <div className='public-events__active-events--back-button'>
                        <Link to='/public-events'>
                          <h4 className='h4--semi-strong public-link'>
                            <ArrowLeft size={20} /> Back to Events
                          </h4>
                        </Link>
                      </div>
                      <div className='public-events__active-events--event-details'>
                        <div className='event-details__content-section'>
                          <div className='event-details__content-section--header'>
                            <Typography variant='h1' className='bold'>{data.event.name}</Typography>
                          </div>
                        </div>
                        <div className='event-details__image-section'>
                          <Image src={data.event.linkToFeaturedImage} fluid />
                          <h6>
                            {data.event.featuredImagePhotographerName ? (
                              <span>
                                {data.event.featuredImagePhotographerName},{'  '}
                              </span>
                            ) : null}
                            {data.event.featuredImagePhotographTitle ? (
                              <span>
                                <i>{data.event.featuredImagePhotographTitle}</i>,{'  '}
                              </span>
                            ) : null}
                            {data.event.featuredImagePhotographYear ? (
                              <span>{data.event.featuredImagePhotographYear}</span>
                            ) : null}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className='public-events__details-section'>
                      <div className='public-events__details-section--date-location'>
                        <Typography variant="h3" className='strong'>Date & Time</Typography>
                        <Typography variant="h3">
                          {moment(data.event.eventStart).format('LL')}
                          {data.event.eventEnd ? (
                            <span> – {moment(data.event.eventEnd).format('LL')}</span>
                          ) : null}
                        </Typography>
                        <br />
                        <Typography variant="h3">{data.event.timingNote}</Typography>
                        <br />
                        <Typography variant="h3" className='strong'>Location</Typography>
                        {data.event.location}
                      </div>
                      <div className='public-events__details-section--description'>
                        <Typography 
                          variant="paragraph"
                          sx={{
                            mb:"1rem",
                          }}
                          component="p"
                        >{data.event.description}</Typography>
                        {/* <div className='public-events__active-events--cta'>
                  <Link to='/public-events'>
                    <h4 className='h4--semi-strong public-link'>
                      Read More <ExternalLink size={20} />
                    </h4>
                  </Link>
                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            )
          }
        }}
      </Query>
      </Layout>
    )
  }
}

export default PublicEventDetails
