import React, { Component } from 'react'
import { Typography } from '@mui/material'
import './studentlist.scss'
import CuratorStudentListRow from '../molecules/CuratorStudentListRow.js'
import { withApollo } from '@apollo/client/react/hoc'
import Loading from '../elements/Loading.js'
import { Grid, Box } from '@mui/material'
import { STUDENT_LIST_BY_COHORT_ID } from '../../queries/studentQueries'

class CuratorStudentList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: [],
      students: []
    }
  }

  async componentDidMount() {
    this.setState({
      ...this.state,
      loading: true
    })

    await this.props.client
      .watchQuery({
        query: STUDENT_LIST_BY_COHORT_ID,
        variables: {
          cohortId: this.props.cohortId
        },
        fetchPolicy: 'cache-and-network'
      })
      .subscribe(({ data }) => {
        this.setState({
          ...this.state,
          students: data.cohort.students,
          projects: data.cohort.submittedProjects,
          loading: false
        })
      })
  }

  render() {
    const { projects, students } = this.state
    const { role } = this.props
    if (!this.state.loading) {
      return (
        <Box sx={{pb: {xs: 10}}}>
          <Box sx={{ px: { xs: 4 }, pb: {md: 0}, mt: {xs: 5} }}>
            <Typography variant='h1' className='bold'>
              Students ({students.length})
            </Typography>
            <Typography variant='paragraph' component='p' sx={{mt: {xs: 2}, maxWidth: '698px'}}>
              {role === 'appadmin'
                ? 'This list includes all students in the class. If a student did not submit their project, curators cannot view that student’s work.'
                : 'This list includes all students in the class. If a student did not submit a project, they are labeled as ‘No Photographs Submitted’.'}
            </Typography>
            <Box display={{ xs: 'none', sm: 'block' }} sx={{ px: { xs: 0, md: 4 }, mt: {xs: 5} }}>
              <Grid container spacing={1}>
                <Grid item sm={4} md={3}>
                  <Typography variant='dataLabel' component={'p'}>
                    Student Names
                  </Typography>
                </Grid>
                <Grid item md={3} display={{ sm: 'none', md: 'block' }}>
                  <Typography variant='dataLabel' component={'p'}>
                    Photographs Submitted
                  </Typography>
                </Grid>
                <Grid item sm={4} md={3}>
                  <Typography variant='dataLabel' component={'p'}>
                    Award Of Excellence
                  </Typography>
                </Grid>
                <Grid item sm={4} md={3}>
                  <Typography variant='dataLabel' component={'p'}>
                    Honorable Mention
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ px: { xs: 0, md: 4 }, mt: {xs: 5, sm: 2} }}>
            {students.map(student => (
              <CuratorStudentListRow
                // setAdjacentGroup={this.props.setAdjacentGroup}
                // role={this.props.role}
                student={student}
                projects={projects}
                // photoOnClick={this.props.photoOnClick}
                // changeScopedProject={this.props.changeScopedProject}
                key={`curator-student-list-row-${student.id}`}
              />
            ))}
          </Box>
        </Box>
      )
    } else if (this.state.loading) {
      return <Loading />
    } else {
      return (
        <Typography variant='paragraph' component='p'>
          Sorry, there's been an error
        </Typography>
      )
    }
  }
}

export default withApollo(CuratorStudentList)
