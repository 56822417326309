import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Box, Grid, Typography } from '@mui/material'
import variables from '../settings/_variables.scss'
import { getFormattedStatus } from '../../utils/commonFunctions'
import DateUtils from '../../utils/DateUtils'

const ReviewListRow = props => {
  const [cohort] = useState(props?.cohort)
  const [index] = useState(props?.index)
  const [reviewType] = useState(props?.reviewType)
  const [role] = useState(props.role)

  const baseLink = ['curator', 'appadmin'].includes(role) ? 'class-review' : 'class-detail'
  const isUserDetail = window.location.pathname.includes('user-detail')
  const submittedProjects = isUserDetail
    ? 0
    : cohort.projects.filter(project => project.submitted).length

  const getReviewStatus = cohort => {
    const reviewStatuses = cohort.getCohortReviewStatus
    let finalStatus = []
    if (reviewStatuses && Object.keys(reviewStatuses)?.length) {
      for (const key in reviewStatuses) {
        if (reviewStatuses.hasOwnProperty(key)) {
          finalStatus.push(
            getFormattedStatus(reviewStatuses[key]['icon'], reviewStatuses[key]['message'])
          )
        }
      }
    }
    return finalStatus
  }

  const getCuratorCompletedDate = cohort => {
    const curatorsDates = cohort.curatorReviews.map(review => DateUtils.createMoment(review.createdAt))
    return moment.max(curatorsDates)
  }

  const reviewStatusElements = getReviewStatus(cohort)

  return (
    <Box sx={{ px: { md: 6 }, mb: { md: 1 } }}>
      <Grid
        container
        columnSpacing={{ sm: 3, md: '0' }}
        sx={{
          backgroundColor: variables.white,
          borderBottom: { xs: variables.borderThinGrey },
          px: { xs: 3, md: 4 },
          py: { xs: 3, md: 4 }
        }}
      >
        <Grid item md={4} sm={6} xs={12}>
          <Typography variant='h4' className='regular'>
            {cohort.school.name}
            {cohort.school?.mailingAddress?.state
              ? `, ${cohort.school?.mailingAddress?.state}`
              : ''}
          </Typography>
          <Box sx={{ mt: 1 }}>
            {DateUtils.isDatePassed(cohort.endDate) ? (
              <Link to={`/${baseLink}/${cohort.id}`} key={index}>
                <Typography variant='h4' className='link--large'>
                  {cohort.name}
                </Typography>
              </Link>
            ) : (
              <Typography variant='h4' className='link--large'>
                {cohort.name}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, mt: 3 }}>
            <Box>
              <Typography variant='h4' className='regular'>
                Photography {'   '} • {'   '}
                {cohort.assignment.name}
              </Typography>
            </Box>

            {isUserDetail ? null : (
              <Box sx={{ mt: 1 }} key={index}>
                <Link to={`/${baseLink}/${cohort.id}/students`}>
                  <Typography variant='h4' className='link--large'>
                    {cohort.approved
                      ? cohort.studentsCount === 0
                        ? 'Invite Students'
                        : submittedProjects + ' Students Submitted'
                      : null}
                  </Typography>
                </Link>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Box
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              mb: { xs: 3, sm: 2, md: 0 },
              mt: { xs: 3, sm: 0, md: 0 }
            }}
          >
            <Typography variant='h4'>
              {moment(cohort.startDate, 'YYYY-MM-DD').format('MMMM D, YYYY')} —{' '}
              {moment(cohort.endDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
            </Typography>
          </Box>
          <Box>
            {reviewStatusElements.map((element, index) => (
              <Box key={index}>{element}</Box>
            ))}
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'block'
            }
          }}
        >
          <Typography variant='h4' sx={{ mb: { md: 4 } }}>
            Class Submitted On
            <br />
            {DateUtils.createMoment(cohort.endDate).format('MMMM D, YYYY')}
          </Typography>

          {reviewType === 'curatorial' ? (
            <Typography variant='h4'>
              Review Due On
              <br />
              {DateUtils.createMoment(cohort.curatorsDueDate).format('MMMM D, YYYY')}
            </Typography>
          ) : reviewType === 'award' || reviewType === 'completed' ? (
            <Typography variant='h4'>
              Review Completed On
              <br />
              {getCuratorCompletedDate(cohort).format('MMMM D, YYYY')}
            </Typography>
          ) : reviewType === 'publishing' ? (
            <Typography variant='h4'>
              Review Summary Sent On
              <br />
              {DateUtils.createMoment(cohort.educatorSummaryAt).format('MMMM D, YYYY')}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReviewListRow

// import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
// import moment from 'moment'
// import { Box, Grid, Typography } from '@mui/material'
// import variables from '../settings/_variables.scss'
// import { getFormattedStatus } from '../../utils/commonFunctions'

// class ReviewListRow extends Component {
//   daysRemaining = dueDate => {
//     const now = moment()
//     const end = moment(dueDate)
//     const diff = end.diff(now, 'days')
//     return diff > 0 ? diff : 0
//   }

//   getReviewStatus = cohort => {
//     const reviewStatuses = cohort.getCohortReviewStatus
//     let finalStatus = []
//     if (Object.keys(reviewStatuses).length) {
//       for (const key in reviewStatuses) {
//         if (reviewStatuses.hasOwnProperty(key)) {
//           finalStatus.push(
//             getFormattedStatus(reviewStatuses[key]['icon'], reviewStatuses[key]['message'])
//           )
//         }
//       }
//     }
//     return finalStatus
//   }

//   getSubmissionReviewStatus = cohort => {
//     return (
//       <>
//         <Box>{getFormattedStatus('AlertTriangle', 'Send Review to Curators')}</Box>
//       </>
//     )
//   }

//   getCuratorialReviewStatus = cohort => {
//     let today = moment()
//     const { role, profileId } = this.props
//     return (
//       <>
//         {cohort.curators.map(curator => {
//           let reviewerId = cohort.curatorReviews
//             .filter(revi => revi.reviewerId === curator.id)
//             .map(review => review.reviewerId)
//           let curatorsReviews = cohort.curatorReviews.filter(revi => revi.reviewerId === curator.id)
//           if (curatorsReviews.length > 0) {
//             return (
//               <Box>
//                 {getFormattedStatus(
//                   'CheckCircle',
//                   reviewerId[0] === profileId
//                     ? 'You Submitted Your Review'
//                     : `${curator.name}  Submitted Review`
//                 )}
//               </Box>
//             )
//           } else {
//             if (moment(cohort.curatorsDueDate, 'YYYY-MM-DD').isBefore(today, 'day')) {
//               return (
//                 <Box>
//                   {getFormattedStatus(
//                     'AlertTriangle',
//                     role === 'curator' && profileId === curator.id
//                       ? 'Submit Your Review (Overdue)'
//                       : `${curator.name} Review Is Overdue`
//                   )}
//                 </Box>
//               )
//             } else {
//               return (
//                 <Box>
//                   {getFormattedStatus(
//                     'Clock',
//                     role === 'curator' && profileId === curator.id
//                       ? 'Select Awards and Submit Your Review'
//                       : `${curator.name} Has Not Submitted Review`
//                   )}
//                 </Box>
//               )
//             }
//           }
//         })}
//       </>
//     )
//   }

//   getAwardReviewStatus = cohort => {
//     let unidentifiedModelsPhotoCount = cohort.photos.filter(
//       photo =>
//         photo.submitted &&
//         photo.isShortListed &&
//         photo.hasPeople === null &&
//         photo.awards.length > 0
//     ).length

//     return (
//       <>
//         {unidentifiedModelsPhotoCount > 0 ? (
//           <Box>
//             {getFormattedStatus(
//               'AlertTriangle',
//               `Identify Models in ${unidentifiedModelsPhotoCount} Photograph(s)`
//             )}
//           </Box>
//         ) : null}
//         <Box>{getFormattedStatus('AlertTriangle', 'Send Review Summary')}</Box>
//       </>
//     )
//   }

//   getPublishingStatus = cohort => {
//     let publishedPhotosCount = cohort.photos.filter(photo => photo.published).length
//     let pendingToPublishCount = cohort.photos.filter(
//       photo => !photo.published && photo.readyToPublish
//     ).length
//     let pendingReviewForms = 0
//     cohort.photos.forEach(photo => {
//       if (photo.isShortListed && photo.awards.length > 0) {
//         let unreviewedMrCount = photo.modelReleases.filter(
//           mr => mr.mrStatus === 'Active' && mr.adminDecision === 'Pending' && mr?.signed !== false
//         ).length
//         let unreviewedUaCount = photo.useAgreements.filter(
//           ua => ua.uaStatus === 'Active' && ua.adminDecision === 'Pending' && ua?.signed !== false
//         ).length
//         pendingReviewForms = pendingReviewForms + unreviewedMrCount + unreviewedUaCount
//       }
//     })
//     return (
//       <>
//         {pendingReviewForms > 0 ? (
//           <Box>{getFormattedStatus('AlertTriangle', `Review ${pendingReviewForms} Form(s)`)}</Box>
//         ) : null}
//         {pendingToPublishCount > 0 ? (
//           <Box>
//             {getFormattedStatus('AlertTriangle', `Publish ${pendingToPublishCount} Photograph(s)`)}
//           </Box>
//         ) : null}

//         {publishedPhotosCount > 0 ? (
//           <Box>
//             {getFormattedStatus('CheckCircle', `${publishedPhotosCount} Photograph(s) Published`)}
//           </Box>
//         ) : null}
//       </>
//     )
//   }
//   getCuratorCompletedDate = cohort => {
//     let curatorsDates = cohort.curatorReviews.map(review => moment(review.createdAt))
//     return moment.max(curatorsDates)
//   }

//   render() {
//     const { cohort, index, reviewType } = this.props
//     const baseLink =
//       this.props.role === 'curator' || this.props.role === 'appadmin'
//         ? 'class-review'
//         : 'class-detail'
//     const isUserDetail = window.location.pathname.includes('user-detail')
//     const submittedProjects = isUserDetail
//       ? 0
//       : cohort.projects.filter(project => project.submitted).length
//     const reviewStatusElements = this.getReviewStatus(cohort)

//     return (
//       <Box sx={{ px: { md: 6 }, mb: { md: 1 } }}>
//         <Grid
//           container
//           columnSpacing={{ sm: 3, md: '0' }}
//           sx={{
//             backgroundColor: variables.white,
//             borderBottom: { xs: variables.borderThinGrey },
//             px: { xs: 3, md: 4 },
//             py: { xs: 3, md: 4 }
//           }}
//         >
//           <Grid item md={4} sm={6} xs={12}>
//             <Typography variant='h4' className='regular'>
//               {cohort.school.name}
//               {cohort.school?.mailingAddress?.state
//                 ? `, ${cohort.school?.mailingAddress?.state}`
//                 : ''}
//             </Typography>
//             <Box sx={{ mt: 1 }}>
//               {moment().isAfter(moment(cohort.endDate)) ? (
//                 <Link to={`/${baseLink}/${cohort.id}`} key={index}>
//                   <Typography variant='h4' className='link--large'>
//                     {cohort.name}
//                   </Typography>
//                 </Link>
//               ) : (
//                 <Typography variant='h4' className='link--large'>
//                   {cohort.name}
//                 </Typography>
//               )}
//             </Box>
//             <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, mt: 3 }}>
//               <Box>
//                 <Typography variant='h4' className='regular'>
//                   Photography {'   '} • {'   '}
//                   {cohort.assignment.name}
//                 </Typography>
//               </Box>

//               {isUserDetail ? null : (
//                 <Box sx={{ mt: 1 }} key={index}>
//                   <Link to={`/${baseLink}/${cohort.id}/students/all`}>
//                     <Typography variant='h4' className='link--large'>
//                       {cohort.approved
//                         ? cohort.studentsCount === 0
//                           ? 'Invite Students'
//                           : submittedProjects + ' Students Submitted'
//                         : null}
//                     </Typography>
//                   </Link>
//                 </Box>
//               )}
//             </Box>
//           </Grid>
//           <Grid item md={4} sm={6} xs={12}>
//             <Box
//               sx={{
//                 display: { xs: 'block', sm: 'block', md: 'none' },
//                 mb: { xs: 3, sm: 2, md: 0 },
//                 mt: { xs: 3, sm: 0, md: 0 }
//               }}
//             >
//               <Typography variant='h4'>
//                 {moment(cohort.startDate, 'YYYY-MM-DD').format('MMMM D, YYYY')} —{' '}
//                 {moment(cohort.endDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
//               </Typography>
//             </Box>
//             <Box>
//               {/* {reviewType === 'submission'
//                 ? this.getSubmissionReviewStatus(cohort)
//                 : reviewType === 'curatorial' || reviewType === 'completed'
//                 ? this.getCuratorialReviewStatus(cohort)
//                 : reviewType === 'award'
//                 ? this.getAwardReviewStatus(cohort)
//                 : reviewType === 'publishing'
//                 ? this.getPublishingStatus(cohort)
//                 : null} */}

//               {reviewStatusElements.map((element, index) => (
//                 <Box key={index}>{element}</Box>
//               ))}
//             </Box>
//           </Grid>
//           <Grid
//             item
//             md={4}
//             sx={{
//               display: {
//                 xs: 'none',
//                 sm: 'none',
//                 md: 'block'
//               }
//             }}
//           >
//             <Typography variant='h4' sx={{ mb: { md: 4 } }}>
//               Class Submitted On
//               <br />
//               {moment(cohort.endDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
//             </Typography>

//             {reviewType === 'curatorial' ? (
//               <Typography variant='h4'>
//                 Review Due On
//                 <br />
//                 {moment(cohort.curatorsDueDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
//               </Typography>
//             ) : reviewType === 'award' || reviewType === 'completed' ? (
//               <Typography variant='h4'>
//                 Review Completed On
//                 <br />
//                 {this.getCuratorCompletedDate(cohort).format('MMMM D, YYYY')}
//               </Typography>
//             ) : reviewType === 'publishing' ? (
//               <Typography variant='h4'>
//                 Review Summary Sent On
//                 <br />
//                 {moment(cohort.educatorSummaryAt, 'YYYY-MM-DD').format('MMMM D, YYYY')}
//               </Typography>
//             ) : null}
//           </Grid>
//         </Grid>
//       </Box>
//     )
//   }
// }

// export default ReviewListRow
