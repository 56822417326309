import { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Button from '../elements/Button.js'
import { Send } from 'react-feather'
import FormCheckbox from '../elements/FormCheckbox.js'
import { gql, useQuery, useMutation } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { SyncToPicsio } from './Picsio/SyncToPicsio.js'
import variables from './../settings/_variables.scss'
import { Info } from 'react-feather'
import LinearProgress from '@mui/material/LinearProgress'

const QUERY = gql`
  query ClassReview($id: ID!) {
    cohort(id: $id) {
      id
      totalFileCount
      lastDownloadReq
      getLatestDownloadRequestStatus
    }
  }
`
const CreatePdfDownloadMUTATION = gql`
  mutation CreatePdfDownload($cohortId: ID!, $requestType: JSON!) {
    createPdfDownload(cohortId: $cohortId, requestType: $requestType) {
      id
    }
  }
`
const ArchiveTools = props => {
  const [cohortId] = useState(props.cohort.id)
  const [requestType, setRequestType] = useState({ caption: false, ua: false, mr: false })
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [totalFileCount, setTotalFileCount] = useState(0)
  const [lastDownloadReq, setLastDownloadReq] = useState(0)
  const [lastRequestStatus, setLastRequestStatus] = useState(0)

  const { refetch } = useQuery(QUERY, {
    variables: { id: cohortId },
    onCompleted(data) {
      setTotalFileCount(data.cohort.totalFileCount)
      setLastRequestStatus(data.cohort.getLatestDownloadRequestStatus)
      setLastDownloadReq(data.cohort.lastDownloadReq)
    }
  })

  const handleSubmit = async () => {
    setIsSubmitted(true)
    try {
      await createPdfDownloadMUTATION()
    } catch (error) {
      props.showToast(
        'error',
        `Your files cannot be downloaded. Please contact the web app team for support.`
      )
      console.error('Error updaing user:', error)
    }
  }

  const [createPdfDownloadMUTATION] = useMutation(CreatePdfDownloadMUTATION, {
    variables: {
      cohortId: cohortId,
      requestType: requestType
    },
    onCompleted() {
      props.showToast(
        'success',
        `Your files are being prepared. You will receive an email when your download is ready.`
      )
      setTimeout(() => {
        setIsSubmitted(false)
        setRequestType({ caption: false, ua: false, mr: false })
        refetch()
      }, 5500)
    }
  })

  const handleCheckboxChange = e => {
    setRequestType(prevState => ({
      ...prevState,
      [e.target.name]: e.target.checked
    }))
  }

  useEffect(() => {
    refetch()
  }, [props.cohort.totalFileCount, refetch])

  return (
    <Box sx={{ px: { xs: 3, sm: 4, md: 6 }, pt: { xs: 5, md: 6, lg: 10 }, pb: { xs: 10 } }}>
      <Typography
        component={'div'}
        variant='note'
        sx={{
          mb: 4,
          p: 2,
          border: `1px solid ${variables.blueAccent}`,
          display: 'flex'
        }}
      >
        <span style={{ alignItems: 'center', marginRight: '16px', display: 'flex' }}>
          <Info color={`${variables.blueAccent}`} />
        </span>
        <span>
          The tools on this tab are in beta and may not all be fully functional.
          <br />
          Please check with the engineering team before using the archive tools.
        </span>
      </Typography>

      <Grid container>
        <Grid item xs={12} md={8} lg={7}>
          <Typography className='bold' variant='h1' sx={{ pb: '16px' }}>
            Download Files for This Class
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={7}>
          <Typography variant='paragraph' component='p'>
            Check the boxes below to choose the files you want to download for this class.
            <br />
            {/* To download files from a specific student, use the tools in the <Typography variant='link' component={'span'} onClick={() => props?.changeTab('students')}>Student List</Typography> or on individual photograph pages. */}
          </Typography>
        </Grid>
        {lastRequestStatus.caption_download_req_status > 0 ||
        lastRequestStatus.ua_download_req_status > 0 ||
        lastRequestStatus.mr_download_req_status > 0 ? (
          <Grid item xs={12} md={8} lg={7} sx={{ pt: '16px' }}>
            <Typography
              component={'div'}
              variant='note'
              sx={{
                p: 2,
                border: `1px solid ${variables.blueAccent}`,
                display: 'flex'
              }}
            >
              <span style={{ alignItems: 'center', marginRight: '16px', display: 'flex' }}>
                <Info color={`${variables.blueAccent}`} />
              </span>
              <span style={{ paddingTop: '4px' }}>
                Last request is currently being processed. Kindly refresh the page in approximately
                10 minutes for the latest updates.
                <br />
              </span>
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12} md={8} lg={7} sx={{ pt: '32px', display: 'flex' }}>
          <FormCheckbox
            sx={{ textAlign: 'center', alignItems: 'baseline' }}
            value='Caption'
            name='caption'
            checked={requestType.caption}
            disabled={
              !(totalFileCount.caption_file_count > 0) ||
              lastRequestStatus.caption_download_req_status > 0
            }
            color='primary'
            onClick={e => {
              handleCheckboxChange(e)
            }}
          />
          <Typography variant='paragraph' component='p' sx={{ pt: '4px' }}>
            Download summary documents of all <strong>original captions</strong> by students in this
            class who submitted projects.
            <br />
            <Typography component='span'>
              {totalFileCount.caption_file_count} files available
            </Typography>
            <br />
            {lastDownloadReq.caption_last_download_req !== 'n/a' ? (
              <span style={{ color: '#878787' }} className='icon-text-span'>
                <Send size={16} />
                Last requested on {lastDownloadReq.caption_last_download_req}
              </span>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={7} sx={{ pt: '16px', display: 'flex' }}>
          <FormCheckbox
            sx={{ textAlign: 'center', alignItems: 'baseline' }}
            value='UA'
            name='ua'
            checked={requestType.ua}
            disabled={
              !(totalFileCount.ua_file_count > 0) || lastRequestStatus.ua_download_req_status > 0
            }
            color='primary'
            onClick={e => {
              handleCheckboxChange(e)
            }}
          />
          <Typography variant='paragraph' component='p' sx={{ pt: '4px' }}>
            Download all available signed and approved <strong>Use Agreements</strong> for awarded
            photographs in this class.
            <br />
            <Typography component='span'>{totalFileCount.ua_file_count} files available</Typography>
            <br />
            {lastDownloadReq.ua_last_download_req !== 'n/a' ? (
              <span style={{ color: '#878787' }} className='icon-text-span'>
                <Send size={16} />
                Last requested on {lastDownloadReq.ua_last_download_req}
              </span>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={7} sx={{ pt: '16px', display: 'flex' }}>
          <FormCheckbox
            md={1}
            sm={1}
            xs={1}
            sx={{ textAlign: 'center', alignItems: 'baseline' }}
            value='MR'
            name='mr'
            disabled={
              !(totalFileCount.mr_file_count > 0) || lastRequestStatus.mr_download_req_status > 0
            }
            checked={requestType.mr}
            color='primary'
            onClick={e => {
              handleCheckboxChange(e)
            }}
          />
          <Typography variant='paragraph' component='p' sx={{ pt: '4px' }}>
            Download all available signed and approved <strong>Model Releases</strong> for awarded
            photographs in this class.
            <br />
            <Typography component='span'>{totalFileCount.mr_file_count} files available</Typography>
            <br />
            {lastDownloadReq.mr_last_download_req !== 'n/a' ? (
              <span style={{ color: '#878787' }} className='icon-text-span'>
                <Send size={16} />
                Last requested on {lastDownloadReq.mr_last_download_req}
              </span>
            ) : null}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8} lg={7} sx={{ pt: '32px', pb: '32px' }}>
          <Typography variant='paragraph' component='p'>
            When your download is ready, you will receive an email with a download link. You will
            then have one week to download your files.
          </Typography>
        </Grid>
        {isSubmitted ? (
          <Grid item xs={12} md={8} lg={7} sx={{ pt: '16px' }}>
            <LinearProgress />
            <Typography
              component={'div'}
              variant='note'
              sx={{
                p: 2,
                fontSize: '14px !important'
              }}
            >
              <span style={{ display: 'flex', padding: '8px', justifyContent: 'space-around' }}>
                Requesting downloads... Please wait before sending any new download requests.
                <br />
              </span>
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs={12} sm={8} lg={8}>
          <Box>
            <Button
              variant='contained'
              title={
                <span className='icon-text-span'>
                  <Send size={18} /> Send Download Link Via Email
                </span>
              }
              onClick={e => handleSubmit(e)}
              color='primary'
              disabled={
                (!Object.values(requestType).some(e => e === true) && !isSubmitted) || isSubmitted
              }
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            />
          </Box>
        </Grid>
      </Grid>

      {process.env.REACT_APP_ENABLE_PICSIO === 'true' && props.cohort.summarySent && (
        <SyncToPicsio cohort={props.cohort} showToast={props.showToast} />
      )}
    </Box>
  )
}
export default withApollo(ArchiveTools)
