import React, { Component } from 'react'
import { Minimize2 } from 'react-feather'
import './imagezoom.scss'
import DiscreteSlider from '../molecules/Slider'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Box } from '@mui/material'

class ImageZoom extends Component {
  constructor(props) {
    super(props)
    this.state = {    
      scale: 1
    }
  }

  componentDidMount() {
    const $navbar = document.querySelector('.container__sticky-header--empty-div')
    if($navbar) $navbar.style.zIndex = '1'
  }

  componentWillUnmount() {
    // const $navbar = document.querySelector('.navbar')
    // $navbar.style.zIndex = '10'
  }

  sliderUpdated = val => {
    if (val === this.state.scale) return
    this.setState({ scale: val })
  }

  render() {
    return (
      <TransformWrapper
        scale={this.state.scale}
        options={{
          limitToBounds: true,
          transformEnabled: true,
          disabled: false,
          limitToWrapper: false,
          maxScale: 3.0,
          minScale: 0.5,
          centerContent: true
        }}
        pan={{
          disabled: false,
          lockAxisX: false,
          lockAxisY: false,
          velocityEqualToMove: true,
          velocity: true
        }}
        pinch={{ disabled: false }}
        doubleClick={{ disabled: false }}
        wheel={{
          wheelEnabled: true,
          touchPadEnabled: true,
          limitsOnWheel: false,
          step: 0.5
        }}
        onZoomChange={e => {
          if (e.scale !== this.state.scale) this.setState({ scale: e.scale })
        }}
      >
        {({ setScale, zoomIn, zoomOut, resetTransform, ...rest }) => (
          <div
            className='container__zoom'
            onClick={e => e.target.className === 'container__zoom' && this.props.onClose()}
            style={{ display: this.props.display ? 'flex' : 'none' }}
          >
            <div className='container__controls'>
              <div className='container__zoom--icon' onClick={() => this.props.onClose()}>
                <Minimize2 />
              </div>
              <div className='container__zoom--icon'>
                <DiscreteSlider
                  orientation='vertical'
                  range={[0.5, 3.0]}
                  step={0.1}
                  onChange={(e, val) => {
                    if (val === 1.0) resetTransform()
                    if (val !== this.state.scale && this.state.scale > 0) {
                      this.setState({ scale: val })
                    }
                  }}
                  valueText={val => `${val}x`}
                  marks={true}
                  height={'150px'}
                  value={this.state.scale}
                />
              </div>
            </div>
            <div className='container__zoom__image'>
              <TransformComponent>
                <div className='container__zoom--centered'>
                  <Box
                    component='img'
                    sx={{
                      height: {
                        xs: 'auto',
                        sm: `100vh`
                      }
                    }}
                    className='container__zoom--image-large'
                    src={this.props.src}
                    ref={ref => (this.content = ref)}
                    alt=''
                  />
                </div>
              </TransformComponent>
            </div>
          </div>
        )}
      </TransformWrapper>
    )
  }
}

export default ImageZoom