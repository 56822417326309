import React, { Fragment, useEffect, useState } from 'react'
import './classes.scss'
import { withApollo } from '@apollo/client/react/hoc'
import Hero from '../ui/organisms/Hero.js'
import { observer, inject } from 'mobx-react'
import Layout from '../ui/template/Layout'
import InProgressReviews from '../ui/organisms/InProgressReviews'
import { withRouter } from '../utils/customHoc'
import { useParams } from 'react-router-dom'
import { isPublishSectionEnable } from '../utils/commonFunctions'

const Reviews = props => {
  const {currentTab: initialTab, page} = useParams()
  const { breadcrumbStore, location, userStore, basePath } = props
  const { role } = userStore

  const [currentTab, setCurrentTab] = useState(initialTab)
  const [filter, setFilter] = useState([true, false, false, false])
  const [currentFilter, setCurrentFilter] = useState('all')


  useEffect(() => {
    setCurrentTab(initialTab)
  }, [initialTab])

  breadcrumbStore.resetBreadcrumb()
  breadcrumbStore.addBreadcrumb({
    pageName: 'Reviews',
    link: location.pathname,
    basePath: basePath,
    isParent: true,
    isActive: true
  })

  const onClick = event => {
    const filters = ['all', 'educator', 'student', 'publish']
    const targetIdx = parseInt(filters.findIndex(filter => filter === event))
    const newState = [false, false, false, false]
    newState[targetIdx] = true

    setCurrentFilter(filters[targetIdx])
    setFilter(newState)
  }

  const handleTabChange = newTab => {
    setCurrentTab(newTab)
  }

  const reivewsTabs = [
    {
      eventKey: 'in-submission-review',
      title: 'In Submission Review',
      path: '/reviews/in-submission-review'
    },
    {
      eventKey: 'in-curatorial-review',
      title: 'In Curatorial Review',
      path: '/reviews/in-curatorial-review'
    },
    {
      eventKey: 'in-award-review',
      title: 'In Award Summary Review',
      path: '/reviews/in-award-review'
    },
    {
      eventKey: 'in-publishing-review',
      title: isPublishSectionEnable ? 'In Publishing Review' : 'In Form Review',
      path: '/reviews/in-publishing-review'
    }
  ]
  const curatorsTabs = [
    {
      eventKey: 'in-curatorial-review',
      title: 'In Curatorial Review',
      path: '/reviews/in-curatorial-review'
    },
    { eventKey: 'review-complete', title: 'Review Complete', path: '/reviews/review-complete' }
  ]
  return (
    <Layout>
      <Fragment>
        <Hero
          heroTitle='Reviews'
          currentTab={currentTab}
          tabChangeHandler={handleTabChange}
          tabs={role === 'appadmin' ? reivewsTabs : curatorsTabs}
          currentFilter={currentFilter}
          filtersArr={filter}
          onClick={onClick}
        />
        {currentTab === 'in-submission-review' ? (
          <InProgressReviews role={role} filter={currentFilter} reviewType='submission' page={page}/>
        ) : currentTab === 'in-curatorial-review' ? (
          <InProgressReviews role={role} filter={currentFilter} reviewType='curatorial' page={page}/>
        ) : currentTab === 'in-award-review' ? (
          <InProgressReviews role={role} filter={currentFilter} reviewType='award' page={page}/>
        ) : currentTab === 'in-publishing-review' ? (
          <InProgressReviews role={role} filter={currentFilter} reviewType='publishing' page={page}/>
        ) : currentTab === 'review-complete' ? (
          <InProgressReviews role={role} filter={currentFilter} reviewType='completed' page={page}/>
        ) : null}
      </Fragment>
    </Layout>
  )
}

export default inject('userStore', 'breadcrumbStore')(observer(withApollo(withRouter(Reviews))))
