import { Box, Grid, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import usePicsio from '../../../utils/picsioUtility'
import Button from '../../elements/Button'
import { getFormattedStatus } from '../../../utils/commonFunctions'
import { RefreshCw } from 'react-feather'
import { Link } from 'react-router-dom'
import variables from './../../settings/_variables.scss'
import { Spinner } from 'react-bootstrap'
import { validationText } from '../../../content/validationText'
import FormCheckbox from '../../elements/FormCheckbox'

export const StudentListRow = ({
  student,
  projects,
  collectionIds,
  cohort,
  showToast,
  refetchParent,
  showModal,
  setNewFiles,
  setSelectedStudent,
  setSelectedStudentProject,
  setIsAwardUpdated,
  isChecked,
  toggleCheckbox,
  parentProcessing
}) => {
  const { uploadPhoto } = usePicsio()
  const [isDisabled, setIsDisabled] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [awardedPhotos, setAwardedPhotos] = useState([])
  const [fileStatus, setFileStatus] = useState('')
  const [showDetails, setShowDetails] = useState(false)
  const [studentProject, setStudentProject] = useState()
  const [awardUpdated, setAwardUpdated] = useState(false)
  const [unSyncedPhotos, setUnSyncedPhotos] = useState([])
  const [checked, setChecked] = useState(isChecked)

  const setSelectedStudentProjectCallback = useCallback(setSelectedStudentProject, [
    setSelectedStudentProject
  ])

  useEffect(() => {
    setChecked(isChecked)
  }, [isChecked])

  useEffect(() => {
    const studentProject = projects.filter(project => {
      return project.student.id === student.id
    })
    const awardedPhotoList =
      studentProject?.[0]?.photos?.filter(photo => photo.awards.length > 0) || []
    const objectsWithAssetId = awardedPhotoList.filter(obj => obj.assetId)
    const objectsWithoutAssetId = awardedPhotoList.filter(obj => !obj.assetId)
    const pendingToSync = objectsWithAssetId?.filter(
      photo =>
        photo?.formsPendingPicsioSync?.ua?.unsyncedCount > 0 ||
        photo?.formsPendingPicsioSync?.mr?.unsyncedCount > 0
    )
    const objectsWithUpdatedAward = awardedPhotoList.filter(obj =>
      obj?.awards?.some(
        award =>
          award.nominatorType === 'AppAdmin' &&
          award.updatedAt > studentProject?.[0]?.lastSync &&
          studentProject?.[0]?.captionFileUpdatedAt > studentProject?.[0]?.lastSync
      )
    )

    const allPhotos = [
      ...new Set([...objectsWithoutAssetId, ...pendingToSync, ...objectsWithUpdatedAward])
    ]

    let status = ''
    if (allPhotos.length) {
      setIsDisabled(false)
      status = `${allPhotos.length} Un-synced File(s)`
    } else {
      setIsDisabled(true)
      status = getFormattedStatus('CheckCircle', 'All Files Synced to Pics.io')
    }

    if (isProcessing) {
      setIsDisabled(true)
    }

    setSelectedStudentProjectCallback(studentProject?.[0])
    setStudentProject(studentProject)
    setAwardedPhotos(awardedPhotoList)
    setFileStatus(status)
    setAwardUpdated(objectsWithUpdatedAward?.length || objectsWithoutAssetId?.length ? true : false)
    setUnSyncedPhotos(allPhotos)
  }, [student, projects, isProcessing, setSelectedStudentProjectCallback])

  useEffect(() => {
    if (fileStatus.length && !fileStatus.includes('All') && awardedPhotos.length) {
      setShowDetails(true)
    } else {
      setShowDetails(false)
    }
  }, [fileStatus, awardedPhotos])

  const uploadToPicsio = async () => {
    try {
      setIsDisabled(true)
      setIsProcessing(true)
      const uploadResponse = await uploadPhoto(
        student,
        studentProject?.[0],
        awardedPhotos,
        collectionIds,
        cohort,
        awardUpdated
      )
      if (uploadResponse) {
        refetchParent?.()
        showToast?.('success', validationText.picsio.syncFile.success)
      }
    } catch (error) {
      showToast?.('error', validationText.picsio.syncFile.error)
    } finally {
      setIsProcessing(false)
    }
  }

  const openModal = () => {
    setNewFiles?.(unSyncedPhotos)
    setSelectedStudent?.(student)
    setSelectedStudentProject(studentProject?.[0])
    setIsAwardUpdated?.(awardUpdated)
    showModal?.(true)
  }

  const handleClick = id => {
    if (!isDisabled) {
      setChecked(!checked)
      toggleCheckbox(id, !checked)
    }
  }

  if (awardedPhotos.length <= 0) {
    return null
  }

  return (
    <>
      <Box
        className='body__section'
        sx={{
          p: { xs: 1 },
          borderBottom: { xs: `${variables.borderThinGrey}` }
        }}
      >
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={1}>
            <FormCheckbox
              name='studentIds'
              sx={{ ml: 1 }}
              checked={awardedPhotos.length <= 0 || isDisabled ? false : checked}
              disabled={awardedPhotos.length <= 0 || isDisabled}
              value={student.id}
              onClick={() => handleClick(student.id)}
            />
          </Grid>
          <Grid item xs={7} sm={4}>
            <Link to={'/project-detail/'.concat(studentProject?.[0]?.id)}>
              <Typography variant='link' component={'p'}>
                {student.name}
              </Typography>
            </Link>
            <Typography
              variant='dataLabel'
              className='content'
              component={'p'}
              sx={{ display: { sm: 'none' } }}
            >
              {fileStatus}{' '}
              {showDetails && (
                <Typography variant='link' component={'span'} onClick={() => openModal(true)}>
                  View Details
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={4} sx={{ display: { xs: 'none', sm: 'inherit' } }}>
            <Typography variant='dataLabel' className='content' component={'p'}>
              {fileStatus}{' '}
              {showDetails && (
                <Typography variant='link' component={'span'} onClick={() => openModal(true)}>
                  View Details
                </Typography>
              )}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={3} sx={{ textAlign: 'right' }}>
            <Button
              title={
                <span className='icon-text-span'>
                  {isProcessing ? (
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      style={{ marginRight: '8px' }}
                    />
                  ) : (
                    <RefreshCw size={18} />
                  )}
                  Sync to Pics.io
                </span>
              }
              variant='contained'
              disabled={awardedPhotos.length <= 0 || isDisabled || parentProcessing}
              onClick={uploadToPicsio}
              sx={{ width: { xs: 'auto' } }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
