import React, { Component } from 'react'
import Image from 'react-bootstrap/Image'
import assignmentOverview from '../../assets/images/assignment-overview.png'
import assignmentActivities from '../../assets/images/assignment-activities.png'
import additionalResources from '../../assets/images/additional-resources.png'
import forms from '../../assets/images/forms.png'
import goingBeyond from '../../assets/images/going-beyond.png'
import help from '../../assets/images/help.png'
import photographyTips from '../../assets/images/photography-tips.png'
import writingTips from '../../assets/images/writing-tips.png'
import chapter1Img from '../../assets/images/resource-icon-chapter-1.png'
import chapter2Img from '../../assets/images/resource-icon-chapter-2.png'
import chapter3Img from '../../assets/images/resource-icon-chapter-3.png'
import chapter4Img from '../../assets/images/resource-icon-chapter-4.png'
import chapter5Img from '../../assets/images/resource-icon-chapter-5.png'
import chapter6Img from '../../assets/images/resource-icon-chapter-6.png'
import chapter7Img from '../../assets/images/resource-icon-chapter-7.png'
import chapter8Img from '../../assets/images/resource-icon-chapter-8.png'
import chapter9Img from '../../assets/images/resource-icon-chapter-9.png'
import chapter10Img from '../../assets/images/resource-icon-chapter-10.png'
import chapter11Img from '../../assets/images/resource-icon-chapter-11.png'
import chapter12Img from '../../assets/images/resource-icon-chapter-12.png'
import worksheetsImg from '../../assets/images/resource-icon-worksheets.png'
import referencesImg from '../../assets/images/resource-icon-quick-references.png'
import visitsImg from '../../assets/images/resource-icon-visit-information.png'
import fullBookImg from '../../assets/images/resource-icon-full-book-download.png'
import WebAppImg from '../../assets/images/web-app-guides.png'
import { Grid, Typography } from '@mui/material'

class ResourceHero extends Component {
  render() {
    const category = this.props.category.replace(/-+/g, ' ')
    const heroImage =
      category === 'Assignment Overview'
        ? assignmentOverview
        : category === 'Assignment Activities'
        ? assignmentActivities
        : category === 'Additional Resources'
        ? additionalResources
        : category === 'Forms'
        ? forms
        : category === 'Going Beyond'
        ? goingBeyond
        : category === 'Help'
        ? help
        : category === 'Photography Tips'
        ? photographyTips
        : category === 'Writing Tips'
        ? writingTips
        : category.includes('Chapter 1:')
        ? chapter1Img
        : category.includes('Chapter 2')
        ? chapter2Img
        : category.includes('Chapter 3')
        ? chapter3Img
        : category.includes('Chapter 4')
        ? chapter4Img
        : category.includes('Chapter 5')
        ? chapter5Img
        : category.includes('Chapter 6')
        ? chapter6Img
        : category.includes('Chapter 7')
        ? chapter7Img
        : category.includes('Chapter 8')
        ? chapter8Img
        : category.includes('Chapter 9')
        ? chapter9Img
        : category.includes('Chapter 10')
        ? chapter10Img
        : category.includes('Chapter 11')
        ? chapter11Img
        : category.includes('Chapter 12')
        ? chapter12Img
        : category.includes('Activity')
        ? worksheetsImg
        : category.includes('References')
        ? referencesImg
        : category.includes('Visit')
        ? visitsImg
        : category.includes('Full Book')
        ? fullBookImg
        : category === 'Web App Guides'
        ? WebAppImg
        : null

    const heroHeader =
      category === 'Assignment Overview' ? (
        'Assignment outline, requirements, and helpful explanations.'
      ) : category === 'Assignment Activities' ? (
        'Core activities used to explore work and family.'
      ) : category === 'Additional Resources' ? (
        'Building on foundational skills.'
      ) : category === 'Forms' ? (
        'Required forms and how and when to use them.'
      ) : category === 'Going Beyond' ? (
        <span>
          Pursuing photography beyond the <i>wrkxfmly</i> program.
        </span>
      ) : category === 'Help' ? (
        'Quick reference guides and frequently asked questions.'
      ) : category === 'Photography Tips' ? (
        'Advice on how to set up, take, and edit photographs.'
      ) : category === 'Writing Tips' ? (
        'Prompts and writing advice for image titles and capptions.'
      ) : null
    return (
      <>
        <Grid className='container__resource-hero' spacing={2} sx={{ paddingX: {xs: 4, sm: 0} }}>
          <div className='container__resource-hero--header'>
            <Typography variant="h1" className='semi-bold'>{heroHeader}</Typography>
          </div>
          <div className='container__resource-hero--image'>
            <Image src={heroImage} fluid />
          </div>
        </Grid>
      </>
    )
  }
}

export default ResourceHero
