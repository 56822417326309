import { Mutation } from '@apollo/client/react/components'
import { Box, Grid, InputAdornment, Typography } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import { Component, Fragment } from 'react'
import { Edit3, Info, Settings, X } from 'react-feather'
import { Link } from 'react-router-dom'

import { getTextContentByPage } from '../../content/textContent'
import { validationText } from '../../content/validationText.js'
import { UPDATE_COHORT_MUTATION } from '../../queries/classDetailsQueries'
import { getGradeLevelObjUsingGradeIds } from '../../utils/commonFunctions.js'

import AssignCuratorModal from '../molecules/AssignCuratorModal.js'
import AssignFacilitatorModal from '../molecules/AssignFacilitatorModal.js'
import EditGradeLevelModal from '../molecules/EditGradeLevelModal.js'
import UserDetailsModal from '../molecules/UserDetailsModal.js'

import Button from '../elements/Button.js'
import DatePicker from '../elements/DatePicker.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import FormField from '../elements/FormField'
import FormFieldMultiline from '../elements/FormFieldMultiline.js'

import './assignmentDetails.scss'
import Cancel from '../elements/Cancel'
import DateUtils from '../../utils/DateUtils'

class AssignmentDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShowCurator: false,
      modalShowFacilitator: false,
      modalCuratorDetailsShow: false,
      modalFacilitatorDetailsShow: false,
      modalTeacherDetailsShow: false,
      modalSchoolDetailsShow: false,
      modalInviteStudentShow: false,
      editClassName: false,
      editGradeLevelModal: false,
      isSavingChanges: false,
      enableSave: false,
      startDateChecked: true,
      endDateChecked: true,
      introVisitChecked: true,
      finalVisitChecked: true,
      midVisitChecked: true,
      extraVisitChecked: true,
      errors: {},
      disableAll: ['teacher', 'facilitator'].includes(this.props.role) && this.props.hasClassEnded,
      name: this.props.cohort.name ? this.props.cohort.name : null,
      startDate: this.props.cohort.startDate,
      endDate: this.props.cohort.endDate,
      curators: this.props.cohort.curators.map(curator => {
        return { ...curator, label: curator.name, value: curator.id }
      }),
      curatorIds: this.props.cohort.curators.map(e => e.id),
      facilitator: this.props.cohort.facilitator,
      facilitatorId: this.props.cohort.facilitator ? this.props.cohort.facilitator.id : null,
      adminNotes: this.props.cohort.adminNotes ? this.props.cohort.adminNotes : '',
      approval: this.props.cohort.approved,
      gradeLevel: getGradeLevelObjUsingGradeIds(this.props.cohort.gradeLevel),
      gradeIds: this.props.cohort.gradeLevel,
      otherGradeDetails: this.props.cohort.otherGradeDetails
        ? this.props.cohort.otherGradeDetails
        : null,
      secondMidVisitRequired: this.props.cohort.secondMidVisitRequired,
      introVisit: this.props.cohort.introVisit ? this.props.cohort.introVisit.date : null,
      midVisit: this.props.cohort.midVisit ? this.props.cohort.midVisit.date : null,
      secondMidVisit: this.props.cohort.secondMidVisit
        ? this.props.cohort.secondMidVisit.date
        : null,
      finalVisit: this.props.cohort.finalVisit ? this.props.cohort.finalVisit.date : null
    }
    this.originalState = this.state
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNoteChange = this.handleNoteChange.bind(this)
    this.handleCuratorChange = this.handleCuratorChange.bind(this)
    this.handleFacilitatorChange = this.handleFacilitatorChange.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    const props = nextProps

    this.setState({
      name: props.cohort.name ? props.cohort.name : null,
      startDate: props.cohort.startDate,
      endDate: props.cohort.endDate,
      curators: props.cohort.curators.map(curator => {
        return { ...curator, label: curator.name, value: curator.id }
      }),
      curatorIds: props.cohort.curators.map(e => e.id),
      facilitator: props.cohort.facilitator,
      facilitatorId: props.cohort.facilitator ? props.cohort.facilitator.id : null,
      adminNotes: props.cohort.adminNotes ? props.cohort.adminNotes : '',
      approval: props.cohort.approved,
      gradeLevel: getGradeLevelObjUsingGradeIds(this.props.cohort.gradeLevel),
      secondMidVisitRequired: props.cohort.secondMidVisitRequired,
      introVisit: props.cohort.introVisit ? props.cohort.introVisit.date : null,
      midVisit: props.cohort.midVisit ? props.cohort.midVisit.date : null,
      secondMidVisit: props.cohort.secondMidVisit ? props.cohort.secondMidVisit.date : null,
      finalVisit: props.cohort.finalVisit ? props.cohort.finalVisit.date : null
    })
  }

  handleCohortApproval(e) {
    this.setState({
      approved: e.target.checked
    })
  }

  handleGradeChange = choice => {
    this.setState({
      gradeIds: choice.map(choice => choice.value),
      gradeLevel: choice,
      otherGradeDetails: choice.map(choice => choice.value).includes('Other')
        ? this.state.otherGradeDetails
        : ''
    })
  }

  handleCuratorChange(curatorIds, curators) {
    this.setState({
      curatorIds: curatorIds,
      curators: curators,
      modalShowCurator: false,
      enableSave: true
    })
  }
  handleFacilitatorChange(facilitator) {
    this.setState({
      facilitatorId: facilitator.id,
      facilitator: facilitator,
      modalShowFacilitator: false,
      enableSave: true
    })
  }
  handleNoteChange(e) {
    this.setState({ adminNotes: e.target.value, enableSave: true })
  }

  handleFormChange(e) {
    const value = e.target.value
    this.setState({
      [e.target.name]: value,
      enableSave: true,
      startDateChecked: true,
      endDateChecked: true,
      introVisitChecked: true,
      finalVisitChecked: true,
      midVisitChecked: true,
      extraVisitChecked: true
    })
    const nameFormat = /(\\|\/)/;
    switch (e.target.name) {
      case 'name':
        if (value.length > 40) {
          this.setState({
            enableSave: false,
            errors: {
              ...this.state.errors,
              nameError: validationText.assignmentDetails.error.name.length
            }
          })
        } else if (!value) {
          this.setState({
            enableSave: false,
            errors: {
              ...this.state.errors,
              nameError: validationText.assignmentDetails.error.name.empty
            }
          })
        } else if (nameFormat.test(value))
        {
          this.setState({
            enableSave: false,
            errors: {
              ...this.state.errors, nameError: validationText.assignmentDetails.error.name.specialCharacters
            }
          })
        } else {
          this.setState({
            errors: {
              ...this.state.errors,
              nameError: null
            }
          })
        }
        break
      case 'gradedLevel':
        if (!value) {
          this.setState({
            errors: {
              ...this.state.errors,
              gradeError: validationText.assignmentDetails.error.name.empty
            }
          })
        } else {
          this.setState({
            errors: {
              ...this.state.errors,
              gradeError: null
            }
          })
        }
        break
      case 'startDate':
        this.validateStartDate(value)
        break
      case 'endDate':
        this.validateEndDate(value)
        break
      case 'introVisit':
        this.validateIntroVisitDate(value)
        break
      case 'midVisit':
        this.validateMidVisitDate(value)
        break
      case 'finalVisit':
        this.validateFinalVisitDate(value)
        break
      case 'secondMidVisit':
        this.validateExtraVisitDate(value)
        break
      default:
        return
    }
  }

  validateStartDate = value => {
    let endDate = this.state.endDate
    let introVisitDate = this.state.introVisit
    let extraVisitDate = this.state.secondMidVisit
    let errArray = { startDateError: null }

    if (!value || value.toString() === 'Invalid Date') {
      errArray.startDateError = validationText.assignmentDetails.error.date.emptyStartDate
      this.setState({ enableSave: false, startDateChecked: false })
    }
    if (
      endDate &&
      moment(endDate, 'YYYY-MM-DD').isSameOrBefore(moment(value).format('YYYY-MM-DD'))
    ) {
      errArray.startDateError = validationText.assignmentDetails.error.date.afterEndDate
      errArray.endDateError = validationText.assignmentDetails.error.date.afterStartDate
      this.setState({ enableSave: false, startDateChecked: false })
    }
    if (introVisitDate && value) {
      errArray.introVisitDateError = moment(introVisitDate, 'YYYY-MM-DD').isBefore(
        moment(value).format('YYYY-MM-DD')
      )
        ? validationText.assignmentDetails.error.date.afterStartDate
        : null
      if (errArray.introVisitDateError) {
        this.setState({ enableSave: false, startDateChecked: false })
      } else {
        if (moment(endDate, 'YYYY-MM-DD').isBefore(moment(introVisitDate).format('YYYY-MM-DD'))) {
          errArray.introVisitDateError = validationText.assignmentDetails.error.date.afterEndDate
          this.setState({ enableSave: false, endDateChecked: false })
        } else {
          errArray.introVisitDateError = null
        }
      }
    }
    if (extraVisitDate) {
      errArray.extraVisitDateError = moment(value, 'YYYY-MM-DD').isAfter(
        moment(extraVisitDate).format('YYYY-MM-DD')
      )
        ? validationText.assignmentDetails.error.date.afterStartDate
        : null
      if (errArray.extraVisitDateError) {
        this.setState({ enableSave: false, startDateChecked: false })
      }
    }
    this.setState({ errors: { ...this.state.errors, ...errArray } })
  }

  validateEndDate = value => {
    let startDate = this.state.startDate
    let introVisitDate = this.state.introVisit
    let extraVisitDate = this.state.secondMidVisit
    let errArray = { endDateError: null }
    if (!value || value.toString() === 'Invalid Date') {
      errArray.endDateError = validationText.assignmentDetails.error.date.emptyEndDate
      this.setState({ enableSave: false, endDateChecked: false })
    }

    if (startDate && value && value.toString() !== 'Invalid Date') {
      if (moment(startDate, 'YYYY-MM-DD').isSameOrAfter(moment(value).format('YYYY-MM-DD'))) {
        errArray.endDateError = validationText.assignmentDetails.error.date.afterStartDate
        errArray.startDateError = validationText.assignmentDetails.error.date.afterEndDate
        this.setState({ enableSave: false, endDateChecked: false })
      } else {
        errArray.endDateError = null
        errArray.startDateError = null
      }
      this.setState({ errors: { ...this.state.errors, ...errArray } })
    }

    if (introVisitDate && value) {
      if (moment(value, 'YYYY-MM-DD').isBefore(moment(introVisitDate).format('YYYY-MM-DD'))) {
        errArray.introVisitDateError = validationText.assignmentDetails.error.date.afterEndDate
        this.setState({ enableSave: false, endDateChecked: false })
      } else {
        errArray.introVisitDateError = null
      }
    }
    if (extraVisitDate) {
      if (moment(value, 'YYYY-MM-DD').isBefore(moment(extraVisitDate).format('YYYY-MM-DD'))) {
        errArray.extraVisitDateError = validationText.assignmentDetails.error.date.afterStartDate
        this.setState({ enableSave: false, endDateChecked: false })
      } else {
        errArray.extraVisitDateError = null
      }
    }
    this.setState({ errors: { ...this.state.errors, ...errArray } })
  }

  validateIntroVisitDate = value => {
    let startDate = this.state.startDate
    let endDate = this.state.endDate
    let midVisitDate = this.state.midVisit
    let finalVisitDate = this.state.finalVisit
    let errArray = { introVisitDateError: null }
    if (
      startDate &&
      value &&
      moment(value, 'YYYY-MM-DD').isBefore(moment(startDate).format('YYYY-MM-DD'))
    ) {
      errArray.introVisitDateError = validationText.assignmentDetails.error.date.afterStartDate
      this.setState({ enableSave: false, introVisitChecked: false })
    } else if (
      endDate &&
      value &&
      moment(endDate, 'YYYY-MM-DD').isBefore(moment(value).format('YYYY-MM-DD'))
    ) {
      errArray.introVisitDateError = validationText.assignmentDetails.error.date.afterEndDate
      this.setState({ enableSave: false, introVisitChecked: false })
    }

    if (value && midVisitDate) {
      errArray.midVisitDateError = moment(value, 'YYYY-MM-DD').isAfter(
        moment(midVisitDate).format('YYYY-MM-DD')
      )
        ? validationText.assignmentDetails.error.date.afterFirstVisitDate
        : null
      if (errArray.midVisitDateError) {
        this.setState({ enableSave: false, introVisitChecked: false })
      }
    }

    if (finalVisitDate && value) {
      errArray.finalVisitDateError = moment(value, 'YYYY-MM-DD').isAfter(
        moment(finalVisitDate).format('YYYY-MM-DD')
      )
        ? validationText.assignmentDetails.error.date.betweenVisitDate
        : null
      if (errArray.finalVisitDateError) {
        this.setState({ enableSave: false, introVisitChecked: false })
      }
    }
    this.setState({ errors: { ...this.state.errors, ...errArray } })
  }

  validateMidVisitDate = value => {
    let introVisitDate = this.state.introVisit
    let finalVisitDate = this.state.finalVisit
    let endDate = this.state.endDate
    let errArray = { midVisitDateError: null }
    if (
      introVisitDate &&
      value &&
      moment(introVisitDate, 'YYYY-MM-DD').isAfter(moment(value).format('YYYY-MM-DD'))
    ) {
      errArray.midVisitDateError = validationText.assignmentDetails.error.date.afterFirstVisitDate
      this.setState({ enableSave: false, midVisitChecked: false })
    } else if (
      endDate &&
      value &&
      moment(endDate, 'YYYY-MM-DD').isBefore(moment(value).format('YYYY-MM-DD'))
    ) {
      errArray.midVisitDateError = validationText.assignmentDetails.error.date.afterEndDate
      this.setState({ enableSave: false, midVisitChecked: false })
    }

    if (finalVisitDate && value) {
      errArray.finalVisitDateError = moment(value, 'YYYY-MM-DD').isAfter(
        moment(finalVisitDate).format('YYYY-MM-DD')
      )
        ? validationText.assignmentDetails.error.date.betweenVisitDate
        : null
      if (errArray.finalVisitDateError) {
        this.setState({ enableSave: false, midVisitChecked: false })
      }
    }

    this.setState({ errors: { ...this.state.errors, ...errArray } })
  }

  validateFinalVisitDate = value => {
    let introVisitDate = this.state.introVisit
    let midVisitDate = this.state.midVisit
    let endDate = this.state.endDate
    let errArray = { finalVisitDateError: null }
    if (value) {
      if (
        introVisitDate &&
        moment(introVisitDate, 'YYYY-MM-DD').isAfter(moment(value).format('YYYY-MM-DD'))
      ) {
        errArray.finalVisitDateError = validationText.assignmentDetails.error.date.betweenVisitDate
        this.setState({ enableSave: false, finalVisitChecked: false })
      } else if (
        midVisitDate &&
        moment(midVisitDate, 'YYYY-MM-DD').isAfter(moment(value).format('YYYY-MM-DD'))
      ) {
        errArray.finalVisitDateError = validationText.assignmentDetails.error.date.betweenVisitDate
        this.setState({ enableSave: false, finalVisitChecked: false })
      } else if (
        endDate &&
        value &&
        moment(endDate, 'YYYY-MM-DD').isBefore(moment(value).format('YYYY-MM-DD'))
      ) {
        errArray.finalVisitDateError = validationText.assignmentDetails.error.date.afterEndDate
        this.setState({ enableSave: false, finalVisitChecked: false })
      }
    }
    this.setState({ errors: { ...this.state.errors, ...errArray } })
  }

  validateExtraVisitDate = value => {
    let startDate = this.state.startDate
    let endDate = this.state.endDate
    let errArray = {}
    if (
      startDate &&
      value &&
      moment(value, 'YYYY-MM-DD').isBefore(moment(startDate).format('YYYY-MM-DD'))
    ) {
      errArray.extraVisitDateError = validationText.assignmentDetails.error.date.afterStartDate
      this.setState({ enableSave: false, extraVisitChecked: false })
    } else if (
      endDate &&
      value &&
      moment(endDate, 'YYYY-MM-DD').isBefore(moment(value).format('YYYY-MM-DD'))
    ) {
      errArray.extraVisitDateError = validationText.assignmentDetails.error.date.afterEndDate
      this.setState({ enableSave: false, extraVisitChecked: false })
    } else {
      errArray.extraVisitDateError = null
    }
    this.setState({ errors: { ...this.state.errors, ...errArray } })
  }

  handleSubmit(e, modifyCohortMutate) {
    this.setState({
      ...this.state,
      editClassName: false,
      isSavingChanges: true,
      enableSave: false
    })
    const INVAID_DATE = 'invalid date'
    modifyCohortMutate({
      variables: {
        cohortId: this.props.cohort.id,
        approval: this.state.approved,
        noteBody: this.state.adminNotes,
        curatorIds: this.state.curatorIds,
        facilitatorId: this.state.facilitator ? this.state.facilitator.id : null,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        introVisit: this.state?.introVisit?.toString()?.toLowerCase() === INVAID_DATE ? null : this.state.introVisit,
        midVisit: this.state?.midVisit?.toString()?.toLowerCase() === INVAID_DATE ? null : this.state.midVisit,
        secondMidVisit: this.state?.secondMidVisit?.toString()?.toLowerCase() === INVAID_DATE ? null : this.state.secondMidVisit,
        secondMidVisitRequired: this.state.secondMidVisitRequired,
        finalVisit: this.state?.finalVisit?.toString()?.toLowerCase() === INVAID_DATE ? null : this.state.finalVisit,
        name: this.state.name,
        gradeLevel: this.state.gradeIds.toString(),
        otherGradeDetails: this.state.otherGradeDetails
      },
      refetchQueries: [`ClassDetails`]
    })
    this.setState({ isSavingChanges: false })
    this.originalState = this.state
  }

  discardChanges = () => {
    this.setState({ ...this.originalState, enableSave: false })
    // this.props.showToast('success', validationText.generic.success)
  }

  getCompleteGradeDetails = () => {
    let gradeDetails = this.state.gradeIds
    if (this.state.otherGradeDetails) {
      gradeDetails += ', ' + this.state.otherGradeDetails
    }
    return gradeDetails
  }

  updateGradesOnParent = gradeDetails => {
    this.setState({ ...this.state, enableSave: true, ...gradeDetails })
  }

  render() {
    let modalClose = () => {
      this.setState({
        modalShowCurator: false,
        modalShowFacilitator: false,
        modalCuratorDetailsShow: false,
        modalFacilitatorDetailsShow: false,
        modalTeacherDetailsShow: false,
        modalSchoolDetailsShow: false,
        modalInviteStudentShow: false,
        editGradeLevelModal: false
      })
    }
    const { handleFormChange } = this
    let { assignment, projects } = this.props.cohort
    const { role } = this.props
    if (!projects) projects = this.props.cohort.submittedProjects

    const { cohort, hasClassEnded } = this.props

    return (
      <Mutation
        mutation={UPDATE_COHORT_MUTATION}
        onCompleted={() => {
          this.props.showToast('success', validationText.assignmentDetails.success.save)
        }}
        onError={e => this.props.showToast('error', validationText.assignmentDetails.error.common)}
      >
        {(modifyCohortMutate, { data }) => (
          <Box
            className='container__body'
            sx={{ px: { xs: 4, md: 6 }, pt: { xs: 5, md: 6, lg: 10 }, pb: { xs: 10 } }}
          >
            <Box className='body__assignment-schedule'>
              {hasClassEnded && ['teacher', 'facilitator'].includes(role) ? (
                <Box className='class-details__class-ended-notice'>
                  <Typography
                    variant={'paragraph'}
                    component={'div'}
                    style={{ display: 'flex'}}
                  >
                    <span style={{ marginRight: '8px' }}>
                      <Info size={30} color='#01568f' />
                    </span>
                    <span style={{ flex: '1' }}>
                      {getTextContentByPage(
                        'CLASSDETAILS',
                        role.toUpperCase(),
                        'CLASS_END_NOTICE',
                        'CLASSDETAILS'
                      )}
                    </span>
                  </Typography>
                </Box>
              ) : null}

              <Box>
                <Grid container gap={{ xs: 2 }}>
                  <Grid item xs={12} sm={10} md={8} lg={7}>
                    <Typography variant='h1' component='p' className='bold'>
                      Class Dates
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} md={8}>
                    <Typography variant='paragraph' component='p'>
                      {getTextContentByPage('CLASSDETAILS', role.toUpperCase(), 'CLASSDATES_INTRO')}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container gap={{ xs: 5, sm: 0 }} columnSpacing={2} sx={{ mt: { xs: 3 } }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DatePicker
                      onChange={date =>
                        handleFormChange({
                          target: {
                            value: date,
                            name: 'startDate'
                          }
                        })
                      }
                      value={moment(this.state.startDate).toDate()}
                      label='Class Start Date'
                      labelId='classStartDate'
                      disabled={role !== 'appadmin'}
                      error={this.state.errors.startDateError ? true : false}
                      helperText={this.state.errors.startDateError}
                      maxDate={moment(this.state.endDate).subtract(1, 'days')}
                      name='startDate'
                      className='custom-input-bg'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <DatePicker
                      onChange={date =>
                        handleFormChange({
                          target: {
                            value: date,
                            name: 'endDate'
                          }
                        })
                      }
                      value={moment(this.state.endDate).toDate()}
                      label='Class Submission Date'
                      labelId='classSubmissionDate'
                      disabled={role !== 'appadmin'}
                      error={this.state.errors.endDateError ? true : false}
                      helperText={this.state.errors.endDateError}
                      name='endDate'
                      className='custom-input-bg'
                    />
                  </Grid>
                </Grid>
                <Grid container sx={{ gap: 2, mt: { xs: 6 } }}>
                  <Grid item xs={12} sm={10} md={8} lg={7}>
                    <Typography variant='h3' className='strong' sx={{ pb: 0 }}>
                      Teaching Artist Visit Dates
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={10} md={8} lg={7}>
                    <Typography variant='paragraph' component='p'>
                      {getTextContentByPage(
                        'CLASSDETAILS',
                        role.toUpperCase(),
                        'CLASSVISITDATES_INTRO'
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container gap={{ xs: 5, sm: 0 }} columnSpacing={2} sx={{ mt: { xs: 3 } }}>
                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      onChange={date =>
                        handleFormChange({
                          target: {
                            value: date,
                            name: 'introVisit'
                          }
                        })
                      }
                      name='introVisit'
                      value={moment(this.state.introVisit).toDate()}
                      label='Introduction Visit On'
                      labelId='introductionVisitOn'
                      disabled={this.state.disableAll}
                      error={this.state.errors.introVisitDateError ? true : false}
                      helperText={this.state.errors.introVisitDateError}
                      minDate={moment(this.state.startDate).add(0, 'days')}
                      maxDate={this.state.endDate}
                      className='custom-input-bg'
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      onChange={date =>
                        handleFormChange({
                          target: {
                            value: date,
                            name: 'midVisit'
                          }
                        })
                      }
                      value={moment(this.state.midVisit).toDate()}
                      label='Mid-Point Visit On'
                      labelId='midPointVisitOn'
                      disabled={this.state.disableAll}
                      error={this.state.errors.midVisitDateError ? true : false}
                      helperText={this.state.errors.midVisitDateError}
                      minDate={moment(
                        this.state.introVisit ? this.state.introVisit : this.state.startDate
                      ).add(1, 'days')}
                      maxDate={this.state.endDate}
                      name='midVisit'
                      className='custom-input-bg'
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <DatePicker
                      onChange={date =>
                        handleFormChange({
                          target: {
                            value: date,
                            name: 'finalVisit'
                          }
                        })
                      }
                      name='finalVisit'
                      value={moment(this.state.finalVisit).toDate()}
                      label='Final Visit On'
                      labelId='finalVisitOn'
                      disabled={this.state.disableAll}
                      error={this.state.errors.finalVisitDateError ? true : false}
                      helperText={this.state.errors.finalVisitDateError}
                      minDate={moment(
                        this.state.midVisit
                          ? this.state.midVisit
                          : this.state.introVisit
                          ? this.state.introVisit
                          : this.state.startDate
                      ).add(1, 'days')}
                      maxDate={this.state.endDate}
                      className='custom-input-bg'
                    />
                  </Grid>
                </Grid>

                {this.state.secondMidVisitRequired || role === 'appadmin' ? (
                  <>
                    <Grid container sx={{ mt: { xs: 4 } }}>
                      <Grid item xs={12} sm={10} md={8} lg={7}>
                        <Typography variant='paragraph' component='p'>
                          {getTextContentByPage(
                            'CLASSDETAILS',
                            role.toUpperCase(),
                            'EXTRACLASSVISITDATE_INTRO'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ mt: { xs: 3 } }} columnSpacing={2}>
                      <Grid item xs={12} sm={4}>
                        <DatePicker
                          onChange={date =>
                            handleFormChange({
                              target: {
                                value: date,
                                name: 'secondMidVisit'
                              }
                            })
                          }
                          name='secondMidVisit'
                          disabled={
                            !this.state.secondMidVisitRequired || this.state.disableAll
                              ? true
                              : false
                          }
                          value={moment(this.state.secondMidVisit).toDate()}
                          label='Extra Visit On'
                          labelId='extraVisitOn'
                          switchLabel='Enable Extra Visit'
                          labelRadioButton={role === 'appadmin' ? true : false}
                          secondMidVisitRequired={this.state.secondMidVisitRequired}
                          switchChange={() => {
                            this.setState({
                              secondMidVisitRequired: !this.state.secondMidVisitRequired,
                              enableSave:
                                role === 'appadmin'
                                  ? this.state.enableSave ||
                                    (this.state.startDateChecked &&
                                      this.state.endDateChecked &&
                                      this.state.introVisitChecked &&
                                      this.state.finalVisitChecked &&
                                      this.state.midVisitChecked &&
                                      this.state.extraVisitChecked)
                                  : false
                            })
                          }}
                          SwitchHeight={'38px'}
                          switchAlignItems={'baseline'}
                          error={this.state.errors.extraVisitDateError ? true : false}
                          helperText={this.state.errors.extraVisitDateError}
                          minDate={moment(this.state.startDate).toDate()}
                          maxDate={moment(this.state.endDate).toDate()}
                          className='custom-input-bg'
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </Box>

              <Box sx={{ mt: { xs: 7, sm: 6 } }}>
                <Typography variant='h1' className='bold'>
                  Class Information
                </Typography>
                <Grid container gap={{ xs: 5, sm: 0 }} columnSpacing={2} sx={{ mt: { xs: 4 } }}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      formFieldType='text'
                      formFieldName='name'
                      formFieldVariant='filled'
                      formFieldLabel='Class Name'
                      fullWidth={true}
                      formFieldValue={this.state.name}
                      formFieldPlaceholder={cohort.name}
                      onChange={this.handleFormChange}
                      formFieldError={this.state.errors.nameError}
                      disabled={!this.state.editClassName}
                      className='custom-input-bg'
                      formInputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {this.state.disableAll ? null : (
                              <span className='edit-text'>
                                <>
                                  {_.includes(['teacher', 'appadmin', 'facilitator'], role) &&
                                  !this.props.showOnly ? (
                                    this.state.editClassName ? (
                                      <X
                                        onClick={() => this.setState({ editClassName: false })}
                                        size={20}
                                        color='#2d2d2d'
                                      />
                                    ) : (
                                      <Edit3
                                        onClick={() => this.setState({ editClassName: true })}
                                        size={20}
                                        color='#2d2d2d'
                                      />
                                    )
                                  ) : null}
                                </>
                              </span>
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      formFieldType='text'
                      formFieldName='gradeLevel'
                      formFieldVariant='filled'
                      formFieldLabel='Grade Level(s)'
                      fullWidth={true}
                      formFieldValue={this.getCompleteGradeDetails()}
                      formFieldPlaceholder={this.getCompleteGradeDetails()}
                      formFieldError={this.state.errors.gradeLevelError}
                      disabled={true}
                      className='custom-input-bg'
                      formInputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            {this.state.disableAll ? null : (
                              <span className='edit-text'>
                                <Edit3
                                  onClick={() => this.setState({ editGradeLevelModal: true })}
                                  size={20}
                                  color='#2d2d2d'
                                />
                              </span>
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                    <EditGradeLevelModal
                      show={this.state.editGradeLevelModal}
                      onHide={modalClose}
                      gradeLevel={this.state.gradeLevel}
                      gradeIds={
                        typeof this.state.gradeIds === 'string'
                          ? this.state.gradeIds.split(',')
                          : this.state.gradeIds
                      }
                      otherGradeDetails={this.state.otherGradeDetails}
                      updateGradesOnParent={this.updateGradesOnParent}
                    />
                  </Grid>
                </Grid>
                <Grid container gap={{ xs: 4, sm: 0 }} columnSpacing={2} sx={{ mt: { xs: 4 } }}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant='dataLabel' component={'p'}>
                      Assignment
                    </Typography>
                    <Typography variant='dataLabel' component={'p'} className='content'>
                      Photography: {assignment.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {projects.length === 0 ? (
                      <>
                        <Typography variant='dataLabel' component={'p'}>
                          Class Size Estimate
                        </Typography>
                        <Typography variant='dataLabel' component={'p'} className='content'>
                          {cohort.estimatedNumberOfStudents} Students
                          <br />
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography variant='dataLabel' component={'p'}>
                          Students Signed Up
                        </Typography>
                        <Link
                          to={`/class-detail/${cohort.id}/student-list`}
                          // onClick={() => this.props.tabChangeHandler('student-list')}
                        >
                          <Typography variant='link' className='labeled-data large'>
                            {projects.length} Students
                          </Typography>
                        </Link>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Box sx={{ mt: { xs: 6 } }}>
                  <Typography variant='h3' className='strong' sx={{ pb: 0 }}>
                    Class Support
                  </Typography>
                  <Grid container sx={{ mt: { xs: 4 } }} gap={{ xs: 4, sm: 0 }} columnSpacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='dataLabel' component={'p'}>
                        Teacher
                      </Typography>
                      <Typography
                        variant='link'
                        className='labeled-data large'
                        onClick={() => this.setState({ modalTeacherDetailsShow: true })}
                      >
                        {cohort.teacher.name}
                      </Typography>
                      <UserDetailsModal
                        show={this.state.modalTeacherDetailsShow}
                        onHide={modalClose}
                        name={cohort.teacher.name}
                        userId={cohort.teacher.userId}
                        userType='Teacher'
                        email={cohort.teacher.email}
                        mobileNumber={cohort.teacher.mobileNumber}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant='dataLabel' component={'p'}>
                        Teaching Artist
                      </Typography>
                      {this.state.facilitator ? (
                        <Fragment>
                          <Typography
                            variant='link'
                            className='labeled-data large'
                            onClick={() => this.setState({ modalFacilitatorDetailsShow: true })}
                          >
                            {this.state.facilitator.name}
                          </Typography>

                          <UserDetailsModal
                            show={this.state.modalFacilitatorDetailsShow}
                            onHide={modalClose}
                            name={this.state.facilitator.name}
                            userId={this.state.facilitator.userId}
                            bio={this.state.facilitator.bio}
                            userType='Teaching Artist'
                            website={this.state.facilitator.website}
                            email={this.state.facilitator.email}
                            mobileNumber={this.state.facilitator.mobileNumber}
                            instagramHandle={this.state.facilitator.instagramHandle}
                          />
                        </Fragment>
                      ) : (
                        <Typography variant='dataLabel' component={'p'} className='content'>
                          None Assigned
                        </Typography>
                      )}
                      {role === 'appadmin' && !this.props.showOnly ? (
                        this.state.facilitator === 0 ? (
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              variant='link'
                              className='labeled-data icon-link'
                              onClick={() => this.setState({ modalShowFacilitator: true })}
                            >
                              <Settings size={16} />
                              Add a Teaching Artist
                            </Typography>
                            <AssignFacilitatorModal
                              show={this.state.modalShowFacilitator}
                              onHide={modalClose}
                              handleSave={this.handleFacilitatorChange}
                            />
                          </Box>
                        ) : (
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              variant='link'
                              className='labeled-data icon-link'
                              onClick={() => this.setState({ modalShowFacilitator: true })}
                            >
                              <Settings size={16} />
                              Edit Teaching Artist
                            </Typography>
                            <AssignFacilitatorModal
                              show={this.state.modalShowFacilitator}
                              onHide={modalClose}
                              handleSave={this.handleFacilitatorChange}
                              facilitator={this.state.facilitator}
                              facilitatorId={this.state.facilitatorId}
                            />
                          </Box>
                        )
                      ) : null}
                    </Grid>
                    {role === 'appadmin' ? (
                      <Grid item xs={12} sm={6} md={4} sx={{ mt: { sm: 2, md: 0 } }}>
                        <Typography variant='dataLabel' component={'p'}>
                          Curator(s)
                        </Typography>
                        {this.state.curators.map((curator, index) => (
                          <Box key={index} className='class-details__curator-names'>
                            <Typography
                              variant='link'
                              className='labeled-data large'
                              onClick={() =>
                                this.setState({
                                  modalCuratorDetailsShow: true,
                                  modalUserIndex: index
                                })
                              }
                            >
                              {curator.name}
                            </Typography>
                            {curator ? (
                              <UserDetailsModal
                                show={
                                  this.state.modalCuratorDetailsShow &&
                                  this.state.modalUserIndex === index
                                }
                                onHide={modalClose}
                                name={curator.name}
                                userId={curator.userId}
                                bio={curator.bio}
                                userType='Curator'
                                website={curator.website}
                                email={curator.email}
                                mobileNumber={curator.mobileNumber}
                                instagramHandle={curator.instagramHandle}
                              />
                            ) : null}
                          </Box>
                        ))}
                        {!this.props.showOnly ? (
                          this.state.curators.length === 0 ? (
                            <Box sx={{ mt: 2 }}>
                              <Typography
                                variant='link'
                                className='labeled-data icon-link'
                                onClick={() => this.setState({ modalShowCurator: true })}
                              >
                                <Settings size={16} />
                                Add a Curator
                              </Typography>
                              <AssignCuratorModal
                                show={this.state.modalShowCurator}
                                onHide={modalClose}
                                handleSave={this.handleCuratorChange}
                                curatorIds={this.state.curatorIds}
                                curators={this.state.curators}
                                open={this.state.modalShowCurator}
                              />
                            </Box>
                          ) : (
                            <Box sx={{ mt: 2 }}>
                              <Typography
                                variant='link'
                                className='labeled-data icon-link'
                                onClick={() => this.setState({ modalShowCurator: true })}
                              >
                                <Settings size={16} />
                                Edit Curator(s)
                              </Typography>
                              <AssignCuratorModal
                                show={this.state.modalShowCurator}
                                onHide={modalClose}
                                handleSave={this.handleCuratorChange}
                                curatorIds={this.state.curatorIds}
                                curators={this.state.curators}
                              />
                            </Box>
                          )
                        ) : null}
                      </Grid>
                    ) : null}
                  </Grid>
                </Box>

                {role === 'appadmin' ? (
                  <Grid container sx={{ mt: { xs: 6 } }} gap={{ xs: 3 }}>
                    <Grid item xs={12} sm={10} md={8} lg={7}>
                      <Typography variant='h3' className='strong' sx={{ pb: 0 }}>
                        Administrative Notes
                      </Typography>
                      <Typography variant='paragraph' component={'p'} sx={{ mt: { xs: 2 } }}>
                        {getTextContentByPage(
                          'CLASSDETAILS',
                          role.toUpperCase(),
                          'ADMINISTRATIVE_NOTES_INTRO'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} lg={7}>
                      <FormFieldMultiline
                        formFieldId='adminNotes'
                        formFieldName='adminNotes'
                        formFieldPlaceholder='Class Notes'
                        formFieldLabel=''
                        onChange={this.handleNoteChange}
                        formFieldDefaultValue={this.state.adminNotes}
                        className='custom-input-bg'
                      />
                    </Grid>
                  </Grid>
                ) : null}
                {role === 'appadmin' && [false, null].includes(cohort.approved) ? (
                  <Grid container sx={{ mt: { xs: 6 } }} gap={{ xs: 3 }}>
                    <Grid item xs={12} sm={10} md={8} lg={7}>
                      <Typography variant='h3' className='strong' sx={{ pb: 0 }}>
                        Class Approval
                      </Typography>
                      <Typography variant='paragraph' component={'p'} sx={{ mt: { xs: 2 } }}>
                        {getTextContentByPage(
                          'CLASSDETAILS',
                          role.toUpperCase(),
                          'CLASS_APPROVAL_INTRO'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} md={8} lg={7}>
                      <FormCheckbox
                        onChange={e => this.handleCohortApproval(e)}
                        name='overallApproval'
                        disabled={!this.state.facilitator}
                        checked={this.state.approved}
                        label='Approve Class'
                      />
                    </Grid>
                  </Grid>
                ) : null}
                {this.state.disableAll ? null : (
                  <Grid
                    container
                    sx={{
                      mt: { xs: 5 },
                      textAlign: { xs: 'center', sm: 'left' }
                    }}
                    gap={{ xs: 3, sm: 2 }}
                    alignItems='center'
                  >
                    <Grid item xs={12} sm={6} md={2} lg={1.5}>
                      <Button
                        variant='contained'
                        type='submit'
                        title='Save Changes'
                        disabled={
                          !(
                            this.state.enableSave &&
                            this.state.startDateChecked &&
                            this.state.endDateChecked &&
                            this.state.introVisitChecked &&
                            this.state.finalVisitChecked &&
                            this.state.midVisitChecked &&
                            this.state.extraVisitChecked && 
                            !this.state.errors.nameError
                          )
                        }
                        onClick={e => {
                          this.handleSubmit(e, modifyCohortMutate)
                        }}
                        sx={{ width: { xs: '100%' } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Cancel
                        onClick={this.discardChanges}
                        showToast={this.props.showToast}
                        label='Discard Changes'
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Mutation>
    )
  }
}

export default AssignmentDetails
