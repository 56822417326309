import React from 'react'
import { ChevronRight } from 'react-feather'
import { Breadcrumbs, Typography } from '@mui/material'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

const CustomBreadcrumbs = props => {
  const { breadcrumbStore } = props
  const breadCrumbLink = (pageName, link, isActive) => {
    return (
      <Link to={link} key={link}>
        <Typography
          variant='dataLabel'
          className={isActive ? 'breadCrumbLinkActive' : 'breadcrumbLink'}
        >
          {pageName}
        </Typography>
      </Link>
    )
  }
  return (
    <React.Fragment>
      {breadcrumbStore.breadcrumbs !== null ? (
        <Breadcrumbs maxItems={4} separator={<ChevronRight size={20} />} aria-label='breadcrumb'>
          {breadcrumbStore.breadcrumbs.map(crumb =>
            breadCrumbLink(crumb.pageName, crumb.link, crumb.isActive)
          )}
        </Breadcrumbs>
      ) : null}
    </React.Fragment>
  )
}

export default inject('breadcrumbStore')(observer(CustomBreadcrumbs))
