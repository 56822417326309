import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import FormCheckbox from '../elements/FormCheckbox.js'
import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'

class UserListRowStudents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showPhotos: false
    }
  }

  render() {
    const { student, checked, userId } = this.props

    return (
      <>
        <Box
          className='body__section'
          sx={{
            p: { xs: 3, md: 4 },
            borderBottom: { xs: '0.0625rem solid rgb(213, 213, 215)' },
            margin: { xs: '0px !important', md: '8px 0 !important' }
          }}
        >
          <Grid container alignItems='center'>
            <Grid item xs={1}>
              <Box sx={{ textAlign: 'center' }}>
                <FormCheckbox
                  checked={checked}
                  onChange={() => this.props.onChecked(userId)}
                  value='checkedReminder'
                  color='primary'
                />
              </Box>
            </Grid>
            <Grid item lg={4} md={6} xs={9}>
              <Link to={'/user-details/'.concat(userId)}>
                <Typography variant='h4' className='semi-strong link link--large'>
                  {student.name.replace('NotSupplied', '').trim()}
                </Typography>
              </Link>
              <Typography variant='h4' className='table_row' sx={{ mt: { xs: 1, md: 2 } }}>
                {student.cohorts && student.cohorts[0]
                  ? student.cohorts[0].school.name
                  : 'No School'}
              </Typography>
              <Box sx={{ display: { xs: 'block', md: 'none' }, mt: { xs: 3, md: 'auto' } }}>
                <Typography variant='h4' className='table_row'>
                  {student.totalAwardedPhotographs
                    ? student.totalAwardedPhotographs + ' Awarded Photograph(s)'
                    : 'No Awarded Photograph(s)'}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              lg={4}
              md={5}
              xs={0}
              sx={{ display: { xs: 'none', md: 'block' }, mb: { md: 5, lg: 0 } }}
            >
              <Link to={`/user-details/${userId}/projects`}>
                <Typography
                  variant='h4'
                  className='semi-strong link link--large'
                  sx={{
                    mb: { xs: 1, sm: 2, md: 2 },
                    display: { xs: 'none', lg: 'block' }
                  }}
                >
                  {student.projects.length} Projects
                </Typography>
              </Link>
              <Typography variant='h4' className='table_row'>
                {student.totalAwardedPhotographs
                  ? student.totalAwardedPhotographs + ' Awarded Photograph(s)'
                  : 'No Awarded Photograph(s)'}
              </Typography>
            </Grid>
            <Grid
              item
              lg={2}
              md={2}
              sm={0}
              xs={0}
              sx={{ display: { xs: 'none', sm: 'none', lg: 'block' }, mb: { lg: 5 } }}
            >
              <Typography variant='h4' className='table_row'>
                {student.lastSeenAt ? (
                  <>Last signed in {moment(student.lastSeenAt).format('LL')}</>
                ) : (
                  <>User has not signed up</>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    )
  }
}

export default UserListRowStudents
