import React, { Component } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import Modal from 'react-bootstrap/Modal'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Typography } from '@mui/material'
import { Spinner } from 'react-bootstrap'

const DELETE_RAW_FILE_MUTATION = gql`
  mutation DeleteRawFileMutation($id: ID!) {
    deleteRawFile(id: $id) {
      id
    }
  }
`

class DeleteRawFileModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleting: false
    }
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleDelete(e, onMutate) {
    const { photoId, onSuccessCallback } = this.props
    this.setState({
      isDeleting: true
    })
    e.preventDefault()
    onMutate({
      variables: {
        id: photoId
      },
      refetchQueries: [`PhotoDetails`]
    })
    this.setState({
      isDeleting: false
    })
    const toastMessage = 'RAW file deleted.'
    onSuccessCallback(toastMessage)
  }

  render() {
    const { handleDelete } = this
    return (
      <Mutation mutation={DELETE_RAW_FILE_MUTATION} onCompleted={() => {
        this.props.refetchPhoto && this.props.refetchPhoto()
      }}>
        {(onMutate, { data }) => (
          <Modal
            {...this.props}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            show={this.props.show}
            onClick={this.props.onClick}
            value={this.props.value}
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>
                <Typography variant="h2">Delete RAW File</Typography>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='edit-award__modal-description'>
                <Typography variant="paragraph" component="p">Are you sure you want to delete this RAW file?</Typography>
                <Typography variant="paragraph" component="p">It will be removed permanently from the application.</Typography>
              </div>
              <div className='delete-user__button'>
                <Button

                  type='copy'
                  disabled={this.state.isDeleting }
                  title={this.state.isDeleting ? <span className='icon-text-span'>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> Deleting... </span>
                  : 'Delete RAW File' }
                  onClick={e => handleDelete(e, onMutate)}
                />
                <Typography variant="h4"className='semi-strong link' ml={2} onClick={this.props.onHide}>
                  Cancel
                </Typography>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </Mutation>
    )
  }
}

export default DeleteRawFileModal
