import React, { Component, Fragment } from 'react'
import Dropzone from 'react-dropzone'
import Button from '../elements/Button.js'
import { DirectUpload } from 'activestorage'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { observer, inject } from 'mobx-react'
import { Box, Typography, Grid } from '@mui/material'
import { validationText } from '../../content/validationText.js'
import { truncateDetails } from '../../utils/commonFunctions'

const UPLOAD_PDF_USEAGREEMENT_MUTATION = gql`
  mutation UploadPdfUseAgreement($fileSignedId: String!, $projectId: ID!) {
    uploadPdfUseAgreement(fileSignedId: $fileSignedId, projectId: $projectId) {
      id
    }
  }
`

class UseAgreementUploader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photoId: this.props.photoId,
      files:{}
    }
  }

  onDrop = (files, onMutate) => {
    // event.preventDefault()
    // const files = event.dataTransfer.files;

    // Array.from(files).forEach(file => this.uploadFile(file, onMutate, this.props.projectId))
    this.setState({files:files})
    this.props.uploadFiles(files)
  }

  render() {
    // const photos = { this.props.photos }
    return (
      <Mutation
        mutation={UPLOAD_PDF_USEAGREEMENT_MUTATION}
        onCompleted={() => this.mutatationComplete()}
      >
        {(onMutate, { data }) => (
          <Fragment>
            <Dropzone
              photos={this.props.photos}
              onDrop={files => this.onDrop(files, onMutate)}
              accept={{"image/jpg": [".png", ".jpeg", ".jpg"], "application/pdf": [".pdf"]}}
            >
              {({ getRootProps, getInputProps , fileRejections }) => {
                let fileRejectionItems = fileRejections.map(({ file, errors }) => (
                  errors.map(e => (
                    (e.code === "file-invalid-type") ? 
                    validationText.fileUpload.fileInvalidType.error : e.message
                  ))
                ));
                return (
                  <Fragment>
                    <Grid container className='model-release__upload-button'>
                      <Grid item xs={12} sm={5}>
                        <Box {...getRootProps()} className='uploader__button mr-ua__uploader__button'>
                          <input {...getInputProps()} />
                          <Button
                            variant='contained'
                            title='Upload Signed Use Agreement'
                            disabled={false}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={7} sx={{alignContent:{xs: 'center'}}}>
                        {this.state.files.length > 0 ? <Box className="mr-upload-file-name" sx={{ml:{xs: 0}}}>
                            <Typography variant="h4" className='semi-strong' sx={{wordWrap:{xs:'break-word'}}}>
                              {truncateDetails(this.state.files[0].name.trim(), 90)}
                            </Typography>
                          </Box>
                          : fileRejectionItems ? 
                            <Box className="mr-upload-file-name" sx={{ml:{xs: 0}}}>
                              <Typography variant="h4" className='component-error-text'>
                                {fileRejectionItems}
                              </Typography>
                            </Box>
                          : null}
                      </Grid>
                    </Grid>
                  </Fragment>
                )
              }}
            </Dropzone>
          </Fragment>
        )}
      </Mutation>
    )
  }
}

export default inject('userStore', 'resourceStore')(observer(UseAgreementUploader))
