import React, { useEffect, useState } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import FormField from '../elements/FormField.js'
import { gradeLevels } from '../../utils/commonFunctions'
import _ from 'lodash'
import { Typography, Grid, Box } from '@mui/material'
import FormSelect from '../elements/FormSelect'
import BaseModal from '../template/BaseModal';

const EditGradeLevelModal = props => {
  const [gradeLevel, setGradeLevel] = useState(props.gradeLevel)
  const [gradeIds, setGradeIds] = useState(props.gradeIds)
  const [otherGradeDetails, setOtherGradeDetails] = useState(props.otherGradeDetails || '')

  const handleGradeChange = choice => {
    const selected =
      choice.target.value &&
      choice.target.value.map(e => {
        const selectedKey = Object.keys(gradeLevels).find(x => gradeLevels[x].value === e)
        return gradeLevels[selectedKey]
      })

    setGradeIds(choice.target.value)
    setGradeLevel(selected)
    setOtherGradeDetails(choice.target.value.includes('Other') ? otherGradeDetails : '')
  }

  const handleFormChange = e => {
    setOtherGradeDetails(e.target.value)
  }

  useEffect(() => {
    setGradeLevel(props.gradeLevel)
    setGradeIds(props.gradeIds)
    setOtherGradeDetails(props.otherGradeDetails || '')
  }, [props.gradeLevel, props.gradeIds, props.otherGradeDetails])
  
  const handleClose = () => {
    this.setState({show: false});
    this.props.onHide(false);

  };

  return (
    <BaseModal
      {...props}
      show={props.show}
      onClick={props.onClick}
      value={props.value}
      open={props.show}
      onClose={handleClose}
      onHide={props.onHide}
    >
      <BaseModal.BaseModalHeader>
        Edit Grade Level(s)
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Grid container>
          <Grid item className='delete-user__description'>
            <Typography
              variant='paragraph'
              sx={{
                mb: '1rem'
              }}
              component='p'
            >
              If this class contains mixed grades, select all grades that apply.
            </Typography>
            <Typography
              variant='paragraph'
              sx={{
                mb: '1rem'
              }}
              component='p'
            >
              After you confirm the grade(s) below, remember to click “Save Changes” in the class
              page.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10} md={7} className='class-details__grade-level-inputs'>
            <>
              <FormSelect
                options={gradeLevels}
                label='Class Grade(s)'
                placeholder='Class Grade(s)'
                name='gradeLevel'
                multiple={true}
                onChange={handleGradeChange}
                value={gradeIds}
                selectedOptions={gradeLevel}
                variant='filled'
                inputProps={{ 'aria-label': 'Without label' }}
                fullWidth={true}
              />
              <Box sx={{mt:{xs: gradeIds && gradeIds.includes('Other') ? '32px' : '0px'}}}>
                {gradeIds && gradeIds.includes('Other') ? (
                  <FormField
                    formFieldVariant='filled'
                    formFieldId='custom-css-outlined-class-grade'
                    formFieldLabel='Other Grade Detail'
                    formFieldType='text'
                    formFieldName='otherGradeDetails'
                    formFieldDefaultValue={otherGradeDetails}
                    onChange={handleFormChange}
                  />
                ) : null}
              </Box>
            </>
          </Grid>
          <Grid item ></Grid>
        </Grid>
        <BaseModal.BaseModalFooter>
          <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
            <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant='contained'
                  type='copy'
                  title='Confirm Grade(s)'
                  disabled={
                    (gradeIds &&
                      gradeIds.includes('Other') &&
                      _.includes(['', null, undefined], otherGradeDetails.trim())) ||
                    false
                  }
                  onClick={() => {
                    props.updateGradesOnParent({ gradeIds, gradeLevel, otherGradeDetails })
                    props.onHide()
                  }}
                  sx={{width:{ xs: '100%', sm: '100%' }}}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={() => props.onHide()}>
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </BaseModal.BaseModalFooter>

      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

export default EditGradeLevelModal
