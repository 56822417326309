import React, { Component, Fragment } from 'react'
import './dashboard.scss'
import Hero from '../ui/organisms/Hero.js'
import { Link } from 'react-router-dom'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import ProjectTimeline from '../ui/molecules/ProjectTimeline'
import moment from 'moment'
import Loading from '../ui/elements/Loading.js'
import { observer, inject } from 'mobx-react'
import Layout from '../ui/template/Layout'
import AdminDashboard from '../ui/organisms/AdminDashboard'
import { Typography } from '@mui/material'
import { withRouter } from '../utils/customHoc'
import DateUtils from '../utils/DateUtils'

const STUDENT_PROFILE_QUERY = gql`
  query Dashboard {
    currentUser {
      id
      firstName
      profile {
        __typename
        ... on Student {
          id
          name
          cohorts {
            approved
            name
            gradeLevel
            startDate
            endDate
            nextFacilitatorVisit
            introVisit {
              id
              date
              teacherApproved
              facilitatorApproved
            }
            midVisit {
              id
              date
              teacherApproved
              facilitatorApproved
            }
            secondMidVisit {
              id
              date
              teacherApproved
              facilitatorApproved
            }
            finalVisit {
              id
              date
              teacherApproved
              facilitatorApproved
            }
            id
            name
          }
        }
      }
    }
  }
`
class Dashboard extends Component {
  constructor(props) {
    super(props)
    const { breadcrumbStore, location, basePath } = props
    breadcrumbStore.resetBreadcrumb()
    breadcrumbStore.addBreadcrumb({
      pageName: 'Dashboard',
      link: location.pathname,
      basePath: basePath,
      isParent: true,
      isActive: true
    })
  }
  renderAdminDashboard = () => {
    return <AdminDashboard />
  }

  renderStudentDashboard = () => {
    const { firstName } = this.props.userStore
    return (
      <div className='dashboard__container'>
        <Query query={STUDENT_PROFILE_QUERY}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <div>error loading dashboard</div>
            const projectsActive =
              data.currentUser.profile.cohorts.filter(
                cohort => !DateUtils.isDatePassed(cohort.endDate)
              ).length > 0
            return (
              <div>
                <div className='dashboard__intro'>
                  <Typography variant='h1' className='bold'>
                    Welcome, {firstName}!
                  </Typography>
                  <br />
                  <Typography
                    variant='paragraph'
                    sx={{
                      mb: '1rem'
                    }}
                    component='p'
                  >
                    Thank you for taking part in the <i>wrkxfmly</i> program.
                  </Typography>
                  {data.currentUser.profile.cohorts ? null : (
                    <Typography
                      variant='paragraph'
                      sx={{
                        mb: '1rem'
                      }}
                      component='p'
                    >
                      You do not current have any active projects. If you are waiting to begin a
                      class, why not <Link to='/national-photos'>explore photographs</Link> by other
                      students or take a look at these{' '}
                      <Link to='/resources'>recommended resources</Link> to find out more about the
                      program.
                    </Typography>
                  )}
                </div>
                {projectsActive ? (
                  <div className='dashboard__project-timeline'>
                    <h2 className='h2--semi-strong'>Your Project Timeline</h2>
                    {data.currentUser.profile.cohorts.map(cohort =>
                      !DateUtils.isDatePassed(cohort.endDate) ? (
                        <Fragment>
                          <Typography
                            variant='paragraph'
                            sx={{
                              mb: '1rem'
                            }}
                            component='p'
                          >
                            Photography: <i>wrkxfmly</i>・{moment(cohort.startDate).format('LL')}
                            {'  '}–{'  '}
                            {moment(cohort.endDate).format('LL')}
                          </Typography>
                          <ProjectTimeline cohort={cohort} />
                        </Fragment>
                      ) : null
                    )}
                  </div>
                ) : (
                  <Fragment>
                    <Typography
                      variant='paragraph'
                      sx={{
                        mb: '1rem'
                      }}
                      component='p'
                      style={{ maxWidth: '600px' }}
                    >
                      You do not current have any active projects. If you are waiting to begin a
                      class, why not <Link to='/national-photos'>explore photographs</Link> by other
                      students or take a look at these{' '}
                      <Link to='/resources'>recommended resources</Link> to find out more about the
                      program.
                    </Typography>
                    <br />
                  </Fragment>
                )}
                {/* <FeaturedResourceContainer resources={data.currentUser.profile.featuredResources} /> */}
              </div>
            )
          }}
        </Query>
      </div>
    )
  }

  renderUserDashboard = role => {
    switch (role) {
      case 'appadmin':
        return this.renderAdminDashboard()

      case 'student':
        return this.renderStudentDashboard()

      default:
        break
    }
  }

  render() {
    const { role } = this.props.userStore
    return (
      <Layout>
        <Hero heroTitle='Dashboard' />
        <div className='container__body'>{this.renderUserDashboard(role)}</div>
      </Layout>
    )
  }
}

export default inject('userStore', 'breadcrumbStore')(observer(withRouter(Dashboard)))
