import React, {Fragment} from 'react'
import Layout from '../ui/template/Layout'
import './homepage.scss';
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem
} from '@mui/material'

const PrivacyPolicyEnglish = () => {

  const getParaHeading = (content) => {
    return (
      <Typography variant='h1' sx={{pb:2}}>{content}</Typography>
    )
  }

  return (

  <Layout>
    <Fragment>
        <Box className='container__public-body' sx={{ py: 10, px: { xs: 4, md: 6, lg: 20 }, pr: {lg: 28} }}>
          <Box display='flex'>
            <Typography variant="h1" className='extra-size'>Privacy Policy</Typography>
          </Box>
            <Grid container sx={{ mt: { xs: 10 }, rowGap: { xs: 5 } }}>

              <Grid item xs={12} md={10}>
                <Typography  component='p' variant='blockQuote'>
                    Last Updated: August 18, 2019
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>

                <Typography component='p' variant='blockQuote'>
                  Working Assumptions, referred to collectively with its affiliated entities and
                  portfolio of brands, as “Working Assumptions,” “we,” “us,” or “our”, collects
                  information about you when you take advantage of our online services or visit or
                  interact with any of our websites, including{' '}
                  <a href='http://www.workingassumptions.org' target='_blank' rel='noopener noreferrer' style={{overflowWrap: 'break-word'}}>
                    <Typography variant='link' className='large'>
                      www.workingassumptions.org
                    </Typography>
                  </a>,{' '}
                  <a href='http://www.showingptw.org' target='_blank' rel='noopener noreferrer' style={{overflowWrap: 'break-word'}}>
                    <Typography variant='link' className='large'>
                      www.showingptw.org
                    </Typography>
                  </a>, and{' '}
                  <a href='http://www.waed.org' target='_blank' rel='noopener noreferrer' style={{overflowWrap: 'break-word'}}>
                    <Typography variant='link' className='large'>
                      www.waed.org
                    </Typography>
                  </a> (the “Site”). This privacy policy

                  (the “Policy”) describes: (1) the types of information we may collect about you, and
                  your use of the Site; (2) how we may use and share that information; and (3) the
                  choices you may make with respect to your personal information.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>

                <Typography component='p' variant='blockQuote'>
                  BY USING THIS SITE, YOU AGREE TO BE BOUND BY THIS PRIVACY POLICY. IF YOU DO NOT
                  AGREE WITH ANY OF THE TERMS OF THE PRIVACY POLICY, PLEASE DO NOT USE THIS SITE OR
                  PROVIDE US WITH YOUR INFORMATION.
                </Typography>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: { xs: 8 }, rowGap: { xs: 8 } }}>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Personal Information We Collect</>)}
                <Typography component='p' variant='blockQuote' className='italic' >
                  Information You Give Us.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:4}}>
                  You provide us with your personal information when you use this Site or otherwise
                  communicate with us, including when you:
                </Typography>
                <Typography component='div' variant='blockQuote'>
                  <List sx={{pl:4, listStyleType:"decimal", pt:5,  pb:5}}>
                    <ListItem sx={{ pl:'4px', display: 'list-item',pb:'0px !important', pt:'0px !important'}}>
                      Create or update an account on the Site;
                    </ListItem>
                    <ListItem sx={{ pl:'4px', display: 'list-item',pb:'0px !important', pt:'0px !important'}}>
                      Subscribe to e-mail newsletters or updates; or
                    </ListItem>
                    <ListItem sx={{ pl:'4px', display: 'list-item',pb:'0px !important', pt:'0px !important'}}>
                      Complete, submit, or otherwise participate in surveys or questionnaires.
                    </ListItem>
                  </List>
                </Typography>
                <Typography component='p' variant='blockQuote'>
                  We collect personal information you choose to provide, which may include your name,
                  email address, gender, date of birth, shipping addresses, and/or telephone number.
                  We may retain your responses to surveys and questionnaires that you may complete
                  from time to time. We may collect other information from you that is not
                  specifically listed here. We may use such information in accordance with this
                  Policy. Please do not submit information that you do not want us to have.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:5}} className='italic' >
                  Information Automatically Collected.
                </Typography>

                <Typography component='p' variant='blockQuote' sx={{mt:4}}>
                  Our servers and third party service providers may automatically record certain
                  information about how you use the Site, such as your Internet Protocol (IP) address,
                  device and browser type, operating system, DNS, the pages or features of the Site
                  that you browsed and the time spent on those pages or features, the frequency with
                  which you use the Site, the links that you click on or use, and other statistics.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>How We May Use Your Personal Information</>)}
                <Typography component='p' variant='blockQuote' className='italic' >
                  To Improve the Site and Communicate with You.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:4}}>
                  We generally use your personal information to serve you, to improve our services,
                  and to improve your experience. For example, we use personal information to
                  communicate with you about programs and services and enhance your experience on the
                  Site. Specifically, your personal information may be used to respond to questions,
                  send you information about programs or services that may be of interest to you,
                  analyze and monitor visitor activity on the Site, comply with our legal obligations,
                  and develop a knowledge base regarding users of the Site, including through data
                  analytics.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:5}} className='italic' >
                  To Send You Marketing Communications.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:4}}>
                  We may use the personal information that you provide us to send you communications
                  inviting you to participate in various programs, like surveys or user groups. We may
                  also send you promotional communications and other offerings to thank you for your
                  feedback or participation in programs, or to share new program or service launches
                  or events. You may opt out of receiving them as described in the Your Choices
                  section below.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:5}} className='italic'>
                  To Improve our Site and Services.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:4}}>
                  We may use statistical information about visitors to our Site to gain a better
                  understanding of the users of our Site, to improve our Site, and to improve our
                  services.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:5}} className='italic'>
                  For Compliance, Fraud Prevention, and Safety.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:4}}>
                  We may use your personal information as we believe appropriate to (a) investigate or
                  prevent violation of the law or your agreements with us; (b) protect our, your or
                  others’ rights, privacy, safety or property (including by prosecuting and defending
                  legal claims); (c) protect, investigate, and deter against fraudulent, harmful,
                  unauthorized, unethical or illegal activity; (d) comply with applicable laws, lawful
                  requests and legal process, such as to respond to subpoenas or requests from
                  government authorities; and (e) where permitted by law in connection with a legal
                  investigation. For example, we may share information with law enforcement to reduce
                  the risk of fraud or if someone uses or attempts to use our Site for illegal
                  reasons.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>How We May Share Your Personal Information</>)}
                <Typography component='p' variant='blockQuote'>
                  We may share your personal information with others for a number of different
                  reasons. We may share your personal information to fulfill your requests, to provide
                  you with information regarding products and services of ours or those of others, to
                  comply with law, and to enforce our rights. We also may share personal information
                  among our affiliated companies, businesses, licensees, distributors, any of our
                  brands, and with our successors and assigns. Examples of how we may share your
                  personal information include:
                </Typography>
                <Typography component='div' variant='blockQuote'>
                  <List sx={{pl:4, listStyleType:'decimal',pb:'0px !important', pt:'40px !important'}}>
                      <ListItem sx={{ display: 'list-item',pb:'0px !important', pt:'0px !important', pl:'0px !important' }}>
                        With Service Providers, Business Partners, and Vendors:
                        <List sx={{pl: '2px', listStyleType:'disc',pb:'0px !important', pt:'0px !important'}}>
                          <ListItem sx={{ display: 'list-item',pb:'0px !important', pt:'32px !important', pl:'0px !important'}}>
                              We may use third parties to help us design and operate the Site, provide
                              services to support the Site, process your information, and provide
                              marketing activities on our behalf. For example, we may retain other
                              companies and individuals to perform services on our behalf, and we may
                              collaborate with other companies and individuals with respect to
                              particular products or services (collectively, “Providers”). Examples of
                              Providers include data analysis firms, email and SMS vendors, and web
                              hosting and development companies. Providers also include our co-promotion
                              partners for products that we jointly develop and/or market with other
                              companies. Some Providers also may collect information on our behalf from
                              our Site. These third parties may be provided with access to personal
                              information needed to perform their functions.
                            </ListItem>
                        </List>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item',pb:'0px !important', pt:'40px !important', pl:'0px !important' }}>
                        To Comply with Law or to Protect Our Rights:
                          <List sx={{pl:'2px', listStyleType:'disc',pb:'0px !important', pt:'0px !important'}}>
                            <ListItem sx={{ display: 'list-item',pb:'0px !important', pt:'32px !important', pl:'0px !important' }}>
                              We may disclose your personal information as required by law, when we
                              believe disclosure is necessary to comply with a regulatory requirement,
                              judicial proceeding, court order, or legal process served on us, or to
                              protect the safety, rights, or property of our customers, the public, or
                              Working Assumptions.

                            </ListItem>
                          </List>
                      </ListItem>
                      <ListItem sx={{ display: 'list-item',pb:'0px !important', pt:'40px !important', pl:'0px !important' }}>
                        To Members of the Corporate Family:
                          <List sx={{pl:'2px', listStyleType:'disc', pb:'0px !important', pt:'0px !important'}}>
                            <ListItem sx={{ display: 'list-item',pb:'0px !important', pt:'32px !important', pl:'0px !important'}}>
                            Working Assumptions may disclose your personal information to our brands,
                            subsidiaries and affiliates, and their agents and employees.
                          </ListItem>
                        </List>
                      </ListItem>
                  </List>
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Cookies and Other Tools That May Collect Information About You</>)}
                <Typography component='p' variant='blockQuote'>
                    In addition to personal information you may provide directly to us, Working
                  Assumptions and its business partners may collect and store information about you
                  and your use of the Site through cookies and similar technologies, which are small
                  files or pieces of text that download to a device when a visitor accesses a website
                  or app. This website is hosted by Squarespace. For information about viewing the
                  cookies dropped on your device, visit{' '}
                  <a href='https://support.squarespace.com/hc/en-us/articles/360001264507' target='_blank' rel='noopener noreferrer' style={{overflowWrap: 'break-word'}}>
                    <Typography variant='link' className='large'>
                      About the cookies Squarespace uses
                    </Typography>
                  </a>
                  .&nbsp;
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Opt-out and Unsubscribe</>)}
                <Typography component='p' variant='blockQuote'>
                  We respect your privacy and give you an opportunity to opt out of receiving
                  announcements of certain information or requests to participate in research about
                  Working Assumptions’ and its affiliates’ current services or potential new services.
                  Users may opt out of receiving any or all such communications from Working
                  Assumptions by:
                    <Typography component='div' variant='blockQuote'>
                      <List sx={{pl:4, mt:5, listStyleType:'decimal',pb:'40px !important', pt:'0px !important'}}>
                        <ListItem sx={{ display: 'list-item',pb:'0px !important', pt:'0px !important', pl:'0px !important' }}>
                          following the unsubscribe instructions located at the end of any marketing
                          communication from us;
                        </ListItem>
                        <ListItem sx={{ display: 'list-item',pb:'0px !important', pt:'0px !important', pl:'0px !important' }}>
                          contacting us using the contact information provided at the end of this Policy.
                        </ListItem>
                      </List>
                    </Typography>
                  <Typography component='p' variant='blockQuote'>
                    Please be sure to include in any email or postal mail your full name, email address,
                    postal address, and any message. Please note that such requests may take up to
                    thirty (30) days to become effective. Please contact us if you have any questions or concerns about your personal
                    information or require assistance in managing your choices.
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Consequences of Not Providing Personal Information.</>)}
                <Typography component='p' variant='blockQuote'>
                    You are not required to provide all personal information identified in this Policy
                    to use our Site or to interact with us offline, but certain functionality will not
                    be available if you do not provide personal information. For example, if you do not
                    provide personal information, we may not be able to respond to your request or
                    provide you with marketing that we believe you would find valuable.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Security</>)}
                <Typography component='p' variant='blockQuote'>
                  Any user or third party accessing our Site or services is responsible for its own
                  use of the Site and the services. We are not responsible for any third party’s
                  actions or its security controls with respect to information that third parties may
                  collect or process via the website, services, or otherwise.
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:5}}>
                  Be Aware of “Phishing.” “Phishing” is a scam designed to steal your personal
                  information. If you receive an email that looks like it is us asking you for your
                  personal information, do not reply to it. We will not request your password, user
                  name, credit card information, or other personal information through email.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Other Websites</>)}
                <Typography component='p' variant='blockQuote'>
                  The Site may contain links to websites of third parties that:
                </Typography>
                <Typography component='div' variant='blockQuote'>
                  <List sx={{pl:4, mt:5, listStyleType:"decimal", pt:"0px !important", pb:"0px !important"}}>
                  <ListItem sx={{ display: 'list-item',pb:"0px", pl:"0px !important", pt:"0px !important"}}>
                      are not affiliated with us
                  </ListItem>
                  <ListItem sx={{ display: 'list-item',pb:"0px", pl:"0px !important", pt:"0px !important" }}>
                      are outside our control
                  </ListItem>
                  <ListItem sx={{ display: 'list-item',pb:"0px", pl:"0px !important", pt:"0px !important" }}>
                      are not covered by this Policy, including without limitation sites operated by
                      our distributors and/or licensees in foreign countries which may not guarantee
                      the same level of protection of personal information (“Third-Party Websites”).
                  </ListItem>
                  </List>
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:5}}>
                  Links provided to Third-Party Websites on the Site are provided to you only as a
                  convenience, and the inclusion of any link does not imply reliability or endorsement
                  by us of the content of any such Third-Party Websites. We are not responsible for
                  the privacy practices of Third-Party Websites, which may collect and use information
                  from you in a manner different than how we do so; accordingly, once you leave the
                  Working Assumptions platform, the use of such Third-Party Websites is entirely at
                  your own risk. For relevant information, you should review the privacy statements or
                  policies of any Third-Party Websites before using them. Other parties may collect
                  information about your online activities over time and across different websites
                  when you use our Site or services.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Children</>)}
                <Typography component='p' variant='blockQuote'>
                  We do not intend to or knowingly collect identifiable personal information from
                  children who are under 13 years of age. If you are a parent who believes that we
                  have collected information about a child under age 13, please contact us using the
                  contact information provided below with your child's name and address, and we will
                  delete the information we have about your child from our records and provide
                  confirmation in accordance with the Children's Online Privacy Protection Act.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Transfer of Data to Other Countries</>)}
                <Typography component='p' variant='blockQuote'>
                  Any information you provide to Working Assumptions through the use of the Site may
                  be stored, processed, transferred among, and accessed from the United States and
                  other countries which may not guarantee the same level of protection of personal
                  information as the country in which you reside. However, Working Assumptions will
                  handle your personal information in accordance with this Policy regardless of where
                  your personal information is kept.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Notice to California Residents</>)}
                <Typography component='p' variant='blockQuote'>
                  Under California law, California residents may request a list of all third parties
                  to whom we have disclosed certain personal information (as defined by California
                  law) during the preceding year for those third parties’ direct marketing purposes.
                  If you are a California resident and would like to receive such a list, please
                  contact us at the address listed in the “Accessing Personal Information” section
                  below. For any such request, include the statement “Your California Privacy Rights”
                  in the body of your request, as well as your name, street address, city, state, and
                  zip code. Please provide enough information for us to determine if this applies to
                  you. You must also attest to the fact that you are a California resident and provide
                  a current California address for our response. Please note that we will not accept
                  requests via the telephone, email, or by facsimile, and we are not responsible for
                  notices that are not labeled or sent properly or that do not have complete
                  information.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Notice to European Users</>)}
                <Typography component='p' variant='blockQuote'>
                  The General Data Protection Regulation (“GDPR”) went into effect on May 25, 2018,
                  and it is intended to protect personal information (as defined by GDPR) of European
                  Union citizens. We do not actively market this Site to the European Union or its
                  citizens in any meaningful way. However, if the personal information that you
                  provide to us while using this Site is governed by GDPR, we will comply with all
                  relevant provisions of the regulation. If you are a resident of the European
                  Economic Area (EEA), or are accessing this Site from within the EEA, you have
                  certain rights under GDPR.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Updates to Our Privacy Policy</>)}
                <Typography component='p' variant='blockQuote'>
                  From time to time, we may post updates to this Policy. Any changes will be effective
                  upon the posting of the revised Policy. We may also make, without notice, minor
                  changes to the Policy that will not significantly affect our use of personal
                  information. We encourage you to periodically review this page for the latest
                  information on our privacy practices. This Policy was updated as of the effective
                  date listed above.
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                {getParaHeading(<>Contact Us</>)}
                <Typography component='p' variant='blockQuote'>
                  If you have questions or concerns about our privacy policy or practices, please
                  contact us at:
                </Typography>
                <Typography component='p' variant='blockQuote' sx={{mt:5}}>
                  Working Assumptions Foundation
                </Typography>
                <Typography component='p' variant='blockQuote'>
                  Attn: Managing Director
                </Typography>
                <Typography component='p' variant='blockQuote'>
                  1700 Shattuck Avenue, #25
                </Typography>
                <Typography component='p' variant='blockQuote'>
                  Berkeley, CA 94709
                </Typography>
              </Grid>
            </Grid>
        </Box>
    </Fragment>
  </Layout>
)}

export default PrivacyPolicyEnglish
