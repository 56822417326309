import React, {Fragment} from 'react'
import Layout from '../ui/template/Layout'
import { withRouter } from '../utils/customHoc.js'
import { gql, useQuery } from '@apollo/client'
import { useState } from 'react'
import Loading from '../ui/elements/Loading.js'
import './homepage.scss';
import {
  Box,
  Typography,
  Grid
} from '@mui/material'


const QUERY = gql`
  query GetModelReleaseDetail($uuid: String!) {
    getModelReleaseDetail(uuid: $uuid)
    {
      id
      isDeceased
      isMinor
    }
  }
`

const ModelReleaseDetail = (params) => {

  const [isStudentMinor, setIsStudentMinor] = useState()
  const [isDeceased, setIsDeceased] = useState()
  const { loading, error, data } = useQuery(QUERY, {
    variables: {uuid: params.params.releaseUuid },
    onCompleted(data) {
      setIsStudentMinor(data.getModelReleaseDetail.isMinor)
      setIsDeceased(data.getModelReleaseDetail.isDeceased)
    }
  });

  const AboutWa = <Box>
    <Box>
        <Typography variant='h4' className='semi-strong'>
        About Working Assumptions
        </Typography>
    </Box>
    <Box sx={{mt: 2}}>
        <Typography variant='paragraph'>
        Working Assumptions is a non-profit arts and education organization. We explore and celebrate the importance of work, family and care through innovative projects and programs. Since 2013, our <i>wrkxfmly</i> photography assignment has inspired countless conversations in classrooms and within families about what care looks like and how work and family overlap and influence daily life.
        </Typography>
    </Box>
    <Box sx={{mt: 1}}>
        <Typography variant='paragraph'>
        A student taking part in the program has been recognized for their <i>wrkxfmly</i> photography and we would like to include their excellent work in a digital research archive we are building to preserve and honor the quality of their unique perspective. The photographs and captions held in this archive advance our mission of seeing cultures of care through the eyes of young adults.
        </Typography>
    </Box>
    <Box sx={{mt: 1}}>
        <Typography variant='paragraph'>
        Digital technology is changing photography and the way we see and remember our lives. Agreements are even more important to foster understanding between the photographed and photographer. We are rigorous in making sure that recognizable people in photographs know and understand their rights as models and that the photographer’s work and intellectual property is valued.
        </Typography>
    </Box>
  </Box>

 const DeceasedPermissionText = <Box sx={{mt : 3}}>
    <Box>
        <Typography variant='h4' className='semi-strong'>
        Model Release Permission
        </Typography>
    </Box>
    <Box sx={{mt : 2}}>
        <Typography variant='paragraph'>
        The permission requested here allows the work of the student photographer to be included in the Working Assumptions archive. By signing, you confirm that you have seen the photograph(s) and/or video(s), that you are the legal next of kin of the person featured in the photograph(s) and/or video(s) and you have the legal right to make decisions on their behalf, and you give permission to Working Assumptions to conserve and utilize the work under the following agreement:
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Grant of Permission:</b> Model’s Next of Kin grants Working Assumptions a non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce, distribute, display and publish the photographs taken by Photographer for non-profit mission-aligned purposes, as well as store the work in Working Assumption’s archive, make it available for research collaborations, and include it in print publications, in digital formats, online, and in other media.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Non-Profit Use:</b> Working Assumptions confirms that the Photographer’s work(s) will not be used for any commercial (i.e. for profit or for advertising or promotion) purposes.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Modifications and Derivative Works:</b> Working Assumptions may crop, edit, or make minor adjustments to the Photographs as necessary for their intended use, without altering the integrity or intended meaning of the image.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Indemnification:</b> Model’s Next of Kin agrees to indemnify and hold Working Assumptions harmless from any claims, damages, or liabilities arising out of the use of the Photographs as permitted by this Agreement, including but not limited to claims of infringement, defamation, or violation of privacy or publicity rights.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Governing Law:</b> This Agreement shall be governed by and construed in accordance with the laws of California. Any legal actions or proceedings arising out of or relating to this Agreement shall be brought in the courts of California.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Entire Agreement:</b> This Agreement constitutes the entire understanding between the parties and supersedes all prior negotiations, understandings, or agreements, whether oral or written, relation to the subject matter herein.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Severability:</b> If any provision of this Agreement is held to be invalid, illegal, or unenforceable, the validity, legality, or enforceability of the remaining provisions shall not in any way be affected or impaired.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        By presenting this document for Model’s Next of Kin’s signing, Working Assumptions thereby acknowledges and agrees to the terms and conditions of this Model Release.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        By signing, Model’s Next of Kin acknowledges and agrees to the terms and conditions of this Model Release.
        </Typography>
    </Box>
  </Box>

  const AdultLivingPermissionText = <Box sx={{mt : 3}}>
    <Box>
        <Typography variant='h4' className='semi-strong'>
        Model Release Permission
        </Typography>
    </Box>
    <Box sx={{mt : 2}}>
        <Typography variant='paragraph'>
        The permission requested here allows the work of the student Photographer to be included in the Working Assumptions archive. By signing, you confirm that you have seen the work of the Photographer, and you give permission to Working Assumptions to conserve and utilize the work under the following agreement:
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Grant of Permission:</b> Model grants Working Assumptions a non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce, distribute, display and publish the photographs taken by Photographer for non-profit mission-aligned purposes, as well as store the work in Working Assumption’s archive, make it available for research collaborations, and include it in print publications, in digital formats, online, and in other media.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Non-Profit Use:</b> Working Assumptions confirms that the Photographer’s work(s) will not be used for any commercial (i.e. for profit or for advertising or promotion) purposes.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Modifications and Derivative Works:</b> Working Assumptions may crop, edit, or make minor adjustments to the Photographs as necessary for their intended use, without altering the integrity or intended meaning of the image.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Indemnification:</b> Model agrees to indemnify and hold Working Assumptions harmless from any claims, damages, or liabilities arising out of the use of the Photographs as permitted by this Agreement, including but not limited to claims of infringement, defamation, or violation of privacy or publicity rights.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Governing Law:</b> This Agreement shall be governed by and construed in accordance with the laws of California. Any legal actions or proceedings arising out of or relating to this Agreement shall be brought in the courts of California.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Entire Agreement:</b> This Agreement constitutes the entire understanding between the parties and supersedes all prior negotiations, understandings, or agreements, whether oral or written, relation to the subject matter herein.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Severability:</b> If any provision of this Agreement is held to be invalid, illegal, or unenforceable, the validity, legality, or enforceability of the remaining provisions shall not in any way be affected or impaired.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        By presenting this document for Model’s signing, Working Assumptions thereby acknowledges and agrees to the terms and conditions of this Model Release.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        By signing, Model acknowledges and agrees to the terms and conditions of this Model Release.
        </Typography>
    </Box>
  </Box>

  const MinorLivingPermissionText = <Box sx={{mt : 3}}>
    <Box>
        <Typography variant='h4' className='semi-strong'>
        Model Release Permission
        </Typography>
    </Box>
    <Box sx={{mt : 2}}>
        <Typography variant='paragraph'>
        The permission requested here allows the work of the student photographer to be included in the Working Assumptions archive. By signing, you confirm that you have seen the photograph(s) and/or video(s), that you are the legal guardian of the child featured in the photograph(s) and/or video(s), and you give permission to Working Assumptions to conserve and utilize the work under the following agreement:
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Grant of Permission:</b> Model’s Parent/Legal Guardian grants Working Assumptions a non-exclusive, royalty-free, perpetual, and irrevocable right to use, reproduce, distribute, display and publish the photographs taken by Photographer for non-profit mission-aligned purposes, as well as store the work in Working Assumption’s archive, make it available for research collaborations, and include it in print publications, in digital formats, online, and in other media.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Non-Profit Use:</b> Working Assumptions confirms that the Photographer’s work(s) will not be used for any commercial (i.e. for profit or for advertising or promotion) purposes.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Modifications and Derivative Works:</b> Working Assumptions may crop, edit, or make minor adjustments to the Photographs as necessary for their intended use, without altering the integrity or intended meaning of the image.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Indemnification:</b> Model’s Parent/Legal Guardian agrees to indemnify and hold Working Assumptions harmless from any claims, damages, or liabilities arising out of the use of the Photographs as permitted by this Agreement, including but not limited to claims of infringement, defamation, or violation of privacy or publicity rights.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Governing Law:</b> This Agreement shall be governed by and construed in accordance with the laws of California. Any legal actions or proceedings arising out of or relating to this Agreement shall be brought in the courts of California.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Entire Agreement:</b> This Agreement constitutes the entire understanding between the parties and supersedes all prior negotiations, understandings, or agreements, whether oral or written, relation to the subject matter herein.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        <b>Severability:</b> If any provision of this Agreement is held to be invalid, illegal, or unenforceable, the validity, legality, or enforceability of the remaining provisions shall not in any way be affected or impaired.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        By presenting this document for Model’s Parent/Legal Guardian’s signing, Working Assumptions thereby acknowledges and agrees to the terms and conditions of this Model Release.
        </Typography>
    </Box>
    <Box sx={{mt : 1}}>
        <Typography variant='paragraph'>
        By signing, Parent/Legal Guardian acknowledges and agrees to the terms and conditions of this Model Release.
        </Typography>
    </Box>
  </Box>
  if (loading) return <Loading />
  if (error) {
    return (
      <Typography variant='paragraph' component='p'>
      Error loading classes
      </Typography>
    )
  }
  if (data) {
    return (
      <Layout>
        <Fragment>
          <Box>
            <Box sx={{ px: 4, py: 3 }}>
              <Grid container spacing={1} sx={{paddingLeft:'4rem !important'}}>
                <Grid item md={11} sm={11} xs={10}>
                  <Typography variant='h1' className='strong'>
                    Model Release Agreement for{' '}
                        {isStudentMinor ? (isDeceased ? 'Deceased Persons' : 'Minors') : isDeceased ? 'Deceased Persons' : 'Adults'}
                  </Typography>
                </Grid>
                <br/>
                <Grid item xs={12} md={10} sx={{ paddingTop:'4rem !important'}}>
                  <Typography  component='p' variant='blockQuote'>
                      Last Updated: November 23, 2023
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ padding:'4rem 6rem 6rem'}}>
              {AboutWa}
              {isStudentMinor
                ? isDeceased
                ? DeceasedPermissionText
                : MinorLivingPermissionText
                : isDeceased
                ? DeceasedPermissionText
                : AdultLivingPermissionText}
            </Box>
          </Box>
        </Fragment>
      </Layout>
    )
  }
}

export default withRouter(ModelReleaseDetail)