import React, { Component, Fragment } from 'react'
import './events.scss'
import { Link } from 'react-router-dom'
import Button from '../ui/elements/Button.js'
import { Typography } from '@mui/material'
import Hero from '../ui/organisms/Hero.js'
import EventList from '../ui/organisms/EventList.js'
import EventEditor from '../ui/organisms/EventEditor.js'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { ArrowLeft } from 'react-feather'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'

const EVENTS_QUERY = gql`
  query EventsQuery {
    events {
      name
      id
      slug
      eventStart
      eventEnd
      timingNote
      location
      summary
      description
      featuredImagePhotographerName
      featuredImagePhotographTitle
      featuredImagePhotographYear
      published
      linkToFeaturedImage
    }
  }
`
class Events extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scope: 'all'
    }
    this.toggleEditEvent = this.toggleEditEvent.bind(this)
  }
  toggleEditEvent(event) {
    this.setState({ scope: 'single', scopedEvent: event })
  }

  handleClose =(event, reason)=>{

    if (reason === "clickaway") {
      return;
    }
    this.setState({showToast:false})
  }


  showToast = message => {
    this.setState({
      showToast: true,
      toastMessage: message
    })
    setTimeout(() => this.setState({ showToast: false }), 10000)
  }
  render() {
    return (
      <Layout>
      <Query query={EVENTS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) {
            return <Typography variant="paragraph" component="p">Error loading classes</Typography>
          }
          if (data) {
            const events = data.events.sort(function(a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.eventStart) - new Date(a.eventStart)
            })
            const vertical = "top"
            const horizontal="right"
            return (
              <Fragment>
                {this.state.showToast === true ? (
                  <Snackbar
                  onClose={this.handleClose}

                  open={this.state.showToast}
                  autoHideDuration={6000}
                    anchorOrigin={{ vertical,horizontal }}
                    >
                    <MuiAlert
                      variant="filled"
                      onClose={this.handleClose}

                      sx={{backgroundColor:"#008160"}}
                    >
                      {this.state.toastMessage}
                    </MuiAlert>
                </Snackbar>) : null}
                <Hero heroTitle='Events' />
                <div className='container__body'>
                  <div className='events__header'>
                    {this.state.scope === 'all' ? (
                      <Fragment>
                        <div className='events__header--title'>
                          <Typography variant='h1' className='bold'>Events ({data.events.length})</Typography>
                        </div>
                        <div className='events__header--button'>
                          <Link to='/events/create-event'>
                            <Button
                              variant='outlined'
                              title='Add New Event'
                              disabled={false}
                              // onClick={() => this.setState({ modalShow: true })}
                            />
                          </Link>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className='events__header--back'>
                          <Typography variant="h3" className="semi-strong link"
                            onClick={() => this.setState({ scope: 'all' })}
                          >
                            <ArrowLeft /> View All Events
                          </Typography>
                        </div>
                        <div className='events__header--title'>
                          <Typography variant='h1' className='bold'>Event Details</Typography>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  {this.state.scope === 'all' ? (
                    <EventList
                      events={events}
                      toggleEditEvent={this.toggleEditEvent}
                      deleteEvent={this.state.deleteEvent}
                      showToast={this.showToast}
                    />
                  ) : this.state.scope === 'single' ? (
                    <EventEditor
                      event={this.state.scopedEvent}
                      deleteEvent={this.state.deleteEvent}
                      showToast={this.showToast}
                    />
                  ) : null}
                </div>
              </Fragment>
            )
          }
        }}
      </Query>
      </Layout>
    )
  }
}

export default Events
