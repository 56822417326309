/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
import moment from 'moment'
import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { Row, Col, Collapse } from 'react-bootstrap'
import { AlertTriangle, CheckCircle, ChevronDown, ChevronUp, Clock, PlusSquare, Slash } from 'react-feather'
import ModelReleaseDropDown from '../elements/ModelReleaseDropDown'
import ApproveOrRejectFormModal from './ApproveOrRejectFormModal'
import DeleteModelReleaseModal from './DeleteModelReleaseModal'
import ResendModelRequestModal from './ResendModelRequestModal'
import { Typography } from '@mui/material'

import Button from 'react-bootstrap/Button'

class ModelReleasesListRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showApprovalModal: false,
      showResendModal: false,
      showDeleteModal: false,
      activeMrUuid: props.modelRelease && props.modelRelease.uuid ? props.modelRelease.uuid : null,
      photoId: this.props.photoId,
      modelRelease: this.props.modelRelease,
      openOne: false,
      opMrUuid: null,
      deleteType: 'sub'
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.modelUuid !== this.state.activeMrUuid) {
      this.setState({
        activeMrUuid: nextProps.modelUuid
      })
    }
    this.setState({modelRelease: nextProps.modelRelease})
  }

  handleModalOpen = (modalType, value, mrUuid) => {
    this.setState({
      [`show${modalType}Modal`]: value,
      opMrUuid: mrUuid
    })
  }
  
  render() {
    const { role, modelName, allRelatedMrs, modelUuid } = this.props
    const { modelRelease } = this.state

    const mrUuid = modelRelease && modelRelease.uuid ? modelRelease.uuid : null 
    let showApprovalModalClose = () => this.setState({ showApprovalModal: false })
    let showResendModalClose = () => this.setState({ showResendModal: false })
    let showDeleteModalClose = () => this.setState({ showDeleteModal: false })
    return <div className='model-release__list'>
    <div className='model-release__list-body-section'>
      <Row>
        <Col sm={12}>
          <div className='model-release__list-heading-section d-flex justify-content-between'>
            <Typography variant ="h3" className="semi-strong" sx={{width:"20%"}}>{modelName}</Typography>
            { ['appadmin'].includes(role) ?
              <Mutation mutation={this.props.manageModelQuery} onCompleted={
                () => {
                  this.props.refetchPhoto && this.props.refetchPhoto()
                }
              }>
                {(onMutate, { data }) => {
                  return (
                  <h4 
                    className='h4--semi-strong model-release__list--links models-list__delete-model-link'
                  >
                    <span style={{ color:'#DE2212' }} className='modelReleaseList__link-section'>
                      <Button style={{ color:'#DE2212' }} variant="link" onClick={e=> {
                        e.target.disabled = true
                        setTimeout(() => {
                          if(modelUuid) {
                            this.setState({showDeleteModal: true, opMrUuid: modelUuid, deleteType: 'main'})
                            e.target.disabled = false
                          } else {
                            this.props.manageNewModel(e, onMutate, 'delete')
                          }
                        }, 500)
                    }}>Delete Model</Button>
                    </span> 
                  </h4>
                )}}
              </Mutation>
            : null }
          </div>
          {
            (modelRelease && modelRelease.signed !== true && !['Approve'].includes(modelRelease.adminDecision) || 
            (modelRelease && modelRelease.signed && ['Completed'].includes(modelRelease.mrStatus) && ['Reject'].includes(modelRelease.adminDecision)) || 
            (modelRelease && modelRelease.signed && ['Active'].includes(modelRelease.mrStatus) && ['Pending'].includes(modelRelease.adminDecision) && modelRelease.sentVia === 'offline') ) || !modelRelease ? 
              <div className='model-release__list-mr-section'>
                <h4
                  className='h4--semi-strong model-release__list-mr-add-link model-release__list--links'
                  onClick={() => this.props.showMrModel('add', mrUuid)}
                >
                  <span className='icon-text-span'>
                    <PlusSquare size={18} /> Add a Release for This Model
                  </span>
                </h4>
                { modelRelease && modelRelease.uuid ? 
                  <h5 className='model-release__list-mr-note'>
                    This will replace the current Model Release for this model.
                  </h5>
                : null}
              </div>  : null
          }
          
          { <> 
            {
              modelRelease && modelRelease.uuid ? 
                <div className='model-release__list-history-section'>
                  <hr className='model-release__list-history-divider'/>
                  <Row>
                    <Col sm={10}>
                      <div className='model-release__list-history-mr-status--mb_12'>
                        { modelRelease.sentVia !== 'offline' ?(
                            ['appadmin', 'student'].includes(role) ? (<Typography variant="paragraph" component="p"> 
                              Model Release for {modelRelease.isMinor ? ( modelRelease.isDeceased ? 'minor deceased' : 'minor') : ( modelRelease.isDeceased ? 'adult deceased' : 'adult')} sent to <strong>{ modelRelease.sentTo }</strong> on { modelRelease.lastSentOn ? moment(modelRelease.lastSentOn, 'YYYY-MM-DD').format('MMMM D, YYYY') : moment(modelRelease.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }
                            </Typography>) : (<Typography variant="paragraph" component="p"> 
                              Model Release for {modelRelease.isMinor ? ( modelRelease.isDeceased ? 'minor deceased' : 'minor') : ( modelRelease.isDeceased ? 'adult deceased' : 'adult')} sent by { modelRelease.sentVia === 'email' ?'email' : 'text message' } on { modelRelease.lastSentOn ? moment(modelRelease.lastSentOn, 'YYYY-MM-DD').format('MMMM D, YYYY') : moment(modelRelease.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }
                            </Typography>)
                          ) : <Typography variant="paragraph" component="p">
                            Model Release PDF for {modelRelease.isMinor ? ( modelRelease.isDeceased ? 'deceased minor' : 'minor') : ( modelRelease.isDeceased ? 'deceased adult' : 'adult')} uploaded on { modelRelease.lastSentOn ? moment(modelRelease.lastSentOn, 'YYYY-MM-DD').format('MMMM D, YYYY') : moment(modelRelease.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }
                          </Typography>
                        }
                      </div>
                      <div className='row'>
                        <Typography variant="h4" className='semi-strong' id="mb_6">
                        {
                          modelRelease.signed ?
                            <span className='icon-text-span model-release__list--success'>
                              { modelRelease.sentVia !== 'offline' ? 
                                <><CheckCircle size={16}/> Granted by { modelRelease.isMinor || modelRelease.isDeceased ? modelRelease.signeeFirstName + ' ' +modelRelease.signeeLastName : modelRelease.modelFirstName + ' ' +modelRelease.modelLastName}{modelRelease.signedOn ? ` on ${moment(modelRelease.signedOn, 'YYYY-MM-DD').format('MMMM D, YYYY')}`: null}</> 
                              : <><CheckCircle size={16}/> PDF Uploaded{modelRelease.signedOn ? ` on ${moment(modelRelease.signedOn, 'YYYY-MM-DD').format('MMMM D, YYYY')}`: null}</>}
                            </span>
                          : modelRelease.signed === false ? 
                            <span className='icon-text-span model-release__list--blocked'>
                              <Slash size={16}/> Declined by { modelRelease.isMinor || modelRelease.isDeceased ? modelRelease.signeeFirstName + ' ' +modelRelease.signeeLastName : modelRelease.modelFirstName + ' ' +modelRelease.modelLastName}{modelRelease.signedOn ? ` on ${moment(modelRelease.signedOn, 'YYYY-MM-DD').format('MMMM D, YYYY')}`: null}
                            </span> 
                          : <span className='icon-text-span'>
                              <Clock size={16}/> Signer Has Not Responded 
                            </span>
                        }
                        {
                            modelRelease.signed ? 
                              <div className='model-release__list--admin-decision-status'>
                                <Typography variant="h4" className='semi-strong'>
                                  { 
                                    modelRelease.adminDecision === 'Pending' ? 
                                      (['appadmin'].includes(role) ?
                                        <span className='icon-text-span model-release__list--alert'><AlertTriangle size={16}/> Approve or Reject Model Release </span>
                                      : <span className='icon-text-span'><Clock size={16}/> Working Assumptions Is Reviewing Model Release </span>
                                      )
                                    : modelRelease.adminDecision == 'Approve' ?
                                      <span className='icon-text-span model-release__list--success'><CheckCircle size={16}/> Approved by Working Assumptions{modelRelease.adminDecisionOn ? ` on ${ moment(modelRelease.adminDecisionOn, 'YYYY-MM-DD').format('MMMM D, YYYY') }`: null}</span>
                                    : modelRelease.adminDecision == 'Reject' ?
                                      <span className='icon-text-span'><Slash size={16}/> Rejected by Working Assumptions{modelRelease.adminDecisionOn ? ` on ${ moment(modelRelease.adminDecisionOn, 'YYYY-MM-DD').format('MMMM D, YYYY') }`: null}</span>
                                    : null
                                  }
                                </Typography>
                                {
                                  modelRelease.adminDecision === 'Reject' ?
                                    <div>
                                      <p className='body text-break'>
                                        { modelRelease.adminRejectionNote ?modelRelease.adminRejectionNote : '' }
                                      </p>
                                    </div>
                                  : null
                                }
                              </div>
                            : null
                          }
                        </Typography>
                      </div>
                    </Col>
                    <Col sm={2}>
                      {(['student', 'teacher', 'facilitator'].includes(role) && modelRelease.signed === null && modelRelease.mrStatus === 'Inactive') || ([ 'teacher', 'facilitator'].includes(role) && modelRelease.signed === null) ?
                        null :
                        <ModelReleaseDropDown
                          modelRelease={modelRelease}
                          handleModalOpen={this.handleModalOpen}
                          role={role}
                        />
                      }
                    </Col>
                  </Row>
                </div>
              : null
            }
            {
              allRelatedMrs && allRelatedMrs.length > 0 && ['appadmin'].includes(role)
                ? <>
                    <Collapse in={this.state.openOne}>  
                      <div>
                        { allRelatedMrs.filter(mr => mr.mrStatus !== 'Deleted' ).map((newMr, index) => (
                          <div className='model-release__list-history-section'>
                            <hr className='model-release__list-history-divider'/>
                            <Row>
                              <Col sm={10}>
                                <div className='model-release__list-history-mr-status--inactive model-release__list-history-mr-status'>
                                  { newMr.sentVia !== 'offline' ?(
                                    ['appadmin', 'student'].includes(role) ? (
                                      <Typography variant="paragraph" component="p">
                                        Model Release for {newMr.isMinor ? ( newMr.isDeceased ? 'minor deceased' : 'minor') : ( newMr.isDeceased ? 'adult deceased' : 'adult')} sent to <strong>{ newMr.sentTo }</strong> on { moment(newMr.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }
                                      </Typography> ) : <Typography variant="paragraph" component="p">Model Release for {newMr.isMinor ? ( newMr.isDeceased ? 'minor deceased' : 'minor') : ( newMr.isDeceased ? 'adult deceased' : 'adult')} sent by { newMr.sentVia === 'email' ?'email' : 'text message' } on { newMr.lastSentOn ? moment(newMr.lastSentOn, 'YYYY-MM-DD').format('MMMM D, YYYY') : moment(newMr.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }</Typography>
                                    ) : <Typography variant="paragraph" component="p">
                                      Model Release PDF for {newMr.isMinor ? ( newMr.isDeceased ? 'deceased minor' : 'minor') : ( newMr.isDeceased ? 'deceased adult' : 'adult')} uploaded on { moment(newMr.createdAt, 'YYYY-MM-DD').format('MMMM D, YYYY') }
                                    </Typography>
                                  }
                                </div>
                                <div className="model-release__list-history-mr-status--inactive">
                                  <Typography variant="h4" className='semi-strong' id="mb_6">
                                  {
                                    newMr.signed ?
                                      <span className='icon-text-span model-release__list--success'>
                                        { newMr.sentVia !== 'offline' ? 
                                          <><CheckCircle size={16}/> Granted by { newMr.isMinor || newMr.isDeceased ? newMr.signeeFirstName + ' ' +newMr.signeeLastName : newMr.modelFirstName + ' ' +newMr.modelLastName}{newMr.signedOn ? ` on ${ moment(newMr.signedOn, 'YYYY-MM-DD').format('MMMM D, YYYY') }`: null}</> 
                                        : <><CheckCircle size={16}/> PDF Uploaded{newMr.signedOn ? ` on ${ moment(newMr.signedOn, 'YYYY-MM-DD').format('MMMM D, YYYY') }`: null}</>}
                                      </span>
                                    : newMr.signed === false ? 
                                      <span className='icon-text-span model-release__list--blocked'><Slash size={16}/> Declined by { newMr.isMinor || newMr.isDeceased ? newMr.signeeFirstName + ' ' +newMr.signeeLastName : newMr.modelFirstName + ' ' +newMr.modelLastName}{newMr.signedOn ? ` on ${ moment(newMr.signedOn, 'YYYY-MM-DD').format('MMMM D, YYYY') }`: null}</span> 
                                    : <span className='icon-text-span'><Clock size={16}/> Signer Has Not Responded(Expired) </span>
                                  }
                                  </Typography>
                                  {
                                    newMr.signed ? 
                                      <div> 
                                        <Typography variant="h4" className='semi-strong'>
                                          {
                                            newMr.adminDecision === 'Pending' ? 
                                              (['appadmin'].includes(role) ?
                                                <span className='icon-text-span model-release__list--alert'><AlertTriangle size={16}/> Approve or Reject Model Release </span>
                                              : <span className='icon-text-span'><Clock size={16}/> Working Assumptions Is Reviewing Model Release </span>
                                              )
                                            : newMr.adminDecision === 'Approve' ?
                                              <span className='icon-text-span model-release__list--success'><CheckCircle size={16}/> Approved by Working Assumptions{newMr.adminDecisionOn ? ` on ${ moment(newMr.adminDecisionOn, 'YYYY-MM-DD').format('MMMM D, YYYY') }`: null}</span>
                                            : newMr.adminDecision === 'Reject' ?
                                              <span className='icon-text-span model-release__list--blocked'><Slash size={16}/> Rejected by Working Assumptions{newMr.adminDecisionOn ? ` on ${ moment(newMr.adminDecisionOn, 'YYYY-MM-DD').format('MMMM D, YYYY') }`: null}</span>
                                            : null
                                          }
                                        </Typography>
                                        {
                                          newMr.adminDecision === 'Reject' ?
                                            <div>
                                              <p className='body text-break'>
                                                { newMr.adminRejectionNote ? newMr.adminRejectionNote : '' }
                                              </p>
                                            </div>
                                          : null
                                        }
                                      </div>
                                    : null
                                  }
                                </div>
                                { newMr.adminDecision === 'Reject' ? <div>
                                  <p className='body text-break'>
                                    {newMr.adminRejectionNote}
                                  </p>
                                </div> : null}
                              </Col>
                              <Col sm={2}>
                                <ModelReleaseDropDown
                                  modelRelease={newMr}
                                  role={role}
                                  handleModalOpen={this.handleModalOpen}
                                />
                              </Col>
                            </Row>
                          </div>
                        ))} 
                      </div>
                    </Collapse>
                    <hr className='model-release__list-history-divider'/>
                    <div 
                      className='photo-release__statuses model-release__list-accordion'
                      onClick={() => {
                        this.setState({ openOne: !this.state.openOne })
                      }}
                      aria-expanded={this.state.openOne}
                    >
                      <div className='photo-release__chevron'>
                        {this.state.openOne ? <ChevronUp /> : <ChevronDown />}
                      </div>
                      <div className='photo-release__accordion'>
                        <Typography variant="h4" className="strong">{this.state.openOne ? 'Hide expired and rejected Model Releases for this model' : 'Show expired and rejected Model Releases for this model'}</Typography>
                      </div>
                    </div>
                  </>
                : null 
            }
            </>
          }
          <ApproveOrRejectFormModal
            uuid={modelRelease && modelRelease.uuid ? modelRelease.uuid : null}
            show={this.state.showApprovalModal}
            onHide={showApprovalModalClose}
            entityType='model'
            refetchPhoto={this.props.refetchPhoto}
          />
          <DeleteModelReleaseModal
            show={this.state.showDeleteModal}
            onHide={showDeleteModalClose}
            photoId={this.state.photoId}
            modelUuid={this.state.opMrUuid}
            onSuccessCallback={showDeleteModalClose}
            refetchPhoto={this.props.refetchPhoto}
            refetchQueries={this.props.refetchQueries}
            studentDetails={this.props.studentDetails}
            deleteType={this.state.deleteType}
          />
          <ResendModelRequestModal
            show={this.state.showResendModal}
            onHide={showResendModalClose}
            photoId={this.state.photoId}

            modelRelease={modelRelease}
            modelUuid={modelRelease && modelRelease.uuid ? modelRelease.uuid : null}
            onSuccessCallback={showResendModalClose}
            refetchPhoto={this.props.refetchPhoto}
            refetchQueries={this.props.refetchQueries}
            studentDetails={this.props.studentDetails}
          />
        </Col>
      </Row>
    </div>
  </div>
  }
}

export default ModelReleasesListRow