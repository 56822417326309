import React, { Component, Fragment } from 'react'
import { Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import Button from '../ui/elements/Button.js'
import Image from 'react-bootstrap/Image'
import WrkXFmlyLogo from '../assets/svgs/wrkxfmlyLogo.jsx'
import signupPhoto from '../assets/images/working-assumptions_sign-up.jpg'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout.js'
import PagesMetaTags from '../utils/PagesMetaTags.js'
import moment from 'moment'
import ErrorPage from '../pages/ErrorPage.jsx'
import { Box } from '@mui/material'
import { withRouter } from '../utils/customHoc.js'
import { inject } from 'mobx-react'
import DateUtils from '../utils/DateUtils.js'

const USER_INVITE_QUERY = gql`
  query UserInvite($uuid: String!) {
    userInvite(uuid: $uuid) {
      id
      uuid
      role
      consumed
      school {
        mailingAddress {
          state
          id
        }
        id
        name
      }
      cohort {
        id
        name
        endDate
        school {
          mailingAddress {
            state
            id
          }
          name
          id
        }
      }
    }
  }
`

class SignUp extends Component {
  render() {
    const { params } = this.props
    const { uuid } = params
    return (
      <Layout>
        <PagesMetaTags index={false} />
        <Query query={USER_INVITE_QUERY} variables={{ uuid: uuid }}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) {
              return (
                <Typography variant='paragraph' component='p' sx={{ mb: '1rem' }}>
                  Error loading classes
                </Typography>
              )
            }
            if (data) {
              if (
                data.userInvite &&
                data.userInvite.cohort &&
                DateUtils.isDatePassed(data.userInvite.cohort.endDate)
              ) {
                let heading = 'This class is no longer accepting sign-ups.'
                let subHeading = (
                  <Box>
                    <Typography variant='paragraph' component='p'>
                      The class <strong>{data.userInvite.cohort.name}</strong> at{' '}
                      <strong>{data.userInvite.cohort.school.name}</strong> has ended.
                    </Typography>
                    <br />
                    <Typography variant='paragraph' sx={{ mb: '1rem' }} component='p'>
                      If you have any questions, please contact{' '}
                      <Box
                        component={'a'}
                        href='mailto:education@workingassumptions.org'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{ wordBreak: 'break-word' }}
                      >
                        <Typography component={'span'} variant='link' className='large'>
                          education@workingassumptions.org
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                )
                return <ErrorPage withInPage={true} heading={heading} subHeading={subHeading} />
              }

              const role = data.userInvite.role
              return (
                <Fragment>
                  <Grid
                    container
                    sx={{
                      mt: { xs: 3.25, md: 0 }
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'block',
                          md: 'none'
                        }
                      }}
                      sm={1}
                      xs={0}
                      md={0}
                    />
                    <Grid
                      item
                      xs={12}
                      sm={10}
                      md={6}
                      direction='row'
                      sx={{
                        p: {
                          xs: role === 'student' ? '2.5rem 1.5rem 5rem' : '5rem 1.5rem',
                          sm: role === 'student' ? '5rem 0' : '7.5rem 0',
                          md:
                            role === 'student' ? '80px 1rem 80px 3rem' : '7.5rem 1rem 7.5rem 3rem',
                          lg: role === 'student' ? '120px 1rem 120px 3rem' : '7.5rem 1rem 0 3rem'
                        },
                        mt: { xs: '30px', sm: 4.75, md: role === 'student' ? '4px' : '2.125rem' },
                        mb: 0
                      }}
                    >
                      <Box
                        className='sign-up__content--header'
                        textAlign={{ xs: 'center', md: 'left' }}
                      >
                        <WrkXFmlyLogo
                          sx={{ height: { xs: '38px', sm: '56px' }, maxWidth: 'inherit', ml: 0 }}
                        />
                      </Box>
                      <Box
                        sx={{
                          mt: {
                            xs: 4,
                            sm: role === 'student' ? 4 : 5,
                            md: role === 'student' ? 3 : 5,
                            lg: 5
                          }
                        }}
                      >
                        <Box className='sign-up__content--copy'>
                          {data.userInvite.consumed ? (
                            <>
                              <Typography variant='paragraph' component='p'>
                                {' '}
                                This invitation has already been used to create an account.
                              </Typography>
                              <br />
                              <Typography variant='paragraph' component='p'>
                                Please contact{' '}
                                <Box
                                  component={'a'}
                                  href='mailto:education@workingassumptions.org'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  sx={{ wordBreak: 'break-word' }}
                                >
                                  <Typography component={'span'} variant='link' className='large'>
                                    education@workingassumptions.org
                                  </Typography>
                                </Box>{' '}
                                if you need a new signup invitation or for help logging in.
                              </Typography>
                            </>
                          ) : role === 'student' ? (
                            <Fragment>
                              <Typography variant='paragraph' component='p'>
                                You have been invited to join {data.userInvite.cohort.name} at{' '}
                                {data.userInvite.cohort.school.name}. If these are not your class
                                details, please contact your teacher.
                              </Typography>
                              <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 } }}>
                                To begin your assignment for this class, please create an account or
                                log in.
                              </Typography>
                            </Fragment>
                          ) : role === 'teacher' ? (
                            <Fragment>
                              <Typography variant='paragraph' component='p'>
                                You have been invited to run assignments for{' '}
                                {data.userInvite.school.name}. If these are not your school details,
                                contact{' '}
                                <Box
                                  component={'a'}
                                  href='mailto:education@workingassumptions.org'
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  sx={{ wordBreak: 'break-word' }}
                                >
                                  <Typography component={'span'} variant='link' className='large'>
                                    education@workingassumptions.org
                                  </Typography>
                                </Box>
                                .
                              </Typography>
                              <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 } }}>
                                Please create an account to get started.
                              </Typography>
                            </Fragment>
                          ) : role === 'facilitator' ? (
                            <Fragment>
                              <Typography variant='paragraph' component='p'>
                                We’re excited to have you as a teaching artist for Working
                                Assumptions' education programs.
                              </Typography>
                              <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 } }}>
                                Please create an account to get started.
                              </Typography>
                            </Fragment>
                          ) : role === 'curator' ? (
                            <Fragment>
                              <Typography variant='paragraph' component='p'>
                                We’re excited to have you as a curator for Working Assumptions'
                                education programs.
                              </Typography>
                              <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 } }}>
                                Please create an account to get started.
                              </Typography>
                            </Fragment>
                          ) : role === 'admin' ? (
                            <Fragment>
                              <Typography variant='paragraph' component='p'>
                                You have been invited to serve as an administrator for Working
                                Assumptions' education programs.
                              </Typography>
                              <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 } }}>
                                Please create an account to get started.
                              </Typography>
                            </Fragment>
                          ) : null}
                        </Box>
                        {data.userInvite.consumed ? null : (
                          <Box
                            sx={{
                              mt: { xs: 5 },
                              mb: {
                                xs: role === 'student' ? 8 : 0,
                                sm: role === 'student' ? 8 : 0,
                                md: 5,
                                lg: 8
                              }
                            }}
                          >
                            <Link to={'/sign-up-flow/' + uuid}>
                              <Button
                                variant='contained'
                                type='submit'
                                title='Create Your Account'
                                disabled={false}
                                className={'create_acc_btn'}
                                btnWidth={{ xs: '100%', md: 'auto' }}
                                color='primary'
                              />
                            </Link>
                          </Box>
                        )}
                      </Box>
                      <Box>
                        {role === 'student' ? (
                          <Box className='sign-up__content--cta'>
                            <Typography component={'p'} variant='paragraph'>
                              Already have an account?
                            </Typography>
                            <Box sx={{ mt: { xs: 3, md: 2 }, mb: { xs: 8, md: 5, lg: 8 } }}>
                              <Link to={`/login?class-uuid=${uuid}`}>
                                <Button
                                  title='Log In'
                                  disabled={false}
                                  className={'create_acc_btn'}
                                  btnWidth={{ xs: '100%', md: 'auto' }}
                                />
                              </Link>
                            </Box>

                            <Typography component={'p'} variant='paragraph'>
                              If you are under 18, review the
                              <Link to='/terms-of-use' target='_blank' rel='noopener noreferrer'>
                                <Typography component={'span'} variant='link' className='large'>
                                  {' '}
                                  Application Terms of Use
                                </Typography>
                              </Link>{' '}
                              and{' '}
                              <Link to='/privacy-policy' target='_blank' rel='noopener noreferrer'>
                                <Typography component={'span'} variant='link' className='large'>
                                  {' '}
                                  Application Privacy Policy
                                </Typography>
                              </Link>{' '}
                              with your parent or guardian before you create an account.
                            </Typography>
                            <Typography
                              variant={'paragraph'}
                              component={'p'}
                              sx={{ mt: { xs: 2, md: 3 } }}
                            >
                              Any work that you upload to the <i>wrkxfmly</i> web application may be
                              stored in the Working Assumptions digital research archive, as part of
                              our mission of seeing cultures of care through the eyes of young
                              adults.
                            </Typography>
                            <Typography
                              variant={'paragraph'}
                              component={'p'}
                              sx={{ mt: { xs: 2, md: 3 } }}
                            >
                              You can read our{' '}
                              <Link
                                to={this.props.resourceStore.getResourceUrlByName('Family Guide')}
                                target='_blank'
                                rel='noopener noreferrer'
                              >
                                <Typography component={'span'} variant='link' className='large'>
                                  Family Guide
                                </Typography>
                              </Link>{' '}
                              to learn more about the <i>wrkxfmly</i> program and archive.
                            </Typography>
                            <Typography
                              variant={'paragraph'}
                              component={'p'}
                              sx={{ mt: { xs: 2, md: 3 } }}
                            >
                              If you have any questions about the program, please contact{' '}
                              <Box
                                component={'a'}
                                href='mailto:education@workingassumptions.org'
                                target='_blank'
                                rel='noopener noreferrer'
                                sx={{ wordBreak: 'break-word' }}
                              >
                                <Typography component={'span'} variant='link' className='large'>
                                  education@workingassumptions.org
                                </Typography>
                              </Box>
                              .
                            </Typography>
                          </Box>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={0}
                      md={6}
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'flex'
                        }
                      }}
                    >
                      <Image src={signupPhoto} fluid />
                    </Grid>
                  </Grid>
                </Fragment>
              )
            }
          }}
        </Query>
      </Layout>
    )
  }
}

export default inject('resourceStore')(withRouter(SignUp))
