export const validationText = {
    generic:{
        cancel: 'All changes have been discarded.'
    },
    assignmentDetails: {
        error: {
            common: 'OOPs! something went wrong.',
            name: {
                empty: 'Please enter a class name.',
                length: 'Class name must be shorter than 40 characters.',
                specialCharacters: 'Please do not use forward slash "/" in name.'
            },
            date: {
                emptyStartDate: 'Please enter a start date.',
                afterEndDate: 'Please enter a date before class ends.',
                afterStartDate: 'Please enter a date after class begins.',
                emptyEndDate: 'Please enter a submission date.',
                afterFirstVisitDate: 'Must be after the first visit date.',
                betweenVisitDate: 'Must be after the first two visit dates.',
                todayOrFutureDate: 'Please enter today’s date or a future date.',
                validEndDate: 'Please enter a date after class starts.'
            },
        },
        success: {
            save: 'Changes to this class were saved successfully.'
        }
    },
    school: {
        error: {
            name: 'Please enter a school name.',
            url: 'Please enter a valid URL.',
            city: 'Please enter the school\'s city.',
            zip: 'Please enter the school\'s zip code.',
            address: 'Please enter an address.'
        },
        success: {
            update: 'Updates saved. All users connected to this school will now see the updated details.'
        }
    },
    user: {
        error: {
            firstName: 'Please enter the user\'s first name.',
            ownFirstName: 'Please enter your first name.',
            lastName: 'Please enter the user\'s last name.',
            ownLastName: 'Please enter your last name.',
            dob: 'You must be 13 or older to use this application.',
            mobileNumber: 'Please enter a phone number.',
            validMobileNumber: 'Please enter a valid mobile phone number.',
            address1: 'Please enter an address.',
            postCode: 'Please enter a zip code.',
            validPostCode: 'Please enter a valid zip code.',
            city: 'Please enter a city.',
            state: 'Please enter a state.',
            website: 'Please enter a valid URL.',
            instagramHandle: 'Must begin with an "@" symbol.',
            email: 'Please enter a valid email address',
            emailExist: 'Email already exists',
            newEmailExist: 'An email has already been registered with this email.',
            newEmailSameAsOld: 'New email address is the same as existing email address.'
        },
        success: {
            update: 'Account update saved.'
        },
        password: {
            error: 'Failed to update student password.',
            success: `The student’s password was reset. Please ask the student to log in with the new password.`,
            emailSent: `An email was sent to the student’s registered email address. Please ask the student to check their email and reset their password.`,
            format: 'Password must be longer than 8 characters and at least one special character.',
            notmatch: 'Passwords do not match.'
        }
    },
    picsio :{
        createCollection: {
            success: 'New collection was created in Pics.io.',
            error: 'Pics.io collection could not be created. Please contact the web app team for support.'
        },
        syncFile: {
            success: 'File(s) successfully synced to Pics.io.',
            error: 'File(s) could not be synced. Please contact the web app team for support.'
        }
    },
    fileUpload: {
        fileInvalidType: {
            error: 'The only allowed file types are png, jpg, jpeg and PDF.'
        }
    }
}