import React, { Component, Fragment } from 'react'
import Button from '../elements/Button.js'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import FormField from '../elements/FormField.js'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import libphonenumber from 'google-libphonenumber'
import { Download } from 'react-feather'
import { DirectUpload } from 'activestorage'
import { inject, observer } from 'mobx-react'
import DownloadUtils from '../../utils/DownloadUtils'
import { Box, FormHelperText, Typography, Grid } from '@mui/material'
import { Spinner } from 'react-bootstrap'
import UseAgreementUploader from '../organisms/UseAgreementUploader'
import './useAgreementModal.scss'
import GATag from '../../utils/GATag'
import BaseModal from '../template/BaseModal';

const CreateUseAgreementMutation = gql`
  mutation CreateUseAgreement(
    $photoIds: [ID!]!
    $useAgreementType: String!
    $guardianFirstName: String
    $guardianLastName: String
    $sendTo: String
    $fileSignedId: String
  ) {
    createUseAgreement(
      photoIds: $photoIds
      useAgreementType: $useAgreementType
      guardianFirstName: $guardianFirstName
      guardianLastName: $guardianLastName
      sendTo: $sendTo
      fileSignedId: $fileSignedId
    ) {
      id
    }
  }
`

const theme = createTheme({
  palette: {
    primary: {
      main: '#0076BB'
    }
  },
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  }
})

const styles = theme => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: 0
  },
  group: {
    margin: 0
  }
})

const uploadFile = (file, onMutate, uaDetails) => {
  // TODO: Make this URL not static
  const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
  const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'photo')

  upload.create((error, blob) => {
    if (error) {
      // Handle the error
    } else {
      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow
      onMutate({
        variables: {
          ...uaDetails,
          fileSignedId: blob.signed_id
        },
        refetchQueries: [`PhotoDetails`]
      })
      // TODO redirect to photo detail page?
    }
  })
}

class UseAgreementModal extends Component {
  constructor(props) {
    super(props)
    const { role } = props.userStore
    this.state = {
      submitted: false,
      errors: {},
      guardianFirstName: '',
      guardianLastName: '',
      sendTo: '',
      useAgreementType:
        ['appadmin', 'student'].includes(role) && this.props.isStudentMinor ? 'email' : 'pdf',
      signedFiles: [],
      isFromSubmitting: false,
      show: this.props.show
    }
    this.initialState = this.state
    this.handleUseAgreementTypeChange = this.handleUseAgreementTypeChange.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this)
    this.download = this.download.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modelOp === 'add' && this.props.show === false) {
      this.setState(this.initialState)
      this.setState({useAgreementType: ['appadmin', 'student'].includes(nextProps.userStore.role) && nextProps.isStudentMinor ? 'email' : 'pdf'})
    }
  }

  phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }
  handleUseAgreementTypeChange(value) {
    delete this.state.errors.sendTo
    this.setState({ sendTo: '', useAgreementType: value })
  }
  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    this.validateChange(e)
  }
  validateChange(e) {
    const fieldName = e.target.name
    const value = e.target.value
    var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    switch (fieldName) {
      case 'guardianFirstName':
        if (value.length < 1) {
          this.setState({
            errors: {
              ...this.state.errors,
              guardianFirstName: 'Please enter the guardian’s first name.'
            }
          })
        } else {
          this.setState({ errors: { ...this.state.errors, guardianFirstName: null } })
        }
        break
      case 'guardianLastName':
        if (value.length < 1) {
          this.setState({
            errors: {
              ...this.state.errors,
              guardianLastName: 'Please enter the guardian’s last name.'
            }
          })
        } else {
          this.setState({ errors: { ...this.state.errors, guardianLastName: null } })
        }
        break
      case 'sendTo':
        let sentToError = null
        if (this.state.useAgreementType === 'email') {
          if (!emailpattern.test(value)) {
            sentToError = 'Please enter a valid email address.'
          } else if (
            value.toLowerCase() === this.props.studentDetails.student.email.toLowerCase()
          ) {
            sentToError = 'Student and guardian email address cannot match.'
          }
        } else if (this.state.useAgreementType === 'text') {
          try {
            let number = this.phoneUtil.parse(value, 'US')
            if (!(value.length === 10 && this.phoneUtil.isValidNumber(number))) {
              sentToError = 'Please enter a valid mobile number.'
            } else if (value === this.props.studentDetails.student.mobileNumber) {
              sentToError = 'Student and guardian mobile number cannot match.'
            }
          } catch (error) {
            sentToError = 'Please enter a valid mobile number.'
          }
        }
        this.setState({ errors: { ...this.state.errors, sendTo: sentToError } })
        break
      default:
        return
    }
  }

  toggleIsMinor(value) {
    this.setState({ isMinor: value })
  }
  toggleIsDeceased(value) {
    this.setState({ isDeceased: value })
  }
  isFormFilled() {
    var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const { role } = this.props.userStore
    return (
      (['appadmin', 'student'].includes(role) &&
      this.props.isStudentMinor &&
      this.state.useAgreementType !== 'pdf'
        ? this.state.guardianFirstName &&
          this.state.guardianFirstName.length > 0 &&
          this.state.guardianLastName &&
          this.state.guardianLastName.length > 0
        : true) &&
      (['appadmin', 'student'].includes(role) && this.props.isStudentMinor
        ? this.state.useAgreementType === 'email'
          ? emailpattern.test(this.state.sendTo) &&
            this.state.sendTo.toLowerCase() !==
              this.props.studentDetails.student.email.toLowerCase()
          : this.state.useAgreementType == 'text'
          ? this.state.sendTo.length === 10 &&
            this.state.sendTo !== this.props.studentDetails.student.mobileNumber
          : this.state.useAgreementType === 'pdf'
          ? this.state.signedFiles.length > 0
          : true
        : this.state.signedFiles.length > 0)
    )
  }
  handleFormSubmit(onMutate, language) {
    // e.preventDefault()
    let errors = {}
    const { role } = this.props.userStore
    if (
      ['appadmin', 'student'].includes(role) &&
      this.props.isStudentMinor &&
      this.state.useAgreementType !== 'pdf'
    ) {
      if (!this.state.guardianFirstName) {
        errors.guardianFirstName = 'Please enter the guardian’s first name.'
      }
      if (!this.state.guardianLastName) {
        errors.guardianLastName = 'Please enter the guardian’s last name.'
      }
    }
    if (this.state.useAgreementType !== 'pdf') {
      if (!this.state.sendTo) {
        errors.sendTo = 'Cannot be blank'
      }
      if (this.state.useAgreementType === 'text') {
        try {
          let number = this.phoneUtil.parse(this.state.sendTo, 'US')
          if (!(this.state.sendTo.length === 10 && this.phoneUtil.isValidNumber(number))) {
            errors.sendTo = 'Please enter a valid mobile number.'
          } else if (this.state.sendTo === this.props.studentDetails.student.mobileNumber) {
            errors.sendTo = 'Student and guardian mobile number cannot match.'
          }
        } catch (error) {
          errors.sendTo = 'Please enter a valid mobile number.'
        }
      }
      if (this.state.useAgreementType === 'email') {
        var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!emailpattern.test(this.state.sendTo)) {
          errors.sendTo = 'Please enter a valid email address.'
        } else if (
          this.state.sendTo.toLowerCase() === this.props.studentDetails.student.email.toLowerCase()
        ) {
          errors.sendTo = 'Student and guardian email address cannot match.'
        }
      }
    } else {
      if (this.state.signedFiles.length <= 0) {
        errors.pdfError = 'File not uploaded.'
      }
    }
    this.setState({ errors: { ...errors }, isFromSubmitting: true })
    if (Object.keys(errors).length === 0) {
      let modelVars = {}
      modelVars = {
        photoIds: this.props.photoIds,
        useAgreementType: this.state.useAgreementType,
        guardianFirstName: this.state.guardianFirstName,
        guardianLastName: this.state.guardianLastName,
        sendTo: this.state.sendTo
      }
      if (this.state.signedFiles.length > 0) {
        Array.from(this.state.signedFiles).forEach(file => {
          uploadFile(file, onMutate, modelVars)
          this.props.refetchPhoto && this.props.refetchPhoto()
          this.props.refetchUaData && this.props.refetchUaData()
        })
      } else {
        onMutate({
          variables: {
            ...modelVars
          },
          refetchQueries: [`PhotoDetails`, `ProjectDetails`]
        })
      }
    }
  }
  handleSubmitSuccess() {
    this.setState({ submitted: true, isFromSubmitting: false })
    this.props.onSuccessCallback('Request sent by '.concat(this.state.useAgreementType))
    this.props?.refetchPhoto?.()
    this.props?.refetchUaData?.()
    this?.props?.refetchQueries?.()
  }
  uploadFiles = files => {
    this.setState({ signedFiles: files })
  }
  download = async name => {
    const { resourceStore } = this.props
    let agreementFor = null
    if (name === 'Use Agreement Form for Minors English') {
      // window.ga('send', 'event', 'use-agreement', 'pdf-downloaded', 'minor', 1)
      agreementFor = 'minor'
    } else if (name === 'Use Agreement Adult English') {
      // window.ga('send', 'event', 'use-agreement', 'pdf-downloaded', 'adult', 1)
      agreementFor = 'adult'
    }
    GATag.setEvent({
      category: 'use-agreement',
      action: 'pdf-downloaded',
      label: agreementFor,
      value: 1
    })
    let newUrl = resourceStore.getResourceUrlByName(name)
    DownloadUtils.downloadFileAndSaveAs(newUrl, name)
  }
  render() {
    const { handleFormChange, handleUseAgreementTypeChange, handleSubmitSuccess } = this
    const { classes } = this.props
    const { role } = this.props.userStore

    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);

    };
    return (
      <Mutation
        mutation={CreateUseAgreementMutation}
        onCompleted={() => {
          handleSubmitSuccess()
        }}
      >
        {(onMutate, { data }) => {
          return (
            <BaseModal
              {...this.props}
              size='lg'
              onClick={this.props.onClick}
              value={this.props.value}
              backdropClassName='text_model_class'
              backdrop='static'
              open={this.props.show}
              onClose={handleClose}
              onHide={this.props.onHide}
            >
              <BaseModal.BaseModalHeader>
                Add a Photograph Use Agreement
              </BaseModal.BaseModalHeader>
              <BaseModal.BaseModalBody>
                {['student', 'appadmin'].includes(role) ? (
                  role === 'student' ? (
                    <div className='invite-student__description'>
                      <Typography variant="paragraph"
                          sx={{
                            mb:"1rem",
                          }}
                          component="p"
                        >
                        Please add details for Working Assumptions to send a Use Agreement to your
                        parent or legal guardian directly, or you can upload a signed form.
                      </Typography>
                    </div>
                  ) : role === 'appadmin' && this.props.isStudentMinor ? (
                    <div className='invite-student__description'>
                      <Typography variant="paragraph"
                          sx={{
                            mb:"1rem",
                          }}
                          component="p"
                        >
                        Please add details for Working Assumptions to send a Use Agreement to the
                        student’s parent or legal guardian directly, or you can upload a signed
                        form.
                      </Typography>
                    </div>
                  ) : null
                ) : null}
                {['appadmin', 'student'].includes(role) && this.props.isStudentMinor ? (
                  <>
                    <div className='invite-student__model-name'>
                      <h3>How will you provide the Use Agreement?</h3>
                      <form
                        className='project-progress__form-container'
                        noValidate
                        autoComplete='on'
                      >
                        <div className='model-release__form'>
                          <div className='invite-student__model-age'>
                            <div className={classes.root}>
                              <MuiThemeProvider theme={theme}>
                                <div className='model-release-type-buttons-containers'>
                                  <Box component={'div'} className='outline-button' sx= {{mb: '0.9375rem'}}>
                                    <Button
                                      className={'model-release-type-buttons outline-buttons'.concat(
                                        this.state.useAgreementType === 'email' ? '--selected' : ''
                                      )}
                                      boldTitle={
                                        role === 'appadmin'
                                          ? 'Send an email on behalf of the student'
                                          : 'Have Working Assumptions send an email'
                                      }
                                      onClick={() => handleUseAgreementTypeChange('email')}
                                      btnWidth={'100%'}
                                      btnHeight='85px'
                                    />
                                  </Box>
                                  <Box component={'div'} className='outline-button' sx= {{mb: '0.9375rem'}}>
                                    <Button
                                      className={'model-release-type-buttons outline-buttons'.concat(
                                        this.state.useAgreementType === 'text' ? '--selected' : ''
                                      )}
                                      boldTitle={
                                        role === 'appadmin'
                                          ? 'Send a text message on behalf of the student'
                                          : 'Have Working Assumptions send a text message'
                                      }
                                      onClick={() => handleUseAgreementTypeChange('text')}
                                      btnWidth={'100%'}
                                      btnHeight='85px'
                                    />
                                  </Box>
                                  <Box component={'div'} className='outline-button'>
                                    <Button
                                      className={'model-release-type-buttons outline-buttons'.concat(
                                        this.state.useAgreementType === 'pdf' ? '--selected' : ''
                                      )}
                                      boldTitle='Download a form and upload the signed copy to Working Assumptions'
                                      onClick={() => handleUseAgreementTypeChange('pdf')}
                                      btnWidth={'100%'}
                                      btnHeight='85px'
                                    />
                                  </Box>
                                </div>
                              </MuiThemeProvider>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {this.state.useAgreementType !== 'pdf' ? (
                      <div className='invite-student__model-age'>
                        <h3>
                          {role === 'appadmin'
                            ? 'What is the name of the student’s parent or guardian?'
                            : 'What is the name of your parent or guardian?'}
                        </h3>
                        <Row>
                          <Col md='true'>
                            <div className='form-field col--left'>
                              <FormField
                                formFieldId='outlined-fname'
                                formFieldFullWidth='false'
                                formFieldLabel='Guardian First Name'
                                formFieldVariant='filled'
                                formFieldError={this.state.errors.guardianFirstName || null}
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldValue={this.state.guardianFirstName}
                                formFieldType='name'
                                formFieldName='guardianFirstName'
                                onChange={handleFormChange}
                              />
                            </div>
                          </Col>
                          <Col md='true'>
                            <div className='form-field col--right'>
                              <FormField
                                formFieldId='outlined-lname'
                                formFieldVariant='filled'
                                formFieldFullWidth='false'
                                formFieldLabel='Guardian Last Name'
                                formFieldError={this.state.errors.guardianLastName || null}
                                formFieldValue={this.state.guardianLastName}
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldType='name'
                                formFieldName='guardianLastName'
                                onChange={handleFormChange}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                  </>
                ) : null}
                {this.state.useAgreementType === 'email' &&
                ['appadmin', 'student'].includes(role) ? (
                  <div className='invite-student__model-age'>
                    <h3>
                      {role === 'appadmin'
                        ? 'What is the student’s parent or guardian’s email address?'
                        : 'What is your parent or guardian’s email address?'}
                    </h3>
                    <Row>
                      <Col md='true'>
                        <div className='form-field col--left'>
                          <FormField
                            formFieldId='outlined-email'
                            formFieldFullWidth='false'
                            formFieldLabel={'Guardian Email'}
                            formFieldVariant='filled'
                            formFieldError={this.state.errors.sendTo || null}
                            formFieldValue={this.state.sendTo}
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='email'
                            formFieldName='sendTo'
                            formFieldAutoComplete='email'
                            onChange={handleFormChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : null}

                {this.state.useAgreementType === 'text' &&
                ['appadmin', 'student'].includes(role) ? (
                  <div className='invite-student__model-age'>
                    <h3>
                      {role === 'appadmin'
                        ? 'What is the student’s parent or guardian’s mobile number?'
                        : 'What is your parent or guardian’s mobile number?'}
                    </h3>
                    <Row>
                      <Col md='true'>
                        <div className='form-field col--left'>
                          <FormField
                            formFieldId='outlined-phone-number'
                            formFieldFullWidth='false'
                            formFieldVariant='filled'
                            formFieldLabel={'Guardian Mobile Number'}
                            formFieldError={this.state.errors.sendTo || null}
                            formFieldValue={this.state.sendTo}
                            formFieldClasses={{
                              root: 'text-field__outline'
                            }}
                            formFieldType='number'
                            formFieldAutoComplete='tel'
                            formFieldName='sendTo'
                            onChange={handleFormChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : null}

                <div
                  className={
                    ['student', 'appadmin'].includes(role) && this.props.isStudentMinor
                      ? 'use-agreement__description'
                      : ''
                  }
                >
                  {['email', 'text'].includes(this.state.useAgreementType) ? (
                    role === 'student' ? (
                      <>
                        <Typography variant="paragraph"
                          sx={{
                            mb:"1rem",
                          }}
                          component="p"
                        >
                          Please let your parent or guardian know that they will receive one message
                          from Working Assumptions for each selected photograph. Each message will
                          contain a link to the photograph and a digital Use Agreement form which
                          they must fill out and submit.
                        </Typography>
                      </>
                    ) : role === 'appadmin' ? (
                      <>
                        <Typography variant="paragraph"
                          sx={{
                            mb:"1rem",
                          }}
                          component="p"
                        >
                          Please ask the student to let their parent or guardian know that they will
                          receive one message from Working Assumptions for each selected photograph.
                          Each message will contain a link to the photograph and a digital Use
                          Agreement form which the parent or guardian must fill out and submit.
                        </Typography>
                      </>
                    ) : null
                  ) : role === 'student' ? (
                    <>
                      <Typography
                      variant="paragraph"
                      component="p"
                      className='use-agreement__p-body'>
                        Please download this form and print it for your parent or guardian, or send
                        the digital PDF file to them to fill out and return. Then upload a photo of
                        the signed printed form or upload the signed PDF.
                      </Typography>
                    </>
                  ) : role === 'appadmin' && this.props.isStudentMinor ? (
                    <>
                      <Typography
                        variant="paragraph"
                        component="p"
                      className='use-agreement__p-body'>
                        Please have the student either give their parent or guardian a printed copy
                        of this form to complete and sign, or have the student send their parent or
                        guardian the digital PDF to complete and return. The student can then either
                        upload a photo of the signed printed form or upload the signed PDF.
                      </Typography>
                    </>
                  ) : (
                    <Typography
                    variant="paragraph"
                    component="p">
                      Please have the student either sign a printed copy of this form or complete
                      the digital PDF. The student can then either upload a photo of the signed
                      printed form or upload the signed PDF.
                    </Typography>
                  )}
                </div>

                {this.state.useAgreementType === 'pdf' ? (
                  <Box className='photo-release__upload-release'>
                    {this.props.isStudentMinor ? (
                      <Box className='send-pdf__links'>
                        <div onClick={() => this.download('Use Agreement Form for Minors English')}>
                          <div className='send-pdf__links--downloads'>
                            <Typography variant="h4"className='semi-strong link'>
                              <Download size={16} /> Download Use Agreement Form for Minors
                            </Typography>
                          </div>
                        </div>
                      </Box>
                    ) : (
                      <Box sx={{width:'90%'}} className='send-pdf__links'>
                        <div onClick={() => this.download('Use Agreement Adult English')}>
                          <div className='send-pdf__links--downloads'>
                            <Typography variant="h4"className='semi-strong link'>
                              <Download size={16} /> Download Use Agreement Form for Adults
                            </Typography>
                          </div>
                        </div>
                      </Box>
                    )}
                    <Box sx={{width:'90%'}} className='send-pdf__submit'>
                      <UseAgreementUploader
                        photoId={this.props.photoId}
                        showToast={this.props.showToast}
                        uploadFiles={this.uploadFiles}
                      />
                    </Box>
                    {this.state.errors.pdfError ? (
                      <FormHelperText
                        className='form-field__error model-release-file-error component-error-text'
                      >
                        {this.state.errors.pdfError}
                      </FormHelperText>
                    ) : null}
                  </Box>
                ) : null}

                <BaseModal.BaseModalFooter>
                  <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='model-release__buttons'>
                    <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}}}>
                      <Grid item xs={12} sm={6} md={4}>
                          <Button
                            variant='contained'
                            type='submit'
                            disabled={!this.isFormFilled() || this.state.isFromSubmitting}
                            title={
                              this.state.isFromSubmitting ? (
                                <span className='icon-text-span'>
                                  <Spinner
                                    as='span'
                                    animation='border'
                                    size='sm'
                                    role='status'
                                    aria-hidden='true'
                                  />{' '}
                                  {this.state.useAgreementType === 'pdf' ? 'Saving...' : 'Sending...'}
                                </span>
                              ) : this.state.useAgreementType === 'pdf' ? (
                                'Save and Close'
                              ) : (
                                'Send Request'
                              )
                            } // in English
                            onClick={() => this.handleFormSubmit(onMutate, 'en')}
                            sx={{width:{xs:'100%', sm:'100%'}, float:'right'}}
                          />
                      </Grid>
                    </Grid>    
                  </Box>
                </BaseModal.BaseModalFooter>
              </BaseModal.BaseModalBody>
            </BaseModal>
          )
        }}
      </Mutation>
    )
  }
}

UseAgreementModal.propTypes = {
  classes: PropTypes.object.isRequired
}

export default inject('userStore', 'resourceStore')(withStyles(styles)(observer(UseAgreementModal)))
