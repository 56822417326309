import React, { Component, Fragment } from 'react'
import './modelrelease.scss'
import { Query, Mutation } from '@apollo/client/react/components'
import FormField from '../ui/elements/FormField.js'
import Button from '../ui/elements/Button.js'
import FormCheckbox from '../ui/elements/FormCheckbox.js'
// import Form from 'react-bootstrap/Form'
import { gql } from '@apollo/client'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'
import { Box, Grid, Typography } from '@mui/material'
import Modal from 'react-bootstrap/Modal'
import { observer, inject } from 'mobx-react'
import { Clock, CheckCircle, XCircle } from 'react-feather'
import PagesMetaTags from '../utils/PagesMetaTags'
import FormSelect from '../ui/elements/FormSelect'
import variables from '../ui/settings/_variables.scss'
import { withRouter } from '../utils/customHoc'
import ModelRelaseAgreement from '../ui/molecules/ModelReleaseAgreement'

const MODEL_RELEASE_QUERY = gql`
  query ModelRelease($uuid: String!) {
    modelRelease(uuid: $uuid) {
      signed
      firstName
      lastName
      sentVia
      isMinor
      isDeceased
      wantsUpdates
      modelFirstName
      modelLastName
      signeeFirstName
      signeeLastName
      signeeEmail
      mrStatus
      adminDecision
      relatedToMr
      signedOn
      adminDecisionOn
      adminRejectionNote
      minorAge
      email
      photo {
        url
        cdnOriginalUrl
        submitter {
          name
        }
        project {
          id
          submitted
          student {
            id
            name
            mobileNumber
            email
          }
        }
      }
    }
  }
`

const SIGN_MODEL_RELEASE = gql`
  mutation SignModelRelease(
    $releaseUuid: String!
    $signed: Boolean!
    $modelFirstName: String
    $modelLastName: String
    $signeeFirstName: String
    $signeeLastName: String
    $signeeEmail: String
    $wantsUpdates: Boolean
    $minorAge: Int
  ) {
    signModelRelease(
      releaseUuid: $releaseUuid
      signed: $signed
      modelFirstName: $modelFirstName
      modelLastName: $modelLastName
      signeeFirstName: $signeeFirstName
      signeeLastName: $signeeLastName
      wantsUpdates: $wantsUpdates
      signeeEmail: $signeeEmail
      minorAge: $minorAge
    ) {
      id
      signed
    }
  }
`
const age = [...Array(18).keys()].map(val => (
  { label: val, value: val }
))

const ModelReleaseModal = ({ show, onClick, isMinor, isDeceased }) => (
  <ModelRelaseAgreement
    size='lg'
    aria-labelledby='contained-modal-title-vcenter'
    centered
    open={show}
    handleClose={onClick} />

)

class ModelRelease extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
      signed: null,
      showModelReleaseModal: false,
      disableButton: null,
      termsAccepted: false
    }

    this.isFormFilled = this.isFormFilled.bind(this)
    this.handleQueryComplete = this.handleQueryComplete.bind(this)
  }

  handleFormChange = (e, release) => {
    this.setState({ [e.target.name]: e.target.value })
    this.validateChange(e, release)
  }

  validateChange(e, release) {
    var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const fieldName = e.target.name
    const value = e.target.value

    switch (fieldName) {
      case 'signeeEmail':
        if (!emailpattern.test(value)) {
          this.setState({
            errors: {
              ...this.state.errors,
              signeeEmail: 'Please enter a valid email address.'
            }
          })
        } else if (value.toLowerCase() === release.photo.project.student.email.toLowerCase()) {
          this.setState({
            errors: {
              ...this.state.errors,
              signeeEmail: 'Email address cannot match student’s registered email address.'
            }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, signeeEmail: null }
          })
        }
        break
      default:
        return
    }
  }


  handleFormChangeCheck = e => {
    this.setState({ [e.target.name]: e.target.checked })
  }
  handleSubmit = (e, onMutate) => {
    e.preventDefault()
    const { params } = this.props
    this.setState({ disableButton: true })
    onMutate({
      variables: {
        releaseUuid: params.releaseUuid,
        signed: this.state.signed,
        signeeFirstName: this.state.signeeFirstName,
        signeeLastName: this.state.signeeLastName,
        signeeEmail: this.state.signeeEmail,
        wantsUpdates: this.state.wantsUpdates,
        modelFirstName: this.state.modelFirstName,
        modelLastName: this.state.modelLastName,
        minorAge: this.state.minorAge
      },
      refetchQueries: [`ModelRelease`]
    })
  }

  toggleSign(value) {
    this.setState({ signed: value })
  }

  toggleShowModelReleaseModal() {
    this.setState({ showModelReleaseModal: true })
  }

  isFormFilled(release) {
    if (this.state.signed === null) return false

    if (this.state.signed) {
      var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return (
        this.state.modelFirstName &&
        this.state.modelFirstName.length > 0 &&
        this.state.modelLastName &&
        this.state.modelLastName.length > 0 &&
        this.state.termsAccepted &&
        emailpattern.test(this.state.signeeEmail) &&
        this.state.signeeEmail.toLowerCase() !== release.photo.project.student.email.toLowerCase() &&
        (this.state.isMinor || this.state.isDeceased
          ? this.state.signeeFirstName &&
          this.state.signeeFirstName.length > 0 &&
          this.state.signeeLastName &&
          this.state.signeeLastName.length > 0 &&
          (this.state.isMinor && !this.state.isDeceased ?
            this.state.minorAge !== null &&
            this.state.minorAge >= 0 : true)
          : true)
      )
    } else {
      return this.state.termsAccepted ? true : false
    }
  }

  handleQueryComplete = data => {
    const modelDetails = data && data.modelRelease
    this.setState({ ...modelDetails });
  }

  checkMrStatus = (mrDetails) => {
    if (mrDetails && mrDetails.modelRelease) {
      const { modelRelease } = mrDetails
      return (modelRelease.mrStatus === 'Active' && modelRelease.adminDecision === null)
    }
    return false
  }

  documentSectionForModelReleaseHeader = () => {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant='h4' className='semi-strong link'>
          <Box
            component='a'
            target='_blank'
            href='http://waed.org/'
            rel='noopener noreferrer'
          >
            <Typography variant='link' className='large'>Read about the Photography Assignment</Typography>
          </Box>
        </Typography>
        <Typography variant='h4' component={'p'} className='semi-strong link' sx={{ mt: 2 }}>
          <Box
            component='a'
            href='#'
            onClick={e => {
              e.preventDefault()
              this.toggleShowModelReleaseModal()
            }}
          >
            <Typography variant='link' className='large'>Model Release Agreement Details</Typography>
          </Box>
        </Typography>
      </Box>
    )
  }

  render() {
    const { userStore, params } = this.props
    return (
      <Layout>
        <PagesMetaTags index={false} />
        <Query query={MODEL_RELEASE_QUERY} variables={{ uuid: params.releaseUuid }} onCompleted={this.handleQueryComplete}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <Typography variant="paragraph" component="p">Could not Find that Model Release</Typography>
            if (data) {
              const release = data && data.modelRelease
              const isMrActive = this.checkMrStatus(data)
              return (
                <Mutation mutation={SIGN_MODEL_RELEASE}>
                  {(onMutate, { data }) => (
                    <Fragment>
                      <Box>
                        <ModelRelaseAgreement
                          open={this.state.showModelReleaseModal}
                          handleClose={() =>
                            this.setState({ showModelReleaseModal: false })}
                          isMinor={release && release.isMinor}
                          isDeceased={release && release.isDeceased}
                        />
                        {/* <ModelReleaseModal
                          modelUuid={params.releaseUuid}
                          show={this.state.showModelReleaseModal}
                          isMinor={release && release.isMinor}
                          isDeceased={release && release.isDeceased}
                          onClick={() =>
                            this.setState({ showModelReleaseModal: false })
                          }
                        /> */}
                        <Box
                          sx={{
                            backgroundColor: `${variables.backgroundGrey}`,
                            p: {
                              xs: '56px 40px 40px',
                              sm: '64px 40px 40px',
                              md: '96px 40px 80px'
                            }
                          }}
                        >
                          <Grid container spacing={4}>
                            <Grid item md={5} sm={12} xs={12} sx={{ pt: { md: '80px !important' } }}>
                              <Typography
                                variant='h1'
                                className='oversize_bold'
                                sx={{
                                  color: `${variables.publicBlue}`,
                                  mt: { xs: 5, md: 0 }
                                }}
                              >
                                Model Release Agreement
                              </Typography>
                            </Grid>
                            <Grid item md={7} sm={12} xs={12} sx={{ pt: { md: '80px !important' } }}>
                              {userStore.role !== null ? (
                                <Fragment>
                                  {release && release.signed === true ? (
                                    <Box>
                                      <Typography
                                        variant='paragraph'
                                        sx={{ mb: 2 }}
                                        component='p'
                                      >
                                        Thank you for granting permission to Working Assumptions to preserve and use this work. You will receive confirmation shortly.
                                      </Typography>
                                      <Typography
                                        variant='paragraph'
                                        sx={{ mb: 2 }}
                                        component='p'
                                      >
                                        If you have any questions, please contact{' '}
                                        <a href='mailto:education@workingassumptions.org' style={{ wordBreak: 'break-word' }}>
                                          <Typography variant='link' className='large'>education@workingassumptions.org</Typography>
                                        </a>
                                        .
                                      </Typography>
                                      {this.documentSectionForModelReleaseHeader()}
                                    </Box>
                                  ) : release && release.signed === false ? (
                                    <Box>
                                      <Typography
                                        variant='paragraph'
                                        sx={{ mb: 2 }}
                                        component='p'
                                      >
                                        Thank you for your decision. This photograph will not be shared or made available for study, reproduction or exhibition, but it will remain in the wrkxfmly web application and digital research archive visible only to individuals connected to Working Assumptions. You will receive confirmation shortly.
                                      </Typography>
                                      <Typography
                                        variant='paragraph'
                                        sx={{ mb: 2 }}
                                        component='p'
                                      >
                                        If you have any questions or wish to grant permission in the future, please contact {' '}
                                        <a href='mailto:education@workingassumptions.org' style={{ wordBreak: 'break-word' }}>
                                          <Typography variant='link' className='large'>education@workingassumptions.org</Typography>
                                        </a>
                                        .
                                      </Typography>
                                      {this.documentSectionForModelReleaseHeader()}
                                    </Box>
                                  ) : (
                                    <Box>
                                      <>
                                        {release.isMinor ? (
                                          release.isDeceased ? (
                                            <>
                                              <Typography
                                                variant='paragraph'
                                                component='p'
                                                sx={{ mb: 2 }}
                                              >
                                                {release.photo.submitter.name} has been recognized for their photography, created during the <i>wrkxfmly</i> assignment organized by Working Assumptions and featuring the image of your deceased relative. We would like to include their excellent work in a digital research archive we are building to preserve and honor their unique perspective. The photographs and captions held in this archive advance our mission of seeing cultures of care through the eyes of young adults.
                                              </Typography>
                                            </>
                                          ) : (
                                            <>
                                              <Typography
                                                variant='paragraph'
                                                component='p'
                                                sx={{ mb: 2 }}
                                              >
                                                {release.photo.submitter.name} has been recognized for their photography, created during the <i>wrkxfmly</i> assignment organized by Working Assumptions and featuring the image of a child under your guardianship. We would like to include their excellent work in a digital research archive we are building to preserve and honor their unique perspective. The photographs and captions held in this archive advance our mission of seeing cultures of care through the eyes of young adults.
                                              </Typography>
                                            </>
                                          )
                                        ) : release.isDeceased ? (
                                          <>
                                            <Typography
                                              variant='paragraph'
                                              component='p'
                                              sx={{ mb: 2 }}
                                            >
                                              {release.photo.submitter.name} has been recognized for their photography, created during the <i>wrkxfmly</i> assignment organized by Working Assumptions and featuring the image of your deceased relative. We would like to include their excellent work in a digital research archive we are building to preserve and honor their unique perspective. The photographs and captions held in this archive advance our mission of seeing cultures of care through the eyes of young adults.
                                            </Typography>
                                          </>
                                        ) : (
                                          <>
                                            <Typography
                                              variant='paragraph'
                                              component='p'
                                              sx={{ mb: 2 }}
                                            >
                                              {release.photo.submitter.name} has been recognized for their photography, created during the <i>wrkxfmly</i> assignment organized by Working Assumptions and featuring your image. We would like to include their excellent work in a digital research archive we are building to preserve and honor their unique perspective. The photographs and captions held in this archive advance our mission of seeing cultures of care through the eyes of young adults.
                                            </Typography>
                                          </>
                                        )}
                                        <Typography
                                          variant='paragraph'
                                          component='p'
                                          sx={{ mb: 2 }}
                                        >
                                          We take great care in making sure that recognizable people in photographs know and understand their rights as models and that the photographer’s work and intellectual property is valued.
                                        </Typography>
                                        <Typography
                                          variant='paragraph'
                                          component='p'
                                          sx={{ mb: 2 }}
                                        >
                                          If you grant permission below, Working Assumptions may use, reproduce, distribute, display and publish the student’s photograph(s) for non-profit mission-aligned purposes. The student’s work may be stored in Working Assumption’s archive, made available for research collaborations, and included in print publications, in digital formats, online, and in other media.
                                        </Typography>
                                        <Typography variant='paragraph' component='p'>
                                          Please read the details in the links below and decide if you want to allow Working Assumptions to preserve and use this photograph as described. If you have any questions, please contact {' '}

                                          <a
                                            target='_blank'
                                            href='mailto:education@workingassumptions.org'
                                            rel='noopener noreferrer'
                                          >
                                            <Typography variant='link' className='large'>education@workingassumptions.org</Typography>
                                          </a>
                                          .
                                        </Typography>

                                        {this.documentSectionForModelReleaseHeader()}
                                      </>
                                    </Box>
                                  )}
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <div className='model-release__hero--copy'>
                                    <Typography
                                      variant='paragraph'
                                      sx={{
                                        mb: '1rem'
                                      }}
                                      component='p'
                                    >
                                      <b>{release.photo.submitter.name}</b> requested
                                      permission to publish the photograph below.
                                    </Typography>

                                    {release.signed === null ? (
                                      release.isMinor ? (
                                        release.isDeceased ? (
                                          <>
                                            <p className='model-release__hero--copy-line-br'>
                                              {release.photo.submitter.name} has been recognized for their photography, created during the <i>wrkxfmly</i> assignment organized by Working Assumptions and featuring the image of your deceased relative. We would like to include their excellent work in a digital research archive we are building to preserve and honor their unique perspective. The photographs and captions held in this archive advance our mission of seeing cultures of care through the eyes of young adults.
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <p className='model-release__hero--copy-line-br'>
                                              {release.photo.submitter.name} has been recognized for their photography, created during the <i>wrkxfmly</i> assignment organized by Working Assumptions and featuring the image of a child under your guardianship. We would like to include their excellent work in a digital research archive we are building to preserve and honor their unique perspective. The photographs and captions held in this archive advance our mission of seeing cultures of care through the eyes of young adults.
                                            </p>
                                          </>
                                        )
                                      ) : release.isDeceased ? (
                                        <>
                                          <p className='model-release__hero--copy-line-br'>
                                            {release.photo.submitter.name} has been recognized for their photography, created during the <i>wrkxfmly</i> assignment organized by Working Assumptions and featuring the image of your deceased relative. We would like to include their excellent work in a digital research archive we are building to preserve and honor their unique perspective. The photographs and captions held in this archive advance our mission of seeing cultures of care through the eyes of young adults.
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p className='model-release__hero--copy-line-br'>
                                            {release.photo.submitter.name} has been recognized for their photography, created during the <i>wrkxfmly</i> assignment organized by Working Assumptions and featuring your image. We would like to include their excellent work in a digital research archive we are building to preserve and honor their unique perspective. The photographs and captions held in this archive advance our mission of seeing cultures of care through the eyes of young adults.
                                          </p>
                                        </>
                                      )
                                    ) : null}
                                    <Typography variant='paragraph' component='p'>
                                      We take great care in making sure that recognizable people in photographs know and understand their rights as models and that the photographer’s work and intellectual property is valued.
                                    </Typography>
                                    <Typography variant='paragraph' component='p'>
                                      If you grant permission below, Working Assumptions may use, reproduce, distribute, display and publish the student’s photograph(s) for non-profit mission-aligned purposes. The student’s work may be stored in Working Assumption’s archive, made available for research collaborations, and included in print publications, in digital formats, online, and in other media.
                                    </Typography>
                                    <Typography variant='paragraph' component='p'>
                                      Please read the details in the links below and decide if you want to allow Working Assumptions to preserve and use this photograph as described. If you have any questions, please contact{' '}
                                      <a
                                        target='_blank'
                                        href='mailto:education@workingassumptions.org'
                                        rel='noopener noreferrer'
                                      >
                                        <Typography variant='link' className='large'>education@workingassumptions.org</Typography>
                                      </a>
                                      .
                                    </Typography>
                                  </div>
                                </Fragment>
                              )}
                              {userStore.role == 'appadmin' ? (
                                <Box sx={{pt:'8px'}}>
                                {release.signed === true ? (
                                  <div className='project-progress__accepted-permission--permission-data'>
                                    <Typography
                                      variant='h5'
                                      className='semi-strong'
                                    >
                                      <CheckCircle size='16' height='17' />
                                      {'  '}Granted by{' '}
                                      {release.isDeceased || release.isMinor
                                        ? release.signeeFirstName +
                                        ' ' +
                                        release.signeeLastName
                                        : release.firstName +
                                        ' ' +
                                        release.lastName}
                                    </Typography>
                                  </div>
                                ) : release.signed === false ? (
                                  <Typography
                                    variant='h5'
                                    className='semi-strong'
                                  >
                                    <XCircle size='16' height='17' />
                                    {'  '}Declined by{' '}
                                    {release.isDeceased || release.isMinor
                                      ? release.signeeFirstName +
                                      ' ' +
                                      release.signeeLastName
                                      : release.firstName +
                                      ' ' +
                                      release.lastName}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant='h5'
                                    className='semi-strong'
                                  >
                                    <Clock size='16' />
                                    {'  '}Waiting for Response
                                  </Typography>
                                )}
                                </Box>
                              ): null}
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          sx={{
                            background: variables.white,
                            p: {
                              xs: '40px 30px',
                              sm: '40px 40px',
                              md: '40px 16%',
                              lg: '80px 16% 40px',
                            }
                          }}
                        >
                          <Box sx={{ m: 'auto', maxWidth: '62.5rem' }}>
                            <Box
                              component='img'
                              className='gallery__photo'
                              src={
                                release &&
                                release.photo &&
                                release.photo.cdnOriginalUrl
                              }
                              alt=''
                              sx={{ width: '100%' }}
                            />
                          </Box>
                        </Box>
                        <Box>
                          {release &&
                            (release.signed === null ||
                              (release.signed !== null &&
                                userStore.role === 'appadmin')) ? (
                            <Box
                              sx={{
                                width: '100%',
                                // maxWidth: '62.25rem',
                                margin: 'auto',
                                background: variables.white,
                                p: {
                                  // lg: '0 4.4% 2.5rem',
                                  md: '2.5rem 16% 2.5rem',
                                  sm: '0.5rem 1.9rem 2.5rem',
                                  xs: '0.5rem 1.9rem 2.5rem'
                                }
                              }}
                            >
                              <Box alignItems='center'>
                                <Box>
                                  <Typography variant='paragraph' component='p'>
                                    May Working Assumptions preserve and use the photograph(s) as described above?
                                  </Typography>
                                </Box>
                                <Grid container alignItems='center' spacing={1} sx={{ mt: { xs: '0px !important' }, pt: { xs: 3, md: 4 } }}>
                                  <Grid item md={6} sm={6} xs={12} sx={{ pt: { xs: '0px !important' } }}>
                                    <Button
                                      variant={
                                        this.state.signed === true || release.signed
                                          ? 'selected-segmented'
                                          : 'segmented'
                                      }
                                      disabled={
                                        userStore.role === 'appadmin' || !isMrActive
                                      }
                                      boldTitle={
                                        <Grid
                                          container
                                          alignItems='center'
                                          spacing={1}
                                        >
                                          <Grid
                                            item
                                            md={10}
                                            xs={10}
                                            sm={10}
                                            sx={{ textAlign: 'left', wordBreak: 'break-word !important' }}
                                          >
                                            <Typography variant='h4' className='table_header'>
                                              Yes, allow Working Assumptions to preserve and use the photograph(s).
                                            </Typography>
                                          </Grid>
                                          {this.state.signed === true ||
                                            release.signed ? (
                                            <Grid item md={2} xs={2} sm={2}>
                                              <CheckCircle
                                                color={`${variables.greenAccent}`}
                                              />
                                            </Grid>
                                          ) : null}
                                        </Grid>
                                      }
                                      onClick={() => this.toggleSign(true)}
                                      sx={{
                                        width: { xs: '97%' },
                                        minHeight: { xs: 120 }
                                      }}
                                    />
                                  </Grid>
                                  <Grid item md={6} sm={6} xs={12} sx={{ pt: { xs: '16px !important', sm: '0px !important' } }}>
                                    <Button
                                      variant={
                                        this.state.signed === false ||
                                          release.signed === false
                                          ? 'selected-segmented'
                                          : 'segmented'
                                      }
                                      disabled={
                                        userStore.role === 'appadmin' || !isMrActive
                                      }
                                      boldTitle={
                                        <Grid
                                          container
                                          alignItems='center'
                                          spacing={1}
                                        >
                                          <Grid
                                            item
                                            md={10}
                                            xs={10}
                                            sm={10}
                                            sx={{ textAlign: 'left', wordBreak: 'break-word !important' }}
                                          >
                                            <Typography variant='h4' className='table_header'>
                                              No, do not allow Working Assumptions to preserve and use the photograph(s).
                                            </Typography>
                                          </Grid>
                                          {this.state.signed === false ||
                                            release.signed === false ? (
                                            <Grid item md={2} xs={2} sm={2}>
                                              <CheckCircle
                                                color={`${variables.greenAccent}`}
                                              />
                                            </Grid>
                                          ) : null}
                                        </Grid>
                                      }
                                      onClick={() => this.toggleSign(false)}
                                      sx={{
                                        width: { xs: '97%' },
                                        minHeight: { xs: 120 }
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                              {this.state.signed ||
                                release.signed &&
                                userStore.role === 'appadmin' ? (
                                <Box>
                                  {(release.isMinor ||
                                    (release.isMinor &&
                                      userStore.role === 'appadmin') ||
                                    (release.isDeceased ||
                                      (release.isDeceased &&
                                        userStore.role === 'appadmin'))) && (
                                      <Box sx={{ mb: 4, mt: { xs: 6, md: 5 } }}>
                                        <Box sx={{ mb: 4 }}>
                                          <Typography variant='h2' component='h2'>
                                            Model Details
                                          </Typography>
                                        </Box>
                                        <Box>
                                          <Box sx={{ mb: 2 }}>
                                            <Typography variant='paragraph' component='p'>
                                              {release.isMinor
                                                ? release.isDeceased
                                                  ? 'What is the name of the child in this photograph?'
                                                  : 'What is the name and age of the child in this photograph?'
                                                : release.isDeceased
                                                  ? 'What is the name of the person in this photograph?'
                                                  : ''}
                                            </Typography>
                                          </Box>
                                          <Grid container spacing={2}>
                                            <Grid item lg={6} xs={12} sx={{ mb: '0px !important' }}>
                                              <FormField sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
                                                formFieldId='outlined-fname'
                                                formFieldFullWidth='false'
                                                formFieldLabel={
                                                  release.isMinor
                                                    ? "Child's First Name"
                                                    : "Model's First Name"
                                                }
                                                formFieldVariant='filled'
                                                formFieldError={
                                                  this.state.errors.modelFirstName ||
                                                  null
                                                }
                                                formFieldClasses={{
                                                  root: 'text-field__outline'
                                                }}
                                                formFieldType='name'
                                                formFieldName='modelFirstName'
                                                onChange={e =>
                                                  this.handleFormChange(e, release)
                                                }
                                                disabled={
                                                  userStore.role === 'appadmin' ||
                                                  !isMrActive
                                                }
                                                formFieldValue={
                                                  this.state.modelFirstName
                                                }
                                              />
                                            </Grid>
                                            <Grid item lg={6} xs={12} sx={{ mt: { xs: 5, lg: 0 }, pt: { xs: '0px !important', lg: '16px !important' } }}>
                                              <FormField sx={{ marginTop: '0px !important', marginBottom: '0px !important', paddingTop: '0px !important' }}
                                                formFieldId='outlined-lname'
                                                formFieldFullWidth='false'
                                                formFieldLabel={
                                                  release.isMinor
                                                    ? "Child's Last Name"
                                                    : "Model's Last Name"
                                                }
                                                formFieldVariant='filled'
                                                formFieldError={
                                                  this.state.errors.modelLastName ||
                                                  null
                                                }
                                                formFieldClasses={{
                                                  root: 'text-field__outline'
                                                }}
                                                formFieldType='name'
                                                formFieldName='modelLastName'
                                                onChange={e =>
                                                  this.handleFormChange(e, release)
                                                }
                                                disabled={
                                                  userStore.role === 'appadmin' ||
                                                  !isMrActive
                                                }
                                                formFieldValue={
                                                  this.state.modelLastName
                                                }
                                              />
                                            </Grid>
                                            {!release.isDeceased ? (
                                              <Grid item lg={6} xs={12} sx={{ mt: { xs: 5 }, pt: { xs: '0px !important' }, pb: { xs: '0px !important' } }}>
                                                <FormSelect
                                                  options={age}
                                                  label="Child's Age"
                                                  placeholder="Child's Age"
                                                  onChange={e =>
                                                    this.handleFormChange(e, release)
                                                  }
                                                  name='minorAge'
                                                  variant='filled'
                                                  value={this.state.minorAge}
                                                  inputProps={{
                                                    'aria-label': 'Without label'
                                                  }}
                                                  fullWidth={true}
                                                  disabled={
                                                    userStore.role === 'appadmin'
                                                  }
                                                />
                                              </Grid>
                                            ) : null}
                                          </Grid>
                                        </Box>
                                      </Box>
                                    )}
                                  <Box sx={{ mt: { xs: 6, md: 8 } }}>
                                    <Box sx={{ mb: 0 }}>
                                      <Typography variant='h2' component='h2'>
                                        {release.isMinor
                                          ? release.isDeceased
                                            ? 'Next of Kin (Your) Details'
                                            : 'Parent or Legal Guardian (Your) Details'
                                          : release.isDeceased
                                            ? 'Next of Kin (Your) Details'
                                            : 'Your Details'}
                                      </Typography>
                                    </Box>
                                    <Box sx={{ pb: '4px' }}>
                                      <Grid container spacing={1}>
                                        <Grid item md={12} sm={12} xs={12} sx={{ pt: '32px !important', pb: 2 }}>
                                          <Typography variant='paragraph' component='p'>
                                            What is your name?
                                          </Typography>
                                        </Grid>
                                        <Grid item lg={6} xs={12} sx={{ pt: '0px !important' }}>
                                          <FormField sx={{ marginTop: '0px !important', marginBottom: '0px !important' }}
                                            formFieldId='outlined-fname'
                                            formFieldFullWidth='false'
                                            formFieldLabel='Your First Name'
                                            formFieldVariant='filled'
                                            formFieldError={
                                              release.isMinor || release.isDeceased
                                                ? this.state.errors.signeeFirstName ||
                                                null
                                                : this.state.errors.modelFirstName ||
                                                null
                                            }
                                            formFieldClasses={{
                                              root: 'text-field__outline'
                                            }}
                                            formFieldType='name'
                                            formFieldName={
                                              release.isMinor || release.isDeceased
                                                ? 'signeeFirstName'
                                                : 'modelFirstName'
                                            }
                                            onChange={e =>
                                              this.handleFormChange(e, release)
                                            }
                                            // formFieldError={this.state.errors.email || null}
                                            disabled={
                                              userStore.role === 'appadmin' ||
                                              !isMrActive
                                            }
                                            formFieldValue={
                                              release.isMinor || release.isDeceased
                                                ? this.state.signeeFirstName
                                                : this.state.modelFirstName
                                            }
                                          />
                                        </Grid>
                                        <Grid item lg={6} xs={12} sx={{ mt: { xs: 5, lg: 0 }, pt: { xs: '0px !important' }, pb: { xs: '0px !important' } }}>
                                          <FormField sx={{ mt: '0px !important', mb: '4px !important' }}
                                            formFieldId='outlined-lname'
                                            formFieldFullWidth='false'
                                            formFieldLabel='Your Last Name'
                                            formFieldVariant='filled'
                                            formFieldError={
                                              release.isMinor || release.isDeceased
                                                ? this.state.errors.signeeLastName ||
                                                null
                                                : this.state.errors.modelLastName ||
                                                null
                                            }
                                            formFieldClasses={{
                                              root: 'text-field__outline'
                                            }}
                                            formFieldType='name'
                                            formFieldName={
                                              release.isMinor || release.isDeceased
                                                ? 'signeeLastName'
                                                : 'modelLastName'
                                            }
                                            onChange={e =>
                                              this.handleFormChange(e, release)
                                            }
                                            // formFieldError={this.state.errors.email || null}
                                            disabled={
                                              userStore.role === 'appadmin' ||
                                              !isMrActive
                                            }
                                            formFieldValue={
                                              release.isMinor || release.isDeceased
                                                ? this.state.signeeLastName
                                                : this.state.modelLastName
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    <Box sx={{ mt: 5 }}>
                                      <Grid container spacing={1}>
                                        <Grid item md={12} sm={12} xs={12} sx={{ pt: { xs: '0px !important' }, pb: { xs: 2 } }}>
                                          <Typography variant='paragraph' component='p' sx={{ pb: 0 }}>
                                            What is your email address?
                                          </Typography>
                                        </Grid>
                                        <Grid item lg={12} sm={12} xs={12} pt={{ xs: '0px !important' }}>
                                          <FormField sx={{ mt: '0px !important', mb: '0px !important' }}
                                            formFieldId='outlined-fname'
                                            formFieldFullWidth='false'
                                            formFieldLabel='Your Email'
                                            formFieldVariant='filled'
                                            formFieldError={
                                              this.state.errors.signeeEmail || null
                                            }
                                            formFieldClasses={{
                                              root: 'text-field__outline'
                                            }}
                                            formFieldType='name'
                                            formFieldName='signeeEmail'
                                            onChange={e =>
                                              this.handleFormChange(e, release)
                                            }
                                            // formFieldError={this.state.errors.email || null}
                                            disabled={
                                              userStore.role === 'appadmin' ||
                                              !isMrActive
                                            }
                                            formFieldValue={this.state.signeeEmail}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Box>
                                  <Box sx={{ mt: 5 }}>
                                    <Typography
                                      variant='paragraph'
                                      sx={{
                                        mb: 5
                                      }}
                                      component='p'
                                    >
                                      We ask for your contact information so that,
                                      should the case ever arise that we would like to
                                      contact you regarding the photograph, we are
                                      able to do so. Working Assumptions protects your
                                      privacy. To learn more about how we do that,
                                      visit{' '}
                                      <a href='https://waed.org/privacy-policy'>
                                        <Typography variant='link' className='large'>waed.org/privacy-policy</Typography>
                                      </a>
                                      .
                                    </Typography>
                                    <Box>
                                      <FormCheckbox
                                        label={
                                          <>
                                            Please send me updates about the{' '}
                                            <i>wrkxfmly</i> assignment and Working
                                            Assumptions education programs.
                                          </>
                                        }
                                        color='primary'
                                        name='wantsUpdates'
                                        disabled={
                                          userStore.role === 'appadmin' || !isMrActive
                                        }
                                        checked={
                                          release.wantsUpdates &&
                                            userStore.role === 'appadmin'
                                            ? release.wantsUpdates
                                            : this.state.wantsUpdates
                                        }
                                        onChange={this.handleFormChangeCheck}
                                      />
                                    </Box>
                                  </Box>
                                </Box>
                              ) : null}
                                {this.state.signed === false && (
                                  <Typography variant="paragraph" component="p" my={{ xs: 5 }}>
                                    If you choose not to give permission, the student will still receive the award. Without permission, their work will not be shared or made available for study, reproduction or exhibition, but it will remain in the <i>wrkxfmly</i> web application and digital research archive visible only to individuals connected to Working Assumptions. You can find out more about the assignment by visiting <a href='http://waed.org/' target='_blank' rel="noreferrer">
                                      <Typography variant='link' className='large'>Working Assumptions Education</Typography>
                                    </a>.
                                  </Typography>
                                )}
                              <Box
                                sx={{
                                  mt: {
                                    xs: this.state.signed ||
                                      release.signed ||
                                      userStore.role === 'appadmin'
                                      ? 2
                                      : 3.2,
                                    lg: this.state.signed ||
                                      release.signed ||
                                      userStore.role === 'appadmin'
                                      ? 2
                                      : 5
                                  },
                                  mb: 5
                                }}
                              >
                                <FormCheckbox
                                  label={
                                    <Fragment>
                                      {this.state.signed || this.state.signed === null
                                        ? 'I accept the terms of the'
                                        : 'I have read and acknowledge the terms of the'}{' '}
                                      <span
                                        className='model-release__link'
                                        onClick={e => {
                                          e.preventDefault()
                                          this.toggleShowModelReleaseModal()
                                        }}
                                      >
                                        <Typography variant='link' className='large'>Model Release Agreement</Typography>
                                      </span>
                                      .
                                    </Fragment>
                                  }
                                  disabled={
                                    userStore.role === 'appadmin' ||
                                    this.state.signed === null ||
                                    !isMrActive
                                  }
                                  checked={
                                   ( (userStore.role === 'appadmin' &&
                                      release.signed) ||
                                    this.state.termsAccepted) || (!this.state.termsAccepted && release.signed === false)
                                  }
                                  color='primary'
                                  name='termsAccepted'
                                  onChange={this.handleFormChangeCheck}
                                />
                              </Box>
                              {isMrActive ? (
                                <Box>
                                  <Button
                                    variant='contained'
                                    type='submit'
                                    disabled={
                                      this.state.disableButton ||
                                      !this.isFormFilled(release)
                                    }
                                    title='Submit Form'
                                    onClick={e => {
                                      this.handleSubmit(e, onMutate)
                                    }}
                                    sx={{
                                      width: { xs: '100%', sm: '50%', md: 'auto' }
                                    }}
                                  />
                                </Box>
                              ) : null}
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                    </Fragment>
                  )}
                </Mutation>
              )
            }
          }}
        </Query>
      </Layout>
    )
  }
}

export default inject('uiStore', 'userStore')(observer(withRouter(ModelRelease)))