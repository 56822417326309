import React, { Component, Fragment } from 'react'
import Button from '../elements/Button.js'
import { gql } from '@apollo/client'
import Dropzone from 'react-dropzone'
import { DirectUpload } from 'activestorage'
import { Mutation } from '@apollo/client/react/components'
import { Box, Typography } from '@mui/material'

const UPSERT_PHOTO_MUTATION = gql`
  mutation ReplacePhoto($fileSignedId: String!, $projectId: ID, $photoId: ID) {
    replacePhoto(fileSignedId: $fileSignedId, projectId: $projectId, photoId: $photoId) {
      id
    }
  }
`

class ReplacePhotoUploader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMessage: false,
      message:'Photo sucessfully replaced',
      messageType:'success'
    }
  }
  onDrop = (files, onMutate, type) => {
    const filteredImages = [];
    let counter = 0;

    Array.from(files).forEach(file => {
      const image = new Image();
      image.addEventListener('load', () => {
          if (image.width < 800 ) {
            filteredImages.push(file)
          }
          counter+=1
          if(counter === files.length) {
            if(filteredImages.length > 0) {
              this.updateMessage(true, `One or more photographs cannot be uploaded because the file dimensions are too small. Please upload files with a minimum width of 800px.`, 'error')
            } else {
              this.handelOnDropImages(files, onMutate, type)
            }
          }
      })
      image.src = URL.createObjectURL(file)
    })
  }
  updateMessage = (show, message, type) => {
    this.setState({
      showMessage: show,
      message : message,
      messageType: type
    })
    setTimeout(() => this.setState({
      showMessage: false,
      message: null,
      messageType: null
    }), 5000)
  }
  handelOnDropImages = (files, onMutate, type) => {
    Array.from(files).forEach(file =>
      this.uploadFile(
        file,
        onMutate,
        parseInt(this.props.photo.project.id),
        parseInt(this.props.photo.id),
        this.props.refetchQueries,
        type
      )
    )
  }
  uploadFile = (file, onMutate, projectId, photoId, refetchQueries, type) => {
    // TODO: Make this URL not static
    const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
    const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'photo')

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        const reader = new FileReader()

        reader.addEventListener(
          'load',
          e => {
            const res = e.target.result
            this.props.updateImage && this.props.updateImage(res, photoId)
          },
          false
        )
        if (file) {
          reader.readAsDataURL(file)
        }

        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        onMutate({
          variables: {
            fileSignedId: blob.signed_id,
            projectId: projectId,
            photoId: photoId
          },
          refetchQueries: refetchQueries
        })

        if (this.props.refetchPhoto) this.props.refetchPhoto()
      }
      const toastMessage = 'Photo sucessfully replaced'
      this.updateMessage(true, toastMessage, 'success')
      this.props.showToast && this.props.showToast('success', toastMessage)
    })
  }

  render() {
    const { photo } = this.props

    const megabyteSize =
      photo && photo.exifData
        ? photo.exifData.size && (photo.exifData.size / 1000000).toFixed(2)
        : ''
    const filename = photo && photo.filename

    return (
      <Box component='div'>
        <Mutation mutation={UPSERT_PHOTO_MUTATION}
        onCompleted={(data)=>{
          if (this.props.refetchPhoto) this.props.refetchPhoto()
          if (this.props.replacePhotoToast) this.props.replacePhotoToast()
        }}>
          {(onMutate, { data }) => (
            <Dropzone
              photos={this.props.photos}
              onDrop={files => this.onDrop(files, onMutate, 'replacePhoto')}
              accept={('image/jpeg')}
            >
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <Fragment>
                    {this.props.photo ? (
                      <Box component='div' {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Box component='div' sx={{mt: {xs: 3}}}>
                          <Typography variant='paragraph' component='p'>
                            Use the highest resolution JPEG files that you can.
                          </Typography>

                          <Typography variant='paragraph' component='p'>
                            Files 2000px wide or larger work best.
                          </Typography>
                        </Box>
                        {/* {isDragActive ? ( */}
                          <Box
                            component='div'
                            sx= {{
                              display: {xs: 'grid', sm: 'flex'},
                              textAlign: 'left',
                              gap: {xs: 2, md: 3},
                              mt: {xs: 2, sm: 4},
                              alignItems: {sm: 'baseline'}
                            }}
                          >
                            <Button title='Replace Photograph' onClick={(e) => {}}/>
                            <Typography variant='note' component='p'>
                              {filename} {megabyteSize && `(${megabyteSize} MB)`}
                            </Typography>
                          </Box>
                        {/* ) : (
                          <Box component='div'>
                            <Button title='Replace Photograph' onClick={(e) => {}}/>
                            <Typography variant='note' component='p'>
                              {filename} {megabyteSize && `(${megabyteSize} MB)`}
                            </Typography>
                          </Box>
                        )} */}
                        {this.state.showMessage ? <Box component='div' className={this.state.messageType === 'error' ? 'student-list__alert--warning' : 'student-list__alert--success'}>{this.state.message}</Box>: null}
                      </Box>
                    ) : null}
                  </Fragment>
                )
              }}
            </Dropzone>
          )}
        </Mutation>
      </Box>
    )
  }
}

export default ReplacePhotoUploader
