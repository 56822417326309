import { Fragment } from 'react'
import { Grid, Typography } from '@mui/material'
import ResourceLink from '../molecules/ResourceLink'

function ResourceList(props) {
  const { resources } = props;

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ paddingX: {xs: 3, sm: 6, md: 7}, mt: {xs: 2, sm: 3, md: 4, lg: 8}, pb: {xs: 10}, backgroundColor: 'rgb(247, 247, 247)' }}>
      {resources.length > 0 &&
        resources.map((resource) => {
          if (resource.fields['Show In Resources Tab']) {
            return (
              <Grid item xs={12} sm={6} md={4} key={resource.id}>
                <ResourceLink
                  resourceLinkTitle={resource.fields.Name}
                  resourceImage={resource.fields.Icon}
                  resourceId={resource.id}
                />
              </Grid>
            );
          }
          return null;
        })}
      <Grid item xs={12} sx={{ mt: {xs: 1, md: 2, lg: 5} }} maxWidth={{ lg: '70%' }}>
        <Typography variant="h2" className="strong" sx={{ fontSize: { md: '1.5rem' } }}>
          Get Support
        </Typography>
        <Typography variant="paragraph" component="p" sx={{ fontSize: { md: '1.125rem', xs: '1.25rem' }, mt: 2 }}>
          If you have questions about anything that is not covered above, reach out to{' '}
          <a href="mailto:education@workingassumptions.org">education@workingassumptions.org</a>. We're here to help.
        </Typography>
      </Grid>
    </Grid>
    </Fragment>
  );
}

export default ResourceList
