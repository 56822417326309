import React, { Component, Fragment } from 'react'
import Button from '../elements/Button.js'
import { Mutation } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import { ChevronDown } from 'react-feather'
import { observer, inject } from 'mobx-react'
import FormFieldMultiline from '../elements/FormFieldMultiline.js'
import moment from 'moment'
import DeleteCommentModal from './DeleteCommentModal.js'
import { orderEntities } from '../../utils/commonFunctions.js'
import { Typography } from '@mui/material'

const ADD_COMMENT_MUTATION = gql`
  mutation AddComment($photoId: ID!, $body: String!) {
    addComment(photoId: $photoId, body: $body) {
      id
    }
  }
`

class PhotoComments extends Component {
  constructor(props) {
    super(props)
    // this.inputRef = React.createRef()
    this.state = {
      showComments: false,
      commentsArr: [...orderEntities(this.props.comments) || []],
      showDeletComment: false,
      deleteComment: null
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleMutate = this.handleMutate.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.photoId !== this.props.photoId) {
      this.setState({
        ...this.state,
        commentsArr: [...orderEntities(nextProps.comments)],
        body: ''
      })
      // if (this.inputRef.current) this.inputRef.current.value = ''
    } else if(JSON.stringify(nextProps.comments) !== JSON.stringify(this.props.comments)) {
      this.setState({
        ...this.state,
        commentsArr: [...orderEntities(nextProps.comments)],
        body: ''
      })
    }
  }

  handleMutate(onMutate) {
    if (!this.state.body || this.state.body === '') return

    const { userStore } = this.props
    const { firstName, lastName, id } = userStore
    const newComment = {
      body: this.state.body,
      commenterName: `${firstName} ${lastName}`,
      commenterInitials: firstName[0] + lastName[0],
      commenterUserId: id,
      id: `${parseInt(Math.random() * 100000000)}`, // dummy value for cache
      isRandom: true,
      __typename: 'Comment'
    }

    onMutate({
      variables: {
        photoId: this.props.photoId,
        body: this.state.body
      }
    })

    this.setState({
      commentsArr: [newComment, ...this.state.commentsArr],
      body: ''
    })

    // if (this.inputRef.current) this.inputRef.current.value = ''

    // if(this.props.refetchphoto) {
    //   this.props.refetchphoto()
    // }
  }

  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  toggleShowComments = () => {
    this.setState({
      showComments: !this.state.showComments
    })
  }

  removeComment = ( commentId ) => {
    const { commentsArr } = this.state
    const comments = commentsArr.filter(comm => comm.id !== commentId)
    this.setState({
      commentsArr: [...orderEntities(comments)],
      deleteComment: null
    })
    if(this.props.refetchphoto) {
      this.props.refetchphoto()
    }
  }

  render() {
    const { handleFormChange, handleMutate } = this
    const { role, userStore, photo, publicView  } = this.props
    const { id } = userStore

    return (<>
      {
        !publicView && photo && photo.submitted && ['appadmin', 'curator'].includes(role) ?
          <Fragment>
            <Mutation mutation={ADD_COMMENT_MUTATION}
              onCompleted={()=>{
                if(this.props.refetchphoto) {
                  this.props.refetchphoto()
                }
              }}>
              {(onMutate, { data }) => (
                <div className='curator-comments'>
                  <div className='curator-comments__header' onClick={() => this.toggleShowComments()}>
                    <Typography variant="h2">
                      Comments ({(this.state.commentsArr && this.state.commentsArr.length) || '0'})
                    </Typography>
                    <div className='chevron-menu' onClick={() => this.toggleShowComments()}>
                      <ChevronDown />
                    </div>
                  </div>
                  {this.state.showComments === true ? (
                    <Fragment>
                      {this.state.commentsArr &&
                        this.state.commentsArr.map(comment => (
                          <div className='curator-comments__display' key={comment.id}>
                            <div className='curator-comments__display--heading'>
                              <Typography variant="h4" className='semi-strong'>{comment.commenterName}</Typography>{' '}<h4 className='curator-comments__display--heading---date'>{moment(comment.createdAt).format('MMMM D, YYYY')}</h4>
                            </div>
                            <div className='curator-comments__display--comment'>
                              {comment.body}
                            </div>
                            {
                              role === 'appadmin' || ( role === 'curator' && id === comment.commenterUserId ) ?
                              <div>
                                <h4 className='h4--semi-strong curator-comments__display--delete' onClick={(e) => {
                                  e.preventDefault()
                                  this.setState({
                                    showDeletComment: true,
                                    deleteComment: comment.id
                                  })
                                }}>Delete Comment</h4>
                                <DeleteCommentModal
                                  show={this.state.showDeletComment}
                                  onHide={() => this.setState({showDeletComment: false})}
                                  commentId={this.state.deleteComment}
                                  refetchphoto={this.props.refetchphoto}
                                  removeComment = {this.removeComment}
                                  comments = {this.state.commentsArr}
                                />
                              </div>
                              : null
                            }
                          </div>
                        ))}
                      <br />
                      <div className='curator-comments__description' />
                      <div className='curator-comments__form-field'>
                        <FormFieldMultiline
                          formFieldId='enter-comment'
                          formFieldPlaceholder='Add a Comment'
                          formFieldVariant='filled'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldName='body'
                          formFieldValue={this.state.body}
                          fullWidth={true}
                          multiline={true}
                          // inputRef={this.inputRef}
                          rows='3'
                          onChange={handleFormChange}
                          onBlur={() => this.props.toggleEditing(false)}
                          onFocus={() => this.props.toggleEditing(true)}
                        />
                      </div>
                      <div className='curator-comments__button'>
                        <Button
                          variant='outlined'
                          title='Post Comment'
                          disabled={!this.state.body}
                          onClick={() => handleMutate(onMutate, 'None')}
                        />
                      </div>
                      <Typography variant="h4">Comments are seen only by curators and <i>wrkxfmly</i> administrators on this page.</Typography>
                    </Fragment>
                  ) : null}
                </div>
              )}
            </Mutation>
          </Fragment>
        : null

      }

      </>
    )
  }
}

export default inject('userStore', 'photoCollectionStore')(observer(withApollo(PhotoComments)))
