import React, { useEffect, useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { observer, inject } from 'mobx-react'
import Navigation from './Navigation.js'
import * as Sentry from '@sentry/browser'
import GATag from '../../utils/GATag.js'
import GlobalLoader from '../elements/GlobalLoader.js'

const requestOptions = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_PAT}`,
  },
}

const CheckAuth = (props) => {
  const { userStore, resourceStore, metadataStore, breadcrumbStore } = props

  const [isCategoriesUpdate, setIsCategoriesUpdate] = useState(false)
  const [isResourcesUpdate, setIsResourcesUpdate] = useState(false)
  const [isMetaUpdate, setIsMetaUpdate] = useState(false)

  useEffect(() => {
    const fetchCategories = async(offset='') => {
      const fullUrl = `${process.env.REACT_APP_AIRTABLE_BASE_URL}${process.env.REACT_APP_AIRTABLE_APP_ID}/Categories?view=Grid%20view${offset !== '' ? `&offset=${offset}` : ''}`
      try {
        const response = await fetch(fullUrl, requestOptions)
        const data = await response.json()
        resourceStore.setCategories(data.records)
        setIsCategoriesUpdate(true)
        if (data.offset) {
          await fetchCategories(data.offset)
        }
      } catch (error) {
        setIsCategoriesUpdate(false)
        console.log('No internet connection found. App is running in offline mode.')
      }
    }
    const fetchResources = async(offset='') => {
      const fullUrl = `${process.env.REACT_APP_AIRTABLE_BASE_URL}${process.env.REACT_APP_AIRTABLE_APP_ID}/Resources?view=Grid%20view${offset !== '' ? `&offset=${offset}` : ''}`
      try {
        const response = await fetch(fullUrl, requestOptions)
        const data = await response.json()
        resourceStore.setResources(data.records)
        setIsResourcesUpdate(true)
        if (data.offset) {
          await fetchResources(data.offset)
        }
      } catch (error) {
        setIsResourcesUpdate(false)
        console.log('No internet connection found. App is running in offline mode.')
      }
    }
    const fetchMetaData = async(offset='') => {
      const fullUrl = `${process.env.REACT_APP_AIRTABLE_BASE_URL}${process.env.REACT_APP_METATAGS_AIRTABLE_TABLE_ID}/Meta%20Content?view=Grid%20view${offset !== '' ? `&offset=${offset}` : ''}`
      try {
        const response = await fetch(fullUrl, requestOptions)
        const data = await response.json()
        metadataStore.setMetadata(data.records)
        setIsMetaUpdate(true)
        if (data.offset) {
          await fetchMetaData(data.offset)
        }
      } catch (error) {
        setIsMetaUpdate(false)
        console.log('No internet connection found. App is running in offline mode.')
      }
    }
    fetchCategories()
    fetchResources()
    fetchMetaData()
  }, [])

  const { loading, error, data } = useQuery(gql`
    query UserStore {
      permaLinkedResources {
        id
        name
        linkToResource
      }
      currentUser {
        id
        firstName
        lastName
        role
        profileId
        email
        phoneNumber
        mobileNumber
        lastSeenAt
        lastSignOutAt
        initials
        photoRatings {
          rating
          photoId
        }
      }
    }
  `, {
    fetchPolicy: 'cache-and-network',
  })

  if (loading) return <p/>
  if (error) return <p>Error checking auth</p>

  if (data?.permaLinkedResources) {
    const resources = data.permaLinkedResources
    userStore.updatePermaLinkedResources(resources)
  }

  if (data?.currentUser) {
    const {
      firstName,
      lastName,
      role,
      id,
      lastSeenAt,
      lastSignOutAt,
      profileId,
      email,
      mobileNumber,
      initials,
    } = data.currentUser
    userStore.setFirstName(firstName)
    userStore.setLastName(lastName)
    userStore.setRole(role)
    userStore.setIsAuthenticated(true)
    userStore.setLastSeenAt(lastSeenAt)
    userStore.setLastSignOutAt(lastSignOutAt)
    userStore.setId(id)
    userStore.setProfileId(profileId)
    userStore.setEmail(email)
    userStore.setInitials(initials)
    userStore.setPhoneNumber(mobileNumber)
    breadcrumbStore.setCurrentUserRole(role)

    GATag.setTracker([{ userId: id }, { dimension1: role }])

    Sentry.configureScope(function (scope) {
      scope.setTag('profile_id', profileId)
      scope.setTag('role', role)
      scope.setUser({
        id: id,
        email: email,
      })
    })
  }

  return (isCategoriesUpdate && isResourcesUpdate && isMetaUpdate) ? <Navigation /> : <GlobalLoader/>
}

export default inject('userStore', 'resourceStore', 'metadataStore', 'breadcrumbStore')(observer(CheckAuth))
