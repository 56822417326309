import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Settings, Trash2 } from 'react-feather'
import DeleteEventModal from './DeleteEventModal.js'
import moment from 'moment'
import DeletePhotoTagModal from './DeletePhotoTagModal.js'
import { Typography } from '@mui/material'

class PhotoTagListRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editPhotoTag:false,
      deletePhotoTag: false
    }
  }

  render() {
    const { photoTag, tagIndex } = this.props
    let modalClose = () => this.setState({ deletePhotoTag: false })
    return (
      <div className='body__section school-list'>
        <Row key={tagIndex}>
          <Col sm={10}>
            <div className='school-list__school-name'>
              <Typography variant="h3" className='semi-strong'>{photoTag.name}</Typography>
            </div>
          </Col>
          <Col sm={2}>
            <div className='events__actions'>
              <Settings onClick={() => this.props.toggleEditEvent(photoTag.id, photoTag.name)} />
              &nbsp;&nbsp;&nbsp;
              <Trash2 onClick={() => this.setState({ deletePhotoTag: true })} />
              <DeletePhotoTagModal
                show={this.state.deletePhotoTag}
                onHide={modalClose}
                eventId={photoTag.id}
                showToast={this.props.showToast}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default PhotoTagListRow
