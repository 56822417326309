
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { Award, Shield, Slash } from 'react-feather'
import EditAwardModal from './EditAwardModal'
import { Typography } from '@mui/material'

const PhotoDetailsAwardSection = (props) => {
  const { photo, publicView, role, refetchPhoto } = props

  const [ showEditAwardModal, setShowEditAwardModal ] = useState(false)
  const [ editAward, setEditAward ] = useState(null)

  const getDesignedAward = (award) => {
    return (
      <>
        <Typography variant="h3" className='semi-strong' key={award.id}>
          {award.awardClass === 'Award of Excellence' ? (
            <div className='award-notification__icon'>
              <Award size={19} />
            </div>
          ) : award.awardClass === 'Honorable Mention' ? (
            <div className='award-notification__icon'>
              <Shield size={19} />
            </div>
          ) : (
            <div className='award-notification__icon'>
              <Slash size={19} />
            </div>
          )}
          {`${award.awardClass} Awarded by: ${award.nominatorName} (${
            award.nominatorType === 'AppAdmin' ? 'Administrator' : 'Curator'
          }) `}
          {award.nominatorType === 'Curator' && role === 'appadmin' ? (
            <span
              className='edit-award__link'
              onClick={() => {
                  setShowEditAwardModal(true)
                  setEditAward(award)
                }
              }
            >
              Edit Award
            </span>
          ) : null}
        </Typography>
        {award.isEdited ? (
          <Typography variant="h4" sx={{ml:"1.6875rem"}}>
            Award type edited by <i>wrkxfmly</i> administrator on{' '}
            {moment(award.editedOn, 'YYYY-MM-DD').format('MMMM D, YYYY')}
          </Typography>
        ) : null}
      </>
    )
  }

  const getFinalAward = (awards) => {
    let aoeAwardsCount = awards.filter(award => award.awardClass === 'Award of Excellence').length
    let hmAwardCount = awards.filter(award => award.awardClass === 'Honorable Mention').length
    return aoeAwardsCount > hmAwardCount
      ? 'Award of Excellence'
      : hmAwardCount > aoeAwardsCount
      ? 'Honorable Mention'
      : aoeAwardsCount === hmAwardCount
      ? 'Award of Excellence'
      : ''
  }


  return (
    <>
      {!publicView && ['appadmin', 'curator'].includes(role) ? (
        <div>
          {['appadmin', 'curator'].includes(role) &&
          photo.awards &&
          photo.awards.length > 0 ? (
            <div className='photo-detail__award-notification'>
              <div>
                <Typography variant="h2">Awards</Typography>
                <div className='awards__list-sction'>
                  {photo.awards.map(award => <Fragment key={award.id}>{getDesignedAward(award)}</Fragment>)}
                </div>
                <div className='awards__final-section'>
                  <Typography variant="h4">
                    The final award for this photograph is the type awarded by the
                    greatest number of people.
                  </Typography>
                  {['appadmin'].includes(role) ? (
                    <Typography variant="h4">
                      If a tie-break is needed after curatorial review, you can break the
                      tie by adding your own award in the header bar.
                    </Typography>
                  ) : (
                    <Typography variant="h4">
                      If a tie-break is needed after curatorial review, the{' '}
                      <i>wrkxfmly</i> administrator can break the tie.
                    </Typography>
                  )}
                  <div className='awards__final-section--result'>
                    <Typography variant="h3" className='semi-strong'>
                      Final Award for This Photograph:{' '}
                      {getFinalAward(photo.awards)}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className='photo-detail__award-notification'>
              <div>
                <Typography variant="h2">Awards</Typography>
                <Typography variant="h4" className='semi-strong'>
                  This photograph has not received any award nominations.
                </Typography>
              </div>
            </div>
          )}
          <EditAwardModal
            show={showEditAwardModal}
            onHide={() => {
                setShowEditAwardModal(false)
                setEditAward(null)
              }
            }
            awardId={editAward ?  editAward.id : ''}
            photoId={photo.id}
            nominatorName={editAward ? editAward.nominatorName : ''}
            awardClass={editAward ? editAward.awardClass : ''}
            refetchPhoto={refetchPhoto}
          />
        </div>
      ) : null}
    </>
  )
}

export default PhotoDetailsAwardSection
