/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react'
import './shortlist.scss'
import { observer, inject } from 'mobx-react'
import { useQuery } from '@apollo/client'
import Loading from '../elements/Loading.js'
import PhotoGridContainer from '../organisms/PhotoGridContainer'
import moment from 'moment'
import NewFullScreenPhotoModal from '../organisms/PhotoDetailsModal/NewFullScreenPhotoModal'
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { withRouter } from '../../utils/customHoc'
import { PAGINATED_SHORTLIST_PHOTO_QUERY } from '../../queries/projectDetailsQueries'
import { ChevronDown } from 'react-feather'
import variables from '../settings/_variables.scss'

const Shortlist = props => {
  const {
    userStore,
    projectId,
    photosStore,
  } = props
  const { role } = userStore
  const [shortListedCount, setShortListedCount] = useState(0)
  const [shortListedPhotos, setShortListedPhotos] = useState([])
  const [selectedPhotoId, setSelectedPhotoId] = useState(-1)
  const [showPhotoModal, setShowPhotoModal] = useState(false)
  const [modalFadeOut, setModalFadeOut] = useState(false)
  const [endCursor, setEndCursor] = useState('')
  const [hasNextPage, setHasNextPage] = useState(false)

  const {
    loading: shortListPhotosLoading,
    data: shortListPhotosData,
    error: shortListPhotosError,
    refetch: shortListPhotosRefetch,
    fetchMore: shortListPhotosFetchMore
  } = useQuery(PAGINATED_SHORTLIST_PHOTO_QUERY, {
    cacheTime:0,
    fetchPolicy: 'network-only',
    variables: {
      projectId: projectId,
      isShortListed: true,
      cursor: endCursor
    },
    onCompleted(data) {
      setShortListedCount(data.finalPhotosCount)
      setShortListedPhotos(data.projectPhotosConnection.edges.map(edge => edge.node))
      setEndCursor(data.projectPhotosConnection.pageInfo.endCursor)
      setHasNextPage(data.projectPhotosConnection.pageInfo.hasNextPage)
      const getIds = data.getAllLibraryPhotos.map(photo => photo.id)
      photosStore.setPhotoStore('shortlist', [...getIds])
      props.setRefetchAllSection(false)
      // props.refetchProject()
    }
  })

  const fetchMoreShortListedPhotos = () => {
    if(!hasNextPage) return []
    shortListPhotosFetchMore({
      variables: {
        after: endCursor
      }
    })
  }

  const thumbnailOnClick = photo => {
    setSelectedPhotoId(photo.id)
    setShowPhotoModal(true)
  }


  useEffect(() => {
    const handleScroll = e => {
      const elem = e.target.scrollingElement
      const vh = elem.scrollHeight
      const currVh = elem.scrollTop
      const threshold = vh / 4 + 200
      if (currVh > threshold) {
        fetchMoreShortListedPhotos()
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)

  }, [hasNextPage])

  useEffect(() => {
    if (props.refetchData) {
      shortListPhotosFetchMore({
        variables:{
          cursor:''
        }
      })
    }
  }, [props.refetchData])


  const updateState = async (photoId = -1) => {
    let photo
    if (photoId > 0) {
      photo = shortListedPhotos.find(photo => photo.id === photoId)
    }

    setShortListedCount(shortListedCount - 1)
    setShortListedPhotos([...shortListedPhotos.filter(photo => photo.id !== photoId)])
    // shortListPhotosFetchMore({
    //   variables:{
    //     cursor: ''
    //   }
    // })
    props.setRefetchAllSection(true)
  }

  return (
    <Box sx={{px:{xs: 2, md: 4}, py: {xs: '16px !important', md: 2}, background: `${variables.white}`}}>
      <Accordion classes={{root: 'shortlist__accordion--root'}} defaultExpanded={true} disableGutters>
        <AccordionSummary
          expandIcon={<ChevronDown size={40} color={`${variables.darkerGrey}`}/>}
          sx={{'.MuiAccordionSummary-expandIconWrapper': {
            position: 'absolute',
            top: 1,
            right: 0
          }}}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h2"className='strong' sx={{width: '90%'}} >
            {`${props.shortlistHeader} (${shortListedCount})`}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Box sx={{mt: 1.5, mb:'0px !important'}}>
              <Typography
                variant="paragraph"
                component="p"
              >
                {props.currentTab !== 'photographs' ? (
                  ['student'].includes(role) ? (
                    <>
                      These are the photographs you are thinking about submitting to Working
                      Assumptions.
                      <br />
                      You must add titles and captions to these photographs before you submit.
                    </>
                  ) : (
                    <>
                      These are the photographs that the student is thinking about submitting to
                      Working Assumptions.
                      <br />
                      Each final photograph must have a title and a caption.
                    </>
                  )
                ) : ['student'].includes(role) ? (
                  !props.isSubmitted ? (
                    <>
                      Because you did not submit your project by the assignment submission due date,
                      these photographs were not submitted to Working Assumptions.
                    </>
                  ) : (
                    <>
                      You submitted these photographs to Working Assumptions on{' '}
                      {moment(props.submittedOn).format('LL')}.
                    </>
                  )
                ) : !props.isSubmitted ? (
                  <>
                    Because the student did not submit their project by the assignment submission due
                    date, these photographs were not submitted to Working Assumptions.
                  </>
                ) : (
                  <>
                    The student submitted these photographs to Working Assumptions on{' '}
                    {moment(props.submittedOn).format('LL')}.
                  </>
                )}
              </Typography>
            </Box>
            <Box sx={{mt: 4}}>
              {!shortListPhotosLoading || shortListedPhotos.length > 0 ? (
                <PhotoGridContainer
                  photos={shortListedPhotos}
                  showToast={props.showToast}
                  renderAsBackgroundImage={false}
                  letterBoxType={true}
                  thumbnailOnClick={thumbnailOnClick}
                  updateState={updateState}
                  isCohortEnded={props.isCohortEnded}
                  refetchProject={props.refetchProject}
                  // updateParentGallery={this.updateParentGallery}
                  isShortListSection={true}
                  setRefetchAllSection={props.setRefetchAllSection}
                  refetchData={props.refetchAll}
                />
              ) : (
                <Loading />
              )}
            </Box>
            {selectedPhotoId !== -1 ? (
              <NewFullScreenPhotoModal
                show={showPhotoModal}
                closeModal={() => {
                  setModalFadeOut(true)
                  setTimeout(() => {
                    setShowPhotoModal(false)
                    setModalFadeOut(false)
                    setSelectedPhotoId(-1)
                    // shortListPhotosFetchMore({
                    //   variables: {
                    //     cursor: ''
                    //   }
                    // })
                  }, 500)
                }}
                photoId={selectedPhotoId}
                isModal={true}
                placeholderDark={true}
                showToast={props.showToast}
                totalCount={shortListedCount}
                componentCallFrom='shortlist'
                toggleParentModalState={(pushState = true, shouldRefetch = false) => {
                  if (pushState) {
                    window.history.pushState(null, '', props.backToPageStore.activePage)
                  }
                  setModalFadeOut(true)
                  setTimeout(() => {
                    setShowPhotoModal(false)
                    setSelectedPhotoId(-1)
                    setModalFadeOut(false)
                  }, 500)
                }}
                photoCurrentIndex={shortListedPhotos.findIndex(elem => elem.id === selectedPhotoId)}
                animation={false}
                className={`animated ${
                  showPhotoModal && !modalFadeOut ? 'fadeInUpBig' : 'fadeOutDownBig'
                }`}
                student={props.student}
              />
            ) : null}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default inject('photosStore', 'userStore', 'backToPageStore')(
  observer(withRouter(Shortlist))
)