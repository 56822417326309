import React, { Fragment, useState } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import GATag from '../../utils/GATag'
import { Typography, Box, Grid } from '@mui/material'
import BaseModal from '../template/BaseModal'
import { useMutation } from '@apollo/client'
import { SEND_SUMMARY_MUTATION } from '../../queries/reviewQueries'
import { Spinner } from 'react-bootstrap'

const SendSummaryModal = props => {
  const { summaryType, cohortId, photoIds } = props
  const [isProcessing, setIsProcessing] = useState(false)

  const [sendSummaryMutation] = useMutation(SEND_SUMMARY_MUTATION, {
    variables: {
      cohortId: cohortId,
      photoIds: photoIds,
      summaryType: summaryType
    },
    onCompleted: () => {
      console.log('in Complete')
      props?.setRefetchData?.(true)
      props?.showToast?.('success', 'Review summary sent.')
      setIsProcessing(false)
      handleClose()
    }
  })

  const handleClick = e => {
    setIsProcessing(true)
    e.preventDefault()
    sendSummaryMutation()
    if (summaryType !== 'online') {
      GATag.setEvent({
        category: 'class-review',
        action: 'sent-offline',
        label: 'class review',
        value: 1
      })
    }
  }

  const handleClose = () => {
    props?.onHide?.()
  }

  return (
    <BaseModal
      {...props}
      show={props.show}
      value={props.value}
      open={props.show}
      onClose={handleClose}
      onHide={handleClose}
    >
      <BaseModal.BaseModalHeader>
        {summaryType === 'online'
          ? 'Send Review Summary'
          : 'Mark Review Summary as Sent Outside Web App'}
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Box sx={{ pb: '0px !important' }} className='invite-student__description'>
          {summaryType === 'online' ? (
            <Fragment>
              <Typography
                variant='paragraph'
                sx={{
                  mb: '1rem'
                }}
                component='p'
              >
                Be sure that you have processed all awarded photographs before sending the review
                summary.{' '}
              </Typography>
              <Typography variant='paragraph' component='p'>
                Reminder: all students, the teacher, and the teaching artist for this class will
                receive the award results.
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Typography
                variant='paragraph'
                sx={{
                  mb: '1rem'
                }}
                component='p'
              >
                Please confirm that the review summary for this class was sent manually (outside of
                the web app) to students and teachers.
              </Typography>
              <Typography variant='paragraph' component='p'>
                Reminder: By marking the summary as ‘Sent’, review results will become visible to
                students, teachers, and teaching artists upon their next log-in.
              </Typography>
            </Fragment>
          )}
        </Box>
        <BaseModal.BaseModalFooter>
          <Box
            sx={{ padding: '24px 0 0', marginTop: '0px', alignContent: 'center' }}
            className='send-message__button'
          >
            <Grid
              container
              direction='row'
              sx={{
                justifyContent: { xs: 'center', sm: 'end' },
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant='contained'
                  type='Submit'
                  title={
                    isProcessing ? (
                      <Typography variant='span' className='icon-text-span'>
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />{' '}
                        Sending...
                      </Typography>
                    ) : summaryType === 'online' ? (
                      'Send Review Summary'
                    ) : (
                      'Mark Summary as Sent'
                    )
                  }
                  disabled={isProcessing}
                  onClick={handleClick}
                  sx={{ width: { xs: '100%', sm: '100%' } }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ padding: { xs: '24px 0 0', sm: '16px 0 0', md: '12px 0 0' } }}
              >
                <Typography
                  sx={{
                    mr: { sm: 2 },
                    display: { xs: 'flex' },
                    justifyContent: { xs: 'center', sm: 'right' }
                  }}
                  variant='h4'
                  className='small_size_semi_bold link'
                  onClick={handleClose}
                >
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </BaseModal.BaseModalFooter>
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

// class SendSummaryModal extends Component {
//   constructor(props) {
//     super(props)
//     this.handleClick = this.handleClick.bind(this)
//   }

//   handleClick(e, onMutate) {
//     e.preventDefault()
//     onMutate({
//       variables: {
//         cohortId: this.props.cohortId,
//         photoIds: this.props.photoIds,
//         summaryType: this.props.summaryType
//       },
//       refetchQueries: [`ClassReview`]
//     })
//     if (this.props.summaryType !== 'online') {
//       // window.ga('send', 'event', 'class-review', 'sent-offline', 1)
//       GATag.setEvent({
//         category: 'class-review',
//         action: 'sent-offline',
//         label: 'class review',
//         value: 1
//       })
//     }
//     this.props.onHide()
//     // this.props.toggleBtnStates(this.props.type)
//   }
//   render() {
//     const { summaryType } = this.props
//     const handleClose = () => {
//       this.setState({ show: false })
//       this.props.onHide(false)
//     }
//     return (
//       <BaseModal
//         {...this.props}
//         show={this.props.show}
//         value={this.props.value}
//         open={this.props.show}
//         onClose={handleClose}
//         onHide={this.props.onHide}
//       >
//         <BaseModal.BaseModalHeader>
//           {summaryType === 'online'
//             ? 'Send Review Summary'
//             : 'Mark Review Summary as Sent Outside Web App'}
//         </BaseModal.BaseModalHeader>
//         <BaseModal.BaseModalBody>
//           <Box sx={{ pb: '0px !important' }} className='invite-student__description'>
//             {summaryType === 'online' ? (
//               <Fragment>
//                 <Typography
//                   variant='paragraph'
//                   sx={{
//                     mb: '1rem'
//                   }}
//                   component='p'
//                 >
//                   Be sure that you have processed all awarded photographs before sending the review
//                   summary.{' '}
//                 </Typography>
//                 <Typography variant='paragraph' component='p'>
//                   Reminder: all students, the teacher, and the teaching artist for this class will
//                   receive the award results.
//                 </Typography>
//               </Fragment>
//             ) : (
//               <Fragment>
//                 <Typography
//                   variant='paragraph'
//                   sx={{
//                     mb: '1rem'
//                   }}
//                   component='p'
//                 >
//                   Please confirm that the review summary for this class was sent manually (outside
//                   of the web app) to students and teachers.
//                 </Typography>
//                 <Typography variant='paragraph' component='p'>
//                   Reminder: By marking the summary as ‘Sent’, review results will become visible to
//                   students, teachers, and teaching artists upon their next log-in.
//                 </Typography>
//               </Fragment>
//             )}
//           </Box>
//           <BaseModal.BaseModalFooter>
//             <Box
//               sx={{ padding: '24px 0 0', marginTop: '0px', alignContent: 'center' }}
//               className='send-message__button'
//             >
//               <Grid
//                 container
//                 direction='row'
//                 sx={{
//                   justifyContent: { xs: 'center', sm: 'end' },
//                   display: 'flex',
//                   flexDirection: 'row-reverse'
//                 }}
//               >
//                 <Grid item xs={12} sm={6} md={4}>
//                   <Button
//                     variant='contained'
//                     type='Submit'
//                     title={
//                       summaryType === 'online' ? 'Send Review Summary' : 'Mark Summary as Sent'
//                     }
//                     disabled={false}
//                     onClick={e => this.handleClick(e, this.props.onMutate)}
//                     sx={{ width: { xs: '100%', sm: '100%' } }}
//                   />
//                 </Grid>
//                 <Grid
//                   item
//                   xs={12}
//                   sm={6}
//                   md={4}
//                   sx={{ padding: { xs: '24px 0 0', sm: '16px 0 0', md: '12px 0 0' } }}
//                 >
//                   <Typography
//                     sx={{
//                       mr: { sm: 2 },
//                       display: { xs: 'flex' },
//                       justifyContent: { xs: 'center', sm: 'right' }
//                     }}
//                     variant='h4'
//                     className='small_size_semi_bold link'
//                     onClick={() => this.props.onHide()}
//                   >
//                     Cancel
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </Box>
//           </BaseModal.BaseModalFooter>
//         </BaseModal.BaseModalBody>
//       </BaseModal>
//     )
//   }
// }

export default SendSummaryModal
