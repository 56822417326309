import { Box, Grid, Typography } from '@mui/material'
import { Link, MessageSquare } from 'react-feather'
import PhotoActionBar from '../../../molecules/PhotoActionBar'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Container from '@mui/material/Container'
import DateUtils from '../../../../utils/DateUtils'

const ModalHeader = props => {
  const { role, publicView, closeModal, photoDetails, awardChipDisabled, toast, isModal } = props

  return (
    <>
      <AppBar
        position={isModal ? 'fixed' : 'relative'}
        sx={{
          color: 'inherit',
          backgroundColor: '#fff',
          borderBottom: '1px solid #dee2e6',
          boxShadow: 'none'
        }}
      >
        {toast}
        <Container maxWidth={false}>
          <Toolbar px={0}>
            <Grid container alignItems={'center'}>
              <Grid item md={4} sm={3} xs={3}>
                {isModal ? (
                  <Box
                    sx={{
                      color: 'rgb(40, 152, 212)',
                      cursor: 'pointer'
                    }}
                    onClick={() => closeModal()}
                  >
                    <IconButton
                      edge='start'
                      color='inherit'
                      aria-label='close'
                      sx={{
                        svg: {
                          verticalAlign: 'middle',
                          fontSize: 16
                        },
                        borderRadius: '4px'
                      }}
                    >
                      <CloseIcon color='primary' />{' '}
                      <Typography
                        variant={'link'}
                        sx={{ display: { xs: 'none', md: 'block' }, ml: { md: 0.5, lg: 1 } }}
                      >
                        Close Presentation
                      </Typography>
                    </IconButton>
                  </Box>
                ) : null}
              </Grid>
              <Grid item md={4} sm={5} xs={6} sx={{ my: 'auto' }}>
                {!publicView &&
                (photoDetails &&
                  ((['curator'].includes(role) && photoDetails.project.submitted) ||
                    (['appadmin'].includes(role) &&
                      photoDetails.project.submitted &&
                      photoDetails.project.cohort.reviewed))) ? (
                  <PhotoActionBar
                    photo={photoDetails}
                    role={role}
                    photoAwards={photoDetails.awards}
                    forStatusStack={false}
                    setShouldRefetchParent={props.setShouldRefetchParent}
                    updateParentGallery={props.updateParentGallery}
                    disabled={awardChipDisabled}
                    refetch={props.refetch}
                  />
                ) : null}
              </Grid>
              {!publicView && (
                <Grid item md={4} xs={3} sm={4}>
                  <Box sx={{ display: 'flex', float: 'right' }}>
                    <Box
                      component='div'
                      sx={{
                        mr: !publicView ? 1 : -2,
                        alignSelf: 'center',
                        borderRight: {
                          md:
                            photoDetails &&
                            photoDetails.photoComments &&
                            photoDetails.photoComments.length <= 0 &&
                            !publicView
                              ? '1px solid #dee2e6'
                              : 0
                        },
                        pr: {
                          md:
                            photoDetails &&
                            photoDetails.photoComments &&
                            photoDetails.photoComments.length <= 0 &&
                            !publicView
                              ? 1
                              : 0
                        },
                        height: '1.5rem'
                      }}
                    >
                      <Box
                        component={'a'}
                        href={photoDetails ? `/photo-detail/${photoDetails.id}` : null}
                        target='_blank'
                        rel='noreferrer'
                        sx={{ svg: { verticalAlign: 'middle' } }}
                      >
                        <Link size={16} color={'rgba(0, 118, 187, 1)'} />
                      </Box>
                    </Box>
                    {!publicView ? (
                      <>
                        {photoDetails &&
                        photoDetails.photoComments &&
                        photoDetails.photoComments.length > 0 &&
                        ['appadmin', 'curator'].includes(role) ? (
                          <Box
                            component='div'
                            className='fsm-photo-comment-header-section-icon'
                            sx={{
                              borderRight: { xs: 0, md: '1px solid #dee2e6' },
                              paddingRight: { xs: 0, md: 1 }
                            }}
                          >
                            <Box component='div' sx={{ svg: { verticalAlign: 'middle' } }}>
                              <MessageSquare size={16} />
                            </Box>
                          </Box>
                        ) : null}
                        <Box
                          sx={{
                            display: { xs: 'none', md: 'block' },
                            ml: {
                              md:
                                photoDetails &&
                                photoDetails.photoComments &&
                                photoDetails.photoComments.length <= 0
                                  ? 0
                                  : 1
                            }
                          }}
                        >
                          <Typography
                            variant='note'
                            component={'h4'}
                            sx={{ textAlign: 'right', wordBreak: 'break-all' }}
                          >
                            {`${
                              photoDetails && photoDetails.updatedAt
                                ? 'Updated on ' +
                                  DateUtils.createMoment(photoDetails.updatedAt).format('LL')
                                : ''
                            }`}
                          </Typography>
                          <Typography variant='h4' className='semi-strong' textAlign={'right'}>
                            {photoDetails &&
                            photoDetails.exifData &&
                            photoDetails.exifData.hasOwnProperty('width')
                              ? photoDetails.exifData.width + ' x ' + photoDetails.exifData.height
                              : ''}
                          </Typography>
                        </Box>
                      </>
                    ) : null}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  )
}

export default ModalHeader
