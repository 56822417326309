import React, { Component, Fragment } from 'react'
import '../../pages/events.scss'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from '../elements/Button.js'
import Switches from '../elements/Switches.js'
import FormField from '../elements/FormField.js'
import FormFieldMultiline from '../elements/FormFieldMultiline.js'
// import Form from 'react-bootstrap/Form'
import DeleteEventModal from '../molecules/DeleteEventModal.js'
// import { Calendar } from 'react-feather'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import DatePicker from '../elements/DatePicker.js'
import EventPhotoUploader from '../organisms/EventPhotoUploader'
// import { withRouter } from 'react-router-dom'
import Image from 'react-bootstrap/Image'
import { Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc'

const MUTATION = gql`
  mutation CreateEvent(
    $name: String
    $id: ID
    $slug: String
    $eventStart: String
    $eventEnd: String
    $timingNote: String
    $location: String
    $summary: String
    $description: String
    $featuredImagePhotographerName: String
    $featuredImagePhotographTitle: String
    $featuredImagePhotographYear: String
    $published: Boolean
    $fileSignedId: String
  ) {
    createEvent(
      name: $name
      slug: $slug
      eventStart: $eventStart
      eventEnd: $eventEnd
      timingNote: $timingNote
      location: $location
      summary: $summary
      description: $description
      featuredImagePhotographerName: $featuredImagePhotographerName
      featuredImagePhotographTitle: $featuredImagePhotographTitle
      featuredImagePhotographYear: $featuredImagePhotographYear
      published: $published
      fileSignedId: $fileSignedId
      id: $id
    ) {
      event {
        id
      }
      errors {
        path
        message
      }
    }
  }
`

class EventEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: { slug: this.validateSlug(this.props.event.slug) },
      deleteEvent: false
      // continueOne: 'true'
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
    this.updateFileSignedId = this.updateFileSignedId.bind(this)
    this.checkForErrors = this.checkForErrors.bind(this)
    this.handleSlugChange = this.handleSlugChange.bind(this)
    this.validateEventForPublishing = this.validateEventForPublishing.bind(this)
    this.updatePreviewImage = this.updatePreviewImage.bind(this)
    this.togglePublishing = this.togglePublishing.bind(this)
  }

  checkForErrors(data) {
    if (data && data.createEvent && data.createEvent.errors) {
      this.setState({ submitted: false })
      // Note the errors hash keys are snake_case not camelCase because they come back from Ruby directly
      var parsed_errors = data.createEvent.errors.reduce(function(map, obj) {
        map[obj.path] = obj.message
        return map
      }, {})
      if (Object.entries(parsed_errors).length === 0 && parsed_errors.constructor === Object) {
        this.props.history.push('/events')
      }
      this.setState({
        errors: { ...this.state.errors, parsed_errors }
      })
      console.log(parsed_errors)
    }
  }

  validateSlug(slug) {
    const slugPattern = /^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/
    if (!slugPattern.test(slug) && slug) {
      return 'Not a valid slug. Can only contains letters and "-"'
    } else return null
  }
  validateUrl(string) {
    var res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
    )

    return res !== null
  }
  handleSlugChange(e) {
    this.setState({ errors: { slug: this.validateSlug(e.target.value) } })
    this.handleFormChange(e)
  }

  handleFormChange(e) {
    const value = e.target.name === 'published' ? e.target.checked : e.target.value
    this.setState({ [e.target.name]: value })
  }

  updateFileSignedId(fileSignedId) {
    this.setState({ fileSignedId: fileSignedId })
  }
  togglePublishing(e) {
    e.preventDefault()
    e.stopPropagation()
    this.validateEventForPublishing(e)
  }
  handleSubmit(e, onMutate) {
    e.preventDefault()

    this.setState({ submitted: true })
    if (!this.state.submitted) {
      onMutate({
        variables: {
          name: this.state.name,
          slug: this.state.slug,
          eventStart: this.state.eventStart,
          eventEnd: this.state.eventEnd,
          timingNote: this.state.timingNote,
          location: this.state.location,
          summary: this.state.summary,
          description: this.state.description,
          externalLink: this.state.externalLink,
          featuredImagePhotographerName: this.state.featuredImagePhotographerName,
          featuredImagePhotographTitle: this.state.featuredImagePhotographTitle,
          featuredImagePhotographYear: this.state.featuredImagePhotographYear,
          published: this.state.published,
          fileSignedId: this.state.fileSignedId,
          id: this.props.event.id
        },
        refetchQueries: [`EventsQuery`]
      })
    }
  }

  validateEventForPublishing(e) {
    let errors = {}
    if (!this.state.name) {
      errors = {
        ...errors,
        name: 'Please enter an event name before publishing.'
      }
    } else if (this.state.name.length > 40) {
      errors = {
        ...errors,
        name: 'Event name must be shorter than 40 characters.'
      }
    } else {
      errors = { ...errors, name: null }
    }
    if (!this.state.eventStart) {
      errors = {
        ...errors,
        eventStart: 'Please enter an event start date before publishing.'
      }
    } else {
      errors = { ...errors, eventStart: null }
    }
    if (!this.state.timingNote) {
      errors = {
        ...errors,
        timingNote: 'Please enter event time details before publishing.'
      }
    } else {
      errors = { ...errors, timingNote: null }
    }
    if (!this.state.location) {
      errors = {
        ...errors,
        location: 'Please enter event location details before publishing.'
      }
    } else {
      errors = { ...errors, location: null }
    }
    if (!this.state.summary) {
      errors = {
        ...errors,
        summary: 'Please enter a short summary of the event before publishing.'
      }
    } else if (this.state.summary > 200) {
      errors = {
        ...errors,
        summary: 'Event summary must be shorter than 200 characters.'
      }
    } else {
      errors = { ...errors, summary: null }
    }
    if (!this.state.description) {
      errors = {
        ...errors,
        description: 'Please enter a description of the event before publishing.'
      }
    } else {
      errors = { ...errors, description: null }
    }
    if (this.state.externalLink && !this.validateUrl(this.state.externalLink)) {
      errors = {
        ...errors,
        externalLink: 'Please enter a valid URL or leave blank.'
      }
    } else {
      errors = { ...errors, externalLink: null }
    }
    if (Object.keys(errors).filter(key => errors[key] !== null).length > 0) {
      this.setState({ published: false })
    } else this.setState({ published: this.state.published ? false : true })
    this.setState({ errors: { ...this.state.errors, ...errors } })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (typeof prevState.slug === 'undefined') {
      return Object.keys(nextProps.event)
        .map(key => {
          return { [key]: nextProps.event[key] }
        })
        .reduce(function(result, current) {
          return Object.assign(result, current)
        }, {})
    } else return null
  }

  updatePreviewImage(url) {
    this.setState({ linkToFeaturedImage: url })
  }

  render() {
    const { handleFormChange, updateFileSignedId, handleSubmit, handleSlugChange } = this
    let modalClose = () => this.setState({ deleteEvent: false })
    return (
      <Mutation
        mutation={MUTATION}
        onCompleted={data => {
          this.checkForErrors(data)
        }}
      >
        {(onMutate, { data }) => {
          return (
            <Fragment>
              {/* <Hero heroTitle={this.props.event.name} /> */}

              <div className='body__events'>
                <form
                  className='my-account__form-container'
                  noValidate
                  autoComplete='on'
                  onSubmit={() => {
                    alert('real submit')
                  }}
                >
                  <div className='events__form-group'>
                    <Typography variant="h3" className='semi-strong'>Event Name</Typography>
                    <Typography
                      variant="paragraph"
                      component="p"

                    >Names should be written in title case and a maximum of 40 characters.</Typography>
                    <Row>
                      <div className='form-field full-width'>
                        <FormField
                          formFieldId='custom-css-outlined-event-name'
                          formFieldLabel='Event Name'
                          formFieldVariant='filled'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='text'
                          formFieldName='name'
                          formFieldMaxLength='40'
                          formFieldDefaultValue={this.state.name}
                          onChange={handleFormChange}
                          formFieldError={this.state.errors.name || null}
                        />
                      </div>
                    </Row>
                  </div>

                  <div className='events__form-group'>
                    <Typography variant="h3" className='semi-strong'>Event Slug</Typography>
                    <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                    >The unique URL slug for this event.</Typography>
                    <Typography variant="h5">
                      For example: "bowling-green-pop-up-exhibition".
                      <br />
                      Note: Changing this slug will break all existing links to the event!
                    </Typography>
                    <Row>
                      <div className='form-field full-width'>
                        <FormField
                          formFieldId='custom-css-outlined-event-name'
                          formFieldLabel='Event Slug'
                          formFieldVariant='filled'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='text'
                          formFieldName='slug'
                          formFieldMaxLength='40'
                          formFieldDefaultValue={this.state.slug}
                          onChange={handleSlugChange}
                          formFieldError={this.state.errors.slug}
                        />
                      </div>
                    </Row>
                  </div>

                  <div className='events__form-group'>
                    <Typography variant="h3" className='semi-strong'>Date and Time</Typography>
                    <Typography
                      variant="paragraph"

                      component="p"

                    >If the event occurs on a single day, do not select an end date.</Typography>
                    <Row>
                      <Col md='true'>
                        <div className='form-field col--left'>
                          <DatePicker
                            onChange={date =>
                              handleFormChange({
                                target: {
                                  value: date,
                                  name: 'eventStart'
                                }
                              })}
                            value={this.state.eventStart ? new Date(this.state.eventStart) : ''}
                            helperText={this.state.errors.eventStart}
                            error={this.state.errors.eventStart}
                            label='Event Start'
                          />
                        </div>
                      </Col>
                      <Col md='true'>
                        <div className='form-field col--right'>
                          <DatePicker
                            onChange={date =>
                              handleFormChange({
                                target: {
                                  value: date,
                                  name: 'eventEnd'
                                }
                              })}
                            value={this.state.eventEnd ? new Date(this.state.eventEnd) : ''}
                            helperText={this.state.errors.eventEnd}
                            error={this.state.errors.eventEnd}
                            label='Event Start'
                          />
                        </div>
                      </Col>
                    </Row>
                    <br />
                    <Typography
                      variant="paragraph"

                      component="p"

                    >Enter additional details about the event time.</Typography>
                    <Typography variant="h5">For example: "11 AM - 4 PM, Monday to Friday".</Typography>
                    <br />
                    <Row>
                      <div className='form-field full-size'>
                        <FormFieldMultiline
                          formFieldId='event-time'
                          formFieldVariant='filled'
                          formFieldLabel='Event Time'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldPlaceholder='Describe the timing for your event.'
                          formFieldType='text'
                          formFieldName='timingNote'
                          formFieldMargin='auto'
                          onChange={handleFormChange}
                          multiline='true'
                          formFieldDefaultValue={this.state.timingNote}
                          formFieldError={this.state.errors.timingNote || null}
                        />
                      </div>
                    </Row>
                  </div>

                  <div className='events__form-group'>
                    <Typography variant="h3" className='semi-strong'>Location</Typography>
                    <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                    >Enter the full address of the event location.</Typography>
                    <Row>
                      <div className='form-field full-size'>
                        <FormFieldMultiline
                          formFieldId='event-location'
                          formFieldVariant='filled'
                          formFieldLabel='Event Location'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='text'
                          formFieldName='location'
                          formFieldMargin='auto'
                          onChange={handleFormChange}
                          multiline='true'
                          formFieldDefaultValue={this.state.location}
                          formFieldError={this.state.errors.location || null}
                        />
                      </div>
                    </Row>
                  </div>

                  <div className='events__form-group'>
                    <Typography variant="h3" className='semi-strong'>Details</Typography>
                    <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                    >
                      Enter a brief summary of the event (between 150 and 200 characters).
                    </Typography>
                    <Row>
                      <div className='form-field full-size'>
                        <FormFieldMultiline
                          formFieldId='event-summary'
                          formFieldVariant='filled'
                          formFieldLabel='Event Summary'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='text'
                          formFieldName='summary'
                          formFieldMargin='auto'
                          onChange={handleFormChange}
                          multiline='true'
                          formFieldMaxLength='200'
                          formFieldDefaultValue={this.state.summary}
                          formFieldError={this.state.errors.sumary || null}
                        />
                      </div>
                    </Row>
                    <br />
                    <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                    >Enter a description of the event.</Typography>
                    <Row>
                      <div className='form-field full-size'>
                        <FormFieldMultiline
                          formFieldId='event-description'
                          formFieldVariant='filled'
                          formFieldLabel='Event Description'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='text'
                          formFieldName='description'
                          formFieldMargin='auto'
                          onChange={handleFormChange}
                          multiline='true'
                          formFieldDefaultValue={this.state.description}
                          formFieldError={this.state.errors.description || null}
                        />
                      </div>
                    </Row>
                    <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                    >
                      Enter a link to additional information about the event, such as the venue
                      website.
                    </Typography>
                    <Row>
                      <div className='form-field full-size'>
                        <FormField
                          formFieldId='event-external-link'
                          formFieldVariant='filled'
                          formFieldLabel='Event Link (Optional)'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='text'
                          formFieldName='externalLink'
                          formFieldMargin='auto'
                          formFieldError={this.state.errors.externalLink}
                          formFieldDefaultValue={this.state.externalLink}
                          onChange={handleFormChange}
                          multiline='true'
                        />
                      </div>
                    </Row>
                  </div>

                  <div className='events__form-group'>
                    <Typography variant="h3" className='semi-strong'>Featured Image</Typography>
                    <Typography
                      variant="paragraph"
                      component="p"
                    >This image will appear on the event page.</Typography>
                    <Typography variant="h5">Images should be saved as JPGs and be 2500px on the longest edge.</Typography>
                    <br />
                    <Row>
                      <div className='form-field'>
                        <Image src={this.state.linkToFeaturedImage} fluid={true} />
                        <EventPhotoUploader
                          updateFileSignedId={updateFileSignedId}
                          buttonTitle='Replace Image'
                          updatePreview={this.updatePreviewImage}
                        />
                      </div>
                    </Row>
                    <Row>
                      <br />
                      <div className='form-field full-width'>
                        <FormField
                          formFieldId='custom-css-outlined-photographer-name'
                          formFieldLabel='Photographer Name (Optional)'
                          formFieldVariant='filled'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='text'
                          formFieldName='featuredImagePhotographerName'
                          formFieldDefaultValue={this.state.featuredImagePhotographerName}
                          onChange={handleFormChange}
                          //   formFieldError={this.state.errors.eventName || null}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className='form-field full-width'>
                        <FormField
                          formFieldId='custom-css-outlined-photograph-title'
                          formFieldLabel='Photograph Title (Optional)'
                          formFieldVariant='filled'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='text'
                          formFieldName='featuredImagePhotographTitle'
                          formFieldDefaultValue={this.state.featuredImagePhotographTitle}
                          onChange={handleFormChange}
                          //   formFieldError={this.state.errors.eventName || null}
                        />
                      </div>
                    </Row>
                    <Row>
                      <div className='form-field full-width'>
                        <FormField
                          formFieldId='custom-css-outlined-photograph-year'
                          formFieldLabel='Photograph Year (Optional)'
                          formFieldVariant='filled'
                          formFieldClasses={{
                            root: 'text-field__outline'
                          }}
                          formFieldType='number'
                          formFieldName='featuredImagePhotographYear'
                          formFieldDefaultValue={this.state.featuredImagePhotographYear}
                          onChange={handleFormChange}
                          formFieldError={this.state.errors.eventName || null}
                        />
                      </div>
                    </Row>
                  </div>

                  <div className='events__form-group'>
                    <Switches
                      checked={this.state.published}
                      // disabled={this.state.publishable}
                      onClick={this.togglePublishing}
                      name='published'
                      formFieldError={
                        this.state.errors.lengtth > 0
                          ? 'Please resolve errors before publishing.'
                          : null
                      }
                    />
                    <div className='notification-preferences__switch-label'>
                      <Typography variant="h4">Publish Event</Typography>
                    </div>
                  </div>

                  <div className='events__form-group'>
                    <div className='events__form-group--buttons'>
                      <Button
                        variant='contained'
                        type='submit'
                        title='Save Updates'
                        onClick={e => handleSubmit(e, onMutate)}
                      />
                      <Typography variant="h3" className="semi-strong link"
                        onClick={() => this.setState({ deleteEvent: true })}
                      >
                        Delete Event
                      </Typography>
                      <DeleteEventModal
                        show={this.state.deleteEvent}
                        eventId={this.state.id}
                        onHide={modalClose}
                        showToast={this.props.showToast}
                        resource={this.state.targetResource}
                        category={this.props.category}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </Fragment>
          )
        }}
      </Mutation>
    )
  }
}

export default withRouter(EventEditor)
