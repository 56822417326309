import { gql } from '@apollo/client'

export const SCHOOL_DETAIL = gql`
  query SchoolList($id: ID!) {
    school(id: $id) {
      id
      name
      website
      schoolDistrict
      demographicLink
      createdAt
      mailingAddress {
        id
        city
        state
        address1
        address2
        postCode
      }
      schoolType
      teachers {
        id
        userId
        name
        email
        mobileNumber
      }
      facilitators {
        id
        name
        userId
        email
        mobileNumber
      }
      cohorts {
        name
        id
        students {
          id
        }
      }
    }
  }
`

export const SCHOOL_LIST = gql`
  query SchoolList {
    schools {
      id
      name
      teachers {
        name
        id
      }
    }
  }
`
export const PAGINATED_SCHOOL_LIST = gql`
  query PaginatedSchoolList($cursor: String, $search: String) {
    schoolsConnection(first: 24, after: $cursor, search: $search) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          name
          createdAt
          mailingAddress {
            id
            city
            state
          }
          cohorts {
            id
            name
            students {
              id
            }
          }
        }
      }
    }
    schoolCount(search: $search)
  }
`

export const SCHOOL_PROJECTS_DETAIL = gql`
  query SchoolList($id: ID!) {
    school(id: $id) {
      id
      name
      cohorts {
        name
        id
        projects {
          photos {
            isShortListed
            awards {
              awardClass
              id
            }
          }
        }
      }
    }
  }
`