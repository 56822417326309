import React, { Component, Fragment } from 'react'
import './joinclass.scss'
import { observer, inject } from 'mobx-react'
import { Mutation, Query } from '@apollo/client/react/components'
import { Link } from 'react-router-dom'
import { gql } from '@apollo/client'
import Button from '../ui/elements/Button.js'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'
import MainLogo from '../assets/svgs/mainLogo.jsx'
import PagesMetaTags from '../utils/PagesMetaTags'
import { Typography } from '@mui/material'
import { withRouter } from '../utils/customHoc'

const CLASS_INFO_QUERY = gql`
  query JoinClassClassInfo($uuid: String!) {
    cohortInviteInfo(uuid: $uuid) {
      id
      name
      teacher {
        id
        name
      }
      students {
        userId
      }
      school {
        mailingAddress {
          state
          id
        }
        id
        name
      }
    }
  }
`

const ADD_STUDENT_MUTATION = gql`
  mutation AddStudentToCohort($cohortUuid: String!) {
    addStudentToCohort(cohortUuid: $cohortUuid) {
      id
    }
  }
`
class JoinClass extends Component {
  constructor(props) {
    super(props)
    this.handleAddStudent = this.handleAddStudent.bind(this)
  }

  handleAddStudent(e, onMutate) {
    e.preventDefault()
    const {params} = this.props
    onMutate({
      variables: {
        cohortUuid: params.classUuid
      },
      refetchQueries: [`JoinClassClassInfo`]
    })
  }

  render() {
    const { userStore, params } = this.props

    return (
      <Layout>
        <PagesMetaTags index={false}/>
        {userStore.id ? (
          <Query query={CLASS_INFO_QUERY} variables={{ uuid: params.classUuid }}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />
              if (error) return <Typography 
              variant="paragraph"
              component="p"
              >Error loading class info</Typography>
              if (data) {
                const { cohortInviteInfo } = data

                return (
                  <Mutation mutation={ADD_STUDENT_MUTATION}>
                    {(onMutate, { data }) => (
                      <Fragment>
                        {cohortInviteInfo.students.filter(s => s.userId === userStore.id).length >
                        0 ? (
                          <div className='container__body--white'>
                            <div className='body__join-class'>
                              <div className='join-class--centered-logo'>
                                <MainLogo fill='#000' />
                              </div>
                              <div className='join-class__title'>
                                <Typography variant='h1' className='bold'>
                                  Welcome to Working Assumptions Education.
                                </Typography>
                              </div>
                              <div className='join-class__message'>
                                <Typography
                                  variant="paragraph"
                                  component="p"      
                                >You are already registered for this class.</Typography>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className='container__body--white'>
                            <div className='body__join-class'>
                              <div className='join-class--centered-logo'>
                                <MainLogo fill='#000' />
                              </div>
                              <div className='join-class__title'>
                                <Typography variant='h1' className='bold'>
                                  Welcome to Working Assumptions Education.
                                </Typography>
                              </div>
                              <div className='join-class__message'>
                                <Typography                             
                                  variant="paragraph"
                                  component="p"
                                  >
                                  You have been invited to join{' '}
                                  <strong>{cohortInviteInfo.name}</strong> by{' '}
                                  <strong>{cohortInviteInfo.teacher.name}</strong> at{' '}
                                  <strong>{cohortInviteInfo.school.name}</strong>. If these are not
                                  your class details, please contact your teacher.
                                </Typography>
                                <Typography
                                  variant="paragraph"
                                  component="p"      
                                >
                                  To begin your photography assignment for this class, please join
                                  below.
                                </Typography>
                              </div>
                              <div className='join-class__buttons'>
                                <Button
                                  title='Join class'
                                  
                                  onClick={e => {
                                    this.handleAddStudent(e, onMutate)
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Mutation>
                )
              }
            }}
          </Query>
        ) : (
          <div className='container__body--white'>
            <div className='body__join-class'>
              <div className='join-class--centered-logo'>
                <MainLogo fill='#000' />
              </div>
              <div className='join-class__title'>
                <Typography variant='h1' className='bold'>Welcome to Working Assumptions Education.</Typography>
              </div>
              <div className='join-class__message'>
                <Typography 
                  variant="paragraph"
                  sx={{
                    mb:"1rem",
                  }}
                  component="p"            
                >You need an account to join Working Assumptions at this time.</Typography>
              </div>
              <div className='join-class__buttons'>
                <Typography 
                  variant="paragraph"
                  component="p"            
                >Already have an account?</Typography>
                <Typography 
                variant="paragraph"
                  component="p"            
                >
                  <Link to={`/login?class-uuid=${params.classUuid}`}>Log In</Link>
                </Typography>
                <Typography 
                variant="paragraph"
                  component="p"            
                >Need an account?</Typography>
                <Typography 
                variant="paragraph"
                  component="p"            
                >
                  <Link to={'/sign-up/' + params.classUuid}>Sign up</Link>
                </Typography>
              </div>
            </div>
          </div>
        )}
      </Layout>
    )
  }
}

export default inject('userStore')(observer(withRouter(JoinClass)))
