import SkipNextIcon from '@mui/icons-material/SkipNext'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import { Box, Pagination, PaginationItem } from '@mui/material'

const CustomPagination = props => {
  const { totalPages, pageNum, onChange } = props
  const isMobile = window.innerWidth > 600

  return (
    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'right' } }}>
      <Pagination
        sx={{ ...props.sx }}
        count={totalPages}
        page={pageNum}
        size='medium'
        showFirstButton={isMobile}
        showLastButton={isMobile}
        defaultPage={1}
        siblingCount={0}
        boundaryCount={1}
        onChange={(e, page) => {
          onChange(page - 1)
        }}
        renderItem={item => (
          <PaginationItem slots={{ first: SkipPreviousIcon, last: SkipNextIcon }} {...item} />
        )}
      />
    </Box>
  )
}

export default CustomPagination
