import { gql } from '@apollo/client'
import React, { Component } from 'react'
import { withApollo } from '@apollo/client/react/hoc'
import { ChevronRight, ChevronsRight, ChevronLeft, ChevronsLeft } from 'react-feather'
import OverDueCuratorialReviewTableRows from '../elements/OverDueCuratorialReviewTableRows'
import { Box, Grid, Typography } from '@mui/material'

const SEND_TO_CURATOR_MURATION = gql`
  query CuratorRevieOverDueList($batchSize: Int!, $step: Int!) {
    curatorRevieOverDueList(batchSize: $batchSize, step: $step) {
      id
      name
      endDate
      curatorsDueDate
    }
  }
`

const SEND_TO_CURATOR_COUNT_QUERY = gql`
  query CuratorRevieOverDueListCount {
    curatorRevieOverDueListCount
  }
`

class OverDueCuratorialReviewTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      libraryPhotosCount: 0,
      pageNum: 0,
      data: [],
      loading: true,
      batchSize: 5,
      linkPhotoModel: false
    }
  }

  async componentDidMount() {
    await this.props.client
      .watchQuery({
        query: SEND_TO_CURATOR_COUNT_QUERY,
        fetchPolicy: 'network-only'
      })
      .subscribe(async ({ data }) => {
        if (!data) return
        this.setState({ ...this.state, cohortCount: data.curatorRevieOverDueListCount })
        await this.fetchMoreCohorts(this.state.pageNum)
      })
  }

  fetchMoreCohorts = async (step, loadSize = null) => {
    const { batchSize, finalPhotosCount } = this.state
    if (step * batchSize > finalPhotosCount) return []
    this.setState({
      ...this.state,
      loading: true
    })
    await this.props.client
      .watchQuery({
        query: SEND_TO_CURATOR_MURATION,
        variables: {
          batchSize: loadSize || batchSize,
          step
        },
        fetchPolicy: 'network-only'
      })
      .subscribe(({ data }) => {
        if (!data) return
        const ids = this.state.data.map(cohort => cohort.id)
        const newIds = data.curatorRevieOverDueList.map(cohort => cohort.id)
        let isNewData = false
        for (let newId of newIds) {
          if (!ids.includes(newId)) {
            isNewData = true
            break
          }
        }
        if (isNewData) {
          this.mergeIncomingData(data.curatorRevieOverDueList)
        } else {
          this.setState({
            ...this.state,
            loading: false
          })
        }
      })
  }

  mergeIncomingData = async data => {
    if (!data) {
      this.setState({
        ...this.state,
        loading: false
      })
      return
    }

    if (
      this.state.pageNum > 0 &&
      data[data.length - 1].id === this.state.data[this.state.data.length - 1].id
    )
      return

    const cohorts = [...this.state.data]
    const ids = cohorts.map(cohort => cohort.id)
    for (let obj of data) {
      if (!ids.includes(obj.id)) {
        cohorts.push(obj)
        ids.push(obj.id)
      }
    }

    this.setState({
      ...this.state,
      data: cohorts,
      loading: false
    })
  }

  getInitialBatch = () => {
    this.setState({
      ...this.state,
      pageNum: 0
    })
  }

  getNextBatch = async () => {
    const { batchSize, pageNum, cohortCount, data } = this.state
    if (batchSize * (pageNum + 1) > cohortCount) return
    if (!(data.length >= cohortCount)) await this.fetchMoreCohorts(pageNum + 1)
    this.setState({
      ...this.state,
      pageNum: this.state.pageNum + 1
    })
  }

  getPrevBatch = () => {
    if (this.state.pageNum <= 0) return
    this.setState({
      ...this.state,
      pageNum: this.state.pageNum - 1
    })
  }

  getFinalBatch = () => {
    const { pageNum, cohortCount, batchSize, data } = this.state
    const isDivisible = cohortCount % batchSize === 0
    if (pageNum * batchSize >= cohortCount) return
    if (data.length < cohortCount) {
      this.fetchMoreCohorts(pageNum, cohortCount - pageNum * batchSize)
    }
    this.setState({
      ...this.state,
      pageNum: isDivisible
        ? parseInt(cohortCount / batchSize - 1)
        : parseInt(cohortCount / batchSize)
    })
  }

  render() {
    const { cohortCount, data, pageNum, batchSize } = this.state
    const leftDelimiter = pageNum * batchSize
    const rightDelimiter = leftDelimiter + batchSize
    const cohorts = data.slice(leftDelimiter, rightDelimiter)
    let rightDelimiterFormatString = `${rightDelimiter}`
    if (rightDelimiter - cohortCount > 0) {
      rightDelimiterFormatString = `${cohortCount}`
    }

    return (
      <div className='dashboard__class-approvals'>
        <Typography variant='h3' className='strong'>
          Overdue Curatorial Reviews ({cohortCount})
        </Typography>
        <div className='dashboard__info-list-tables'>
          <Box sx={{ overflow: 'auto' }}>
            <Grid container spacing={2} className='admin-dashboard__table-labels'>
              <Grid item lg={8} md={7} sm={6} xs={12}>
                <Typography variant='h4'>Class Name</Typography>
              </Grid>
              <Grid item lg={4} md={5} sm={6} xs={6} sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Typography variant='h4'>Review Due Date</Typography>
              </Grid>
            </Grid>
            {this.state.loading ? (
              <div className='dashboard__no-result-container'>
                <div className='dashboard__no-result-text'>Loading...</div>
              </div>
            ) : (
              <>
                <div className='admin-dashboard__table-list dashboard__table-lists'>
                  {cohorts.length > 0 ? (
                    cohorts.map(cohort => {
                      return (
                        <OverDueCuratorialReviewTableRows
                          cohort={cohort}
                          key={`overDue-${cohort.id}`}
                        />
                      )
                    })
                  ) : (
                    <div className='dashboard__no-result-container'>
                      <div className='dashboard__no-result-text'>No Reviews to Send to Curator</div>
                    </div>
                  )}
                </div>
                <div className='pagination admin-dashboard__pagination'>
                  {cohortCount > 0 ? (
                    <div className='pagination__content'>
                      <span>{`${leftDelimiter +
                        1}-${rightDelimiterFormatString} of ${cohortCount}`}</span>
                      <span
                        onClick={() => this.getInitialBatch()}
                        className={`${pageNum === 0 ? 'grey' : ''}`}
                      >
                        <ChevronsLeft size={20} />{' '}
                      </span>
                      <span
                        onClick={() => this.getPrevBatch()}
                        className={`${pageNum === 0 ? 'grey' : ''}`}
                      >
                        <ChevronLeft size={20} />{' '}
                      </span>
                      <span
                        onClick={() => this.getNextBatch()}
                        className={`${
                          pageNum * batchSize >= cohortCount - batchSize ? 'grey' : ''
                        }`}
                      >
                        <ChevronRight size={20} />
                      </span>
                      <span
                        onClick={() => this.getFinalBatch()}
                        className={`${
                          pageNum * batchSize >= cohortCount - batchSize ? 'grey' : ''
                        }`}
                      >
                        <ChevronsRight size={20} />
                      </span>
                    </div>
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>
              </>
            )}
          </Box>
        </div>
      </div>
    )
  }
}

export default withApollo(OverDueCuratorialReviewTable)
