import React, { Component, Fragment } from 'react'
import EventListRow from '../molecules/EventListRow'
import { Typography } from '@mui/material'

class EventList extends Component {
  render() {
    const { events } = this.props
    return events ? (
      <Fragment>
        {events.map((event, index) => (
          <EventListRow
            key={index}
            event={event}
            showToast={this.props.showToast}
            toggleEditEvent={this.props.toggleEditEvent}
            deleteUser={this.props.deleteUser}
          />
        ))}
      </Fragment>
    ) : (
      <Typography variant="paragraph" component="p"> There are currently no events listed</Typography>
    )
  }
}

export default EventList
