import React, { Component } from 'react'
import './styleguide.scss'
import Button from '../ui/elements/Button.js'
import FormField from '../ui/elements/FormField.js'
import TextField from '@mui/material/TextField'
import Hero from '../ui/organisms/Hero.js'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Typography } from '@mui/material'

import {
  MapPin,
  Calendar,
  Camera,
  Sliders,
  ChevronDown,
  ChevronRight,
  MoreHorizontal,
  MoreVertical
} from 'react-feather'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Layout from '../ui/template/Layout'

class StyleGuide extends Component {
  state = {
    showPassword: true
  }
  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value })
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }))
  }

  render() {
    return (
      <Layout>
        <Hero heroTitle='Style Guide' />

        <div className='container__body'>
          <p className='body__intro'>
            Welcome to the Working Assumptions style guide. This living documentation for how you
            view styles on our web application will be updated frequently during the build out of
            the app. Please reference this page to help establish more consistency around how we use
            styles in the app!
          </p>

          <div className='body__section'>
            <h3 className='section__title'>Typography</h3>

            <div className='section__content'>
              <h1>H1 - Large Headers</h1>
              <Typography variant="h2" component="h2">H2 - Medium Headers</Typography>
              <h3>H3 - Small Headers</h3>
              <Typography variant="h4">H4 - Buttons, Nav Titles</Typography>
              <Typography variant="h5">H5 - Sub Titles, Tabs</Typography>
              <h6>H6 - Extra Small Sub Titles, Sub Nav</h6>
              <Typography variant="paragraph" component="p">P - Paragraph / Body Text</Typography>
              <blockquote>Blockquote - Experpts & Pull-quotes</blockquote>
            </div>
          </div>

          <div className='body__section'>
            <h3 className='section__title'>Fonts</h3>

            <div className='section__content'>
              <h2 className='section__font--Libre-Franklin-Bold'>Libre Franklin Bold</h2>
              <h2 className='section__font--Libre-Franklin-Regular'>Libre Franklin Regular</h2>
              <h2 className='section__font--Merriweather-Light'>Merriweather Light</h2>
            </div>
          </div>

          <div className='body__section'>
            <h3 className='section__title'>Colors</h3>

            <div className='section__content'>
              <Row>
                <Col lg='3'>
                  <div className='section__color section__color--black' />
                  <Typography variant="paragraph" component="p">$black</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--white' />
                  <Typography variant="paragraph" component="p">$white</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--background-accent' />
                  <Typography variant="paragraph" component="p">$background-grey</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--light-grey' />
                  <Typography variant="paragraph" component="p">$light-grey</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--dark-grey' />
                  <Typography variant="paragraph" component="p">$dark-grey</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--blue-accent' />
                  <Typography variant="paragraph" component="p">$blue-accent</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--red-accent' />
                  <Typography variant="paragraph" component="p">$red-accent</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--red-status' />
                  <Typography variant="paragraph" component="p">$red-status</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--green-status' />
                  <Typography variant="paragraph" component="p">$green-status</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--green-accent' />
                  <Typography variant="paragraph" component="p">$green-accent</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--dark-overlay' />
                  <Typography variant="paragraph" component="p">$dark-overlay</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--transparent-green' />
                  <Typography variant="paragraph" component="p">$transparent-green</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--transparent-grey' />
                  <Typography variant="paragraph" component="p">$transparent-grey</Typography>
                </Col>

                <Col lg='3'>
                  <div className='section__color section__color--transparent-red' />
                  <Typography variant="paragraph" component="p">$transparent-red</Typography>
                </Col>
              </Row>
            </div>
          </div>

          <div className='body__section'>
            <h3 className='section__title'>Buttons</h3>

            <div className='section__content'>
              <Row>
                <Col>
                  <Button variant='primary' title='Primary' />
                </Col>

                <Col>
                  <Button variant='primary' title='Disabled' disabled='true' />
                </Col>

                <Col>
                  <Button  title='Small' />
                </Col>

                <Col>
                  <Button  title='Disabled' disabled='true' />
                </Col>

                <Col>
                  <Button variant='outline-dark' title='Alt Dark' />
                </Col>

                <Col>
                  <Button variant='outline-light' title='Alt Light' />
                </Col>
              </Row>
              <br />

              <Row>
                <Col lg='5'>
                  <Button variant='outline-success' title='Choice - Confirm' />
                </Col>

                <Col lg='1' />

                <Col lg='5'>
                  <Button variant='outline-danger' title='Choice - Deny' />
                </Col>
              </Row>
              <br />

              <Row>
                <Col lg='5'>
                  <Button variant='outline-default' title='Choice - Default' />
                </Col>

                <Col lg='1' />

                <Col lg='5'>
                  <Button variant='outline-default' title='Choice - Not Chosen' disabled='true' />
                </Col>
              </Row>
            </div>
          </div>

          <div className='body__section'>
            <h3 className='section__title'>Forms</h3>

            <div className='section__content'>
              <Row>
                <Col lg='4'>
                  <FormField
                    formFieldId='formfield-placeholder'
                    formFieldLabel='Standard Form Field'
                    formFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    formFieldType='text'
                  />
                </Col>
                <Col lg='4'>
                  <FormField
                    formFieldId='placeholder'
                    formFieldLabel='With Placeholder Text'
                    formFieldPlaceholder='Woah... Impressive!!!'
                    formFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    formFieldType='text'
                  />
                </Col>
                <Col lg='4'>
                  <TextField
                    error
                    id='outlined-error'
                    label='Form Field Error'
                    classes={{
                      root: 'text-field__outline'
                    }}
                    type='error'
                    variant='outlined'
                    autoComplete='off'
                    margin='dense'
                    fullWidth={true}
                  />
                </Col>
                <Col lg='4'>
                  <FormField
                    formFieldId='outlined-number'
                    formFieldLabel='Standard Number'
                    formFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    formFieldType='number'
                    formFieldName='mobileNumber'
                    formFieldAutoComplete='tel'
                  />
                </Col>
                <Col lg='4'>
                  <FormField
                    formFieldId='birthday'
                    formFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    formFieldType='date'
                    formFieldName='dob'
                  />
                </Col>
                <Col lg='4'>
                  <FormField
                    formFieldId='placeholder-password'
                    formFieldVariant='outlined-error'
                    formFieldAutoComplete='off'
                    formFieldClasses={{
                      root: 'text-field__outline'
                    }}
                    formFieldType={this.state.showPassword ? 'text' : 'password'}
                    formFieldLabel='Password Form Field'
                    formFieldOnChange={this.handleChange('password')}
                    formFieldInputPropsEnd={
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='Toggle password visibility'
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>

          <div className='body__section'>
            <h3 className='section__title'>Icons</h3>

            <div className='section__content'>
              <Row>
                <Col>
                  <Camera />
                </Col>
                <Col>
                  <Calendar />
                </Col>
                <Col>
                  <MapPin />
                </Col>
                <Col>
                  <Sliders />
                </Col>
                <Col>
                  <ChevronRight />
                </Col>
                <Col>
                  <ChevronDown />
                </Col>
                <Col>
                  <MoreHorizontal />
                </Col>
                <Col>
                  <MoreVertical />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default StyleGuide
