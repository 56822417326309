import {React, useState} from 'react'
import './modals.scss'
import { useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import { Typography, Box, Grid } from '@mui/material'
import Button from '../elements/Button.js'
import BaseModal from '../template/BaseModal';

const DELETE_MUTATION = gql`
  mutation DeletePhotoMutation($id: ID!) {
    deletePhoto(id: $id) {
      id
    }
  }
`

const DeletePhotoModal = (props) => {
  const [title, setTitle] = useState('Delete Photograph')
  const [disabled, setDisabled] = useState(false)

  const handleDelete = (onDeleteMutate, photoId) => {
    setTitle('Deleting...')
    setDisabled(true)
    onDeleteMutate({
      variables: {
        id: photoId,
      },
      refetchQueries: ['ProjectDetails'],
    })
  }

  const [deletePhoto] = useMutation(DELETE_MUTATION, {
    onCompleted: () => {
      props.updateParentState(props.photo.id, 'delete')
      props.showToast('success', 'Photograph deleted successfully.')
      if (props.refetchProject) {
        props.refetchProject()
      }
    },
  })

  const handleClose = () => {
    this.setState({show: false});
    this.props.onHide(false);
  };

  return (
    <BaseModal
      {...props}
      open={props.show}
      onClose={handleClose}
      onHide={props.onHide}
    >
      <BaseModal.BaseModalHeader>
        Delete Photograph
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody>
        <Box sx={{pb:'0px !important'}} className='delete-user__description'>
          <Typography variant='paragraph' component='p'>
            Are you sure you want to delete this photo?
          </Typography>
        </Box>
        <BaseModal.BaseModalFooter>
          <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
          <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                variant='contained'
                type='copy'
                title={title}
                disabled={disabled}
                onClick={() => handleDelete(deletePhoto, props.photo.id)}
                sx={{width:{ xs: '100%', sm: '100%' }}}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
              <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={() => props.onHide()}>
                Cancel
              </Typography>
            </Grid>
          </Grid>
        </Box>
        </BaseModal.BaseModalFooter>
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

export default DeletePhotoModal
