import React, { Component } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import Modal from 'react-bootstrap/Modal'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Typography } from '@mui/material'


const MUTATION = gql`
  mutation ManagePhotoTags(
    $id: ID
    $action: String!) {
    managePhotoTags(
      tagId: $id
      action:$action
    ) {
      id
    }
  }
`

class DeletePhotoTagModal extends Component {
  render() {
    return (
      <Mutation
        mutation={MUTATION}
        onCompleted={() => {
          this.props.onHide()
          this.props.showToast('Photo Tag deleted.')
        }}
      >
        {(onMutate, { data }) => {
          return (
            <Modal
              {...this.props}
              size='lg'
              aria-labelledby='contained-modal-title-vcenter'
              centered
              show={this.props.show}
            >
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  <Typography variant="h2">Delete Photo Tag</Typography>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='delete-event__description'>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                    >
                    This will permanently delete the photo tag from the system.
                  </Typography>
                </div>
                <div className='delete-event__button'>
                  <Button
                    type='copy'
                    title='Delete Photo Tag'
                    disabled={false}
                    onClick={() =>
                      onMutate({
                        variables: {
                          id: this.props.eventId,
                          action:'delete'
                        }
                      })
                    }
                  />
                  {'  '}
                  <Typography variant="h4"className='semi-strong link' ml={2} onClick={() => this.props.onHide()}>
                    Cancel
                  </Typography>
                </div>
              </Modal.Body>
            </Modal>
          )
        }}
      </Mutation>
    )
  }
}

export default DeletePhotoTagModal
