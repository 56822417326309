import ClassListRow from '../molecules/ClassListRow.js'
import { observer, inject } from 'mobx-react'
import EmptyListDesign from '../molecules/EmptyListDesign.js'
import { getTextContentByPage } from '../../content/textContent'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import CustomPagination from '../molecules/CustomPagination.js'
import DateUtils from '../../utils/DateUtils.js'
import { useQuery } from '@apollo/client'
import React, { useState, useEffect } from 'react'
import { GET_COHORT_LIST_QUERY } from '../../queries/userDetailsQueries'
import Loading from '../elements/Loading.js'

const accentColors = ['#005691', '#00746B', '#F68842', '#8E5BA6', '#C95147', '#FFCC32']

const ClassList = props => {
  const { role } = props.userStore
  const parentId = parseInt(props.id)
  const parentType = props.entity
  const [search] = useState('')
  const [listType] = useState('')
  const [cohorts, setCohorts] = useState(parentId ? [] : props.cohorts)
  const [endCursor, setEndCursor] = useState('')
  const [hasNextPage, setHasNextPage] = useState(false)
  const isXXLScreen = useMediaQuery('(min-width: 1536px)')
  const isSkip = !parentId ? true : false

  const { loading, fetchMore } = useQuery(GET_COHORT_LIST_QUERY, {
    variables: { id: parentId, type: parentType, listType: listType, search: search},
    skip: isSkip,
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setCohorts(data.getCohortsConnectionByUser.edges.map(edge => edge.node))
      if (data.getCohortsConnectionByUser.pageInfo.hasNextPage) {
        setEndCursor(data.getCohortsConnectionByUser.pageInfo.endCursor)
      }
      setHasNextPage(data.getCohortsConnectionByUser.pageInfo.hasNextPage)
    }
  })

  const fetchMoreCohorts = () => {
    if (!hasNextPage) return []
    fetchMore({
      variables: {
        cursor: endCursor
      }
    })
  }

  useEffect(() => {
    const handleScroll = e => {
      const elem = e.target.scrollingElement
      const vh = elem.scrollHeight
      const currVh = elem.scrollTop
      const threshold = vh / 4 + 50
      if (currVh > threshold) {
        fetchMoreCohorts()
      }
    }
    if (!isXXLScreen) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (!isXXLScreen) {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [hasNextPage, endCursor, isXXLScreen])

  if(loading) return <Loading/>

  const completedCohorts = cohorts.filter(cohort =>
    DateUtils.isDatePassed(cohort.endDate)
  )
  const { userRole, entity, showPagination } = props
  const userEmptyList = entity
    ? entity.toUpperCase()
    : userRole
    ? userRole.toLowerCase() === 'teacher'
      ? 'TEACHER'
      : 'FACILITATOR'
    : ''

  const pagination = () => {
    return showPagination !== false ? (
      <Box sx={{ mt: 4, mr: { md: 4 } }}>
        <CustomPagination
          totalPages={props.totalPages}
          pageNum={props.page + 1}
          onChange={props.setPage}
        />
      </Box>
    ) : (
      <Box sx={{ pt: 4 }} />
    )
  }

  return (
    <Box>
      {(role === 'Curator' && completedCohorts > 0) ||
      (role !== 'Curator' && cohorts.length > 0) ? (
        <Box sx={{ display: { xs: 'none', md: 'block' }, mb: 2, mt: 5 }}>
          <Grid container sx={{ px: 10 }} rowGap={{ xs: 2 }}>
            <Grid item md={4}>
              <Typography variant='dataLable' component={'span'}>
                Class Details
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant='dataLable' component={'span'}>
                Actions and Notices
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant='dataLable' component={'span'}>
                Dates
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ''
      )}
      {props.role === 'Curator' ? (
        <>
          {completedCohorts.map((cohort, index) => (
            <ClassListRow
              key={`cureator-${index}`}
              cohort={cohort}
              color={accentColors[cohort.id % accentColors.length]}
              role={role}
            />
          ))}
          {pagination()}
        </>
      ) : cohorts.length > 0 ? (
        <>
          {cohorts.map((cohort, index) => (
            <ClassListRow
              key={`admin-${index}`}
              cohort={cohort}
              color={accentColors[cohort.id % accentColors.length]}
            />
          ))}
          {pagination()}
        </>
      ) : (
        <EmptyListDesign
          heading={getTextContentByPage(userEmptyList, role.toUpperCase(), 'CLASSLIST_EMPTYINTRO')}
          subheading={getTextContentByPage(
            userEmptyList,
            role.toUpperCase(),
            'CLASSLIST_EMPTYSUBHEADING'
          )}
        />
      )}
    </Box>
  )
}

export default inject('userStore')(observer(ClassList))
