import React, { useEffect } from 'react'
import { Nav } from 'react-bootstrap'
import './herotabs.scss'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material'

const ClassSubTabs = props => {
  const tabs = props.tabs
  const defaultActiveKey = tabs[0]['eventKey']

  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const buttonGroupRef = React.useRef(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [popperWidth, setPopperWidth] = React.useState('auto')

  useEffect(() => {
    const handleResize = () => {
      if (buttonGroupRef.current) {
        setPopperWidth(buttonGroupRef.current.clientWidth)
      }
    }

    // Attach the resize event listener
    window.addEventListener('resize', handleResize)

    // Initial width setup
    if (buttonGroupRef.current) {
      setPopperWidth(buttonGroupRef.current.clientWidth)
    }

    // Clean up the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    props.handelTabChange(tabs[index].eventKey)
    setOpen(false)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <Box
        sx={{
          display: {
            md: 'block',
            sm: 'none',
            xs: 'none'
          },
          p: 0,
          m: 0
        }}
        className='class-review__sub-menu class-review__tabs'
      >
        <Nav
          variant='pills'
          defaultActiveKey={defaultActiveKey}
          activeKey={props.currentTab}
          onSelect={selectedKey => props.handelTabChange(selectedKey)}
          style={{ padding: 0, margin: 0 }}
        >
          {tabs.map((tab, index) => (
            <Nav.Link
              disabled={tab.count ? false : true}
              key={index}
              eventKey={tab.eventKey}
              style={{ padding: '0.7rem 1rem' }}
            >
              {tab.title}
            </Nav.Link>
          ))}
        </Nav>
      </Box>
      <Box
        sx={{
          display: {
            md: 'none',
            sm: 'block',
            xs: 'block'
          },
          textAlign: 'center',
          width: '100%'
        }}
      >
        <ButtonGroup
          variant='contained'
          ref={node => {
            anchorRef.current = node
            buttonGroupRef.current = node
          }}
          aria-label='split button'
          sx={{
            boxShadow: 'none',
            width: { xs: '100%', md: 'auto' }
          }}
        >
          <Button
            onClick={handleClick}
            sx={{
              backgroundColor: '#fff',
              color: '#8e5ba6',
              borderRadius: '0',
              border: '1px solid #8e5ba6',
              boxShadow: 'none',
              padding: {xs: '9px'},
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#e9deee',
                border: '1px solid #8e5ba6'
              },
              width: { xs: '100%', md: 'auto' },
              fontSize: '1.25rem'
            }}
            endIcon={<ArrowDropDownIcon />}
          >
            {tabs[selectedIndex].title}
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
            backgroundColor: '#e9deee',
            color: '#8e5ba6',
            borderRadius: '0',
            border: '1px solid #8e5ba6',
            boxShadow: 'none',
            width: popperWidth,
            alignSelf: 'center'
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id='split-button-menu' autoFocusItem>
                    {tabs.map((option, index) => (
                      <MenuItem
                        key={option.title}
                        disabled={option.count ? false : true}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                        sx={{
                          color: '#8e5ba6',
                          fontSize: '1.25rem'
                        }}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </>
  )
}

export default ClassSubTabs
