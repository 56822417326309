import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
  
export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    let matches = Object.values(params).reduce(
      (path, param) => path.replace('/' + param, ''),
      location.pathname,
  )
    // let match = useMatches();

    return (
      <Component
        {...props}
        location = {location}
        navigate={navigate}
        params={params}
        basePath={matches}
      />
    );
  }



  return ComponentWithRouterProp;
}