import React, { Component, Fragment } from 'react'
import Button from '../elements/Button.js'
import SchoolListRow from '../molecules/SchoolListRow.js'
import { Link } from 'react-router-dom'
import { withApollo } from '@apollo/client/react/hoc'
import { observer, inject } from 'mobx-react'
import Loading from '../elements/Loading.js'
import ListSearchBar from '../elements/ListSearchBar.js'
import EmptyListDesign from '../molecules/EmptyListDesign.js'
import { getTextContentByPage } from '../../content/textContent'
import { Typography, Box, Grid } from '@mui/material'

class SchoolList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      schoolCount: props.schoolCount
    }
    this.originalItems = props.schools
  }

  makeSchoolsArray = arr => {
    return arr
      .filter(elem => elem.node)
      .map(elem => ({ ...elem.node }))
      .map(school => ({
        id: school.id,
        name: school.name,
        mailingAddress: school.mailingAddress,
        state: school.mailingAddress && school.mailingAddress.state,
        city: school.mailingAddress && school.mailingAddress.city,
        createdAt: school.createdAt,
        cohorts: school.cohorts,
        students: new Set(school.cohorts.map(cohort => cohort.students).flat(2)).size
      }))
  }

  async componentDidMount() {
    this.setState({
      ...this.state,
      schoolCount: this?.originalItems?.schoolCount,
      loading: false
    })
  }

  handelSearch = value => {
    this.props.handelSearch(value)
  }

  render() {
    if (this.props.schools && !this.state.loading) {
      const schools = this.makeSchoolsArray(this.props.schools.schoolsConnection.edges)

      return (
        <Box sx={{pb: {xs: 4}}}>
          <Box
            className='container__body'
            sx={{ px: { xs: 3, sm: 4, lg: 6 }, py: { xs: 4, sm: 5, md: 6, lg: 10 } }}
          >
            <Grid container>
              <Grid item md={6} sm={6} xs={12}>
                <Typography variant='h1' className='bold'>
                  Schools ({this.props.schoolCount})
                </Typography>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Grid
                  container
                  spacing={{ xs: 2, sm: 2, md: 0 }}
                  textAlign='right'
                  direction={{ md: 'column' }}
                  mt={{ xs: 2, md: 0 }}
                >
                  <Grid item xs={12} sm={4}>
                    <Link to='/create-school'>
                      <Button
                        variant='contained'
                        title='Add a School'
                        disabled={false}
                        sx={{
                          width: {
                            xs: '100%',
                            md: 'auto'
                          }
                        }}
                      />
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={8} mt={{ xs: 1, sm: 0, md: 3 }}>
                    <ListSearchBar
                      placeHolder='Search School by Name'
                      id='school-search'
                      onChange={e => this.handelSearch(e.target.value)}
                      value={this.props.searchVal}
                      style={{ marginTop: 0, marginBottom: 0 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {!this.props.pageReady &&
            (this.props.searchVal ? (
              <EmptyListDesign
                heading={getTextContentByPage(
                  'SCHOOL',
                  this.props.role.toUpperCase(),
                  'LIST_EMPTYINTRO'
                )}
                subheading={getTextContentByPage(
                  'SCHOOL',
                  this.props.role.toUpperCase(),
                  'LIST_EMPTYSUBHEADING'
                )}
              />
            ) : (
              <EmptyListDesign
                heading={getTextContentByPage(
                  'SCHOOL_DEFAULT',
                  this.props.role.toUpperCase(),
                  'LIST_EMPTYINTRO'
                )}
                subheading={getTextContentByPage(
                  'SCHOOL_DEFAULT',
                  this.props.role.toUpperCase(),
                  'LIST_EMPTYSUBHEADING'
                )}
              />
            ))}
          {this.props.pageReady && (
            <Fragment>
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'block'
                  }
                }}
              >
                <Grid container sx={{ px: 10 }} rowGap={{ xs: 2 }}>
                  <Grid item sm={5}>
                    <Typography variant='dataLable' component='span'>
                      School Details
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography variant='dataLable' component='span'>
                      Classes
                    </Typography>
                  </Grid>
                  <Grid item sm={4}>
                    <Typography variant='dataLable' component='span'>
                      Activity
                    </Typography>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ mt: { xs: 5, md: 2.1 } }}>
                {schools.map(school => (
                  <SchoolListRow school={school} key={school.id} />
                ))}
              </Box>
            </Fragment>
          )}
        </Box>
      )
    } else {
      return <Loading />
    }
  }
}

export default inject('schoolFilterStore')(observer(withApollo(SchoolList)))
