import { gql } from '@apollo/client'

export const COHORT_LIST_QUERY = gql`
  query PaginatedCohorts(
    $batchSize: Int!
    $baseBatchSize: Int!
    $step: Int!
    $search: String
    $listType: String!
  ) {
    cohortsConnection(
      batchSize: $batchSize
      baseBatchSize: $baseBatchSize
      step: $step
      search: $search
      listType: $listType
    ) {
      id
      allVisitsScheduled
      startDateApproval
      endDateApproval
      approved
      name
      reviewed
      summarySent
      school {
        id
        name
        mailingAddress {
          id
          city
          state
        }
      }
      students {
        id
      }
      assignment {
        id
        name
      }
      startDate
      endDate
      nextFacilitatorVisit
      introVisit {
        id
        date
        createdAt
      }
      midVisit {
        id
        date
        createdAt
      }
      secondMidVisit {
        id
        date
        createdAt
      }
      finalVisit {
        id
        date
        createdAt
      }
      name
      projects {
        id
        photos {
          id
          isShortListed
        }
      }
    }
    cohortCount(search: $search)
    pastCohortCount(search: $search)
    upcomingCohortCount(search: $search)
  }
`

export const COHORT_COUNT_QUERY = gql`
  query CohortAmount {
    cohortCount
  }
`

export const PAST_COHORT_COUNT_QUERY = gql`
  query CohortAmount {
    pastCohortCount
  }
`

export const UPCOMING_COHORT_COUNT_QUERY = gql`
  query CohortAmount {
    upcomingCohortCount
  }
`

export const UPCOMING_TA_VISIT_DATES = gql`
  query TaVisitDatesList($batchSize: Int!, $step: Int!, $dayDiff: Int!, $visitPage: String) {
    taVisitDatesList(batchSize: $batchSize, step: $step, dayDiff: $dayDiff, visitPage: $visitPage) {
      id
      name
      school {
        id
        name
        mailingAddress {
          id
          city
          state
        }
      }
      facilitator {
        name
        id
      }
      introVisit {
        id
        date
        createdAt
      }
      midVisit {
        id
        date
        createdAt
      }
      secondMidVisit {
        id
        date
        createdAt
      }
      finalVisit {
        id
        date
        createdAt
      }
      nextFacilitatorVisit
    }
  }
`
