import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import ProjectsListRow from '../molecules/ProjectsListRow'
import _ from 'lodash'
import { Box, Typography } from '@mui/material'
import { Grid } from '@mui/material'
class ProjectsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      projects: props.projects,
      filteredProjects: props.projects,
      sortBy: null
    }
    this.originalItems = props.projects
  }

  componentWillMount() {
    const { projectFilterStore, projects } = this.props
    projectFilterStore.setFilters(this.filters())
    projectFilterStore.setItems(projects)
  }

  filters() {
    return [
      {
        key: 'project-status',
        title: 'Project Status',
        type: 'multiple-select',
        choices: [
          {
            // key: 'status-active',
            title: 'Active',
            func: project => {
              return project.submitted === false
            }
          },
          {
            // key: 'status-submitted',
            title: 'Submitted',
            func: project => {
              return project.submitted === true
            }
          }
        ]
      }
    ]
  }

  sortOptions() {
    return [
      {
        title: 'Newest First',
        onSelect: () => {
          this.setState({
            sortBy: 'Newest First'
          })
        }
      },
      {
        title: 'Oldest First',
        onSelect: () => {
          this.setState({
            sortBy: 'Oldest First'
          })
        }
      }
    ]
  }

  sortedItems() {
    const { projectFilterStore } = this.props
    const items = projectFilterStore.filteredItems

    switch (this.state.sortBy) {
      case 'Newest First':
        return _.orderBy(items, 'createdAt', 'desc')
      case 'Oldest First':
        return _.orderBy(items, 'createdAt', 'asc')
      default:
        return items
    }
  }

  render() {
    const { userStore } = this.props
    const projects = this.sortedItems()
    return (
      <Fragment>
        {/* <ListOrganizers
          hideFilters={true}
          sortOptions={this.sortOptions()}
          // filters={projectFilterStore.filters}
          // toggleFilter={projectFilterStore.toggleFilter}
          noMatchingResults={this.originalItems.length && !projects.length}
          filterStore={projectFilterStore}
        /> */}
        <Box sx={{ display: { xs: 'none', md: 'block' }, mb: 2, mt: 5 }}>
          <Grid container sx={{ px: 10 }} rowGap={{ xs: 2 }}>
            <Grid item md={4}>
              <Typography variant='dataLable' component={'span'}>
                Project Details
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant='dataLable' component={'span'}>
                Instructors
              </Typography>
            </Grid>
            <Grid item md={4}>
              <Typography variant='dataLable' component={'span'}>
                Dates
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: { xs: 5, md: 0 }, pb: { xs: 4 } }}>
          {projects ? (
            projects.map((project, index) => (
              <Fragment key={index}>
                <ProjectsListRow
                  project={project}
                  shortListedPhotos={project.photos.filter(photo => photo.isShortListed === true)}
                  role={userStore.role}
                  tabChangeHandler={this.props.tabChangeHandler}
                />
              </Fragment>
            ))
          ) : (
            <Typography variant='paragraph' component='p'>
              User has not yet uploaded any projects
            </Typography>
          )}
        </Box>
      </Fragment>
    )
  }
}

export default inject('userStore', 'projectFilterStore')(observer(ProjectsList))
