import React, { Component } from 'react'
import Button from '../elements/Button.js'
import { Spinner } from 'react-bootstrap'
import FormCheckbox from '../elements/FormCheckbox.js'
import FormField from '../elements/FormField'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Box, Grid } from '@mui/material';
import BaseModal from '../template/BaseModal';

const SUBMIT_PROJECT_MUTATION = gql`
  mutation SubmitProject(
    $projectId: ID!
    $secondaryEmail: String!
    $wantsUpdate: Boolean!
  ) {
    submitProject(
      projectId: $projectId
      secondaryEmail: $secondaryEmail
      wantsUpdate: $wantsUpdate
    ) {
      id
    }
  }
`

class SubmitProjectModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedA: false,
      wantsUpdate: false,
      checkedC: false,
      isSubmitting: false,
      secondaryEmail: '',
      errors: {}
    }
    this.initialState = this.state
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this.initialState)
  }

  handleSubmit = (e, onMutate) => {
    e.preventDefault()
    this.setState({
      isSubmitting: true
    }, () => {
      onMutate({
        variables: {
          projectId: this.props.projectId,
          secondaryEmail: this.state.secondaryEmail,
          wantsUpdate: this.state.wantsUpdate
        },
        refetchQueries: [`ProjectDetails`]
      })
    })
  }

  handleFormChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    this.validateChange(e)
  }

  validateChange(e) {
    const fieldName = e.target.name
    const value = e.target.value
    var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    switch (fieldName) {
      case 'secondaryEmail':
        let sentToError = null
        if (!emailpattern.test(value)) {
          sentToError = 'Please enter a valid email address.'
        } else if (value.toLowerCase() === this.props.studentDetails.email.toLowerCase()) {
          sentToError = 'Email address cannot match registered email address.'
        }
        this.setState({ errors: { ...this.state.errors, secondaryEmail: sentToError } })
        break
      default:
        return
    }
  }

  render() {
    const submissionReqMet =
      this.state.checkedA === true &&
      this.state.checkedC === true &&
      this.state.secondaryEmail.length > 0 &&
      this.state.errors.secondaryEmail == null
    const { isSubmitting } = this.state
    const { handleFormChange } = this
    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);

    };
    return (
      <BaseModal
        {...this.props}
        show={this.props.show}
        className='project-progress__submit-project-modal'
        value={this.props.value}
        open={this.props.show}
        onClose={handleClose}
        onHide={this.props.onHide}
      >
        <BaseModal.BaseModalHeader>
          {this.props.role === 'student' ? 'Submit Your Project' : 'Submit Project'}
        </BaseModal.BaseModalHeader>
        <BaseModal.BaseModalBody>
          <Grid container className='project-progress__submit-project-modal-body'>
            <Grid item xs={12}>
              <p className='project-progress__submit-project-p'>
                {this.props.role === 'student' ? 'Last step! Please confirm that:' : 'Before you submit the student’s project on their behalf, please confirm with the student that:'}
              </p>
            </Grid>
            <Grid item xs={12} className='project-progress__form-container'>
              <Grid container gap={{xs: 2}}>
                <Grid item xs={12} className='project-progress__submit-project__checkboxes'>
                  <FormCheckbox
                    checked={this.state.checkedC}
                    onChange={this.handleChange('checkedC')}
                    value='checkedC'
                    label={this.props.role === 'student' ? 'All photographs, titles, and captions are your own work.' : 'All photographs, titles, and captions are their own work.'}
                    color='primary'
                  />
                </Grid>
                <Grid item xs={12} className='project-progress__submit-project__checkboxes'>
                  <FormCheckbox
                    checked={this.state.checkedA}
                    onChange={this.handleChange('checkedA')}
                    value='checkedA'
                    label={this.props.role === 'student' ? 'You understand that any photographs, titles, and captions that you submit may be stored in the Working Assumptions digital research archive, visible to individuals connected to Working Assumptions.' : 'The student has been informed and understands that any photographs, titles, and captions that they submit may be stored in the Working Assumptions digital research archive, visible to individuals connected to Working Assumptions.'}
                    color='primary'
                  />
                </Grid>
              </Grid>
              <Grid container mt={2}>
                <Grid item xs={12} className='project-progress__submit-project-input'>
                  <Box mb={'16px'}>
                    <p className='project-progress__submit-project-p'>
                      {this.props.role === 'student' ? 'If you receive an award, Working Assumptions may ask for your permission to publish your work. Please provide a secondary email address so that we can contact you.' : 'If the student receives an award, Working Assumptions may ask for their permission to publish their work. Please ask the student for a secondary email address so that we can contact them.'}
                    </p>
                  </Box>
                  <Grid item xs={12} width={{xs: '100%', md: '50%'}} className='project-progress__submit-project-input--field'>
                    <FormField
                      formFieldId='outlined-email'
                      formFieldFullWidth='false'
                      formFieldVariant='filled'
                      formFieldLabel='Secondary Email Address'
                      formFieldClasses={{
                        root: 'text-field__outline'
                      }}
                      formFieldType='email'
                      formFieldName='secondaryEmail'
                      formFieldAutoComplete='email'
                      onChange={handleFormChange}
                      formFieldError={this.state.errors.secondaryEmail || null}
                    />
                  </Grid>
                </Grid>
                <Grid mt={3} container>
                  <p className='project-progress__submit-project-p'>{this.props.role === 'student' ? 'Working Assumptions would like to stay in touch about future programs. If you would like to receive updates, please check the box below.' : 'Working Assumptions would like to stay in touch about future programs. If the student would like to receive updates, please check the box below.'}</p>
                  <Grid item xs={12} mt={'-8px'}>
                    <FormCheckbox
                      checked={this.state.wantsUpdate}
                      onChange={this.handleChange('wantsUpdate')}
                      value='wantsUpdate'
                      label={this.props.role === 'student' ? 'Send me updates about Working Assumptions programs.' : 'Send updates about Working Assumptions programs.'}
                      color='primary'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <BaseModal.BaseModalFooter>
            <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
              <Mutation
                mutation={SUBMIT_PROJECT_MUTATION}
                onCompleted={() => {
                  this.setState({
                    isSubmitting: false
                  })
                  this.props.onHide()
                  this.props.showToast('success','Your project was submitted for review.')
                  this.props.projectSubmitted(true)
                }}
              >
                  {(onMutate, { data }) => (
                    <Grid container direction="row" justifyContent={'right'}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Button
                          variant='contained'
                          type='Submit'
                          title={isSubmitting ? <span className='icon-text-span'><Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          /> Submitting...</span> : (this.props.role === 'student' ? 'Submit Your Project' : 'Submit Project')}
                          disabled={!submissionReqMet || isSubmitting}
                          onClick={e => {
                            this.handleSubmit(e, onMutate)
                          }}
                          sx={{width:{xs:'100%', sm:'100%'}}}
                        />
                      </Grid>
                    </Grid>
                  )}
              </Mutation>
            </Box>
          </BaseModal.BaseModalFooter>
        </BaseModal.BaseModalBody>
      </BaseModal>
    )
  }
}

export default SubmitProjectModal