import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import Layout from '../ui/template/Layout'
import Hero from '../ui/organisms/Hero'
import { Col, Row } from 'react-bootstrap'
import { Typography } from '@mui/material'
class ExternalLinks extends Component {
  render() {
    return (
      <Layout>
        <Hero heroTitle='External Links' />
        <Fragment>
          <div className='container__body'>
            <div className='body__classes'>
              <div className='classes__labels'>
                <Row>
                  <Col sm={4}>
                    <Typography variant="h4">External Services</Typography>
                  </Col>
                  <Col sm={8}>
                    <Typography variant="h4">Links</Typography>
                  </Col>
                </Row>
              </div>
              <div className='classes__list'>
                <div className='body__section'>
                  <div
                    className='color-bar'
                    style={{
                      width: 5,
                      height: '100%',
                      top: 0,
                      left: 0,
                      position: 'absolute',
                      display: 'block'
                    }}
                  />
                  <Row>
                    <Col sm={4}>
                      Chart.io
                    </Col>
                    <Col sm={8}>
                      <a href="https://chartio.com/workingassumptionsorg/" target="_blank">https://chartio.com/workingassumptionsorg/</a>
                    </Col>
                  </Row>
                </div>
                <div className='body__section'>
                  <div
                    className='color-bar'
                    style={{
                      width: 5,
                      height: '100%',
                      top: 0,
                      left: 0,
                      position: 'absolute',
                      display: 'block'
                    }}
                  />
                  <Row>
                    <Col sm={4}>
                      AirTable <br /><Typography variant="h5">(Same for staging and Prod)</Typography>
                    </Col>
                    <Col sm={8}>
                    <a href="https://airtable.com/" target="_blank">https://airtable.com/</a>
                    </Col>
                  </Row>
                  
                </div>
                <div className='body__section'>
                  <div
                    className='color-bar'
                    style={{
                      width: 5,
                      height: '100%',
                      top: 0,
                      left: 0,
                      position: 'absolute',
                      display: 'block'
                    }}
                  />
                  <Row>
                    <Col sm={4}>
                      Twilio
                    </Col>
                    <Col sm={8}>
                    <a href="https://www.twilio.com/login" target="_blank">https://www.twilio.com/login</a>
                    </Col>
                  </Row>
                </div>

                <div className='body__section'>
                  <div
                    className='color-bar'
                    style={{
                      width: 5,
                      height: '100%',
                      top: 0,
                      left: 0,
                      position: 'absolute',
                      display: 'block'
                    }}
                  />
                  <Row>
                    <Col sm={4}>
                    Mandrill (MailChimp)
                    </Col>
                    <Col sm={8}>
                    <a href="https://login.mailchimp.com/" target="_blank">https://login.mailchimp.com/</a>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      </Layout>
    )
  }
}

export default inject('userStore', 'resourceStore')(observer(ExternalLinks))
