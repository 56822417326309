import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // marginBottom: '0px',
    // marginTop: '0px',
    // zIndex: 2,
    // background: 'white',
    // borderRadius: 0,
    // flexWrap: 'wrap'
  },
  margin: {
    // margin: 0,
    // marginTop: 0,
    // marginBottom: 0
  },
  cssLabel: {
    // fontSize: '18px',
    // '&$cssFocused': {
    //   color: '#0076BB'
    // }
  },
  cssOutlinedInput: {
    // fontSize: '18px',
    // '&$cssFocused $notchedOutline': {
    //   borderColor: '#0076BB'
    // }
  },
  cssFocused: {},
  notchedOutline: {
    borderRadius: 0,
    borderWidth: 2
  }
}));

const FormFieldMultiline = (props) => {
  const classes = useStyles();
  const [formFieldValue, setFormFieldValue] = useState(props.formFieldDefaultValue || '');

  const handleInputChange = (event) => {
    setFormFieldValue(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  }
  useEffect(() => {
    setFormFieldValue(props.formFieldDefaultValue);
  }, [props.formFieldDefaultValue])

  return (
    <Box component='div' className={classes.root}>
      <TextField
        id={props.formFieldId}
        label={props.formFieldLabel}
        multiline
        onChange={handleInputChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        name={props.formFieldName}
        rows='4'
        value={formFieldValue}
        className={props.className}
        margin='none'
        variant={props.variant || 'filled'}
        disabled={props.disabled}
        inputRef={props.inputRef}
        inputProps={{ maxLength: props.formFieldMaxLength }}
        placeholder={props.formFieldPlaceholder}
        fullWidth={true}
        error={!!props.formFieldError}
        required={props.formFieldRequired}
        sx={{
          '.MuiInputBase-root': {
            pt: 1,
            fontSize: '1.125rem'
          },
          ...props.sx
        }}
      />
      {props.formFieldError ? (
        <FormHelperText className='form-field__error component-error-text'>
          {props.formFieldError}
        </FormHelperText>
      ) : null}
    </Box>
  );
};

FormFieldMultiline.propTypes = {
  formFieldId: PropTypes.string.isRequired,
  formFieldLabel: PropTypes.string,
  formFieldDefaultValue: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  formFieldName: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  formFieldMaxLength: PropTypes.number,
  formFieldPlaceholder: PropTypes.string,
  formFieldError: PropTypes.string,
  formFieldValue: PropTypes.string,
  formFieldRequired: PropTypes.bool,
};

export default FormFieldMultiline;
