import React, { Component } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import Modal from 'react-bootstrap/Modal'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Typography } from '@mui/material'

const MUTATION = gql`
  mutation DeleteEventMutation($id: ID!) {
    deleteEvent(id: $id) {
      id
    }
  }
`

class DeleteEventModal extends Component {
  render() {
    return (
      <Mutation
        mutation={MUTATION}
        onCompleted={() => {
          this.props.showToast('Event deleted.')
        }}
      >
        {(onMutate, { data }) => {
          return (
            <Modal
              {...this.props}
              size='lg'
              aria-labelledby='contained-modal-title-vcenter'
              centered
              show={this.props.show}
              onClick={this.props.onClick}
              value={this.props.value}
            >
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  <Typography variant="h2">Delete Event</Typography>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='delete-event__description'>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"
                  >
                    This will permanently delete all of this event’s information from the system and
                    from{' '}
                    <a href='http://www.waeducation.org' target='_blank' rel='noopener noreferrer'>
                      waed.org.
                    </a>
                  </Typography>
                </div>
                <div className='delete-event__button'>
                  <Button
                    type='copy'
                    title='Delete Event'
                    disabled={false}
                    onClick={() =>
                      onMutate({
                        variables: {
                          id: this.props.eventId
                        },
                        refetchQueries: [`EventsQuery`]
                      })
                    }
                  />
                  {'  '}
                  <Typography variant="h4"className='semi-strong link' ml={2} onClick={() => this.props.onHide()}>
                    Cancel
                  </Typography>
                </div>
              </Modal.Body>
            </Modal>
          )
        }}
      </Mutation>
    )
  }
}

export default DeleteEventModal
