import React, { Component, Fragment } from 'react'
import Loading from '../ui/elements/Loading.js'
import Hero from '../ui/organisms/Hero.js'
// import { withRouter } from 'react-router-dom'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import Layout from '../ui/template/Layout'
import PhotoTagsList from '../ui/organisms/PhotoTagsList.js'
import { observer, inject } from 'mobx-react'

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { withRouter } from '../utils/customHoc.js'

const QUERY = gql`
  query PaginatedPhotoTagsList($cursor: String) {
    photoTagsConnection(first: 500, after: $cursor) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
					name
					createdAt
        }
      }
    }
  }
`

const COUNTQUERY = gql`
  query photoTagAmount {
    photoTagsCount
  }
`

class PhotoTags extends Component {
  constructor(props) {
    super(props)
    const { role } = this.props.userStore
    this.state = {
      gqlRes: null,
      role: role,
      modalShow:false,
      showToast:false,
      photoTagsCount:0
    }
  }

  async componentDidMount() {
    const mockObj = {
      hasNextPage: true,
      endCursor: ''
    }
    await this.fetchMorePhotoTags(mockObj)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  makePhotoTagsArray = arr => {
    return arr
      .filter(elem => elem.node)
      .map(elem => ({ ...elem.node }))
      .map(photoTag => ({
        id: photoTag.id,
        name: photoTag.name,
        createdAt: photoTag.createdAt,
      }))
  }

  fetchMorePhotoTags = async ({ endCursor, hasNextPage }) => {
    if (!hasNextPage) return []
    this.setState({
      ...this.state,
      loading: true
    })
    const data = await this.props.client.query({
      query: QUERY,
      variables: {
        cursor: endCursor
      },
      fetchPolicy: 'network-only'
    })
    return this.mergeIncomingData(data.data)
  }

  mergeIncomingData = async (data) => {
    if (!data) {
      this.setState({
        ...this.state,
        loading: false
      })
      return
    }
    const current = (this.state.gqlRes && this.state.gqlRes.photoTagsConnection) || { edges: [] }
    const { edges } = current

    let newEdges = [...data.photoTagsConnection.edges]
    const countData = await this.props.client.query({
      query: COUNTQUERY,
      fetchPolicy: 'network-only'
    })
    this.setState({
      ...this.state,
      gqlRes: {
        photoTagsConnection: {
          edges: newEdges,
          pageInfo: data.photoTagsConnection.pageInfo
        }
      },
      photoTagsCount: countData.data.photoTagsCount,
      loading: false
    })
  }

  handleClose =(event, reason)=>{
    
    if (reason === "clickaway") {
      return;
    }
    this.setState({showToast:false})
  }


  handleScroll = e => {
    const elem = e.target.scrollingElement
    const vh = elem.scrollHeight
    const currVh = elem.scrollTop
    const threshold = vh / 4 + 200
    if (currVh > threshold) {
      this.fetchMorePhotoTags(this.state.gqlRes.photoTagsConnection.pageInfo)
    }
  }

  showToast = async (message, smallToast = false) => {
    const mockObj = {
      hasNextPage: true,
      endCursor: ''
    }
    await this.fetchMorePhotoTags(mockObj)
    this.setState({
      ...this.state,
      showToast: true,
      toastMessage: message,
      smallToast
    })
    setTimeout(() => this.setState({ showToast: false, toastMessage: '', smallToast: false }), 5000)
  }

  render() {
    const vertical = "top"
    const horizontal="right"

    const pageReady = 
      this.state.gqlRes &&
      this.state.gqlRes.photoTagsConnection &&
      this.state.gqlRes.photoTagsConnection.edges
      return (
        <Layout>
          <Fragment>
          {this.state.showToast === true ? (           
           <Snackbar 
           open={this.state.showToast} 
           onClose={this.handleClose}

           autoHideDuration={6000}
             anchorOrigin={{ vertical,horizontal }}
             >
               <MuiAlert 
                 variant="filled"
                 onClose={this.handleClose}

                 sx={{backgroundColor:"#008160", fontSize:this.state.smallToast ? 'textSmall' : ''}}
               >
               {this.state.toastMessage}           
               </MuiAlert>
             </Snackbar>
          ) : null}
          </Fragment>
          <Hero heroTitle='Photo Tags' />
          {
            pageReady ? 
              <PhotoTagsList 
                photoTags={this.state.gqlRes.photoTagsConnection.edges}
                showToast={this.showToast}
                photoTagsCount={this.state.photoTagsCount}/>
            : this.state.loading ?
              <Loading /> : null
          }
        </Layout>
      )
  }
}

export default inject('userStore')(observer(withRouter(withApollo(PhotoTags))))
