import { gql } from "@apollo/client";

export const USER_DETAILS_QUERY = gql`
  query UserDetails($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      role
      createdAt
      newEmail
      confirmationToken
      confirmationSentAt
      profile {
        __typename
        ... on AppAdmin {
          id
          mobileNumber
          mailingAddress {
            id
            address1
            address2
            city
            state
            postCode
          }
        }
        ... on Student {
          id
          projects {
            id
            name
            updatedAt
            cohort {
              id
              name
              endDate
              startDate
              nextFacilitatorVisit
              introVisit {
                id
                date
              }
              midVisit {
                id
                date
              }
              finalVisit {
                id
                date
              }
            }
            teacher {
              id
              userId
              name
            }
            facilitator {
              id
              userId
              name
              email
              mobileNumber
            }
            photos {
              id
              url
              isShortListed
            }
          }
          attribution
          instagramHandle
          dob
          mobileNumber
          cohorts {
            id
            name
            allVisitsScheduled
            startDateApproval
            endDateApproval
            nextFacilitatorVisit
            getCohortReviewStatus
            introVisit {
              id
              date
            }
            midVisit {
              id
              date
            }
            finalVisit {
              id
              date
            }
            reviewed
            school {
              mailingAddress {
                state
                id
              }
              id
              name
            }
          }
        }
        ... on Teacher {
          id
          attribution
          myProjects {
            id
            name
            updatedAt
            teacher {
              id
              name
            }
            facilitator {
              id
              name
            }
            photos {
              id
              url
              isShortListed
            }
          }
          cohorts {
            students {
              id
            }
            approved
            name
            gradeLevel
            allVisitsScheduled
            startDateApproval
            endDateApproval
            nextFacilitatorVisit
            introVisit {
              id
              date
            }
            midVisit {
              id
              date
            }
            finalVisit {
              id
              date
            }
            school {
              mailingAddress {
                state
                id
              }
              id
              name
            }
            assignment {
              id
              name
            }
            startDate
            endDate
            nextFacilitatorVisit
            id
            name
            projects {
              id
              name
              photos {
                id
                caption
                title
                altText
                attribution
                url
                isShortListed
              }
            }
          }
          mailingAddress {
            id
            address1
            address2
            city
            state
            postCode
          }
          mobileNumber
          cohorts {
            id
            name
            allVisitsScheduled
            startDateApproval
            endDateApproval
            reviewed
            nextFacilitatorVisit
            introVisit {
              id
              date
            }
            midVisit {
              id
              date
            }
            finalVisit {
              id
              date
            }
            school {
              mailingAddress {
                state
                id
              }
              id
              name
            }
          }
        }
        ... on Facilitator {
          id
          provisionalBio
          bio
          bioNeedsApproval
          attribution
          myProjects {
            id
            name
            updatedAt
            teacher {
              id
              name
            }
            facilitator {
              id
              name
            }
            photos {
              id
              url
              isShortListed
            }
          }
          cohorts {
            students {
              id
            }
            approved
            name
            gradeLevel
            allVisitsScheduled
            startDateApproval
            endDateApproval
            reviewed
            introVisit {
              id
              date
            }
            midVisit {
              id
              date
            }
            finalVisit {
              id
              date
            }
            school {
              mailingAddress {
                state
                id
              }
              id
              name
            }
            assignment {
              id
              name
            }
            startDate
            endDate
            nextFacilitatorVisit
            id
            name
            projects {
              id
              name
              photos {
                id
                caption
                title
                altText
                mrAdminNotes
                attribution
                url
                isShortListed
              }
            }
          }
          instagramHandle
          website
          mailingAddress {
            id
            address1
            address2
            city
            state
            postCode
          }
          mobileNumber
          cohorts {
            id
            name
            allVisitsScheduled
            startDateApproval
            endDateApproval
            nextFacilitatorVisit
            introVisit {
              id
              date
            }
            midVisit {
              id
              date
            }
            finalVisit {
              id
              date
            }
            reviewed
            school {
              mailingAddress {
                state
                id
              }
              id
              name
            }
          }
        }
        ... on Curator {
          id
          provisionalBio
          bio
          bioNeedsApproval
          cohorts {
            approved
            name
            gradeLevel
            allVisitsScheduled
            startDateApproval
            endDateApproval
            school {
              mailingAddress {
                state
                id
              }
              id
              name
            }
            students {
              id
            }
            assignment {
              id
              name
            }
            startDate
            endDate
            nextFacilitatorVisit
            id
            name
          }
          instagramHandle
          website
          mailingAddress {
            id
            address1
            address2
            city
            state
            postCode
          }
          mobileNumber
          cohorts {
            id
            name
            allVisitsScheduled
            startDateApproval
            endDateApproval
            reviewed
            school {
              mailingAddress {
                state
                id
              }
              id
              name
            }
          }
        }
      }
    }
  }
`


export const USER_UPDATE_MUTATION = gql`
  mutation UpdateAccountDetails(
    $id: ID
    $firstName: String
    $lastName: String
    $email: String
    $pronouns: String
    $attribution: Boolean
    $instagramHandle: String
    $website: String
    $dob: String
    $bio: String
    $bioApproved: Boolean
    $mobileNumber: String
    $address1: String
    $address2: String
    $postCode: String
    $city: String
    $state: String
    $submitBioForApproval: Boolean
  ) {
    updateAccountDetails(
      firstName: $firstName
      id: $id
      lastName: $lastName
      bioApproved: $bioApproved
      email: $email
      pronouns: $pronouns
      attribution: $attribution
      instagramHandle: $instagramHandle
      website: $website
      dob: $dob
      bio: $bio
      mobileNumber: $mobileNumber
      address1: $address1
      address2: $address2
      postCode: $postCode
      city: $city
      state: $state
      submitBioForApproval: $submitBioForApproval
    ) {
      errors {
        path
        message
      }
    }
  }
`

export const SEND_FORGOT_PASSWORD_MUTATION = gql`
         mutation ForgotPasswordRequest($id: ID) {
           forgotAccountPassword(id: $id) {
             errors {
               path
               message
             }
           }
         }
       `

export const USER_DETAILS_WITHOUT_COHORTS_QUERY = gql`
  query UserDetails($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      role
      createdAt
      newEmail
      confirmationToken
      profile {
        __typename
        ... on AppAdmin {
          id
          mobileNumber
          mailingAddress {
            id
            address1
            address2
            city
            state
            postCode
          }
        }
        ... on Student {
          id
          projects {
            id
            name
            updatedAt
            cohort {
              name
              endDate
              startDate
              nextFacilitatorVisit
            }
            teacher {
              id
              userId
              name
            }
            facilitator {
              id
              userId
              name
              email
              mobileNumber
            }
            photos {
              id
              url
              isShortListed
            }
          }
          cohorts {
            id
            name
            school {
              id
              name
            }
          }
          attribution
          instagramHandle
          dob
          mobileNumber
        }
        ... on Teacher {
          id
          attribution
          myProjects {
            id
            name
            updatedAt
            teacher {
              id
              name
            }
            facilitator {
              id
              name
            }
            photos {
              id
            }
          }
          mailingAddress {
            id
            address1
            address2
            city
            state
            postCode
          }
          mobileNumber
          cohorts {
            id
            school {
              id
              name
            }
          }
        }
        ... on Facilitator {
          id
          provisionalBio
          bio
          bioNeedsApproval
          attribution
          myProjects {
            id
            name
            updatedAt
            teacher {
              id
              name
            }
            facilitator {
              id
              name
            }
            photos {
              id
            }
          }
          instagramHandle
          website
          mailingAddress {
            id
            address1
            address2
            city
            state
            postCode
          }
          mobileNumber
          cohorts {
            id
            school {
              id
              name
            }
          }
        }
        ... on Curator {
          id
          provisionalBio
          bio
          bioNeedsApproval
          cohorts {
            id
            name
            startDate
            endDate
            nextFacilitatorVisit
            reviewed
            school {
              id
              name
            }
            assignment {
              id
              name
            }
          }
          instagramHandle
          website
          mailingAddress {
            id
            address1
            address2
            city
            state
            postCode
          }
          mobileNumber
        }
      }
    }
  }
` 
export const GET_COHORT_LIST_QUERY = gql`
  query PaginatedCohorts(
    $id: Int
    $type: String!
    $search: String
    $listType: String!
    $cursor: String
  ) {
    getCohortsConnectionByUser(
      id: $id
      type: $type
      first: 9
      after: $cursor
      search: $search
      listType: $listType
    ) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          allVisitsScheduled
          startDateApproval
          endDateApproval
          approved
          name
          reviewed
          summarySent
          school {
            id
            name
            mailingAddress {
              id
              city
              state
            }
          }
          students {
            id
          }
          assignment {
            id
            name
          }
          startDate
          endDate
          nextFacilitatorVisit
          introVisit {
            id
            date
            createdAt
          }
          midVisit {
            id
            date
            createdAt
          }
          secondMidVisit {
            id
            date
            createdAt
          }
          finalVisit {
            id
            date
            createdAt
          }
          name
          projects {
            id
          }
        }
      }
    }
  }
`