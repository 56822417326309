import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import UseAgreementContent from '../ui/organisms/UseAgreementContent.js'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout.js'
import moment from 'moment'
import { waPrivateDecipher } from '../utils/commonFunctions.js'
import ErrorPage from './ErrorPage.jsx'
import PagesMetaTags from '../utils/PagesMetaTags.js'
import { withRouter } from '../utils/customHoc.js'

const QUERY = gql`
  query UseAgreement($uuid: String!) {
    useAgreement(uuid: $uuid) {
      signed
      signeeFirstName
      signeeLastName
      wantsUpdates
      email
      phoneNumber
      address1
      address2
      city
      state
      photo {
        id
        caption
        title
        altText
        mrAdminNotes
        isShortListed
        attribution
        url
        cdnOriginalUrl
        project {
          id
          student {
            id
            name
            dob
            email
            mobileNumber
          }
        }
        awards {
          id
          awardClass
          nominatorInitials
          nominatorUserId
          nominatorName
          nominatorType
          isEdited
          editedOn
        }
      }
    }
  }
`

const PHOTO_QUERY = gql`
  query PhotosByIds($ids: [ID!]) {
    photosByIds(ids: $ids) {
      id
      caption
      title
      altText
      mrAdminNotes
      isShortListed
      attribution
      url
      cdnOriginalUrl
      summarySent
      project {
        id
        student {
          id
          name
          dob
          email
          mobileNumber
        }
        cohort {
          id
          endDate
          reviewed
          curatorReviews {
            id
            reviewerId
          }
        }
      }
      awards {
        id
        awardClass
        nominatorInitials
        nominatorUserId
        nominatorName
        nominatorType
        isEdited
        editedOn
      }
      useAgreements {
        id
        linkToFile
        uuid
        uaStatus
        adminDecision
        createdAt
        signed
      }
    }
  }
`

class UseAgreement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: []
    }
  }

  isSummarySent = (photos) => {
    let photosSummarySent = photos.filter(photo => !photos.summarySent)
    return photosSummarySent.length > 0
  }

  render(photos, projectId) {
    const {params} = this.props
    return (
      <Layout>
        <PagesMetaTags index={false}/>
        <Query query={params.agreementUuid ? QUERY : PHOTO_QUERY} variables={params.agreementUuid ? { uuid: params.agreementUuid } : { ids: waPrivateDecipher(params.photoId).split("-") }}>
          {({ loading, error, data }) => {
            if (loading) return <Loading />
            if (error) return <ErrorPage errorCode='403' withInPage={true}/>
            if (data) {
              if (params.agreementUuid) {
                const useAgreement = data.useAgreement
                if (useAgreement.photo) {
                  return (
                    <UseAgreementContent
                      agreementUuid={params.agreementUuid}
                      useAgreementValue={useAgreement.signed}
                      // project={useAgreement.project}
                      photoId={useAgreement.photo.id}
                      photo={useAgreement.photo}
                      student={useAgreement.photo.project.student}
                      agreement={useAgreement}
                      type='existingUa'
                    />
                  )
                } else return <Loading />
              } else {
                const photos = data.photosByIds
                if (photos) {
                  const birthDay =
                  !!moment().diff(photos[0].project.student.dob, 'years') === false
                    ? 0
                    : moment().diff(photos[0].project.student.dob, 'years')
                    if (birthDay > 17 || !this.isSummarySent(photos) ) {
                      return (
                        <UseAgreementContent
                          useAgreementValue={null}
                          type='newUa'
                          student={photos[0].project.student}
                          photoIds={waPrivateDecipher(params.photoId).split("-")}
                          photos={photos}
                          projectId={photos[0].project.id}
                        />
                      )
                    } else {
                      return <ErrorPage errorCode='403' withInPage={true}/>
                    }
                } else return <Loading />
              }
            }
          }}
        </Query>
      </Layout>
    )
  }
}

export default withRouter(UseAgreement)
