import React, { useState, useEffect, Fragment } from 'react'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import UserListRowStudents from '../molecules/UserListRowStudents.js'
import SendMessageModal from '../molecules/SendMessageModal.js'
import { observer, inject } from 'mobx-react'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import Loading from '../../ui/elements/Loading.js'
import ListSearchBar from '../elements/ListSearchBar.js'
import EmptyListDesign from '../molecules/EmptyListDesign.js'
import { getTextContentByPage } from '../../content/textContent'
import { Box, Grid, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc.js'
import CustomPagination from '../molecules/CustomPagination.js'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

const GET_STUDENTS_QUERY = gql`
  query PaginatedStudents($search: String, $batchSize: Int!, $baseBatchSize: Int!, $step: Int!) {
    studentsConnection(search: $search, batchSize: $batchSize, baseBatchSize: $baseBatchSize, step: $step) {
      id
      userId
      name
      lastName
      lastSeenAt
      createdAt
      totalAwardedPhotographs
      cohorts {
        id
        school {
          id
          name
        }
      }
      projects {
        id
      }
    }
    studentCount(search: $search)
  }
`
const BATCH_SIZE = 100

const UserListStudents = (props) => {
  const {location} = props
  const [checkedReminder, setCheckedReminder] = useState(false)
  const [checkedBoxes, setNewCheckedBoxes] = useState({})
  const [modalSendMessage, setModalSendMessage] = useState(false)
  const [studentCount, setStudentCount] = useState(0)
  const [searchKey, setSearchKey] = useState('')
  const [step, setStep] = useState(0)
  const [batchSize] = useState(BATCH_SIZE)
  const [baseBatchSize] = useState(BATCH_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [students, setStudents] = useState([])
  const navigate = useNavigate()

  const { loading } = useQuery(GET_STUDENTS_QUERY,
    {variables: {
      search: searchKey,
      step: step,
      batchSize: batchSize,
      baseBatchSize: baseBatchSize
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setStudents( data)
      setStudentCount( data.studentCount)
      setTotalPages(Math.ceil(data.studentCount / batchSize))
      setCheckedBoxes(data.studentsConnection)
    }
  })

  const checkAllCheckedBoxes = () => {
    const listStudents = students.studentsConnection ? students.studentsConnection : []
    const newState = Object.assign({}, checkedBoxes)
    const isSelected = !checkedReminder ? true : false
    listStudents.forEach(student => (
      newState[student.id] = isSelected
    ))
    setNewCheckedBoxes(newState)
    setCheckedReminder(isSelected)
  }

  const setCheckedBoxes = (students) => {
    const checkedBoxes = {}
    students.forEach(student => (checkedBoxes[student.id] = false))
    setNewCheckedBoxes(checkedBoxes)
  }

  const updatedCheckedBoxes = studentId => {
    const newState = Object.assign({}, checkedBoxes)
    newState[studentId] = newState[studentId] ? false : true
    setNewCheckedBoxes(newState)
    setCheckedReminder(Object.values(newState).includes(false) ? false : true)
  }

  useEffect(() => {
    setStep(isNaN(parseInt(props?.params?.page)) ? 0 : (parseInt(props?.params?.page) - 1))
  }, [props?.params])

  const handlePageChange = page => {
    setCheckedReminder(false)
    // Update the URL
    const pathParts = location.pathname.split('/').filter(Boolean)
    if (!isNaN(pathParts[pathParts.length - 1])) {
      pathParts.pop()
    }
    const newPath = `/${pathParts.join('/')}/${page + 1}`
    navigate(newPath)
  }

  useEffect(() => {
    if(searchKey) {
      handlePageChange(0)
    }
  }, [searchKey])

  useEffect(() => {
    setTotalPages(Math.ceil(studentCount / batchSize))
  }, [batchSize, studentCount])

  const modalClose = () => { setModalSendMessage(false) }

  const listStudents = students.studentsConnection ? students.studentsConnection : []
  const selectedStudents = listStudents.filter(student => checkedBoxes[student.id]).map(student => student.userId)
  return(
      <Box sx={{ pb: { xs: 4 } }}>
        <Box sx={{ px: { xs: 4, md: 0 } }}>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <Typography variant='h1' className='bold'>
                Students ({studentCount})
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              xs={12}
              sx={{
                textAlign: {
                  xs: 'rignt',
                  sm: 'right',
                  md: 'right'
                }
              }}
            >
              <Grid
                container
                spacing={{
                  xs: 3,
                  sm: 4,
                  md: 3
                }}
                alignItems='center'
              >
                <Grid
                  item
                  md={12}
                  sm={4}
                  xs={12}
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'none',
                      md: 'block'
                    }
                  }}
                >
                  <Button
                    title='Message Selected People'
                    disabled={selectedStudents.length < 1}
                    onClick={() => setModalSendMessage(true)}
                    sx={{
                      ml: 2,
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 'fit-content'
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'block',
                      md: 'none'
                    }
                  }}
                >
                  <Box sx={{ mt: { sm: -1 } }}>
                    <ListSearchBar
                      placeHolder='Search for a Student Name'
                      id='student-search'
                      onChange={e => setSearchKey(e.target.value)}
                      value={searchKey}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{
                    display: {
                      xs: 'block',
                      sm: 'block',
                      md: 'none'
                    }
                  }}
                >
                  <Button
                    title='Message Selected People'
                    disabled={selectedStudents.length < 1}
                    onClick={() =>setModalSendMessage(true)}
                    sx={{
                      width: {
                        xs: '100%',
                        sm: '100%',
                        md: 'fit-content'
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'none',
                      md: 'block'
                    }
                  }}
                >
                  <Box mt={{ xs: '-8px', sm: 'auto', md: '-6px' }}>
                    <ListSearchBar
                      placeHolder='Search for a Student Name'
                      id='student-search'
                      onChange={e => setSearchKey(e.target.value)}
                      value={searchKey}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SendMessageModal
            show={modalSendMessage}
            onHide={modalClose}
            userIds={selectedStudents}
          />
        </Box>
        { loading ? (
          <Box className='reivew-list-loader--wrapper'>
            <Loading />
          </Box>
        ) : null}
        { listStudents.length > 0 ? (
            <Fragment>
              <Box
                sx={{
                  mt: { xs: 5, md: 4, lg: 5 },
                  mb: { xs: '1.125rem', md: 2 },
                  px: { xs: 3, md: 4 }
                }}
              >
                <Grid container alignItems='center'>
                  <Grid item md={1} sm={1} xs={1}>
                    <Box sx={{ textAlign: 'center' }}>
                      <FormCheckbox
                        checked={checkedReminder}
                        onChange={checkAllCheckedBoxes}
                        value='checkedReminder'
                        color='primary'
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={9}
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'block'
                      }
                    }}
                  >
                    <Typography variant='dataLabel' component={'p'}>
                      User Details
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={5}
                    xs={0}
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'block'
                      }
                    }}
                  >
                    <Typography variant='dataLabel' component={'p'}>
                      Projects
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sm={0}
                    xs={0}
                    sx={{
                      display: {
                        xs: 'none',
                        lg: 'block'
                      }
                    }}
                  >
                    <Typography variant='dataLabel' component={'p'}>
                      Activity
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sx={{
                      display: {
                        xs: 'block',
                        lg: 'none'
                      },
                      ml: { xs: '3px' }
                    }}
                  >
                    <Typography variant='dataLabel' component={'p'}>
                      Select All Rows
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              {listStudents.map(student => (
                <UserListRowStudents
                  key={student.id}
                  lastSeenAt={student.lastSeenAt}
                  student={student}
                  checked={checkedBoxes[student.id] || false}
                  onChecked={() => updatedCheckedBoxes(student.id)}
                  userId={student.userId}
                />
              ))}
              <Box sx={{ mt: { xs: 4 } }}>
                <CustomPagination totalPages={totalPages} pageNum={props?.params?.page ? parseInt(props?.params?.page) : 1} onChange={handlePageChange}/>
              </Box>
            </Fragment>
          ) : (
            <Box sx={{ mt: { xs: 4 }, px: { xs: 2, md: '0' } }}>
              <EmptyListDesign
                heading={getTextContentByPage(
                  'USERLIST',
                  props.role.toUpperCase(),
                  'STUDENTLIST_EMPTYINTRO'
                )}
                subheading={getTextContentByPage(
                  'USERLIST',
                  props.role.toUpperCase(),
                  'STUDENTLIST_EMPTYSUBHEADING'
                )}
              />
            </Box>
          )
        }
      </Box>
  )

}

export default withRouter(inject('userStudentsFilterStore')(observer(withApollo(UserListStudents))))
