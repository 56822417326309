import React, { Component } from 'react'
import '../organisms/projectprogress.scss'
import './modals.scss'
import Button from '../elements/Button.js'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import FormField from '../elements/FormField.js'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import libphonenumber from 'google-libphonenumber'
import ModelReleaseUploader from '../organisms/ModelReleaseUploader'
import { Download } from 'react-feather'
import { DirectUpload } from 'activestorage'
import { inject, observer } from 'mobx-react'
import DownloadUtils from '../../utils/DownloadUtils'
import FormHelperText from '@mui/material/FormHelperText'
import { Spinner } from 'react-bootstrap'
import GATag from '../../utils/GATag'
import { Typography, Box, Grid } from '@mui/material'
import BaseModal from '../template/BaseModal'

const CreateModelReleaseMutation = gql`
  mutation CreateModelRelease(
    $photoId: ID!
    $modelReleaseType: String!
    $modelFirstName: String
    $modelLastName: String
    $sendTo: String
    $isMinor: Boolean
    $isDeceased: Boolean
    $language: String
    $fileSignedId: String
    $signeeFirstName: String
    $signeeLastName: String
    $existingMrUuid: String
  ) {
    createModelRelease(
      photoId: $photoId
      modelReleaseType: $modelReleaseType
      modelFirstName: $modelFirstName
      modelLastName: $modelLastName
      sendTo: $sendTo
      isMinor: $isMinor
      isDeceased: $isDeceased
      language: $language
      fileSignedId: $fileSignedId
      signeeFirstName: $signeeFirstName
      signeeLastName: $signeeLastName
      existingMrUuid: $existingMrUuid
    ) {
      id
    }
  }
`

const ManageModelReleaseMutation = gql`
  mutation ManageModelRelease(
    $photoId: ID!
    $modelUuid: String!
    $manageType: String!
    $sendType: String!
    $modelFirstName: String
    $modelLastName: String
    $sendTo: String
    $isMinor: Boolean
    $isDeceased: Boolean
    $fileSignedId: String
    $signeeFirstName: String
    $signeeLastName: String
  ) {
    manageModelRelease(
      photoId: $photoId
      modelUuid: $modelUuid
      manageType: $manageType
      sendType: $sendType
      sendTo: $sendTo
      modelFirstName: $modelFirstName
      modelLastName: $modelLastName
      isMinor: $isMinor
      isDeceased: $isDeceased
      fileSignedId: $fileSignedId
      signeeFirstName: $signeeFirstName
      signeeLastName: $signeeLastName
    ) {
      id
    }
  }
`

const theme = createTheme({
  palette: {
    primary: {
      main: '#0076BB'
    }
  },
  typography: {
    useNextVariants: true,
    suppressDeprecationWarnings: true
  }
})

const styles = theme => ({
  root: {
    display: 'flex'
  },
  formControl: {
    margin: 0
  },
  group: {
    margin: 0
  }
})

const uploadFile = (file, onMutate, mrDetails) => {
  // TODO: Make this URL not static
  const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
  const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'photo')

  upload.create((error, blob) => {
    if (error) {
      // Handle the error
    } else {
      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow
      onMutate({
        variables: {
          ...mrDetails,
          fileSignedId: blob.signed_id
        },
        refetchQueries: [`PhotoDetails`]
      })
      // TODO redirect to photo detail page?
    }
  })
}

class ModelReleaseModal extends Component {
  constructor(props) {
    super(props)
    const { role } = props.userStore
    this.state = {
      submitted: false,
      errors: {},
      modelFirstName: '',
      modelLastName: '',
      signeeFirstName: '',
      signeeLastName: '',
      sendTo: '',
      modelReleaseType: ['appadmin', 'student'].includes(role) ? 'email' : 'pdf',
      isMinor: false,
      isDeceased: false,
      modelUuid: props.modealUuid,
      signedFiles: [],
      isFromSubmitting: false
    }
    this.initialState = this.state
    this.handleModelReleaseTypeChange = this.handleModelReleaseTypeChange.bind(this)
    this.toggleIsMinor = this.toggleIsMinor.bind(this)
    this.toggleIsDeceased = this.toggleIsDeceased.bind(this)
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this)
    this.download = this.download.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.modelOp === 'add') {
      //this.setState(this.initialState)
    } else {
      if (nextProps.modelRelease) {
        let releaseType = nextProps.modelRelease.pdfUploaded
          ? 'pdf'
          : nextProps.modelRelease.sentViaSms
          ? 'text'
          : 'email'
        let releaseDetails = nextProps.modelRelease.pdfUploaded
          ? nextProps.modelRelease.linkToFile
          : nextProps.modelRelease.sentViaSms
          ? nextProps.modelRelease.phoneNumber
          : nextProps.modelRelease.email
        this.setState({
          ...this.state,
          ...nextProps.modelRelease,
          modelReleaseType: releaseType,
          sendTo: releaseDetails
        })
      }
    }
  }

  phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }
  handleModelReleaseTypeChange(value) {
    delete this.state.errors.sendTo
    this.setState({ sendTo: '', modelReleaseType: value })
  }
  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    this.validateChange(e)
  }
  validateChange(e) {
    const fieldName = e.target.name
    const value = e.target.value
    var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    switch (fieldName) {
      case 'modelFirstName':
        if (value.length < 1) {
          this.setState({
            errors: { ...this.state.errors, modelFirstName: 'Please enter the model’s first name.' }
          })
        } else {
          this.setState({ errors: { ...this.state.errors, modelFirstName: null } })
        }
        break
      case 'modelLastName':
        if (value.length < 1) {
          this.setState({
            errors: { ...this.state.errors, modelLastName: 'Please enter the model’s last name.' }
          })
        } else {
          this.setState({ errors: { ...this.state.errors, modelLastName: null } })
        }
        break
      case 'signeeFirstName':
        if (this.state.isMinor || this.state.isDeceased) {
          if (value.length < 1) {
            this.setState({
              errors: {
                ...this.state.errors,
                signeeFirstName:
                  this.state.isMinor && !this.state.isDeceased
                    ? 'Please enter the guardian’s first name.'
                    : !this.state.isMinor && this.state.isDeceased
                    ? 'Please enter the next of kin’s first name.'
                    : 'Please enter the legal next of kin’s first name.'
              }
            })
          } else {
            this.setState({ errors: { ...this.state.errors, signeeFirstName: null } })
          }
        } else {
          this.setState({ errors: { ...this.state.errors, signeeFirstName: null } })
        }
        break
      case 'signeeLastName':
        if (this.state.isMinor || this.state.isDeceased) {
          if (value.length < 1) {
            this.setState({
              errors: {
                ...this.state.errors,
                signeeLastName:
                  this.state.isMinor && !this.state.isDeceased
                    ? 'Please enter the guardian’s last name.'
                    : !this.state.isMinor && this.state.isDeceased
                    ? 'Please enter the next of kin’s last name.'
                    : "Please enter the legal next of kin's last name."
              }
            })
          } else {
            this.setState({ errors: { ...this.state.errors, signeeLastName: null } })
          }
        } else {
          this.setState({ errors: { ...this.state.errors, signeeLastName: null } })
        }
        break
      case 'sendTo':
        let sentToError = null
        if (this.state.modelReleaseType === 'email') {
          if (!emailpattern.test(value)) {
            sentToError = 'Please enter a valid email address.'
          } else if (
            value.toLowerCase() === this.props.studentDetails.student.email.toLowerCase()
          ) {
            sentToError =
              this.state.isMinor && !this.state.isDeceased
                ? 'Student and guardian email address cannot match.'
                : !this.state.isMinor && this.state.isDeceased
                ? 'Student and next of kin email address cannot match.'
                : this.state.isMinor && this.state.isDeceased
                ? 'Student and legal next of kin email address cannot match.'
                : 'Student and model email address cannot match.'
          }
        } else if (this.state.modelReleaseType === 'text') {
          try {
            let number = this.phoneUtil.parse(value, 'US')
            if (!(value.length === 10 && this.phoneUtil.isValidNumber(number))) {
              sentToError = 'Please enter a valid mobile number.'
            } else if (value === this.props.studentDetails.student.mobileNumber) {
              sentToError =
                this.state.isMinor && !this.state.isDeceased
                  ? 'Student and guardian mobile number cannot match.'
                  : !this.state.isMinor && this.state.isDeceased
                  ? 'Student and next of kin mobile number cannot match.'
                  : this.state.isMinor && this.state.isDeceased
                  ? 'Student and legal next of kin mobile number cannot match.'
                  : 'Student and model mobile number cannot match.'
            }
          } catch (error) {
            sentToError = 'Please enter a valid mobile number.'
          }
        }
        this.setState({ errors: { ...this.state.errors, sendTo: sentToError } })
        break
      default:
        return
    }
  }

  toggleIsMinor(value) {
    this.setState({ isMinor: value })
  }
  toggleIsDeceased(value) {
    this.setState({ isDeceased: value })
  }
  isFormFilled() {
    var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const { role } = this.props.userStore
    return (
      this.state.modelFirstName &&
      this.state.modelFirstName.trim() &&
      this.state.modelFirstName.length > 0 &&
      this.state.modelLastName &&
      this.state.modelLastName.trim() &&
      this.state.modelLastName.length > 0 &&
      ((this.state.isMinor || this.state.isDeceased) && this.state.modelReleaseType !== 'pdf'
        ? this.state.signeeFirstName &&
          this.state.signeeFirstName.length > 0 &&
          this.state.signeeLastName &&
          this.state.signeeLastName.length > 0
        : true) &&
      (['appadmin', 'student'].includes(role)
        ? this.state.modelReleaseType === 'email'
          ? emailpattern.test(this.state.sendTo) &&
            this.state.sendTo.toLowerCase() !==
              this.props.studentDetails.student.email.toLowerCase()
          : this.state.modelReleaseType == 'text'
          ? this.state.sendTo.length === 10 &&
            this.state.sendTo !== this.props.studentDetails.student.mobileNumber
          : this.state.modelReleaseType === 'pdf'
          ? this.state.signedFiles.length > 0
          : true
        : this.state.signedFiles.length > 0)
    )
  }
  handleFormSubmit(onMutate, language) {
    // e.preventDefault()
    let errors = {}
    if (!this.state.modelFirstName) {
      errors.modelFirstName = 'Please enter the model’s first name.'
    }
    if (!this.state.modelLastName) {
      errors.modelLastName = 'Please enter the model’s last name.'
    }
    if (this.state.modelReleaseType !== 'pdf') {
      if (!this.state.sendTo) {
        errors.sendTo = 'Cannot be blank'
      }
      if (this.state.modelReleaseType === 'text') {
        try {
          let number = this.phoneUtil.parse(this.state.sendTo, 'US')
          if (!(this.state.sendTo.length === 10 && this.phoneUtil.isValidNumber(number))) {
            errors.sendTo = 'Please enter a valid mobile number.'
          } else if (this.state.sendTo === this.props.studentDetails.student.mobileNumber) {
            errors.sendTo =
              this.state.isMinor && !this.state.isDeceased
                ? 'Student and guardian mobile number cannot match.'
                : !this.state.isMinor && this.state.isDeceased
                ? 'Student and next of kin mobile number cannot match.'
                : this.state.isMinor && this.state.isDeceased
                ? 'Student and legal next of kin mobile number cannot match.'
                : 'Student and model mobile number cannot match.'
          }
        } catch (error) {
          errors.sendTo = 'Please enter a valid mobile number.'
        }
      }
      if (this.state.modelReleaseType === 'email') {
        var emailpattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!emailpattern.test(this.state.sendTo)) {
          errors.sendTo = 'Please enter a valid email address.'
        } else if (
          this.state.sendTo.toLowerCase() === this.props.studentDetails.student.email.toLowerCase()
        ) {
          errors.sendTo =
            this.state.isMinor && !this.state.isDeceased
              ? 'Student and guardian email address cannot match.'
              : !this.state.isMinor && this.state.isDeceased
              ? 'Student and next of kin email address cannot match.'
              : this.state.isMinor && this.state.isDeceased
              ? 'Student and legal next of kin email address cannot match.'
              : 'Student and model email address cannot match.'
        }
      }
      if (this.state.isMinor || this.state.isDeceased) {
        if (!this.state.signeeFirstName) {
          errors.signeeFirstName =
            this.state.isMinor && !this.state.isDeceased
              ? 'Please enter the guardian’s first name.'
              : !this.state.isMinor && this.state.isDeceased
              ? 'Please enter the next of kin’s first name.'
              : 'Please enter the legal next of kin’s first name.'
        }
        if (!this.state.signeeLastName) {
          errors.signeeLastName =
            this.state.isMinor && !this.state.isDeceased
              ? 'Please enter the guardian’s last name.'
              : !this.state.isMinor && this.state.isDeceased
              ? 'Please enter the next of kin’s last name.'
              : "Please enter the legal next of kin's last name."
        }
      }
    } else {
      if (this.state.signedFiles.length <= 0) {
        errors.pdfError = 'File not uploaded.'
      }
    }
    this.setState({ errors: { ...errors }, isFromSubmitting: true })
    if (Object.keys(errors).length === 0) {
      let modelVars = {}
      if (this.props.modelOp === 'edit') {
        modelVars = {
          photoId: this.props.photoId,
          modelUuid: this.state.uuid,
          manageType: 'edit',
          sendType: this.state.modelReleaseType,
          modelFirstName: this.state.modelFirstName,
          modelLastName: this.state.modelLastName,
          signeeFirstName: this.state.signeeFirstName,
          signeeLastName: this.state.signeeLastName,
          sendTo: this.state.sendTo,
          isMinor: this.state.isMinor,
          isDeceased: this.state.isDeceased
        }
        if (this.state.signedFiles.length > 0) {
          Array.from(this.state.signedFiles).forEach(file => {
            uploadFile(file, onMutate, modelVars)
            this.props.refetchPhoto()
          })
        } else {
          onMutate({
            variables: {
              ...modelVars
            },
            refetchQueries: [`PhotoDetails`]
          })
        }
      } else {
        modelVars = {
          photoId: this.props.photoId,
          modelUuid: this.state.uuid,
          modelReleaseType: this.state.modelReleaseType,
          modelFirstName: this.state.modelFirstName,
          modelLastName: this.state.modelLastName,
          signeeFirstName: this.state.signeeFirstName,
          signeeLastName: this.state.signeeLastName,
          sendTo: this.state.sendTo,
          isMinor: this.state.isMinor,
          isDeceased: this.state.isDeceased,
          language: language,
          existingMrUuid: this.props.existingMrUuid
        }
        if (this.state.signedFiles.length > 0) {
          Array.from(this.state.signedFiles).forEach(file => {
            uploadFile(file, onMutate, modelVars)
            this.props.refetchPhoto()
          })
        } else {
          onMutate({
            variables: {
              ...modelVars
            },
            refetchQueries: [`PhotoDetails`, `ProjectDetails`]
          })
        }
      }
    }
  }
  handleSubmitSuccess() {
    this.setState({ ...this.initialState, submitted: true, isFromSubmitting: false })
    this.props.onSuccessCallback('Request sent by '.concat(this.state.modelReleaseType))
    this.props.refetchPhoto && this.props.refetchPhoto()
    this.props.refetchMrData && this.props.refetchMrData()
  }
  uploadFiles = files => {
    this.setState({ signedFiles: files })
  }
  download = async name => {
    const { resourceStore } = this.props
    let downloadFor = null
    if (name === 'Model Release Form for Adults English') {
      // window.ga('send', 'event', 'model-release', 'pdf-downloaded', 'adult', 1)
      downloadFor = 'adult'
    } else if (name === 'Model Release Form for Minors English') {
      // window.ga('send', 'event', 'model-release', 'pdf-downloaded', 'minor', 1)
      downloadFor = 'minor'
    } else if (name === 'Model Release Form for Deceased Persons English') {
      // window.ga('send', 'event', 'model-release', 'pdf-downloaded', 'deceased', 1)
      downloadFor = 'deceased'
    }
    GATag.setEvent({
      category: 'model-release',
      action: 'pdf-downloaded',
      label: downloadFor,
      value: 1
    })
    let newUrl = resourceStore.getResourceUrlByName(name)
    DownloadUtils.downloadFileAndSaveAs(newUrl, name)
  }
  render() {
    const {
      handleFormChange,
      handleModelReleaseTypeChange,
      toggleIsMinor,
      toggleIsDeceased,
      handleSubmitSuccess
    } = this
    const { classes } = this.props
    const { role } = this.props.userStore
    const handleClose = () => {
      this.setState({ ...this.initialState, isFromSubmitting: false, show: false })
      this.props.onHide(false)
    }
    return (
      <Mutation
        mutation={
          this.props.modelOp === 'edit' ? ManageModelReleaseMutation : CreateModelReleaseMutation
        }
        onCompleted={() => {
          handleSubmitSuccess()
        }}
      >
        {(onMutate, { data }) => {
          return (
            <BaseModal
              {...this.props}
              show={this.props.show}
              onClick={this.props.onClick}
              value={this.props.value}
              backdropClassName='text_model_class'
              backdrop='static'
              open={this.props.show}
              onClose={handleClose}
              onHide={handleClose}
            >
              <BaseModal.BaseModalHeader>Add a Model Release</BaseModal.BaseModalHeader>
              <BaseModal.BaseModalBody>
                <Box className='invite-student__description'>
                  {role === 'student' ? (
                    <>
                      <Typography
                        variant='paragraph'
                        sx={{
                          mb: '1rem'
                        }}
                        component='p'
                      >
                        Please provide your model’s details below, then either upload a signed form
                        or provide contact details for Working Assumptions to contact the model (or
                        their legal guardian or next of kin) directly.
                      </Typography>
                      <Typography
                        variant='paragraph'
                        sx={{
                          mb: '1rem'
                        }}
                        component='p'
                      >
                        Please let your model, their guardian, or their next of kin know that they
                        will receive a message from Working Assumptions. The message will contain a
                        link to your photograph and a digital Model Release form which they must
                        fill out and submit.
                      </Typography>
                    </>
                  ) : role === 'appadmin' ? (
                    <>
                      <Typography
                        variant='paragraph'
                        sx={{
                          mb: '1rem'
                        }}
                        component='p'
                      >
                        Please provide the model’s details below, then either upload a signed form
                        or provide contact details for Working Assumptions to contact the model (or
                        their legal guardian or next of kin) directly.
                      </Typography>
                      <Typography
                        variant='paragraph'
                        sx={{
                          mb: '1rem'
                        }}
                        component='p'
                      >
                        Please ask the student to let the model, guardian, or next of kin know that
                        they will receive a message from Working Assumptions. The message will
                        contain a link to the photograph and a digital Model Release form which they
                        must fill out and submit.
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant='paragraph'
                        sx={{
                          mb: '1rem'
                        }}
                        component='p'
                      >
                        Please provide the model’s details below, then upload a signed form on
                        behalf of the student.
                      </Typography>
                    </>
                  )}
                </Box>
                <Box className='invite-student__model-name'>
                  <Typography variant='h3'>
                    What is the name of the person in this photograph?
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      {/* <div className='form-field col--left'> */}
                      <FormField
                        // formFieldId='outlined-fname'
                        formFieldFullWidth='false'
                        formFieldLabel='Model First Name'
                        formFieldVariant='filled'
                        formFieldError={this.state.errors.modelFirstName || null}
                        formFieldClasses={{
                          root: 'text-field__outline'
                        }}
                        formFieldValue={this.state.modelFirstName}
                        formFieldType='name'
                        formFieldName='modelFirstName'
                        onChange={handleFormChange}
                      />
                      {/* </div> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {/* <div className='form-field col--right'> */}
                      <FormField
                        formFieldId='outlined-lname'
                        formFieldVariant='filled'
                        formFieldFullWidth='false'
                        formFieldLabel='Model Last Name'
                        formFieldError={this.state.errors.modelLastName || null}
                        formFieldValue={this.state.modelLastName}
                        formFieldClasses={{
                          root: 'text-field__outline'
                        }}
                        formFieldType='name'
                        formFieldName='modelLastName'
                        onChange={handleFormChange}
                      />
                      {/* </div> */}
                    </Grid>
                  </Grid>
                </Box>
                <Box className='invite-student__model-age'>
                  <Typography variant='h3'>
                    What is the age of the person in this photograph?
                  </Typography>
                  <Grid container className='invite-student__age-buttons' spacing={1}>
                    <Grid item xs={12} md={6} className='outline-button'>
                      <Button
                        // variant='outline-default'
                        className={'outline-buttons__left-sm'.concat(
                          this.state.isMinor === false ? '--selected' : ''
                        )}
                        boldTitle='18 or Over'
                        onClick={() => toggleIsMinor(false)}
                        btnWidth={'100%'}
                        btnHeight='85px'
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className='outline-button'>
                      <Button
                        // variant='outline-default'
                        className={'outline-buttons__right-sm'.concat(
                          this.state.isMinor === true ? '--selected' : ''
                        )}
                        boldTitle='Under 18'
                        onClick={() => toggleIsMinor(true)}
                        btnWidth={'100%'}
                        btnHeight='85px'
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className='invite-student__model-age'>
                  <Typography variant='h3'>
                    Is the person in this photograph living or deceased?
                  </Typography>
                  <Grid container className='invite-student__age-buttons' spacing={1}>
                    <Grid item xs={12} md={6} className='outline-button'>
                      <Button
                        // variant='outline-default'
                        className={'outline-buttons__left-sm'.concat(
                          this.state.isDeceased === false ? '--selected' : ''
                        )}
                        boldTitle='Living'
                        onClick={() => toggleIsDeceased(false)}
                        btnWidth={'100%'}
                        btnHeight='85px'
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className='outline-button'>
                      <Button
                        // variant='outline-default'
                        className={'outline-buttons__right-sm'.concat(
                          this.state.isDeceased === true ? '--selected' : ''
                        )}
                        boldTitle='Deceased'
                        onClick={() => toggleIsDeceased(true)}
                        btnWidth={'100%'}
                        btnHeight='85px'
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className={
                    ['appadmin', 'student'].includes(role) ? 'model-release__form-parent' : ''
                  }
                >
                  {this.state.isMinor === true &&
                  this.state.isDeceased === true &&
                  ['appadmin', 'student'].includes(role) ? (
                    <Typography variant='h3' className='semi-strong small_size'>
                      Because the deceased person in this photograph was under 18, the request must
                      be sent to their legal next of kin and guardian.
                    </Typography>
                  ) : this.state.isMinor === true &&
                    this.state.isDeceased === false &&
                    ['appadmin', 'student'].includes(role) ? (
                    <Typography variant='h3' className='semi-strong small_size'>
                      Because the person in this photograph is under 18, the request must be sent to
                      their parent or legal guardian.
                    </Typography>
                  ) : this.state.isDeceased === true && ['appadmin', 'student'].includes(role) ? (
                    <Typography variant='h3' className='semi-strong small_size'>
                      Because the person in this photograph is deceased, the request must be sent to
                      their legal next of kin.
                    </Typography>
                  ) : null}
                  {['appadmin', 'student'].includes(role) ? (
                    <Typography variant='h3'>How will you provide the Model Release?</Typography>
                  ) : null}
                  <form className='project-progress__form-container' noValidate autoComplete='on'>
                    <div className='model-release__form'>
                      <div
                        className={
                          ['appadmin', 'student'].includes(role) ? 'invite-student__model-age' : ''
                        }
                      >
                        {['appadmin', 'student'].includes(role) ? (
                          <div className={classes.root}>
                            <MuiThemeProvider theme={theme}>
                              <Grid
                                container
                                className='model-release-type-buttons-containers'
                                rowSpacing={1}
                              >
                                <Grid item xs={12} className='outline-button'>
                                  <Button
                                    // variant='outline-default'
                                    className={'model-release-type-buttons outline-buttons'.concat(
                                      this.state.modelReleaseType === 'email' ? '--selected' : ''
                                    )}
                                    boldTitle={
                                      role === 'appadmin'
                                        ? 'Send an email on behalf of the student'
                                        : 'Have Working Assumptions send an email'
                                    }
                                    onClick={() => handleModelReleaseTypeChange('email')}
                                    btnWidth={'100%'}
                                    btnHeight='85px'
                                  />
                                </Grid>
                                <Grid item xs={12} className='outline-button'>
                                  <Button
                                    // variant='outline-default'
                                    className={'model-release-type-buttons outline-buttons'.concat(
                                      this.state.modelReleaseType === 'text' ? '--selected' : ''
                                    )}
                                    boldTitle={
                                      role === 'appadmin'
                                        ? 'Send a text message on behalf of the student'
                                        : 'Have Working Assumptions send a text message'
                                    }
                                    onClick={() => handleModelReleaseTypeChange('text')}
                                    btnWidth={'100%'}
                                    btnHeight='85px'
                                  />
                                </Grid>
                                <Grid item xs={12} className='outline-button'>
                                  <Button
                                    // variant='outline-default'
                                    className={'model-release-type-buttons outline-buttons'.concat(
                                      this.state.modelReleaseType === 'pdf' ? '--selected' : ''
                                    )}
                                    boldTitle='Download a form and upload the signed copy to Working Assumptions'
                                    onClick={() => handleModelReleaseTypeChange('pdf')}
                                    btnWidth={'100%'}
                                    btnHeight='85px'
                                  />
                                </Grid>
                              </Grid>
                            </MuiThemeProvider>
                          </div>
                        ) : null}
                        {(this.state.isMinor || this.state.isDeceased) &&
                        this.state.modelReleaseType !== 'pdf' ? (
                          <div className='invite-student__model-age'>
                            <Typography variant='h3'>
                              What is the model's{' '}
                              {this.state.isMinor
                                ? this.state.isDeceased
                                  ? "legal next of kin's"
                                  : "guardian's"
                                : this.state.isDeceased
                                ? "next of kin's"
                                : ''}{' '}
                              name?
                            </Typography>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={6}>
                                {/* <div className='form-field col--left'> */}
                                <FormField
                                  formFieldId='outlined-fname'
                                  formFieldFullWidth='false'
                                  formFieldLabel={
                                    this.state.isMinor
                                      ? this.state.isDeceased
                                        ? 'Legal Next of Kin First Name'
                                        : 'Guardian First Name'
                                      : this.state.isDeceased
                                      ? 'Next of Kin First Name'
                                      : 'Model First Name'
                                  }
                                  formFieldVariant='filled'
                                  formFieldError={this.state.errors.signeeFirstName || null}
                                  formFieldClasses={{
                                    root: 'text-field__outline'
                                  }}
                                  formFieldValue={this.state.signeeFirstName}
                                  formFieldType='name'
                                  formFieldName='signeeFirstName'
                                  onChange={handleFormChange}
                                />
                                {/* </div> */}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                {/* <div className='form-field col--right'> */}
                                <FormField
                                  formFieldId='outlined-lname'
                                  formFieldVariant='filled'
                                  formFieldFullWidth='false'
                                  formFieldLabel={
                                    this.state.isMinor
                                      ? this.state.isDeceased
                                        ? 'Legal Next of Kin Last Name'
                                        : 'Guardian Last Name'
                                      : this.state.isDeceased
                                      ? 'Next of Kin Last Name'
                                      : 'Model Last Name'
                                  }
                                  formFieldError={this.state.errors.signeeLastName || null}
                                  formFieldValue={this.state.signeeLastName}
                                  formFieldClasses={{
                                    root: 'text-field__outline'
                                  }}
                                  formFieldType='name'
                                  formFieldName='signeeLastName'
                                  onChange={handleFormChange}
                                />
                                {/* </div> */}
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        {this.state.modelReleaseType === 'email' &&
                        ['appadmin', 'student'].includes(role) ? (
                          <div className='invite-student__model-age'>
                            <Typography variant='h3'>
                              What is the model's{' '}
                              {this.state.isMinor
                                ? this.state.isDeceased
                                  ? "legal next of kin's"
                                  : "guardian's"
                                : this.state.isDeceased
                                ? "next of kin's"
                                : ''}{' '}
                              email address?
                            </Typography>
                            <Grid container>
                              <Grid item xs={12} md={6}>
                                <div className='form-field col--left'>
                                  <FormField
                                    formFieldId='outlined-email'
                                    formFieldFullWidth='false'
                                    formFieldLabel={
                                      this.state.isMinor
                                        ? this.state.isDeceased
                                          ? 'Legal Next of Kin Email'
                                          : 'Guardian Email'
                                        : this.state.isDeceased
                                        ? 'Next of Kin Email'
                                        : 'Model Email'
                                    }
                                    formFieldVariant='filled'
                                    formFieldError={this.state.errors.sendTo || null}
                                    formFieldValue={this.state.sendTo}
                                    formFieldClasses={{
                                      root: 'text-field__outline'
                                    }}
                                    formFieldType='email'
                                    formFieldName='sendTo'
                                    formFieldAutoComplete='email'
                                    onChange={handleFormChange}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        {this.state.modelReleaseType === 'text' &&
                        ['appadmin', 'student'].includes(role) ? (
                          <div className='invite-student__model-age'>
                            <Typography variant='h3'>
                              What is the model's{' '}
                              {this.state.isMinor
                                ? this.state.isDeceased
                                  ? "legal next of kin's"
                                  : "guardian's"
                                : this.state.isDeceased
                                ? "next of kin's"
                                : ''}{' '}
                              mobile number?
                            </Typography>
                            <Grid container>
                              <Grid item xs={12} md={6}>
                                <div className='form-field col--left'>
                                  <FormField
                                    formFieldId='outlined-phone-number'
                                    formFieldFullWidth='false'
                                    formFieldVariant='filled'
                                    formFieldLabel={
                                      this.state.isMinor
                                        ? this.state.isDeceased
                                          ? 'Legal Next of Kin Mobile Number'
                                          : 'Guardian Mobile Number'
                                        : this.state.isDeceased
                                        ? 'Next of Kin Mobile Number'
                                        : 'Model Mobile Number'
                                    }
                                    formFieldError={this.state.errors.sendTo || null}
                                    formFieldValue={this.state.sendTo}
                                    formFieldClasses={{
                                      root: 'text-field__outline'
                                    }}
                                    formFieldType='number'
                                    formFieldAutoComplete='tel'
                                    formFieldName='sendTo'
                                    onChange={handleFormChange}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ) : null}
                        {this.state.modelReleaseType === 'pdf' ? (
                          <div className='photo-release__upload-release'>
                            <div className='send-pdf__description'>
                              {role === 'student' ? (
                                <>
                                  <Typography
                                    variant='paragraph'
                                    sx={{
                                      mb: '1rem'
                                    }}
                                    component='p'
                                  >
                                    Please download this form and print it for your model, or send
                                    the digital PDF file to them to fill out and return. Then upload
                                    a photo of the signed printed form or upload the signed PDF.
                                  </Typography>
                                  <Typography
                                    variant='paragraph'
                                    sx={{
                                      mb: '1rem'
                                    }}
                                    component='p'
                                  >
                                    If the person in the photograph is under 18 years old, please
                                    give the Model Release Form for Minors to their parent or legal
                                    guardian.
                                  </Typography>
                                  <Typography
                                    variant='paragraph'
                                    sx={{
                                      mb: '1rem'
                                    }}
                                    component='p'
                                  >
                                    If the person in the photograph is deceased, please give the
                                    Model Release Form for Deceased Persons to the person's legal
                                    next of kin.
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    variant='paragraph'
                                    sx={{
                                      mb: '1rem'
                                    }}
                                    component='p'
                                  >
                                    Please have the student either give the model a printed copy of
                                    the Model Release form to complete and sign, or have the student
                                    send them the digital PDF to complete and return. The student
                                    can then either upload a photo of the signed printed form or
                                    upload the signed PDF.
                                  </Typography>
                                  <Typography
                                    variant='paragraph'
                                    sx={{
                                      mb: '1rem'
                                    }}
                                    component='p'
                                  >
                                    If the person in the photograph is under 18 years old, please
                                    ask the student to give the{' '}
                                    <strong>Model Release Form for Minors</strong> to their parent
                                    or legal guardian.
                                  </Typography>
                                  <Typography
                                    variant='paragraph'
                                    sx={{
                                      mb: '1rem'
                                    }}
                                    component='p'
                                  >
                                    If the person in the photograph is deceased, please ask the
                                    student to give the{' '}
                                    <strong>Model Release Form for Deceased Persons</strong> to the
                                    person's legal next of kin.
                                  </Typography>
                                </>
                              )}
                            </div>
                            <div className='send-pdf__links'>
                              <div
                                onClick={() =>
                                  this.download('Model Release Form for Adults English')
                                }
                              >
                                <div className='send-pdf__links--downloads'>
                                  <Typography variant='h4' className='semi-strong link'>
                                    <Download size={16} /> Download Model Release Form for Adults
                                    (English)
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            <div className='send-pdf__links'>
                              <div
                                onClick={() =>
                                  this.download('Model Release Form for Minors English')
                                }
                              >
                                <div className='send-pdf__links--downloads'>
                                  <Typography variant='h4' className='semi-strong link'>
                                    <Download size={16} /> Download Model Release Form for Minors
                                    (English)
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            <div className='send-pdf__links'>
                              <div
                                onClick={() =>
                                  this.download('Model Release Form for Deceased Persons English')
                                }
                              >
                                <div className='send-pdf__links--downloads'>
                                  <Typography variant='h4' className='semi-strong link'>
                                    <Download size={16} /> Download Model Release Form for Deceased
                                    Persons (English)
                                  </Typography>
                                </div>
                              </div>
                            </div>
                            <div className='send-pdf__submit'>
                              <ModelReleaseUploader
                                photoId={this.props.photoId}
                                parentState={this.state}
                                modelOp={this.props.modelOp}
                                onSuccessCallback={this.handleSubmitSuccess}
                                refetchQueries={this.props.refetchQueries}
                                uploadFiles={this.uploadFiles}
                                secondaryStyle={true}
                              />
                            </div>
                            {this.state.errors.pdfError ? (
                              <FormHelperText className='form-field__error model-release-file-error component-error-text'>
                                {this.state.errors.pdfError}
                              </FormHelperText>
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <BaseModal.BaseModalFooter>
                      <Box
                        sx={{ padding: '24px 0 0', marginTop: '0px', alignContent: 'center' }}
                        className='model-release__buttons'
                      >
                        <Grid
                          container
                          direction='row'
                          sx={{
                            justifyContent: { xs: 'center', sm: 'end' },
                            display: 'flex',
                            flexDirection: 'row-reverse'
                          }}
                        >
                          <Grid item xs={12} sm={6} md={4}>
                            <Button
                              variant='contained'
                              type='submit'
                              disabled={!this.isFormFilled() || this.state.isFromSubmitting}
                              title={
                                this.state.isFromSubmitting ? (
                                  <span className='icon-text-span'>
                                    <Spinner
                                      as='span'
                                      animation='border'
                                      size='sm'
                                      role='status'
                                      aria-hidden='true'
                                    />{' '}
                                    {this.state.modelReleaseType === 'pdf'
                                      ? 'Saving...'
                                      : 'Sending...'}
                                  </span>
                                ) : this.state.modelReleaseType === 'pdf' ? (
                                  'Save and Close'
                                ) : (
                                  'Send Request'
                                )
                              } // in English
                              onClick={() => this.handleFormSubmit(onMutate, 'en')}
                              sx={{ width: { xs: '100%', sm: '100%' }, float: 'right' }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </BaseModal.BaseModalFooter>
                  </form>
                </Box>
              </BaseModal.BaseModalBody>
            </BaseModal>
          )
        }}
      </Mutation>
    )
  }
}

ModelReleaseModal.propTypes = {
  classes: PropTypes.object.isRequired
}

export default inject('userStore', 'resourceStore')(withStyles(styles)(observer(ModelReleaseModal)))
