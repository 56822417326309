import ReactGA from 'react-ga'
import ReactGA4 from 'react-ga4'
import _ from 'lodash'

class GATag {
  static async initialize() {
    const isGA4Enable = _.includes([true, 'true', 1, '1'],  process.env.REACT_APP_ENABLE_GA4) ? true : false
    const MEASUREMENT_ID = isGA4Enable
      ? process.env.REACT_APP_GA4_MEASUREMENT_ID
      : process.env.REACT_APP_GA_TRACKING_ID
    this.GA = isGA4Enable ? ReactGA4 : ReactGA
    this.GA.initialize(MEASUREMENT_ID)
    this.GA.send('pageview')
  }
  static async setTracker(tracker) {
    this.initialize()
    if (Array.isArray(tracker)) {
      tracker.map(t => this.GA.set(t))
    } else {
      this.GA.set(tracker)
    }
  }
  static async setEvent(event) {
    this.initialize()
    this.GA.event({
      category: event.category,
      action: event.action,
      label: event.label || null, // optional
      value: event.value || 99, // optional, must be a number
      nonInteraction: event.nonInteraction || true // optional, true/false
      // transport: "xhr", // optional, beacon/xhr/image
    })
  }
}

export default GATag
