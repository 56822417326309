import React, { Component, Fragment } from 'react'
import Button from '../elements/Button.js'
import { gql } from '@apollo/client'
import Dropzone from 'react-dropzone'
import { DirectUpload } from 'activestorage'
import { Mutation } from '@apollo/client/react/components'
import { Typography } from '@mui/material'
import { observer, inject } from 'mobx-react'
import DeleteRawFileModal from '../molecules/DeleteRawFileModal'
import { Spinner } from 'react-bootstrap'
import _ from 'lodash'

const CREATE_RAW_FILE_MUTATION = gql`
  mutation UploadRawFile($fileSignedId: String!, $photoId: ID) {
    uploadRawFile(fileSignedId: $fileSignedId, photoId: $photoId) {
      id
    }
  }
`

class RawFileUploader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      modalShowConfirm: false,
      uploading: false
    }
    this.modalClose = this.modalClose.bind(this)
  }

  modalClose(toastMessage) {
    this.setState({ modalShow: false, modalShowConfirm: false })
    this.props.showToast && this.props.showToast('success', toastMessage)
    this.props.updateRAW && this.props.updateRAW(0)
  }

  onDrop = async (files, onMutate, type) => {
    this.setState({
      uploading: true
    })
    await Array.from(files).forEach(file =>
      this.uploadFile(
        file,
        onMutate,
        parseInt(this.props.photo.project.id),
        parseInt(this.props.photo.id),
        type
      )
    )
    // this.setState({
    //   uploading: false
    // })
  }

  uploadFile = (file, onMutate, projectId, photoId, type) => {
    const railsActiveStorageDirectUploadsUrl = process.env.REACT_APP_DIRECT_UPLOAD_URL
    const upload = new DirectUpload(file, railsActiveStorageDirectUploadsUrl, 'photo')

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        // Add an appropriately-named hidden input to the form with a
        //  value of blob.signed_id so that the blob ids will be
        //  transmitted in the normal upload flow
        onMutate({
          variables: {
            fileSignedId: blob.signed_id,
            projectId: projectId,
            photoId: photoId
          }
        })
        // const reader = new FileReader()

        // reader.addEventListener(
        //   'load',
        //   e => {
        //     const res = e.target.result
        //     this.props.updateRAW && this.props.updateRAW(res, file.size, file.name)
        //   },
        //   false
        // )
        // let base64
        // if (file) {
        //   base64 = reader.readAsDataURL(file)
        // }
      }
    })

    const toastMessage = 'RAW file replaced.'
    this.props.showToast && this.props.showToast('success', toastMessage)
  }

  render() {
    const { modalClose } = this
    const { photo } = this.props
    const { rawFileUrl, rawFileSize, rawFileName } = photo
    const megabyteSize = rawFileSize && (rawFileSize / 1000000).toFixed(2)

    return (
      <Fragment>
        <div className='photo-detail__photo-files--add-raw'>
          <Mutation mutation={CREATE_RAW_FILE_MUTATION} onCompleted={() => {
            this.props.refetchPhoto && this.props.refetchPhoto()
            this.setState({
              uploading: false
            })
          }}>
            {(onMutate, { data }) => (
              <Dropzone
                photos={this.props.photos}
                onDrop={files => this.onDrop(files, onMutate, 'rawFile')}
                accept={
                  '.3fr, .ari, .arw, .bay, .braw, .crw, .cr2 .cr3, .cap, .data, .dcs, .dcr, .dng, .drf, .eip, .erf, .fff, .gpr, .iiq, .k25, .kdc, .mdc, .mef, .mos, .mrw, .nef, .nrw, .obm, .orf, .pef, .ptx, .pxn, .r3d, .raf, .raw, .rwl, .rw2, .rwz, .sr2, .srf, .srw, .tif, .x3f'
                }
              >
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <Fragment>
                      {this.props.photo ? (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <div className='photo-detail__photo-files--flex'>
                              <Typography variant="paragraph" component="p">Only *.jpeg and *.png images will be accepted</Typography>
                              <Button
                                variant='outlined'
                                disabled={this.state.uploading}
                                title={this.state.uploading ? <span className='icon-text-span'>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  /> Saving... </span>
                                : 'Add RAW File' }
                              />
                              {/* <Button variant='outline-dark btn-sm' title='Add RAW File' disabled={this.state.uploading}/> */}
                              <Typography variant="h5">
                              {rawFileName === 'No attachment' ? 'No RAW file uploaded' : <>{rawFileName} {megabyteSize && `(${megabyteSize} MB)`}</>}
                              </Typography>
                            </div>
                          ) : (
                            <div className='photo-detail__photo-files--flex'>
                              <Button
                                variant='outlined'
                                disabled={this.state.uploading}
                                title={this.state.uploading ? <span className='icon-text-span'>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  /> Saving... </span>
                                : 'Add RAW File' }
                              />
                              <Typography variant="h5">
                                {rawFileName === 'No attachment' ? 'No RAW file uploaded' : <>{rawFileName} {megabyteSize && `(${megabyteSize} MB)`}</>}
                              </Typography>
                            </div>
                          )}
                          <div className='uploader__content'>
                            <Typography variant="h4">
                              If you have a RAW or TIFF file for this photograph, please upload it here.
                            </Typography>
                         </div>
                        </div>
                      ) : null}
                    </Fragment>
                  )
                }}
              </Dropzone>
            )}
          </Mutation>

          {!_.includes(['No attachment', undefined, null, ''], rawFileUrl) && (
            <div>
              <Typography variant="h5" className="semi-strong link"
                onClick={() => this.setState({ modalShow: true })}
              >
                Delete File
              </Typography>
              <DeleteRawFileModal
                show={this.state.modalShow}
                onHide={() => this.setState({ modalShow: false, modalShowConfirm: false })}
                onSuccessCallback={modalClose}
                photoId={this.props.photo.id}
                refetchPhoto={this.props.refetchPhoto}
              />
            </div>
          )}
        </div>
        {/* <a
          rel='noopener noreferrer'
          target='_blank'
          href={this.props.userStore.permaLinkedResources['High Res Images']}
        >
          <h5 className='h5--semi-strong inline'>Why we ask for these files</h5>
        </a> */}
      </Fragment>
    )
  }
}

export default inject('userStore')(observer(RawFileUploader))
