import { createTheme } from '@mui/material/styles'
import { createBreakpoints } from '@mui/system'
import variables from '../settings/_variables.scss'

const breakpoints = createBreakpoints({
  // Define your breakpoints here
  // xs: 0,
  // sm: 600,
  // md: 900,
  // lg: 1200,
  // xl: 1536
})

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: `${variables.navigationGrey}`,
          padding: `${variables.spacing18px} ${variables.spacingMedium}`,
          selected: {
            color: `${variables.blueAccent}`
          }
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          top: '72px !important'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: `${variables.sansSerifBold}`,
          textDecoration: 'none',
          cursor: 'pointer',
          paddingBottom: '.25rem',
          color: '#0076bb',
          fontSize: `${variables.sizeBody}`,
          '&.large': {
            fontSize: `${variables.sizeH3}`
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          textAlign: 'left'
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {

        lineVertical:{
          minHeight: '24px',
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        vertical: {
          padding: 0
        }
      }
    },
    MuiMobileStepper: {
      styleOverrides: {
        root : {
          [breakpoints.up('sm')]: {
            paddingLeft: '32px',
            paddingRight: '32px'
          }
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'selected-segmented' },
          style: {
            padding: '1.5rem 1rem 1.5rem 1.5rem !important',
            color: `${variables.black}`,
            border: `2px solid ${variables.greenAccent} !important`,
            backgroundColor: `${variables.selectedOption}`,
            '&:hover': {
              backgroundColor: `${variables.selectedOption}`
            }
          }
        },
        {
          props: { variant: 'segmented' },
          style: {
            padding: '1.5rem 1rem 1.5rem 1.5rem !important',
            color: `${variables.black}`,
            border: `1px solid ${variables.lightGrey} !important`,
            backgroundColor: `${variables.white}`,
            '&:hover': {
              backgroundColor: `${variables.selectedOption}`
            }
          }
        },
        {
          props: { color: 'secondary' },
          style: {
            borderColor: `${variables.purpleAccent} !important`,
            '&:hover': {
              backgroundColor: `${variables.purpleAccent}`
            }
          }
        }
      ],
      styleOverrides: {
        root: {
          border: `2px solid ${variables.blueAccent}`,
          '&:disabled': {
            border: `2px solid ${variables.lightGrey}`
          },
          '&:hover': {
            border: `2px solid ${variables.blueAccent}`
          }
        },
        outlined: {
          borderColor: `${variables.blueAccent}`,
          color: `${variables.blueAccent}`,
          '&:disabled': {
            color: `${variables.lightGrey}`,
            borderColor: `${variables.lightGrey}`
          }
        },
        containedPrimary: {
          backgroundColor: `${variables.blueAccent}`,
          '&:hover': {
            backgroundColor: `${variables.blueHover}`
          }
        },
        purple: {
          color: `${variables.white}`,
          backgroundColor: `${variables.purpleAccent}`,
          borderColor: `${variables.purpleAccent}`,
          '&:hover': {
            backgroundColor: `${variables.purpleAccent}`,
            borderColor: `${variables.purpleAccent}`,
          }
        },
        '& h4': {
          paddingBottom: '0px'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: `${variables.sizeBody}`,
          color: `${variables.navigationGrey}`,
          backgroundColor: `${variables.backgroundGrey}`,
          borderBottomColor: `${variables.greyBorder}`,
          "& :disabled": {
            backgroundColor: `${variables.backgroundGrey}`,
          },
        }
      }
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: `${variables.gery53}`,
        }
      }
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: 'rgb(45, 45, 45)'
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 54,
          height: 40,
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontSize: `${variables.sizeBody}`,
          '.MuiFormControlLabel-label': {
            fontSize: `${variables.sizeBody}`
          }
        }
      }
    }
  },

  palette: {
    primary: {
      main: `${variables.blueAccent}`
    },
    secondary: {
      main: `${variables.purpleAccent}`
    },
    error: {
      main: `${variables.redError}`,
      color: `${variables.redError}`
    }
  },
  button: {
    borderColor: `${variables.blueAccent}`,
    outlined: {
      color: `${variables.blueAccent}`
    },
    contained: {
      color: `${variables.white}`,
      backgroundColor: `${variables.blueAccent}`
    }
  },
  typography: {
    lineHeight: '1.2',
    fontFamily: `${variables.sansSerif}`,
    h1: {
      lineHeight: '1.2',
      fontSize: '2rem',
      fontWeight: 'bold',
      '&.semi-bold': {
        fontFamily: `${variables.sansSerifBold}`
      },
      [breakpoints.down('sm')]: {
        fontSize: '2rem'
      },
      '&.oversize': {
        fontFamily: `${variables.sansSerifXbold}`,
        fontWeight: '500',
        letterSpacing: '0.2px',
        fontSize: '2.5rem'
      },
      '&.extra-size': {
        fontFamily: `${variables.sansSerifXbold}`,
        fontWeight: 'bold',
        letterSpacing: '0.2px',
        lineHeight: '0.9',
        fontSize: `${variables.sizeExtra}`
      },
      '&.editing': {
        padding: 0
      },

      '&.editable': {
        margin: '0 auto',
        padding: 0
      },

      '&.bold': {
        fontFamily: `${variables.sansSerifXbold}`,
        letterSpacing: '0.2px'
      },
      '&.oversize_bold': {
        fontFamily: `${variables.sansSerifXbold} !important`,
        fontWeight: '500',
        letterSpacing: '0.2px',
        fontSize: '2.5rem',
        '&.public': {
          fontSize: '3.75rem',
          [breakpoints.down('sm')]: {
            fontSize: '2.5rem',
            lineHeight: '0.9'
          }
        }
      }
    },

    h2: {
      color: 'rgb(45, 45, 45)',
      fontSize: '1.75rem',
      lineHeight: '1.2',
      fontFamily: `${variables.sansSerifBold}`,
      '&.medium': {
        fontFamily: `${variables.sansSerifMedium}`,
        letterSpacing: `${variables.letterSpacing}`,
        lineHeight: `${variables.spacingMedium}`
      },
      '&.strong': {
        fontFamily: `${variables.sansSerifXbold}`
      }
    },
    h3: {
      color: 'rgb(45, 45, 45)',
      letterSpacing: '0.2px',
      fontSize: '1.5rem',
      lineHeight: '1.2',
      '&.semi-strong': {
        fontFamily: `${variables.sansSerifBold}`
      },
      '&.strong': {
        fontFamily: `${variables.sansSerifXbold}`
      },
      '&.link': {
        color: 'rgb(0, 118, 187)',
        cursor: 'pointer',
        '&:hover': {
          color: '#0056b3'
        }
      },
      '&.small_size': {
        fontSize: `${variables.sizeBody}`
      }
    },

    h4: {
      fontFamily: `${variables.sansSerif}`,
      fontSize: `${variables.sizeH3}`,
      letterSpacing: '0.2px',
      lineHeight: '1.2',
      '&.table_header': {
        fontSize: `${variables.sizeBody}`,
      },
      '&.table_row': {
        fontSize: `${variables.sizeH3}`
      },
      '&.semi-strong': {
        fontSize: `${variables.sizeH3}`,
        fontFamily: `${variables.sansSerifBold}`
      },
      '&.schoolName': {
        width: '300px'
      },

      '&.small_size': {
        fontSize: `${variables.sizeBody}`
      },

      '&.small_size_semi_bold': {
        fontSize: `${variables.sizeBody}`,
        fontFamily: `${variables.sansSerifBold}`
      },

      '&.link': {
        color: 'rgb(0, 118, 187)',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&--large': {
          fontSize: `${variables.sizeH3}`,
          fontFamily: `${variables.sansSerifBold}`,
          textDecoration: 'underline'
        }
      },
      '&.strong': {
        fontFamily: 'Calibre Bold, Helvetica, Verdana, sans-serif',
        display: 'inline-block'
      },
      '&.regular': {
        fontSize: `${variables.sizeH3}`
      },
      '&.btn-label': {
        fontSize: `${variables.sizeH4}`,
      },
      '&.line-height' : {
        lineHeight: '1.8'
      },
      '&.truncate' : {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    },
    h5: {
      fontFamily: `${variables.sansSerif}`,
      fontSize: '1rem',
      strong: {
        fontFamily: `${variables.sansSerifXbold}`
      },
      '&.school-detail-link': { fontSize: `${variables.sizeBody}` },
      '&.link': {
        color: 'rgb(0, 118, 187)',
        cursor: 'pointer'
      },
      '&.semi-strong': {
        fontFamily: `${variables.sansSerifBold}`
      },
      '&.h5_alert': {
        color: 'rgb(222, 34, 18)',
        marginBottom: '10px',
        position: 'relative'
      },
      '&.footer-links' : {
        color: `${variables.white}`
      }
    },
    h6: {
      fontFamily: `${variables.sansSerif}`,
      fontSize: '11.25px',
      '&.regular': {
        fontSize: '1rem'
      },
      '&.data-label': {
        fontSize: `${variables.sizeBody}`
      }
    },

    paragraph: {
      lineHeight: 1.2,
      fontFamily: `${variables.sansSerif}`,
      fontSize: `${variables.sizeH3}`,
      '&.strong': {
        fontFamily: `${variables.sansSerifBold}`
      },
      '&.note-style': {
        fontSize: `${variables.sizeH4}`
      }
    },
    blockQuote: {
      fontFamily: `${variables.lyon} !important`,
      fontSize: `${variables.sizeH3}`,
      lineHeight: 1.2,
      '&.italic': {
        fontStyle: 'Italic'
      }
    },
    link: {
      fontFamily: `${variables.sansSerifXbold}`,
      fontSize: `${variables.sizeBody}`,
      textDecoration: 'underline',
      color: '#0076BB',
      cursor: 'pointer',
      lineHeight: 1.2,
      svg:{
        verticalAlign: 'middle'
      },
      '&.large': {
        fontSize: `${variables.sizeH3}`,
      },
      '&.classes-header-sub-text': {
        width: '80%',
        margin: '2px 0 1.75rem'
      },
      '&.tab-header-two-content': {
        padding: '0.9375rem 0'
      },
      '&.tab-content-four-sub-text': {
        marginTop: '9px',
        marginBottom: '1.8125rem'
      },
      '&.regular': {
        fontFamily: `${variables.sansSerif}`
      },
      '&.icon-link': {
        fontSize: `${variables.sizeBody}`,
        display: 'flex',
        gap: 8
      },
      '&.alert-link': {
        color: `${variables.redAccent}`,
      },
      '&.labeled-data': {
        fontFamily: `${variables.sansSerif}`,
        fontWeight: 700
      }
    },
    disabled_link: {
      fontFamily: `${variables.sansSerifXbold}`,
      fontSize: `${variables.sizeBody}`,
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.26)',
      '&.regular': {
        fontFamily: `${variables.sansSerif}`
      }
    },
    note: {
      fontFamily: `${variables.sansSerif}`,
      fontSize: `${variables.sizeH4}`,
      lineHeight: 1.2
    },
    status: {
      fontFamily: `${variables.sansSerifBold}`,
      fontSize: `${variables.sizeBody}`,
      lineHeight: 1.2,
      svg: {
        verticalAlign: 'middle !important',
        flexShrink: 0,
        marginTop: 2
      },
      '&.status_alert': {
        color: `${variables.redAccent}`,
      },
      '&.flex': {
        display: 'flex',
        gap: 8

      }
    },
    dataLabel: {
      fontFamily: `${variables.sansSerif}`,
      fontSize: `${variables.sizeBody}`,
      lineHeight: 1.2,
      '&.content': {
        fontSize: `${variables.sizeH3}`,
      },
      '&.breadcrumbLink' : {
        color: `${variables.gery53}`
      },
      '&.breadCrumbLinkActive' : {
        color: `${variables.darkGrey}`
      }
    },
  }
})

export default theme
