import { gql } from '@apollo/client'
import { MODEL_RELEASE_ATTRIBUTES } from '../graphql/modelReleaseAttributes'
import { PHOTO_ATTRIBUTES } from '../graphql/photoAttributes'
import { USE_AGREEMENT_ATTRIBUTES } from '../graphql/useAgreementAttributes'

export const PHOTO_DETAIL_QUERY = gql`
  query PhotoDetails($id: ID!) {
    photo(id: $id) {
      ...photoAttributes
    }
  }
  ${PHOTO_ATTRIBUTES}
`

export const UPDATE_META_DATA_MUTATION = gql`
  mutation UpdatePhotoMetadata($id: ID!, $title: String, $caption: String, $altText: String) {
    updatePhotoMetadata(id: $id, title: $title, caption: $caption, altText: $altText) {
      id
      caption
      date
      title
      altText
      mrAdminNotes
    }
  }
`

export const ADD_COMMENT_MUTATION = gql`
  mutation AddComment($photoId: ID!, $body: String!) {
    addComment(photoId: $photoId, body: $body) {
      id
    }
  }
`

export const TOGGLE_ADMIN_APPROVAL_MUTATION = gql`
  mutation ToggleAdminApproval($photoId: ID!, $isPublishable: Boolean!) {
    toggleAdminApproval(photoId: $photoId, isPublishable: $isPublishable) {
      id
    }
  }
`

export const PUBLISH_PHOTO_MUTATION = gql`
  mutation PublishPhotos(
    $photoId: ID!
    $nationalCollection: Boolean!
    $covidCollection: Boolean!
    $opType: String!
  ) {
    publishPhotos(
      photoId: $photoId
      nationalCollection: $nationalCollection
      covidCollection: $covidCollection
      opType: $opType
    )
  }
`

export const OVERRIDE_DATE_MUTATION = gql`
  mutation EditPhotoSubmittedDate($photoId: ID!, $submittedDate: String!) {
    editPhotoSubmittedDate(photoId: $photoId, submittedDate: $submittedDate) {
      id
      submittedDate
    }
  }
`

export const GET_USE_AGREEMENTS_DATA = gql`
  query GetPhotoUseAgreements($id: ID!) {
    allUseAgreement: getPhotoUseAgreements(id: $id) {
      ...useAgreementAttributes
    }
    activeUseAgreements: getPhotoUseAgreements(id: $id, status: "Active") {
      ...useAgreementAttributes
    }
    inactiveUseAgreements: getPhotoUseAgreements(id: $id, status: "Inactive") {
      ...useAgreementAttributes
    }
    approvedUa: getPhotoUseAgreements(id: $id, status: "Completed", adminDecision: "Approve") {
      ...useAgreementAttributes
    }
    activeRejected: getPhotoUseAgreements(id: $id, status: "Completed", adminDecision: "Reject") {
      ...useAgreementAttributes
    }
  }
  ${USE_AGREEMENT_ATTRIBUTES}
`

export const TOGGLE_HAS_PEOPLE_MUTATION = gql`
  mutation UpdatePhotoHasPeople($photoId: ID!, $hasPeople: Boolean!) {
    updatePhotoHasPeople(photoId: $photoId, hasPeople: $hasPeople) {
      id
    }
  }
`

export const MANAGE_MR_NUMBERS = gql`
  mutation ManageMrNumberPhotos($photoId: ID!, $opType: String!) {
    manageMrNumberPhotos(photoId: $photoId, opType: $opType) {
      id
    }
  }
`

export const GET_MODEL_RELEASES_DATA = gql`
  query GetPhotoModelReleases($id: ID!) {
    allModelReleases: getPhotoModelReleases(id: $id) {
      ...modelReleaseAttributes
    }
    activeModelReleases: getPhotoModelReleases(id: $id, status: "Active") {
      ...modelReleaseAttributes
    }
    inactiveModelReleases: getPhotoModelReleases(id: $id, status: "Inactive") {
      ...modelReleaseAttributes
    }
    approvedMr: getPhotoModelReleases(id: $id, status: "Completed", adminDecision: "Approve") {
      ...modelReleaseAttributes
    }
    activeRejected: getPhotoModelReleases(id: $id, status: "Completed", adminDecision: "Reject") {
      ...modelReleaseAttributes
    }
  }
  ${MODEL_RELEASE_ATTRIBUTES}
`

export const OVERRIDE_TITLE_AND_CAPTION_MUTATION = gql`
  mutation EditPhotoEditedTitleAndCaption(
    $photoId: ID!
    $submittedDate: String!
    $editedTitle: String!
    $editedCaption: String!
  ) {
    editPhotoEditedTitleAndCaption(
      photoId: $photoId
      submittedDate: $submittedDate
      editedTitle: $editedTitle
      editedCaption: $editedCaption
    ) {
      id
      editedTitle
      editedCaption
      submittedDate
    }
  }
`
