import React, { useEffect, useState } from 'react'
import '../molecules/shortlist.scss'
import { observer, inject } from 'mobx-react'
import Photo from '../elements/Photo.js'
import PhotoOverlay from '../molecules/PhotoOverlay.js'
import _ from 'lodash'
import { withApollo } from '@apollo/client/react/hoc'
import PhotoStatusStack from '../molecules/PhotoStatusStack'
import { withRouter } from '../../utils/customHoc'
import { Box, Grid } from '@mui/material'

const PhotoGridContainer = (props) => {
  const { role } = props.userStore;
  const [shortListedPhotos, setShortListedPhotos] = useState(props.photos);

  useEffect(() => {
    setShortListedPhotos([...props.photos]);
  }, [props.photos]);

  return (
    <Grid container columnSpacing={2}>
      {shortListedPhotos.map(photo => (
        <Grid item xs={12} sm={6} md={4} key={'gallery__container__' + photo.id}>
          {/* <div className='gallery__container' key={'gallery__container__' + photo.id}> */}
          <Box
            component='div'
            className='gallery__photo-container'
            key={photo.id}
            data-id={photo.id} // used in cypress.
          >
            <PhotoOverlay
              photo={photo}
              showToast={props.showToast}
              updateParentState={photoId => props.updateState(photoId)}
              photoOnClick={() => props.thumbnailOnClick(photo)}
              key={'PhotoOverlay' + photo.id}
              hideStudentSection={
                _.includes(['student'], role) &&
                props.userStore.profileId !== photo.project.student.id
                  ? true
                  : null
              }
              isCohortEnded={props.isCohortEnded}
              refetchProject={props.refetchProject}
              // setRefetchAllSection={props.setRefetchAllSection}
            />
            <Photo
              photo={photo}
              placeholderDark={true}
              renderAsBackgroundImage={props.renderAsBackgroundImage}
              letterBoxType={props.letterBoxType}
              onClick={() => props.thumbnailOnClick(photo)}
              showToast={props.showToast}
              key={'Photo' + photo.id}
              role={role}
              hideStudentSection={_.includes(['student'], role) ? true : null}
              viewBadges={
                (['student', 'teacher', 'facilitator'].includes(role) && photo.summarySent) ||
                false
              }
              showAwards={
                ['appadmin', 'curator'].includes(role) ||
                (['student', 'teacher', 'facilitator'].includes(role) && photo.summarySent)
              }
            />
          </Box>
          {/* <div className='gallery__photo-container--missing-content' /> */}
          <PhotoStatusStack
            photo={photo}
            role={role}
            isShortListSection={props.isShortListSection}
            updateParentGallery={props.updateParentGallery}
          />
          {/* </div> */}
        </Grid>
      ))}
    </Grid>
  )
}

// class PhotoGridContainer extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       shortListedPhotos: props.photos
//     }
//   }

//   componentWillReceiveProps(nextProps) {
//     this.setState({
//       ...this.state,
//       shortListedPhotos: [...nextProps.photos]
//     })
//   }

//   render() {
//     const { updateState, thumbnailOnClick, showToast } = this.props
//     const { shortListedPhotos } = this.state
//     const { userStore } = this.props
//     const { role } = userStore
//     return (
//       <Grid container columnSpacing={2}>
//         {shortListedPhotos.map(photo => (
//           <Grid item xs={12} sm={6} md={4} key={'gallery__container__' + photo.id}>
//             {/* <div className='gallery__container' key={'gallery__container__' + photo.id}> */}
//             <Box
//               component='div'
//               className='gallery__photo-container'
//               key={photo.id}
//               data-id={photo.id} // used in cypress.
//             >
//               <PhotoOverlay
//                 photo={photo}
//                 showToast={showToast}
//                 updateParentState={photoId => updateState(photoId)}
//                 photoOnClick={() => thumbnailOnClick(photo)}
//                 key={'PhotoOverlay' + photo.id}
//                 hideStudentSection={
//                   _.includes(['student'], role) &&
//                   this.props.userStore.profileId !== photo.project.student.id
//                     ? true
//                     : null
//                 }
//                 isCohortEnded={this.props.isCohortEnded}
//                 refetchProject={this.props.refetchProject}
//                 // setRefetchAllSection={this.props.setRefetchAllSection}
//               />
//               <Photo
//                 photo={photo}
//                 placeholderDark={true}
//                 renderAsBackgroundImage={this.props.renderAsBackgroundImage}
//                 letterBoxType={this.props.letterBoxType}
//                 onClick={() => thumbnailOnClick(photo)}
//                 showToast={showToast}
//                 key={'Photo' + photo.id}
//                 role={role}
//                 hideStudentSection={_.includes(['student'], role) ? true : null}
//                 viewBadges={
//                   (['student', 'teacher', 'facilitator'].includes(role) && photo.summarySent) ||
//                   false
//                 }
//                 showAwards={
//                   ['appadmin', 'curator'].includes(role) ||
//                   (['student', 'teacher', 'facilitator'].includes(role) && photo.summarySent)
//                 }
//               />
//             </Box>
//             {/* <div className='gallery__photo-container--missing-content' /> */}
//             <PhotoStatusStack
//               photo={photo}
//               role={role}
//               isShortListSection={this.props.isShortListSection}
//               updateParentGallery={this.props.updateParentGallery}
//             />
//             {/* </div> */}
//           </Grid>
//         ))}
//       </Grid>
//     )
//   }
// }

export default inject('userStore')(observer(withApollo(withRouter(PhotoGridContainer))))
