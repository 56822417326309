import React from 'react'
import { Box } from '@mui/material'
import {Typography} from '@mui/material'

const HeroHeader =  ({ heroHeader, photoCount }) => {
  return (
    <Box
      sx={{
          color: "rgb(45, 45, 45)",
      }}
    >
      <Typography variant="h2"className='strong'>
      {heroHeader}
        {photoCount ? <span className='hero-photo-count'>({`${photoCount} photos`})</span> : null}
      </Typography>
    </Box>
  )
}


export default HeroHeader