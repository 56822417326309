import React from 'react'
import { Box } from '@mui/material'

export const BaseModalBody = props => {
  return (
    <Box sx={{
      padding:{xs: '32px 24px 24px', sm: '32px 32px 24px 32px'},
      maxHeight: {xs:'calc(84vh - 64px)', sm:'calc(87.3vh - 64px)'},
      overflowY:'auto',
      overflowX:'hidden',
      '&::-webkit-scrollbar': {
        display: 'block',
      },
    }} id='modal-modal-description'>
      {props?.children?.[1]?.props?.children}
    </Box>
  );
}