import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Award, Shield, MessageSquare } from 'react-feather'
import ReactImageFallback from 'react-image-fallback'
import ImageZoom from '../organisms/ImageZoom'
import galleryPlaceholder from '../../assets/images/gallery-placeholder.svg'
import galleryPlaceholderDark from '../../assets/images/gallery-placeholder-dark.svg'
import { Box } from '@mui/material'
import {Typography} from '@mui/material'
class Photo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      zoomEnabled: false
    }
  }

  getFinalAward = (awards) => {
    if(awards.length > 0) {
      let aoeCount = awards.filter(award => award.awardClass === 'Award of Excellence').length
      let hmCount = awards.filter(award => award.awardClass === 'Honorable Mention').length

      return (aoeCount > hmCount || aoeCount === hmCount) ? 'aoe' : (hmCount > 0) ? 'hm' : null
    }
    return null
  }

  render() {
    const {
      photo,
      onClick,
      viewBadges,
      displayOriginalPhoto,
      notClickable,
      showAwards,
      isSinglePhotoView,
      role,
      placeholderDark
    } = this.props
    const isNationalPhotos = window.location.pathname.includes('national-photos')
    const isProjectPage = window.location.pathname.includes('project-detail')

    const finalAward = this.getFinalAward(photo.awards)

    const src = photo.sizedUrl || (displayOriginalPhoto ? photo.originalUrl : photo.cdnOriginalUrl)
    const altText = photo.altText ? photo.altText : ''
    const imgComponent = (
      <ReactImageFallback
        src={src}
        fallbackImage='https://i.pinimg.com/originals/9b/96/92/9b9692c9f0db9f6276e6bd29a98c25e0.png'
        initialImage={placeholderDark ? galleryPlaceholderDark : galleryPlaceholder}
        alt={altText}
        className={this.props.letterBoxType ? 'photo photo_letterbox_type': 'photo'}
        onClick={() => {
          this.setState({ zoomEnabled: true })
        }}
      />
    )

    return (
      <Fragment>
        {typeof photo == 'undefined' ? null : (
          <div className='container__photo' key={photo.id}>
            {this.props.onClick || this.props.photo.onClick ? (
              <Box
                onClick={() => {
                  onClick ? onClick(photo) : this.props.photo.onClick(photo)
                }}
              >
                {this.props.renderAsBackgroundImage ? (
                  // <div
                  //   className='container__photo--background'
                  //   style={{
                  //     backgroundImage: `url('${src}')`
                  //   }}
                  // />
                  <ReactImageFallback
                    fallbackImage='https://i.pinimg.com/originals/9b/96/92/9b9692c9f0db9f6276e6bd29a98c25e0.png'
                    initialImage={placeholderDark ? galleryPlaceholderDark : galleryPlaceholder}
                    src={src}
                    alt={''}
                    className='photo photo_letterbox_type'
                    key={photo.id}
                  />
                ) : (
                  <Box sx={{cursor:'zoom-in'}}>
                    <ImageZoom
                      srcSmall={src}
                      src={photo.originalUrl || photo.url}
                      onClose={() => this.setState({ zoomEnabled: false })}
                      width={photo.exifData && photo.exifData.width}
                      height={photo.exifData && photo.exifData.height}
                      display={this.state.zoomEnabled}
                    />
                    {imgComponent}
                  </Box>
                )}
              </Box>
            ) : (
              <div>
                {notClickable ? (
                  <div>
                    {this.props.renderAsBackgroundImage ? (
                      // <div
                      //   className='container__photo--background'
                      //   style={{
                      //     backgroundImage: `url('${src}')`
                      //   }}
                      // />
                      <ReactImageFallback
                        fallbackImage='https://i.pinimg.com/originals/9b/96/92/9b9692c9f0db9f6276e6bd29a98c25e0.png'
                        initialImage={placeholderDark ? galleryPlaceholderDark : galleryPlaceholder}
                        src={src}
                        alt={''}
                        className='photo photo_letterbox_type'
                        key={photo.id}
                      />
                    ) : (
                      imgComponent
                    )}
                  </div>
                ) : (
                  <Link to={`/photo-detail/${photo.id}`} disabled={true}>
                    {this.props.renderAsBackgroundImage ? (
                      // <div
                      //   className='container__photo--background'
                      //   style={{
                      //     backgroundImage: `url('${src}')`
                      //   }}
                      // />
                      <ReactImageFallback
                        fallbackImage='https://i.pinimg.com/originals/9b/96/92/9b9692c9f0db9f6276e6bd29a98c25e0.png'
                        initialImage={placeholderDark ? galleryPlaceholderDark : galleryPlaceholder}
                        src={src}
                        alt={''}
                        className='photo photo_letterbox_type'
                        key={photo.id}
                      />
                    ) : (
                      imgComponent
                    )}
                  </Link>
                )}
              </div>
            )}
            {showAwards && photo.awards ? <div className='photo-container__hover-content--top-right'>{(
              isNationalPhotos || ( (isProjectPage || this.props.projectPage) && ['teacher', 'facilitator', 'student'].includes(role) ) && (finalAward === 'hm' || finalAward === 'aoe') ? (
                <div className='photo-container__hover-content--top-right---content'>
                  <Typography variant="h4" className='semi-strong'>
                    <span className='photo-container__hover-content--top-right---overlay'>
                      { finalAward === 'aoe' ? <Award size={16}/> : finalAward === 'hm' ? <Shield size={16}/> : null }
                    </span>
                  </Typography>
                </div>
              ) : (
                <>
                  {photo.awards.map(award => (
                    <div className='photo-container__hover-content--top-right---content' key={`photo-container_${award.nominatorInitials}`}>
                      <Typography variant="h4" className='semi-strong'>
                        <span className='photo-container__hover-content--top-right---overlay'>
                          { viewBadges ? null : `${award.nominatorInitials} `} {award.awardClass === 'Award of Excellence' ? <Award size={16}/> : award.awardClass === 'Honorable Mention' ? <Shield size={16}/> : null }
                        </span>
                      </Typography>
                    </div>
                  ))}
                </>
              )
            ) }</div>: null}
            {photo.photoComments && photo.photoComments.length > 0 && ['appadmin', 'curator'].includes(role) ? (
              <div className='message-notifier__photo-overlay'>
                <MessageSquare />
              </div>
            ) : null}
          </div>
        )}
      </Fragment>
    )
  }
}

export default Photo
