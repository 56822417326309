import React, { Component } from 'react'
import './photoStatusStack.scss'
import { withApollo } from '@apollo/client/react/hoc'
import PhotoActionBar from './PhotoActionBar'
import { getApprovedEntityCount } from '../../utils/commonFunctions'
import { inject, observer } from 'mobx-react'
import { Box } from '@mui/material'
import { getFormattedStatus, isPublishSectionEnable } from '../../utils/commonFunctions'
import { withRouter } from '../../utils/customHoc'

class PhotoStatusStack extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photo: props.photo
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (JSON.stringify(nextProps.photo) !== JSON.stringify(prevState.photo)) {
      return {
        photo: nextProps.photo
      }
    } else return null
  }

  getMrCountByStauts = (modelReleases, mrStatus, adminDecision) => {
    return modelReleases.filter(
      mr => mr.mrStatus === mrStatus && mr.adminDecision === adminDecision
    ).length
  }

  render = () => {
    const { photo } = this.state
    const { role, userStore } = this.props
    const { profileId } = userStore
    const { hasPeople } = photo
    const isClassReviewed = photo.project.cohort.reviewed
    const curatorReviews = photo.project.cohort.curatorReviews

    const onClassReview = window.location.pathname.includes('class-review')
    const shouldDisable =
      (this.props.awardResults && ['student'].includes(role) && photo.summarySent) ||
      (['curator'].includes(role) &&
        (isClassReviewed ||
          (curatorReviews &&
            curatorReviews.filter(reviewe => reviewe.reviewerId === profileId).length > 0)))

    const isPhotoReviewdAndAwarded =
      photo && photo.awards.length > 0 && photo.project.cohort.reviewed
    const isThereAnyActiveUa =
      photo &&
      photo.useAgreements.filter(
        ua =>
          (ua.uaStatus === 'Active' && ua.adminDecision === null) ||
          (ua.uaStatus === 'Active' && ua.adminDecision === 'Pending') ||
          (ua.uaStatus === 'Completed' && ua.adminDecision === 'Approve')
      ).length > 0

    const isUaApproved =
      photo &&
      photo.useAgreements.filter(
        ua => ua.uaStatus === 'Completed' && ua.adminDecision === 'Approve'
      ).length > 0

    const isThereAnyActiveOrApproveMr =
      photo &&
      photo.modelReleases.filter(
        mr =>
          (mr.mrStatus === 'Active' && mr.adminDecision === null) ||
          (mr.mrStatus === 'Active' && mr.adminDecision === 'Pending') ||
          (mr.mrStatus === 'Completed' && mr.adminDecision === 'Approve')
      ).length

    const approvedMrCount =
      photo &&
      photo.modelReleases.filter(
        mr => mr.mrStatus === 'Completed' && mr.adminDecision === 'Approve'
      ).length

    const isReadyToPublishEnabled = photo
      ? (photo.hasPeople &&
          photo.totalMrRequired === getApprovedEntityCount(photo.modelReleases, 'mr') &&
          getApprovedEntityCount(photo.useAgreements, 'ua') > 0) ||
        (photo.hasPeople === false && getApprovedEntityCount(photo.useAgreements, 'ua') > 0)
      : false

    const renderStatusMessage = (icon, message) => {
      return getFormattedStatus(icon, message, { svg: { marginTop: '0 !important' } })
    }

    return (
      <Box sx={{ width: '100%' }}>
        {this.props.role !== 'curator' && (
          <Box sx={{ padding: '0px', width: '100%', mb: 4 }}>
            {!photo.title || !photo.caption ? (
              <>
                {photo.title
                  ? renderStatusMessage('AlertTriangle', 'Add Caption')
                  : photo.caption
                  ? renderStatusMessage('AlertTriangle', 'Add Title')
                  : renderStatusMessage('AlertTriangle', 'Add Title and Caption')}
              </>
            ) : null}

            {isPhotoReviewdAndAwarded && !this.props.isShortListSection ? (
              <>
                {photo.summarySent ? (
                  <>
                    {['student'].includes(role) && !isThereAnyActiveUa
                      ? renderStatusMessage('AlertTriangle', `Add Use Agreement`)
                      : ['teacher', 'facilitator', 'appadmin'].includes(role) && !isUaApproved
                      ? renderStatusMessage('AlertTriangle', 'Needs Approved Use Agreement')
                      : ['teacher', 'facilitator', 'appadmin'].includes(role) && isUaApproved
                      ? renderStatusMessage('CheckCircle', 'Use Agreement Approved')
                      : null}

                    {['student'].includes(role) &&
                    photo.totalMrRequired > isThereAnyActiveOrApproveMr ? (
                      renderStatusMessage(
                        'AlertTriangle',
                        `Add ${photo.totalMrRequired -
                          isThereAnyActiveOrApproveMr} Model Release(s)`
                      )
                    ) : ['teacher', 'facilitator', 'appadmin'].includes(role) ? (
                      <>
                        {hasPeople === null || (hasPeople === true && photo.totalMrRequired === 0)
                          ? renderStatusMessage('AlertTriangle', 'Identify Model(s)')
                          : photo.totalMrRequired > approvedMrCount
                          ? renderStatusMessage('AlertTriangle', 'Needs Approved Model Release(s)')
                          : photo.totalMrRequired === approvedMrCount
                          ? renderStatusMessage('CheckCircle', 'Model Release Approved')
                          : null}
                      </>
                    ) : null}
                    {isPublishSectionEnable ? (['appadmin'].includes(role) && !isReadyToPublishEnabled
                      ? renderStatusMessage('Slash', 'Cannot Be Published')
                      : ['appadmin'].includes(role) &&
                        isReadyToPublishEnabled &&
                        !photo.readyToPublish
                      ? renderStatusMessage('AlertTriangle', 'Queue for Publishing')
                      : ['appadmin'].includes(role) &&
                        isReadyToPublishEnabled &&
                        photo.readyToPublish &&
                        !photo.published
                      ? renderStatusMessage('Clock', 'Ready to Publish')
                      : ['appadmin'].includes(role) &&
                        isReadyToPublishEnabled &&
                        photo.readyToPublish &&
                        photo.published
                      ? renderStatusMessage('CheckCircle', 'Published')
                      : null) : null}
                  </>
                ) : ['appadmin'].includes(role) && !photo.summarySent ? (
                  <>
                    {photo.hasPeople === null ||
                    (photo.hasPeople === true && photo.totalMrRequired === 0)
                      ? renderStatusMessage('AlertTriangle', 'Identify Model(s)')
                      : photo.hasPeople === false ||
                        (photo.hasPeople === true && photo.totalMrRequired > 0)
                      ? renderStatusMessage('CheckCircle', 'Model(s) Identified')
                      : null}
                  </>
                ) : null}
              </>
            ) : null}
          </Box>
        )}

        {['curator'].includes(role) ||
        (this.props.awardResults && ['student'].includes(role) && photo.summarySent) ? (
          <Box component={'div'} sx={{ mt: 1, mb: 4 }}>
            <PhotoActionBar
              photo={photo}
              isClassReview={onClassReview}
              photoAwards={photo.awards}
              forStatusStack={true}
              disabled={shouldDisable}
              role={role}
              thumbnail={true}
            />
          </Box>
        ) : null}
      </Box>
    )
  }
}

export default inject('userStore')(observer(withApollo(withRouter(PhotoStatusStack))))
