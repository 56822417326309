import React, { Component } from 'react'
import './modals.scss'
import Button from '../elements/Button.js'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import GATag from '../../utils/GATag'
import { Box, Grid, Typography } from '@mui/material'
import BaseModal from '../template/BaseModal';

const REOPEN_STUDENT_PROJECT_MUTATION = gql`
  mutation ReopenStudentProject($projectId: ID!) {
    reopenStudentProject(projectId: $projectId) {
      id
    }
  }
`

class ReopenStudentProjectModal extends Component {
  constructor(props) {
    super(props)
    this.handleReopenProject = this.handleReopenProject.bind(this)
  }
  handleReopenProject(e, onMutate) {
    e.preventDefault()
    onMutate({
      variables: {
        projectId: this.props.projectId
      },
      refetchQueries: [`ClassDetails`]
    })
  }
  render() {
    const { handleReopenProject } = this
    const toastMsg =
      'This project was reopened. Please remind the student to finish their project and resubmit.'
    const handleClose = () => {
      this.setState({show: false});
      this.props.onHide(false);

    };
    return (
      <BaseModal
        {...this.props}
        show={this.props.show}
        onClick={this.props.onClick}
        value={this.props.value}
        open={this.props.show}
        onClose={handleClose}
        onHide={this.props.onHide}
      >
        <BaseModal.BaseModalHeader>
          Reopen Student Project
        </BaseModal.BaseModalHeader>
        <BaseModal.BaseModalBody>
          <Box>
            <Grid container>
              <Grid item className='invite-student__description_margin_bottom_83'>
                <Typography
                    variant="paragraph"
                      sx={{
                        mb:"1rem",
                      }}
                      component="p"
                  >Be sure that you want to reopen this project.</Typography>
                <Typography variant="paragraph" component="p">
                  Note: after you reopen the project, the student will be able to add and remove
                  photographs from the Shortlist and update titles and captions. They will need to
                  resubmit the project before the class end date.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <BaseModal.BaseModalFooter>
            <Box sx={{padding:'24px 0 0', marginTop:'0px'}} className='send-message__button'>
             
              <Mutation
                mutation={REOPEN_STUDENT_PROJECT_MUTATION}
                onCompleted={() => {
                  // window.ga('send', 'event', 'class-tracker', 'project', 'reopened', 1)
                  GATag.setEvent({
                    category: 'class-tracker',
                    action: 'project-reopened',
                    label: 'project reopened',
                    value: 1
                  })
                  this.props.onHide()
                  this.props.showToast && this.props.showToast('success', toastMsg)
                }}
              >
              {(reopenMutate, { data }) => (
                <Grid container direction="row" dir="rtl">
                  <Grid item xs={12} sm={4}>
                    <Button
                      variant='contained'
                      type='copy'
                      title='Reopen Project'
                      disabled={false}
                      onClick={e => handleReopenProject(e, reopenMutate)}
                      sx={{width:{ xs: '100%', sm: '100%' }}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
                    <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4" className='small_size_semi_bold link' onClick={() => this.props.onHide()}>
                      Cancel
                    </Typography>
                  </Grid>
                </Grid>
              )}
              </Mutation>
            </Box>
          </BaseModal.BaseModalFooter>
        </BaseModal.BaseModalBody>
      </BaseModal>
    )
  }
}

export default ReopenStudentProjectModal
