import React from 'react'
import { checkStudentAccessToPhoto } from '../../../../utils/commonFunctions'
import ReplacePhotoUploader from '../../ReplacePhotoUploader'
import { Box, Typography } from '@mui/material'
const FileOperationSection = props => {
    const {
      role,
      photo,
      refetchPhoto,
      updateImage,
      replacePhotoToast,
      userStore
    } = props
    return role === 'appadmin' || (['student'].includes(role) && photo && photo.project && !photo.project.submitted) ? (
      <Box component='div' className='photo-detail__file-replace' sx={{ pt: { xs: 4 }, pb: { xs: 8, lg: 10 }}}>
        <Typography variant='h2' className='strong'>Files</Typography>
        <Box component='div'>
          {role === 'appadmin' ||
          (['student'].includes(role) &&
            checkStudentAccessToPhoto(photo.project, userStore)) ? (
            <>
              <ReplacePhotoUploader
                photo={photo}
                updateImage={updateImage}
                refetchPhoto={refetchPhoto}
              />
            </>
          ) : null}
        </Box>
      </Box>
    ) : null
}

export default FileOperationSection