import React, { Fragment } from 'react'
import './herotabs.scss'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { Camera, Flag, Eye, Award, ChevronsUp, ChevronsDown } from 'react-feather'
import GATag from '../../utils/GATag'

class ClassTrackerHeroTabs extends React.Component {
  getIcon = tabName => {
    if (tabName === 'All Photos') {
      return (
        <Fragment>
          <Camera size={16} /> {tabName}
        </Fragment>
      )
    } else if (tabName === 'Final') {
      return (
        <Fragment>
          <Flag size={16} /> {tabName}
        </Fragment>
      )
    } else if (tabName === 'Class Share') {
      return (
        <Fragment>
          <Eye size={16} /> {tabName}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <Award size={16} /> {tabName}
        </Fragment>
      )
    }
  }
  render() {
    const tabs = this.props.tabs
    const defaultActiveKey = tabs[0]['eventKey']
    return (
      <div className='hero__tabs class-tracker-hero__tabs'>
        <span className='class-tracker-filters'>View By:</span>
        <Tabs
          id='controlled-tab'
          activeKey={this.props.currentTab}
          onSelect={this.props.tabChangeHandler}
          defaultActiveKey={defaultActiveKey}
          TabScrollButtonProps={{ sx: { width: '20px' } }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              eventKey={tab.eventKey}
              title={this.getIcon(tab.title)}
              id={tab.eventKey}
            />
          ))}
        </Tabs>
        {this.props.expandAll ? (
          <div className='collapse-all' onClick={() => this.props.collapseAllFunc(false)}>
            <ChevronsUp size={16} /> Collapse All
          </div>
        ) : (
          <div
            className='collapse-all'
            onClick={() => {
              // window.ga('send', 'event', 'class-tracker', 'expanded', 1)
              GATag.setEvent({
                category: 'class-tracker',
                action: 'expanded',
                label: 'class tracker expanded',
                value: 1
              })
              this.props.collapseAllFunc(true)
            }}
          >
            <ChevronsDown size={16} /> Expand All
          </div>
        )}
      </div>
    )
  }
}

export default ClassTrackerHeroTabs
