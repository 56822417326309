import React from 'react'
import { Typography } from '@mui/material'
import { validationText } from '../../content/validationText'

const Cancel = props => {
  const handleClick = () => {
    props?.onClick?.()
    props?.showToast?.('info', validationText.generic.cancel)
  }
  return (
    <Typography variant='link' onClick={handleClick} sx={{ ...props.sx }}>
      {props?.label || 'Cancel'}
    </Typography>
  )
}
export default Cancel
