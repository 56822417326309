import React, { Component, Fragment } from 'react'
import { gql } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { Typography } from '@mui/material'

import Loading from '../ui/elements/Loading.js'
import { observer, inject } from 'mobx-react'
import './publicresources.scss'
import PublicResourceList from '../ui/organisms/PublicResourceList'
import Layout from '../ui/template/Layout.js'
const QUERY = gql`
  query PublicResources {
    resources {
      id
      name
      category
      linkToResource
    }
  }
`

class PublicResources extends Component {
  render() {
    return (
      <Layout>
      <Query query={QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <Loading />
          if (error) {
            return <Typography variant="paragraph" component="p">Error loading classes</Typography>
          }
          if (data) {
            return (
              <Fragment>
                <div className='container__public-body'>
                  <div className='public-body__public-resources'>
                    <div className='public-resources__hero'>
                      <div className='public-resources__hero--header'>
                        <h1 className='h1--strong public'>Resources</h1>
                      </div>
                      <div className='public-resources__hero--copy'>
                        <h2 className='h2--medium'>
                          We provide students and teachers in the <i>wrkxfmly</i> assignment with a
                          rich set of supporting resources. These include activities that help frame
                          explorations of work and family, guides for composing photographs, advice
                          on pursuing photography beyond the classroom, and more.
                        </h2>
                      </div>
                    </div>
                    <PublicResourceList resources={data.resources} />
                  </div>
                </div>
              </Fragment>
            )
          }
        }}
      </Query>
      </Layout>
    )
  }
}

export default inject('userStore')(observer(PublicResources))
