import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'
import { Box, Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import { PAGINATED_LIBRARY_PHOTO_QUERY } from '../../queries/projectDetailsQueries'
import { withRouter } from '../../utils/customHoc'
import Loading from '../elements/Loading'
import Photo from '../elements/Photo.js'
import CustomPagination from '../molecules/CustomPagination'
import PhotoOverlay from '../molecules/PhotoOverlay.js'
import NewFullScreenPhotoModal from './PhotoDetailsModal/NewFullScreenPhotoModal'
import PhotoUploader from './PhotoUploader.js'
import './photogallery.scss'

const PhotoGallery = props => {
  const {
    userStore,
    projectId,
    currentTab,
    projectSubmitted,
    isCohortEnded,
    showToast,
    photosStore
  } = props
  const { role, profileId } = userStore
  const [libraryCount, setLibraryCount] = useState(0)
  const [libraryPhotos, setLibraryPhotos] = useState([])
  const [currentLibraryPhotos, setCurrentLibraryPhotos] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [pageNum, setPageNum] = useState(0)
  const [batchSize] = useState(9)
  const [uploadedFileCount, setUploadedFileCount] = useState(0)
  const [expectedFileCount, setExpectedFileCount] = useState(0)
  const [selectedPhotoId, setSelectedPhotoId] = useState(-1)
  const [showPhotoModal, setShowPhotoModal] = useState(false)
  const [modalFadeOut, setModalFadeOut] = useState(false)

  const {
    loading: libraryPhotosLoading,
    data: libraryPhotosData,
    error: libraryPhotosError,
    refetch: libraryPhotosRefetch,
    fetchMore: libraryPhotosFetchMore
  } = useQuery(PAGINATED_LIBRARY_PHOTO_QUERY, {
    variables: {
      batchSize: batchSize,
      projectId: projectId,
      step: pageNum < 0 ? 0 : pageNum,
      isShortListed: false
    },
    fetchPolicy: 'network-only',
    onCompleted(data) {
      let totalPhotos = data.libraryPhotosCount
      setLibraryCount(data.libraryPhotosCount)
      setTotalPages(Math.ceil(totalPhotos / batchSize))
      if (data.projectPhotosGalleryConnection.length === 0) {
        setPageNum(pageNum - 1)
      } else {
        const getIds = data.getAllLibraryPhotos.map(photo => photo.id)
        photosStore.setPhotoStore('library', [...getIds])
        setCurrentLibraryPhotos(data.projectPhotosGalleryConnection)

        props.setRefetchAllSection(false)
      }
    }
  })

  const thumbnailOnClick = photo => {
    setSelectedPhotoId(photo.id)
    setShowPhotoModal(true)
  }

  const customFetchMore = (newPageNum, isUpload = false) => {
    setPageNum(newPageNum)
    libraryPhotosFetchMore({
      variables: {
        step: newPageNum
      }
    })
  }

  const updateState = async (photoId = -1, opType = 'move') => {
    let photo
    if (photoId > 0) {
      photo = libraryPhotos.find(photo => photo.id === photoId)
    }

    let newPageNum = pageNum
    if (libraryPhotos.findIndex(pho => pho.id === photoId) === libraryCount - 1) {
      newPageNum = pageNum - 1
    }
    setLibraryCount(libraryCount - 1)
    setPageNum(newPageNum)
    setLibraryPhotos(libraryPhotos.filter(img => img.id !== photo.id), async () => {
      await customFetchMore(newPageNum)
    })

    // updateHeader('library', libraryCount)
    // if (opType !== 'delete') props.addLibPhotoToFinal(photo)
    props.setRefetchAllSection(true)
  }

  const getAdjacentImage = async (direction, photoId) => {
    if (direction && !['prev', 'next'].includes(direction)) return

    const photos = libraryPhotos
    const currIdx = photos.findIndex(elem => elem.id === photoId)
    if (direction === 'prev') {
      setSelectedPhotoId(currIdx === 0 ? photos[photos.length - 1].id : photos[currIdx - 1].id)
    } else {
      if (libraryCount - 1 >= currIdx && !photos[currIdx + 1]) {
        await customFetchMore(pageNum + 1, null, false, true)
      } else {
        setSelectedPhotoId(currIdx === libraryCount - 1 ? photos[0].id : photos[currIdx + 1].id)
      }
    }
  }

  useEffect(() => {
    if (props.refetchData) {
      // countRefetch()
      libraryPhotosRefetch()
    }
  }, [libraryPhotosRefetch, props.refetchData])

  return (
    <Box sx={{ backgroundColor: '#f7f7f7', px: { xs: 4, md: 6 }, my: { xs: 8 } }}>
      <Typography variant='h2' className='strong'>
        Library ({libraryCount})
      </Typography>
      <Box sx={{ my: 4 }}>
        {currentTab === 'photographs' ? (
          ['student'].includes(role) ? (
            <Typography variant='paragraph'>
              You chose not to submit these photographs to Working Assumptions. You can still view
              them here privately at any time.
            </Typography>
          ) : (
            <Typography variant='paragraph'>
              The student chose not to submit these photographs to Working Assumptions. The
              photographs will remain in the Library.
            </Typography>
          )
        ) : ['student'].includes(role) ? (
          <Box>
            <Typography variant='paragraph'>
              Upload photographs here to review with your teacher and teaching artist. Then move
              your favorites to your Shortlist.
            </Typography>
            <Box sx={{ mt: '16px' }}>
              <Typography variant='h4' className='line-height'>
                • Use the highest resolution JPEG files that you can.
              </Typography>
              <Typography variant='h4' className='line-height'>
                • Files 2000px wide or larger work best.
              </Typography>
              <Typography variant='h4' className='line-height'>
                • You can only upload a maximum of 10 files at once.
              </Typography>
            </Box>
          </Box>
        ) : (
          <Typography variant='paragraph'>
            The student will upload their photographs here. From the Library, they can select
            <br />
            photographs to share with the class and/or move photographs to the Shortlist.
          </Typography>
        )}
      </Box>

      {projectSubmitted || isCohortEnded ? null : ['student', 'appadmin'].includes(role) ? (
        <Box sx={{ mb: 4 }}>
          <PhotoUploader
            projectId={projectId}
            photos={props.photos}
            buttonTitle='Upload Photographs'
            buttonVariant={'contained'}
            libraryPhotosCount={libraryCount}
            onUploadCompleteCb={async () => {
              await customFetchMore(0)
            }}
            showToast={props.showToast}
            newUpload={props.newUpload}
            setUploadedFileCount={fileCount => {
              setUploadedFileCount(fileCount)
              setExpectedFileCount(fileCount + libraryCount)
            }}
            refetchProject={props.refetchProject}
          />
        </Box>
      ) : null}
      {libraryPhotosLoading ? (
        <Box sx={{ position: 'relative' }}>
          <Loading />
        </Box>
      ) : !libraryPhotosLoading && libraryCount ? (
        <Box>
          <Grid container columnSpacing={2} rowGap={3}>
            {currentLibraryPhotos.map(photo => (
              // <Box className='gallery__container' key={'gallery-container-' + photo.id} sx={{mb: 4}}>
              <Grid item xs={12} sm={6} md={4} key={'gallery__container__' + photo.id}>
                <Box className='gallery__photo-container' key={photo.id} data-id={photo.id}>
                  <PhotoOverlay
                    showToast={showToast}
                    key={'PhotoOverlay' + photo.id}
                    photo={photo}
                    photoOnClick={() => thumbnailOnClick(photo)}
                    updateParentState={(photoId, opType = 'move') => updateState(photoId, opType)}
                    hideStudentSection={
                      _.includes(['student'], role) && profileId !== photo.project.student.id
                        ? true
                        : null
                    }
                    refetchProject={props.refetchProject}
                    setRefetchAllSection={props.setRefetchAllSection}
                    isCohortEnded={isCohortEnded}
                  />
                  <Photo
                    photo={photo}
                    placeholderDark={true}
                    onClick={() => thumbnailOnClick(photo)}
                    letterBoxType={true}
                    showToast={props.showToast}
                    key={'PhotoComp' + photo.id}
                    hideStudentSection={
                      _.includes(['student'], role) && profileId !== photo.project.student.id
                        ? true
                        : null
                    }
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
          <Box sx={{ mt: { xs: 4 } }}>
            <CustomPagination totalPages={totalPages} pageNum={pageNum + 1} onChange={setPageNum} />
          </Box>
        </Box>
      ) : null}
      {selectedPhotoId !== -1 ? (
        <NewFullScreenPhotoModal
          show={showPhotoModal}
          closeModal={() => {
            setModalFadeOut(true)
            setTimeout(() => {
              setShowPhotoModal(false)
              setModalFadeOut(false)
              setSelectedPhotoId(-1)
              libraryPhotosRefetch()
            }, 500)
          }}
          photoId={selectedPhotoId}
          isModal={true}
          placeholderDark={true}
          showToast={props.showToast}
          totalCount={libraryCount}
          componentCallFrom='library'
          toggleParentModalState={(pushState = true, shouldRefetch = false) => {
            if (pushState) {
              window.history.pushState(null, '', props.backToPageStore.activePage)
            }
            setModalFadeOut(true)
            // setFadeOut(false)
            setTimeout(() => {
              setShowPhotoModal(false)
              setSelectedPhotoId(-1)
              setModalFadeOut(false)
            }, 500)
          }}
          photoCurrentIndex={libraryPhotos.findIndex(elem => elem.id === selectedPhotoId)}
          getAdjacentImage={getAdjacentImage}
          // photo={allPhotos.find(photo => photo.id === this.state.selectedPhotoId)}
          // photoDetails={allPhotos.find(photo => photo.id === this.state.selectedPhotoId)}
          // updateParentGallery={this.updateParentGallery}
          // changeScopedProject={this.props.changeScopedProject}
          animation={false}
          className={`animated ${
            showPhotoModal && !modalFadeOut ? 'fadeInUpBig' : 'fadeOutDownBig'
          }`}
          student={props.student}
        />
      ) : null}
    </Box>
  )
}
export default inject('photosStore', 'userStore', 'backToPageStore')(
  observer(withApollo(withRouter(PhotoGallery)))
)
