import { gql } from '@apollo/client'

export const MODEL_RELEASE_ATTRIBUTES = gql`
    fragment modelReleaseAttributes on ModelRelease {
        id
        uuid
        sentTo
        phoneNumber
        email
        sentViaSms
        sentViaEmail
        createdAt
        firstName
        lastName
        modelFirstName
        modelLastName
        linkToFile
        signed
        sentVia
        isMinor
        isDeceased
        signeeFirstName
        signeeLastName
        mrStatus
        adminDecision
        relatedToMr
        signedOn
        adminDecisionOn
        adminRejectionNote
        assetId
    }
`
