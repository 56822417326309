import React, { Component } from 'react'
import './toast.scss'
import { CheckCircle, Clock, AlertTriangle, Info } from 'react-feather'
import { default as BootstrapToast } from 'react-bootstrap/Toast'
import { Typography } from '@mui/material'
class Toast extends Component {
  render() {
    const toastColor = this.props.toastColor || ''
    return (
      <div
        className={`${
          window.location.pathname.includes('project-detail') && toastColor ? 'upload-toast' : ''
        } toast---${toastColor || ''} status--toast`}
      >
        <BootstrapToast>
          <div className={`container__toast ${this.props.textSize ? 'smallText' : ''}`}>
            <BootstrapToast.Header >
              <Typography variant="h4" className='semi-strong'>
                <span className='icon-span'>
                  {['', 'green'].includes(toastColor) ? (
                    <CheckCircle size={16} color={'#fff'} />
                  ) : toastColor === 'yellow' ? (
                    <Clock size={16} color={'#fff'} />
                  ) : toastColor === 'blue' ? (
                    <Info size={16} color={'#fff'} />
                  ) : (
                    <AlertTriangle size={16} color={'#fff'} />
                  )}
                </span>
                <span className='text-span'>{this.props.toastMessage}</span>
              </Typography>
            </BootstrapToast.Header>
          </div>
        </BootstrapToast>
      </div>
    )
  }
}

export default Toast
