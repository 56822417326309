import { useMutation } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import Button from '../../../elements/Button'
import NakedFormField from '../../../elements/NakedFormField'
import { UPDATE_META_DATA_MUTATION } from '../../../../queries/PhotoDetailsQueries'
import variables from '../../../settings/_variables.scss'

const PhotoTitleCaptionSection = props => {
  const { photoId, photoDetails, role, projectSubmitted } = props
  const [editing, setEditing] = useState({
    caption: false,
    title: false
  })
  const [photo, setPhoto] = useState({
    title: photoDetails && photoDetails.title,
    caption: photoDetails && photoDetails.caption
  })

  const [detailsUpdated, setDetailsUpdated] = useState(true)
  const titleInputRef = useRef()
  const captionInputRef = useRef()
  const isEditable =
    !window.location.pathname.includes('national') &&
    ((['student'].includes(role) && !projectSubmitted) ||
      (role === 'appadmin' && !projectSubmitted))

  useEffect(() => {
    setEditing({
      title: false,
      caption: false
    })
    setPhoto({
      title: props.photoDetails && props.photoDetails.title,
      caption: props.photoDetails && props.photoDetails.caption
    })
    setDetailsUpdated(true)
  }, [props.photoId])

  useEffect(() => {
    setPhoto({
      title: props.photoDetails.title,
      caption: props.photoDetails.caption
    })
  }, [props.photoDetails])

  const [titleCaptionMuatation, { loading, data, error }] = useMutation(UPDATE_META_DATA_MUTATION, {
    onCompleted(data) {
      setEditing({
        caption: false,
        title: false
      })
      setPhoto({
        title: titleInputRef.current.value,
        caption: captionInputRef.current.value
      })
      setDetailsUpdated(true)
      props.refetch()
      props.showToast('success', 'All changes saved.')
      props.setIsDetailsBeignEdited(false)
    },
    onError(error) {
      console.log(error, 'error')
    }
  })
  const toggleEdit = key => {
    setEditing({
      ...editing,
      [key]: !editing[key]
    })
  }

  const adjustInputColor = elem => {
    if (!elem) return
    const type = elem.name.toLowerCase()
    if (photo[`${type}`] && photo[`${type}`].trim() === '') {
      const placeholder =
        type === 'title'
          ? 'Add a Title'
          : 'Write a caption about your photograph here. What does this image mean to you? What does it tell us about how work impacts your life and the lives of the people around you?'
      elem.setAttribute('placeholder', placeholder)
      elem.classList.replace('blackened-out', 'greyed-out')
    } else {
      elem.classList.replace('greyed-out', 'blackened-out')
    }
    toggleEdit(elem.name)
    props.setIsDetailsBeignEdited(false)
  }

  const clearElementText = elem => {
    toggleEdit(elem.name)
    const placeholder =
      elem.name.toLowerCase() === 'title'
        ? photo.title || 'Add a Title'
        : photo.caption ||
          'Write a caption about your photograph here. What does this image mean to you? What does it tell us about how work impacts your life and the lives of the people around you?'
    if (placeholder === '') elem.classList.replace('blackened-out', 'greyed-out')
    props.setIsDetailsBeignEdited(true)
  }

  const handlePhotoAttributeChange = event => {
    const { name, value } = event.target
    const oldPhoto = { ...photo }
    setPhoto({ ...oldPhoto, [name]: value })
    setDetailsUpdated(false)
  }

  const cancleEvent = () => {
    setEditing({
      caption: false,
      title: false
    })
    setPhoto({
      caption: photoDetails.caption,
      title: photoDetails.title
    })
    props.refetch()
    props.showToast('info', 'All changes have been discarded.')
  }

  return (
    <Fragment>
      <Box className='body__editor--active' sx={{ mt: { xs: 3 }, mb: { xs: 5, lg: 7 }, px: 0 }}>
        <Box className={`${isEditable ? 'body__editor--title' : 'body__editor--title2'}`}>
          <Typography variant='h1' className={`${editing.title ? 'editing' : 'editable'} bold`}>
            {isEditable ? (
              <NakedFormField
                type='text'
                name='title'
                placeholder={'Add a Title'}
                fullWidth={true}
                onChange={handlePhotoAttributeChange}
                value={photo.title || ''}
                onBlur={() => adjustInputColor(titleInputRef.current)}
                onFocus={() => clearElementText(titleInputRef.current)}
                inputRef={titleInputRef}
              />
            ) : photoDetails.originalTitle ? (
              photoDetails.originalTitle
            ) : (
              <Box className='body__editor--placeholder'>Add a Title</Box>
            )}
          </Typography>
        </Box>
        <Typography variant='paragraph' component={'p'} mt={{ xs: 1 }}>
          {photoDetails ? (
            photoDetails.name === 'Anonymous' ? (
              <>
                Anonymous, {photoDetails.attribution.year} ©<i>All Rights Reserved</i>
              </>
            ) : (
              <>
                {photoDetails.attribution.replace('NotSupplied', '')},{' '}
                {photoDetails.attribution.year} ©
                <i> {photoDetails.attribution.replace('NotSupplied', '')}</i>
              </>
            )
          ) : null}
        </Typography>
        <Box
          mt={{ xs: 2.5 }}
          className={`${isEditable ? 'body__editor--caption' : 'body__editor--caption2'}`}
        >
          <Fragment>
            <Typography variant='blockQuote'>
              {isEditable ? (
                <NakedFormField
                  multiline={true}
                  fullWidth={true}
                  type='text'
                  name='caption'
                  placeholder='Write a caption about your photograph here. What does this image mean to you? What does it tell us about how work impacts your life and the lives of the people around you?'
                  onChange={handlePhotoAttributeChange}
                  value={photo.caption || ''}
                  onBlur={() => adjustInputColor(captionInputRef.current)}
                  onFocus={() => clearElementText(captionInputRef.current)}
                  inputRef={captionInputRef}
                  rows={5.4}
                  sx={{
                    fontFamily: `${variables.lyon} !important`,
                    fontSize: `${variables.sizeH3}`
                  }}
                />
              ) : photoDetails.originalCaption ? (
                photoDetails.originalCaption
              ) : (
                `Write a caption about your photograph here. What does this image mean to you?
                  What does it tell us about how work impacts your life and the lives of the
                  people around you?`
              )}
            </Typography>
          </Fragment>
          {isEditable ? (
            <Box
              sx={{
                mt: { xs: 2.5, sm: 1.3 },
                mb: { xs: 0 },
                textAlign: { xs: 'center' },
                display: { xs: 'grid', sm: 'flex' }
              }}
            >
              <Button
                title='Save Changes'
                onClick={() => {
                  titleCaptionMuatation({
                    variables: {
                      id: photoId,
                      ...photo
                    }
                  })
                }}
                disabled={
                  (!editing.title && !editing.caption && detailsUpdated) ||
                  !(
                    (captionInputRef.current &&
                      photoDetails.caption !== captionInputRef.current.value.trim()) ||
                    (titleInputRef.current &&
                      photoDetails.title !== titleInputRef.current.value.trim())
                  )
                }
                sx={{ width: { xs: '100%', sm: '33%', md: 'auto' } }}
              />
              {'  '}
              {(!editing.title && !editing.caption && detailsUpdated) ||
              !(
                (captionInputRef.current &&
                  photoDetails.caption !== captionInputRef.current.value.trim()) ||
                (titleInputRef.current && photoDetails.title !== titleInputRef.current.value.trim())
              ) ? null : (
                <Typography
                  variant={'link'}
                  onClick={() => cancleEvent()}
                  sx={{
                    mt: { xs: 2, sm: '0' },
                    ml: { sm: 3, md: 2 },
                    alignSelf: 'center'
                  }}
                >
                  Cancel
                </Typography>
              )}
            </Box>
          ) : null}
        </Box>
      </Box>
    </Fragment>
  )
}

export default PhotoTitleCaptionSection
