import React, { Component } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'

class ClassApprovalTableRows extends Component {
  render() {
    const { cohort, isEndDate } = this.props

    return (
      <Grid container spacing={2}>
        <Grid item md={7} sm={6} xs={12} lg={8}>
          <Link to={`/class-detail/${cohort.id}`}>
            <Typography variant='h4' className='semi-strong truncate'>
              {cohort.name}
            </Typography>
          </Link>
        </Grid>
        <Grid item md={5} sm={6} xs={6} lg={4} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Typography variant='h4'>
            {moment(isEndDate ? cohort.endDate : cohort.startDate).format(
              'MMMM D, YYYY'
            )}
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

export default ClassApprovalTableRows
