import React, { Component } from 'react'
import './noresultlistmessage.scss'

class NoResultListMessage extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return <div className="no-message-container">
    <div className="no-message-sub-container">{this.props.message}</div>
  </div>
  }
}

export default NoResultListMessage