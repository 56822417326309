import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import Photo from '../elements/Photo'
import NewFullScreenPhotoModal from '../organisms/PhotoDetailsModal/NewFullScreenPhotoModal'
import PhotoOverlay from './PhotoOverlay'
import PhotoStatusStack from './PhotoStatusStack'
import { Box, Grid } from '@mui/material'
import DateUtils from '../../utils/DateUtils'

const ProjectDetailsAoeAwardSection = props => {
  const { photos, role, photosStore } = props
  const [showPhotoModal, setShowPhotoModal] = useState(false)
  const [selectedPhotoId, setSelectedPhotoId] = useState(-1)
  const [modalFadeOut, setModalFadeOut] = useState(false)
  const getPhotoIds = photos.map(photo => photo.id)
  photosStore.setPhotoStore('aoeAward', [...getPhotoIds])
  return (
    <Fragment>
      <h3 className='h3--strong student-results__award-heading'>Award of Excellence</h3>
      <Grid container columnSpacing={2}>
        {photos.map(photo => (
          <Grid item xs={12} sm={6} md={4}>
            {/* <Box component='div' className='gallery__container' key={`hmgallery__container__${photo.id}`}> */}
            <Box
              component='div'
              className='gallery__photo-container'
              key={photo.id}
              data-id={photo.id}
            >
              <PhotoOverlay
                showToast={props.showToast}
                key={'PhotoOverlay' + photo.id}
                photo={photo}
                photoOnClick={() => {
                  setShowPhotoModal(true)
                  setSelectedPhotoId(photo.id)
                }}
                isCohortEnded={DateUtils.isDatePassed(photo.project.cohort.endDate)}
              />
              <Photo
                photo={photo}
                placeholderDark={true}
                onClick={() => {
                  setShowPhotoModal(true)
                  setSelectedPhotoId(photo.id)
                }}
                renderAsBackgroundImage={true}
                showToast={props.showToast}
                key={'PhotoComp' + photo.id}
                viewBadges={
                  (['student', 'teacher', 'facilitator'].includes(role) && photo.summarySent) ||
                  false
                }
                showAwards={true}
                projectPage={true}
                role={role}
              />
            </Box>
            {/* <Box component='div' className='gallery__photo-container--missing-content' /> */}
            {['appadmin', 'teacher', 'student', 'facilitator'].includes(role) && (
              <PhotoStatusStack photo={photo} role={role} awardResults={false} />
            )}
            {/* </Box> */}
          </Grid>
        ))}
      </Grid>
      {selectedPhotoId !== -1 ? (
        <NewFullScreenPhotoModal
          show={showPhotoModal}
          closeModal={() => {
            setModalFadeOut(true)
            setTimeout(() => {
              setShowPhotoModal(false)
              setModalFadeOut(false)
              setSelectedPhotoId(-1)
            }, 500)
          }}
          photoId={selectedPhotoId}
          isModal={true}
          placeholderDark={true}
          showToast={props.showToast}
          totalCount={photos.length}
          componentCallFrom='aoeAward'
          toggleParentModalState={(pushState = true, shouldRefetch = false) => {
            if (pushState) {
              window.history.pushState(null, '', props.backToPageStore.activePage)
            }
            setModalFadeOut(true)
            // setFadeOut(false)
            setTimeout(() => {
              setShowPhotoModal(false)
              setSelectedPhotoId(-1)
              setModalFadeOut(false)
            }, 500)
          }}
          photoCurrentIndex={photos.findIndex(elem => elem.id === selectedPhotoId)}
          animation={false}
          className={`animated ${
            showPhotoModal && !modalFadeOut ? 'fadeInUpBig' : 'fadeOutDownBig'
          }`}
          student={props.student}
        />
      ) : null}
    </Fragment>
  )
}

export default inject('photosStore', 'backToPageStore')(observer(ProjectDetailsAoeAwardSection))
