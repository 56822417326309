import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'
import ImageZoom from '../../ImageZoom'
import 'react-lazy-load-image-component/src/effects/blur.css'
import galleryPlaceholder from '../../../../assets/images/gallery-placeholder.svg'
import galleryPlaceholderDark from '../../../../assets/images/gallery-placeholder-dark.svg'
import { Box, Button, useMediaQuery, useTheme } from '@mui/material'
import variable from '../../../settings/_variables.scss'


const ImageSection = props => {
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const defaultWidth = '53vw'
  const {
    isModal,
    getAdjacentImage,
    publicView,
    photoDetails,
    enableNext,
    enablePrev,
    loading: parentLoading,
    placeholderDark
  } = props

  const photo_container = {
    width: '100vw',
    textAlign: 'center',
    p: '2rem 1rem 1rem 1rem',
    position: 'relative',
    mt: isModal ? {
      xs: '3rem',
      sm: '4rem'
    } : 0
  }

  const [zoomUrl, setZoomUrl] = useState(photoDetails ? photoDetails.originalUrl : null)
  const [displayUrl, setDisplayUrl] = useState(
    photoDetails
      ? photoDetails.cdnOriginalUrl ||
          photoDetails.url ||
          photoDetails.originalUrl ||
          photoDetails.sizedUrl
      : null
  )
  const [shouldAllowUpdate, setShouldAllowUpdate] = useState(props.shouldAllowUpdate)
  const [zoomEnabled, setZoomEnabled] = useState(false)
  const [imagWidth, setImageWidth] = useState(defaultWidth)
  const [imageLoaded, setImageLoaded] = useState(false)
  const enableColor = publicView ? `${variable.black}` : `${variable.white}`
  const disableColor = publicView ? `${variable.silver}` : `${variable.silverOpacity}`
  const nextPrevButton = {
    backgroundColor: publicView ? 'transparent' : disableColor,
    border: publicView ? '0px' : `1px solid ${disableColor}`,
    borderRadius: 0,
    width: { xs: 32, md: 56 },
    height: 128,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    minWidth: 'auto'
  }

  const nextStyling = {
    ...nextPrevButton,
    left: 0,
    border: publicView ? '0px' : `1px solid ${enablePrev ? enableColor : disableColor}`,
    borderLeft: 0,
    color: enablePrev ? enableColor : disableColor,
    '&:hover': {
      backgroundColor: publicView ? 'transparent' : disableColor,
      border: publicView ? '0px' : `1px solid ${enablePrev ? enableColor : disableColor}`,
      borderLeft: 0,
      color: enablePrev ? enableColor : disableColor
    },
    '&:disabled': {
      backgroundColor: publicView ? 'transparent' : disableColor,
      border: publicView ? '0px' : `1px solid ${disableColor}`,
      borderLeft: 0,
      cursor: 'not-allowed'
    },
    svg: {
      color: enablePrev ? enableColor : disableColor
    }
  }
  const prevStyling = {
    ...nextPrevButton,
    right: 0,
    border: publicView ? '0px' : `1px solid ${enableNext ? enableColor : disableColor}`,
    borderRight: 0,
    color: enableNext ? enableColor : disableColor,
    '&:hover': {
      backgroundColor: publicView ? 'transparent' : disableColor,
      border: publicView ? '0px' : `1px solid ${enableNext ? enableColor : disableColor}`,
      borderRight: 0,
      color: enableNext ? enableColor : disableColor
    },
    '&:disabled': {
      backgroundColor: publicView ? 'transparent' : disableColor,
      border: publicView ? '0px' : `1px solid ${enableNext ? enableColor : disableColor}`,
      borderRight: 0,
      cursor: 'not-allowed'
    },
    svg: {
      color: enableNext ? enableColor : disableColor
    }
  }

  const handleResize = () => {
    setImageWidth(
      document.getElementById('picture') ? document.getElementById('picture').width : defaultWidth
    )
  }

  const handleImageNavigation = navType => {
    setDisplayUrl(null)
    if (!shouldAllowUpdate) {
      setShouldAllowUpdate(true)
      getAdjacentImage(navType)
      return
    }
    getAdjacentImage(navType)
  }

  const getImageZoomComponent = () => {
    return mobileScreen ? null : (
      <ImageZoom
        srcSmall={zoomUrl}
        src={zoomUrl}
        onClose={() => setZoomEnabled(false)}
        width={photoDetails && photoDetails.exifData && photoDetails.exifData.width}
        height={photoDetails && photoDetails.exifData && photoDetails.exifData.height}
        display={zoomEnabled}
      />
    )
  }

  const getImageComponent = () => {
    return (
      <figure className='item'>
        {displayUrl ? (
          <Box
            component='img'
            sx={{
              maxHeight: '70vh',
              maxWidth: '85vw',
              verticalAlign: 'middle',
              cursor: 'zoom-in',
              width: 'auto',
              filter: parentLoading && !imageLoaded ? 'blur(3px)' : 'none'
            }}
            src={
              !parentLoading && imageLoaded
                ? displayUrl
                : placeholderDark
                ? galleryPlaceholderDark
                : galleryPlaceholder
            }
            id='picture'
            onClick={() => setZoomEnabled(true)}
            onLoad={() => {
              setImageLoaded(true)
              handleResize()
            }}
          />
        ) : null}
        {publicView && photoDetails ? (
          <figcaption className='caption' style={{ width: imagWidth }}>
            <i>{photoDetails.title}</i>,{' '}
            {photoDetails.attribution ? photoDetails.attribution : 'Anonymous'},{' '}
            {moment(photoDetails.submittedDate).format('YYYY')}
          </figcaption>
        ) : null}
      </figure>
    )
  }
  useEffect(() => {
    setDisplayUrl(
      photoDetails
        ? photoDetails.cdnOriginalUrl ||
            photoDetails.url ||
            photoDetails.originalUrl ||
            photoDetails.sizedUrl
        : null
    )
    setZoomUrl(
      photoDetails
        ? photoDetails.originalUrl ||
            photoDetails.cdnOriginalUrl ||
            photoDetails.url ||
            photoDetails.sizedUrl
        : null
    )
  }, [props])

  return isModal ? (
    <>
      <Box
        component='div'
        className={publicView ? 'collection-modal-photo-container' : 'modal-photo-container'}
        sx={{...photo_container}}
        backgroundColor={publicView ? '#fff' : '#020202'}
      >
        <Button
          sx={{ ...nextStyling }}
          disabled={!enablePrev}
          onClick={() => (enablePrev ? handleImageNavigation('prev') : null)}
        >
          <ChevronLeft size={publicView ? 24 : 16} strokeWidth={publicView ? 2 : 3} />
        </Button>
        <span className='helper' />
        {getImageZoomComponent()}
        {getImageComponent()}
        <Button
          sx={{ ...prevStyling }}
          disabled={!enableNext}
          onClick={() => (enableNext ? handleImageNavigation('next') : null)}
        >
          <ChevronRight size={publicView ? 24 : 16} strokeWidth={publicView ? 2 : 3} />
        </Button>
      </Box>
    </>
  ) : (
    <Box sx={{
      width: '100vw',
        backgroundColor: publicView ? '#fff' : '#020202',
        textAlign: 'center',
        // padding: '16px',
        position: 'relative',
        ...photo_container
    }}>
      <div className='' key={photoDetails.id}>
        {getImageZoomComponent()}
        {getImageComponent()}
      </div>
    </Box>
  )
}

export default ImageSection
