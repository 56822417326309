import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { Calendar } from 'react-feather'
import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'
import _ from 'lodash'
import { getStatusesForPage } from '../../content/textContent'
import { getFormattedStatus } from '../../utils/commonFunctions'
import variables from '../../../src/ui/settings/_variables.scss'
import DateUtils from '../../utils/DateUtils'

const ClassListRow = props => {
  const today = moment(DateUtils.createMoment().toString())
  const { cohort, index } = props
  const { role } = props.userStore
  const baseLink = props.role === 'curator' ? 'class-review' : 'class-detail'
  const classStatuses = getStatusesForPage('CLASSLIST', cohort, props.userStore)

  const [endDate] = useState(
    moment(DateUtils.createMoment(cohort.endDate).toString())
      .hours(23)
      .minutes(59)
      .seconds(59)
  )

  const renderDateColumn = (cohort, index, today) => {
    let warningText = ''
    let warningIsUrgent = false

    if (!cohort.allVisitsScheduled) {
      if (['appadmin', 'teacher', 'facilitator'].includes(props.role)) {
        warningText = 'Schedule Class Visits'
      }
      warningIsUrgent = props.role !== 'appadmin'
    }

    return (
      <Fragment>
        {cohort.startDate && endDate ? (
          <Typography variant='h4'>
            {moment(cohort.startDate, 'YYYY-MM-DD').format('MMMM D, YYYY')} —{' '}
            {DateUtils.createMoment(endDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
          </Typography>
        ) : (
          <Typography
            variant='h4'
            style={{ marginBottom: `${window.innerWidth < 611 ? '-5px' : '0'}` }}
          >
            No Dates Added for This Class
          </Typography>
        )}
        {cohort.startDate && endDate ? (
          <div className='classes__days-remaining' key={index}>
            {DateUtils.createMoment(cohort.startDate)
              .hours(0)
              .minutes(0)
              .seconds(0)
              .isAfter(today, 'day') ? (
              <Typography variant='h5'>
                {DateUtils.pendingTimeToEndDate(cohort.startDate)} Until Start Date
              </Typography>
            ) : !DateUtils.isDatePassed(endDate) ? (
              <Typography variant='h5'>
                {DateUtils.pendingTimeToEndDate(
                  moment(cohort.endDate)
                    .hours(23)
                    .minutes(59)
                    .seconds(59)
                )}{' '}
                Until End Date
              </Typography>
            ) : (
              <Typography variant='h5'>Class Ended</Typography>
            )}
          </div>
        ) : null}
        {props.role === 'curator' ? null : (
          <div className='classes__next-visit' key={`curator-${index}`}>
            {cohort.nextFacilitatorVisit ? (
              <Typography
                variant='h4'
                sx={{
                  display: { xs: 'none', sm: 'none', md: 'block' }
                }}
              >
                <Calendar size={17} color={'#505050'} line-height={20} /> Next Visit:{' '}
                {cohort.introVisit && cohort.nextFacilitatorVisit === cohort.introVisit.date
                  ? 'Introduction Visit On'
                  : cohort.midVisit && cohort.nextFacilitatorVisit === cohort.midVisit.date
                  ? 'Mid-Point Visit On'
                  : cohort.finalVisit && cohort.nextFacilitatorVisit === cohort.finalVisit.date
                  ? 'Final Visit On'
                  : cohort.secondMidVisit &&
                    cohort.nextFacilitatorVisit === cohort.secondMidVisit.date
                  ? 'Extra Visit On'
                  : ' '}
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {cohort.nextFacilitatorVisit
                  ? moment(cohort.nextFacilitatorVisit, 'YYYY-MM-DD').format('MMMM D, YYYY')
                  : 'None Scheduled'}
              </Typography>
            ) : null}
          </div>
        )}

        {warningText ? (
          warningIsUrgent ? (
            <div
              className='classes__alert--approve-class'
              style={{ marginBottom: `${window.innerWidth < 611 ? '-13px' : '0'}` }}
            >
              {getFormattedStatus('AlertTriangle', warningText)}
            </div>
          ) : (
            <div className='classes__approval-warning'>
              {getFormattedStatus('AlertTriangle', warningText)}
            </div>
          )
        ) : null}
      </Fragment>
    )
  }

  return (
    <Box>
      <Box sx={{ px: { md: 6 }, mb: { md: 1 } }}>
        <Grid
          container
          rowGap={{ xs: 2 }}
          columnSpacing={{ sm: 3, md: '0' }}
          sx={{
            backgroundColor: variables.white,
            borderBottom: { xs: variables.borderThinGrey },
            px: { xs: 3, sm: 4 },
            py: { xs: 3, sm: 4 }
          }}
        >
          <Grid item md={4} sm={12} xs={12}>
            {_.includes(['facilitator', 'appadmin'], role) ? (
              <Typography variant='h4' sx={{ mb: 1 }}>
                {cohort.school.name}
              </Typography>
            ) : null}
            <Box sx={{ mb: 3 }}>
              {role === 'teacher' && !cohort.approved ? (
                <Typography variant='h4' className='semi-strong'>
                  {cohort.name}
                </Typography>
              ) : (
                <Link to={`/${baseLink}/${cohort.id}`} key={index}>
                  <Typography variant='h4' className='semi-strong link'>
                    {cohort.name}
                  </Typography>
                </Link>
              )}
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
              <Typography variant='h4'>
                Photography {'   '} • {'   '}
                {cohort.assignment.name}
              </Typography>
              {['appadmin', 'teacher', 'facilitator'].includes(role) ? (
                <Box sx={{ mt: 1 }} key={index}>
                  {cohort.approved ? (
                    cohort.students && cohort.students.length === 0 ? (
                      <Typography variant='h4'>0 Students in Class</Typography>
                    ) : (
                      <Link to={`/${baseLink}/${cohort.id}/student-list`}>
                        <Typography variant='h4' className='semi-strong link'>
                          {cohort.students && cohort.students.length} Students in Class
                        </Typography>
                      </Link>
                    )
                  ) : null}
                </Box>
              ) : null}
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
              {renderDateColumn(cohort, index, today)}
              {classStatuses.map(status => {
                return status
              })}
            </Box>
          </Grid>
          <Grid
            className='test-space'
            item
            md={4}
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block' }
            }}
          >
            {classStatuses.map(status => {
              return status
            })}
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block' }
            }}
          >
            {renderDateColumn(cohort, index, today)}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

// class ClassListRow extends Component {
//   renderDateColumn(cohort, index, today) {
//     const { role } = this.props
//     let warningText = ''
//     let warningIsUrgent = false

//     if (!cohort.allVisitsScheduled) {
//       if (['appadmin', 'teacher', 'facilitator'].includes(role)) {
//         warningText = 'Schedule Class Visits'
//       }
//       warningIsUrgent = role !== 'appadmin'
//     }

//     return (
//       <Fragment>
//         {cohort.startDate && cohort.endDate ? (
//           <Typography variant='h4'>
//             {moment(cohort.startDate, 'YYYY-MM-DD').format('MMMM D, YYYY')} —{' '}
//             {moment(cohort.endDate, 'YYYY-MM-DD').format('MMMM D, YYYY')}
//           </Typography>
//         ) : (
//           <Typography
//             variant='h4'
//             style={{ marginBottom: `${window.innerWidth < 611 ? '-5px' : '0'}` }}
//           >
//             No Dates Added for This Class
//           </Typography>
//         )}

//         {cohort.startDate && cohort.endDate ? (
//           <div className='classes__days-remaining' key={index}>
//             {moment(cohort.startDate).isAfter(today, 'day') ? (
//               <Typography variant='h5'>
//                 {moment(cohort.startDate, 'YYYY-MM-DD').fromNow(true)} Until Start Date
//               </Typography>
//             ) : moment(cohort.endDate, 'YYYY-MM-DD').isAfter(today, 'day') ? (
//               <Typography variant='h5'>
//                 {moment(cohort.endDate, 'YYYY-MM-DD').fromNow(true)} Until End Date
//               </Typography>
//             ) : (
//               <Typography variant='h5'>Class Ended</Typography>
//             )}
//           </div>
//         ) : null}
//         {this.props.role === 'curator' ? null : (
//           <div className='classes__next-visit' key={index}>
//             {cohort.nextFacilitatorVisit ? (
//               <Typography
//                 variant='h4'
//                 sx={{
//                   display: { xs: 'none', sm: 'none', md: 'block' }
//                 }}
//               >
//                 <Calendar size={17} color={'#505050'} line-height={20} /> Next Visit:{' '}
//                 {cohort.introVisit && cohort.nextFacilitatorVisit === cohort.introVisit.date
//                   ? 'Introduction Visit On'
//                   : cohort.midVisit && cohort.nextFacilitatorVisit === cohort.midVisit.date
//                   ? 'Mid-Point Visit On'
//                   : cohort.finalVisit && cohort.nextFacilitatorVisit === cohort.finalVisit.date
//                   ? 'Final Visit On'
//                   : cohort.secondMidVisit &&
//                     cohort.nextFacilitatorVisit === cohort.secondMidVisit.date
//                   ? 'Extra Visit On'
//                   : ' '}
//                 <br />
//                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//                 {cohort.nextFacilitatorVisit
//                   ? moment(cohort.nextFacilitatorVisit, 'YYYY-MM-DD').format('MMMM D, YYYY')
//                   : 'None Scheduled'}
//               </Typography>
//             ) : null}
//           </div>
//         )}

//         {warningText ? (
//           warningIsUrgent ? (
//             <div
//               className='classes__alert--approve-class'
//               style={{ marginBottom: `${window.innerWidth < 611 ? '-13px' : '0'}` }}
//             >
//               {getFormattedStatus('AlertTriangle', warningText)}
//             </div>
//           ) : (
//             <div className='classes__approval-warning'>
//               {getFormattedStatus('AlertTriangle', warningText)}
//             </div>
//           )
//         ) : null}
//       </Fragment>
//     )
//   }

//   render() {
//     const today = moment()
//     const { cohort, index } = this.props
//     const { role } = this.props.userStore
//     const baseLink = this.props.role === 'curator' ? 'class-review' : 'class-detail'
//     const classStatuses = getStatusesForPage('CLASSLIST', cohort, this.props.userStore)
//     return (
//       <Box>
//         <Box
//         sx={{ px: { md: 6 }, mb: { md: 1 } }}
//       >
//         <Grid
//           container
//           rowGap={{ xs: 2 }}
//           columnSpacing={{sm: 3, md : '0'}}
//           sx={{ backgroundColor: variables.white, borderBottom: { xs: variables.borderThinGrey }, px: { xs: 3, sm: 4 }, py: { xs: 3, sm: 4 } }}
//         >
//             <Grid item md={4} sm={12} xs={12}>
//               {_.includes(['facilitator', 'appadmin'], role) ? (
//                 <Typography variant='h4' sx={{ mb: 1 }}>
//                   {cohort.school.name}
//                 </Typography>
//               ) : null}
//               <Box sx={{ mb: 3 }}>
//                 {role === 'teacher' && !cohort.approved ? (
//                   <Typography variant='h4' className='semi-strong'>
//                     {cohort.name}
//                   </Typography>
//                 ) : (
//                   <Link to={`/${baseLink}/${cohort.id}`} key={index}>
//                     <Typography variant='h4' className='semi-strong link'>
//                       {cohort.name}
//                     </Typography>
//                   </Link>
//                 )}
//               </Box>
//               <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
//                 <Typography variant='h4'>
//                   Photography {'   '} • {'   '}
//                   {cohort.assignment.name}
//                 </Typography>
//                 {['appadmin', 'teacher', 'facilitator'].includes(role) ? (
//                   <Box sx={{ mt: 1 }} key={index}>
//                     {cohort.approved ? (
//                       cohort.students && cohort.students.length === 0 ? (
//                         <Typography variant='h4'>0 Students in Class</Typography>
//                       ) : (
//                         <Link to={`/${baseLink}/${cohort.id}/students`}>
//                           <Typography variant='h4' className='semi-strong link'>
//                             {cohort.students && cohort.students.length} Students in Class
//                           </Typography>
//                         </Link>
//                       )
//                     ) : null}
//                   </Box>
//                 ) : null}
//               </Box>
//               <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
//                 {this.renderDateColumn(cohort, index, today)}
//                 {classStatuses.map(status => {
//                   return status
//                 })}
//               </Box>
//             </Grid>
//             <Grid
//               className='test-space'
//               item
//               md={4}
//               sx={{
//                 display: { xs: 'none', sm: 'none', md: 'block' },
//               }}
//             >
//               {classStatuses.map(status => {
//                 return status
//               })}
//             </Grid>
//             <Grid
//               item
//               md={4}
//               sx={{
//                 display: { xs: 'none', sm: 'none', md: 'block' }
//               }}
//             >
//               {this.renderDateColumn(cohort, index, today)}
//             </Grid>
//           </Grid>
//         </Box>
//       </Box>
//     )
//   }
// }

export default inject('userStore')(observer(ClassListRow))
