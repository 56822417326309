import React, { useEffect, useState } from 'react'
import './myaccount.scss'
import { Box, Typography } from '@mui/material'
import Hero from '../ui/organisms/Hero.js'
import AccountDetails from '../ui/organisms/AccountDetails.js'
import ProjectsList from '../ui/organisms/ProjectsList.js'
import ClassList from '../ui/organisms/ClassList.js'
import ReviewList from '../ui/organisms/ReviewList.js'
import Loading from '../ui/elements/Loading.js'
import Layout from '../ui/template/Layout'
import { withApollo } from '@apollo/client/react/hoc'
import { USER_DETAILS_QUERY, USER_DETAILS_WITHOUT_COHORTS_QUERY } from '../queries/userDetailsQueries'
import ToastNotification from '../ui/elements/ToastNotification'
import { withRouter } from '../utils/customHoc'
import { inject, observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

const UserDetails = props => {
  const { userId, currentTab: initialTab } = useParams()
  const [currentTab, setCurrentTab] = useState()
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')
  const [toastType, setToastType] = useState('')
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)

  useEffect(() => {
    setCurrentTab(initialTab || 'account-details')
  }, [initialTab])

  const { refetch } = useQuery(USER_DETAILS_WITHOUT_COHORTS_QUERY, {
    variables: { id: userId },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      const userDetails = data.user
      props.breadcrumbStore.addBreadcrumb({
        pageName: `${userDetails.firstName} ${userDetails.lastName}`,
        link: props?.location.pathname,
        basePath: props?.basePath,
        isParent: false,
        isActive: true
      })
      setLoading(false)
      setUser(userDetails)
    }
  })

  const showToastNotification = (toastType, message = '') => {
    setShowToast(true)
    setToastMessage(message)
    setToastType(toastType)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowToast(false)
  }

  let defaultTabs = [
    { eventKey: 'account-details', title: 'Account Details', path: `/user-details/${userId}` }
  ]
  const profile = user && user.profile
  const role = profile && profile.__typename
  const tabs =
    role === 'Curator'
      ? defaultTabs.concat([
          { eventKey: 'reviews', title: 'Reviews', path: `/user-details/${userId}/reviews` }
        ])
      : role === 'Teacher' || role === 'Facilitator'
      ? defaultTabs.concat([
          { eventKey: 'classes', title: 'Classes', path: `/user-details/${userId}/classes` }
        ])
      : role === 'Student'
      ? defaultTabs.concat([
          { eventKey: 'projects', title: 'Projects', path: `/user-details/${userId}/projects` }
        ])
      : defaultTabs

  return (
    <Layout>
      <Box>
        {loading ? (
          <Loading />
        ) : user ? (
          <Box>
            {showToast && (
              <ToastNotification
                notificationType={toastType}
                showToast={showToast}
                onClose={handleClose}
                toastMessage={toastMessage}
              />
            )}
            <Hero
              heroTitle={`${user.firstName} ${user.lastName}`}
              currentTab={currentTab}
              tabChangeHandler={setCurrentTab}
              tabs={tabs}
            />
            {currentTab === 'account-details' && (
              <AccountDetails
                user={user}
                userId={userId}
                showToast={showToastNotification}
                fetchUserDetails={refetch}
              />
            )}
            {currentTab === 'reviews' && (
              <Box sx={{ mt: { xs: 4, md: 6, lg: 10 } }}>
                <Typography variant='h1' className='bold' sx={{ px: { xs: 4, md: 6 } }}>
                  User Reviews ({profile.cohorts.length})
                </Typography>
                <Box sx={{ mt: 5 }}>
                  <ReviewList
                    profileId={profile.id}
                    cohorts={profile.cohorts}
                    role={role}
                    showPagination={false}
                  />
                </Box>
              </Box>
            )}
            {currentTab === 'classes' && (
              <Box sx={{ mt: { xs: 4, md: 6, lg: 10 } }}>
                <Typography variant='h1' className='bold' sx={{ px: { xs: 4, md: 6 } }}>
                  User Classes ({profile.cohorts.length})
                </Typography>
                <Box sx={{ mt: 5 }}>
                  <ClassList id={userId} entity='user' userRole={user.role} showPagination={false} />
                </Box>
              </Box>
            )}
            {currentTab === 'projects' && (
              <Box sx={{ mt: { xs: 4, md: 6, lg: 10 } }}>
                <Typography variant='h1' className='bold' sx={{ px: { xs: 4, md: 6 } }}>
                  User Projects ({profile.projects.length})
                </Typography>
                <ProjectsList
                  user={user}
                  projects={profile.projects}
                  assignment={profile.assignment}
                  tabChangeHandler={setCurrentTab}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Typography variant='paragraph' component='p'>
            Error loading classes
          </Typography>
        )}
      </Box>
    </Layout>
  )
}

export default inject('breadcrumbStore')(observer(withRouter(withApollo(UserDetails))))
