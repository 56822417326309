import React from 'react'
import { css } from '@emotion/react'
// First way to import
import { PulseLoader } from 'react-spinners'

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  border-color: #8e5ba6;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const review = css`
  border-color: #8e5ba6;
  margin: 20px 40vw;
  transform: rotateZ(90deg);
`

class Loading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  render() {
    return (
      <div className={this.props.inContainer ? '': 'pulse-loading'}>
        <PulseLoader
          css={this.props.inContainer ? review : override}
          size={15}
          color={'#8e5ba6'}
          loading={this.state.loading}
        />
      </div>
    )
  }
}

export default Loading
