import React, { Fragment, useState } from 'react'
import './studentlist.scss'
import { withApollo } from '@apollo/client/react/hoc'
import { observer, inject } from 'mobx-react'
import SectionHeadingAndIntro from '../molecules/SectionHeadingAndIntro'
import NewClassReviewGallery from '../molecules/NewClassReviewGallery'
import ClassReviewSubTabs from '../molecules/ClassReviewSubTabs'
import { withRouter } from '../../utils/customHoc'
import { Box } from '@mui/material'
import DateUtils from '../../utils/DateUtils'
import { isPublishSectionEnable } from '../../utils/commonFunctions'

const ReviewDetails = props => {
  const { backToPageStore, location, userStore } = props
  const { role } = userStore

  const [currentSubTab, setCurrentSubTab] = useState(
    location.pathname === backToPageStore.activePage && backToPageStore.activePageFilter
      ? backToPageStore.activePageFilter
      : 'all'
  )
  const [cohort] = useState(props.cohort)
  const [refetchCount, setRefetchCount] = useState(false)

  const awardMap = {
    all: 'All',
    aoe: 'Award of Excellence',
    honorable: 'Honorable Mention',
    none: 'None'
  }

  let type =
    currentSubTab === 'all'
      ? 'all'
      : currentSubTab === 'honorable-mention'
      ? 'honorable'
      : currentSubTab === 'award-of-excellence'
      ? 'aoe'
      : 'none'

  const getIntroText = () => {
    const isClassEnded = DateUtils.isDatePassed(cohort.endDate)
    if (role === 'appadmin') {
      if (isClassEnded && cohort.isReviewSend && !cohort.reviewed) {
        return 'This class is in curatorial review. Curators can add awards and comments to photographs.'
      } else if (cohort.reviewed && !cohort.summarySent) {
        return 'Please complete the Model Release review process by identifying which awarded photographs contain recognizable people.'
      } else if (cohort.summarySent) {
        return isPublishSectionEnable ? 'Please review Use Agreements and/or Model Releases on the awarded photographs in this class and publish photographs as applicable.' : 'Please review Use Agreements and/or Model Releases on the awarded photographs in this class.'
      } else {
        return 'The submission date for this class has passed. Please check that enough students submitted, then send this review to curators in the Review Status tab.'
      }
    } else {
      if (cohort.isReviewSend && !cohort.reviewed) {
        return 'Please add your awards to the photographs submitted in this class. When you have finalized your awards, please submit your curatorial review in the Review Status tab.'
      } else if (cohort.reviewed) {
        return 'You have submitted your curatorial review for this class. You can still see review information but can no longer change your awards.'
      }
    }
  }

  const handleFilterChange = filterName => {
    setCurrentSubTab(filterName)
    backToPageStore.setActivePageFilter(filterName)
  }

  if (cohort) {
    return (
      <Fragment>
        <Box sx={{ px: { xs: 4, md: 6 }, pt: { xs: 5, md: 6, lg: 10 }, pb: { xs: 5, md: 6 } }}>
          <SectionHeadingAndIntro heading='Submitted Photographs' introText={getIntroText()} />
        </Box>
        {role === 'appadmin' || role === 'curator' ? (
          <ClassReviewSubTabs
            handelTabChange={tabKey => handleFilterChange(tabKey)}
            currentTab={currentSubTab}
            cohortId={cohort.id}
            refetchCount={refetchCount}
            setRefetchCount={setRefetchCount}
          />
        ) : null}
        <Box className='body__review-details--award-details' sx={{ p: 0, m: 0 }}>
          <NewClassReviewGallery
            role={role}
            type={currentSubTab}
            cohortId={cohort.id}
            awardClass={awardMap[type]}
            currentUserStore={userStore}
            refetchReviewCounts={() => {
              setRefetchCount(true)
            }}
          />
        </Box>
      </Fragment>
    )
  }
}

export default inject('userStore', 'backToPageStore')(
  observer(withApollo(withRouter(ReviewDetails)))
)
