import React, { useState, useEffect } from 'react'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import UserListRowCurators from '../molecules/UserListRowCurators.js'
import InviteStudentModal from '../molecules/InviteStudentModal.js'
import SendMessageModal from '../molecules/SendMessageModal.js'
import { observer, inject } from 'mobx-react'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import Loading from '../../ui/elements/Loading.js'
import { Box, Grid, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc.js'
import CustomPagination from '../molecules/CustomPagination.js'
import { useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

const GET_CURATORS_QUERY = gql`
  query getCurators ($batchSize: Int!, $baseBatchSize: Int!, $step: Int!) {
    curatorsConnection(batchSize: $batchSize, baseBatchSize: $baseBatchSize, step: $step) {
      id
      userId
      name
      lastName
      lastSeenAt
      bio
      bioNeedsApproval
      lastSeenAt
      createdAt
      cohorts {
        id
      }
    }
    curatorCount
  }
`
const BATCH_SIZE = 10

const UserListCurators = (props) => {
  const {location} = props
  const [checkedReminder, setCheckedReminder] = useState(false)
  const [checkedBoxes, setNewCheckedBoxes] = useState({})
  const [modalShow] = useState(false)
  const [modalSendMessage, setModalSendMessage] = useState(false)
  const [curatorCount, setCuratorCount] = useState(0)
  const [step, setStep] = useState(0)
  const [batchSize] = useState(BATCH_SIZE)
  const [baseBatchSize] = useState(BATCH_SIZE)
  const [totalPages, setTotalPages] = useState(0)
  const [curators, setCurators] = useState([])
  const navigate = useNavigate()

  const { loading } = useQuery(GET_CURATORS_QUERY,
    {variables: {
      step: step,
      batchSize: batchSize,
      baseBatchSize: baseBatchSize
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      setCurators( data)
      setCuratorCount( data.curatorCount)
      setTotalPages(Math.ceil(data.curatorCount / batchSize))
      setCheckedBoxes(data.curatorsConnection)
    }
  })

  const checkAllCheckedBoxes = () => {
    const listCurators = curators.curatorsConnection ? curators.curatorsConnection : []
    const newState = Object.assign({}, checkedBoxes)
    const isSelected = !checkedReminder ? true : false
    listCurators.forEach(curator => (
      newState[curator.id] = isSelected
    ))
    setNewCheckedBoxes(newState)
    setCheckedReminder(isSelected)
  }

  const setCheckedBoxes = (curators) => {
    const checkedBoxes = {}
    curators.forEach(curators => (checkedBoxes[curators.id] = false))
    setNewCheckedBoxes(checkedBoxes)
  }

  const updatedCheckedBoxes = curatorsId => {
    const newState = Object.assign({}, checkedBoxes)
    newState[curatorsId] = newState[curatorsId] ? false : true
    setNewCheckedBoxes(newState)
    setCheckedReminder(Object.values(newState).includes(false) ? false : true)
  }

  useEffect(() => {
    setStep(isNaN(parseInt(props?.params?.page)) ? 0 : (parseInt(props?.params?.page) - 1))
  }, [props?.params])

  const handlePageChange = page => {
    setCheckedReminder(false)
    // Update the URL
    const pathParts = location.pathname.split('/').filter(Boolean)
    if (!isNaN(pathParts[pathParts.length - 1])) {
      pathParts.pop()
    }
    const newPath = `/${pathParts.join('/')}/${page + 1}`
    navigate(newPath)
  }

  const modalClose = () => { setModalSendMessage(false) }

  const listCurators = curators.curatorsConnection ? curators.curatorsConnection : []
  const selectedCurators = listCurators.filter(curator => checkedBoxes[curator.id]).map(curator => curator.userId)
  return(
      <>
        <Box sx={{ pb: { xs: 4 } }}>
          <Box sx={{ px: { xs: 4, md: 0 } }}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <Typography variant='h1' className='bold'>
                  Curators ({curatorCount})
                </Typography>
              </Grid>
              <Grid
                item
                md={6}
                sm={12}
                xs={12}
                sx={{
                  textAlign: {
                    xs: 'left',
                    sm: 'left',
                    md: 'right'
                  }
                }}
              >
                <Grid
                  container
                  spacing={{
                    xs: 3,
                    sm: 3,
                    md: 3
                  }}
                  columnSpacing={1}
                  alignItems='center'
                >
                  <Grid item md={12} sm={12} xs={12}>
                    <Button
                      variant='contained'
                      title='Add a Curator'
                      onClick={() => navigate('/create-user/curator')}
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: 'fit-content'
                        }
                      }}
                    />
                    <Button
                      variant='outlined'
                      title='Message Selected People'
                      disabled={selectedCurators.length < 1}
                      onClick={() => setModalSendMessage(true)}
                      sx={{
                        ml: 2,
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: 'fit-content'
                        },
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'inline-flex'
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={6}
                    xs={12}
                    sx={{
                      display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none'
                      }
                    }}
                  >
                    <Button
                      variant='outlined'
                      title='Message Selected People'
                      disabled={selectedCurators.length < 1}
                      onClick={() => setModalSendMessage(true)}
                      sx={{
                        width: {
                          xs: '100%',
                          sm: '100%',
                          md: 'fit-content'
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <>
            <SendMessageModal
              show={modalSendMessage}
              onHide={modalClose}
              userIds={selectedCurators}
            />
            <InviteStudentModal
              show={modalShow}
              onHide={modalClose}
              classUuid={props.classUuid}
            />
          </>
          <Box
            sx={{
              mt: { xs: 5, md: 4, lg: 5 },
              mb: { xs: '1.125rem', md: 2 },
              px: { xs: 3, md: 4 }
            }}
          >
            <Grid container alignItems='center'>
              <Grid item md={1} sm={1} xs={1}>
                <Box sx={{ textAlign: 'center' }}>
                  <FormCheckbox
                    checked={checkedReminder}
                    onChange={checkAllCheckedBoxes}
                    value='checkedReminder'
                    color='primary'
                  />
                </Box>
              </Grid>
              <Grid
                item
                md={5}
                sm={6}
                xs={9}
                sx={{
                  display: {
                    xs: 'none',
                    lg: 'block'
                  }
                }}
              >
                <Typography variant='h4' className='table_header'>
                  User Details
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                sm={5}
                xs={0}
                sx={{
                  display: {
                    xs: 'none',
                    lg: 'block'
                  }
                }}
              >
                <Typography variant='h4' className='table_header'>
                  Classes
                </Typography>
              </Grid>
              <Grid
                item
                md={3}
                sm={0}
                xs={0}
                sx={{
                  display: {
                    xs: 'none',
                    lg: 'block'
                  }
                }}
              >
                <Typography variant='h4' className='table_header'>
                  Activity
                </Typography>
              </Grid>
              <Grid
                item
                md={11}
                sm={11}
                xs={11}
                sx={{
                  display: {
                    xs: 'block',
                    lg: 'none'
                  }
                }}
              >
                <Typography variant='h4' className='table_header'>
                  Select All Rows
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
          { loading ? (
              <Box className='reivew-list-loader--wrapper'>
                <Loading />
              </Box>
            ) : null}
            { listCurators.map(curator => (
                <UserListRowCurators
                  key={curator.id}
                  lastSeenAt={curator.lastSeenAt}
                  curator={curator}
                  checked={checkedBoxes[curator.id] || false}
                  onChecked={() => updatedCheckedBoxes(curator.id)}
                  userId={curator.userId}
                />
              ))
            }
          </Box>
          <Box sx={{ mt: { xs: 4 } }}>
            <CustomPagination totalPages={totalPages} pageNum={props?.params?.page ? parseInt(props?.params?.page) : 1} onChange={handlePageChange}/>
          </Box>
        </Box>
      </>
    )
}

export default inject('userCuratorsStore')(observer(withRouter(withApollo(UserListCurators))))
