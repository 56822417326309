import React from 'react';
import FormField from './FormField';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import variables from '../settings/_variables.scss'
import { Search } from 'react-feather';


const ListSearchBar = ({ id, placeHolder, value, onChange, style, formControlStyle }) => {
  return (
    <FormField
      formFieldId={id}
      formFieldVariant='filled'
      formFieldPlaceholder={placeHolder}
      formFieldValue={value}
      formFieldType='search'
      formFieldName='search'
      formInputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <Search size={20}/>
          </InputAdornment>
        ),
        sx: {
          backgroundColor: `${variables.white}`,
          '&:hover': {
            backgroundColor: `${variables.white}`
          },
          '&:after': {
            border: 'inherit'
          }
        }
      }}
      style={{
        ...style,
        '& .MuiInputBase-input': {
          paddingTop: 1.5,
          paddingBottom: 1.5
        }
      }}
      onChange={onChange}
      formControlStyle={{ ...formControlStyle }}
    />
  )
}

export default ListSearchBar
