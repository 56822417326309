import { gql } from '@apollo/client'
import { Mutation, Query } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { withStyles } from '@material-ui/core/styles'
import { Tab, Tabs, Typography } from '@mui/material'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { validationText } from '../content/validationText.js'
import Button from '../ui/elements/Button.js'
import FormField from '../ui/elements/FormField.js'
import FormSelect from '../ui/elements/FormSelect'
import Loading from '../ui/elements/Loading.js'
import ToastNotification from '../ui/elements/ToastNotification'
import Hero from '../ui/organisms/Hero.js'
import Layout from '../ui/template/Layout'
import Validations from '../utils/Validations'
import { withRouter } from '../utils/customHoc'
import './createclass.scss'
import { Send } from 'react-feather'

const MUTATION = gql`
  mutation CreateUserInvite(
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: String!
    $schoolId: ID
  ) {
    createUserInvite(
      firstName: $firstName
      lastName: $lastName
      email: $email
      schoolId: $schoolId
      role: $role
    ) {
      uuid
    }
  }
`
const CheckUserEmailExist = gql`
  query CheckUserEmailExist($email: String!) {
    checkUserEmailExist(email: $email)
  }
`

function TabContainer(props) {
  return (
    <Typography component='div' style={{ padding: '24px 24px 32px' }}>
      {props.children}
    </Typography>
  )
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  tabsRoot: {
    borderBottom: '1px solid #D5D5D7'
  },
  tabsIndicator: {
    backgroundColor: '#0076BB'
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 100,
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 0,
    fontFamily: [
      'Calibre',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:hover': {
      color: '#000',
      opacity: 1
    },
    '&$tabSelected': {
      color: '#000',
      fontWeight: theme.typography.fontWeightBold,
      marginRight: 0
    },
    '&:focus': {
      color: '#000'
    }
  },
  tabSelected: {},
  typography: {
    padding: 0
  }
})

class CreateUser extends Component {
  constructor(props) {
    super(props)
    const { breadcrumbStore, location, params, basePath } = props
    const { role } = params
    this.state = {
      value: 'one',
      errors: { email: null },
      showToast: false
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSchoolChange = this.handleSchoolChange.bind(this)
    breadcrumbStore.addBreadcrumb({
      pageName: this.getAddUserRole(),
      link: location.pathname,
      basePath: basePath,
      isParent: false,
      isActive: true
    })
  }

  showToast = (toastType, message) => {
    const { navigate } = this.props
    this.setState({
      showToast: true,
      toastMessage: message,
      toastType: toastType
    })
    setTimeout(() => {
      this.setState({ showToast: false })
      const redirectURL = `/users/${this.props.params.role === 'appadmin' ? 'admin' : this.props.params.role}s` 
      return toastType === 'success' ? navigate(redirectURL) : ''
    }, 3000)
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ showToast: false })
  }

  handleChange = (event, value) => {
    this.setState({ value })
  }

  handleSchoolChange(choice) {
    this.setState({
      schoolId: choice.target.value
    })
  }
  handleSubmit(e, onMutate) {
    e.preventDefault()
    this.setState({ submitted: true })
    const { params } = this.props
    if (!this.state.submitted) {
      onMutate({
        variables: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          role: params.role,
          schoolId: this.state.schoolId
        }
      })
    }
  }
  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value })
    if (e.target.name === 'email') {
      const emailErrors = this.validateEmail(e.target.value)
    }
  }

  fetchEmailExist = async testString => {
    const data = await this.props.client.query({
      query: CheckUserEmailExist,
      variables: {
        email: testString
      },
      fetchPolicy: 'network-only'
    })
    return data
  }

  validateEmail = async emailTextString => {
    const validEmail = Validations.isValidEmail(emailTextString && emailTextString.toLowerCase())
    if (validEmail) {
      const checkEmailExist = await this.fetchEmailExist(emailTextString)
      this.setState({
        errors: {
          email: checkEmailExist.data.checkUserEmailExist
            ? validationText.user.error.emailExist
            : null
        }
      })
    } else {
      this.setState({ errors: { email: validationText.user.error.email } })
    }
  }

  getAddUserRole = () => {
    const { params } = this.props
    const { role } = params
    const heroTitle =
      'Add New ' + role === 'facilitator'
        ? 'Teaching Artist'
        : role === 'appadmin'
        ? 'Add New Administrator'
        : role === 'facilitator'
        ? 'Add New Teaching Artist'
        : 'Add New ' + role.charAt(0).toUpperCase() + role.slice(1)
    return heroTitle
  }

  render() {
    const { classes, params } = this.props
    const { role } = params
    const { value } = this.state
    const { handleSubmit, handleFormChange } = this
    const heroTitle = this.getAddUserRole()
    const NOTE = 'The user will receive an email with a link to complete the signup process. Please remind the user to check their inbox and create their account.'

    return (
      <Layout>
        <Mutation
          mutation={MUTATION}
          onCompleted={() => {
            this.showToast('success', 'User invitation send successfully.')
          }}
          onError={errors => {
            this.setState({ submitted: false, errors: { email: errors.message } })
          }}
        >
          {(onMutate, { data, errors }) => {
            return (
              <Fragment>
                {this.state.showToast === true ? (
                  <ToastNotification
                    notificationType={this.state.toastType}
                    showToast={this.state.showToast}
                    onClose={this.handleClose}
                    toastMessage={this.state.toastMessage}
                  />
                ) : null}
                <Hero heroTitle={heroTitle} />
                <div className='container__body'>
                  <div className='container__create-class'>
                    <div className={classes.root}>
                      {_.includes(['teacher'], role) ? (
                        <Tabs
                          value={value}
                          onChange={this.handleChange}
                          classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                          variant='fullWidth'
                        >
                          <Tab
                            disableRipple
                            value='one'
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label='User Details'
                          />
                          <Tab
                            disableRipple
                            value='two'
                            classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                            label='School'
                            disabled={
                              this.state.firstName && this.state.lastName && this.state.email
                                ? null
                                : 'true'
                            }
                          />
                        </Tabs>
                      ) : _.includes(['facilitator', 'appadmin', 'curator'], role) ? (
                        <Tabs value={value} onChange={this.handleChange} variant='fullWidth'>
                          <Tab
                            disableRipple
                            value='one'
                            label='User Details'
                            disabled={
                              this.state.firstName && this.state.lastName && this.state.email
                                ? null
                                : 'true'
                            }
                          />
                        </Tabs>
                      ) : null}

                      {value === 'one' && (
                        <TabContainer>
                          <div className='create-class__tab-header'>
                            <Typography variant='h2' component='h2'>
                              Enter the user's details.
                            </Typography>
                            <br />
                            <Typography
                              variant='paragraph'
                              sx={{
                                mb: '1rem'
                              }}
                              component='p'
                            >
                              The user will have the option to update their name and email address
                              when they sign up.
                            </Typography>
                          </div>
                          <div className='create-class__tab-form'>
                            <form>
                              <FormField
                                formFieldVariant='filled'
                                formFieldId='custom-css-outlined-class-name'
                                formFieldLabel='First Name'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldType='class-name'
                                formFieldName='firstName'
                                onChange={handleFormChange}
                                formFieldValue={this.state.firstName}
                              />
                              <br />
                              <FormField
                                formFieldVariant='filled'
                                formFieldId='custom-css-outlined-class-name'
                                formFieldLabel='Last Name'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldType='class-name'
                                formFieldName='lastName'
                                onChange={handleFormChange}
                                formFieldValue={this.state.lastName}
                              />
                              <br />
                              <FormField
                                formFieldVariant='filled'
                                formFieldId='custom-css-outlined-class-name'
                                formFieldLabel='Email'
                                formFieldClasses={{
                                  root: 'text-field__outline'
                                }}
                                formFieldType='email'
                                formFieldName='email'
                                onChange={handleFormChange}
                                formFieldError={this.state.errors.email}
                                formFieldValue={this.state.email}
                              />
                              <br />
                              <br />
                              <br />
                              <Button
                                variant={role !== 'teacher' ? 'contained' : 'outlined'}
                                type='submit'
                                title={ role === 'teacher' ? 'Continue' :
                                  <span className='icon-text-span'>
                                    <Send size={18} /> Send Signup Invite
                                  </span>
                                }
                                onClick={e => {
                                  if (
                                    this.state.firstName &&
                                    this.state.lastName &&
                                    this.state.email &&
                                    this.state.errors.email === null
                                  ) {
                                    role === 'teacher'
                                      ? this.setState({ value: 'two' })
                                      : handleSubmit(e, onMutate)
                                  }
                                }}
                                sx={{
                                  width: {
                                    xs: '100%',
                                    sm: 'fit-content'
                                  }
                                }}
                                disabled={
                                  this.state.firstName &&
                                  this.state.firstName.trim() &&
                                  this.state.lastName &&
                                  this.state.lastName.trim() &&
                                  this.state.email &&
                                  this.state.errors.email === null
                                    ? null
                                    : 'true'
                                }
                              />
                              {role !== 'teacher' && (<Typography mt={2} variant='note' component={'p'}>{NOTE}</Typography>)}
                            </form>
                          </div>
                        </TabContainer>
                      )}
                      {value === 'two' && (
                        <Query
                          query={gql`
                            query SchoolList {
                              schools {
                                id
                                name
                                teachers {
                                  name
                                  id
                                }
                              }
                            }
                          `}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return <Loading />
                            if (error) {
                              return (
                                <Typography variant='paragraph' component='p'>
                                  Error loading classes
                                </Typography>
                              )
                            }
                            if (data) {
                              const options = data.schools.map(school => ({
                                label: school.name,
                                value: school.id,
                                teachers: school.teachers.map(teacher => ({
                                  label: teacher.name,
                                  value: teacher.id
                                }))
                              }))

                              return (
                                <TabContainer>
                                  <div className='create-class__tab-header'>
                                    <Typography variant='h2' component='h2'>
                                      Select the user's school.
                                    </Typography>
                                    <br />
                                    <Typography
                                      variant='paragraph'
                                      sx={{
                                        mb: '1rem'
                                      }}
                                      component='p'
                                    >
                                      Start typing a school name to select the user's school.
                                    </Typography>
                                  </div>
                                  <div className='create-class__tab-form'>
                                    <form>
                                      <FormSelect
                                        options={options}
                                        label='School Name'
                                        placeholder='School Name'
                                        onChange={this.handleSchoolChange}
                                        name='schoolName'
                                        variant='filled'
                                        value={this.state.schoolId ? this.state.schoolId : null}
                                        defaultValue={
                                          this.state.schoolId ? this.state.schoolId : null
                                        }
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        fullWidth={true}
                                      />
                                      <br />
                                      <Button
                                        variant='contained'
                                        type='submit'
                                        title={
                                          <span className='icon-text-span'>
                                            <Send size={18} /> Send Signup Invite
                                          </span>
                                        }
                                        onClick={e => {
                                          handleSubmit(e, onMutate)
                                        }}
                                        disabled={
                                          this.state.schoolId &&
                                          this.state.firstName &&
                                          this.state.firstName.trim() &&
                                          this.state.lastName &&
                                          this.state.lastName.trim() &&
                                          this.state.email
                                            ? null
                                            : 'true'
                                        }
                                        sx={{
                                          width: {
                                            xs: '100%',
                                            sm: 'fit-content'
                                          }
                                        }}
                                      />
                                      <Typography mt={2} variant='note' component={'p'}>{NOTE}</Typography>
                                    </form>
                                  </div>
                                </TabContainer>
                              )
                            }
                          }}
                        </Query>
                      )}
                    </div>
                  </div>
                  <div className='create-class__cancel'>
                    <Link to='/users'>Cancel</Link>
                  </div>
                </div>
              </Fragment>
            )
          }}
        </Mutation>
      </Layout>
    )
  }
}

CreateUser.propTypes = {
  classes: PropTypes.object.isRequired
}

export default inject('userStore', 'breadcrumbStore')(
  withApollo(withRouter(withStyles(styles)(observer(CreateUser))))
)
