import statesList from './USAStates'
import libphonenumber from 'google-libphonenumber'

class Validations {
  isValidString = str => {
    return str && (typeof str === 'string' || str instanceof String)
  }

  isValidEmail = emailString => {
    if (!this.isValidString(emailString) || emailString.length < 6) return false
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return emailRegex.test(emailString)
  }

  isValidState = stateString => {
    return this.isValidString(stateString) && this.stateInStateList(stateString)
  }

  stateInStateList = stateString => {
    return (
      this.isValidString(stateString) &&
      statesList.filter(stateObj => stateObj.value === stateString).length === 1
    )
  }

  isValidPhoneNumber = phoneNumberString => {
    if (
      !this.isValidString(phoneNumberString) ||
      phoneNumberString.length !== 10 ||
      !phoneNumberString.match(/^\d+$/)
    )
      return
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
    const number = phoneUtil.parse(phoneNumberString, 'US')
    return phoneUtil.isValidNumber(number)
  }

  isValidAddress = addressString => {
    return this.isValidString(addressString) && addressString.length > 0
  }

  isValidCity = cityString => {
    return (
      this.isValidString(cityString) &&
      cityString.split().filter(char => /\d+/.test(char)).length === 0
    )
  }

  isValidZip = zipCodeString => {
    return this.isValidString(zipCodeString) && Array.isArray(zipCodeString.match(/^\d{5}$/))
  }
}

export default new Validations()
