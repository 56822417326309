import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { withRouter } from '../../utils/customHoc'

const ScrollToTop = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    // window.scrollTo(0, 0);
  }, [location])

  return <>{children}</>
}

// class ScrollToTop extends React.Component{
//     componentWillReceiveProps(nextProps, prevProps){
//         // window.scrollTo(0, 0);
//     }
//     render(){
//         return <>
//             {this.props.children}
//         </>
//     }
// }
export default withRouter(ScrollToTop)
