import { gql } from '@apollo/client'

export const CLASS_DETAILS_QUERY = gql`
  query ClassDetails($id: ID!) {
    cohort(id: $id) {
      id
      adminNotes
      uuid
      name
      approved
      gradeLevel
      otherGradeDetails
      startDate
      startDateApproval
      endDate
      endDateApproval
      secondMidVisitRequired
      summarySent
      reviewed
      introVisit {
        id
        date
        teacherApproved
        facilitatorApproved
      }
      midVisit {
        id
        date
        teacherApproved
        facilitatorApproved
      }
      secondMidVisit {
        id
        date
        teacherApproved
        facilitatorApproved
      }
      finalVisit {
        id
        date
        teacherApproved
        facilitatorApproved
      }
      estimatedNumberOfStudents
      curators {
        name
        bio
        instagramHandle
        website
        email
        mobileNumber
        id
        userId
      }
      school {
        mailingAddress {
          state
          id
          city
        }
        name
        id
      }
      assignment {
        name
        id
      }
      teacher {
        name
        id
        email
        mobileNumber
        userId
      }
      facilitator {
        name
        id
        bio
        instagramHandle
        website
        email
        mobileNumber
        userId
      }
      projects {
        id
        submitted
        reviewed
        photos {
          id
          publishable
          project {
            id
          }
        }
        student {
          id
          dob
          name
        }
      }
      photos {
        id
        adminApproved
        educatorSummaryAt
        published
        sentStudentSummary
        studentSummaryAt
        summarySent
        awards {
          id
        }
      }
      submittedProjects {
        id
        submitted
        reviewed
        name
        submittedUpdatedAt
        student {
          id
          name
        }
        cohort {
          id
          endDate
        }
        photos {
          id
          published
          summarySent
          sentStudentSummary
          originalFileSize
          rawFileUrl
          rawFileName
          rawFileSize
          hasPeople
          title
          caption
          altText
          mrAdminNotes
          adminApproved
          covidRelated
          publishable
          readyToPublish
          totalMrRequired
          useAgreements {
            id
            linkToFile
            signed
            sentTo
            signeeFirstName
            signeeLastName
            sentVia
            uaStatus
            adminDecision
            adminRejectionNote
            adminDecisionOn
            signedon
            relatedUaUuid
            wantsUpdates
            email
            phoneNumber
            createdAt
            uuid
          }
          project {
            id
            submitted
            reviewed
            cohort {
              id
              endDate
            }
          }
          modelReleases {
            signed
            mrStatus
            adminDecision
            relatedToMr
            signedOn
            adminDecisionOn
            adminRejectionNote
          }
          exifData {
            size
            width
            height
            exposureProgram
            meteringMode
            iso
            lensModel
            focalLength
            cameraModel
            captureDate
            aperture
            shutterSpeedValue
            fileType
          }
          awards {
            id
            awardClass
            nominatorInitials
            nominatorUserId
            nominatorName
            nominatorType
            isEdited
            editedOn
          }
        }
      }
    }
  }
`
export const CLASS_PHOTO_AMOUNT_QUERY = gql`
  query cohortPhotoAmount($id: ID!) {
    cohortPhotoCount(id: $id)
  }
`

export const GET_STUDENTS_QUERY = gql`
  query ClassStudentDetails($id: ID!) {
    cohort(id: $id) {
      id
      name
      endDate
      approved
      reviewed
      summarySent
      students {
        id
        userId
        name
        dob
        email
        mobileNumber
        projects {
          id
          reviewed
          submitted
          submittedUpdatedAt
          photosCounter
          cohort {
            id
            endDate
            reviewed
            summarySent
            curatorReviews {
              id
              reviewerId
            }
          }
        }
      }
      submittedProjects {
        id
        photos {
          id
          published
        }
      }
    }
    allPhotoCount: photoCountByFilter(cohortId: $id, filterType: "all")
    finalPhotoCount: photoCountByFilter(cohortId: $id, filterType: "finals")
    classSharePhotoCount: photoCountByFilter(cohortId: $id, filterType: "classShare")
    awardedPhotoCounts: photoCountByFilter(cohortId: $id, filterType: "awarded")
  }
`

export const PRESENTATION_QUERY = gql`
  query CohortPresentationPhotos($id: ID!, $filter: String!) {
    filteredPhotosByCohortId(id: $id, filter: $filter) {
      id
      url
      title
      caption
      originalTitle
      originalCaption
      editedCaption
      classShare
      summarySent
      attribution
      submitted
      cdnOriginalUrl
      originalFileSize
      rawFileSize
      rawFileUrl
      rawFileName
      rawFileSize
      hasPeople
      mrAdminNotes
      totalMrRequired
      adminApproved
      covidRelated
      publishable
      published
      readyToPublish
      altText
      updatedAt
      project {
        id
        submitted
        reviewed
        cohort {
          id
          name
          endDate
          isReviewSend
          reviewed
          curatorReviews {
            id
            reviewerId
          }
        }
        student {
          id
          name
          mobileNumber
          email
          dob
        }
      }
      photoComments {
        body
        id
        commenterInitials
        commenterUserId
        commenterName
        createdAt
      }
      useAgreements {
        id
        linkToFile
        signed
        sentTo
        signeeFirstName
        signeeLastName
        sentVia
        uaStatus
        adminDecision
        adminRejectionNote
        adminDecisionOn
        signedon
        secondaryEmail
        secondaryPhoneNumber
        relatedUaUuid
        wantsUpdates
        email
        phoneNumber
        address1
        address2
        city
        state
        createdAt
        uuid
      }
      awards {
        id
        awardClass
        nominatorInitials
        nominatorUserId
        nominatorName
        nominatorType
      }
      exifData {
        size
        width
        height
        exposureProgram
        meteringMode
        iso
        lensModel
        focalLength
        cameraModel
        captureDate
        aperture
        shutterSpeedValue
        fileType
      }
      modelReleases {
        id
        uuid
        sentTo
        createdAt
        firstName
        lastName
        linkToFile
        signed
        sentVia
        isMinor
        isDeceased
        signeeFirstName
        signeeLastName
        mrStatus
        adminDecision
        relatedToMr
        signedOn
        adminDecisionOn
        adminRejectionNote
      }
    }
  }
`

export const UPDATE_COHORT_MUTATION = gql`
  mutation ModifyCohortData(
    $cohortId: ID!
    $name: String
    $gradeLevel: String
    $otherGradeDetails: String
    $curatorIds: [ID!]
    $facilitatorId: ID
    $startDate: String
    $endDate: String
    $introVisit: String
    $midVisit: String
    $secondMidVisit: String
    $secondMidVisitRequired: Boolean
    $finalVisit: String
    $approval: Boolean
    $noteBody: String!
  ) {
    modifyCohortData(
      cohortId: $cohortId
      name: $name
      gradeLevel: $gradeLevel
      otherGradeDetails: $otherGradeDetails
      curatorIds: $curatorIds
      facilitatorId: $facilitatorId
      startDate: $startDate
      endDate: $endDate
      introVisit: $introVisit
      midVisit: $midVisit
      secondMidVisit: $secondMidVisit
      secondMidVisitRequired: $secondMidVisitRequired
      finalVisit: $finalVisit
      noteBody: $noteBody
      approval: $approval
    ) {
      id
    }
  }
`

export const PHOTO_COUNT_BY_FILTER_QUERY = gql`
  query photoCountByFilter($cohortId: ID!, $filterType: String!) {
    photoCountByFilter(cohortId: $cohortId, filterType: $filterType)
  }
`

export const COHORT_BY_ID = gql`
  query ClassDetails($id: ID!) {
    cohort(id: $id) {
      id
      uuid
      name
      gradeLevel
      startDate
      endDate
      estimatedNumberOfStudents
      isReviewSend
      sendForReviewOn
      curatorsDueDate
      reviewed
      summarySent
      educatorSummaryAt
      isReviewSend
      createdAt
      school {
        mailingAddress {
          state
          city
          id
        }
        id
        name
      }
    }
  }
`
