import React from 'react'
import { Box, Typography, Grid } from '@mui/material'
import '../molecules/modals.scss'

export const BaseModalHeader = props => {
  return (
    <Box sx={{
      padding:{xs: 3, sm: '24px 32px'},
      borderBottom:'0.0625rem solid #d5d5d7'
    }}>
      <Grid container>
        <Typography variant='h3' className='strong' id='modal-modal-title'>
          {props?.children?.[0]?.props?.children}
        </Typography>
      </Grid>
    </Box>
  );
}