import { useMutation, useQuery } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Upload } from 'react-feather'
import { ADD_COLLECTION, REVIREW_BY_COHORT_ID } from '../../../queries/ClassReviewTabQueries'
import { STUDENT_LIST_BY_COHORT_ID } from '../../../queries/studentQueries'
import usePicsio from '../../../utils/picsioUtility'
import Button from '../../elements/Button'
import { StudentList } from './StudentList'
import { validationText } from '../../../content/validationText'
import LinearProgress from '@mui/material/LinearProgress'

export const SyncToPicsio = props => {
  const { createCollection } = usePicsio()
  const [cohort, setCohort] = useState(props.cohort)
  const [collectionIds, setCollectionIds] = useState(props.cohort.collectionId)
  const [isDisabled, setIsDisabled] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const { data: cohortData, refetch } = useQuery(REVIREW_BY_COHORT_ID, {
    variables: {
      id: props.cohort.id
    },
    onCompleted(cohortData) {
      setCohort(cohortData.cohort)
      setCollectionIds(cohortData.cohort?.picsioCollection)
    }
  })

  const { data, refetch: fetchStudentList } = useQuery(STUDENT_LIST_BY_COHORT_ID, {
    variables: {
      cohortId: cohort.id
    }
  })

  const [addCollection] = useMutation(ADD_COLLECTION, {
    onCompleted() {
      refetch()
      props?.showToast?.('success', validationText.picsio.createCollection.success)
    }
  })

  useEffect(() => {
    if (collectionIds?.photoCollectionId) {
      fetchStudentList()
    }
  }, [collectionIds?.photoCollectionId, fetchStudentList])

  useEffect(() => {
    if (
      !process.env.REACT_APP_PICSIO_RELEASE_DATE ||
      moment(moment(props.cohort.endDate).format('YYYY-MM-DD')).diff(
        process.env.REACT_APP_PICSIO_RELEASE_DATE
      ) > 0
    ) {
      setIsAvailable(true)
    }
  }, [props.cohort])

  const handleClick = async () => {
    setIsDisabled(true)
    try {
      const response = await createCollection(cohort)
      if (response?.['photo']?._id) {
        setCollectionIds(response?.['photo']?._id)
        addCollection({
          variables: {
            cohortId: cohort.id,
            schoolCollectionId: response?.['school']?._id,
            classCollectionId: response?.['class']?._id,
            photoCollectionId: response?.['photo']?._id,
            captionsCollectionId: response?.['captions']?._id,
            formsCollectionId: response?.['forms']?._id,
            mrCollectionId: response?.['mr']?._id,
            uaCollectionId: response?.['ua']?._id
          }
        })
      } else {
        props?.showToast?.('error', validationText.picsio.createCollection.error)
      }
    } catch (error) {
      props?.showToast?.('error', validationText.picsio.createCollection.error)
    }
    setIsDisabled(false)
  }

  const getCollectionName = () => {
    const schoolName = `${cohort.school.mailingAddress.state} - ${cohort.school.name}`
    const endDate = new Date(cohort.endDate)
    return `${schoolName} > ${endDate.getFullYear()} - ${cohort.name}`
  }

  return (
    <Box sx={{ mt: 8 }}>
      <Typography className='bold' variant='h1'>
        Sync with Pics.io Archive {isAvailable ? '' : ' is Unavailable'}
      </Typography>
      {!isAvailable && (
        <>
          <Typography component={'p'} variant='paragraph' sx={{ maxWidth: '750px', mt: 3 }}>
            This class cannot be synced to the Working Assumptions archive in Pics.io because a
            collection was created manually before the web app archive tools were built. Only
            classes with submission dates after{' '}
            <b>{moment(process.env.REACT_APP_PICSIO_RELEASE_DATE).format('LL')}</b> can be synced
            with our current tools.
          </Typography>
          <Typography component={'p'} variant='paragraph' sx={{ my: 1 }}>
            Please contact the web app team for more information.
          </Typography>
        </>
      )}
      {isAvailable && (
        <>
          <Box component={'div'} sx={{ maxWidth: '750px', mt: 3 }}>
            {!collectionIds?.photoCollectionId && (
              <Typography component={'p'} variant='paragraph'>
                Click the button below to create a new collection for this class in the Working
                Assumptions archive in Pics.io. Make sure all awards are finalized and the award
                review summary has been sent before doing so.
              </Typography>
            )}
            {collectionIds?.photoCollectionId && (
              <>
                <Typography component={'p'} variant='paragraph'>
                  Awarded work from this class first uploaded to the Working Assumptions archive in
                  Pics.io on {cohort?.firstSync ? moment(cohort.firstSync).format('LL') : null}.
                </Typography>
                <Typography component={'p'} variant='paragraph' sx={{ my: 1 }}>
                  To view the awarded photographs for this class in Pics.io, navigate to the
                  following collection:
                </Typography>
                <Typography component={'p'} variant='paragraph' className='strong'>
                  {getCollectionName()}
                </Typography>
              </>
            )}
          </Box>
          <Box component={'div'} sx={{ mt: 4 }}>
            {!collectionIds?.photoCollectionId && (
              <>
                {isDisabled ? (
                  <Box xs={12} md={8} lg={7} sx={{ pt: '16px', maxWidth: '750px' }}>
                    <LinearProgress />
                    <Typography
                      component={'div'}
                      variant='note'
                      sx={{
                        p: 2,
                        fontSize: '14px !important'
                      }}
                    >
                      <span
                        style={{ display: 'flex', padding: '8px', justifyContent: 'space-around' }}
                      >
                        Creating collection in Picsio...
                        <br />
                      </span>
                    </Typography>
                  </Box>
                ) : null}
                <Button
                  title={
                    <span className='icon-text-span'>
                      {isDisabled ? (
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                          style={{ marginRight: '8px' }}
                        />
                      ) : (
                        <Upload size={18} />
                      )}
                      Create New Collection in Pics.io
                    </span>
                  }
                  variant='contained'
                  disabled={isDisabled}
                  onClick={handleClick}
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                />
                <Typography component={'div'} variant='note' sx={{ maxWidth: '750px', mt: 2 }}>
                  {isDisabled
                    ? 'A collection is being created in Pics.io for this class. The process may take several minutes. Please do not leave the page until the process is complete.'
                    : 'A new collection will be created in Pics.io for this class. The process may take several minutes. After the collection is created, return to this page to sync files to the Pics.io  collection.'}
                </Typography>
              </>
            )}

            {collectionIds?.photoCollectionId && data && (
              <StudentList
                data={data}
                collectionIds={collectionIds}
                showToast={props?.showToast}
                refetchParent={refetch}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  )
}
