import React, { useEffect, useState } from "react";
import ImageSection from "../ImageSection";
import PhotoTitleCaptionSection from "../PhotoTitleCaptionSection";
import { Box, Container } from "@mui/material";
import ModalHeader from "../ModalHeader";
import { useQuery } from "@apollo/client";
import { inject, observer } from "mobx-react";
import Loading from "../../../elements/Loading";
import AwardSection from "../AwardSection";
import CommentSection from "../CommentSection";
import PhotoModalFooter from "../PhotoModalFooter";
import PhotoMetaDetails from "../PhotoMetaDetails";
import { PHOTO_DETAIL_QUERY } from "../../../../queries/PhotoDetailsQueries";
import PhotoAltTextSection from "../PhotoAltTextSection";
import PhotoPublishSection from "../PhotoPublishSection";
import FileOperationSection from "../FileOperationSection";
import AdminSubmittedDateOverride from "../AdminSubmittedDateOverride";
import UseAgreementSection from "../UseAgreementSection";
import ModalReleaseSection from "../ModalReleaseSection";
import GATag from "../../../../utils/GATag";
import ToastNotification from "../../../elements/ToastNotification";
import { withRouter } from "../../../../utils/customHoc";

const PhotoDetailModalSub = (props) => {
  const {
    placeholderDark,
    publicView,
    isModal,
    closeModal,
    userStore,
    photosStore,
    componentCallFrom,
    photoId,
  } = props;
  const { role, profileId } = userStore;
  const { photoStore } = photosStore;
  const getCurrentPhotoIndexInStore = () => {
    const componentPhotos = photoStore[componentCallFrom];
    const currentPhotoIndex = componentPhotos.findIndex(
      (photo) => photo === activePhotoId
    );
    return currentPhotoIndex;
  };

  // State data
  const [activePhotoId, setActivePhotoId] = useState(photoId);
  const [awardChipDisabled, setAwardChipDisabled] = useState(false);
  const [photoDetails, setPhotoDetails] = useState(null);
  const [projectSubmitted, setProjectSubmitted] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [isDetailsBeignEdited, setIsDetailsBeignEdited] = useState(false);

  const [enableNext, setEnableNext] = useState(
    getCurrentPhotoIndexInStore() === props.totalCount - 1 ? false : true
  );
  const [enablePrev, setEnablePrev] = useState(
    getCurrentPhotoIndexInStore() === 0 ? false : true
  );
  const [formIsValid, setFormIsValid] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { loading, data, error, refetch } = useQuery(PHOTO_DETAIL_QUERY, {
    variables: {
      id: activePhotoId,
    },
    fetchPolicy: "network-only",
    onCompleted(data) {
      setPhotoDetails(data.photo);
      setProjectSubmitted(data.photo.project.submitted);
      const curatorReviews =
        data &&
        data.photo &&
        data.photo.project &&
        data.photo.project.cohort &&
        data.photo.project.cohort.curatorReviews;
      setAwardChipDisabled(
        ["curator"].includes(role) &&
          (data.photo.project.cohort.reviewed ||
            curatorReviews.filter((review) => review.reviewerId === profileId)
              .length > 0)
      );
    },
  });

  const getNextPrevImage = (direction) => {
    const componentPhotos = photoStore[componentCallFrom];
    const currentIndex = getCurrentPhotoIndexInStore();

    setActivePhotoId(
      componentPhotos[
        direction === "next" ? currentIndex + 1 : currentIndex - 1
      ]
    );
  };

  useEffect(() => {
    refetch();
    GATag.setEvent({
      category: "photo-detail",
      action: "viewed",
      label: "/photo-detail/" + activePhotoId,
      value: 1,
    });
    const currentIndex = getCurrentPhotoIndexInStore();
    setEnableNext(currentIndex === props.totalCount - 1 ? false : true);
    setEnablePrev(currentIndex === 0 ? false : true);
  }, [activePhotoId]);

  useEffect(() => {
    if (
      !props.keyCode ||
      isModalOpen ||
      (['input', 'textarea'].includes(document.activeElement.tagName.toLowerCase()) &&
        !['checkbox', 'radio'].includes(document.activeElement?.type?.toLowerCase?.()))
    ) {
      return
    }

    if (
      ((props.keyCode === 37 && enablePrev) || (props.keyCode === 39 && enableNext)) &&
      !isDetailsBeignEdited
    ) {
      getNextPrevImage(props.keyCode === 37 ? 'prev' : 'next')
      props.setKeyCode('')
    }
  }, [props.keyCode])

  useEffect(() => {
    props.setKeyCode("");
  }, [isDetailsBeignEdited]);

  const setToastInfo = (notificationType, message, color) => {
    setShowToast(true);
    setToastMessage(message);
    setToastType(notificationType)
    // setToastColor(color);
    // setSmallText(true);
    setTimeout(() => setShowToast(false), 5000);

  };

  return (
    <Box>
      <ModalHeader
        closeModal={closeModal}
        refetch={refetch}
        photoDetails={photoDetails}
        awardChipDisabled={awardChipDisabled}
        role={role}
        publicView={publicView}
        isModal={true}
        className={
          !isModal
            ? "container__sticky-header modal-header"
            : "container__sticky-header--empty-div"
        }
        toast= {showToast === true ? (
            <ToastNotification
              notificationType={toastType}
              showToast={showToast}
              onClose={() => setShowToast(false)}
              toastMessage={toastMessage}
            />
          ) : null
        }
      />
      <Box>
        <ImageSection
          photoDetails={photoDetails}
          placeholderDark={placeholderDark}
          photoImageType="Image Type "
          photoDimensions=" Dimensions "
          photoFileSize=" File Size"
          isModal={true}
          key={photoDetails && photoDetails.id}
          getAdjacentImage={getNextPrevImage}
          publicView={publicView}
          refetch={refetch}
          enableNext={enableNext}
          enablePrev={enablePrev}
          loading={loading}
        />
      </Box>
      {photoDetails ? (
        <>
          <Container>
          <Box
            sx={{
              maxWidth: "48rem",
              margin: "0 auto",
            }}
          >
            <Box>
              {!publicView ? (
                <PhotoTitleCaptionSection
                  photoId={photoDetails && photoDetails.id}
                  photoDetails={photoDetails}
                  role={role}
                  projectSubmitted={projectSubmitted}
                  publicView={publicView}
                  refetch={refetch}
                  loading={loading}
                  showToast={setToastInfo}
                  setIsDetailsBeignEdited={setIsDetailsBeignEdited}
                />
              ) : (
                <div className="photo-caption">
                  <blockquote className="blockquote--editable">
                    {photoDetails && photoDetails.caption}
                  </blockquote>
                </div>
              )}
            </Box>
            {!publicView && ["appadmin", "curator"].includes(role) ? (
              <Box>
                <AwardSection
                  photo={photoDetails}
                  isModal={true}
                  publicView={publicView}
                  role={role}
                  refetchPhoto={refetch}
                />
              </Box>
            ) : null}
            {/* Photos comments section */}
            {!publicView &&
            photoDetails &&
            photoDetails.submitted &&
            ["appadmin", "curator"].includes(role) ? (
              <Box>
                <CommentSection
                  photoId={photoDetails && photoDetails.id}
                  comments={photoDetails && photoDetails.photoComments}
                  userStore={userStore}
                  id={userStore.id}
                  refetchphoto={refetch}
                  // toggleEditing={this.toggleEditing.bind(this)}
                  role={role}
                  profileId={profileId}
                  publicView={publicView}
                  photo={photoDetails}
                />
              </Box>
            ) : null}

            {!publicView &&
            photoDetails &&
            photoDetails.submitted &&
            photoDetails.project.cohort.reviewed &&
            photoDetails.summarySent &&
            photoDetails.awards.length > 0 &&
            ["appadmin", "student", "teacher", "facilitator"].includes(role) ? (
              <Box>
                <UseAgreementSection
                  photoId={photoDetails && photoDetails.id}
                  refetchPhoto={refetch}
                  photoDetails={photoDetails}
                  project={photoDetails && photoDetails.project}
                  role={role}
                  student={
                    photoDetails &&
                    photoDetails.project &&
                    photoDetails.project.student
                  }
                  toggleEditing={setIsDetailsBeignEdited}
                  showEdit={isDetailsBeignEdited}
                  // isShowingEdited={this.state.isShowingEdited}
                />
              </Box>
            ) : null}
            {!publicView &&
            photoDetails &&
            photoDetails.submitted &&
            photoDetails.project.cohort.reviewed &&
            ((photoDetails.awards.length > 0 && role === "appadmin") ||
              (photoDetails.summarySent &&
                photoDetails.hasPeople !== null &&
                ["student", "teacher", "facilitator"].includes(role))) ? (
              <Box>
                <ModalReleaseSection
                  photoId={photoDetails && photoDetails.id}
                  refetchPhoto={refetch}
                  photoDetails={photoDetails}
                  project={photoDetails && photoDetails.project}
                  role={role}
                  student={
                    photoDetails &&
                    photoDetails.project &&
                    photoDetails.project.student
                  }
                  toggleEditing={setIsDetailsBeignEdited}
                  showEdit={isDetailsBeignEdited}
                  setIsModalOpen={setIsModalOpen}
                />
              </Box>
            ) : null}
            {!publicView &&
            role === "appadmin" &&
            photoDetails &&
            photoDetails.submitted &&
            photoDetails.awards.length > 0 ? (
              <Box>
                <PhotoAltTextSection
                  photoId={photoDetails && photoDetails.id}
                  photoDetails={photoDetails}
                  refetchphoto={refetch}
                  showToast={setToastInfo}
                  role={role}
                  projectSubmitted={projectSubmitted}
                />
              </Box>
            ) : null}
            {!publicView ? (
              <>
                <Box>
                  <PhotoPublishSection
                    photoId={photoDetails && photoDetails.id}
                    photoDetails={photoDetails}
                    refetchphoto={refetch}
                    showToast={setToastInfo}
                    role={role}
                    formIsValid={formIsValid}
                  />
                </Box>
                <Box>
                  <FileOperationSection
                    role={role}
                    photo={photoDetails}
                    refetchPhoto={refetch}
                    userStore={userStore}
                  />
                </Box>
              </>
            ) : null}
            {!publicView && role === "appadmin" ? (
              <Box>
                <AdminSubmittedDateOverride
                  photoId={photoDetails && photoDetails.id}
                  photoDetails={photoDetails}
                  refetchPhoto={refetch}
                  role={role}
                  userStore={userStore}
                  showToast={setToastInfo}
                  setFormIsValid={setFormIsValid}
                />
              </Box>
            ) : null}
            {!publicView ? (
              <Box>
                <PhotoMetaDetails
                  photoId={photoDetails && photoDetails.id}
                  toggleParentModalState={props.toggleParentModalState}
                  photo={photoDetails}
                  isStudent={false}
                  role={role}
                />
              </Box>
            ) : null}
          </Box>
          </Container>
          {!publicView ? (
            <Box
              sx={{
                minWidth: "100%",
              }}
            >
              <PhotoModalFooter
                role={role}
                photoId={photoDetails && photoDetails.id}
                photo={photoDetails}
                newRawFile={photoDetails && photoDetails.rawFileUrl}
              />
            </Box>
          ) : null}
        </>
      ) : null}
      {loading ? (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0",
            background: "rgba(0,0,0,0.7)",
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              width: "fit-content",
              position: "relative",
              top: "35%",
              transform: "rotate(90deg)",
            }}
          >
            <div className="modal-photo-container-pulse-loader-wrapper">
              <Loading />
            </div>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default inject(
  "userStore",
  "photoCollectionStore",
  "backToPageStore",
  "photosStore"
)(observer(withRouter(PhotoDetailModalSub)));
