import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box } from '@mui/material'
import Slider from '@mui/material/Slider'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const useStyles = makeStyles(theme => ({
  root: {
    height: props => props.height
  }
}))

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff'
    }
  }
})

const DiscreteSlider = props => {
  const classes = useStyles(props)
  const [value, setValue] = useState(props.value || 1)

  useEffect(() => {
    setValue(props.value)
  }, [props])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    if (props.onChange) {
      props.onChange(event, newValue)
    }
  }

  return (
    <Box component='div' height={props.height}>
      <ThemeProvider theme={theme}>
        <Typography id='discrete-slider' gutterBottom />
        <Slider
          defaultValue={props.defaultValue}
          getAriaValueText={props.valueText}
          aria-labelledby='discrete-slider'
          valueLabelDisplay='auto'
          step={props.step}
          marks={props.marks}
          min={props.range[0]}
          max={props.range[1]}
          orientation={props.orientation}
          onChange={handleChange}
          color='primary'
          value={value}
        />
      </ThemeProvider>
    </Box>
  )
}

export default DiscreteSlider
