import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import variables from '../../../src/ui/settings/_variables.scss'

const TeacherAwardResultsRow = props => {
  const [project] = useState(props.project)

  const getFinalAward = awards => {
    if (awards.length > 0) {
      let aoeCount = awards.filter(award => award.awardClass === 'Award of Excellence').length
      let hmCount = awards.filter(award => award.awardClass === 'Honorable Mention').length

      return aoeCount > hmCount || aoeCount === hmCount
        ? 'Award of Excellence'
        : hmCount > 0
        ? 'Honorable Mention'
        : null
    }
    return null
  }

  const awardCount = (photos, awardType) => {
    let photoCount = 0
    photos.forEach(photo => {
      let finalAward = getFinalAward(photo.awards)
      if (finalAward === awardType) photoCount++
    })
    return photoCount
  }

  const getAwarkLink = awardType => {
    const link =
      awardType === 'Award of Excellence'
        ? '/awarded-photos/award-of-excellence/' + project.id
        : '/awarded-photos/honorable-mention/' + project.id
    const text =
      awardType === 'Award of Excellence' ? 'Award(s) of Excellence' : 'Honorable Mention(s)'
    const count = awardCount(project.photos, awardType)

    return count > 0 ? (
      <Link to={link}>
        <Typography variant='link' className='large' sx={{ display: { md: 'none' } }}>
          {[count, text].join(' ')}
        </Typography>
        <Typography variant='link' className='large' sx={{ display: { xs: 'none', md: 'block' } }}>
          {[count, 'Photographs'].join(' ')}
        </Typography>
      </Link>
    ) : (
      <>
        <Typography
          variant='dataLabel'
          className='content'
          component={'p'}
          display={{ md: 'none' }}
        >
          {count}{' '}
          <Typography
            variant='dataLabel'
            className='content'
            component='span'
            sx={{ display: { md: 'none' } }}
          >
            {text}
          </Typography>
        </Typography>
        <Typography
          variant='dataLabel'
          className='content'
          component={'p'}
          display={{ xs: 'none', md: 'block' }}
        >
          None
        </Typography>
      </>
    )
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        m: 0,
        mb: { md: 1 },
        px: { xs: 2, md: 0 },
        pb: 2,
        backgroundColor: variables.white,
        borderBottom: variables.borderThinGrey
      }}
    >
      <Grid item xs={12} md={4}>
        <Typography variant='dataLabel' className='content'>
          {project.student.name.replace('NotSupplied', '').trim()}
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        {getAwarkLink('Award of Excellence')}
      </Grid>
      <Grid item xs={12} md={4}>
        {getAwarkLink('Honorable Mention')}
      </Grid>
    </Grid>
  )
}

export default TeacherAwardResultsRow
