import React, { Fragment, Component } from 'react'
import { Link } from 'react-router-dom'
import { gql } from '@apollo/client'
import Cookies from 'universal-cookie'
import queryString from 'query-string'
import { observer, inject } from 'mobx-react'
import { withApollo } from '@apollo/client/react/hoc'
import { Mutation } from '@apollo/client/react/components'
// import Slide from '@mui/material/Slide'
import { Typography, Grid, Box } from '@mui/material'
import { withStyles } from '@material-ui/core/styles'
import WrkXFmlyLogo from '../assets/svgs/wrkxfmlyLogo'
import Layout from '../ui/template/Layout'
import PasswordFormField from '../ui/elements/PasswordFormField'
import FormField from '../ui/elements/FormField'
import Button  from '../ui/elements/Button'
import variables from '../ui/settings/_variables.scss'
import { withRouter } from '../utils/customHoc'
import ToastNotification from '../ui/elements/ToastNotification'

const styles = theme => ({
  button: {
    borderRadius: 0,
    p: '12px 16px',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '33%',
    },
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
});

const cookies = new Cookies()

const LOGIN_MUTATION = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      accessToken
      client
      expiry
      id
      uid
      firstName
      lastName
      role
      profileId
      phoneNumber
      mobileNumber
      initials
    }
  }
`

const ADD_STUDENT_MUTATION = gql`
  mutation AddStudentToCohort($cohortUuid: String!) {
    addStudentToCohort(cohortUuid: $cohortUuid) {
      id
    }
  }
`

class Login extends Component {
  constructor(props) {
    super(props)
    this.userStore = this.props.userStore
    this.breadcrumbStore = this.props.breadcrumbStore
    const urlParams = new URLSearchParams(window.location.search)

    this.state = {
      email: '',
      password: '',
      errors: [],
      showToast: false,
      toastMessage: '',
      classUuid: urlParams.get('class-uuid'),
      showPassword: false
    }

    this.handleLogin = this.handleLogin.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
  }

  showToast = (toastType, message='') => {
    this.setState({
      showToast: true,
      toastMessage: message,
      toastType: toastType
    })
    setTimeout(() => this.setState({ showToast: false, toastMessage: '' }), 5000)
  }

  componentDidMount() {
    if (queryString.parse(window.location.search)['password-reset-toast']) {
      this.showToast('success', 'Password Reset Email Sent. Please check your inbox and follow the instructions to reset your password.')
    }
    if (sessionStorage.getItem('resetToast')) {
      sessionStorage.removeItem('resetToast')
      this.showToast('success', 'Your password was reset. Please log in to continue.')
    }
  }

  handleLogin(cache) {
    this.setState({ errors: {} })
    const loginPayloadKey = Object.keys(cache.optimisticData.data).filter(function(str) {
      return str.includes('SignInPayload')
    })[0]
    const normalData = cache.optimisticData.data[loginPayloadKey]
    const { firstName, lastName, accessToken, client, expiry, uid, role, profileId, mobileNumber } = normalData
    this.userStore.setFirstName(firstName)
    this.userStore.setLastName(lastName)
    this.userStore.setRole(role)
    this.userStore.setProfileId(profileId)
    this.userStore.setIsAuthenticated(true)
    this.userStore.setPhoneNumber(mobileNumber)
    this.breadcrumbStore.setCurrentUserRole(role)

    cookies.set('WA_ACCESS_TOKEN', accessToken, { path: '/' })
    cookies.set('WA_CLIENT_TOKEN', client, { path: '/' })
    cookies.set('WA_EXPIRY', expiry, { path: '/' })
    cookies.set('WA_UID', uid, { path: '/' })
  }

  handleErrors(error) {
    if (error && error.message) {
      if (this.state.errors.length === 0) {
        this.setState({ errors: { credentials: 'Please enter a valid email/password.' } })
      }
    }
  }

  async joinClass() {
    const uuid = this.state.classUuid

    return await this.props.client.mutate({
      mutation: ADD_STUDENT_MUTATION,
      variables: {
        cohortUuid: uuid
      }
    })
  }

  handleRedirect(role) {
    const {location, navigate} = this.props
    if (location.pathname.indexOf('/login') === -1) {
      window.location.reload()
      return
    }

    switch (role) {
      case 'student':
        navigate('/projects')
        break
      case 'teacher':
        navigate('/classes/current')
        break
      case 'facilitator':
        navigate('/classes/current')
        break
      case 'curator':
        navigate('/reviews/in-curatorial-review')
        break
      default:
        navigate('/dashboard')
        break
    }
  }

  handleClose =(event, reason)=>{
    if (reason === "clickaway") {
      return;
    }
    this.setState({showToast:false})
  }

  render() {
    const handleLogin = this.handleLogin
    const handleErrors = this.handleErrors
    const {userStore, breadcrumbStore} = this.props
    // const Transition = React.forwardRef(function Transition(props, ref) {
    //   return (
    //     <Slide
    //       ref={ref}
    //       {...props}
    //       direction='down'
    //       in={props.showToast}
    //       mountOnEnter
    //       unmountOnExit
    //     />
    //   )
    // })
    return (
      <Layout>
      <Mutation
        mutation={LOGIN_MUTATION}
        update={(cache, { data: { doLogin } }) => {
          handleLogin(cache)
        }}
        onCompleted={res => {
          if (!res.signIn) return
          const { id, firstName, lastName, accessToken, client, expiry, uid, role, profileId, mobileNumber, initials } = res.signIn
          userStore.setFirstName(firstName)
          userStore.setLastName(lastName)
          userStore.setRole(role)
          userStore.setProfileId(profileId)
          userStore.setIsAuthenticated(true)
          userStore.setPhoneNumber(mobileNumber)
          userStore.setInitials(initials)

         userStore.setId(id)
    // userStore.setEmail(email)

          breadcrumbStore.setCurrentUserRole(role)

          cookies.set('WA_ACCESS_TOKEN', accessToken, { path: '/' })
          cookies.set('WA_CLIENT_TOKEN', client, { path: '/' })
          cookies.set('WA_EXPIRY', expiry, { path: '/' })
          cookies.set('WA_UID', uid, { path: '/' })
          // join class if classUuid is present as query parameter:
          if (this.state.classUuid && role.toLowerCase() === 'student' ) {
            this.joinClass().then(res2 => {
              if (res2.data.addStudentToCohort) this.handleRedirect(role.toLowerCase())
            })
          } else this.handleRedirect(role.toLowerCase())
        }}
      >
        {(doLogin, { data, error }) => {
          handleErrors(error)
          return (
            <Fragment>
              {this.state.showToast && (
                <ToastNotification
                  notificationType={this.state.toastType}
                  showToast={this.state.showToast}
                  onClose={this.handleClose}
                  toastMessage={this.state.toastMessage}
                />
              )}
              <Box component={'div'} className='container__body--login'>
                <Box className='container__login' sx={{px: {sm: 10, md: 13, lg: 15}, pt: {xs: 2, md: 8}, pb: {xs: 4}}}>
                  <Box className='login__header'>
                    <Typography variant='h1' component='h1' className='bold' sx={{mt: {xs: 3, sm: 8, md: 0}}}>
                      Log in to
                    </Typography>
                    <Box mt={1.5}>
                      <WrkXFmlyLogo sx={{ height: {xs: '43px', md: '36px'}, maxWidth: 'inherit' }}/>
                    </Box>
                  </Box>
                  <Box sx={{ mt: {xs: 4.1, sm: 9}}}>
                    <form
                      onSubmit={e => {
                        this.props.userStore.setEmail(this.state.email)

                        e.preventDefault()
                        doLogin({
                          variables: {
                            email: this.state.email,
                            password: this.state.password
                          }
                        })
                        this.email = ''
                        this.password = ''
                      }}
                    >
                      <Grid container direction='column' alignItems='center' spacing={5} paddingLeft={{xs: 0}} mb={{xs: 1, sm: 6}}>
                        <Grid item xs={12} md={6} width={'100%'}>
                          <Typography variant='paragraph' component='p' sx={{ color: `${variables.redError} !important`, pb: '0px !important'}}>
                          {this.state.errors.credentials}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} width={'100%'}>
                          <FormField
                            formFieldId='outlined-email'
                            formFieldLabel='Email'
                            formFieldVariant='filled'
                            formFieldType='email'
                            formFieldName='email'
                            onChange={event => this.setState({ email: event.target.value })}
                            formFieldError={this.state.errors.credentials ? true : false}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} width={'100%'} mt={{xs: this.state.errors.credentials ? 0: '-4px'}}>
                          <PasswordFormField
                            passwordFormFieldId='filled-adornment-current-password'
                            passwordFormFieldVariant='filled'
                            passwordFormFieldName='password'
                            passwordFormFieldType='password'
                            passwordFormFieldLabel='Password'
                            onChange={event => this.setState({ password: event.target.value })}
                            formFieldError={this.state.errors.credentials || null}
                            PasswordFormFieldValue={this.state.password}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} width={{xs: '100%', md: '25%'}}>
                          <Button
                            variant='contained'
                            type='submit'
                            disabled={(this.state.password === '' || this.state.email === '')}
                            fullWidth
                            sx={{
                              width: {xs: '100%', sm: '33%', md: '100%'},
                            }}
                            title='Log In'
                            lableFontClass='strong'
                          />
                        </Grid>
                        <Grid item textAlign={'center'} mt={{xs: '-5px'}}>
                          <Link to='/forgot-password'>
                            <Typography
                              variant='link'
                              >Forgot your password?</Typography>
                          </Link>
                        </Grid>
                        <Grid item mt={{xs: '-2px', md: 4.8}} textAlign='center' display='block'>
                            <Typography
                              variant='paragraph'
                              component='p'
                            >
                            To sign up for an account, please contact {' '}
                            <a href='mailto:education@workingassumptions.org' style={{wordBreak: 'break-word'}}>
                              <Typography variant='link' className='large'>education@workingassumptions.org</Typography>
                            </a>
                          </Typography>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Box>
              </Box>
            </Fragment>
          )
        }}
      </Mutation>
      </Layout>
    )
  }
}

export default withStyles(styles)(inject('userStore', 'breadcrumbStore')(observer(withRouter(withApollo(Login)))))
