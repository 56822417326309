import React, { Component, Fragment } from 'react'
import { ArrowRight } from 'react-feather'
import { Link } from 'react-router-dom'
import './termsprivacy.scss'
import Layout from '../ui/template/Layout'
import { Typography } from '@mui/material'

class PhotographUseEnglish extends Component {
  render() {
    return (
      <Layout>
        <div className='container__public-body'>
          <div className='public-body__app-terms-and-policies'>
            <div className='app-terms-and-policies__hero'>
              <div className='app-terms-and-policies__hero--header'>
                <h4 className='h4--semi-strong'>
                  <Link to='/photograph-use-english'>English</Link> /{' '}
                  <Link to='/photograph-use-espanol'>Español</Link>
                </h4>
                <h1 className='h1--strong public'>Application Terms and Policies</h1>
              </div>
              <div className='app-terms-and-policies__hero--copy'>
                <h2 className='h2--medium'>
                  These terms and policies apply to the use of the Working Assumptions student web
                  application. If you have any questions, please contact{' '}
                  <strong>
                    <a
                      href='mailto:education@workingassumptions.org'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      education@workingassumptions.org
                    </a>
                  </strong>
                  .
                </h2>
                <br />
                <h3 className='h3--semi-strong'>
                  <Link to='/app-terms-espanol'>
                    Application Terms of Use <ArrowRight size={21} />
                  </Link>
                </h3>
                <h3 className='h3--semi-strong'>
                  <Link to='/app-privacy-espanol'>
                    Application Privacy Policy <ArrowRight size={21} />
                  </Link>
                </h3>
                <h3 className='h3--semi-strong'>
                  <Link to='/photograph-use-policy-espanol'>
                    Photograph Use Policy <ArrowRight size={21} />
                  </Link>
                </h3>
              </div>
            </div>

            <div className='app-terms-and-policies__content'>
              <h1 className='h1--semi-strong'>Photograph Use Policy</h1>

              <div className='app-terms-and-policies__content--section'>
                <div className='app-terms-and-policies__content--description'>
                  <h2 className='h2--semi-strong'>Section Name</h2>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                    Longer legal text, in plain English. Cursus magna, vel scelerisque nisl
                    consectetur et. Cras mattis consectetur purus sit amet fermentum. Curabitur
                    blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet
                    non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
                    nibh, ut fermentum massa justo sit amet risus. Aenean eu leo quam. Pellentesque
                    ornare sem lacinia quam venenatis vestibulum. Integer posuere erat a ante
                    venenatis dapibus posuere velit aliquet. Donec ullamcorper nulla non metus
                    auctor fringilla. Etiam porta sem malesuada magna mollis euismod. Donec id elit
                    non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed
                    consectetur. Maecenas faucibus mollis interdum.
                  </Typography>
                </div>
                <div className='app-terms-and-policies__content--summary'>
                  <h4 className='h4--semi-strong'>What This Means</h4>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                    Brief summary of section. Donec sed odio dui. Integer posuere erat a ante
                    venenatis dapibus posuere velit aliquet. Donec id elit non mi porta gravida at
                    eget metus.
                  </Typography>
                </div>
              </div>

              <div className='app-terms-and-policies__content--section'>
                <div className='app-terms-and-policies__content--description'>
                  <h2 className='h2--semi-strong'>Section Name</h2>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                    Longer legal text, in plain English. Cursus magna, vel scelerisque nisl
                    consectetur et. Cras mattis consectetur purus sit amet fermentum. Curabitur
                    blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet
                    non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
                    nibh, ut fermentum massa justo sit amet risus. Aenean eu leo quam. Pellentesque
                    ornare sem lacinia quam venenatis vestibulum. Integer posuere erat a ante
                    venenatis dapibus posuere velit aliquet. Donec ullamcorper nulla non metus
                    auctor fringilla. Etiam porta sem malesuada magna mollis euismod. Donec id elit
                    non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed
                    consectetur. Maecenas faucibus mollis interdum.
                  </Typography>
                </div>
                <div className='app-terms-and-policies__content--summary'>
                  <h4 className='h4--semi-strong'>What This Means</h4>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                    Brief summary of section. Donec sed odio dui. Integer posuere erat a ante
                    venenatis dapibus posuere velit aliquet. Donec id elit non mi porta gravida at
                    eget metus.
                  </Typography>
                </div>
              </div>

              <div className='app-terms-and-policies__content--section'>
                <div className='app-terms-and-policies__content--description'>
                  <h2 className='h2--semi-strong'>Section Name</h2>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                    Longer legal text, in plain English. Cursus magna, vel scelerisque nisl
                    consectetur et. Cras mattis consectetur purus sit amet fermentum. Curabitur
                    blandit tempus porttitor. Maecenas sed diam eget risus varius blandit sit amet
                    non magna. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum
                    nibh, ut fermentum massa justo sit amet risus. Aenean eu leo quam. Pellentesque
                    ornare sem lacinia quam venenatis vestibulum. Integer posuere erat a ante
                    venenatis dapibus posuere velit aliquet. Donec ullamcorper nulla non metus
                    auctor fringilla. Etiam porta sem malesuada magna mollis euismod. Donec id elit
                    non mi porta gravida at eget metus. Aenean lacinia bibendum nulla sed
                    consectetur. Maecenas faucibus mollis interdum.
                  </Typography>
                </div>
                <div className='app-terms-and-policies__content--summary'>
                  <h4 className='h4--semi-strong'>What This Means</h4>
                  <Typography variant="paragraph"
                    sx={{
                      mb:"1rem",
                    }}
                    component="p"                             
                  >
                    Brief summary of section. Donec sed odio dui. Integer posuere erat a ante
                    venenatis dapibus posuere velit aliquet. Donec id elit non mi porta gravida at
                    eget metus.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PhotographUseEnglish
