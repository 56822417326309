import { Box, Grid, Typography } from '@mui/material'
import { inject } from 'mobx-react'
import moment from 'moment'
import _ from 'lodash'
import variables from '../../settings/_variables.scss'
import { Link } from 'react-router-dom'

const ProgramInfo = props => {
  const { user, userStore } = props
  const { profile } = user
  const role = profile.__typename
  const school = profile.cohorts && profile.cohorts.length ? profile.cohorts[0].school : null

  const getCohortsList = () => {
    let cohortsNameList = []
    if (_.includes(['Student'], role)) {
      cohortsNameList = profile.cohorts.map(cohortsName => {
        return props.userStore.role !== 'appadmin' ? (
          <Box key={cohortsName.id}>{cohortsName.name}</Box>
        ) : (
          <Box key={cohortsName.id}>
            <Link to={`/class-detail/${cohortsName.id}`} key={`key-${cohortsName.id}`}>
              <Typography variant='link' className='large'>
                {cohortsName.name}
              </Typography>
            </Link>
          </Box>
        )
      })
    }
    return cohortsNameList
  }

  return ['student', 'teacher', 'facilitator', 'appadmin'].includes(userStore.role) ? (
    <Box
      sx={{
        borderTop: `1px solid ${variables.lightGrey}`,
        pt: 6,
        pb: 6
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography variant='h2' className='strong'>
          Program Information
        </Typography>
      </Box>

      <Box>
        <Box>
          <Grid container spacing={3}>
            {_.includes(['Student', 'Teacher', 'Facilitator'], role) ? (
              <Grid item md={5} sm={6} xs={12}>
                <Box>
                  <Typography variant='dataLabel' component='p'>
                    School
                  </Typography>
                </Box>
                <Box>
                { props.userStore.role !== 'appadmin' && school === 'null' ?
                   profile.__typename === 'Teacher' || profile.__typename === 'Facilitator' ?
                    <Typography variant='dataLabel' className='content' component='p'>This user has not taken part in classes in any schools.</Typography>
                  : 
                  <Typography variant='dataLabel' className='content' component='p'>
                    {school ? school.name : 'This user does not have projects in any schools.'}
                  </Typography>
                : school ?
                  ['student', 'teacher', 'facilitator'].includes(userStore.role) ? <Typography variant='dataLabel' className='content' component='p'>
                      {school ? school.name : 'This user does not have projects in any schools.'}
                    </Typography>
                    :
                    <Link to={'/school-details/' + school.id}>
                      <Typography variant='link' className='large'>
                        {school.name}
                      </Typography>
                    </Link>
                  : 
                  profile.__typename === 'Teacher' || profile.__typename === 'Facilitator' ?
                    <Typography variant='dataLabel' className='content' component='p'>This user has not taken part in classes in any schools.</Typography>
                  : 
                  <Typography variant='dataLabel' className='content' component='p'>
                    {'This user does not have projects in any schools.'}
                  </Typography>
                }
                </Box>
              </Grid>
            ) : null}

            {_.includes(['Student'], role) ? (
              <Grid item md={5} sm={6} xs={12}>
                <Box>
                  <Typography variant='dataLabel' component='p'>
                    Class
                  </Typography>
                  <Typography variant='dataLabel' component='div' className='content'>
                    {profile && profile.cohorts && profile.cohorts.length > 0
                      ? getCohortsList()
                      : 'This user does not have projects in any classes.'}
                  </Typography>
                </Box>
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={3} sx={{ pt: 4 }}>
            <Grid item md={5} sm={6} xs={12}>
              <Box>
                <Typography variant='dataLabel' component='p'>
                  Account Created
                </Typography>
              </Box>
              <Box>
                {user ? (
                  <Typography variant='dataLabel' className='content' component='p'>
                    {moment(user.createdAt).format('LL')}
                  </Typography>
                ) : null}
              </Box>
            </Grid>

            <Grid item md={5} sm={6} xs={12}>
              <Typography variant='dataLabel' component='p'>
                User Type
              </Typography>
              <Typography variant='dataLabel' className='content' component='p'>
                {role}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  ) : null
}

export default inject('userStore')(ProgramInfo)
