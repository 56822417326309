import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Button from '../elements/Button.js'
import PropTypes from 'prop-types'
import { Typography, Grid, Box } from '@mui/material'
import { Spinner } from 'react-bootstrap'
import BaseModal from '../template/BaseModal'
import usePicsio from '../../../src/utils/picsioUtility'

const ManageModelReleaseMutation = gql`
  mutation ManageModelRelease($photoId: ID!, $modelUuid: String!, $manageType: String!) {
    manageModelRelease(photoId: $photoId, modelUuid: $modelUuid, manageType: $manageType) {
      id
    }
  }
`

// const styles = theme => ({
//   root: {
//     display: 'flex',
//     marginBottom: '16px'
//   },
//   formControl: {
//     margin: 0
//   },
//   group: {
//     margin: 0
//   }
// })

const DeleteModelReleaseModal = props => {
  const { removeImage } = usePicsio()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [manageModelRelease] = useMutation(ManageModelReleaseMutation)

  const handleFormSubmit = () => {
    setIsSubmitting(true)
    manageModelRelease({
      variables: {
        photoId: props.photoId,
        modelUuid: props.modelUuid,
        manageType: props.deleteType === 'main' ? 'main-delete' : 'delete'
      },
      refetchQueries: ['PhotoDetails']
    }).then(handleSubmitSuccess)
  }

  const handleSubmitSuccess = () => {
    setIsSubmitting(false)
    if (props?.assetId) {
      removeImage([props.assetId])
    }
    props?.refetchPhoto?.()
    props?.refetchMrData?.()
    props.onHide()
  }

  return (
    <BaseModal
      {...props}
      show={props.show}
      onClick={props.onClick}
      value={props.value}
      open={props.show}
      onClose={() => props.onHide(false)}
      onHide={props.onHide}
    >
      <BaseModal.BaseModalHeader>
        {props.deleteType === 'main' ? 'Delete Model' : 'Delete Model Release'}
      </BaseModal.BaseModalHeader>
      <BaseModal.BaseModalBody id='mt_10'>
        <Box>
          {props.deleteType === 'main' ? (
            <>
              <Typography variant='paragraph' component='p'>
                Be sure you want to delete this model.
              </Typography>
              <br />
              <Typography variant='paragraph' component='p' sx={{ mb: '-20px' }}>
                Reminder: if you delete this model, any Model Releases attached to the model will
                also be removed from the web app.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant='paragraph' component='p'>
                Be sure you want to delete this Model Release.
              </Typography>
              <br />
              <Typography variant='paragraph' component='p'>
                Reminder: if you delete this Model Release, you will no longer be able to access the
                information about this form through the web app.
              </Typography>
            </>
          )}
        </Box>
        <BaseModal.BaseModalFooter>
          <Box
            sx={{ padding: '24px 0 0', marginTop: '0px', alignContent: 'center' }}
            className='send-message__button'
          >
            <Grid
              container
              direction='row'
              sx={{
                justifyContent: { xs: 'center', sm: 'end' },
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={isSubmitting}
                  title={
                    isSubmitting ? (
                      <span className='icon-text-span'>
                        <Spinner
                          as='span'
                          animation='border'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />{' '}
                        Deleting...
                      </span>
                    ) : props.deleteType === 'main' ? (
                      'Delete Model'
                    ) : (
                      'Delete Model Release'
                    )
                  }
                  onClick={handleFormSubmit}
                  sx={{ width: { xs: '100%', sm: '100%' } }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{ padding: { xs: '24px 0 0', sm: '16px 0 0', md: '12px 0 0' } }}
              >
                <Typography
                  sx={{
                    mr: { sm: 2 },
                    display: { xs: 'flex' },
                    justifyContent: { xs: 'center', sm: 'right' }
                  }}
                  variant='h4'
                  className='small_size_semi_bold link'
                  onClick={props.onHide}
                >
                  Cancel
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </BaseModal.BaseModalFooter>
      </BaseModal.BaseModalBody>
    </BaseModal>
  )
}

// class DeleteModelReleaseModal extends Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       resendMessage: false,
//       modelUuid:props.modelUuid,
//       isSubmitting: false
//     }

//     this.handleFormSubmit = this.handleFormSubmit.bind(this)
//     this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this)
//   }

//   handleFormSubmit = (onMutate) => {
//     this.setState({isSubmitting: true})
//     onMutate({
//       variables: {
//         photoId: this.props.photoId,
//         modelUuid: this.props.modelUuid,
//         manageType: this.props.deleteType === 'main'? 'main-delete' :'delete',
//       },
//       refetchQueries: [`PhotoDetails`]
//     })
//   }

//   handleSubmitSuccess = () => {
//     this.setState({isSubmitting: false})
//     removeImage?.(this.props?.assetId)
//     this.props.refetchPhoto()
//     this.props.refetchMrData && this.props.refetchMrData()
//     this.props.onHide()
//   }

//   render() {
//     const {
//       handleSubmitSuccess
//     } = this
//     const handleClose = () => {
//       this.setState({show: false});
//       this.props.onHide(false);

//     };
//     return <Mutation
//       mutation={ManageModelReleaseMutation}
//       onCompleted={()=>{
//         handleSubmitSuccess()
//       }}
//     >
//       {(manageOnMutate, { data }) => (
//         <BaseModal
//           {...this.props}
//           show={this.props.show}
//           onClick={this.props.onClick}
//           value={this.props.value}
//           open={this.props.show}
//           onClose={handleClose}
//           onHide={this.props.onHide}
//         >
//         <BaseModal.BaseModalHeader>
//           {this.props.deleteType === 'main' ? 'Delete Model' : 'Delete Model Release'}        </BaseModal.BaseModalHeader>
//         <BaseModal.BaseModalBody id="mt_10">
//           <Box>
//             {this.props.deleteType === 'main' ? <><Typography variant="paragraph" component="p">
//             Be sure you want to delete this model.
//             </Typography>
//             <br/>
//             <Typography variant="paragraph" component="p" sx={{mb:"-20px"}}>
//               Reminder: if you delete this model, any Model Releases attached to the model will also be removed from the web app.
//             </Typography></> :<><Typography variant="paragraph" component="p">
//             Be sure you want to delete this Model Release.
//             </Typography>
//             <br/>
//             <Typography variant="paragraph" component="p">
//             Reminder: if you delete this Model Release, you will no longer be able to access the information about this form through the web app.
//             </Typography></> }
//           </Box>
//           <BaseModal.BaseModalFooter>
//             <Box sx={{padding:'24px 0 0', marginTop:'0px', alignContent:'center'}} className='send-message__button'>
//             <Grid container direction="row" sx={{justifyContent:{xs:'center', sm:'end'}, display: 'flex', flexDirection: 'row-reverse'}}>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Button
//                   variant='contained'
//                   type='submit'
//                   disabled={this.state.isSubmitting}
//                   title={
//                     this.state.isSubmitting ? <span className='icon-text-span'>
//                     <Spinner
//                       as="span"
//                       animation="border"
//                       size="sm"
//                       role="status"
//                       aria-hidden="true"
//                     /> Deleting... </span> : (this.props.deleteType === 'main' ? 'Delete Model' : 'Delete Model Release')
//                   }
//                   onClick={() => this.handleFormSubmit(manageOnMutate)}
//                   sx={{width:{ xs: '100%', sm: '100%' }}}
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6} md={4} sx={{padding:{xs:'24px 0 0', sm:'16px 0 0', md:'12px 0 0'}}}>
//                 <Typography sx={{mr:{sm:2}, display:{xs: 'flex'}, justifyContent:{xs:'center', sm:'right'}}} variant="h4"className='small_size_semi_bold link' onClick={() => this.props.onHide()}>
//                   Cancel
//                 </Typography>
//               </Grid>
//             </Grid>
//           </Box>
//           </BaseModal.BaseModalFooter>
//         </BaseModal.BaseModalBody>
//       </BaseModal>
//       ) }
//     </Mutation>
//   }
// }

DeleteModelReleaseModal.propTypes = {
  classes: PropTypes.object.isRequired
}

export default DeleteModelReleaseModal
